<template>
  <div>
    <UiBottomSheetPopup
      :show="show"
      name="watermelon-refund-bottomsheet"
      class="WatermelonRefundBottomSheet"
      closable
      style="--popup-width-d: 588px"
      @change="v => $emit('change', v)"
    >
      <div class="wrb">
        <img
          src="../watermelon-refund/images/watermelon_desktop.png"
          alt=""
          width="548"
          height="216"
        />
        <p class="wrb__title" v-html="$t('t1')" />
        <p class="wrb__descr" v-html="$t('t2')" />
        <p class="wrb__descr" v-html="$t('t3')"></p>
        <UiButton
          variant="primary"
          :size="$isDesktop ? 'xl' : 'LG'"
          class="w-full font-bold mt-12 md:text-2xl text-base mb-8 md:mb-0"
          @click="close"
        >
          {{ $t('t4') }}
        </UiButton>
      </div>
    </UiBottomSheetPopup>
  </div>
</template>

<script>
import UiBottomSheetPopup from '@pure-ui/components/UiBottomSheetPopup/UiBottomSheetPopup.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'WatermelonRefundBottomSheet',
  components: { UiButton, UiBottomSheetPopup },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {},
  methods: {
    onBackClick() {
      this.close();
    },
    close() {
      this.$emit('change', false);
    },
  },
  i18n: {
    messages: {
      kk: {
        t1: 'Қарбыз ұнамаса — ақшаңызды қайтарамыз',
        t2: 'Біз қарбызды мұқият сұрыптап, оның жарамдылық мерзімін жіті қадағалаймыз.',
        t3: 'Егер сізде қарбызға байланысты мәселе туса, қамқорлық қызметіне хабарласыңыз немесе тапсырыс деталіндегі “Тапсырысқа байланысты мәселе” бөліміне жағдайды сипаттап жазыңыз. Біз өнімнің сапасына көңіл толмаған жағдайда ақшаны қайтаратынымызға кепілдік береміз.',
        t4: 'Біліп жүремін!',
      },
      ru: {
        t1: 'Что-то не так с арбузом? Гарантируем&nbsp;возврат',
        t2: 'Мы тщательно отбираем арбузы и следим за&nbsp;их&nbsp;сроками годности.',
        t3: 'Если у вас возникли проблемы с арбузом, свяжитесь со&nbsp;службой заботы или опишите проблему в разделе «Что-то не так с&nbsp;заказом» в&nbsp;деталях заказа. Мы&nbsp;гарантируем возврат денег в&nbsp;случае неудовлетворенности качеством продукта.',
        t4: 'Буду знать!',
      },
    },
  },
};
</script>

<style scoped>
.wrb {
  @apply flex justify-center flex-col pt-11 md:pt-0;
}

.wrb img {
  max-width: none;
  align-self: center;
}

.WatermelonRefundBottomSheet >>> .popup-dialog {
  background: linear-gradient(180deg, #ffe5e1 0%, #fff 100%);
}

.WatermelonRefundBottomSheet >>> .bottom-sheet-content {
  background: linear-gradient(180deg, #ffe5e1 0%, #fff 100%);
}

.wrb__title {
  @apply text-xl md:text-3xl font-bold leading-6 md:leading-30;
  max-width: 380px;
}
.wrb__descr {
  @apply md:mt-7 mt-5 text-base md:text-lg leading-6 text-sirius-black-400;
  line-height: 24px;
  max-width: 530px;
}

@media (max-width: 375px) {
  .wrb__descr {
    max-width: 330px;
  }
}

@media (max-width: 430px) {
  .wrb__descr {
    max-width: 360px;
  }
}
</style>
