<template>
  <transition name="fade" appear>
    <div
      class="fixed z-overlay inset-0 flex items-center justify-center bg-overlay ease-in duration-200 overflow-hidden"
    >
      <UiRippleLoader />
    </div>
  </transition>
</template>

<script>
import UiRippleLoader from '@ui/UiRippleLoader.vue';

export default {
  name: 'UiFullScreenLoader',
  components: {
    UiRippleLoader,
  },
};
</script>
