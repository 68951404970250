<template>
  <div class="AppStores">
    <a
      href="https://play.google.com/store/apps/details?id=kz.rahmet.app"
      target="_blank"
    >
      <img src="./images/google-play.png" width="154" />
    </a>
    <a
      href="https://apps.apple.com/us/app/%D1%80%D0%B0%D1%85%D0%BC%D0%B5%D1%82/id1346543292"
      target="_blank"
      class="AppStores__apple"
    >
      <img src="./images/apple-store.png" width="154" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppStores',
};
</script>

<style scoped>
.AppStores {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.AppStores__apple {
  justify-self: flex-end;
}

@media (min-width: 768px) {
  .AppStores {
    grid-template-columns: initial;
  }

  .AppStores__apple {
    grid-row: 1;
    justify-self: flex-start;
  }
}
</style>
