export const PRODUCT_ADDED = {
  CATEGORY: 'category',
  RECOMMENDATION: 'recommendation',
  SEARCH: 'search',
  MAIN: 'main',
  CART: 'cart',
  CATEGORY_SEARCH: 'category_search',
  PRODUCT: 'product',
  HISTORY: 'history',
  HISTORY_SLIDER_CART_PAGE: 'history_slider_cart_page',
  FAVORITES: 'favorites',
  RECIPE: 'recipe',
};

export const SCREENS = {
  [PRODUCT_ADDED.CATEGORY]: 'Category Page',
  [PRODUCT_ADDED.RECOMMENDATION]: 'Recomendation Page',
  [PRODUCT_ADDED.SEARCH]: 'Search Page',
  [PRODUCT_ADDED.MAIN]: 'Main Page',
  [PRODUCT_ADDED.CART]: 'Cart Page',
  [PRODUCT_ADDED.CATEGORY_SEARCH]: 'Category Search Page',
  [PRODUCT_ADDED.PRODUCT]: 'Product Page',
  [PRODUCT_ADDED.HISTORY]: 'History Page',
  [PRODUCT_ADDED.HISTORY_SLIDER_CART_PAGE]: 'History Slider Cart Page',
  [PRODUCT_ADDED.FAVORITES]: 'Favorites Page',
  [PRODUCT_ADDED.RECIPE]: 'Recipe Page',
};
