<template>
  <UiPopup
    :show="modelValue"
    :closable="$isDesktop"
    :radius="$isDesktop ? '4xl' : '3xl'"
    style="--popup-width-d: 588px; --popup-width: 343px"
    @change="$emit('update:modelValue', $event)"
    @onTriggeredModalClose="$emit('onTriggeredModalClose')"
  >
    <div class="CancelOrderPopup cancel-modal">
      <p class="cancel-modal-title">{{ $t('confirmOrderCanceling') }}</p>
      <p class="cancel-modal-desc" v-html="$t('moneyWillBeReturned')"></p>
      <p class="cancel-modal-desc" v-html="$t('moneyMayBeDelayed')"></p>
      <div class="cancel-modal-btns">
        <UiButton
          variant="secondary"
          :size="$isDesktop ? 'XL' : 'M'"
          @click="onCancel"
        >
          {{ $t('cancel') }}
        </UiButton>
        <UiButton :size="$isDesktop ? 'XL' : 'M'" @click="onConfirm">
          {{ $t('confirm') }}
        </UiButton>
      </div>
    </div>
  </UiPopup>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';

export default {
  name: 'CancelButton',
  components: {
    UiButton,
    UiPopup,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
  },
  methods: {
    onCancel() {
      this.$emit('update:modelValue', false);
      this.$emit('onCancel');
    },
    onConfirm() {
      this.$emit('update:modelValue', false);
      this.$emit('onConfirm');
    },
  },
  i18n: {
    messages: {
      ru: {
        confirm: 'Подтвердить',
        cancel: 'Закрыть',
        confirmOrderCanceling: 'Подтвердите отмену заказа',
        moneyWillBeReturned: `
          Оплаченные вами деньги&nbsp;за заказ вернутся.
        `,
        moneyMayBeDelayed: `
        Часто деньги приходят сразу, но&nbsp;могут задержаться <nobr>из-за&nbsp;правил банка,</nobr> в&nbsp;таком случае они&nbsp;придут в&nbsp;течение трёх&nbsp;рабочих дней.
        `,
      },
      kk: {
        confirm: 'Растау',
        cancel: 'Жабу',
        confirmOrderCanceling: 'Тапсырыстан бас тартуды растаңыз',
        moneyWillBeReturned: 'Тапсырыс үшін төлеген ақшаңыз қайтарылады',
        moneyMayBeDelayed: `
          Көбінесе ақша бірден түседі, бірақ банк ережелеріне байланысты
          кешіктірілуі мүмкін, бұл жағдайда ақша үш жұмыс күні ішінде түседі.
        `,
      },
    },
  },
};
</script>

<style scoped>
.cancel-modal {
  @apply mt-2;
}
.cancel-modal-title {
  @apply font-bold text-xl leading-6 md:leading-7 md:text-32;
}
.cancel-modal-desc {
  @apply mt-4 last:mt-3
    md:mt-7 md:last-of-type:mt-4 md:last-of-type:pt-0.5
    font-normal text-base leading-5 md:text-lg md:leading-6;
}
.cancel-modal-btns {
  @apply mt-6 md:mt-8 flex items-center space-x-2 md:space-x-3;
}
</style>
