<template>
  <div class="SwiperImageItem swiper-slide-wrapper">
    <div class="swiper-slide">
      <div
        class="swiper-item"
        :class="{
          '--right': index > currentSlide,
          '--left': index < currentSlide,
        }"
      >
        <img :src="img" alt="product-img" class="swiper-item-image" />

        <div v-if="review === 'like'" class="img-carousel-like">
          <img
            :src="IconLike"
            @click="index === currentSlide ? $emit('onReset') : ''"
          />
        </div>
        <div v-if="review === 'dislike'" class="img-carousel-dislike">
          <img
            :src="IconDislike"
            @click="index === currentSlide ? $emit('onReset') : ''"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLike from '@pure-ui/components/ProductReview/icons/like.png';
import IconDislike from '@pure-ui/components/ProductReview/icons/dislike.png';

export default {
  name: 'SwiperImageItem',
  props: {
    currentSlide: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      default: '',
    },
    review: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IconLike,
      IconDislike,
    };
  },
};
</script>

<style scoped>
.swiper-slide-wrapper {
  width: 100%;
  height: 376px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.swiper-slide {
  width: 100%;
  height: 216px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.swiper-item {
  position: absolute;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide .swiper-item,
.swiper-slide .swiper-item {
  width: 80px;
  height: 112px;
  transform: scale(80 / 216);
}

.swiper-slide-active .swiper-slide .swiper-item {
  width: 216px;
  height: 216px;
  transform: scale(1);
  z-index: 10;
}

.--left .swiper-item-image {
  transform: translateX(-32px);
}
.--right .swiper-item-image {
  transform: translateX(32px);
}
.swiper-item-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  mix-blend-mode: multiply;
}

.img-carousel-like,
.img-carousel-dislike {
  position: absolute;
  top: 50%;
  right: 32px;
  width: 32px;
  height: 32px;

  transform: translateY(+20%);
  transition: transform 500ms;

  border-radius: 50%;
  background: #ffa100;
  /* backdrop-filter: blur(4px); */

  display: flex;
  align-items: center;
  justify-content: center;
}
.--left .img-carousel-like,
.--left .img-carousel-dislike {
  right: 32px;
}
.--right .img-carousel-like,
.--right .img-carousel-dislike {
  right: -32px;
}
.swiper-slide-active .img-carousel-like,
.swiper-slide-active .img-carousel-dislike {
  transform: translateY(-50%) scale(2);
}
.img-carousel-like img,
.img-carousel-dislike img {
  width: 15px;
  height: 20px;
  object-fit: contain;
  /* padding-bottom: 1px; */
}

@media (max-width: 360px) {
  .hooper-slide.is-current .swiper-item {
    width: 192px;
    height: 192px;
  }
  .hooper-slide .swiper-item {
    width: 64px;
    height: 96px;
    transform: scale(64 / 192);
  }
}
</style>
