import { EventEmitter } from '@services/emitter';

export const DELIVERY_EVENTS = {
  ON_WAREHOUSE_CHANGE: 'ON_WAREHOUSE_CHANGE',
  ON_WAREHOUSE_ID_CHANGE: 'ON_WAREHOUSE_ID_CHANGE',
  /** PaidDeliveryModal */
  OPEN_DELIVERY_CONDITIONS_MODAL: 'OPEN_DELIVERY_CONDITIONS_MODAL',
};

export const DeliveryEmitter = new EventEmitter();
