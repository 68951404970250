<template>
  <div class="space-y-4">
    <TipsCourierView
      :name="name"
      :picture="picture"
      :reaction-text="reactionText"
    />
    <TipsListView
      :predefined-tips="predefinedTips"
      :is-not-leave-first="isNotLeaveFirst"
      @onChangeTipPrice="onChangeTipPrice"
      @onChangeTipType="onChangeTipType"
    />
  </div>
</template>

<script>
import { AIMS, TIPS } from '../constants';
import { i18n } from '../i18n';
import TipsCourierView from './TipsCourierView.vue';
import TipsListView from './TipsListView.vue';

export default {
  nmae: 'TipsBlock',
  components: {
    TipsCourierView,
    TipsListView,
  },
  props: {
    courier: {
      type: Object,
      required: true,
    },
    isNotLeaveFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      predefinedTips: [],
      selectedTipType: null,
      reactionLoader: false,
      timer: null,
      TIPS,
    };
  },
  computed: {
    picture() {
      return this.courier.picture;
    },
    name() {
      return this.courier.name || this.$t('ryadomCourier');
    },
    randomAims() {
      return this.$t(this.randomItemFromArray(AIMS));
    },
    randomReaction() {
      if (!this.selectedTipType) return false;

      const { messages } = this.selectedTipType;
      return this.$t(this.randomItemFromArray(messages));
    },
    reactionText() {
      if (this.reactionLoader) return '';
      return this.randomReaction || this.courier.savingFor || this.randomAims;
    },
  },
  mounted() {
    this.predefinedTips = Object.values(this.TIPS.GROUP_CONTROL_V2);
  },
  methods: {
    randomItemFromArray(array) {
      return array ? array[Math.floor(Math.random() * array.length)] : '';
    },
    onChangeTipPrice(price) {
      clearTimeout(this.timer);
      this.reactionLoader = true;
      this.$emit('onChangeTipPrice', price);

      this.timer = setTimeout(() => {
        this.reactionLoader = false;
      }, 600);
    },
    onChangeTipType(value) {
      this.selectedTipType = this.TIPS.GROUP_CONTROL_V2[value];
    },
  },
  i18n,
};
</script>
