import { Analitycs, EVENTS } from '@shared/services/analitycs';
import Vue from 'vue';
import UiToast from './UiToast.vue';

function toastApp() {
  const state = Vue.observable({
    id: 0,
    toasts: [],
  });

  return {
    toast: {
      show(content, status = null) {
        const { id } = state;

        state.id += 1;
        state.toasts.push({ id, content, status });
        if (content === 'Авторизуйтесь в Choco') {
          Analitycs.logEvent(EVENTS.LOGIN_MSG);
        }
        setTimeout(() => {
          state.toasts = state.toasts.filter(x => x.id !== id);
        }, 3000);
      },
    },
    mount() {
      new Vue({
        data: state,
        render(h) {
          return h(
            'div',
            {
              class: `UiToasts w-full px-4 z-toast fixed top-3
                  flex flex-col items-center md:items-end justify-center 
                  space-y-2 pointer-events-none`,
            },
            this.toasts.map(toast =>
              h(UiToast, {
                on: {
                  click: () => {
                    this.toasts = this.toasts.filter(x => x.id !== toast.id);
                  },
                },
                props: {
                  status: toast.status,
                  content: toast.content,
                },
              })
            )
          );
        },
      }).$mount('#toast-app');
    },
  };
}

export const ToastApp = toastApp();
