<template>
  <div class="HorizontalScroll horizontal-scroll">
    <div class="horizontal-scroll-links hidden-scrollbar">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.scrollToActive();
  },
  methods: {
    scrollToActive() {
      let links = document.querySelector('.horizontal-scroll-links');
      let link = document.querySelector(
        '.horizontal-scroll-links > div.--active'
      );
      if (!links || !link) return;
      links.scrollTo({
        left: link.getBoundingClientRect().left - 20,
      });
    },
  },
};
</script>

<style scoped>
.horizontal-scroll {
  border-right: 16px solid transparent;
  border-left: 16px solid transparent;
}
.horizontal-scroll-links {
  @apply flex items-center space-x-5
  overflow-x-scroll overscroll-auto;
  border-bottom: 0.5px solid #d4d3df;
}
.horizontal-scroll-links div {
  min-width: fit-content;
}
</style>
