<template>
  <div class="LegalBlock">
    <a
      class="LegalBlock__userAgreement"
      href="https://docs.google.com/document/d/1tcASBHbX1TAZmr4rSD5PJSfkhJOtRT-O/"
      target="_blank"
      v-html="$t('agreement')"
    />
    <p class="LegalBlock__allRightsReserved" v-html="$t('reserved')" />
  </div>
</template>

<script>
export default {
  name: 'LegalBlock',
  i18n: {
    messages: {
      kk: {
        agreement: 'Қолданушы келесімі',
        reserved: '© Барлық құқық сақталған',
      },
      ru: {
        agreement: 'Пользовательское соглашение',
        reserved: '© Все права защищены',
      },
    },
  },
};
</script>

<style scoped>
.LegalBlock__userAgreement {
  color: #000;
  text-decoration: none;
}

.LegalBlock__userAgreement:hover {
  color: #ffa100;
}

.LegalBlock__allRightsReserved {
  margin-top: 16px;
  color: #7b7a8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.07px;
}

@media screen and (min-width: 1188px) {
  .LegalBlock {
    font-size: 14px;
    line-height: 22px;
  }

  .LegalBlock__allRightsReserved {
    margin-top: 20px;
  }
}
</style>
