import { EventEmitter } from '@services/emitter';

export const CART_EVENTS = {
  /**
   * Эмитится после успешного получения товаров корзины
   */

  ON_CART_FETCHED: 'ON_CART_FETCHED',
  /**
   * Эмитится после успешных оформления заказа и оплаты
   */
  ON_PAYMENT_DONE: 'ON_PAYMENT_DONE',

  /**
   * Эмитится после успешных оформления заказа и оплаты
   */
  ON_CART_CHANGED: 'ON_CART_CHANGED',
};

export const CartEmitter = new EventEmitter();
