export const NyStatModule = {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    loaded(state) {
      const d = state.data || {};
      return d.order_count && d.time_count && d.weight;
    },
    data(state) {
      return state.data;
    },
  },
  mutations: {
    SET_DATA(state, value) {
      state.data = value;
    },
  },
};
