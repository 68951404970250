import { client } from '../../clients';

export default function (role_code) {
  return client
    .get('/lavka-faq/v1/categories', {
      params: {
        role: role_code,
        role_code: role_code,
      },
    })
    .then(res => res.data);
}
