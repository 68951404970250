<template>
  <div v-if="categories.length" class="SpecialCategoires">
    <UiNavigationTitle
      v-secret-click="toggleConsoleButton"
      class="px-4 md:px-0"
      main
    >
      {{ $t('title') }}
    </UiNavigationTitle>

    <UiSwiper
      v-if="$isDesktop"
      :slides="categories"
      :settings="settings"
      navigation
      class="special-category-swiper"
    >
      <template #slide="category">
        <SpecialCategory
          class="w-full"
          :category="category"
          :parent-id="specialCategories.id"
        />
      </template>
    </UiSwiper>
    <div v-else class="special-category-swiper-mob hidden-scrollbar">
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="special-category-swiper-mob-item"
      >
        <SpecialCategory
          :category="category"
          :parent-id="specialCategories.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { shuffleArray } from '@/utils/functions/shuffle-array';
import { getFilteredSpecialCategories } from '@/lib/coffee/get-filtered-special-categories';
import SecretClick from '@/utils/directives/secret-click';

import UiSwiper from '@pure-ui/components/UiSwiper/UiSwiper.vue';
import UiNavigationTitle from '@ui/UiNavigationTitle.vue';
import SpecialCategory from './SpecialCategory.vue';

export default {
  name: 'SpecialCategoires',
  directives: { SecretClick },
  components: {
    UiNavigationTitle,
    SpecialCategory,
    UiSwiper,
  },
  data() {
    return {
      categories: [],
      settings: {
        slidesPerView: 3,
      },
    };
  },
  computed: {
    ...mapState('catalog', ['specialCategories']),
  },
  watch: {
    specialCategories: {
      immediate: true,
      deep: true,
      handler(v) {
        const l = v.categories || [];

        const isFixed = v => String(v.sort_weight).startsWith('777');

        const fixed = l.filter(v => isFixed(v));
        const shuffled = shuffleArray(l.filter(v => !isFixed(v)));

        this.categories = getFilteredSpecialCategories([
          ...fixed,
          ...shuffled,
        ]).filter(e => e.has_product);
      },
    },
  },
  methods: {
    toggleConsoleButton() {
      this.$store.commit('logger/TOGGLE_BUTTON_VISIBILITY');
    },
  },
  i18n: {
    messages: {
      ru: { title: 'Специальные предложения' },
      kk: { title: 'Арнайы ұсыныстар' },
    },
  },
};
</script>

<style scoped>
.special-category-swiper {
  @apply mt-5;
  height: 152px;
}
.special-category-swiper-mob {
  @apply mt-3 pl-4 w-full 
  flex items-center overflow-scroll;
}
.special-category-swiper-mob-item {
  @apply mr-2 last:border-r-8 border-transparent;
}
</style>
