import { EventEmitter } from '@services/emitter';

export const ABTEST_EVENTS = {
  /**
   * Эмитится при получении тестов
   */
  ON_FETCH_TESTS: 'ON_FETCH_TESTS',
};

export const ABTestEmitter = new EventEmitter();
