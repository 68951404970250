<template>
  <div class="MmMHeader">
    <div class="MmMHeader__wrapper">
      <div class="MmMHeader__container">
        <button class="focus:outline-none" @click="$emit('onMenuClick')">
          <img :src="icon" alt="" />
        </button>
        <img
          class="shrink-0 ml-auto mr-auto"
          src="@/views/micromarket/images/logo.png"
          alt=""
          width="139"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: new URL('@/views/micromarket/images/burger.svg', import.meta.url)
        .href,
    };
  },
};
</script>

<style scoped>
.MmMHeader {
  background: rgba(255, 255, 255, 0.8);
  position: sticky;
  top: 0;
  backdrop-filter: blur(4px);
  z-index: 1;
}

.MmMHeader__wrapper {
  margin: auto;
  width: calc(100% - 32px);
  max-width: 535px;
  box-sizing: content-box;
  border-bottom: 0.5px solid var(--main-grey, #d4d3df);
  padding-top: 16px;
  padding-bottom: 16px;
}

.MmMHeader__container {
  display: flex;
  align-items: center;
  padding-right: 40px;
}
</style>
