import { store } from '@/store';
import { rFetchSamplings } from '@shared/services/api/apis';
import { Logger as LOG } from '@shared/services/logger';
import { CartService } from '@services/cart';

import { getObjectDifferences } from './utils/object-differences';
import { SAMPLINGS_EVENTS, SamplingsEmitter } from './events';
import { arrayDifference } from './utils/array-deifference';
import { ProductBDService } from '@services/product-db';

/**
 * @typedef AdditionalProduct Продукт который нужно добрать для семплинга
 * @prop {Number} product_id айди товара
 * @prop {Number} quantity количество товара которое нужно добрать
 *
 *
 * @typedef Sample
 * @prop {Number} id
 * @prop {String} type product | category | any
 * @prop {Number} sample_product_id айди семплового товара
 * @prop {Number?} sample_price цена семплового товара
 * @prop {Number} applied_count сколько семплов добавлено
 * @prop {null | AdditionalProduct[]} getting_to_apply Сколько нужно добрать для семпла
 */

let lastWarehouseId = 1;

const getWarehouseId = () => {
  return store.getters['delivery/warehouseId'];
};
const getCartProducts = () => {
  return [...store.getters['cart/items']];
};
const getAddedProduct = id => {
  if (!id) return null;
  const cart = getCartProducts();
  const product = cart.find(item => item.product_id === id);
  return { ...product } || null;
};
const setSamples = value => {
  store.commit('samplings/SET_SAMPLES', value);
};
const resetSamlpes = () => {
  store.commit('samplings/SET_SAMPLES', []);
};
const getSamples = () => {
  return [...store.getters['samplings/samples']];
};

const resetAll = () => {
  store.commit('samplings/RESET_ALL');
};

function openModal(data) {
  SamplingsEmitter.emit(SAMPLINGS_EVENTS.ON_OPEN_MODAL, data);
}

/**
 *  Выносим все айдишки товаров из обеъкта Sample, это семплы и это доп товары для добора
 * @param {Sample[]} samples массив семплов
 * @returns {Number[]} айди товаров
 */
function extractProductIds(samples) {
  const ids = [];

  samples.forEach(sample => {
    ids.push(sample.sample_product_id);

    const gettings = sample.getting_to_apply;
    if (gettings && gettings.length) {
      gettings.forEach(product => ids.push(product.product_id));
    }
  });
  return ids;
}

/**
 * Тут мы получаем все инфу товаров по айдишникам которые были в объекте семплов
 * и сохраняем их в store ProductDBModule для кеширования
 * @param {Sample[]} samples семплы
 * @returns
 */
async function loadProductsInfo(samples) {
  let ids = extractProductIds(samples);
  LOG.event('Samplings', { samples, ids }, 'loadProductsInfo');
  await ProductBDService.loadProductsByIds(ids);
}

/**
 *
 * @param {Sample[]} oldSamples
 * @param {Sample[]} newSamples
 * @returns
 */
export function checkDifferences(oldSamples, newSamples) {
  let hasChanges = false;
  const added = [];
  const removed = [];
  const changed = [];

  oldSamples = oldSamples && oldSamples.length ? oldSamples : [];
  newSamples = newSamples && newSamples.length ? newSamples : [];

  const oldIds = oldSamples.map(e => e.id);
  const newIds = newSamples.map(e => e.id);
  const diffIds = arrayDifference(oldIds, newIds);

  newSamples.forEach(ns => {
    const oldSample = oldSamples.find(os => os.id === ns.id);
    if (oldSample) {
      const diffs = getObjectDifferences(oldSample, ns);
      if (Object.keys(diffs).length) changed.push({ ...ns, diffs });
    }
  });

  if (diffIds.length) {
    newSamples.forEach(sample => {
      if (diffIds.includes(sample.id)) added.push(sample);
    });
    oldSamples.forEach(sample => {
      if (diffIds.includes(sample.id)) removed.push(sample);
    });
  }

  hasChanges = !!(added.length || removed.length || changed.length);

  return { hasChanges, samples: newSamples, added, removed, changed };
}

const getAdditionalProducts = (products, originalProducts) => {
  if (!products || !products.length) return [];
  if (originalProducts.length === 1 && originalProducts[0].quantity === 1) {
    return [];
  }

  return products.map(product => {
    return {
      ...ProductBDService.getProductByKey(product.product_id),
      quantity: product.quantity,
    };
  });
};

function buildPayloadForPresentation(addedProductId, formatedSamples) {
  const addedProduct = getAddedProduct(addedProductId);

  let sample = null;
  const added = formatedSamples.added[0];
  const changed = formatedSamples.changed[0];
  if (added) {
    sample = { ...added, triggeredType: 'added' };
  } else if (
    changed?.diffs?.applied_count &&
    changed.diffs.applied_count[1] > changed.diffs.applied_count[0]
  ) {
    sample = { ...changed, triggeredType: 'reached' };
  } else sample = { ...changed, triggeredType: 'changed' };

  const sampling = ProductBDService.getProductByKey(sample.sample_product_id);
  sampling.price = sample.sample_product_price || 1;
  sampling.quantity = sample.applied_count;

  const additionalProducts = getAdditionalProducts(
    sample.getting_to_apply,
    sample.products_to_apply
  );

  return {
    sample,
    sampling,
    addedProduct,
    additionalProducts,
  };
}
function filterUnAppliedSamples(samples) {
  if (!samples || !samples.length) return [];
  return samples.filter(
    s => !(s.getting_to_apply === null && s.applied_count === 0)
  );
}
async function formatSampleRequest(newSamples, addedProductId) {
  const oldSamples = getSamples();
  oldSamples.map(s => delete s.diffs);
  newSamples = filterUnAppliedSamples(newSamples);
  const formatedSamples = checkDifferences(oldSamples, newSamples);

  setSamples(formatedSamples.samples);
  LOG.event('formatSampleRequest', formatedSamples, 'SamplingsService');
  const changes = [...formatedSamples.changed, ...formatedSamples.added];
  if (changes.length) await loadProductsInfo(changes);

  if (addedProductId && changes.length) {
    const payload = buildPayloadForPresentation(
      addedProductId,
      formatedSamples
    );
    LOG.event('OpenModal', payload, 'SamplingsService');
    openModal(payload);
  }
  return Promise.resolve();
}

function formatRequestProducts(products, key = 'product_id') {
  return products.map(item => {
    return {
      product_id: +item[key],
      feature_id: item.feature_id || null,
      quantity: +item.quantity,
    };
  });
}

function resetIfWarehouseChanged() {
  const warehouseId = getWarehouseId();
  const isWarehouseChanged = lastWarehouseId !== warehouseId;
  LOG.event('resetIfWarehouseChanged', { isWarehouseChanged }, 'Samplings');
  if (isWarehouseChanged) resetAll();
  lastWarehouseId = warehouseId;
}

function loadSamplings(addedProductId) {
  const warehouseId = getWarehouseId();
  resetIfWarehouseChanged();

  const products = formatRequestProducts(getCartProducts());
  if (!products || !products.length) {
    resetSamlpes();
    return Promise.resolve([]);
  }

  return new Promise(resolve => {
    rFetchSamplings(products, warehouseId)
      .then(res => res.data)
      .then(res => formatSampleRequest(res, addedProductId))
      .then(res => resolve(res))
      .catch(err => {
        LOG.event('LoadSamplings Err', err, 'Samplings');
        console.error(err);
        resolve([]);
      });
  });
}

/**
 * Добваляем в корзину все
 * @param {Product[]} products
 * @returns
 */
function addAdditionalProducts(products) {
  const cartProducts = formatRequestProducts(getCartProducts());
  products = products.filter(p => p.max_quantity > 0);
  const additionalProducts = formatRequestProducts(products, 'id');
  products = [...cartProducts, ...additionalProducts];

  return CartService.applyProducts(products)
    .then(() => loadSamplings())
    .catch(err => {
      console.error(err);
    });
}

export const SamplingsService = {
  addAdditionalProducts,
  loadSamplings,
  resetSamlpes,
  openModal,
};
