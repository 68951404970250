import { ORDER_STATUSES } from '../constants/index';

export const getStatusText = status => {
  switch (status) {
    case ORDER_STATUSES.CREATED:
    case ORDER_STATUSES.READY:
      return {
        color: 'bg-sirius-orange-100 bg-opacity-10 text-sirius-orange-100',
        title: {
          ru: 'оформлен',
          kk: 'рәсімделді',
        },
      };
    case ORDER_STATUSES.IS_BEING_FORMED_BY_PICKER:
      return {
        color: 'bg-sirius-orange-100 bg-opacity-10 text-sirius-orange-100',
        title: {
          ru: 'собирается',
          kk: 'жиналып жатыр',
        },
      };
    case ORDER_STATUSES.FORMED_BY_PICKER:
      return {
        color: 'bg-sirius-orange-100 bg-opacity-10 text-sirius-orange-100',
        title: {
          ru: 'собран',
          kk: 'жиналды',
        },
      };
    case ORDER_STATUSES.GIVEN_TO_COURIER:
    case ORDER_STATUSES.ARRIVED_TO_ADDRESS:
      return {
        color: 'bg-sirius-orange-100 bg-opacity-10 text-sirius-orange-100',
        title: {
          ru: 'у курьера',
          kk: 'курьерде',
        },
      };
    case ORDER_STATUSES.FINISHED:
    case ORDER_STATUSES.DELIVERED:
      return {
        color: 'bg-sirius-green-100 bg-opacity-10 text-sirius-green-100',
        title: {
          ru: 'доставлен',
          kk: 'жеткізілді',
        },
      };
    case ORDER_STATUSES.CANCELED:
      return {
        color: 'bg-sirius-red-200 bg-opacity-10 text-sirius-red-200',
        title: {
          ru: 'отменен',
          kk: 'жойылды',
        },
      };
    default:
      return {
        color: '',
        title: {
          ru: '',
          kk: '',
        },
      };
  }
};
