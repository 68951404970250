<template>
  <div class="CancelOrder">
    <CancelOrderPopup
      v-model="confirmModal"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
      @onTriggeredModalClose="onCancel('confirmation')"
    />
    <CancelOrderReasonModal
      v-model="reasonModal"
      :reasons="reasons"
      :comment="comment"
      @onUpdateReason="onUpdateReason"
      @onSubmitReason="onSubmitReason"
      @onInputComment="v => (comment = v)"
      @onTriggeredModalClose="onCancel('reason')"
    />
  </div>
</template>

<script>
import CancelOrderReasonModal from './CancelOrderReasonModal/CancelOrderReasonModal.vue';
import CancelOrderPopup from './CancelOrderPopup/CancelOrderPopup.vue';

export default {
  name: 'CancelOrder',
  components: {
    CancelOrderReasonModal,
    CancelOrderPopup,
  },
  props: {
    reasons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      confirmModal: false,
      reasonModal: false,
      comment: '',
    };
  },
  methods: {
    closeModals() {
      this.confirmModal = false;
      this.reasonModal = false;
    },
    openConfirmModal() {
      this.confirmModal = true;
    },
    onCancel(event) {
      this.$emit('onCancel', event);
    },
    onConfirm(event) {
      this.reasonModal = true;
      this.$emit('onConfirm', event);
    },
    onUpdateReason(event) {
      this.$emit('onUpdateReason', event);
    },
    onSubmitReason(event) {
      this.reasonModal = false;
      this.$emit('onSubmitReason', { selected: event, comment: this.comment });
    },
  },
};
</script>

<style scoped></style>
