import { ClientService } from '@/shared/services/client';
import { client } from '../client/client';
import { v4 as uuidv4 } from 'uuid';

/**
 * Отправляет пользователю SMS с кодом
 * для подтверждения удаления
 */
export function requestSMScode() {
  const { accessToken } = ClientService.getContext();
  const requestId = uuidv4();

  return new Promise((resolve, reject) => {
    // @see https://swaggerui2.chocodev.kz/?urls.primaryName=user_v2#/User/v3-delete-user
    client({
      url: '/api/v3/user',
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        data: {
          attributes: {
            dispatch_type: 'sms',
          },
          id: requestId,
          type: 'code',
        },
      },
    })
      .then(() => {
        resolve({ status: 'ok', removeRequestId: requestId });
      })
      .catch(err => {
        if (err.response?.data?.errors[0].code === '144') {
          return resolve({
            status: 'too_many_requests',
            remaining_at: err.response?.data?.errors[0].meta.remaining_at,
          });
        }
        reject(err);
      });
  });
}
