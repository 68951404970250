<template>
  <div class="ContestRules">
    <div class="ContestRules__container">
      <template v-if="$i18n.locale === 'ru'">
        <NyPageSubtitle id="ny-rules">Правила конкурса</NyPageSubtitle>
        <ul>
          <li>
            В&nbsp;случае если&nbsp;несколько участников набирают одинаковое
            количество баллов, выше в&nbsp;рейтинге оказывается
            тот&nbsp;пользователь, который раньше совершил финальную покупку;
          </li>
          <li>
            За 1&nbsp;заказ можно получить только 1&nbsp;дополнительный балл;
          </li>
          <li>
            В&nbsp;случае возврата по&nbsp;заказу (частичного или&nbsp;полного)
            полученные баллы аннулируются;
          </li>
          <li>Баллы нельзя переносить с&nbsp;аккаунта на&nbsp;аккаунт;</li>
          <li>
            Сотрудники ChocoFamily, их супруги и&nbsp;близкие родственники
            не&nbsp;могут участвовать в&nbsp;конкурсе;
          </li>
          <li>
            Организатор вправе изменить условия, срок действия и&nbsp;механику
            розыгрыша;
          </li>
          <li>
            Организатор вправе поменять оглашённые призы на&nbsp;другие,
            эквивалентные по&nbsp;стоимости;
          </li>
          <li>
            Организатор имеет право аннулировать результаты розыгрыша
            в&nbsp;случае обнаружения признаков мошенничества;
          </li>
          <li>
            При&nbsp;несоблюдении условий участия в&nbsp;розыгрыше, выигравший
            участник будет дисквалифицирован, а&nbsp;приз будет предоставлен
            следующему участнику в&nbsp;рейтинге, не&nbsp;вошедшему
            в&nbsp;ТОП–50.
          </li>
        </ul>
      </template>
      <template v-if="$i18n.locale === 'kk'">
        <NyPageSubtitle id="ny-rules">Конкурс ережесі</NyPageSubtitle>
        <ul>
          <li>
            Бірнеше қатысушы бірдей ұпай жинаған жағдайда, рейтингтің жоғары
            жағына финалдық сатып алуды бұрын жасаған қолданушы өтеді;
          </li>
          <li>1 тапcырыс үшін тек 1 қосымша ұпай беріледі;</li>
          <li>
            Тапсырысты қайтарған жағдайда (толық немесе ішінара) жинаған ұпайлар
            жойылады;
          </li>
          <li>Ұпайларды бір аккаунттан екіншісіне алмастыруға болмайды;</li>
          <li>
            ChocoFamily қызметкерлері, олардың жұбайы мен жақын туыстары
            конкурсқа қатыса алмайды;
          </li>
          <li>
            Ұйымдастырушы ұтыс ойнының шарттарын, мерзімі мен механикасын
            өзгертуге құқылы;
          </li>
          <li>
            Ұйымдастырушы сыйлықтарды құны сәйкес келетін өзге сыйлыққа
            алмастыруға құқылы;
          </li>
          <li>
            Ұйымдастырушы алаяқтық белгілерін байқаған жағдайда, ұтыс ойнының
            нәтижесін жоюға құқылы;
          </li>
          <li>
            Ұтыс ойнына қатысу шарттары сақталмаған жағдайда, жеңген қатысушы
            ойыннан шығарылады, ал сыйлық ТОП–50-ге енбеген рейтингте келесі
            тұрған қатысушыға табысталады.
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import NyPageSubtitle from '@/views/ny-2023-contest/components/NyPageSubtitle/NyPageSubtitle.vue';

export default {
  name: 'ContestRules',
  components: {
    NyPageSubtitle,
  },
};
</script>

<style scoped>
.ContestRules {
  padding-top: 72px;
}

.ContestRules__container {
  width: calc(100% - 32px);
  max-width: 984px;
  margin: auto;
  padding: 0 21px;
}

.ContestRules ul {
  padding-top: 16px;
  color: #767485;
}

.ContestRules li {
  position: relative;
}

.ContestRules li::before {
  content: '•';
  position: absolute;
  top: 0;
  left: -16px;
}

.ContestRules li + li {
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .ContestRules {
    padding-top: 128px;
  }

  .ContestRules ul {
    columns: 2;
    column-gap: 64px;
    padding-top: 32px;
  }
}
</style>
