export const LINKS = [
  {
    name: 'links.aboutUs',
    path: 'https://landing.choco.kz/',
  },
  {
    name: 'links.app',
    path: 'https://rahmetapp.kz/webapp/1',
  },
  {
    name: 'links.questions',
    path: '/faq',
    inner: true,
  },
  {
    name: 'links.toSuppliers',
    path: 'https://landing.choco.kz/supplier',
  },
  {
    name: 'links.vacancies',
    path: 'https://job.ryadom.kz/courier?utm_source=ryadom&utm_medium=link&utm_campaign=header-menu',
  },
  {
    name: 'links.realProperty',
    path: 'https://landing.choco.kz/warehouse',
  },
];

export const LINKS_I18N = {
  messages: {
    ru: {
      links: {
        aboutUs: 'О нас',
        app: 'Приложение',
        questions: 'Вопросы и ответы',
        toSuppliers: 'Поставщикам',
        vacancies: 'Курьерам',
        realProperty: 'Помещение',
      },
    },
    kk: {
      links: {
        aboutUs: 'Біз туралы',
        app: 'Қолдаңба',
        questions: 'Сұрақтар мен жауаптар',
        toSuppliers: 'Жеткізушілерге',
        vacancies: 'Курьерге',
        realProperty: 'Бөлме',
      },
    },
  },
};
