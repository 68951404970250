import { capitalize } from '@/utils/formatters/words';
import { CityService } from '@services/city';

/**
 * @typedef Options
 * @prop {String} lang
 * @prop {Boolean} onlyName
 * @prop {Boolean} withName
 * @prop {Boolean} withCity
 */

/**
 * @param {*} address
 * @param {Options} options
 * @returns
 */
export function getAddressText(address, options) {
  if (!address) return '';
  // lang = 'ru', withName = true, withCity: true, onlyName: true
  let result = '';
  let addressTexts = ['Адрес', 'Мекен-жай', 'Мекенжай'];
  let comma = ', ';

  let { name: n, street, flat_number: kv, city_id: cityId } = address;

  if (options.onlyName) {
    let name = n && n !== street && !addressTexts.includes(n) ? n : '';
    return name;
  }

  if (options.withCity) {
    let city = CityService.findCityById(cityId);
    result = city.name;
  }

  if (options.withName) {
    let name = n && n !== street && !addressTexts.includes(n) ? n : '';
    if (name) result = result ? result + comma + name : capitalize(name);
  }

  result = result ? result + comma + street : capitalize(street);

  if (kv) {
    let kvText = options.lang === 'kk' ? kv + ' пәтер' : 'кв.' + kv;
    result = result + comma + kvText;
  }

  return result;
}
