import { client } from '../../clients';

export default function ({ mock = false }) {
  if (mock) {
    return import('./mocks/active-promocodes.json');
  }
  return client
    .get(`/lavka-promoaction/v1/profile/promocodes/list/ready_to_activate`)
    .then(r => r.data);
}
