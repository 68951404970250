import { ClientService } from '@shared/services/client';

export function injectDeviceId(config) {
  const { deviceId } = ClientService.getContext();

  if (config.useDeviceId && deviceId) {
    switch (config.method.toUpperCase()) {
      case 'GET':
        config.params = {
          ...config.params,
          device_id: deviceId,
        };
        break;
      case 'POST':
      case 'PUT':
        config.transformRequest.unshift(data => {
          return { ...data, device_id: deviceId };
        });
        break;
      default:
    }
  }

  return config;
}
