<template>
  <div class="circles">
    <div
      v-for="item in length"
      :key="item"
      class="circle"
      :class="{
        '--active': active + 1 === item,
        '--done': done.includes(item - 1),
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'CarouselPagination',
  props: {
    length: {
      type: Number,
      default: 5,
    },
    active: {
      type: Number,
      default: 0,
    },
    done: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.circles {
  @apply flex items-center justify-center;
}
.circle {
  width: 8px;
  height: 8px;
  margin: 0px 8px;
  gap: 8px;
  border-radius: 12px;
  background: #f1f2f7;
  transition: all 0.3s;
}

.circle.--active {
  width: 16px;
  background: #acaaba;
}
.circle.--done {
  background: rgba(255, 161, 0, 0.1);
}
.circle.--active.--done {
  background: rgba(255, 161, 0, 1);
}
</style>
