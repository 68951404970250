<template>
  <div
    class="PreheatAlert py-2 px-4 flex rounded-xl items-center"
    style="background: #fc9f021a; color: #fc9f02"
  >
    <IconPreheat class="mr-2" style="min-width: 20px" />
    <span class="text-sm whitespace-nowrap text-ellipsis overflow-hidden">
      {{ $t('warmFoodBeforeEat') }}
    </span>
  </div>
</template>

<script>
import IconPreheat from '@shared/components/icons/IconPreheat.vue';

export default {
  name: 'PreheatAlert',
  components: {
    IconPreheat,
  },
  i18n: {
    messages: {
      ru: {
        warmFoodBeforeEat: 'Разогрейте еду перед употреблением',
      },
      kk: {
        warmFoodBeforeEat: 'Тамақтанар алдында тағамды жылытыңыз',
      },
    },
  },
};
</script>
