<template>
  <button
    v-if="isDesktop"
    class="p-3 w-max focus:outline-none rounded-xl font-medium text-lg"
    :class="[
      active
        ? 'bg-sirius-orange-100 text-white'
        : 'bg-sirius-gray-200 text-sirius-black-100',
    ]"
    @click="onClick"
  >
    {{ title }}
  </button>
  <button
    v-else
    class="h-9 px-2 mr-2 mb-2 w-max focus:outline-none rounded-xl"
    :class="[
      active
        ? 'bg-sirius-orange-100 text-white'
        : 'bg-sirius-gray-200 text-sirius-black-100',
    ]"
    @click="onClick"
  >
    {{ title }}
  </button>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Tag',
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState(['isDesktop']),
    title() {
      return this.item.name.replace(' .', '');
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.item);
    },
  },
};
</script>
