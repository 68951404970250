<template>
  <div class="UiRadio">
    <div
      v-for="(item, index) in radios"
      :key="index"
      class="UiRadio__item"
      @click="onClick(item)"
    >
      <span class="UiRadio__itemText" v-html="item[text]" />
      <UiRadioCircle
        v-bind="{
          modelValue: item[valueId] === (modelValue && modelValue[valueId]),
        }"
        class="UiRadio__itemIcon"
      />
    </div>
  </div>
</template>

<script>
import UiRadioCircle from '@/pure-ui/components/UiRadioCircle/UiRadioCircle.vue';

export default {
  name: 'UiRadio',
  components: { UiRadioCircle },
  inject: [],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    radios: {
      type: Array,
      default: () => [],
    },
    valueId: {
      type: String,
      default: 'id',
    },
    text: {
      type: String,
      default: 'title',
    },
  },
  data: () => ({}),
  methods: {
    onClick(v) {
      this.$emit('update:modelValue', v);
    },
  },
};
</script>

<style scoped>
.UiRadio {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.UiRadio__item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.UiRadio__itemText {
  padding-right: 8px;
}

.UiRadio__itemIcon {
  flex-shrink: 0;
}

@media screen and (min-width: 768px) {
  .UiRadio__item {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .UiRadio__itemText {
    padding-left: 16px;
  }
}
</style>
