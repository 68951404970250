<template>
  <search v-click-outside="onClickOutside" class="SearchTrigger search-trigger">
    <label for="searchTrigger" class="search-trigger-content">
      <UiInlineIcon name="loupe" width="24" class="search-trigger-icon" />

      <input
        id="searchTrigger"
        ref="searchTrigger"
        :value="value"
        v-bind="$attrs"
        type="search"
        name="search"
        :placeholder="placeholder || $t('findProducts')"
        class="search-trigger-input"
        tabindex="0"
        v-on="$listeners"
        @input="$emit('onInput', $event.target.value)"
      />

      <transition name="scale">
        <button
          v-if="value.length > 0"
          class="search-trigger-cancel"
          style="--scale: 0.1"
          @click="$emit('onInput', '')"
        >
          <UiInlineIcon name="cancel" width="24" height="24" />
        </button>
      </transition>
    </label>
    <div v-if="disabled" class="search-trigger-cover"></div>
  </search>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import vClickOutside from 'v-click-outside';

export default {
  name: 'SearchTrigger',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    UiInlineIcon,
  },
  model: {
    prop: 'value',
    event: 'onInput',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.autofocus) this.focusIt();
  },
  methods: {
    focusIt() {
      this.$refs.searchTrigger.focus();
    },
    blurIt() {
      this.$refs.searchTrigger.blur();
    },
    onClickOutside() {
      this.blurIt();
    },
  },
  i18n: {
    messages: {
      ru: {
        findProducts: 'Найти продукты и товары',
      },
      kk: {
        findProducts: 'Өнімдер мен тауарларды іздеу',
      },
    },
  },
};
</script>

<style scoped>
.search-trigger {
  @apply relative w-full;
}
.search-trigger-cover {
  @apply top-0 left-0 absolute w-full h-full cursor-pointer;
  z-index: 2;
}
.search-trigger-content {
  @apply py-2 px-3 flex items-center rounded-2xl cursor-pointer;

  border-radius: 16px;
  background: #ffffff;
  /* box-shadow:
    0px 92px 92px 0px rgba(0, 0, 0, 0.05),
    0px 3px 38.435px 0px rgba(0, 0, 0, 0.03),
    0px -3px 20.549px 0px rgba(0, 0, 0, 0.06),
    0px 5.794px 11.52px 0px rgba(0, 0, 0, 0.02),
    0px 2.404px 6.118px 0px rgba(0, 0, 0, 0.01),
    0px 0.705px 2.546px 0px rgba(0, 0, 0, 0.01); */
  box-shadow:
    0px 0.71px 2.55px 0px #00000002,
    0px 2.4px 6.12px 0px #00000003,
    0px 5.79px 11.52px 0px #00000005,
    0px -3px 20.55px 0px #0000000f,
    0px 3px 38.44px 0px #00000009,
    0px 92px 92px 0px #0000000d;
}
.search-trigger-icon {
  @apply mr-2;
  min-width: 24px;
}
.search-trigger-content {
  @apply flex items-center justify-between w-full;
}
.search-trigger-input {
  @apply w-full appearance-none focus:outline-none bg-transparent font-normal;
  text-overflow: ellipsis;
  /*
    Похоже, ios safari сам увеличивает размер шрифта,
    если не установлено значение >= 16px
  */
  font-size: 16px;
  line-height: 20px;
}
.search-trigger-input::placeholder {
  @apply text-sirius-gray-1000 opacity-100;
  font: inherit;
}
.search-trigger-input:-ms-input-placeholder {
  @apply text-sirius-gray-300 opacity-100;
}
.search-trigger-input::-ms-input-placeholder {
  @apply text-sirius-gray-300 opacity-100;
}

.search-trigger-cancel {
  @apply ml-2 transition duration-200 focus:outline-none active:opacity-50 text-sirius-black-100;
}
</style>
