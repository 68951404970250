<template>
  <div class="ProductSearchResult product-search-result">
    <NotFoundProducts v-if="isEmpty" class="mb-10" />
    <template v-else>
      <div
        v-if="type === 'ungrouped'"
        class="gap-x-3 gap-y-4"
        :class="$isDesktop ? 'flex flex-wrap' : 'custom-grid'"
      >
        <div
          v-for="product in productList"
          :key="uniqueKey(product.id, product.category_id)"
        >
          <ProductCardWrapper
            v-if="injectReplacement(product)"
            :product="injectReplacement(product)"
            :product-deeplink="productDeeplink"
            :added-from="addedFrom"
          />
        </div>
      </div>
      <!-- grouped -->
      <template v-else>
        <section v-for="(cat, k, i) in categories" :key="cat.id" class="mb-14">
          <WatermelonRefundPlank
            v-if="
              cat.id === 397 &&
              cat.products.some(
                item =>
                  item.id === 15072 || item.id === 18993 || item.id === 11816
              )
            "
            class="mb-10"
            @onPlankClick="onRefundModal(cat.id)"
          />
          <UiNavigationTitle>
            {{ cat.name }}
          </UiNavigationTitle>

          <div
            v-if="cat.products.length > 0"
            :class="[
              $isDesktop
                ? 'mt-6 flex flex-wrap gap-3'
                : 'mt-3 pb-2 grid-rows-auto custom-grid gap-2',
            ]"
          >
            <CatalogItemsWrapper :banner="getCategoryBanner(cat.id, i)">
              <ProductCardWrapper
                v-for="prod in cat.products"
                :key="uniqueKey(prod.id, prod.category_id)"
                :product="injectReplacement(prod)"
                :product-deeplink="productDeeplink"
                :added-from="addedFrom"
              />
            </CatalogItemsWrapper>
          </div>
        </section>
      </template>
    </template>

    <UpButton />
    <OpenTime :reminder="false" />
    <WatermelonRefundBottomSheet
      v-model="showWatermelonRefundBS"
    ></WatermelonRefundBottomSheet>
  </div>
</template>

<script>
import { PRODUCT_ADDED } from '@shared/config/product';
import { getCategoryBanner } from '@shared/components/product/get-category-banner';

import NotFoundProducts from '@shared/components/product/NotFoundProducts.vue';
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';
import UpButton from '@shared/components/product/UpButton.vue';
import OpenTime from '@/views/main/components/OpenTime.vue';
import UiNavigationTitle from '@ui/UiNavigationTitle.vue';
import CatalogItemsWrapper from './CatalogItemsWrapper';
import WatermelonRefundPlank from '@components/watermelon-refund/WatermelonRefundPlank.vue';
import WatermelonRefundBottomSheet from '@components/watermelon-refund/WatermelonRefundBottomSheet.vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'ProductsSearchResult',
  components: {
    WatermelonRefundBottomSheet,
    WatermelonRefundPlank,
    CatalogItemsWrapper,
    UiNavigationTitle,
    NotFoundProducts,
    ProductCardWrapper,
    UpButton,
    OpenTime,
  },
  props: {
    categories: {
      type: Object,
      default: () => ({}),
    },
    productDeeplink: {
      type: Number,
      default: -1,
    },
    addedFrom: {
      type: String,
      default: PRODUCT_ADDED.CATEGORY,
    },
    replacement: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'grouped',
    },
    productList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showWatermelonRefundBS: false,
  }),
  computed: {
    isEmpty() {
      return Object.keys(this.categories).length === 0;
    },
  },
  methods: {
    getCategoryBanner,
    injectReplacement(prod) {
      return Object.assign(prod, { replacement: this.replacement });
    },
    uniqueKey: (id, categoryId) => {
      return `${id}_${categoryId}`;
    },
    onRefundModal(categoryId) {
      Analitycs.logEvent(EVENTS.REFUND_BANNER_CLICKED, {
        category_id: categoryId,
      });
      this.showWatermelonRefundBS = true;
    },
  },
};
</script>

<style scoped>
.custom-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}
@media (max-width: 359px) {
  .custom-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
