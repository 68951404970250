<template>
  <div class="w-full flex flex-col px-4 pb-2">
    <p class="font-bold mb-4">
      {{ $t('provideProductsPhoto') }}
    </p>

    <input
      ref="file"
      type="file"
      class="hidden"
      accept="image/*"
      @change="handleFileUpload()"
    />

    <div
      v-if="fileLoading || images.length > 0"
      class="w-full overflow-x-scroll hidden-scrollbar mb-4"
    >
      <div class="file-carousel flex items-center">
        <div
          v-if="fileLoading"
          class="flex items-center justify-center mr-2 bg-sirius-gray-200 border border-sirius-gray-300 rounded-2xl"
          style="width: 100px; height: 100px"
        >
          <UiRippleLoader />
        </div>

        <FileBlock
          v-for="(img, index) in images"
          :key="index"
          :src="img"
          :index="index"
          class="border border-sirius-gray-300 rounded-2xl"
          style="width: 100px; height: 100px"
          @onClick="onDeleteButton"
        />
      </div>
    </div>

    <div class="flex">
      <UiButton
        class="font-medium px-3"
        variant="gray"
        size="sm"
        :disabled="fileLoading"
        @click="chooseFile"
      >
        {{ $t('toProvidePhoto') }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import { Logger as LOG } from '@shared/services/logger';
import { FileService } from '@services/file';

import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiButton from '@ui/UiButtonNew.vue';
import FileBlock from './FileBlock.vue';

export default {
  name: 'FileLoader',
  components: {
    FileBlock,
    UiRippleLoader,
    UiButton,
  },
  inject: ['toast'],
  data() {
    return {
      images: [],
      fileLoading: false,
    };
  },
  methods: {
    chooseFile() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      const [file] = this.$refs.file.files;
      LOG.event('handleFileUpload', file);
      if (!file) return;

      this.fileLoading = true;
      this.$emit('uploadingFile', true);

      FileService.uploadImage(file)
        .then(res => {
          LOG.event('FileService.uploadImage', res);
          this.images = [res, ...this.images];
          this.getLoadedImages();
        })
        .catch(err => {
          LOG.event('FileService.uploadImage err', err);
          if (err.message === 'format not supported') {
            this.toast.show('Загрузите фото с форматом png, jpg, jpeg');
          } else {
            this.toast.show(
              'Произошла ошибка при загрузке. Попробуйте еще раз',
              'error'
            );
          }
        })
        .finally(() => {
          this.fileLoading = false;
          this.$emit('uploadingFile', false);
        });
    },
    onDeleteButton(index) {
      if (index !== -1) {
        this.$refs.file.value = null;
        this.images.splice(index, 1);
      }
    },
    getLoadedImages() {
      this.$emit('getLoadedImages', this.images);
    },
  },
  i18n: {
    messages: {
      ru: {
        provideProductsPhoto: 'Приложите фотографии товаров',
        toProvidePhoto: 'Приложить фото',
      },
      kk: {
        provideProductsPhoto: 'Тауарлардың суреттерін қосыңыз',
        toProvidePhoto: 'Суреттерді қосу',
      },
    },
  },
};
</script>

<style lang="postcss">
.file-carousel {
  width: fit-content;
}
</style>
