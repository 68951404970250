<template>
  <UiPopup
    :show="modelValue"
    class="RequestGeoModal rgm"
    radius="3xl"
    @change="onChange"
  >
    <p class="rgm__title">{{ $t('requestGeo') }}</p>
    <p class="rgm__descr" v-html="$t('geoInSettings')"></p>
    <div class="rgm__btns">
      <UiButton size="LG" variant="gray" @click.native="close">
        {{ $t('close') }}
      </UiButton>
      <UiButton size="LG" @click.native="allow">{{ $t('allow') }}</UiButton>
    </div>
  </UiPopup>
</template>

<script>
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
export default {
  components: { UiPopup, UiButton },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    allow() {
      this.$emit('onAllow');
    },
    onChange(value) {
      if (!value) this.$emit('onClose');
      this.$emit('update:modelValue', value);
    },
  },
  i18n: {
    messages: {
      ru: {
        requestGeo: 'Разрешите доступ к вашему местоположению',
        geoInSettings: `Чтобы увидеть актуальный ассортимент,
        разрешите доступ к&nbsp;геолокации в настройках`,
        close: 'Закрыть',
        allow: 'Разрешить',
      },
      kk: {
        requestGeo: 'Мекеннжайыңызды білуге рұқсатыңызды бересіз бе?',
        geoInSettings: `Мекеннжайыңыздағы өнімдердің дұрыс қалдығын көру үшін,
        геолокацияңызды білуімузге рұқсат етіңіз`,
        close: 'Жабу',
        allow: 'Рұқсат беру',
      },
    },
  },
};
</script>

<style scoped>
.rgm {
  --popup-width: 343px;
}
.rgm__title {
  @apply mt-2 text-xl font-bold leading-6;
}
.rgm__descr {
  @apply mt-3 leading-5;
}
.rgm__btns {
  @apply mt-6 flex space-x-2;
}
</style>
