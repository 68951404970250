<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_366_24149)">
      <mask
        id="mask0_366_24149"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="30"
        height="30"
      >
        <circle cx="16" cy="16" r="14.8" fill="#B4B4B4" />
      </mask>
      <g mask="url(#mask0_366_24149)">
        <g filter="url(#filter0_ii_366_24149)">
          <circle
            cx="16"
            cy="16"
            r="14.8"
            fill="url(#paint0_radial_366_24149)"
          />
        </g>
        <g filter="url(#filter1_f_366_24149)">
          <circle
            cx="16"
            cy="15.8"
            r="14"
            fill="url(#paint1_radial_366_24149)"
          />
        </g>
        <g filter="url(#filter2_f_366_24149)">
          <circle
            cx="15.9"
            cy="13.3"
            r="9.3"
            fill="url(#paint2_radial_366_24149)"
          />
        </g>
        <g filter="url(#filter3_if_366_24149)">
          <circle
            cx="16"
            cy="16"
            r="13.4"
            fill="url(#paint3_linear_366_24149)"
            style="mix-blend-mode: hard-light"
          />
        </g>
      </g>
      <g filter="url(#filter4_ddii_366_24149)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.8289 23.0776C23.8717 22.9196 23.7086 22.7918 23.5585 22.8571C21.694 23.6682 19.174 24.1999 16.4004 24.1999C13.6268 24.1999 11.1068 23.6682 9.24227 22.857C9.09216 22.7917 8.92913 22.9195 8.9719 23.0776C9.71154 25.8097 12.7565 28.1999 16.4004 28.1999C20.0443 28.1999 23.0893 25.8097 23.8289 23.0776Z"
          fill="url(#paint4_radial_366_24149)"
        />
      </g>
      <g filter="url(#filter5_d_366_24149)">
        <g filter="url(#filter6_ii_366_24149)">
          <path
            d="M21.083 9.32315L21.0211 9.2995C19.7223 8.80314 18.2671 9.45361 17.7708 10.7524L17.7706 10.7528C16.5999 13.8159 19.7868 19.3954 19.7868 19.3954C19.7868 19.3954 25.8826 17.3635 27.0533 14.3005L27.0534 14.3C27.5498 13.0013 26.8993 11.546 25.6006 11.0497L25.5387 11.026C24.504 10.6306 23.3699 10.9631 22.7006 11.7713C22.7409 10.7227 22.1177 9.7186 21.083 9.32315Z"
            fill="url(#paint5_linear_366_24149)"
          />
        </g>
        <mask
          id="mask1_366_24149"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="17"
          y="9"
          width="10"
          height="10"
        >
          <path
            d="M20.5873 18.47C20.289 18.5794 19.9568 18.4525 19.8075 18.172C19.417 17.4379 19.0696 16.6793 18.7695 15.905C18.4542 15.0916 18.1748 14.1979 18.0396 13.339C17.904 12.4775 17.9167 11.6696 18.1664 11.016L18.1666 11.0156C18.5791 9.93633 19.8225 9.40884 20.944 9.83742L21.0028 9.85991C21.8959 10.2012 22.3954 11.1701 22.3721 12.0458L22.379 12.6128L22.7623 12.1949C23.3289 11.5269 24.3473 11.1381 25.2404 11.4794L25.2992 11.5019C26.4207 11.9305 26.9954 13.1529 26.5829 14.2321L26.5827 14.2325C26.333 14.8861 25.8035 15.4965 25.1279 16.048C24.4543 16.5978 23.6501 17.0774 22.8727 17.4732C22.1327 17.85 21.3679 18.1835 20.5873 18.47Z"
            fill="url(#paint6_linear_366_24149)"
          />
        </mask>
        <g mask="url(#mask1_366_24149)">
          <path
            d="M20.5873 18.47C20.289 18.5794 19.9568 18.4525 19.8075 18.172C19.417 17.4379 19.0696 16.6793 18.7695 15.905C18.4542 15.0916 18.1748 14.1979 18.0396 13.339C17.904 12.4775 17.9167 11.6696 18.1664 11.016L18.1666 11.0156C18.5791 9.93633 19.8225 9.40884 20.944 9.83742L21.0028 9.85991C21.8959 10.2012 22.4427 11.0464 22.4194 11.922L22.4263 12.489L22.8096 12.0711C23.3762 11.4031 24.3473 11.1381 25.2404 11.4794L25.2992 11.5019C26.4207 11.9305 26.9954 13.1529 26.5829 14.2321L26.5827 14.2325C26.333 14.8861 25.8035 15.4965 25.1279 16.048C24.4543 16.5978 23.6501 17.0774 22.8727 17.4732C22.1327 17.85 21.3679 18.1835 20.5873 18.47Z"
            fill="url(#paint7_linear_366_24149)"
          />
          <g filter="url(#filter7_f_366_24149)">
            <ellipse
              cx="21.3833"
              cy="15.2182"
              rx="5.6975"
              ry="3.67687"
              transform="rotate(20.9159 21.3833 15.2182)"
              fill="#FF3E30"
            />
          </g>
          <path
            d="M17.4471 12.8983C17.5484 14.0728 17.8226 15.2848 18.1461 16.3834C18.4593 17.4469 18.8331 18.4845 19.263 19.4835C19.4054 19.8146 19.7914 19.9621 20.1184 19.8104C21.1049 19.3527 22.0755 18.8289 23.0181 18.2454C23.9919 17.6426 25.0045 16.9224 25.8634 16.1148L21.883 17.3131C21.0174 17.5737 20.0849 17.2173 19.6137 16.4458L17.4471 12.8983Z"
            fill="#BF0E07"
          />
        </g>
      </g>
      <g filter="url(#filter8_d_366_24149)">
        <g filter="url(#filter9_ii_366_24149)">
          <path
            d="M6.40255 11.0668L6.34091 11.0911C5.04725 11.6006 4.41157 13.0623 4.92108 14.356L4.92125 14.3564C6.12292 17.4075 12.239 19.3774 12.239 19.3774C12.239 19.3774 15.3691 13.7658 14.1675 10.7148L14.1673 10.7143C13.6578 9.42069 12.196 8.78501 10.9024 9.29452L10.8407 9.3188C9.81008 9.72472 9.19706 10.7351 9.24806 11.7833C8.57055 10.9818 7.4332 10.6609 6.40255 11.0668Z"
            fill="url(#paint8_linear_366_24149)"
          />
        </g>
        <mask
          id="mask2_366_24149"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="5"
          y="9"
          width="10"
          height="10"
        >
          <path
            d="M12.2059 18.1543C12.0595 18.4363 11.7286 18.5666 11.4292 18.4602C10.6458 18.1817 9.87756 17.8559 9.13375 17.4866C8.35244 17.0987 7.54335 16.6274 6.86426 16.0844C6.18304 15.5398 5.64747 14.9348 5.39106 14.2838L5.39091 14.2834C4.9675 13.2083 5.52978 11.9802 6.6468 11.5403L6.70542 11.5172C7.59502 11.1668 8.61729 11.5452 9.19064 12.2075L9.57818 12.6215L9.57932 12.0544C9.5471 11.179 10.0367 10.2051 10.9263 9.85477L10.985 9.83168C12.102 9.39174 13.3507 9.90659 13.7741 10.9816L13.7743 10.982C14.0307 11.633 14.0515 12.4407 13.9247 13.3037C13.7982 14.1639 13.5278 15.0604 13.2209 15.8769C12.9287 16.6542 12.589 17.4163 12.2059 18.1543Z"
            fill="url(#paint9_linear_366_24149)"
          />
        </mask>
        <g mask="url(#mask2_366_24149)">
          <path
            d="M12.2059 18.1543C12.0595 18.4363 11.7286 18.5666 11.4292 18.4602C10.6458 18.1817 9.87756 17.8559 9.13375 17.4866C8.35244 17.0987 7.54335 16.6274 6.86426 16.0844C6.18304 15.5398 5.64747 14.9348 5.39106 14.2838L5.39091 14.2834C4.9675 13.2083 5.52978 11.9802 6.6468 11.5403L6.70542 11.5172C7.59502 11.1668 8.56874 11.422 9.14208 12.0842L9.52963 12.4982L9.53076 11.9311C9.49854 11.0558 10.0367 10.2051 10.9263 9.85477L10.985 9.83168C12.102 9.39174 13.3507 9.90659 13.7741 10.9816L13.7743 10.982C14.0307 11.633 14.0515 12.4407 13.9247 13.3037C13.7982 14.1639 13.5278 15.0604 13.2209 15.8769C12.9287 16.6542 12.589 17.4163 12.2059 18.1543Z"
            fill="url(#paint10_linear_366_24149)"
          />
          <g filter="url(#filter10_f_366_24149)">
            <ellipse
              cx="10.6003"
              cy="15.2166"
              rx="5.6975"
              ry="3.67687"
              transform="rotate(-21.4971 10.6003 15.2166)"
              fill="#FF3E30"
            />
          </g>
          <path
            d="M6.12946 16.1586C6.9965 16.9574 8.01635 17.6673 8.99616 18.2602C9.94468 18.8341 10.9205 19.3481 11.9117 19.7957C12.2402 19.9441 12.6246 19.7927 12.7637 19.4601C13.1834 18.4568 13.5467 17.4155 13.8491 16.3489C14.1614 15.2471 14.4233 14.0324 14.5127 12.8568L12.3822 16.4261C11.9188 17.2024 10.99 17.5682 10.1218 17.3164L6.12946 16.1586Z"
            fill="#BF0E07"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ii_366_24149"
        x="1.20001"
        y="1.00001"
        width="29.6"
        height="29.8"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.6" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.823529 0 0 0 0 0.403451 0 0 0 0 0.0156863 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_366_24149"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.2" />
        <feGaussianBlur stdDeviation="0.3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_366_24149"
          result="effect2_innerShadow_366_24149"
        />
      </filter>
      <filter
        id="filter1_f_366_24149"
        x="1.4"
        y="1.19999"
        width="29.2"
        height="29.2"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.3"
          result="effect1_foregroundBlur_366_24149"
        />
      </filter>
      <filter
        id="filter2_f_366_24149"
        x="0.599977"
        y="-2"
        width="30.6"
        height="30.6"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="3"
          result="effect1_foregroundBlur_366_24149"
        />
      </filter>
      <filter
        id="filter3_if_366_24149"
        x="2.39998"
        y="2.39998"
        width="27.2"
        height="27.2"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.2" />
        <feGaussianBlur stdDeviation="0.1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_366_24149"
        />
        <feGaussianBlur
          stdDeviation="0.1"
          result="effect2_foregroundBlur_366_24149"
        />
      </filter>
      <filter
        id="filter4_ddii_366_24149"
        x="8.36497"
        y="22.6396"
        width="16.0708"
        height="6.56029"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.4" />
        <feGaussianBlur stdDeviation="0.1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.905882 0 0 0 0 0.215686 0 0 0 0.45 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_366_24149"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.4" />
        <feGaussianBlur stdDeviation="0.3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 0.298039 0 0 0 0.45 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_366_24149"
          result="effect2_dropShadow_366_24149"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_366_24149"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.2" />
        <feGaussianBlur stdDeviation="0.3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="overlay"
          in2="shape"
          result="effect3_innerShadow_366_24149"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.4" />
        <feGaussianBlur stdDeviation="0.4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.55 0"
        />
        <feBlend
          mode="overlay"
          in2="effect3_innerShadow_366_24149"
          result="effect4_innerShadow_366_24149"
        />
      </filter>
      <filter
        id="filter5_d_366_24149"
        x="13.8942"
        y="7.97308"
        width="15.2857"
        height="15.4857"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.195833 0 0 0 0 0.195833 0 0 0 0 0.195833 0 0 0 0.25 0"
        />
        <feBlend
          mode="multiply"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_366_24149"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_366_24149"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_ii_366_24149"
        x="17.5122"
        y="8.47037"
        width="9.70776"
        height="11.19"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.662568" />
        <feGaussianBlur stdDeviation="0.662568" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_366_24149"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.265027" />
        <feGaussianBlur stdDeviation="0.132514" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_366_24149"
          result="effect2_innerShadow_366_24149"
        />
      </filter>
      <filter
        id="filter7_f_366_24149"
        x="14.3103"
        y="9.63567"
        width="14.146"
        height="11.1649"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.795081"
          result="effect1_foregroundBlur_366_24149"
        />
      </filter>
      <filter
        id="filter8_d_366_24149"
        x="2.76891"
        y="7.94244"
        width="15.347"
        height="15.547"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="0.4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.195833 0 0 0 0 0.195833 0 0 0 0 0.195833 0 0 0 0.25 0"
        />
        <feBlend
          mode="multiply"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_366_24149"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_366_24149"
          result="shape"
        />
      </filter>
      <filter
        id="filter9_ii_366_24149"
        x="4.7453"
        y="8.45615"
        width="9.69684"
        height="11.1863"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.662568" />
        <feGaussianBlur stdDeviation="0.662568" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_366_24149"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.265027" />
        <feGaussianBlur stdDeviation="0.132514" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_366_24149"
          result="effect2_innerShadow_366_24149"
        />
      </filter>
      <filter
        id="filter10_f_366_24149"
        x="3.53911"
        y="9.61797"
        width="14.1223"
        height="11.1972"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.795081"
          result="effect1_foregroundBlur_366_24149"
        />
      </filter>
      <radialGradient
        id="paint0_radial_366_24149"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 12.2) rotate(90) scale(19.7075 19.5743)"
      >
        <stop offset="0.197917" stop-color="#FFEF5B" />
        <stop offset="0.911458" stop-color="#F17B00" />
        <stop offset="0.994792" stop-color="#D54D01" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_366_24149"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16 7.74243) rotate(90) scale(23.1052 18.6)"
      >
        <stop stop-color="#FFDF1E" />
        <stop offset="0.681992" stop-color="#FFDF1E" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_366_24149"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(15.9 5.4) rotate(90) scale(17.2)"
      >
        <stop offset="0.209302" stop-color="white" />
        <stop offset="0.418605" stop-color="#FFF65B" />
        <stop offset="0.860465" stop-color="#FFDD30" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_366_24149"
        x1="16"
        y1="2.59998"
        x2="16"
        y2="13.2797"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFFF8" />
        <stop offset="1" stop-color="#FFCF2C" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_366_24149"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.3293 21.3679) rotate(90) scale(6.20487 8.64265)"
      >
        <stop stop-color="#A66707" />
        <stop offset="1" stop-color="#945706" />
      </radialGradient>
      <linearGradient
        id="paint5_linear_366_24149"
        x1="23.3108"
        y1="10.1746"
        x2="19.7868"
        y2="19.3954"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF3D30" />
        <stop offset="0.479167" stop-color="#EB2B1E" />
        <stop offset="1" stop-color="#A60A03" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_366_24149"
        x1="23.1216"
        y1="10.6697"
        x2="20.3781"
        y2="17.8483"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.189655" stop-color="#CC1009" />
        <stop offset="0.564655" stop-color="#CC1009" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_366_24149"
        x1="20.4017"
        y1="17.7864"
        x2="23.1216"
        y2="10.6697"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CC1009" />
        <stop offset="0.295652" stop-color="#ED2416" />
        <stop offset="0.786458" stop-color="#FF958D" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_366_24149"
        x1="8.62164"
        y1="10.1928"
        x2="12.239"
        y2="19.3774"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF3D30" />
        <stop offset="0.479167" stop-color="#EB2B1E" />
        <stop offset="1" stop-color="#A60A03" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_366_24149"
        x1="8.81588"
        y1="10.686"
        x2="11.6321"
        y2="17.8364"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.189655" stop-color="#CC1009" />
        <stop offset="0.564655" stop-color="#CC1009" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_366_24149"
        x1="11.6078"
        y1="17.7747"
        x2="8.81588"
        y2="10.686"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CC1009" />
        <stop offset="0.295652" stop-color="#ED2416" />
        <stop offset="0.786458" stop-color="#FF958D" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <clipPath id="clip0_366_24149">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'EmojiHeartEyes',
};
</script>
