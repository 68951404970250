<template>
  <div class="map-controls" :class="{ '--desk': $isDesktop }" @touchstart.stop>
    <UiInlineIcon
      v-if="zoom"
      id="zoomIn"
      name="map-zoom-in"
      class="map-controls-btn"
      @click.native="$emit('onZoomIn')"
    />
    <UiInlineIcon
      v-if="zoom"
      id="zoomOut"
      name="map-zoom-out"
      class="map-controls-btn"
      @click.native="$emit('onZoomOut')"
    />
    <div v-if="geo" id="geoLocationBtn" class="map-controls-btn --geo">
      <UiLoader v-if="geoLoading" size="md" variant="gray2" />
      <UiInlineIcon
        v-else
        :name="$isDesktop ? 'map-geo-desk' : 'map-geo'"
        @click.native="$emit('onGeo')"
      />
    </div>
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';

export default {
  name: 'MapControls',
  components: {
    UiInlineIcon,
    UiLoader,
  },
  props: {
    zoom: Boolean,
    geo: Boolean,
    geoLoading: Boolean,
  },
};
</script>

<style scoped>
.map-controls {
  @apply absolute z-10 right-4 md:right-13 top-1/2 pt-3.5 space-y-4 transform -translate-y-1/2;
}
.map-controls-btn {
  @apply w-12 h-12 
  rounded-full bg-white 
  flex items-center justify-center 
  shadow-map-btn cursor-pointer;
}
.--desk .map-controls-btn {
  @apply w-14 h-14;
}
.--geo {
  transition: top 300ms;
}
</style>
