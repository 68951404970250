<template>
  <ul class="profile-menu">
    <MenuItem
      :router-to="{ name: 'orders' }"
      icon="IconProfileOrders"
      @click="onLinkClick('orders')"
    >
      {{ $t('myOrders') }}
    </MenuItem>
    <!-- Задача еще не готова, временно удалили -->
    <MenuItem
      :router-to="{ name: 'profile-addresses' }"
      icon="IconProfileLocation"
      @click="onLinkClick('profile-addresses')"
    >
      {{ $t('addresses') }}
    </MenuItem>
    <MenuItem
      :router-to="{ name: 'promocodes' }"
      :counter="promocodesCount"
      icon="IconProfilePromocodes"
      @click="onLinkClick('promocodes')"
    >
      {{ $t('myPromocodes') }}
    </MenuItem>
    <MenuItem
      v-if="hasCashbacksProgram || hasCashbacksLinks"
      :router-to="{
        name: 'cashbacks',
        query: { from: 'miniapp-profile-menu' },
      }"
      icon="IconProfileCashback"
      @click="onLinkClick('cashbacks')"
    >
      <span class="text-sirius-red-300 font-bold">
        {{ $t('cashbacks') }}
      </span>
    </MenuItem>
    <MenuItem
      v-if="hasReferralProgram"
      :gradient="true"
      :router-to="{ name: 'referral-share' }"
      icon="IconProfileGift"
      @click="onLinkClick('referral-share')"
    >
      <span v-html="$t('giveAndTake')"></span>
    </MenuItem>
    <MenuItem
      :router-to="{ name: 'faq' }"
      icon="IconProfileFaq"
      @click="onLinkClick('faq')"
    >
      {{ $t('faq') }}
    </MenuItem>
    <MenuItem
      icon="IconProfileSupport"
      @click="
        onSupport();
        onLinkClick('support');
      "
    >
      {{ $t('support') }}
    </MenuItem>
    <MenuItem
      v-if="!1"
      icon="IconProfileFeedback"
      @click="
        onLinkClick('feedback');
        $eventBus.emit('eb_show_feedback_modal');
      "
    >
      {{ $t('feedback') }}
    </MenuItem>
    <MenuItem
      :router-to="{ name: 'iin' }"
      icon="IconProfileIIN"
      @click="onLinkClick('iin')"
    >
      {{ $t('iin') }}
    </MenuItem>
    <MenuItem
      :router-to="{ name: 'docs' }"
      icon="IconProfileDocs"
      @click="onLinkClick('docs')"
    >
      {{ $t('docs') }}
    </MenuItem>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import MenuItem from './MenuItem.vue';

export default {
  name: 'ProfileMenu',
  components: {
    MenuItem,
  },
  inject: ['bottomSheetPopup'],
  props: {
    promocodesCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters('cashbacks', ['hasCashbacksProgram', 'hasCashbacksLinks']),
    ...mapGetters('user', ['hasReferralProgram']),
  },
  methods: {
    onLinkClick(name) {
      Analitycs.logEvent(EVENTS.REF_PROFILE_PAGE_LINK_CLICK, {
        name,
      });
    },
    onSupport() {
      this.bottomSheetPopup.show('support-popup');
    },
  },
  i18n: {
    messages: {
      ru: {
        myOrders: 'Мои заказы',
        myPromocodes: 'Мои промокоды',
        cashbacks: 'Кешбэк за первые 5 заказов',
        giveAndTake: 'Дарите 1500 ₸, получайте 1500&nbsp;₸',
        faq: 'Ответы на частые вопросы',
        iin: 'Указывать БИН/ИИН в чеке',
        support: 'Связаться со службой заботы',
        feedback: 'Хочу оставить обратную связь',
        docs: 'Правовые документы',
        addresses: 'Мои адреса',
      },
      kk: {
        myOrders: 'Тапсырыстарым',
        myPromocodes: 'Менің промокодтарым',
        cashbacks: 'Бірінші 5 тапсырыс кешбэктері',
        giveAndTake: '1500 ₸ сыйланыз, 1500&nbsp;₸ алыңыз',
        faq: 'Жиі қойылатын сұрақтар',
        iin: 'БИН/ЖСН чекте көрсету',
        support: 'Қамқорлық қызметіне хабарласу',
        feedback: 'Кері байланыс қалдырғым келеді',
        docs: 'Құжаттар',
        addresses: 'Менің мекенжайларым',
      },
    },
  },
};
</script>
