<template>
  <div>
    <div
      v-if="!src"
      class="mt-9 w-20 h-20 rounded-full bg-sirius-gray-200 relative flex items-center justify-center cursor-pointer"
      @click="addImage"
    >
      <input
        ref="file"
        type="file"
        class="hidden"
        accept="image/*"
        @change="handleFileUpload()"
      />

      <IconAvatar class="" />
      <IconImagePlus class="absolute bottom-0 right-0" />
    </div>
    <template v-else>
      <img
        :src="src"
        alt=""
        class="mt-9 w-20 h-20 rounded-full bg-sirius-gray-200 relative flex items-center justify-center cursor-pointer"
      />
    </template>
  </div>
</template>

<script>
import { api } from '@shared/services/api';
import { FileService } from '@services/file';
import IconImagePlus from '@shared/components/icons/IconImagePlus.vue';
import IconAvatar from '@shared/components/icons/IconAvatar.vue';

export default {
  name: 'ProfileAvatar',
  components: {
    IconImagePlus,
    IconAvatar,
  },
  inject: ['toast'],
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: '',
      fileLoading: false,
    };
  },
  methods: {
    addImage() {
      this.$refs.file.click();
    },
    handleFileUpload() {
      const [file] = this.$refs.file.files;
      if (!file) return;
      this.fileLoading = true;
      FileService.fileToBase64(file)
        .then(data => {
          if (!(file.type === 'image/png' || file.type === 'image/jpeg')) {
            throw new Error('asdfasd');
          }

          return api.lavka.uploadUserAvatar({ image: data });
        })
        .then(image => {
          this.image = image;
        })
        .catch(() => {
          this.toast.show('Произошла ошибка при загрузке. Попробуйте еще раз');
        })
        .finally(() => {
          this.fileLoading = false;
        });
    },
    deleteAvatar() {},
  },
};
</script>
