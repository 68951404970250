<template>
  <div class="ProductReviewModal">
    <UiBottomSheetWrapper
      :show="modelValue"
      with-back-button
      remove-padding
      @change="onChange"
      @onOpen="reset"
      @onClose="reset"
    >
      <FinalPage
        v-show="isFinal"
        :show="isFinal"
        :count="doneCount"
        @onDone="onDoneFinal"
        @onMore="onMoreFinal"
      />
      <div
        v-if="!isFinal && activeProduct"
        class="modal"
        :class="{ modal__single: productsRes.length === 1 }"
      >
        <ReviewProductsModalContent
          class="modal-content"
          :active-index="currentSlideIndex"
          :review="activeProduct.review"
          :call-back="activeProduct.callBack"
          :comment="activeProduct.comment"
          :name="activeProduct.name"
          :pagination="doneIndexes"
          :ready="isLast || productsRes.length === 1"
          @onComment="openCommentModal"
          @onLike="onLike"
          @onDislike="onDislike"
          @onGoNext="goNext"
        />

        <UiSwiper
          ref="swiper"
          class="modal-swiper"
          :slides="productsRes"
          :settings="settings"
          @onSlideChange="onSlideChange"
        >
          <template #slide="slide">
            <SwiperImageItem
              :index="slide.index"
              :current-slide="currentSlideIndex"
              :img="slide.img"
              :review="slide.review"
              @onReset="onResetReview"
              @click.native="slideTo(slide.index)"
            />
          </template>
        </UiSwiper>
      </div>
    </UiBottomSheetWrapper>

    <TellMoreModal
      v-if="activeProduct"
      v-model="tellMoreModal"
      :name="activeProduct.name"
      :call-me="activeProduct.review === 'dislike'"
      :comment="activeProduct.comment"
      :checked="activeProduct.callBack"
      @onReady="onTellMore"
    />
  </div>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import TellMoreModal from '@pure-ui/components/ProductReview/components/TellMoreModal.vue';
import ReviewProductsModalContent from './components/ReviewProductsModalContent.vue';
import UiSwiper from '@pure-ui/components/UiSwiper/UiSwiper.vue';
import SwiperImageItem from './components/SwiperImageItem.vue';
import FinalPage from './components/FinalPage.vue';
import NoPhoto from '@img/no-photo.png';

export default {
  name: 'ProductReviewModal',
  components: {
    ReviewProductsModalContent,
    UiBottomSheetWrapper,
    SwiperImageItem,
    TellMoreModal,
    FinalPage,
    UiSwiper,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array,
      required: true,
    },
    more: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tellMoreModal: false,
    productsRes: [],
    currentSlideIndex: 0,
    isFinal: false,
    settings: {
      slidesPerView: 3,
      spaceBetween: 0,
      centeredSlides: true,
      allowTouchMove: false,
      longSwipes: false,
    },
  }),
  computed: {
    activeProduct() {
      return this.isFinal ? null : this.productsRes[this.currentSlideIndex];
    },
    doneIndexes() {
      return this.productsRes.map((p, idx) => (p.review ? idx : -1));
    },
    doneCount() {
      return this.doneIndexes.reduce((t, i) => (t = i !== -1 ? t + 1 : t), 0);
    },
    isLast() {
      return this.currentSlideIndex + 1 > this.productsRes.length - 1;
    },
  },
  watch: {
    products: {
      handler() {
        this.updateProducts();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onSlideChange(idx) {
      this.currentSlideIndex = idx;
      this.$emit('onChangeSlide');
    },
    slideNext() {
      this.$refs.swiper.next();
    },
    slideTo(idx) {
      this.$refs.swiper.slideTo(idx);
    },
    /** Needs to be accessable from outer */
    reset() {
      this.currentSlideIndex = 0;
      this.isFinal = false;
    },
    updateProducts() {
      this.productsRes = [...this.products].map(p => {
        let oldProduct = this.productsRes.find(old => old.id === p.id);

        return {
          id: p.id,
          name_kk: p.name_kk || p.name,
          name: p.name,
          img: p.images?.[0]?.path || p.path || NoPhoto,
          review: oldProduct?.review || '',
          comment: oldProduct?.comment || '',
          callBack: oldProduct?.callBack || false,
          isSkipped: oldProduct?.isSkipped || false,
        };
      });
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    onResetReview() {
      this.activeProduct.review = '';
      this.activeProduct.callBack = false;
    },
    onLike() {
      this.activeProduct.review = 'like';
    },
    onDislike() {
      this.activeProduct.review = 'dislike';
    },
    openCommentModal() {
      this.tellMoreModal = true;
    },
    onTellMore(comment, callBack) {
      this.activeProduct.comment = comment;
      this.activeProduct.callBack = callBack;
    },

    sendInfo(more = false) {
      let markedSkippeds = this.productsRes.map((r, idx) => ({
        ...r,
        isSkipped: idx <= this.currentSlideIndex && r.review === '',
      }));
      this.$emit('onDone', markedSkippeds, more, this.currentSlideIndex);
    },
    onDoneFinal() {
      this.sendInfo();
    },
    onMoreFinal() {
      this.sendInfo(true);
    },
    goNext() {
      if (this.isLast && this.more) {
        this.isFinal = true;
        return;
      }
      if (this.isLast) {
        this.sendInfo();
        this.closeModal();
        return;
      }

      this.slideNext();
    },
    onChange(value) {
      if (!value) this.sendInfo();

      this.$emit('update:modelValue', value);
    },
  },
};
</script>

<style scoped>
.modal {
  @apply mt-4 relative;
  min-height: 574px;
}
.modal__single {
  min-height: 520px;
}
.modal-swiper {
  min-height: 376px;
}
.modal-content {
  @apply absolute w-full px-4;
  margin-top: 216px;
  bottom: 0px;
}
</style>
