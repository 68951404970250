<template>
  <div
    class="LabelsItem labels-item"
    :class="{
      '--full': variant === 'full',
      '--tooltip': variant === 'tooltip',
      '--desk': $isDesktop,
    }"
    :style="`background-color: ${getColor(color)}`"
  >
    <img class="labels-item-icon" :src="icon" />

    <p
      v-if="variant !== 'default'"
      class="labels-item-name"
      :style="`color: ${color}`"
    >
      {{ name }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      // default: 'full',
      // default: 'tooltip',
    },
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  methods: {
    hexToRgba(hex, alpha = 1) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);

      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },
    getColor(color) {
      return this.variant !== 'default'
        ? this.hexToRgba(color, 0.1)
        : this.hexToRgba(color);
    },
  },
};
</script>

<style scoped>
.labels-item {
  @apply flex items-center rounded-full
    h-6 pr-0 pl-0;
  min-width: fit-content;
  width: fit-content;
}
.labels-item.--full {
  @apply rounded-xl 
    h-7 pr-3 pl-1;
}
.labels-item.--tooltip {
  @apply rounded-xl  
    h-6 px-2;
  width: auto;
  min-width: max-content;
}

.labels-item-icon {
  width: 24px;
  height: 24px;
}
.--full .labels-item-icon {
  width: 20px;
  height: 20px;
}
.--tooltip .labels-item-icon {
  width: 16px;
  height: 16px;
}

.labels-item-name {
  @apply ml-1 leading-none whitespace-nowrap;
}
.--tooltip .labels-item-name {
  @apply pb-px leading-tight;
}

.--desk.labels-item {
  @apply h-8;
}
.--desk.--full.labels-item {
  @apply pr-2 pl-2;
}

.--desk .labels-item-icon {
  width: 32px;
  height: 32px;
}
.--desk.--full .labels-item-icon {
  width: 16px;
  height: 16px;
}
</style>
