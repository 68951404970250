<template>
  <div class="flex flex-col md:flex-row text-center md:text-left">
    <img
      class="md:hidden object-contain mx-auto"
      src="@img/address/empty-address.png"
      style="height: 174px; scale: 1.2"
      width="174px"
      height="174px"
      alt=""
    />
    <img
      class="mr-3 hidden md:block object-contain"
      src="@img/address/empty-address.png"
      style="height: 210px"
      width="288px"
      height="210px"
      alt=""
    />
    <div class="">
      <p class="mt-3 font-bold text-2xl md:text-3xl">Добавьте адреса</p>
      <p class="mt-4 md:mt-6 text-lg">У вас пока нет сохраненных адресов</p>
      <UiButton
        size="lg"
        class="w-full md:w-min px-7 mt-8 md:mt-7 font-bold text-lg whitespace-nowrap"
        @click="$emit('onCreate')"
      >
        Добавить
      </UiButton>
    </div>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  components: {
    UiButton,
  },
};
</script>

<style></style>
