import { client } from '../../clients';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';

export default function (id, isAuthorized = false) {
  return client
    .post(
      `/lavka/v2${isAuthorizedUrl(isAuthorized)}/user_address/remove`,
      { id },
      { useDeviceId: true }
    )
    .then(response => response.data);
}
