import { client } from '../../clients';

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};
export function rUploadFile(payload) {
  return client
    .post('/lavka-images/image/upload/base64', payload, { headers })
    .then(res => res.data);
}
