<template>
  <div class="CashbackBox box">
    <div class="box-content">
      <div class="box-cashback">
        <p v-if="isFinished" class="box-cashback__success">
          {{ $t('price', [condition.cashback]) }}
        </p>
        <p v-else>{{ $t('until', [condition.maxBonus]) }}</p>
        <UiInlineIcon name="cashbacks-romb" />
      </div>

      <p class="box-count">{{ $t('forOrder', [condition.count, maxCount]) }}</p>

      <p v-if="isFinished && $isDesktop" class="box-gained">
        {{ $t('gained') }}
      </p>
    </div>

    <div class="box-content box-content__last">
      <Conditions :condition="condition" />

      <p v-if="isFinished && !$isDesktop" class="box-gained">
        {{ $t('gained') }}
      </p>
    </div>
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import Conditions from '../Conditions/Conditions.vue';

export default {
  name: 'CashbackBox',
  components: { UiInlineIcon, Conditions },
  props: {
    maxCount: {
      type: Number,
      default: 5,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isFinished() {
      return !!this.condition.cashback;
    },
  },
  i18n: {
    messages: {
      kk: {
        price: '+ {0}',
        until: '{0}-дейін',
        forOrder: '{1}-тің {0}-сі үшін',
        gained: 'кешбэк алынды',
      },
      ru: {
        price: '+ {0}',
        until: 'до {0}',
        forOrder: 'за {0} заказ из {1}',
        gained: 'кешбэк начислен',
      },
    },
  },
};
</script>

<style scoped>
.box {
  @apply p-4 pb-5 md:pr-5 md:pb-6 md:pl-6 border-2 border-sirius-gray-200 rounded-2xl md:rounded-3xl;
}
.box-content {
  @apply flex items-end  justify-between;
}
.box-content__last {
  @apply mt-6 items-center;
}
.box-cashback {
  @apply flex items-center space-x-1 text-2xl leading-30 font-bold;
}
.box-cashback__success {
  @apply text-sirius-green-100;
}
.box-count {
  @apply leading-5 font-medium text-sirius-gray-700;
}
.box-gained {
  @apply rounded-xl text-sirius-green-100 text-xs leading-14 font-medium bg-sirius-gray-200 whitespace-nowrap;
  padding: 6px 12px 8px;
}
</style>
