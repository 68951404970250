import { rActiveModal } from '@/shared/services/api/requestsV2/active-modal';
import { mapResponse } from './map-response';
import { wasAlreadyShown, setShown } from './localstorage';

export function fetchActiveModal() {
  return new Promise((resolve, reject) => {
    rActiveModal()
      .then(({ data }) => {
        if (!data || wasAlreadyShown(data.id)) {
          return resolve({ status: 'NoActiveModal' });
        }

        setShown(data.id);
        resolve({
          status: 'HasActiveModal',
          data: mapResponse(data),
        });
      })
      .catch(reject);
  });
}
