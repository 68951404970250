/**
 * Ссылки на пользовательское соглашение
 */
export const PUBLIC_OFFER_LINKS = {
  ru: 'https://docs.google.com/document/d/1UMD5InTYJP4vbx2SROd3RyN_IycyO0_R/edit',
  kk: 'https://docs.google.com/document/d/1PNMAszWeZr4Y1GNHvXaJpEO8XmseY-my/edit',
};

/**
 * Ссылки на Политика возвратов и доставки
 */
export const DELIVERY_POLICY_LINKS = {
  ru: 'https://docs.google.com/document/d/1eZ-kDqc2EfajUSNfNzfGh2buQXfIc4a7U5e5LwRwZAg/edit',
  kk: 'https://docs.google.com/document/d/1i5whGi2vyN61I-h4-QNduO3ao9XB9aXuZoRgPjDFO8E/edit',
};
