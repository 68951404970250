<template>
  <RenderlessProduct
    v-slot="{
      isPending,
      addToCart,
      quantity,
      removeFromCart,
      removeProductFromCart,
    }"
    :product="product"
    :feature="feature"
    :id-key="idKey"
  >
    <div
      class="CartProduct relative w-full h-full cursor-pointer font-medium text-sirius-black-100 dark:text-white"
      @mouseover="isHovered = true"
      @mouseleave="isHovered = false"
    >
      <div
        ref="block"
        v-touch:swipe="swipeHandler"
        v-touch-options="{ swipeTolerance: 30 }"
        class="flex items-start left-0 relative transition-all ease-out duration-200"
        :class="{
          'bg-white dark:bg-black': isAvailable,
          'z-block px-4 py-3 ': isCart,
        }"
      >
        <div class="" @click="goToFullProduct">
          <ProductPhoto
            :src="productImage"
            mix-blend
            :disabled="!isAvailable"
            :blured="checkIsImgBlured"
            class="bg-sirius-gray-200 rounded-xl overflow-hidden"
            style="width: 60px; height: 60px"
          />
        </div>
        <div class="ml-3">
          <p
            class="text-sm leading-4 cursor-pointer font-normal"
            :class="`
              ${!isAvailable ? 'text-sirius-gray-100' : ''}`"
            @click="goToFullProduct"
          >
            {{ productName }}
          </p>
          <p v-if="!isAvailable" class="text-sirius-red-400 text-xs">
            {{ $t('outOfStock') }}
          </p>
          <div
            v-else
            class="text-xs leading-4 text-sirius-black-100 dark:text-white"
          >
            <p class="mt-1 pt-0.5">
              <span
                class="mr-1 leading-3 text-xs text-sirius-gray-100"
                :class="{ 'text-sirius-red-400': hasDiscount }"
              >
                {{ formatPrice(product.price) }} ₸/шт
              </span>
              <span
                v-if="hasDiscount"
                class="mr-1 leading-3 text-xs text-sirius-gray-100 line-through"
              >
                {{ formatPrice(product.old_price) }} ₸
              </span>
            </p>
            <p v-if="quantity > 0" class="text-base font-medium leading-5 mt-1">
              {{ formatPrice(quantity * product.price) }} ₸
            </p>
          </div>
        </div>

        <div class="ml-auto pl-3">
          <UiButton
            v-if="isAuthorized && isPending"
            size="sm"
            variant="gray-no-hover"
            style="width: 100px; max-width: 100px; height: 40px"
          >
            <UiLoader size="sm" variant="gray" />
          </UiButton>
          <div v-else-if="!isAvailable && !isPopup" class="w-6 h-6">
            <img
              :src="cancelIcon"
              alt="delete"
              @click="removeUnAvailableProduct(removeFromCart)"
            />
          </div>
          <UiButton
            v-else-if="isAvailable && quantity === 0"
            size="sm"
            class="text-white font-medium w-full"
            style="width: 104px"
            @click="onIncrement(addToCart)"
          >
            {{ $t('toAdd') }}
          </UiButton>
          <ProductCounter
            v-else-if="isAvailable && quantity"
            style="width: 100px; max-width: 100px"
            :model-value="quantity"
            :max="maxQuantity"
            size="sm"
            variant="secondary"
            :loading="isAuthorized ? isPending : null"
            @onIncrement="onIncrement(addToCart)"
            @onDecrement="onDecrement(removeFromCart)"
            @onMaxLimit="onMaxLimit"
          />
        </div>
      </div>
      <button
        v-if="isAvailable && isCart"
        class="absolute right-0 top-0 z-0 bg-sirius-red-400 focus:outline-none active:opacity-50 flex justify-center items-center cursor-pointer"
        style="width: 100px; height: calc(100% - 2px); margin-top: 1px"
        @click="removeFullProduct(removeProductFromCart)"
      >
        <IconTrashbin class="text-white" width="24" height="24" />
      </button>
      <div
        v-if="isAvailable && isCart && isHovered"
        class="hidden md:block absolute right-4 -top-3 z-10"
        @click="removeFullProduct(removeProductFromCart)"
      >
        <IconCartCrestik />
      </div>
    </div>
  </RenderlessProduct>
</template>

<script>
import { RenderlessProduct } from '@shared/components/product/RenderlessProduct';
import { EVENTS, Analitycs } from '@shared/services/analitycs';
import { formatPrice } from '@shared/utils/formatters/price';
import { onAdd, onDec } from '@services/product/analytics';
import { PRODUCT_ADDED } from '@shared/config/product';
import { ProductService } from '@services/product';
import { AdultsService } from '@services/adults';
import { RahmetApp } from '@shared/RahmetApp';
import { mapGetters } from 'vuex';

import ProductCounter from '@pure-ui/components/Product/ProductCounter/ProductCounter.vue';
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';
import IconCartCrestik from '@shared/components/icons/IconCartCrestik.vue';
import IconTrashbin from '@shared/components/icons/IconTrashbin.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'CartProduct',
  components: {
    RenderlessProduct,
    IconCartCrestik,
    ProductCounter,
    ProductPhoto,
    IconTrashbin,
    UiButton,
    UiLoader,
  },
  inject: ['toast', 'bottomSheetPopup'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    isAvailable: {
      type: Boolean,
      default: true,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    isCart: {
      type: Boolean,
      default: true,
    },
    addedFrom: {
      type: String,
      default: PRODUCT_ADDED.CART,
    },
    idKey: {
      type: String,
      default: 'product_id',
    },
  },
  data() {
    return {
      isHovered: false,
      cancelIcon: new URL('@img/icons/cancel.svg', import.meta.url).href,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),
    checkIsImgBlured() {
      return (
        AdultsService.checkIsAdultProduct(this.product) &&
        !(
          AdultsService.checkIsAgreed() ||
          this.$store.getters['adults/isAgreed']
        )
      );
    },
    productImage() {
      if (!this.product) return '';

      if (this.product.path) return this.product.path;
      if (
        this.product.images &&
        this.product.images.length !== 0 &&
        this.product.images[0].path
      )
        return this.product.images[0].path;
      return '';
    },
    maxQuantity() {
      if (this.product.features) return this.feature.max_quantity || 0;
      return this.product.max_quantity || 0;
    },
    productName() {
      if (!this.feature?.name) {
        return this.product.name;
      }
      const arr = this.feature.name.split(' ');

      return `
        ${this.product.name}, ${Number(arr[0].replace(',', '.'))} ${arr[1]}`;
    },
    feature() {
      if (this.product.feature_id && this.product.feature_name) {
        return { id: this.product.feature_id, name: this.product.feature_name };
      }
      if (
        (this.product.feature_id || this.product.featureID) &&
        this.product.features
      ) {
        return this.product.features.find(e => this.product.featureID === e.id);
      }
      return {};
    },
    hasDiscount() {
      const oldPrice = this.product.old_price;
      return oldPrice > 0 && oldPrice !== this.product.price;
    },
  },
  methods: {
    formatPrice,
    onIncrement(addToCart) {
      RahmetApp.hapticSelection();
      onAdd(this.addedFrom, this.product, true);
      addToCart(this.addedFrom, this.product.category_id);
    },
    onDecrement(removeFromCart) {
      RahmetApp.hapticSelection();
      if (this.product.quantity === 1) Analitycs.logEvent(EVENTS.CLEAR_BASKET);
      onDec(this.addedFrom, this.product);
      removeFromCart();
    },
    removeFullProduct(removeProductFromCart) {
      Analitycs.logEvent(EVENTS.FLUSHED_PRODUCT, {
        product: this.product,
      });
      removeProductFromCart();
    },
    removeUnAvailableProduct(removeFromCart) {
      removeFromCart(this.product.quantity);
    },
    swipeHandler(direction) {
      if (!this.isAvailable) return;
      if (direction === 'left') {
        this.$refs.block.style.left = '-100px';
      } else {
        this.$refs.block.style.left = '0';
      }
    },
    goToFullProduct() {
      let query = ProductService.buildQueryOnGoToProduct(
        this.product,
        false,
        this.addedFrom
      );
      if (this.product.added_from) query.added_from = this.product.added_from;

      this.$emit('onNavigate');
      if (this.product.is_sample) return;
      if (this.$isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: `${this.product.product_id ?? this.product.id}`,
          },
          query,
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: this.product.product_id,
          query,
        });
      }
    },
    onMaxLimit() {
      RahmetApp.hapticNotification('error');
    },
  },
  i18n: {
    messages: {
      ru: {
        outOfStock: 'Нет в наличии',
        toAdd: 'Добавить',
      },
      kk: {
        outOfStock: 'Әзірге жоқ',
        toAdd: 'Қосу',
      },
    },
  },
};
</script>
