import { DEFAULT_CITY } from '@services/city/constants';

const MarkerIcon = new URL('@img/icons/placemark.svg', import.meta.url).href;
const ExtendedMarkerIconKAZ = new URL(
  '@img/extended-delivery/pin_kz.svg',
  import.meta.url
).href;
const ExtendedMarkerIconRUS = new URL(
  '@img/extended-delivery/pin_rus.svg',
  import.meta.url
).href;

export const YMAPS_SETTINGS = {
  apiKey: import.meta.env.VITE_YANDEX_MAP_API_KEY,
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1',
};

export const INITIAL_COORDS = DEFAULT_CITY.defaultCoords;

export const GEOCODE_CONSTRAINTS = {
  rspn: 1,
  ll: '76.910350,43.239554',
  spn: '0.14,0.14',
};

const BLUE_COLOR = '#7284E4';
const ORANGE_COLOR = '#ffb069';

export const NEW_POLYGON_SETTINGS = {
  regular: {
    fillColor: ORANGE_COLOR,
    fillOpacity: 0.2,

    strokeColor: ORANGE_COLOR,
    strokeOpacity: 0.8,
    strokeWidth: 3,
  },
  regularNearExtended: {
    fillColor: ORANGE_COLOR,
    fillOpacity: 0.25,

    strokeStyle: 'shortDash',
    strokeColor: ORANGE_COLOR,
    strokeOpacity: 0.8,
    strokeWidth: 0,
  },
  extended: {
    fillColor: BLUE_COLOR,
    fillOpacity: 0.2,

    strokeColor: BLUE_COLOR,
    strokeOpacity: 1,
    strokeWidth: 2,
  },
};
export const POLYGON_SETTINGS = {
  regular: {
    markerFill: {
      enabled: true,
      color: '#ffb069',
      opacity: 0.25,
    },
    markerStroke: {
      // style: 'dash',
      enabled: true,
      color: '#ffb069',
      opacity: 0.8,
      width: 3,
    },
  },
  regularNearExtended: {
    markerFill: {
      enabled: true,
      color: '#ffb069',
      opacity: 0.25,
    },
    markerStroke: {
      style: 'dash',
      enabled: true,
      color: '#ffb069',
      opacity: 0.8,
      width: 0,
    },
  },
  extended: {
    markerFill: {
      enabled: true,
      color: '#7284E4',
      opacity: 0.2,
    },
    markerStroke: {
      enabled: true,
      color: '#7284E4',
      opacity: 1,
      width: 2,
    },
  },
};

// CHECK: убрали лимит поиска только по алмате
export const QUERY_SEARCH_SETTINGS = {
  apikey: import.meta.env.VITE_YANDEX_MAP_API_KEY,
  format: 'json',
  // rpsn: GEOCODE_CONSTRAINTS.rspn,
  // ll: GEOCODE_CONSTRAINTS.ll,
  // spn: GEOCODE_CONSTRAINTS.spn,
  kind: 'house',
};

export const MARKER_ICON = {
  layout: 'default#image',
  imageHref: MarkerIcon,
  imageSize: [60, 60],
  imageOffset: [-30, -60],
};

export const EXTENDED_MARKER_ICON_RU = {
  layout: 'default#image',
  imageHref: ExtendedMarkerIconRUS,
  imageSize: [370, 82],
  imageOffset: [-30, -82],
};

export const EXTENDED_MARKER_ICON_KAZ = {
  layout: 'default#image',
  imageHref: ExtendedMarkerIconKAZ,
  imageSize: [370, 82],
  imageOffset: [-30, -82],
};
