<template>
  <div class="">
    <div
      v-if="whiteSpace"
      style="height: 68px; width: 100%"
      class="bg-white"
    ></div>
    <div
      v-if="withBackButton || withTitle"
      class="fixed flex items-center z-block left-0 px-4 w-full"
      :class="
        whiteSpace && withTitle ? 'top-0 pt-9 h-16 rounded-t-3xl' : 'top-9  h-8'
      "
      @click="onClickBackButton"
    >
      <IconBack v-if="withBackButton" class="cursor-pointer" />
      <slot v-if="withTitle" name="title"></slot>
      <div class="w-6 h-6"></div>
    </div>

    <slot></slot>

    <div v-if="withButtons" class="bottom-sheet-wrapper-buttons">
      <slot name="buttons"> </slot>
    </div>
  </div>
</template>

<script>
import IconBack from '../../icons/IconBack.vue';

export default {
  name: 'BottomSheetWrapper',
  components: { IconBack },
  inject: ['bottomSheet'],
  props: {
    withBackButton: {
      type: Boolean,
      default: false,
    },
    withButtons: {
      type: Boolean,
      default: false,
    },
    onBack: {
      type: Function,
      default: null,
    },
    whiteSpace: {
      type: Boolean,
      default: true,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickBackButton() {
      this.$emit('onBackButtonClicked');
      if (!this.onBack) return this.bottomSheet.hide();
      return this.onBack();
    },
  },
};
</script>

<style lang="postcss" scoped>
.bottom-sheet-wrapper {
  @apply relative;
  &-buttons {
    @apply px-4 py-3 fixed w-full flex z-modal bottom-0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 67.89%,
      #ffffff 47.78%
    );
    backdrop-filter: blur(12px);
  }
}
</style>
