<template>
  <div class="OrderInfo">
    <div class="flex items-center font-medium text-sirius-gray-700">
      <p>№ {{ orderCode }}</p>
      <div
        class="ml-4 py-0.5 px-2 rounded-lg text-xs font-bold"
        :class="statusType.color"
      >
        {{ $t(statusType.title[$i18n.locale]) }}
      </div>
    </div>
    <p class="mt-6 font-bold text-2xl">{{ paidAt }}</p>
    <div>
      <div
        class="flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-3"
        :class="{
          'mt-7': canLeaveFeedback || isProblemBtnShown,
        }"
      >
        <UiButton
          v-if="canLeaveFeedback"
          size="lg"
          class="w-full font-bold text-lg"
          @click="openFromButton"
        >
          {{ $t('toRate') }}
        </UiButton>

        <UiButton
          v-if="isProblemBtnShown"
          size="lg"
          variant="gray"
          class="w-full font-bold text-lg whitespace-nowrap px-6"
          @click="$emit('goToProblemsClicked')"
        >
          {{ $t('somethingWrongWithOrder') }}
        </UiButton>
      </div>

      <div class="flex items-center justify-start cursor-pointer mt-5 mb-8">
        <IconRepeatOrder />
        <p
          class="md:ml-3 ml-2.5 font-medium md:text-xl text-base flex items-center text-sirius-orange-100"
          @click="onRepeatOrder"
        >
          {{ $t('repeatOrder') }}
        </p>
      </div>

      <SupportButtons class="mt-5 mb-2 md:mt-10 md:mb-0" view="finishedOrder" />

      <div
        v-if="isCourierTipsAvailable"
        class="flex justify-between items-center mt-5 cursor-pointer"
        @click="openTipsBottomSheet"
      >
        <div class="text-sm">
          <p class="text-sirius-orange-100 font-bold flex items-center">
            {{ $t('leaveTipsToCourier') }}

            <svg
              class="mt-1 ml-1"
              width="20"
              height="23"
              viewBox="0 0 20 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.33341 14.8334L11.6667 11.5001L8.33341 8.16675"
                stroke="#FFA520"
                stroke-width="2"
                stroke-linecap="square"
              />
            </svg>
          </p>
          <p class="">{{ courier.name }}</p>
        </div>
        <img
          v-if="courier.picture"
          :src="courier.picture"
          class="w-12 h-12 rounded-full flex items-center justify-center"
          alt="courier-img"
        />
        <IconCourierDefault
          v-else
          class="w-12 h-12 rounded-full flex items-center justify-center"
        />
      </div>
    </div>

    <NoGoodsPopup v-model="showNoGoodsPopup"></NoGoodsPopup>

    <CustomerFeedback
      :can-leave-feedback="canLeaveFeedback"
      :can-leave-tip="canLeaveTip"
      :order-id="orderId"
      :order="order"
      :courier="courier"
      :review-type="reviewType"
      @onClose="closeFeedbackBottomSheet"
      @onFeedbackDone="onFeedbackDone"
      @onTipDone="onTipDone"
    />

    <UiFullScreenLoader v-if="loading" />
  </div>
</template>

<script>
import { api } from '@shared/services/api';
import { wordDeclination } from '@shared/utils/delivery/word-declination';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { formatPrice } from '@shared/utils/formatters/price';
import { ORDER_STATUSES } from '@services/orders/constants';

import CustomerFeedback from '@shared/components/feedback/CustomerFeedback.vue';
import IconCourierDefault from '@shared/components/icons/IconCourierDefault.vue';
import SupportButtons from '@shared/components/support/SupportButtons.vue';
import UiButton from '@ui/UiButtonNew.vue';
import {
  computeDateDiff,
  dateAndTime,
  getDefaultDateFormat,
} from '@/utils/formatters/date';
import { getStatusText } from '@services/orders/utils/get-status-text';
import IconRepeatOrder from '@/views/order/components/IconRepeatOrder.vue';
import { mapGetters } from 'vuex';
import { CartService } from '@services/cart';
import NoGoodsPopup from '@/views/order/components/NoGoodsPopup.vue';
import {ProductBDService} from "@services/product-db";
import UiFullScreenLoader from "@ui/UiFullScreenLoader.vue";

export default {
  name: 'OrderInfo',
  components: {
    UiFullScreenLoader,
    NoGoodsPopup,
    IconRepeatOrder,
    IconCourierDefault,
    CustomerFeedback,
    SupportButtons,
    UiButton,
  },
  inject: ['bottomSheetPopup', 'toast'],
  props: {
    order: {
      type: Object,
      required: true,
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
    isProblemBtnShown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courier: null,
      reviewType: 'History_Buttons',
      showNoGoodsPopup: false,
      loading: false
    };
  },
  computed: {
    ...mapGetters('delivery', ['warehouseId']),
    ...mapGetters('abtest', ['testGroups']),
    orderId() {
      return this.order && this.order.id ? `${this.order.id}` : '';
    },
    orderCode() {
      return this.order?.code ? this.order.code : '-';
    },
    statusType() {
      return getStatusText(this.order.status);
    },
    /**
     * Кнопка "Оставить чаевые" всегда видна
     * @returns {false|null|boolean}
     */
    isCourierTipsAvailable() {
      return this.isFinished && this.courier && this.order.delivery_type === 0;
    },
    canLeaveTip() {
      return this.isFinished && this.courier && this.order.delivery_type === 0;
    },
    canLeaveFeedback() {
      return (
        this.order &&
        (this.order.status === ORDER_STATUSES.DELIVERED ||
          this.order.status === ORDER_STATUSES.FINISHED) &&
        this.order.is_reviewed === false
      );
    },
    paidAt() {
      return this.order.paid_at
        ? dateAndTime(this.order.paid_at, true, this.$i18n.locale)
        : '';
    },
  },
  created() {
    this.getCourier();
    this.handleFeedbackDeeplink();
  },
  methods: {
    formatPrice,
    wordDeclination,
    handleFeedbackDeeplink() {
      if (
        this.$route.query.rate ||
        (this.$route.query.feedback &&
          this.$route.query.feedback === 'true' &&
          this.order.is_reviewed === false)
      ) {
        this.reviewType = 'Deeplink';
        this.openFeedbackBottomSheet();
      }
    },
    openTipsBottomSheet() {
      this.reviewType = 'History_Buttons';
      const modalShownAt = getDefaultDateFormat(new Date());
      Analitycs.logEvent(EVENTS.FEEDBACK_MODAL_STARTED, {
        type: this.reviewType,
        orderPaidAt: this.order.paid_at,
        modalShownAt,
        dayDiff: computeDateDiff(this.order.paid_at, modalShownAt),
        orderId: String(this.orderId),
        warehouseId: String(this.order.warehouse_id),

        isReviewShown: Boolean(this.canLeaveFeedback),

        isTipsShown: Boolean(this.canLeaveTip),
        fromTipsButton: true,
      });
      this.bottomSheetPopup.show(`feedback-modal::${this.orderId}`);
    },
    onFeedbackDone() {
      // Check it
      this.$emit('onUpdateOrder', { id: this.order.id, is_reviewed: true });
    },
    onTipDone() {
      // Check it
      this.$emit('onUpdateOrder', { id: this.order.id, is_tip_paid: true });
    },
    openFromButton() {
      this.reviewType = 'History_Buttons';
      this.openFeedbackBottomSheet();
    },
    openFeedbackBottomSheet() {
      this.bottomSheetPopup.show(`feedback-modal::${this.orderId}`);
      const modalShownAt = getDefaultDateFormat(new Date());
      Analitycs.logEvent(EVENTS.FEEDBACK_MODAL_STARTED, {
        type: this.reviewType,
        orderPaidAt: this.order.paid_at,
        modalShownAt,
        dayDiff: computeDateDiff(this.order.paid_at, modalShownAt),
        orderId: String(this.orderId),
        warehouseId: String(this.order.warehouse_id),

        isReviewShown: Boolean(this.canLeaveFeedback),

        isTipsShown: Boolean(this.canLeaveTip),
        fromTipsButton: false,
      });
    },
    closeFeedbackBottomSheet() {
      this.bottomSheetPopup.hide(`feedback-modal::${this.orderId}`);
    },
    getCourier() {
      if (this.order.delivery_type === 1) return;
      if (!this.order.id) return;

      api.lavka
        .courierInfo(this.order.id)
        .then(({ data }) => (this.courier = data?.id > 0 ? data : null));
    },
    onRepeatOrder() {
      Analitycs.logEvent(EVENTS.REPEAT_ORDER_CLICKED, {})
      this.loading = true
      let localCart = this.$store.getters['cart/items'];
      let orderItems = this.order?.items;

      // Создаем набор имен продуктов из заказа для быстрого поиска
      const orderItemNames = new Set(orderItems.map(item => item.product_id));

      // Убираем все общие объекты из localCart
      localCart = localCart.filter(localItem => !orderItemNames.has(localItem.product_id));

      // Добавляем все в общий заказ и убираем is_coffee и is_sample из заказа и акционные товары
      let overallCart = [...localCart, ...orderItems].filter(item => !item.is_coffee && !item.is_sample && item.price !== 1)


      const productIndexes = overallCart.map(item => item.product_id);

      ProductBDService.getProductsByIds(productIndexes).then(res => {
        const products = overallCart.reduce((acc, item) => {
          const product = res.find(p => p.id === item.product_id);
          if (product) {
            acc.push({
              ...product,
              product_id: item.product_id,
              feature_id: item.feature_id,
              quantity: item.quantity > product.max_quantity ? product.max_quantity : item.quantity,
              max_quantity: product.max_quantity,
              added_from: item.added_from,
              price: item.price,
            });
          }
          return acc;
        }, []);

        const readyProducts = {
          items: {
            available: products.filter(item => item.max_quantity > 0),
            not_available: products.filter(item => item.max_quantity === 0),
          },
        };

        const { available, not_available } = readyProducts.items;
        if (available.length > 0) {
          if (not_available.length > 0) {
            this.loading = false;
            this.$eventBus.emit('eb_open_lost_goods_modal', {
              cb: () => this.goToCart(available),
              repeatOrderProducts: readyProducts,
            });
          } else {
            this.goToCart(available);
          }
        } else {
          this.loading = false;
          this.showNoGoodsPopup = true;
        }
      })
    },
    goToCart(products) {
      CartService.applyProducts(products).then(() => {
        this.toast.show(this.$t('productsAddedtoCart'), 'success');
        this.$router.push({ name: 'cart' });
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        order: 'Заказ',
        free: 'Бесплатно',
        toRate: 'Оценить заказ',
        repeatOrder: 'Заказать ещё раз',
        somethingWrongWithOrder: 'Что-то не так с заказом',
        leaveTipsToCourier: 'Оставить чаевые курьеру',
        careService: 'Служба заботы',
        productsAddedtoCart: 'Товары добавлены в корзину',
      },
      kk: {
        order: 'Тапсырыс',
        free: 'Тегін',
        toRate: 'Тапсырысты бағалау',
        repeatOrder: 'Тағы да тапсырыс беру',
        somethingWrongWithOrder: 'Тапсырыста қателік бар',
        leaveTipsToCourier: 'Курьерге шайпұл қалдыру',
        careService: 'Қолдау қызметі',
        productsAddedtoCart: 'Тауарлар себетке салынды',
      },
    },
  },
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0px 2px 10px rgba(51, 51, 51, 0.12);
}
</style>
