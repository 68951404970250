import { client } from '../../clients';

export default function (type, roleId) {
  return client
    .get(`/lavka-faq/v1/questions/${type}`, {
      headers: {
        'X-Role': roleId,
      },
    })
    .then(res => res.data);
}
