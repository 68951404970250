const SHIFT_DESK = 150;
const SHIFT_MOB = 30;

const isFunc = cb => {
  return cb && typeof cb === 'function';
};
export class Scroll {
  constructor(onScrolledCB, onScrollChangedCB) {
    this.onScrolledCB = onScrolledCB;
    this.onScrollChangedCB = onScrollChangedCB;
    this.shift = window.innerWidth > 768 ? SHIFT_DESK : SHIFT_MOB;
    this.lastScrollTop = 0;
    this.lastDirection = '';

    /**If you need to be able to remove the event listener later,
     * you should store the bound function in a property
     * so that you can reference the same function when removing the listener. */
    this.scrollingListener = this.scrollingListener.bind(this);
  }

  onDirectionChanged() {
    if (isFunc(this.onScrollChangedCB))
      this.onScrollChangedCB(this.lastDirection);
  }

  scrollingUp() {
    if (this.lastDirection === 'up') return;
    this.lastDirection = 'up';
    this.onDirectionChanged('up');
  }

  scrollingDown() {
    if (this.lastDirection === 'down') return;
    this.lastDirection = 'down';
    this.onDirectionChanged('down');
  }

  onScrolled() {
    if (isFunc(this.onScrolledCB)) this.onScrolledCB();
  }

  scrollingListener() {
    const st = window.pageYOffset || document.documentElement.scrollTop;
    const shift = this.shift;

    if (!(Math.abs(st - this.lastScrollTop) > shift)) return;
    this.onScrolled();

    if (st > this.lastScrollTop) {
      this.scrollingDown();
    } else {
      this.scrollingUp();
    }
    this.lastScrollTop = st <= 0 ? 0 : st;
  }

  addListener() {
    document.addEventListener('scroll', this.scrollingListener, false);
  }

  removeListener() {
    document.removeEventListener('scroll', this.scrollingListener, false);
  }
}
