<template>
  <div class="AddressSearchItem address">
    <IconCircle class="address__circle" />

    <div class="address__text">
      <div class="address__name">{{ text }}</div>
      <div class="address__description">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import IconCircle from './components/IconCircle.vue';

export default {
  components: { IconCircle },
  props: {
    text: {
      type: String,
      default: 'Байзакова, 281',
    },
    description: {
      type: String,
      default: 'Алматы',
    },
  },
};
</script>

<style scoped>
.address {
  @apply w-full flex items-start space-x-2;
}

.address__circle {
  @apply mt-0.5;
}
.address__text {
  @apply w-full;
}
.address__name {
  @apply leading-6 line-clamp-1;
}

.address__description {
  @apply text-sm leading-22 line-clamp-1 text-sirius-gray-900;
}
</style>
