export function getReferralMessage(promocode, locale = 'ru') {
  const messages = {
    ru: [
      'Закажи продукты с промокодом на 1500 тенге в Choco Рядом с доставкой за 15 минут.',
      '',
      'Примени этот промокод в корзине:',
      '',
      promocode,
      '',
      'Ссылка на приложение:',
      'https://choco.onelink.me/FbGP/puwk3gi7',
    ].join('\n'),
    kk: [
      'Choco-Рядомда 1500 теңге жеңілдікпен өнімдерге тапсырыс беріңіз. 15 минутта жеткіземіз.',
      '',
      'Бұл промокодты себетке қолданыңыз:',
      '',
      promocode,
      '',
      'Қосымшаға сілтеме',
      'https://choco.onelink.me/FbGP/puwk3gi7',
    ].join('\n'),
  };

  return messages[locale];
}
