import { ORDER_STATUSES, STATUS_STEPS } from '@services/orders/constants';

export function matchStatusWithInfo(status) {
  switch (status) {
    case ORDER_STATUSES.CREATED:
    case ORDER_STATUSES.READY:
      return {
        step: STATUS_STEPS.CREATED,
        title: 'orderHasBeenMade',
        description: 'orderIsGonnaBeAssemblingSoon',
      };

    case ORDER_STATUSES.IS_BEING_FORMED_BY_PICKER:
      return {
        step: STATUS_STEPS.PREPARING,
        title: 'weAreAssemblingOrder',
        description: 'orderIsWaitingForCourier',
      };
    case ORDER_STATUSES.FORMED_BY_PICKER:
      return {
        step: STATUS_STEPS.PREPARING,
        title: 'orderHasBeenAssembled',
        description: 'orderIsWaitingForCourier',
      };

    case ORDER_STATUSES.GIVEN_TO_COURIER:
      return {
        step: STATUS_STEPS.DELIVERING,
        title: 'orderIsDelivering',
        description: 'weWillTellWhenCourierCome',
      };

    case ORDER_STATUSES.ARRIVED_TO_ADDRESS:
      return {
        step: STATUS_STEPS.DELIVERED,
        title: 'courierHasCome',
        description: 'courierHasComeAtYourAddress',
      };

    case ORDER_STATUSES.DELIVERED:
      return {
        step: STATUS_STEPS.DELIVERED,
        title: 'orderHasBeenDelivered',
        description: 'thanksForChoosingUs',
      };

    case ORDER_STATUSES.CANCELED:
      return {
        step: -1,
        title: 'orderHasBeenCanceled',
        description: '',
      };

    case ORDER_STATUSES.FINISHED:
      return {
        step: STATUS_STEPS.DELIVERED,
        title: 'orderHasBeenDelivered',
        description: 'rateOrder',
      };

    default:
      return {
        step: -1,
        title: '',
        description: '',
      };
  }
}

export const LOCALIZATION = {
  messages: {
    ru: {
      orderHasBeenMade: 'Заказ оформлен',
      orderIsGonnaBeAssemblingSoon:
        'Заказ скоро начнут собирать, мы вам сообщим, когда это произойдет',
      weAreAssemblingOrder: 'Собираем заказ',
      orderIsWaitingForCourier:
        'Скоро передадим заказ курьеру, мы вам сообщим, когда это произойдет',
      orderHasBeenAssembled: 'Заказ собран',
      orderIsDelivering: 'Заказ в пути',
      weWillTellWhenCourierCome:
        'Мы сообщим вам, когда курьер прибудет по вашему адресу',
      courierHasCome: 'Курьер прибывает',
      courierHasComeAtYourAddress: 'Курьер прибывает по вашему адресу',
      orderHasBeenDelivered: 'Заказ доставлен',
      thanksForChoosingUs: 'Спасибо за то, что выбрали нас 😊',
      orderHasBeenCanceled: 'Заказ отменен',
      rateOrder: 'Оцените заказ',

      created: 'создана',
      preparing: 'собирается',
      prepared: 'собрана',
      delivering: 'доставляется',
      delivered: 'доставлена',
      partOfOrder: '{part}-ая часть заказа {status}',

      partWillDelivered: 'Скоро привезут {otherPart}-ую часть заказа',
      thanks: 'Спасибо за то, что выбрали нас ☺️',
      willPrepare: 'Совсем скоро заказ начнут собирать.',
      courierWillTake: 'Скоро передадим заказ курьеру',
      courierDriving:
        'Курьер уже везёт ваш заказ. Мы сообщим когда курьер прибудет по вашему адресу',
      courierBeingLate:
        'К сожалению, курьер задерживается. Постараемся доставить заказ как можно скорее',
    },
    kk: {
      created: 'создана',
      preparing: 'собирается',
      prepared: 'собрана',
      delivering: 'доставляется',
      delivered: 'доставлена',
      partOfOrder: '{part}-ая часть заказа {status}',

      partWillDelivered: 'Скоро привезут {otherPart}-ую часть заказа',
      thanks: 'Спасибо за то, что выбрали нас ☺️',
      willPrepare: 'Совсем скоро заказ начнут собирать.',
      courierWillTake: 'Скоро передадим заказ курьеру',
      courierDriving:
        'Курьер уже везёт ваш заказ. Мы сообщим когда курьер прибудет по вашему адресу',
      courierBeingLate:
        'К сожалению, курьер задерживается. Постараемся доставить заказ как можно скорее',

      orderHasBeenMade: 'Тапсырыс рәсімделді',
      orderIsGonnaBeAssemblingSoon:
        'Тапсырысты жақында жинап бастайды, аяқталғанда сізге хабарлаймыз',
      weAreAssemblingOrder: 'Тапсырысты жинап жатырмыз',
      orderIsWaitingForCourier:
        'Жақын арада тапсырысты курьерге береміз, ол туралы сол кезде хабарлаймыз',
      orderHasBeenAssembled: 'Тапсырыс жиналды',
      orderIsDelivering: 'Тапсырыс жолда',
      weWillTellWhenCourierCome:
        'Курьер мекенжайыңызға келгенде, сізге хабарлаймыз',
      courierHasCome: 'Курьер келді',
      courierHasComeAtYourAddress: 'Курьер мекенжайыңызға келді',
      orderHasBeenDelivered: 'Тапсырысыңыз жеткізілді',
      thanksForChoosingUs: 'Бізді таңдағыныңыз үшін рақмет 😊',
      orderHasBeenCanceled: 'Тапсырыстан бас тартылды',
      rateOrder: 'Тапсырысты бағалаңыз',
    },
  },
};
