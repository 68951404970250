import { client } from '../../clients';

export default function (products, warehouseId, abGroups) {
  return client
    .post('lavka/v2/cart/add_products', {
      products,
      warehouse_id: warehouseId,
      ab_groups: abGroups,
    })
    .then(response => response.data);
}
