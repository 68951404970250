import Vue from 'vue';
import VueSegmentAnalytics from 'vue-segment-analytics';

import { authEmitter, AUTH_EVENTS } from '@shared/services/auth/events';
import { ClientService } from '@shared/services/client';
import { pollUntillTimeout } from '@/utils/functions/poll-untill-timeout';

const KEY = import.meta.env.VITE_SEGMENT_KEY;

const getSegment = () => window.analytics;

/**
 * Трекает ивент Сегмента
 * @param {Sting} event название ивента
 * @param {Object} data параметры ивента
 */
const trackEvent = (event, data) => {
  if (window.analytics) window.analytics.track(event, data);
};

/**
 * Возвращает Promise, возвращающий anonymousId сегмента
 * @returns {Promise<String>} Стринг с айдишкой
 */
const getId = () => {
  return new Promise(resolve => {
    pollUntillTimeout(() => {
      return window.analytics && window.analytics.user;
    })
      .then(() => {
        resolve(window.analytics.user().anonymousId());
      })
      .catch(() => {
        resolve(-1);
      });
  });
};

/**
 * Подвязывает к сегменту ID юзера
 */
const identify = () => {
  if (!getSegment()) return;

  const userId = ClientService.getUserId();
  if (userId !== -1) getSegment().identify(userId);
};

/**
 * Иницализирует Сегмент
 * Подписывается на авторизацию и запускает идентификацию юзера
 */
const init = () => {
  Vue.use(VueSegmentAnalytics, { id: KEY });

  authEmitter.on(AUTH_EVENTS.ON_AUTH, identify);
};

export const SegmentService = {
  init,
  getId,
  trackEvent,
};
