<template>
  <OldEmptyAddress v-if="old" @onCreate="$emit('onCreate')" />
  <div v-else class="EmptyAddresses ea">
    <img
      class="ea__image"
      src="./img/location.png"
      width="192"
      height="192"
      alt="локация"
    />
    <div class="ea__content">
      <p class="ea__title" v-html="$t('nothing')" />
      <p class="ea__subtitle" v-html="$t('plsAddAddress')" />
    </div>
  </div>
</template>

<script>
import OldEmptyAddress from './components/OldEmptyAddress.vue';

export default {
  components: { OldEmptyAddress },
  props: {
    old: Boolean,
  },
  i18n: {
    messages: {
      ru: {
        nothing: 'Здесь пока ничего нет',
        plsAddAddress: 'Добавьте адрес доставки,<br/> чтобы сделать заказ',
      },
      kk: {
        nothing: 'Әзір ештеңе сақталмаған',
        plsAddAddress: 'Тапсырыс беру үшін,<br/> тапсырыс мекенжайын қосыңыз',
      },
    },
  },
};
</script>

<style scoped>
.ea {
  @apply flex flex-col text-center
    md:flex-row md:text-left md:space-x-3;
}
.ea__image {
  @apply mt-16 object-contain mx-auto
  md:mt-0 md:mx-0;
}
.ea__title {
  @apply font-bold text-2xl leading-30 
    md:mt-3 md:text-3xl md:leading-8;
}
.ea__subtitle {
  @apply mt-3 leading-5 text-sirius-gray-900
  md:mt-6;
}
@media (min-width: 768px) {
  .ea__image {
    width: 288px;
    height: 210px;
  }
}
</style>
