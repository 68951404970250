<template>
  <div></div>
</template>

<script>
import { Logger as LOG } from '@shared/services/logger';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'ContextAddView',
  created() {
    LOG.event('Context Add Deeplink', this.$route.query);
    // Источник кампании Канал Название кампании
    Analitycs.logEvent(EVENTS.CONTEXT_ADD_DEEPLINKS, {
      campaignSource: this.$route.query.campaign_source,
      campaignName: this.$route.query.campaign_name,
      chanel: this.$route.query.chanel,
    });
    if (this.$route.query.link) this.$router.push(this.$route.query.link);
    else this.$router.push({ name: 'main' });
  },
};
</script>
