<template>
  <div
    class="horizontal-product-list mx-auto"
    style="width: 100%; max-width: 988px"
  >
    <UiNavigationTitle class="px-4 md:px-0" :navigation="navigation" main>
      <slot name="title">
        {{ $t('categoryName') }}
      </slot>
    </UiNavigationTitle>
    <ProductListDesktop
      v-if="$isDesktop"
      class="mt-5"
      :products="products"
      :added-from="addedFrom"
      :navigation="navigation"
      @onCardsViewed="$emit('onCardsViewed', $event)"
    />
    <ProductListMobile
      v-else
      :products="products"
      :added-from="addedFrom"
      :navigation="navigation"
      @onCardsViewed="$emit('onCardsViewed', $event)"
    />
  </div>
</template>

<script>
import UiNavigationTitle from '@ui/UiNavigationTitle.vue';

import ProductListMobile from './ProductListMobile.vue';
import ProductListDesktop from './ProductListDesktop.vue';

export default {
  name: 'HorizontalProductList',
  components: {
    UiNavigationTitle,
    ProductListDesktop,
    ProductListMobile,
  },
  props: {
    navigation: {
      type: Object,
      default: () => {
        return { name: 'main' };
      },
    },
    products: {
      type: Array,
      default: () => [],
    },
    addedFrom: {
      type: String,
      required: true,
    },
  },

  i18n: {
    messages: {
      ru: {
        categoryName: 'Название категории',
      },
      kk: {
        categoryName: 'Категория аты',
      },
    },
  },
};
</script>
