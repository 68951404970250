<template>
  <MainLayout>
    <ProfileLayout>
      <div class="px-4 md:px-0">
        <UiRippleLoader v-if="!userProfile" class="flex justify-center" />
        <template v-else-if="false && (isEdit || !hasInfo)">
          <p class="font-bold text-2xl">
            {{
              isEdit
                ? 'Редактировать личные данные'
                : 'Заполните информацию о себе'
            }}
          </p>
          <ProfileAvatar src="" class="mt-9" />
          <div class="mt-8">
            <UiTextField
              v-model="$v.name.$model"
              size="lg"
              name="username"
              error-message="Обязательное поле"
              label="Введите имя и фамилию"
              :invalid="$v.name.$error"
            />
            <!-- <UiTextField
              v-model="email"
              class="mt-4"
              name="username"
              label="Введите email"
              size="lg"
            /> -->
          </div>

          <UiButton
            size="lg"
            variant="gray"
            class="px-8 mt-8 font-bold text-lg"
            @click="onSubmit"
          >
            Сохранить изменения
          </UiButton>
        </template>
        <template v-else-if="false && !hasInfo">
          <p class="font-bold text-2xl">Заполните информацию о себе</p>
          <ProfileAvatar src="" class="mt-9 mx-auto" />
          <div class="mt-8">
            <UiTextField
              v-model="name"
              name="username"
              label="Введите имя и фамилию"
              size="lg"
            />
            <!-- <UiTextField
              v-model="name"
              class="mt-4"
              name="username"
              label="Введите email"
              size="lg"
            /> -->
          </div>

          <UiButton
            size="lg"
            variant="gray"
            class="px-8 mt-8 font-bold text-lg"
            :disabled="true"
          >
            Сохранить изменения
          </UiButton>
        </template>
        <template v-else>
          <div class="flex items-center">
            <img
              :src="avatar"
              class="rounded-full object-contain"
              style="width: 68px; height: 68px; background: #f1f2f7"
              width="68"
              height="68"
              alt=""
            />
            <p class="ml-5 font-bold text-2xl">
              {{ userProfile.first_name }} {{ userProfile.last_name }}
            </p>
            <div
              v-if="false"
              class="ml-auto cursor-pointer flex items-center font-medium text-sirius-orange-100"
              @click="editProfile"
            >
              <IconEdit class="mr-2" />
              редактировать
            </div>
          </div>
          <div class="mt-6 md:mt-9">
            <p class="font-medium text-sirius-gray-700 text-sm md:text-base">
              {{ $t('phoneNumber') }}
            </p>
            <p class="font-medium md:text-2xl">{{ userPhoneNumber }}</p>
          </div>

          <div v-if="userProfile.email" class="mt-6 md:mt-7">
            <p class="font-medium text-sirius-gray-700 text-sm md:text-base">
              email
            </p>
            <p class="font-medium md:text-2xl">{{ userProfile.email }}</p>
          </div>

          <div
            class="mt-15 md:mt-24 flex items-center cursor-pointer font-bold text-lg text-sirius-red-200"
            @click="logout"
          >
            <IconLogout class="mr-2 md:mr-3" /> {{ $t('logout') }}
          </div>
        </template>
        <!-- <template v-else>
          <div>
            Вы не вошли
          </div>
        </template> -->
      </div>
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { api } from '@shared/services/api';
import { validationMixin } from 'vuelidate';
import { UserService } from '@services/user';
import { required } from 'vuelidate/lib/validators';
import { AuthService } from '@shared/services/auth';

import ProfileAvatar from '@shared/components/profile/ProfileAvatar.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import UiRippleLoader from '@shared/components/ui/UiRippleLoader.vue';
import MainLayout from '@shared/components/layout/MainLayout.vue';
import IconLogout from '@shared/components/icons/IconLogout.vue';
import IconEdit from '@shared/components/icons/IconEdit.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiButton from '@ui/UiButtonNew.vue';

export default {
  name: 'ProfileView',
  components: {
    UiRippleLoader,
    ProfileAvatar,
    ProfileLayout,
    UiTextField,
    IconLogout,
    MainLayout,
    UiButton,
    IconEdit,
  },
  mixins: [validationMixin],
  inject: ['toast'],
  data() {
    return {
      isEdit: false,
      name: '',
      email: '',
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
    },
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    avatar() {
      return (
        this.userProfile?.avatar ||
        new URL('@img/profile/default-avatar.svg', import.meta.url).href
      );
    },
    hasInfo() {
      return (
        this.userProfile &&
        this.userProfile.first_name &&
        this.userProfile.last_name &&
        this.userProfile.email &&
        this.userProfile.phone
      );
    },
    userPhoneNumber() {
      if (this.userProfile && this.userProfile.phone) {
        const numbers = this.userProfile.phone.split('');
        const splice = (ind1, ind2) => {
          return numbers.splice(ind1, ind2).join('');
        };
        const number = `+ ${splice(0, 1)} (${splice(0, 3)}) ${splice(
          0,
          3
        )} ${splice(0, 2)} ${splice(0, 2)} ${splice(0, numbers.length)}`;
        return number;
      }
      return '';
    },
  },
  mounted() {
    if (!this.userProfile) UserService.loadUserProfile();
  },
  methods: {
    resetData() {
      this.name = '';
      this.email = '';
    },
    constractEdit() {
      this.resetData();
      if (this.userProfile.first_name && this.userProfile.last_name) {
        this.name = `${this.userProfile.first_name} ${this.userProfile.last_name}`;
      }
      if (this.userProfile.email) {
        this.email = this.userProfile.email;
      }
    },
    editProfile() {
      this.isEdit = true;
      this.constractEdit();
    },
    buildPayload() {
      const name = this.name.split(' ');
      const firstName = name[0];
      const lastName = name[1];
      const payload = {
        attributes: {
          first_name: firstName,
          last_name: lastName,
        },
      };
      return payload;
    },
    onSubmit() {
      this.$v.$touch();

      if (this.name.trim() === '') {
        this.toast.show('Пожалуйста введите имя и фамилию', 'info');
        return;
      }
      const payload = this.buildPayload();

      api.lavka
        .updateRahmetUser(payload)
        .then(() => {
          return UserService.loadUserProfile();
        })
        .catch(err => {
          this.toast.show(err.message, 'error');
        })
        .finally(() => (this.isEdit = false));
    },
    logout() {
      AuthService.logoutWeb();
    },
  },
  i18n: {
    messages: {
      ru: {
        phoneNumber: 'номер телефона',
        logout: 'Выйти из аккаунта',
      },
      kk: {
        phoneNumber: 'телфон нөміріңіз',
        logout: 'Аккаунттан шығу',
      },
    },
  },
};
</script>
