<template>
  <div class="">
    <p
      v-if="showMe !== id && isNotEmpty"
      class="text-sm cursor-pointer"
      @click="showMe = id"
    >
      show more
    </p>
    <p v-if="showMe === id" class="text-sm cursor-pointer" @click="showMe = ''">
      hide
    </p>

    <pre
      v-if="showMe === id"
      class="mt-1 text-xs whitespace-pre-wrap break-all"
      @click="showMe = ''"
      >{{ data.key }}</pre
    >
  </div>
</template>

<script>
export default {
  name: 'ShowMoreView',
  props: {
    id: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showMe: '',
    };
  },
  computed: {
    isNotEmpty() {
      if (this.data && this.data.key && typeof this.data.key === 'object')
        return Object.keys(this.data.key).length;
      return this.data && String(this.data.key);
    },
  },
};
</script>
