<template>
  <div class="">
    <section
      v-if="productsCount"
      class="font-medium text-sirius-black-100 bg-sirius-gray-500"
    >
      <div
        v-if="productsCount > 2"
        class="flex items-center justify-between p-4"
      >
        <p class="text-sirius-red-400 text-xs">{{ $t('outOfStock') }}</p>
        <img
          :src="cancelIcon"
          alt=""
          class="cursor-pointer"
          @click="removeUnavailable"
        />
      </div>
      <div
        v-if="productsCount > 2"
        class="relative flex items-center h-16 mx-auto overflow-hidden"
        :class="{
          '-space-x-6 xs:-space-x-4 ': productsCount > 6,
          '-space-x-4 xs:-space-x-2 ': productsCount > 5,
          '-space-x-2 xs:-space-x-0 ': productsCount > 4,
          '-space-x-0 xs:-space-x-2 ': productsCount > 3,
        }"
        style="width: calc(100% - 32px)"
      >
        <div
          v-for="(item, index) in products"
          :key="item.id"
          class="relative inline-block h-16 w-16 rounded-full border-2 border-sirius-gray-300 overflow-hidden bg-white"
          :class="{ hidden: productsCount === 7 ? index >= 7 : index >= 6 }"
        >
          <img
            class="w-full h-full p-1"
            style="filter: grayscale(100%)"
            :src="item.path"
            alt=""
            @click="goToFullProduct(item)"
          />
        </div>
        <div
          v-if="productsCount > 7"
          class="relative h-16 w-16 rounded-full border-2 border-sirius-gray-300 overflow-hidden bg-white flex items-center justify-center font-medium text-2xl"
        >
          +{{ productsCount - 6 }}
        </div>
      </div>

      <div v-else>
        <CartProduct
          v-for="item in products"
          :key="item.id"
          :product="item"
          :is-available="false"
        />
      </div>

      <div
        class="p-4 flex items-center justify-between cursor-pointer"
        @click="goToMain"
      >
        <p class="font-bold">
          {{ $t('toSelectAnotherProducts') }}
        </p>
        <div
          class="transform transition"
          :class="[
            productsCount > 2 || true
              ? ''
              : toggled
                ? '-rotate-90'
                : 'rotate-90',
          ]"
        >
          <!-- prettier-ignore -->
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14Z" fill="#F1F2F7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4142 21.457L19.1642 14.707C19.5547 14.3165 19.5547 13.6833 19.1642 13.2928L12.4142 6.54282L11 7.95703L17.0429 13.9999L11 20.0428L12.4142 21.457Z" fill="#2E323D"/>
          </svg>
        </div>
      </div>

      <div
        v-if="productsCount <= 2 && toggled"
        class="p-4 pt-0 flex overflow-scroll hidden-scrollbar border-b border-sirius-gray-200"
      >
        <div
          v-for="item in products"
          :key="`${item.id}_${item.category_id}`"
          class="mr-2 last:mr-0 last:border-r-16 border-transparent"
        >
          <ProductCardWrapper
            :added-from="PRODUCT_ADDED.RECOMMENDATION"
            :product="item"
            style="min-width: 116px; max-width: 116px"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PRODUCT_ADDED } from '@shared/config/product';

import CartProduct from '@shared/components/product/CartProduct.vue';
import ProductCardWrapper from '@shared/components/product/ProductCardWrapper.vue';

export default {
  name: 'LostGoods',
  components: {
    ProductCardWrapper,
    CartProduct,
  },
  data: () => ({
    PRODUCT_ADDED,
    toggled: false,
    cancelIcon: new URL('@img/icons/cancel.svg', import.meta.url).href,
  }),
  computed: {
    ...mapGetters('cart', {
      products: 'noItems',
    }),
    productsCount() {
      return this.products.length;
    },
  },
  methods: {
    ...mapActions('cart', { removeUnavailable: 'REMOVE_UNAVAILABLE' }),
    goToMain() {
      this.$router.push({ name: 'main' });
    },
    goToFullProduct(item) {
      const query = item.added_from ? { added_from: item.added_from } : null;

      if (item.is_sample) return;
      if (this.$isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: item.product_id.toString(),
          },
          query,
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: item.product_id,
          query,
        });
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        outOfStock: 'Нет в наличии',
        toSelectAnotherProducts: 'Подобрать другие товары',
      },
      kk: {
        outOfStock: 'Қазір жоқ',
        toSelectAnotherProducts: 'Басқа өнім қарастыру',
      },
    },
  },
};
</script>

<style scoped></style>
