<template>
  <div class="cashbacks-active">
    <p class="cashbacks-title" v-html="$t('title')" />

    <div class="cashbacks-descr">
      <p v-html="$t('descr')" />
      <p class="mt-4" v-html="$t('descr2')" />
    </div>

    <CashbackBoxWrapper :conditions="conditions" class="mt-10 md:mt-12" />
  </div>
</template>

<script>
import CashbackBoxWrapper from '../CashbackBox/CashbackBoxWrapper.vue';

export default {
  name: 'ActiveCashbacks',
  components: { CashbackBoxWrapper },
  props: {
    conditions: {
      type: Array,
      required: true,
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'Тапсырыстар үшін кешбэк алыңыз',
        descr:
          'Азық-түлікке тапсырыс беріп, бірінші 5 тапсырыс үшін кешбэк ұтып алыңыз. Шарттарын төменнен көре аласыз.',
        descr2:
          'Кешбэктердің қаншалықты жиналғанын Choco қосымшасының (үстіңгі оң&nbsp;жақ бұрышында) басты бетінде жәнеде төлем жасау алдында көре аласыз',
      },
      ru: {
        title: 'Получайте кешбэк за&nbsp;заказы продуктов',
        descr: `
          Заказывайте продукты и получайте кешбэк за&nbsp;первые 5 заказов. Условия начисления можно посмотреть ниже.
        `,
        descr2:
          'Баланс кешбэка отображается на главном экране приложения Choco (в&nbsp;правом верхнем углу), a&nbsp;также при оплате.',
      },
    },
  },
};
</script>

<style>
.cashbacks-active {
  @apply mx-auto;
  max-width: 588px;
}
.cashbacks-title {
  @apply font-bold text-2xl leading-8 md:text-4xl md:leading-10;
}
.cashbacks-descr {
  @apply text-base leading-6 font-normal mt-4 md:mt-6;
}
</style>
