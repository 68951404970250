<template>
  <UiPopupV2
    :show="showMe"
    style="--popup-width: 375px; --popup-width-d: 448px; --popup-offset: 200px"
    closable
    @onOpen="onOpen"
    @onClose="onClose"
    @change="onChange"
  >
    <template #title>
      <p class="text-center">
        {{ $t('provideTipsSum') }}
      </p>
    </template>

    <UiTextField
      ref="tipsRef"
      v-model="value"
      class="mt-5 w-full"
      type="number"
      pattern="[0-9]*"
      inputmode="numeric"
      name="tip"
      clearable
    />

    <div class="flex items-center justify-between space-x-2">
      <UiButton
        size="lg"
        variant="gray"
        class="w-full text-lg font-bold mt-4"
        @click.self="cancelTipsPopup"
      >
        {{ $t('cancel') }}
      </UiButton>
      <UiButton
        size="lg"
        class="w-full text-lg font-bold mt-4"
        @click.self="onClose"
      >
        {{ $t('done') }}
      </UiButton>
    </div>
  </UiPopupV2>
</template>

<script>
import UiPopupV2 from '@shared/components/ui/ui-popup/UiPopupV2.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiButton from '@ui/UiButtonNew.vue';
import { i18n } from './i18n';

export default {
  name: 'TipsPopup',
  components: {
    UiTextField,
    UiButton,
    UiPopupV2,
  },
  model: {
    prop: 'showMe',
    event: 'change',
  },
  props: {
    showMe: Boolean,
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    onOpen() {
      this.$refs.tipsRef.focusIt();
    },
    onClose() {
      this.$emit('onChangeCustomTip', this.value);
    },
    cancelTipsPopup() {
      this.value = '';
      this.onClose();
    },
    onChange(value) {
      this.$emit('change', value);
    },
  },
  i18n,
};
</script>
