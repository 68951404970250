import { store } from '@/store';
import { getLastOrderAddress } from '../get-last-order-address';
import { $log } from '@/utils/plugins/logger';

// store.getters['user/presumptiveAddress'] — SET_PRESUMPTIVE_ADDRESS
// store.commit('user/SET_PRESUMPTIVE_ADDRESS')

function log(...args) {
  $log(args[0], {}, `${args[1]} ${args[2]}`);
}

let decided = false;

/**
 * @see https://www.figma.com/file/cgbQlb5tNqmNhT9OXDP5XI/%F0%9F%97%BA%EF%B8%8F-%D0%90%D0%B4%D1%80%D0%B5%D1%81%D0%B0_%D0%B2%D1%81%D0%B5-%D1%87%D1%82%D0%BE-%D1%81-%D0%BD%D0%B8%D0%BC%D0%B8-%D1%81%D0%B2%D1%8F%D0%B7%D0%B0%D0%BD%D0%BE-(Copy)?type=design&node-id=338-45533&mode=design&t=IUFtPcEAtIFq1JlY-4
 */
export function decideToShowAddressConfirmation({
  isGeoGranted = true,
  isNear = false,
}) {
  const addresses = store.getters['user/addresses'];
  const deliveryAddress = store.getters['user/deliveryAddress'];

  // Временно выключено, пока авторизация не раскатится на всех пз
  if (!import.meta.env.VITE_FEATURE_ADDRESS_CONFIRMATION) {
    return false;
  }

  if (!addresses.length || !deliveryAddress || decided) {
    return false;
  }

  decided = true;
  const lastOrderAddress = getLastOrderAddress();
  const lastAddedAddress = addresses[0];

  if (isGeoGranted) {
    // Если пользователь находится по адресу B (этот адрес есть в адресах),
    // предыдущий заказ был по адресу А — показываем подсказку с выбранным адресом B
    if (lastOrderAddress.id !== deliveryAddress.id) {
      store.commit('user/SET_PRESUMPTIVE_ADDRESS', deliveryAddress);
      /* prettier-ignore */ log('decideToShowAddressConfirmation', 'isGeoGranted', 'lastOrderAddress.id !== deliveryAddress.id' );
    }

    // Если пользователь находится по адресу C (адреса нет в адресах),
    // предыдущий заказ был по адресу А — то показываем подсказку с выбранным адресом А
    if (!isNear && lastOrderAddress) {
      store.commit('user/SET_PRESUMPTIVE_ADDRESS', lastOrderAddress);
      /* prettier-ignore */ log('decideToShowAddressConfirmation', 'isGeoGranted', '!isNear && lastOrderAddress');
    }

    // Если пользователь находится по адресу C (адреса нет в адресах),
    // и у него еще не было заказов — то показываем подсказку с последним добавленным адресом
    if (!lastOrderAddress && !isNear) {
      store.commit('user/SET_PRESUMPTIVE_ADDRESS', lastAddedAddress);
      /* prettier-ignore */ log('decideToShowAddressConfirmation', 'isGeoGranted', '!lastOrderAddress && !isNear');
    }
  } else {
    /*
      Если у пользователя выключена геопозиция, то при входе в приложение,
      мы всегда  показываем подсказку. В подсказке отображается адрес последнего заказа.
      Если есть добавленные адреса, но нет заказов, то в подсказке показывается
      последний добавленный адрес
    */
    if (lastOrderAddress) {
      store.commit('user/SET_PRESUMPTIVE_ADDRESS', lastOrderAddress);
      /* prettier-ignore */ log('decideToShowAddressConfirmation', '!isGeoGranted', 'lastOrderAddress');
    } else {
      store.commit('user/SET_PRESUMPTIVE_ADDRESS', lastAddedAddress);
      /* prettier-ignore */ log( 'decideToShowAddressConfirmation', '!isGeoGranted', '!lastOrderAddress' );
    }
  }
}
