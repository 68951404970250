import { client } from '../../clients';

export function rSendFeedback(payload) {
  return client
    .post('/ryadom-review/api/v1/review/category', payload, {
      useIdempotencyKey: true,
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: v => JSON.stringify(v),
      transformRequest: [v => JSON.stringify(v)],
    })
    .then(res => res.data);
}
