<template>
  <UiBottomSheetWrapper
    :show="open"
    with-title
    with-back-button
    class="MPromocodeModal"
    @change="$emit('onModalChange', $event)"
  >
    <form class="" @submit.prevent="onSubmit">
      <h3 class="MPromocodeModal__title">
        {{ $t('enterPromocode') }}
      </h3>
      <UiInput
        ref="input"
        v-model="localValue"
        name="promocode"
        class="MPromocodeModal__input"
        :placeholder="$t('yourPromocode')"
        :valid="success"
        :invalid="!!errorMessage && !changed"
        transform-uppercase
        transform-trim
        clearable
      />
      <div
        v-if="!!errorMessage && !changed"
        class="MPromocodeModal__msg MPromocodeModal__msg_error"
      >
        {{ errorMessage }}
      </div>
      <div
        v-if="success"
        class="MPromocodeModal__msg MPromocodeModal__msg_success"
      >
        {{ $t('promocodeHasBeenApplied') }}
      </div>
      <div
        v-if="success && freeDeliveryNotApplied"
        class="MPromocodeModal__freeDeliveryNotAppliedNote"
      >
        <UiInlineIcon
          name="tooltip"
          class="MPromocodeModal__freeDeliveryNotAppliedIcon"
        />
        <p v-html="$t('freeDeliveryPromocodeNotApplied')" />
        <p style="margin-top: 8px" v-html="$t('canBeAppliedNextTime')" />
      </div>

      <UiButton
        class="MPromocodeModal__btnSubmit w-full font-bold text-lg"
        size="lg"
      >
        {{ $t(changed || errorMessage ? 'toConfirm' : 'done') }}
      </UiButton>
    </form>
  </UiBottomSheetWrapper>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiInput from '@pure-ui/components/UiInput/UiInput.vue';
import UiInlineIcon from '../UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MPromocodeModal',
  components: { UiBottomSheetWrapper, UiButton, UiInput, UiInlineIcon },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    freeDeliveryNotApplied: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localValue: '',
  }),
  computed: {
    changed() {
      return this.localValue !== this.value;
    },
    success() {
      return !!(this.value && !this.changed && !this.errorMessage);
    },
  },
  watch: {
    value(v) {
      this.localValue = v;
    },
    open() {
      // фокус на инпуте когда открываем модалку
      if (this.open) {
        // модалка ещё не отрисовала содержимое
        this.$nextTick(() => {
          this.$refs.input.focusIt();
        });
      }
    },
  },
  created() {
    this.localValue = this.value;
  },
  methods: {
    onSubmit() {
      if (this.changed || this.errorMessage) {
        this.$refs.input.focusIt();
        this.$emit('onSubmit', this.localValue);
      } else {
        this.$emit('onModalChange', false);
      }
    },
  },
  i18n: {
    messages: {
      kk: {
        yourPromocode: 'Сіздің промокодыңыз',
        enterPromocode: 'Промокод енгізіңіз',
        promocodeHasBeenApplied: 'Промокод дұрыс',
        toConfirm: 'Подтвердить',
        done: 'Дайын',
        freeDeliveryPromocodeNotApplied: `
          Промокод қолданылмады, себебі бұл тапсырыста
          жеткізу тегін шарты бар. 
        `,
        canBeAppliedNextTime: `
          Оны келесі ақылы тапсырыстарда қолдануға болады
        `,
      },
      ru: {
        yourPromocode: 'Ваш промокод',
        enterPromocode: 'Введите промокод',
        promocodeHasBeenApplied: 'Промокод верный',
        toConfirm: 'Подтвердить',
        done: 'Готово',
        freeDeliveryPromocodeNotApplied: `
          Промокод не&nbsp;применён, так как в&nbsp;заказе
          уже действуют условия бесплатной доставки. 
        `,
        canBeAppliedNextTime: `
          Его можно будет применить в&nbsp;следующем заказе
          с&nbsp;платной доставкой.
        `,
      },
    },
  },
};
</script>

<style scoped>
.MPromocodeModal__title {
  font-size: 24px;
  font-weight: 900;
  line-height: 115%;
  letter-spacing: 0.36px;
}

.MPromocodeModal__input {
  margin-top: 16px;
}

.MPromocodeModal__btnSubmit {
  margin-top: 24px;
}

.MPromocodeModal__msg {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.06px;
  margin-top: 4px;
}

.MPromocodeModal__msg_error {
  color: #f91f01;
}

.MPromocodeModal__msg_success {
  color: #05bf0d;
}

.MPromocodeModal__freeDeliveryNotAppliedNote {
  position: relative;
  margin-top: 12px;
  font-size: 16px;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.08px;
  padding-left: 32px;
  color: #7b7a8c;
}

.MPromocodeModal__freeDeliveryNotAppliedIcon {
  position: absolute;
  top: 0;
  left: 0;
  color: #a4a2b7;
}
</style>
