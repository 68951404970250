<template>
  <router-link class="WhiteBreadcrumbs" tag="div" to="/">
    <svg
      class="WhiteBreadcrumbs__icon"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 13L5 8L10 3"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    {{ $t('returnToMain') }}
  </router-link>
</template>

<script>
export default {
  name: 'WhiteBreadcrumbs',
  i18n: {
    messages: {
      ru: {
        returnToMain: 'Вернуться на главную',
      },
      kk: {
        returnToMain: 'Бастапқы мәзірге оралу',
      },
    },
  },
};
</script>

<style>
.WhiteBreadcrumbs {
  color: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}
.WhiteBreadcrumbs__icon {
  margin-right: 8px;
}
</style>
