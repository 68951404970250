export default function (state) {
  const data = JSON.parse(atob(state));
  const queries = { ...data };
  delete queries.route;

  let url = data.route;

  if (Object.keys(queries).length) {
    url += '?';
    const arr = [];

    Object.entries(queries).forEach(([key, val]) => {
      arr.push(`${key}=${val}`);
    });

    url += arr.join('&');
  }

  return url;
}
