<template>
  <div
    class="px-3 h-10 rounded-xl bg-sirius-gray-200 leading-tight flex items-center space-x-2"
  >
    <p
      class="cursor-pointer hover:text-sirius-orange-100"
      :class="{ 'text-sirius-orange-100': locale === 'kk' }"
      @click="changeLocale('kk')"
    >
      қаз
    </p>
    <div class="h-5 border-r border-sirius-gray-300"></div>
    <p
      class="cursor-pointer hover:text-sirius-orange-100"
      :class="{ 'text-sirius-orange-100': locale === 'ru' }"
      @click="changeLocale('ru')"
    >
      рус
    </p>
  </div>
</template>

<script>
import { LocaleService } from '@shared/services/locale';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'LocaleToggle',

  data() {
    return {
      locale: 'ru',
    };
  },
  mounted() {
    this.locale = LocaleService.getLocale();
  },
  methods: {
    changeLocale(lang) {
      this.locale = lang;
      LocaleService.setLocale(lang);
      Analitycs.logEvent(EVENTS.LANGUAGE_CHANGE, {
        language: lang,
      });
    },
  },
};
</script>
