import { store } from '@/store';

import { fetchSurveys } from './lib/fetch-surveys';
import { sendFeedback } from './lib/send-feedback';
import {
  logBannerClick,
  logScorePick,
  logClosed,
  logSend,
} from './lib/log-analytics';

/**
 * Запускается при загрузке если авторизован
 */
function loadSurveys() {
  return fetchSurveys().then(surveys => {
    store.commit('categoryNps/SET_SURVEYS', surveys);
  });
}

export const CategoryNpsService = {
  loadSurveys,
  sendFeedback,
  analytics: {
    logBannerClick,
    logScorePick,
    logClosed,
    logSend,
  },
};
