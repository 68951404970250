<template>
  <div></div>
</template>

<script>
import { Logger as LOG } from '@shared/services/logger';
// import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { ReferralService } from '@services/referral';

/**
 * Страница, на которую будут попадать
 * приглашённые по промокоду п-ли
 */
export default {
  name: 'PromocodeView',
  inject: ['toast'],
  props: {
    /**
     * Приходит из роутера
     */
    promocode: {
      type: String,
      required: true,
    },
  },
  created() {
    LOG.event('Promocode deeplink', this.$route.query, this.promocode);
    // Analitycs.logEvent(TODO_CREATE_EVENT, {
    //   promocode: this.promocode,
    // });
    // todo: remove toast
    /* eslint-disable */
    this.toast.show('Промокод применён ' + this.promocode, 'success');
    ReferralService.savePromocode(this.promocode);
    this.$router.push({ name: 'main' });
  },
};
</script>
