import Story1kk from '@img/static-stories/lamber-1-kk.jpg';
import Story1ru from '@img/static-stories/lamber-1-ru.jpg';
import imageDesktopKk from '@img/category-banners/lamber-d-kk.jpg';
import imageMobileKk from '@img/category-banners/lamber-m-kk.jpg';
import imageDesktopRu from '@img/category-banners/lamber-d-ru.jpg';
import imageMobileRu from '@img/category-banners/lamber-m-ru.jpg';

export const categoryLamberBanner = {
  type: 'categoryBanner',
  code: 'category_lamber',
  imageDesktopKk,
  imageDesktopRu,
  imageMobileKk,
  imageMobileRu,
  videoMobileKk:
    'https://player.vimeo.com/progressive_redirect/playback/1007938757/rendition/360p/file.mp4?loc=external&signature=3cfab8682f55346d9ca9aef3ef0b1e503ee0b9868d1cfe6d2c070d9b04c25dbd',
  videoMobileRu:
    'https://player.vimeo.com/progressive_redirect/playback/1007938779/rendition/360p/file.mp4?loc=external&signature=d852c126114de173235187843fe775ed9feac9531973fe5126bc216bb8dae225',
  videoDesktopKk:
    'https://player.vimeo.com/progressive_redirect/playback/1008339540/rendition/540p/file.mp4?loc=external&signature=0c995337b4c0f17ea0a9447c3165212e2f92c1b20d284e8feec34888202d52af',
  videoDesktopRu:
    'https://player.vimeo.com/progressive_redirect/playback/1008339540/rendition/540p/file.mp4?loc=external&signature=0c995337b4c0f17ea0a9447c3165212e2f92c1b20d284e8feec34888202d52af',
  stories: [
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
      videoKk:
        'https://player.vimeo.com/progressive_redirect/playback/1008370263/rendition/720p/file.mp4?loc=external&signature=5b0810bf0fe0cbccd1319db28faed195f775afa0c007c8fb2029c70307d40733',
      videoRu:
        'https://player.vimeo.com/progressive_redirect/playback/1008370295/rendition/720p/file.mp4?loc=external&signature=e9f104d8de71ac096a9d7a667630673bb0ae66072f7c0de2ee21ddd5e5a37019',
    },
  ],
};
