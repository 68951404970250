<template>
  <div class="TopHeroMobile">
    <div class="TopHeroMobile__bg" />
    <div class="TopHeroMobile__decor">
      <img class="TopHeroMobile__tree" src="./images/tree.png" width="196" />
      <img class="TopHeroMobile__ball" src="./images/ball.png" width="36" />
      <img class="TopHeroMobile__bell" src="./images/bell.png" width="52" />
    </div>
    <div class="TopHeroMobile__container">
      <WhiteBreadcrumbs v-if="!$isWebView" class="TopHeroMobile__breadcrumbs" />
      <h1 class="TopHeroMobile__title" v-html="$t('xmasFatherNear')" />
      <div class="TopHeroMobile__descr" v-html="$t('descr')" />
      <ReviewsList class="TopHeroMobile__reviews" />
    </div>
  </div>
</template>

<script>
import WhiteBreadcrumbs from '../WhiteBreadcrumbs/WhiteBreadcrumbs.vue';
import ReviewsList from '../ReviewsList/ReviewsList.vue';

export default {
  name: 'TopHeroMobile',
  components: {
    ReviewsList,
    WhiteBreadcrumbs,
  },
  i18n: {
    messages: {
      ru: {
        xmasFatherNear: 'Дед&nbsp;Мороз Рядом',
        descr: 'Розыгрыш завершён',
      },
      kk: {
        xmasFatherNear: 'Рядом&nbsp;Аяз Атасы',
        descr: 'Ұтыс аяқталды',
      },
    },
  },
};
</script>

<style scoped>
.TopHeroMobile {
  position: relative;
  overflow: hidden;
  color: #fff;
  z-index: 0;
  min-height: 500px;
}

.TopHeroMobile__bg {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 600px;
  margin: auto;
  height: 380px;
  border-radius: 0 0 72px 72px;
  background: url('./images/bg.jpg') no-repeat center top / 600px 380px;
}

.TopHeroMobile__container {
  position: relative;
  width: calc(100% - 32px);
  margin: auto;
  padding-top: 72px;
}

.TopHeroMobile__breadcrumbs {
  position: absolute;
  left: 0;
  top: 26px;
}

.TopHeroMobile__title {
  max-width: 315px;
  margin: auto;

  color: var(--text-text-invert, #fff);
  text-align: center;
  text-shadow: 0px 4px 24px rgba(237, 86, 108, 0.48);

  /* web/h2 web */
  font-size: 48px;
  font-weight: 700;
  line-height: 56px; /* 116.667% */
}

.TopHeroMobile__descr {
  padding-top: 20px;
  margin: auto;
  max-width: 328px;

  color: var(--text-text-invert, #fff);
  text-align: center;
  text-shadow: 0px 2px 16px rgba(255, 54, 85, 0.24);

  /* mobile/body/large, decorative/medium 18 */
  font-size: 18px;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}

.TopHeroMobile__reviews {
  position: relative;
  max-width: 323px;
  margin: auto;
  margin-top: 50px;
}

.TopHeroMobile__decor {
  position: relative;
  max-width: 375px;
  margin: auto;
}

.TopHeroMobile__tree {
  position: absolute;
  top: 182px;
  left: 240px;
}

.TopHeroMobile__ball {
  position: absolute;
  left: 20px;
  top: 250px;
}

.TopHeroMobile__bell {
  position: absolute;
  left: -10px;
  top: 320px;
}
</style>
