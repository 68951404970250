<template>
  <UiBottomSheetWrapper
    :show="open"
    with-title
    with-back-button
    class="AddPromocodeModal"
    @change="$emit('onClose')"
  >
    <template #title>{{ $t('enterPromocode') }}</template>
    <form class="" @submit.prevent="onSubmit">
      <UiInput
        ref="input"
        v-model="localValue"
        name="promocode"
        class="AddPromocodeModal__input"
        :placeholder="$t('yourPromocode')"
        transform-uppercase
        transform-trim
        clearable
      />
      <div v-if="!!errorMessage" class="AddPromocodeModal__errorMsg">
        {{ errorMessage }}
      </div>

      <UiButton
        class="AddPromocodeModal__btnSubmit w-full font-bold text-lg"
        size="lg"
      >
        {{ $t(localValue.trim() === '' ? 'done' : 'toAddPromocode') }}
      </UiButton>
    </form>
  </UiBottomSheetWrapper>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiInput from '@pure-ui/components/UiInput/UiInput.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

import { PromocodesService } from '@services/promocodes';

export default {
  name: 'AddPromocodeModal',
  components: { UiBottomSheetWrapper, UiInput, UiButton },
  inject: [],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localValue: '',
    errorMessage: '',
  }),

  watch: {
    async open(v) {
      if (v) {
        this.localValue = '';
        this.errorMessage = '';
        await this.$nextTick();
        this.$refs.input.focusIt();
      }
    },
    localValue() {
      this.errorMessage = '';
    },
  },
  methods: {
    onSubmit() {
      if (this.localValue.trim() === '') {
        Analitycs.logEvent(EVENTS.SUBMIT_ADD_PROMOCODE_MODAL, {
          type: 'close',
        });
        return this.$emit('onClose');
      }
      PromocodesService.setLoader(true);
      PromocodesService.savePromocode(this.localValue)
        .then(() => {
          Analitycs.logEvent(EVENTS.SUBMIT_ADD_PROMOCODE_MODAL, {
            type: 'success',
            promocode: this.localValue,
          });
          this.$emit('onSuccess', false);
        })
        .catch(err => {
          Analitycs.logEvent(EVENTS.SUBMIT_ADD_PROMOCODE_MODAL, {
            type: 'error',
            promocode: this.localValue,
            error: err.message,
          });
          this.errorMessage = err.message;
          this.$refs.input.focusIt();
        })
        .finally(() => {
          PromocodesService.setLoader(false);
        });

      return null;
    },
  },
  i18n: {
    messages: {
      kk: {
        enterPromocode: 'Промокод енгізіңіз',
        done: 'Дайын',
        toAddPromocode: 'Промокод қосу',
        yourPromocode: 'Сіздің промокод',
      },
      ru: {
        enterPromocode: 'Введите промокод',
        done: 'Готово',
        toAddPromocode: 'Добавить промокод',
        yourPromocode: 'Ваш промокод',
      },
    },
  },
};
</script>

<style scoped>
.AddPromocodeModal__title {
  font-size: 24px;
  font-weight: 900;
  line-height: 115%;
  letter-spacing: 0.36px;
}

.AddPromocodeModal__input {
  margin-top: 16px;
}

.AddPromocodeModal__btnSubmit {
  margin-top: 24px;
}

.AddPromocodeModal__errorMsg {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.06px;
  margin-top: 4px;
  color: #f91f01;
}

.AddPromocodeModal__freeDeliveryNotAppliedNote {
  position: relative;
  margin-top: 12px;
  font-size: 16px;
  line-height: 18px; /* 112.5% */
  letter-spacing: 0.08px;
  padding-left: 32px;
  color: #7b7a8c;
}

.AddPromocodeModal__freeDeliveryNotAppliedIcon {
  position: absolute;
  top: 0;
  left: 0;
  color: #a4a2b7;
}
</style>
