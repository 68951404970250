<template>
  <div class="referral-promocode">
    <span class="referral-promocode-value --no">
      {{ $t('notAvailable') }}
    </span>
  </div>
</template>

<script>
export default {
  i18n: {
    messages: {
      ru: {
        notAvailable: 'Промокод появится после 3-го заказа',
      },
      kk: {
        notAvailable: 'Промокод 3 тапсырыстан кейін',
      },
    },
  },
};
</script>

<style scoped>
.referral-promocode {
  @apply p-3 h-13 relative 
    flex items-center justify-center space-x-2
    bg-sirius-gray-200 rounded-xl md:rounded-2xl cursor-pointer;
}
.referral-promocode-value {
  @apply text-base font-bold leading-5 text-center select-all;
}
.--no {
  @apply select-none text-sirius-gray-1000;
}
</style>
