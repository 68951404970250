<template>
  <div class="ShoppingHistorySlider mt-6 md:mt-16 mb-1 md:mb-6 mx-auto">
    <HorizontalProductList
      v-if="lastPurchasedProducts.length"
      :navigation="{ name: 'shopping-history' }"
      :products="productsForSlider"
      :added-from="PRODUCT_ADDED.HISTORY_SLIDER_CART_PAGE"
      @onCardsViewed="onCardsViewed"
    >
      <template #title>
        {{ $t('youBoughtIt') }}
      </template>
    </HorizontalProductList>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  logMounted,
  logViewed,
} from '@shared/components/shopping-history/analytics';
import { PRODUCT_ADDED } from '@shared/config/product';
import { onCartLeave } from './on-cart-leave';
import HorizontalProductList from '../horizontal-products/HorizontalProductList.vue';

export default {
  name: 'ShoppingHistory',
  components: {
    HorizontalProductList,
  },
  data: () => ({
    PRODUCT_ADDED,
    analytics: {
      cardsViewed: 0,
    },
  }),
  computed: {
    ...mapGetters('shoppingHistory', ['lastPurchasedProducts', 'logId']),
    productsForSlider() {
      return this.lastPurchasedProducts.slice(0, 9);
    }
  },
  mounted() {
    this.loadHistory();
  },
  methods: {
    loadHistory() {
      this.$store
        .dispatch('shoppingHistory/FETCH_LAST_PURCHASED_PRODUCTS')
        .then(() => {
          if (this.lastPurchasedProducts.length) {
            logMounted();
            onCartLeave(this.logViewedCards);
          }
        });
    },
    logViewedCards() {
      const { cardsViewed } = this.analytics;
      if (cardsViewed && this.logId) {
        logViewed(this.logId, cardsViewed);
      }
    },
    onCardsViewed(event) {
      this.analytics.cardsViewed = event;
      console.log('onCardsViewed', this.analytics);
    },
  },
  eventBusEvents: {
    eb_on_address_change() {
      this.loadHistory();
    },
  },

  i18n: {
    messages: {
      ru: {
        youBoughtIt: 'Вы это покупали',
      },
      kk: {
        youBoughtIt: 'Сіз сатып алған тауарлар',
      },
    },
  },
};
</script>
