<template>
  <div
    class="UiSwiper ui-swiper"
    :class="{ '--pagination-left': isPaginationLeft }"
  >
    <swiper-container
      ref="swiper"
      class="swiper"
      @swiperslidechange="onSlideChange"
    >
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <slot name="slide" v-bind="{ ...slide, index }"></slot>
      </swiper-slide>
    </swiper-container>

    <slot
      v-if="navigation"
      name="navigation"
      v-bind="{ hasNext, hasPrev, prev, next }"
    >
      <SwiperArrow
        v-if="hasPrev"
        class="ui-swiper-arrow ui-swiper-prev-arrow"
        type="prev"
        @click.native="prev"
      />
      <SwiperArrow
        v-if="hasNext"
        class="ui-swiper-arrow ui-swiper-next-arrow"
        type="next"
        @click.native="next"
      />
    </slot>
  </div>
</template>

<script>
import SwiperArrow from './components/SwiperArrow.vue';
import { register } from 'swiper/element/bundle';
import { Pagination } from 'swiper/modules';
register();

export default {
  name: 'UiSwiper',
  components: {
    SwiperArrow,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
    slides: {
      type: Array,
      default: () => [],
    },
    navigation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSlide: 0,
      swiper: null,
    };
  },
  computed: {
    swiperSettings() {
      return {
        slidesPerView: 5,
        spaceBetween: 12,
        ...this.settings,
      };
    },
    hasNext() {
      if (this.settings.loop && this.slides.length > 1) return true;
      return (
        this.currentSlide <
        this.slides.length - this.swiperSettings.slidesPerView
      );
    },
    hasPrev() {
      if (this.settings.loop && this.slides.length > 1) return true;
      return this.currentSlide > 0;
    },
    isPaginationLeft() {
      return this.settings.pagination?.textAlign === 'left';
    },
  },
  mounted() {
    this.init();
    // Update of swiper can be done but not now
  },
  methods: {
    // доступен из вне
    slideTo(idx) {
      this.swiper.slideTo(idx);
    },
    next() {
      this.swiper.slideNext();
      this.$emit('onNext', { currentSlide: this.currentSlide });
    },
    prev() {
      this.swiper.slidePrev();
      this.$emit('onPrev', { currentSlide: this.currentSlide });
    },
    onSlideChange(event) {
      const [swiper] = event.detail;
      this.currentSlide = swiper.realIndex;
      this.$emit('onSlideChange', this.currentSlide);
    },
    init() {
      const swiperEl = this.$refs.swiper;
      this.swiper = swiperEl.swiper;

      let modules = [];
      if (this.swiperSettings.pagination) modules = modules.push(Pagination);

      const swiperParams = {
        ...this.swiperSettings,
        modules,
      };

      Object.assign(swiperEl, swiperParams);
      swiperEl.initialize();
    },
  },
};
</script>

<style>
:root {
  --swiper-pagination-bottom: 0px;
  --swiper-pagination-color: #ffa100;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-inactive-color: #f1f2f7;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 4px;
  --swiper-pagination-progressbar-size: 1px;
}

.ui-swiper {
  @apply w-full relative;
}
.ui-swiper-arrow {
  @apply w-13 h-13 absolute z-10 
    transform 
    top-1/2 -translate-y-1/2;
}
.ui-swiper-next-arrow {
  @apply right-0 lg:translate-x-2/3;
}
.ui-swiper-prev-arrow {
  @apply left-0 lg:-translate-x-2/3;
}
swiper-container {
  height: 100%;
}
.ui-swiper.--pagination-left swiper-container::part(pagination) {
  text-align: left;
}
</style>
