<template>
  <div v-if="!$isWebView" class="mt-8 md:mt-16 mx-auto container">
    <UiBreadCrumbs
      class="md:-ml-6 px-4 md:px-0"
      :links="breadLinks"
      :has-support="hasSupport"
    />
    <div
      class="mt-8 md:mt-12 relative flex flex-col md:flex-row justify-between"
      :style="`max-width: ${containerWidth + 300}px;`"
    >
      <ProfileSidebar class="md:mr-4 md:pt-1" />
      <div
        class="w-full mt-8 md:mt-0"
        :style="
          $isDesktop ? `max-width: ${containerWidth}px;` : 'min-height: 100vh;'
        "
      >
        <slot></slot>
      </div>
    </div>
  </div>
  <div v-else class="">
    <slot></slot>
  </div>
</template>

<script>
import ProfileSidebar from '@shared/components/profile/ProfileSidebar.vue';
import UiBreadCrumbs from '@ui/UiBreadCrumbs.vue';

export default {
  name: 'ProfileView',
  components: {
    ProfileSidebar,
    UiBreadCrumbs,
  },
  props: {
    breadLinks: {
      type: Array,
      default: function breadLinksDefault() {
        return [{ name: this.$t('returnToMain'), path: '/' }];
      },
    },
    containerWidth: {
      type: Number,
      default: 588,
    },
    hasSupport: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      ru: {
        returnToMain: 'Вернуться на главную',
      },
      kk: {
        returnToMain: 'Басты бетке',
      },
    },
  },
};
</script>
