<template>
  <div class="TopHeroDesktop">
    <WhiteBreadcrumbs class="TopHeroDesktop__breadcrumbs" />
    <img
      v-if="$i18n.locale === 'ru'"
      src="./images/decor-ru.webp"
      class="TopHeroDesktop__decor"
      width="1252"
    />
    <img
      v-if="$i18n.locale === 'kk'"
      src="./images/decor-kk.webp"
      class="TopHeroDesktop__decor"
      width="1252"
    />
    <div class="TopHeroDesktop__container">
      <h1 class="TopHeroDesktop__title">
        <span v-html="$t('xmasFatherNear')" />
      </h1>
      <div class="TopHeroDesktop__descr">
        <span v-html="$t('descr')" />
      </div>
    </div>
  </div>
</template>

<script>
import WhiteBreadcrumbs from '@/views/ny-2023-contest/components/TopHero/components/WhiteBreadcrumbs/WhiteBreadcrumbs.vue';

export default {
  name: 'TopHeroDesktop',
  components: {
    WhiteBreadcrumbs,
  },
  i18n: {
    messages: {
      ru: {
        xmasFatherNear: 'Дед&nbsp;Мороз Рядом',
        descr: 'Розыгрыш завершён',
      },
      kk: {
        xmasFatherNear: 'Рядом&nbsp;Аяз Атасы',
        descr: 'Ұтыс аяқталды',
      },
    },
  },
};
</script>

<style scoped>
.TopHeroDesktop {
  position: relative;
  max-width: 1188px;
  margin: auto;
  height: 592px;
  background: url('./images/background.jpg') center top / 100% no-repeat;
}

.TopHeroDesktop__breadcrumbs {
  position: absolute;
  z-index: 1;
  top: 70px;
  left: 100px;
}

.TopHeroDesktop__decor {
  position: absolute;
  left: -33px;
  top: 123px;
  max-width: none;
}

.TopHeroDesktop__container {
  padding-top: 136px;
}

.TopHeroDesktop__title {
  color: #fff;
  text-align: center;
  font-size: 72px;
  font-weight: 700;
  line-height: 80px;
  text-shadow: 0px 4px 24px rgba(237, 86, 108, 0.48);
}

.TopHeroDesktop__descr {
  color: var(--text-text-invert, #fff);
  text-align: center;
  text-shadow: 0px 2px 16px rgba(255, 54, 85, 0.24);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px; /* 150% */
  max-width: 488px;
  margin: auto;
  padding-top: 24px;
}
</style>
