<template>
  <div class="PromocodeCopyDesk copy">
    <span class="copy-value"> {{ promocode }} </span>
    <UiButton size="M" variant="white" class="copy-btn">
      <IconCopy name="promocode-copy" />
      <span>{{ $t('copy') }}</span>
    </UiButton>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import IconCopy from './IconCopy.vue';
export default {
  components: { IconCopy, UiButton },
  props: {
    promocode: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: {
      kk: { copy: 'Промокодты көшіру' },
      ru: { copy: 'Копировать промокод' },
    },
  },
};
</script>

<style scoped>
.copy {
  @apply py-3 pr-4 pl-7  
    flex items-center justify-between space-x-2
    bg-sirius-gray-200 rounded-3xl cursor-pointer;
}
.copy-value {
  @apply text-2xl font-bold leading-30 text-center select-all;
}
.copy-btn {
  @apply ml-3 rounded-2xl;
  max-width: 248px;
}
.copy-btn span {
  @apply ml-2 font-medium text-sirius-orange-100;
}
</style>
