import { store } from '@/store';
import { $log } from '@/utils/plugins/logger';
import { rFetchProductsByIds } from '@shared/services/api/apis';

/**
 * @param {Object} value
 */
const setProducts = value => {
  store.commit('productDB/SET_PRODUCTS', value);
};
/**
 * @returns Object
 */
const getProducts = () => {
  return { ...store.getters['productDB/products'] };
};
const getKey = (id, wId) => {
  return wId ? `id_${id}_wId_${wId}` : `id_${id}`;
};

const getProductByKey = (id, wId) => {
  const productsObj = { ...getProducts() };
  let key = getKey(id, wId);
  return productsObj[key];
};

/**
 * Возвращаем порядок который был задан в изначальном массиве ids
 *
 * Порядок, возвращённый /lavka/v1/catalog/by_product_ids
 * не соответствует порядку
 * в /lavka-favorites/v1/shopping-history/products/list
 * @param {Number[]} ids массив айдишек товаров
 * @param {Product[]} products массив товаров
 * @returns {Products[]} вернули изначальный порядок
 */
function revertOrder(ids, products) {
  return ids
    .map(id => products.find(p => p.id === id))
    .filter(p => typeof p === 'object');
}

/**
 * Сохраняем инфу о товаре по ключу id_id и id_{id}_wId_{wId}
 * @param {Product[]} products массив товаров
 * @param {number} warehouseId склад айди по которому был получен товар
 */
function saveProducts(products, warehouseId) {
  let objProducts = { ...getProducts() };
  products.forEach(p => {
    objProducts[`id_${p.id}_wId_${warehouseId}`] = p;
    objProducts[`id_${p.id}`] = p;
  });
  setProducts(objProducts);
}

/**
 * Получаем инфо товара в каталоге по айдишкам товара
 * @param {Array<Number>} ids
 * @returns {Promise<Products[]>}
 */
function getProductsByIds(ids) {
  const warehouseId = store.getters['delivery/warehouseId'];
  return rFetchProductsByIds(ids, warehouseId)
    .then(res => revertOrder(ids, res.data))
    .then(res => {
      if (!res || !res.length) return [];

      res = res.map(e => {
        return { ...e, name_kk: e.name_kk || e.name || ' ' };
      });

      saveProducts(res, warehouseId);

      return res;
    })
    .catch(() => []);
}

/**
 * Убираем дубликаты айдишек и уже сохраненные товары
 * @param {Sample[]} samples массив семплов
 * @param {Boolean} isForce параметр для того что б получать список товаров даже если они сохранены, нужны для остатков
 * @returns {Number[]} айди товаров
 */
function extractNotSavedProducts(ids, isForce) {
  // так как нам нужны остатки по товарам мы их не исключаем в списке айдишек, соостветсвенно делаем запрос на этот товар
  if (isForce) return ids;
  ids = ids.filter(id => !getProductByKey(id));
  return ids;
}

/**
 * Метод для получение полных данных о продутах
 *
 * - Получает инфу о товаре и сохраняет ее в Store в обекте где ключь id_${id} или id_${id}_wId_${wId}
 * - Возвращяет массив с полной информацией о продукте
 * - Сохраняем эти продукты для того что б в дальнейшем ее не получать снова будем их получать уже из сохраненных
 * - Для товаров где важны остатки в складе мы будем должны запрашивать эттот товар в любом случаи, из за этого сохраняем по двум ключам
 * - В тех кейсаз где нужен просто товар то получаем просто по айдишке
 * - Если нужны остатки то мы ее запросим у бека и будем получать по айдишке товара и айдишке склада
 * @param {number[]} ids айдишки товаров
 * @param {number | undefined} wId warehouseId - если нужны остатки по товарам
 * @returns {Promise<Product[]>} массив товаров
 */
async function loadProductsByIds(ids, wId) {
  $log('ProductBDService loadProductsByIds', { ids, wId });
  if (!ids.length) Promise.resolve([]);
  ids = [...new Set(ids)];

  let newIds = extractNotSavedProducts(ids, !!wId);

  if (newIds.length) {
    let fetched = await getProductsByIds(newIds);
    $log('ProductBDService fetched', { newIds, wId, fetched });
  }

  let products = ids.map(id => getProductByKey(id, wId)).filter(p => p);
  $log('ProductBDService products', { products });
  return Promise.resolve(products);
}

export const ProductBDService = {
  extractNotSavedProducts,
  loadProductsByIds,
  getProductsByIds,
  getProductByKey,
};
