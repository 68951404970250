<template>
  <ExpandableTransition>
    <div v-if="isExpanded" class="duration-300">
      <TagsGrid class="mt-4">
        <UiButton
          v-for="tag in tags"
          :key="tag.id"
          :disabled="disabled"
          :variant="tagDeeplink[tag.id] ? 'primary' : 'neutral'"
          class="first:mt-2 first:mr-2 text-sm text-gray-700 disabled:opacity-50"
          size="sm"
          @click="toggleTag(tag)"
        >
          {{ tag.name }}
        </UiButton>
      </TagsGrid>
    </div>
  </ExpandableTransition>
</template>

<script>
import TagsGrid from '@layout/TagsGrid.vue';
import UiButton from '@ui/UiButton.vue';
import ExpandableTransition from '@shared/components/ExpandableTransition.vue';

export default {
  name: 'FilterTags',
  components: {
    ExpandableTransition,
    TagsGrid,
    UiButton,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tagDeeplink: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    toggleTag(tag) {
      this.$set(this.tagDeeplink, tag.id, !this.tagDeeplink[tag.id]);
      this.$emit('onSelect', this.tagDeeplink);
    },
  },
};
</script>
