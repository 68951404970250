<template>
  <div class="flex">
    <p class="w-2/5 leading-6 text-sirius-gray-100">{{ title }}</p>
    <slot name="value">
      <p class="ml-2">{{ value }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'title',
    },
    value: {
      type: String,
      default: 'value',
    },
  },
};
</script>

<style></style>
