import { authClient } from '../../clients/auth-client';

export default function ({ refreshToken }) {
  const body = {
    refresh_token: refreshToken,
    client_id: import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID,
    grant_type: 'refresh_token',
  };
  const params = {
    useFingerprint: true,
    useIdempotency: true,
    headers: {
      'Content-Type': 'application/json',
    },
    paramsSerializer: v => JSON.stringify(v),
    transformRequest: v => JSON.stringify(v),
  };
  return authClient
    .post('/api/v2/oauth2/tokens', body, params)
    .then(({ data }) => data);
}
