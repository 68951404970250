import { ClientService } from '@shared/services/client';

/**
 * Проблема: на версии супераппа <7.6.11 видео всегда разворачивается
 * на весь экран, поэтому надо как-то детектить
 * Для всех остальных показываем видео
 *
 * Поддержка воспроизведения видео inline появилась одновременно
 * с заменой в нативке в webview user agent на строку ChocoSuperApp/iOS/*
 * Раньше упоминания ChocoSuperApp вообще не было
 *
 * Если platform заполнен — значит пришли с миниаппки и в url
 * был query параметр platform (но иногда он не передаётся, например при
 * переходе в заказ с главного экрана)
 */
export function hasSupportInlineMedia() {
  const { platform } = ClientService.getContext();
  const isIosMiniapp = platform === 'ios';
  return (
    !isIosMiniapp ||
    window.navigator.userAgent.includes('ChocoSuperApp/iOS/')
  );
}
