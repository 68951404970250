export function withAuth(authorizedAction, unauthorizedAction) {
  return (ctx, ...args) => {
    if (ctx.rootState.initPromise instanceof Promise) {
      return ctx.rootState.initPromise.then(() => {
        authorizedAction(ctx, ...args);
      });
    }

    if (ctx.rootState.isAuthorized) {
      return authorizedAction(ctx, ...args);
    }

    return unauthorizedAction(ctx, ...args);
  };
}
