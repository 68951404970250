<template>
  <div class="ProductLabels relative">
    <LabelsContainer
      v-click-outside="closeTooltip"
      :labels="labels"
      :type="type"
      @click.native="openTooltip"
    >
      <template #default="{ label }">
        <LabelsItem
          :icon="label.icon"
          :name="label.name"
          :color="label.color"
          :variant="full ? 'full' : 'default'"
          :class="itemSpacing"
        />
      </template>
    </LabelsContainer>

    <LabelsTooltip v-if="show && !full" :labels="labels" />
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import LabelsContainer from './components/LabelsContainer.vue';
import LabelsTooltip from './components/LabelsTooltip.vue';
import LabelsItem from './components/LabelsItem.vue';

export default {
  name: 'ProductLabels',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    LabelsContainer,
    LabelsTooltip,
    LabelsItem,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'column',
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { show: false };
  },
  computed: {
    itemSpacing() {
      if (this.$isDesktop && this.full) return 'mr-3 mb-2';
      if (this.$isDesktop) return 'mb-2 mr-2';
      return 'mr-1 mb-1';
    },
  },
  methods: {
    closeTooltip() {
      this.show = false;
    },
    openTooltip() {
      if (this.full) return;
      this.show = !this.show;
    },
  },
};
</script>

<style></style>
