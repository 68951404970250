<template>
  <transition name="slide-up" apear>
    <div class="LabelsTooltip labels-tooltip custom-tooltip">
      <div class="labels-tooltip-arrow"></div>

      <LabelsContainer :labels="labels">
        <template #default="{ label }">
          <LabelsItem
            :icon="label.icon"
            :name="label.name"
            :color="label.color"
            variant="tooltip"
            class="mb-1 last:mb-0"
          />
        </template>
      </LabelsContainer>
    </div>
  </transition>
</template>

<script>
import LabelsContainer from './LabelsContainer.vue';
import LabelsItem from './LabelsItem.vue';

export default {
  components: { LabelsContainer, LabelsItem },
  props: {
    labels: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.labels-tooltip {
  @apply bg-white p-2 rounded-lg absolute top-full duration-300;
  left: -12px;
  filter: drop-shadow(0px 2px 8px rgba(13, 10, 32, 0.04))
    drop-shadow(0px 4px 28px rgba(13, 10, 32, 0.08));
  width: max-content;
}
.labels-tooltip-arrow {
  @apply h-0 w-0 absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(255, 255, 255, 1);
  top: -8px;
  left: 16px;
}
</style>
