function createTempInput() {
  const el = document.createElement('input');
  el.style.width = '0';
  el.style.height = '0';
  el.style.margin = '0';
  el.style.padding = '0';
  el.style.border = '0';
  el.style.opacity = '0';
  return el;
}
let innerHeight = window.innerHeight;
function visualViewportHandler() {
  let keyBoardHeight = innerHeight - visualViewport.height;
  window.scrollTo({
    top: window.scrollY + keyBoardHeight,
    behavior: 'smooth',
  });
}

function focusElement(el, tmpElement) {
  el.focus();
  el.click();
  document.body.removeChild(tmpElement);
}

export default {
  bind(el, binding) {
    innerHeight = window.innerHeight;
    const { value } = binding;
    if (value.disabled === true) return;

    const tmpElement = createTempInput();
    document.body.appendChild(tmpElement);
    tmpElement.focus();

    setTimeout(() => focusElement(el, tmpElement), 0);

    if (visualViewport) {
      visualViewport.addEventListener('resize', visualViewportHandler, {
        once: true,
      });
    }
  },
};
