export function formatPhoneNumber(phoneNumber, symbol = ' ') {
  // Extracting components of the phone number
  const country_code = phoneNumber.slice(0, 1);
  const area_code = phoneNumber.slice(1, 4);
  const first_part = phoneNumber.slice(4, 7);
  const second_part = phoneNumber.slice(7, 9);
  const last_part = phoneNumber.slice(9);

  // Formatting the phone number
  const formattedNumber = `+${country_code} ${area_code} ${first_part}${symbol}${second_part}${symbol}${last_part}`;
  return formattedNumber;
}
