import { api } from '@shared/services/api';

export const ComponentModule = {
  namespaced: true,
  state: {
    miniStatusState: false,
    category: {
      yPos: 0,
      page: 0,
      maxPage: 0,
      tags: [],
      brands: [],
      tagList: [],
      brandList: [],
      products: {},
      sort: null,
    },
    recipe: {
      yPos: 0,
      page: 0,
      maxPage: 0,
      tags: [],
      brands: [],
      tagList: [],
      brandList: [],
      products: {},
      sort: null,
    },
    search: {
      query: '',
      result: {},
      page: 0,
      maxPage: 0,
    },
    isOpenTimePopupShown: false,
    nyStat: null,
  },
  getters: {
    isMiniStatusShown(state) {
      return state.miniStatusState;
    },
    hasCategoryState(state) {
      return Object.keys(state.category.products).length !== 0;
    },
    categoryYPos(state) {
      return state.category.yPos;
    },
    categoryPage(state) {
      return state.category.page;
    },
    categoryMaxPage(state) {
      return state.category.maxPage;
    },
    categoryProducts(state) {
      return state.category.products;
    },
    categoryTags(state) {
      return state.category.tags;
    },
    categoryBrands(state) {
      return state.category.brands;
    },
    categoryTagList(state) {
      return state.category.tagList;
    },
    categoryBrandList(state) {
      return state.category.brandList;
    },
    categorySort(state) {
      return state.category.sort;
    },
    isCategoryTagsEmpty(state) {
      return state.category.tags.length === 0;
    },
    isCategoryBrandsEmpty(state) {
      return state.category.brands.length === 0;
    },
    isCategoryTagListEmpty(state) {
      return state.category.tagList.length === 0;
    },
    isCategoryBrandListEmpty(state) {
      return state.category.brandList.length === 0;
    },
    isCategoryProductsEmpty(state) {
      return Object.keys(state.category.products).length === 0;
    },
    hasRecipeState(state) {
      return Object.keys(state.recipe.products).length !== 0;
    },
    recipeYPos(state) {
      return state.recipe.yPos;
    },
    recipePage(state) {
      return state.recipe.page;
    },
    recipeMaxPage(state) {
      return state.recipe.maxPage;
    },
    recipeProducts(state) {
      return state.recipe.products;
    },
    recipeTags(state) {
      return state.recipe.tags;
    },
    recipeBrands(state) {
      return state.recipe.brands;
    },
    recipeTagList(state) {
      return state.recipe.tagList;
    },
    recipeBrandList(state) {
      return state.recipe.brandList;
    },
    recipeSort(state) {
      return state.recipe.sort;
    },
    isRecipeTagsEmpty(state) {
      return state.recipe.tags.length === 0;
    },
    isRecipeBrandsEmpty(state) {
      return state.recipe.brands.length === 0;
    },
    isRecipeTagListEmpty(state) {
      return state.recipe.tagList.length === 0;
    },
    isRecipeBrandListEmpty(state) {
      return state.recipe.brandList.length === 0;
    },
    isRecipeProductsEmpty(state) {
      return Object.keys(state.recipe.products).length === 0;
    },
    nyStat(state) {
      return state.nyStat;
    },
  },
  mutations: {
    SET_MINI_STATUS_STATE(state, value) {
      state.miniStatusState = value;
    },
    SET_CATEGORY_YPOS(state, value) {
      state.category.yPos = value;
    },
    SET_CATEGORY_PAGE(state, value) {
      state.category.page = value;
    },
    SET_CATEGORY_TAGS(state, value) {
      state.category.tags = value;
    },
    SET_CATEGORY_BRANDS(state, value) {
      state.category.brands = value;
    },
    SET_CATEGORY_TAGLIST(state, value) {
      state.category.tagList = value;
    },
    SET_CATEGORY_BRANDLIST(state, value) {
      state.category.brandList = value;
    },
    SET_CATEGORY_PRODUCTS(state, value) {
      state.category.products = value;
    },
    SET_CATEGORY_MAXPAGE(state, value) {
      state.category.maxPage = value;
    },
    SET_RECIPE_YPOS(state, value) {
      state.recipe.yPos = value;
    },
    SET_RECIPE_PAGE(state, value) {
      state.recipe.page = value;
    },
    SET_RECIPE_TAGS(state, value) {
      state.recipe.tags = value;
    },
    SET_RECIPE_BRANDS(state, value) {
      state.recipe.brands = value;
    },
    SET_RECIPE_TAGLIST(state, value) {
      state.recipe.tagList = value;
    },
    SET_RECIPE_BRANDLIST(state, value) {
      state.recipe.brandList = value;
    },
    SET_RECIPE_PRODUCTS(state, value) {
      state.recipe.products = value;
    },
    SET_RECIPE_MAXPAGE(state, value) {
      state.recipe.maxPage = value;
    },
    SET_SORTING_TYPE(state, value) {
      state.category.sort = value;
    },
    SET_RECIPE_SORTING_TYPE(state, value) {
      state.recipe.sort = value;
    },
    SET_OPEN_TIME_POPUP_TO_TRUE(state) {
      state.isOpenTimePopupShown = true;
    },
    SET_NY_STAT(state, payload) {
      state.nyStat = payload;
    },
  },
  actions: {
    SET_CATEGORY_STATE(ctx, payload) {
      const {
        page,
        tags,
        brands,
        tagList,
        brandList,
        products,
        maxPage,
        sort,
      } = payload;
      ctx.commit('SET_CATEGORY_PAGE', page);
      ctx.commit('SET_CATEGORY_TAGS', tags);
      ctx.commit('SET_CATEGORY_BRANDS', brands);
      ctx.commit('SET_CATEGORY_TAGLIST', tagList);
      ctx.commit('SET_CATEGORY_BRANDLIST', brandList);
      ctx.commit('SET_CATEGORY_PRODUCTS', products);
      ctx.commit('SET_CATEGORY_MAXPAGE', maxPage);
      ctx.commit('SET_SORTING_TYPE', sort);
    },
    SET_RECIPE_STATE(ctx, payload) {
      const {
        page,
        tags,
        brands,
        tagList,
        brandList,
        products,
        maxPage,
        sort,
      } = payload;
      ctx.commit('SET_RECIPE_PAGE', page);
      ctx.commit('SET_RECIPE_TAGS', tags);
      ctx.commit('SET_RECIPE_BRANDS', brands);
      ctx.commit('SET_RECIPE_TAGLIST', tagList);
      ctx.commit('SET_RECIPE_BRANDLIST', brandList);
      ctx.commit('SET_RECIPE_PRODUCTS', products);
      ctx.commit('SET_RECIPE_MAXPAGE', maxPage);
      ctx.commit('SET_RECIPE_SORTING_TYPE', sort);
    },
    RESET_RECIPE_STATE(ctx) {
      ctx.commit('SET_RECIPE_PAGE', 0);
      ctx.commit('SET_RECIPE_TAGS', []);
      ctx.commit('SET_RECIPE_BRANDS', []);
      ctx.commit('SET_RECIPE_TAGLIST', []);
      ctx.commit('SET_RECIPE_BRANDLIST', []);
      ctx.commit('SET_RECIPE_PRODUCTS', {});
      ctx.commit('SET_RECIPE_MAXPAGE', 0);
    },
    RESET_CATEGORY_STATE(ctx) {
      ctx.commit('SET_CATEGORY_PAGE', 0);
      ctx.commit('SET_CATEGORY_TAGS', []);
      ctx.commit('SET_CATEGORY_BRANDS', []);
      ctx.commit('SET_CATEGORY_TAGLIST', []);
      ctx.commit('SET_CATEGORY_BRANDLIST', []);
      ctx.commit('SET_CATEGORY_PRODUCTS', {});
      ctx.commit('SET_CATEGORY_MAXPAGE', 0);
    },
    FETCH_NY_STAT(ctx) {
      api.lavka
        .fetchNYStat()
        .then(res => {
          ctx.commit('SET_NY_STAT', res.data);
        })
        .catch(() => {});
    },
  },
};
