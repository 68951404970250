<template>
  <UiBottomSheetWrapper
    :show="show"
    with-title
    with-buttons
    with-back-button
    @change="$emit('change', $event)"
  >
    <template #title>{{ $t('selectSubcategories') }}</template>
    <div class="mt-4 pb-13">
      <UiList
        text="name"
        :value="selected"
        :list-items="tags"
        @onChange="$emit('onModalListChange', $event)"
      />
    </div>
    <template #buttons>
      <UiButton
        class="w-full font-bold"
        size="lg"
        @click="$emit('change', false)"
      >
        {{ $t('done') }}
      </UiButton>
    </template>
  </UiBottomSheetWrapper>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiList from '@ui/UiListNew.vue';
export default {
  name: 'CategoriesModal',
  components: {
    UiBottomSheetWrapper,
    UiButton,
    UiList,
  },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    selected: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  i18n: {
    messages: {
      ru: {
        selectSubcategories: 'Выберите подкатегории',
        done: 'Готово',
      },
      kk: {
        selectSubcategories: 'Ішкі санаттарды таңдаңыз',
        done: 'Дайын',
      },
    },
  },
};
</script>

<style></style>
