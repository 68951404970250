import {
  rFetchWarehouses,
  rFetchWarehouseByCoords,
} from '@shared/services/api/apis';
import {
  ERR_NO_DELIVERY,
  ERR_NO_ACTIVE_WAREHOUSE,
  DEFAULT_WAREHOUSE_ID,
} from '@shared/config/delivery';
import { DeliveryEmitter, DELIVERY_EVENTS } from '@services/delivery/emitter';
import { DeliveryConfigService } from '@services/delivery-config';
import { replaceTime } from '@shared/utils/replace-time';
import { CityService } from '@services/city';
import { isEmployee } from './utils';

export const DeliveryModule = {
  namespaced: true,
  state: {
    warehouse: null,
    isFailedToFindWarehouse: false,
    noActiveWarehouse: false,
    isWarehouseOpen: true,
    openingTime: '8:00:00',
    closingTime: '',
  },
  getters: {
    isOutOfDeliveryZone(state) {
      return !state.isFailedToFindWarehouse && state.warehouse === null;
    },
    warehouseCoords(state) {
      return state.warehouse
        ? {
            lat: state.warehouse.lat,
            long: state.warehouse.long,
          }
        : null;
    },
    noActiveWarehouse(state) {
      return state.noActiveWarehouse;
    },
    warehouseId(state) {
      let defaultID =
        CityService.getCityData().defaultWarehouseId || DEFAULT_WAREHOUSE_ID;
      return state.warehouse?.id || defaultID;
    },
    warehouse(state) {
      return state.warehouse;
    },
    isWarehouseOpen(state) {
      return state.isWarehouseOpen;
    },
    openingTime(state) {
      return state.openingTime;
    },

    isWarehouseGuy(state, getters, rootState, rootGetters) {
      const { warehouse } = state;
      const deliveryAddress = rootGetters['user/deliveryAddress'];
      return isEmployee(warehouse, deliveryAddress);
    },
    /**
     * минчек
     */
    originalUserMinAmount(state) {
      return (state.warehouse && state.warehouse.order_min_amount) || 0;
    },
    /**
     * сурж
     */
    originalMinAmount(state) {
      return (state.warehouse && state.warehouse.min_amount) || 0;
    },
    originalMinCheck(state, getters) {
      return Math.max(getters.originalUserMinAmount, getters.originalMinAmount);
    },
    userMinAmount(state, getters) {
      if (getters.isWarehouseGuy) return 0;

      return getters.originalUserMinAmount;
    },
    minAmount(state, getters, rootState, rootGetters) {
      const isTestWarehouse = [6, 8, 11, 13].includes(getters.warehouseId);
      if (isTestWarehouse) return 0;

      const ordersCount = rootGetters['user/userOrdersCount'];

      if (getters.isWarehouseGuy) return 0;
      if (ordersCount < 2) return 0;
      return getters.originalMinAmount;
    },
    minCheck(state, getters) {
      return Math.max(getters.userMinAmount, getters.minAmount);
    },

    closingTime(state) {
      return state.closingTime;
    },
    expectedDeliveryTime(state, getters, rootState) {
      const { warehouse } = state;
      const { originalMinAmount } = getters;
      const { isDistant } = rootState.paidOrder;
      const distant = '30 - 40';
      const minAmount = '20 - 30';
      const regular = '13 - 17';
      let expected = warehouse?.expected_delivery_time || '0';

      if (expected.includes('-') && !expected.includes(' - ')) {
        expected = expected.split('-').join(' - ');
      }

      if (expected !== '0' && isDistant) {
        return expected.split('-')[1] > distant.split('-')[1]
          ? expected
          : distant;
      }
      if (expected !== '0') {
        return expected;
      }
      if (isDistant) {
        return distant;
      }
      if (originalMinAmount) return minAmount;
      return regular;
    },
  },
  mutations: {
    SET_WAREHOUSE(state, value) {
      DeliveryEmitter.emit(DELIVERY_EVENTS.ON_WAREHOUSE_CHANGE, {
        newVal: value,
        oldVal: state.warehouse,
      });
      state.warehouse = value;
    },
    SET_NO_ACTIVE_WAREHOUSE(state, value) {
      state.noActiveWarehouse = value;
    },
    SET_FAILURE_STATE(state, value) {
      state.isFailedToFindWarehouse = value;
    },
    SET_IS_WAREHOUSE_OPEN(state, value) {
      state.isWarehouseOpen = value;
    },
    SET_OPENING_TIME(state, value) {
      state.openingTime = value;
    },
    SET_CLOSING_TIME(state, value) {
      state.closingTime = value;
    },
  },
  actions: {
    FIND_WAREHOUSE(ctx) {
      const DEFAULT_ERROR_MESSAGE = 'Не удалось определить зону доставки';
      if (ctx.state.noActiveWarehouse) {
        return Promise.reject(new Error(ERR_NO_ACTIVE_WAREHOUSE));
      }
      if (ctx.rootState.user.deliveryAddress == null) {
        // Непонятно, почему ошибка — это нормальный случай, если адреса нет
        // return Promise.reject(
        //   new Error('Не удалось определить адрес доставки')
        // );
        return Promise.resolve();
      }
      const { lat, long } = ctx.rootState.user.deliveryAddress;
      return Promise.resolve()
        .then(() => {
          const promiseArchive = [];
          const coords = { lat, long };
          const { isAuthorized } = ctx.rootGetters;

          promiseArchive.push(rFetchWarehouseByCoords(coords, isAuthorized));
          promiseArchive.push(rFetchWarehouses());

          return Promise.all(promiseArchive);
        })
        .then(promises => {
          const [warehouse, warehousesRes] = promises;
          const activeWarehouse = warehouse.data;
          const warehouses = warehousesRes.data;
          return {
            warehouse: activeWarehouse,
            noActiveWarehouse: warehouses.length === 0,
          };
        })
        .then(res => {
          if (res.noActiveWarehouse) {
            ctx.commit('SET_NO_ACTIVE_WAREHOUSE', true);
            throw new Error(ERR_NO_ACTIVE_WAREHOUSE);
          }
          if (res.warehouse == null) {
            throw new Error(ERR_NO_DELIVERY);
          }

          ctx.commit('SET_WAREHOUSE', res.warehouse);

          ctx.commit('SET_FAILURE_STATE', false);

          ctx.dispatch('SET_OPEN_TIME');
        })
        .then(() => {
          return DeliveryConfigService.loadConfig();
        })
        .catch(err => {
          const noDelivery = err.message === ERR_NO_DELIVERY;
          ctx.commit('SET_WAREHOUSE', null);
          ctx.commit('SET_FAILURE_STATE', !noDelivery);
          return noDelivery
            ? Promise.reject(err)
            : Promise.reject(new Error(DEFAULT_ERROR_MESSAGE));
        });
    },
    FIND_WAREHOUSE_IF_NEEDED(ctx) {
      return ctx.state.isFailedToFindWarehouse
        ? ctx.dispatch('FIND_WAREHOUSE')
        : Promise.resolve();
    },
    SET_IS_WAREHOUSE_OPEN_TO_TRUE(ctx) {
      ctx.commit('SET_IS_WAREHOUSE_OPEN', true);
    },
    SET_OPEN_TIME(ctx) {
      const { warehouse } = ctx.state;

      if (warehouse && warehouse.closes_at && warehouse.opens_at) {
        const openDateTime = replaceTime(warehouse.opens_at);
        const tempDate = replaceTime(warehouse.closes_at);
        const closeDateTime = new Date(
          tempDate.setMinutes(tempDate.getMinutes())
        );

        const now = new Date();
        ctx.commit('SET_OPENING_TIME', warehouse.opens_at);
        ctx.commit('SET_CLOSING_TIME', warehouse.closes_at);

        if (now >= openDateTime && now <= closeDateTime) {
          ctx.commit('SET_IS_WAREHOUSE_OPEN', true);
        } else {
          ctx.commit('SET_IS_WAREHOUSE_OPEN', false);
        }
      }
    },
  },
};
