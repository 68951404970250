<template>
  <div class="SearchList">
    <UiLoader v-if="loading" />

    <NoDelivery
      v-else-if="noDelivery"
      class="SearchList__nodelivery"
      :variant="noDelivery"
      :gray="gray"
      @onMount="$emit('onNoDeliveryToAddress')"
      @typeNew="$emit('typeNew')"
      @goToMain="$emit('goToMain')"
      @saveAnyway="$emit('saveAnyway')"
    />

    <SearchMessage
      v-else-if="messageType"
      :type="messageType"
      class="SearchList__message"
      @showOnMap="$emit('onReturnToMap')"
    />

    <template v-else>
      <slot name="return"></slot>

      <CustomerAddress
        v-for="(address, index) in savedAddresses"
        :key="`saved_${index}`"
        class="SearchList__address"
        :address="address"
        @onSelect="$emit('onSelectSavedAddress', $event)"
      />

      <AddressSearchItem
        v-for="(item, index) in searchedAddresses"
        :key="`searched_${index}`"
        :text="item.name"
        :description="item.description"
        class="SearchList__address"
        @click.native="$emit('onSelectSearchedAddress', item)"
      />
    </template>
  </div>
</template>

<script>
import AddressSearchItem from '@pure-ui/components/Address/AddressSearchItem/AddressSearchItem.vue';
import CustomerAddress from '@pure-ui/components/Address/CustomerAddress/CustomerAddressOld.vue';
import NoDelivery from '@pure-ui/components/Address/NoDelivery/NoDelivery.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import SearchMessage from './components/SearchMessage.vue';

export default {
  name: 'SearchList',
  components: {
    AddressSearchItem,
    CustomerAddress,
    SearchMessage,
    NoDelivery,
    UiLoader,
  },
  props: {
    messageType: {
      type: String,
      default: '',
    },
    noDelivery: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    savedAddresses: {
      type: Array,
      default: () => [],
    },
    searchedAddresses: {
      type: Array,
      default: () => [],
    },
    gray: Boolean,
  },
  i18n: {
    messages: {
      kk: { returnToMap: 'Картаға қайта оралу' },
      ru: { returnToMap: 'Вернуться к выбору на карте' },
    },
  },
};
</script>

<style scoped>
.SearchList__nodelivery {
  @apply mt-1 px-1;
  max-width: 524px;
}
.SearchList__message {
  @apply pt-2 md:pt-0;
}
.SearchList__address {
  @apply py-4 
    border-b-2 md:last:border-b-0 border-sirius-gray-400

    md:pt-4 md:pb-4.5 md:first:pt-1 md:last:pb-1.5;
  border-color: rgba(241, 242, 247, 0.7);
}
</style>
