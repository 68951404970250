<template>
  <UiBottomSheetPopup
    :show="show"
    closable
    with-back-button
    style="--popup-width-d: 588px"
    @change="v => $emit('change', v)"
  >
    <div class="modal">
      <img
        src="./img/door-img.jpg"
        alt="door-img"
        width="198px"
        height="196px"
        class="img"
      />

      <p class="title">{{ $t('title') }}</p>

      <p class="subtitle" v-html="$t('descr')" />

      <UiButton class="btn" size="lg" @click="agree">
        {{ $t('btn') }}
      </UiButton>
    </div>
  </UiBottomSheetPopup>
</template>

<script>
import UiBottomSheetPopup from '@pure-ui/components/UiBottomSheetPopup/UiBottomSheetPopup.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'AdultsLeaveAtDoorModal',
  components: { UiBottomSheetPopup, UiButton },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
  },
  methods: {
    agree() {
      this.$emit('change', false);
      this.$emit('onAgree');
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'Тапсырысты есік алдына қалдыра алмаймыз',
        descr:
          'Себеттің ішінде алкоголь немесе темекі өнімдері бар. Оны сізге беру үшін жеке куәлігіңізді тексеруіміз керек.',
        btn: 'Түсінікті',
      },
      ru: {
        title: 'Не сможем оставить заказ у двери',
        descr:
          'В корзине есть алкогольная или табачная продукция. Чтобы ее передать, нам нужно проверить ваше удостоверение.',
        btn: 'Понятно',
      },
    },
  },
};
</script>

<style scoped>
.modal {
  @apply pb-3;
}
.img {
  @apply mt-8 md:mt-3 mx-auto;
}
.title {
  @apply -mt-1 text-2xl md:text-28 font-bold leading-30 md:leading-8 md:text-center;
}
.subtitle {
  @apply mt-3 text-base leading-6 md:mt-4 md:text-lg md:leading-7 md:text-center;
}
.btn {
  @apply mt-12 md:mt-10 w-full text-lg font-bold md:text-2xl;
}
</style>
