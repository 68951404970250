<template>
  <router-link :to="{ name: 'favorites' }" class="FavoritesIcon relative">
    <IconFavoritesDesk v-if="$route.name !== 'favorites'" />
    <IconFavoritesDeskActive v-else />
  </router-link>
</template>

<script>
import IconFavoritesDesk from './icons/IconFavoritesDesk.vue';
import IconFavoritesDeskActive from './icons/IconFavoritesDeskActive.vue';

export default {
  name: 'FavoritesIcon',
  components: {
    IconFavoritesDesk,
    IconFavoritesDeskActive,
  },
};
</script>
