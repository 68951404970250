import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { createStorage } from '@shared/services/storage';
import { SERVICE_FEE_EVENTS, serviceFeeEmitter } from './emitter';

function hasEvent() {
  return serviceFeeEmitter.events[
    SERVICE_FEE_EVENTS.ON_CURRENT_ORDER_SESSION_ENDED
  ]?.length;
}
function saveShown() {
  const storage = createStorage(window.localStorage);
  storage.set('serviceFee::isShown', true);
  serviceFeeEmitter.off(
    SERVICE_FEE_EVENTS.ON_CURRENT_ORDER_SESSION_ENDED,
    saveShown
  );
}

function isShownBefore() {
  const storage = createStorage(window.localStorage);
  const isShown = storage.parse('serviceFee::isShown', false);
  return isShown;
}

/**
 * Для того что б показать доп инфу о севисном сборе только один раз
 */
function onUserShowed() {
  if (isShownBefore() || hasEvent()) return;

  Analitycs.logEvent(EVENTS.SERVICE_FEE_FIRST_TIME_SEEN);
  serviceFeeEmitter.on(
    SERVICE_FEE_EVENTS.ON_CURRENT_ORDER_SESSION_ENDED,
    saveShown
  );
}

function openModal() {
  Analitycs.logEvent(EVENTS.SERVICE_FEE_MODAL_SHOWN);
  serviceFeeEmitter.emit(SERVICE_FEE_EVENTS.OPEN_MODAL);
}

export const ServiceFeeService = {
  onUserShowed,
  isShownBefore,
  openModal,
};
