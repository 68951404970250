import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createStorage } from '@shared/services/storage';
import { eventBus, BUS_EVENTS } from '@shared/utils/event-bus';

Vue.use(VueI18n);

const storage = createStorage(window.localStorage);

export const i18n = new VueI18n({
  locale: 'ru', // ['kk', 'ru']
  fallbackLocale: 'ru',
  pluralizationRules: {
    ru: function ru(choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

const key = 'lang';
const defaultValue = { lang: 'ru' };

function setToStorage(newVal) {
  storage.stringify(key, newVal);
}
function getFromStorage() {
  return storage.parse(key, defaultValue);
}

function getLocale() {
  return getFromStorage().lang;
}
function setLocale(lang) {
  if (!lang) lang = getLocale();
  eventBus.$emit(BUS_EVENTS.LOCALE_CHANGE, lang);

  i18n.locale = lang;
  setToStorage({ lang });
}

export const LocaleService = {
  setLocale,
  getLocale,
};
