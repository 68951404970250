<template>
  <div class="FAQQuestion faq">
    <div class="faq-question" @click="show = !show">
      <p><slot name="question"></slot></p>
      <UiInlineIcon
        class="faq-arrow"
        :class="{ active: show }"
        name="faq-arrow"
      />
    </div>
    <UiCollapseAnimation :open="show">
      <div class="faq-answer editor-text">
        <slot name="answer"></slot>
      </div>
    </UiCollapseAnimation>
  </div>
</template>

<script>
import UiCollapseAnimation from '@pure-ui/components/UiCollapseAnimation/UiCollapseAnimation.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'FAQQuestion',
  components: { UiCollapseAnimation, UiInlineIcon },
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style scoped>
.faq {
  @apply py-4 border-b-2 border-sirius-gray-200;
}
.faq-question {
  @apply flex items-center justify-between leading-6 cursor-pointer;
}
.faq-arrow {
  @apply transition-all duration-300;
}
.faq-arrow.active {
  @apply rotate-180;
}
.faq-answer {
  @apply pt-3  leading-6 text-sirius-gray-900;
  padding-bottom: 10px;
}
</style>
