<template>
  <div class="">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1784_7256)">
        <path
          d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
          :fill="`${white ? 'white' : '#F1F2F7'}`"
        />
        <path
          d="M11.125 11.125H12V17.25H12.875"
          stroke="black"
          stroke-width="1.75"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.7813 8.5C12.5061 8.5 13.0938 7.91237 13.0938 7.1875C13.0938 6.46263 12.5061 5.875 11.7813 5.875C11.0564 5.875 10.4688 6.46263 10.4688 7.1875C10.4688 7.91237 11.0564 8.5 11.7813 8.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1784_7256">
          <path
            d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill="black"
          />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
