export const PROFILE_LINKS = [
  {
    name: 'personalInfo',
    routeName: 'profile',
    toggled: false,
    childs: [],
  },
  {
    name: 'myOrders',
    routeName: 'orders',
    keywords: ['orders', 'order', 'problems'],
    toggled: false,
    childs: [],
  },
  {
    name: 'myAddresses',
    routeName: 'profile-addresses',
    toggled: false,
    childs: [],
  },
  {
    name: 'referral',
    routeName: 'referral-share',
    toggled: false,
    childs: [],
  },

  {
    name: 'faq',
    routeName: 'faq',
    toggled: false,
    childs: [],
  },
  {
    name: 'iin',
    routeName: 'iin',
    toggled: false,
    childs: [],
  },
  {
    name: 'cashbacks',
    routeName: 'cashbacks',
    toggled: false,
    childs: [],
    color: 'red',
  },
  {
    name: 'docs',
    routeName: 'docs',
    toggled: false,
    childs: [],
  },
];

export const PROFILE_LINKS_I18N = {
  messages: {
    ru: {
      personalInfo: 'Личная информация',
      myOrders: 'Мои заказы',
      myAddresses: 'Мои адреса',
      cashbacks: 'Кешбэк за заказы',
      referral: 'Пригласи друга',
      faq: 'Вопросы и ответы',
      iin: 'БИН/ИИН в чеке',
      docs: 'Правовые документы',
    },
    kk: {
      personalInfo: 'Жеке aқпарат',
      myOrders: 'Тапсырыстар',
      myAddresses: 'Мекен-жайлар',
      cashbacks: 'Кешбэк за заказы',
      referral: 'Досыңды шақыр',
      faq: 'Жиі қойылатын сұрақтар',
      iin: 'БСН/ЖСН чекте көрсету',
      docs: 'Құжаттар',
    },
  },
};
