<template>
  <div>
    <UiFullScreenLoader v-if="loading" />
    <div class="FormScreen">
      <div class="FormScreen__container">
        <form class="FormScreen__form" @submit.prevent="onSubmit">
          <div class="FormScreen__textWrapper">
            <h2 class="FormScreen__title">{{ $t('leaveAppliance') }}</h2>
            <p
              class="FormScreen__text"
              :class="{
                FormScreen__text_error:
                  ($v.$invalid && $v.$anyDirty) || errorMessage,
              }"
              v-html="descriptionText"
            />
          </div>
          <div class="FormScreen__formWrapper">
            <div class="FormScreen__grid">
              <MmInput
                v-model="name"
                :invalid="$v.name.$error"
                name="name"
                :placeholder="$t('name')"
                variant="white"
              />
              <MmInput
                v-model="phone"
                v-mask="`+7-###-###-##-##`"
                :invalid="$v.phone.$error"
                name="phone"
                :placeholder="$t('phone')"
                variant="white"
              />
              <MmInput
                v-model="email"
                :invalid="$v.email.$error"
                name="email"
                :placeholder="$t('email')"
                variant="white"
              />
              <MmInput
                v-model="company"
                :invalid="$v.company.$error"
                :placeholder="$t('company')"
                variant="white"
                name="company"
              />
              <MmTextarea
                v-if="!$isDesktop"
                v-model="address"
                name="address"
                :invalid="$v.address.$error"
                :placeholder="$t('address')"
                :rows="2"
                no-resize
              />
              <MmInput
                v-if="$isDesktop"
                v-model="address"
                class="FormScreen__fieldAddress"
                :invalid="$v.address.$error"
                name="address"
                :placeholder="$t('address')"
                variant="white"
              />
            </div>
            <MmButton class="FormScreen__btnSend">
              {{ $t('sendForm') }}
            </MmButton>
            <p class="FormScreen__legal">
              <span v-html="legalHtml" />
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import MmInput from '@/views/micromarket/components/MmInput/MmInput.vue';
import MmTextarea from '@/views/micromarket/components/MmTextarea/MmTextarea.vue';
import MmButton from '@/views/micromarket/components/MmButton/MmButton.vue';
import UiFullScreenLoader from '@ui/UiFullScreenLoader.vue';
import postHolodosForm from '@shared/services/api/requestsV2/micromarket/post-holodos-form';
import { getLegalText } from './get-legal-text';

export default {
  name: 'FormScreen',
  components: {
    UiFullScreenLoader,
    MmButton,
    MmTextarea,
    MmInput,
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
    },
    phone: {
      required,
      phone: value => {
        return value.length === 16;
      },
    },
    email: {
      required,
    },
    company: {
      required,
    },
    address: {
      required,
    },
  },
  data: () => ({
    loading: false,
    name: '',
    phone: '+7',
    email: '',
    company: '',
    address: '',
    errorMessage: '',
  }),
  computed: {
    descriptionText() {
      if (this.$v.$invalid && this.$v.$anyDirty) {
        return this.$t('errorText');
      }
      if (this.errorMessage) {
        return this.errorMessage;
      }
      return this.$t('descrText');
    },
    legalHtml() {
      return getLegalText(this.$i18n.locale);
    },
  },
  watch: {
    name() {
      this.errorMessage = '';
    },
    phone() {
      this.errorMessage = '';
    },
    email() {
      this.errorMessage = '';
    },
    company() {
      this.errorMessage = '';
    },
    address() {
      this.errorMessage = '';
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;

      const payload = {
        name: this.name,
        phone_number: this.phone,
        email: this.email,
        company_address: this.address,
        company_name: this.company,
      };

      postHolodosForm(payload)
        .then(res => {
          console.log('holodos success', res);
          this.$emit('onSuccess');
        })
        .catch(err => {
          console.error('holodos error', err);
          this.errorMessage = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  i18n: {
    messages: {
      kk: {
        leaveAppliance: 'Өтінім қалдыру',
        name: 'Аты',
        phone: 'Телефон номері',
        email: 'Email',
        company: 'Компания аты',
        address: 'Компания мекенжайы',
        sendForm: 'Форманы жіберу',
        legal: `
          Жалғастыра отырып {link} оқығанымды
          және онымен келісетінімді растаймын
        `,
        legalLink: 'Қолданушы келісімін',
        toContinueFillForm: `
          Жалғастыру үшін форманы толтырыңыз. Бұл деректер сізбен
          алдағы уақытта коммуникация құру үшін қажет.
        `,
        descrText: `
          Форманы толтырыңыз, өзіміз хабарласып, егжей-тегжейін айтамыз
        `,
        errorText: `
          Жалғастыру үшін форманы толтырыңыз. Бұл деректер сізбен
          алдағы уақытта коммуникация құру үшін қажет.
        `,
      },
      ru: {
        leaveAppliance: 'Оставьте заявку',
        name: 'Имя',
        phone: 'Номер телефона',
        email: 'Email',
        company: 'Название компании',
        address: 'Адрес компании',
        sendForm: 'Отправить форму',
        legal: `
          Продолжая я&nbsp;подтверждаю, что&nbsp;прочитал(а)
          и&nbsp;согласн(а) с&nbsp;{link}
        `,
        legalLink: 'Пользовательским соглашением',
        toContinueFillForm: `
          Чтобы продолжить, заполните форму. Эти данные нужны для дальнейшей коммуникации с вами.
        `,
        descrText: `
          Заполните форму, мы&nbsp;cвяжемся и&nbsp;расскажем все детали.
        `,
        errorText: `
          Чтобы продолжить, заполните форму.
          Эти&nbsp;данные нужны для дальнейшей коммуникации с&nbsp;вами.
        `,
      },
    },
  },
};
</script>

<style>
.FormScreen {
  padding-top: 28px;
}

.FormScreen__container {
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.FormScreen__title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #ffa100;
}

.FormScreen__text {
  padding-top: 12px;
  font-size: 16px;
  line-height: 24px;
}

.FormScreen__text_error {
  color: #ec5555;
}

.FormScreen__grid {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.FormScreen__btnSend {
  margin-top: 40px;
  width: 100%;
}

.FormScreen__legal {
  padding-top: 16px;
  font-size: 14px;
  line-height: 22px;
}

.FormScreen__legalLink {
  color: var(--services-produkty, #ffa100);
  font-weight: 500;
}

@media (min-width: 768px) {
  .FormScreen {
    padding: 80px 0 96px;
  }

  .FormScreen__container {
    max-width: 988px;
    width: 100%;
  }

  .FormScreen__title {
    font-size: 36px;
    line-height: 44px;
  }

  .FormScreen__text {
    font-size: 18px;
    line-height: 28px;
  }

  .FormScreen__form {
    display: grid;
    grid-template-columns: auto 492px;
    gap: 0 100px;
  }

  .FormScreen__grid {
    padding-top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px 12px;
  }

  .FormScreen__fieldAddress {
    grid-column: 1 / 3;
  }

  .FormScreen__btnSend {
    max-width: 276px;
  }

  .FormScreen__legal {
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
