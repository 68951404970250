<template>
  <MainLayout class="CashbacksPage">
    <template v-if="$isWebView" #header>
      <CashbacksHeader />
    </template>
    <ProfileLayout :container-width="662">
      <UiRippleLoader v-if="loading" />
      <div v-else class="cashbacks-content">
        <ProgressSweets class="mb-7" :active="sweets" />
        <div class="w-full mx-auto" style="max-width: 588px">
          <FinishedCashbacks
            v-if="isFinished"
            :total-bonuses="totalBonuses"
            @onMount="onMountFinishPage"
            @onBtnClicked="onReferralBtnClicked"
          />
          <ActiveCashbacks v-else :conditions="conditions" />
        </div>
      </div>
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { CashbackService } from '@services/cashback';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { CASHBACK_CONDITIONS } from '@services/cashback/constants';

import FinishedCashbacks from '@pure-ui/components/Cashbacks/CashbacksPage/FinishedCashbacks.vue';
import ActiveCashbacks from '@pure-ui/components/Cashbacks/CashbacksPage/ActiveCashbacks.vue';
import ProgressSweets from '@pure-ui/components/Cashbacks/ProgressSweets/ProgressSweets.vue';
import CashbacksHeader from '@components/cashbacks/CashbacksHeader.vue';
import UiRippleLoader from '@shared/components/ui/UiRippleLoader.vue';
import ProfileLayout from '@layout/ProfileLayout.vue';
import MainLayout from '@layout/MainLayout.vue';

export default {
  name: 'CashbacksView',
  components: {
    FinishedCashbacks,
    CashbacksHeader,
    ActiveCashbacks,
    ProgressSweets,
    UiRippleLoader,
    ProfileLayout,
    MainLayout,
  },
  data() {
    return {
      conditions: CASHBACK_CONDITIONS,
      loading: true,
    };
  },
  computed: {
    sweets() {
      const doneCashbacks = this.conditions.filter(e => e.cashback);
      return doneCashbacks.length;
    },
    isFinished() {
      return this.sweets === 5;
    },
    totalBonuses() {
      return this.conditions.reduce(
        (total, config) => config.cashback + total,
        0
      );
    },
  },
  async mounted() {
    Analitycs.logEvent(EVENTS.CASHBACKS_PAGE_VIEWED, {
      from: this.$route.query.from,
    });
    this.conditions = await CashbackService.loadCashbacks();
    this.loading = false;
  },
  methods: {
    onMountFinishPage() {
      CashbackService.setFinishedPageViewed();
    },
    onReferralBtnClicked() {
      this.$router.push({ name: 'referral-share' });
    },
  },
};
</script>

<style scoped>
.cashbacks-content {
  @apply mt-5 px-4 md:px-0;
  padding-bottom: 108px;
}
</style>
