<template>
  <div class="">
    <DeliveryOption
      v-model="lessPackages"
      :option="{
        icon: 'less-packages',
        title: $t('asPossibleLessPackages'),
      }"
      @change="setLessPackages"
    />
    <LeaveAtDoor />
    <!-- Промокод -->
    <PromocodeDesktop v-if="$isDesktop" />
    <PromocodeMobile v-else />
    <!-- / Промокод -->
  </div>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import { createStorage } from '@shared/services/storage';
import DeliveryOption from '@pure-ui/components/DeliveryOption/DeliveryOption.vue';
import LeaveAtDoor from '@components/adults/LeaveAtDoor.vue';

import PromocodeMobile from './promocode/PromocodeMobile.vue';
import PromocodeDesktop from './promocode/PromocodeDesktop.vue';

export default {
  name: 'AdditionalFeatures',
  components: {
    LeaveAtDoor,
    DeliveryOption,
    PromocodeMobile,
    PromocodeDesktop,
  },
  inject: ['popup'],
  data: () => ({
    lessPackages: false,
    storage: null,
  }),
  mounted() {
    this.storage = createStorage(window.localStorage);

    if (this.storage.get('additionalFeat::lessPackages') === null) {
      this.setLessPackages(false, false);
    }
    this.lessPackages = this.storage.parse('additionalFeat::lessPackages');
  },
  methods: {
    setLessPackages(value, isSwitch = true) {
      if (isSwitch) RahmetApp.hapticSelection();
      this.storage.set('additionalFeat::lessPackages', value);
    },
  },
  i18n: {
    messages: {
      ru: {
        lessPackages: 'Меньше пакетов',
        asPossibleLessPackages: 'Как можно меньше пакетов',
        done: 'Готово',
      },
      kk: {
        lessPackages: 'Қалташалар аз болсын',
        asPossibleLessPackages: 'Қалташалар азырақ болсын',
        done: 'Дайын',
      },
    },
  },
};
</script>
