<template>
  <div class="DesktopArrowButtons">
    <DesktopArrowButton
      class="DesktopArrowButtons__btn DesktopArrowButtons__left"
      name="left"
      @click="$emit('onDesktopArrowLeftClick')"
    />
    <DesktopArrowButton
      class="DesktopArrowButtons__btn DesktopArrowButtons__right"
      name="right"
      @click="$emit('onDesktopArrowRightClick')"
    />
  </div>
</template>

<script>
import DesktopArrowButton from './components/DesktopArrowButton/DesktopArrowButton.vue';

export default {
  name: 'DesktopArrowButtons',
  components: {
    DesktopArrowButton,
  },
};
</script>

<style scoped>
.DesktopArrowButtons {
  display: flex;
  justify-content: space-between;
}

.DesktopArrowButtons__btn {
  margin-top: -26px;
}

.DesktopArrowButtons__left {
  margin-left: -100px;
}

.DesktopArrowButtons__right {
  margin-right: -100px;
}
</style>
