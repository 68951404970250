<template>
  <CommonDeskView v-if="isDesktop" v-on="$listeners" />

  <div
    v-else
    class="flex items-center cursor-pointer"
    @click="$emit('openModal')"
  >
    <img class="block" :src="IconSupport" alt="support" />

    <p class="ml-2 font-medium">
      {{ $t('contactWithSuppotService') }}
    </p>
    <img class="ml-auto block" :src="IconArrow" alt="arrow" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { i18n } from '../utils';
import CommonDeskView from './CommonDeskView.vue';

export default {
  name: 'FinishedOrderView',
  components: {
    CommonDeskView,
  },
  data() {
    return {
      IconSupport: new URL('@img/icons/support.svg', import.meta.url).href,
      IconArrow: new URL('@img/icons/support-arrow.svg', import.meta.url).href,
    };
  },
  computed: {
    ...mapGetters(['isDesktop']),
  },
  i18n,
};
</script>

<style></style>
