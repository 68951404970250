<template>
  <div class="ReferralMobile referral-mobile-view">
    <img
      class="w-full"
      src="@img/referral/puzzle.jpg"
      width="375"
      height="172"
    />

    <div class="referral-mobile-content">
      <!-- Дарите друзьям 1500 ₸ — получайте 1500 ₸ -->
      <h2 class="referral-mobile-title" v-html="$t('title')" />

      <!-- Делитесь с друзьями своим промокодом... -->
      <template v-if="!promocode">
        <p class="referral-mobile-descr" v-html="$t('do3orders')" />
        <p class="mt-2 referral-mobile-descr" v-html="$t('friendGets')" />
      </template>
      <p v-else class="referral-mobile-descr" v-html="$t('shareWithFriends')" />

      <!-- Подробнее об условиях -->
      <button class="referral-mobile-link" @click="showModal = true">
        <span v-html="$t('conditionsDetails')" />
        <UiInlineIcon name="arrow-right-orange" class="inline-block" />
      </button>

      <div class="mt-12">
        <PromocodeCopy
          v-if="promocode"
          :promocode="promocode"
          @click.native="$emit('onCopy')"
        />
        <PromocodeNotAvailable v-else />
      </div>

      <!-- Поделиться промокодом -->
      <UiButton
        v-if="promocode && $isWebView"
        class="referral-mobile-share"
        size="LG"
        @click="$emit('onShare', promocode)"
      >
        {{ $t('sharePromocode') }}
      </UiButton>
    </div>

    <ConditionsModal v-model="showModal" :min-price="minPrice" />
  </div>
</template>

<script>
import { i18n } from './i18n';
import ConditionsModal from '@pure-ui/components/Referral/ConditionsModal/ConditionsModal.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import PromocodeNotAvailable from './components/PromocodeNotAvailable.vue';
import PromocodeCopy from './components/PromocodeCopy.vue';

export default {
  name: 'ReferralView',
  components: {
    PromocodeNotAvailable,
    ConditionsModal,
    PromocodeCopy,
    UiInlineIcon,
    UiButton,
  },
  props: {
    promocode: {
      type: String,
      default: '',
    },
    minPrice: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    showModal: false,
  }),
  i18n,
};
</script>

<style scoped>
.referral-mobile-view {
  @apply mt-5;
}
.referral-mobile-content {
  @apply mt-6 px-6;
}
.referral-mobile-title {
  @apply text-2xl font-bold leading-30;
}
.referral-mobile-descr {
  @apply mt-4 text-sm font-normal leading-22;
}
.referral-mobile-link {
  @apply mt-3 flex items-center
    text-sirius-orange-100 font-medium leading-18;
}

.referral-mobile-promocode {
  @apply mt-12;
}

.referral-mobile-share {
  @apply mt-3;
}
</style>
