/* eslint-disable */
const TRANSITION_DURATION = 300;

/**
 * Одновременно анимирует фон и контейнер
 */
export default {
  name: 'ComplexTransition',
  props: {
    bgSelector: {
      type: String,
      required: true,
    },
    containerSelector: {
      type: String,
      required: true,
    },
  },
  render(h) {
    return h(
      'transition',
      {
        props: { css: false },
        on: {
          enter: this.enter,
          leave: this.leave,
        },
      },
      this.$slots.default
    );
  },
  methods: {
    enter(el, done) {
      const container = this.$el.querySelector(this.containerSelector);
      container.style.transition = `all ${TRANSITION_DURATION}ms`;
      container.style.transform = 'translateY(100%)';
      container.style.transformOrigin = 'center bottom';
      container.style.opacity = '0';
      const bg = this.$el.querySelector(this.bgSelector);
      bg.style.transition = `all ${TRANSITION_DURATION}ms`;
      bg.style.opacity = '0';
      requestAnimationFrame(() => {
        container.style.transform = 'translateY(0%)';
        container.style.opacity = '1';
        bg.style.opacity = '1';
        setTimeout(done, TRANSITION_DURATION);
      });
    },
    leave(el, done) {
      const container = this.$el.querySelector(this.containerSelector);
      container.style.transition = `all ${TRANSITION_DURATION}ms`;
      container.style.transformOrigin = 'center bottom';
      const bg = this.$el.querySelector(this.bgSelector);
      bg.style.transition = `all ${TRANSITION_DURATION}ms`;
      bg.style.opacity = '1';
      requestAnimationFrame(() => {
        container.style.opacity = '0';
        container.style.transform = 'translateY(100%)';
        bg.style.opacity = '0';
        setTimeout(done, TRANSITION_DURATION);
      });
    },
  },
};
