<template>
  <MainLayout :with-footer="false">
    <template v-if="$isWebView" #header>
      <ToolBar hide-help> {{ $t('title') }} </ToolBar>
    </template>
    <ProfileLayout :container-width="488" :has-support="!$isDesktop">
      <IINPage
        class="iin-view"
        :iin="iin"
        :state="state"
        :toggle="toggle"
        :phone-number="phone"
        @update:toggle="onToggle"
        @update:iin="onInputIIN"
        @onSave="onSave"
        @onWhatsapp="onWhatsapp"
        @onPhone="onPhone"
      />
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import debounce from 'debounce';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { TEL_NUM } from '@services/support/constants';
import { SupportService } from '@services/support';
import { loadState, saveState } from './lib/storage';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import IINPage from '@pure-ui/components/IINPage/IINPage.vue';
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@layout/ToolBar.vue';

export default {
  name: 'IINView',
  components: {
    ProfileLayout,
    MainLayout,
    ToolBar,
    IINPage,
  },
  inject: ['toast'],
  data() {
    return {
      iin: '',
      toggle: false,
      phone: TEL_NUM,
      state: 'default',
    };
  },
  computed: {
    isValid() {
      return this.iin.length === 16;
    },
  },
  created() {
    let { iin, toggle } = loadState();
    this.toggle = toggle;
    this.iin = iin;
    if (this.toggle) this.validate();
  },
  mounted() {
    Analitycs.logEvent(EVENTS.IIN_PAGE_ENTER);
  },
  methods: {
    analytics(action = 'submit') {
      Analitycs.logEvent(EVENTS.RECEIPT_IIN, {
        action: action,
        state: this.state,
        value: this.iin,
        isToggled: this.toggle,
      });
    },
    saveToLS() {
      saveState(this.iin, this.isValid ? this.toggle : false);
    },
    validate() {
      this.state = this.isValid ? 'success' : 'error';
    },
    onInputIIN(value) {
      this.iin = value;
      this.saveToLS();
      if (!this.isValid) this.state = 'default';
    },
    onToggle(value) {
      this.toggle = value;
      this.saveToLS();
      this.analytics('toggle');

      if (this.toggle && this.isValid) {
        this.validate();
        this.toast.show(this.$t('successToast'), 'success');
      } else this.state = 'default';
    },
    onSave() {
      this.validate();

      this.isValid
        ? this.toast.show(this.$t('successToast'), 'success')
        : this.toast.show(this.$t('errorToast'), 'error');

      this.analytics();
      this.saveToLS();
    },
    onPhone: debounce(function () {
      SupportService.clickSupport('call');
    }, 300),
    onWhatsapp: debounce(function () {
      SupportService.clickSupport('whatsapp');
    }, 300),
  },
  i18n: {
    messages: {
      ru: {
        title: 'Указывать БИН/ИИН в чеке',
        successToast: 'БИН/ИИН будет указан в чеке',
        errorToast: 'Введите корректный БИН/ИИН',
      },
      kk: {
        title: 'БИН/ЖСН чекте көрсету',
        successToast: 'БСН/ЖСН чекте көрсетіледі',
        errorToast: 'Дұрыс БСН/ЖСН жазыңыз',
      },
    },
  },
};
</script>

<style scoped>
.iin-view {
  @apply px-4 mt-3 pb-28 
    md:px-0 md:mt-0;
}
</style>
