import { authEmitter, AUTH_EVENTS } from '@shared/services/auth/events';
import { ClientService } from '@shared/services/client';

const ymId = 70028893;

function reachGoal(target) {
  if (typeof window.ym === 'function') {
    window.ym(ymId, 'reachGoal', target);
  }
}
function setUserId() {
  const userId = ClientService.getUserId();
  if (typeof window.ym === 'function' && userId) {
    window.ym(ymId, 'setUserID', userId);
  }
}
function init() {
  authEmitter.on(AUTH_EVENTS.ON_AUTH, setUserId);
}

export const YandexMetrikaService = {
  reachGoal,
  init,
};
