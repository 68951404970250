<template>
  <div class="TopHero">
    <TopHeroMobile v-if="!$isDesktop" />
    <TopHeroDesktop v-if="$isDesktop" />
  </div>
</template>

<script>
import TopHeroMobile from '@/views/ny-2023-contest/components/TopHero/components/TopHeroMobile/TopHeroMobile.vue';
import TopHeroDesktop from '@/views/ny-2023-contest/components/TopHero/components/TopHeroDesktop/TopHeroDesktop.vue';

export default {
  name: 'TopHero',
  components: {
    TopHeroMobile,
    TopHeroDesktop,
  },
  i18n: {
    messages: {
      ru: {},
    },
  },
};
</script>

<style scoped>
.TopHero {
}
</style>
