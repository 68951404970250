<template>
  <div class="contains mb-7 md:mb-16 last:mb-0">
    <div>
      <UiNavigationTitle
        class="px-4 md:px-0"
        main
        :navigation="{ path: '/catalog' }"
        @click.native.capture="logCatalogOpened('all')"
      >
        {{ $i18n.locale === 'ru' ? 'Категории' : 'Категориялар' }}
      </UiNavigationTitle>

      <div
        class="px-4 md:px-0 mt-3 md:mt-5 gap-2 md:gap-3"
        style="display: grid; grid-template-columns: repeat(6, 1fr)"
      >
        <CategoryCard
          v-for="item in catGroup.categories"
          :key="item.id"
          :category="item"
          :grid="calcGrid(catGroup, item)"
        />
      </div>
      <div class="px-4 pt-4">
        <AllCategoriesButton
          link="/catalog"
          @click.native.capture="logCatalogOpened('show_all_categories')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { store } from '@/store';
import UiNavigationTitle from '@ui/UiNavigationTitle.vue';
import CategoryCard from '@/views/main/components/CategoryCard.vue';
import AllCategoriesButton from './components/AllCategoriesButton/AllCategoriesButton.vue';
import img451 from './img/451.png';
import img470 from './img/470.png';

function calcGrid(parentCategory, category) {
  const sizes = {
    1: 6,
    '1/2': 3,
    '1/3': 2,
    '2/3': 4,
  };
  let result = '1/7';
  if (parentCategory.block_size) {
    const add = parentCategory.block_size + sizes[category.block_size];
    if (add > 6) {
      parentCategory.block_size = sizes[category.block_size];
      result = `1/${sizes[category.block_size] + 1}`;
    } else {
      result = `${parentCategory.block_size + 1}/${add + 1}`;
      parentCategory.block_size = add;
    }
  } else {
    parentCategory.block_size = sizes[category.block_size];
    result = `1/${parentCategory.block_size + 1}`;
  }
  return result;
}

const STATIC_CATEGORIES = [
  { id: 451, block_size: '2/3', icon: img451 },
  { id: 470, block_size: '1/3', icon: img470 },
  { id: 390, block_size: '1/3' },
  { id: 391, block_size: '1/3' },
  { id: 591, block_size: '1/3' },
];

const catGroup = computed(() => {
  const result = {
    id: 18,
    icon: 'https://lavka.object.pscloud.io/static/upload/images/lavka/categories/1669711342-827.png',
    categories: [],
    block_size: 6,
  };

  for (let c of STATIC_CATEGORIES) {
    const category = store.state.catalog.categories.find(v => v.id === c.id);
    result.categories.push({
      ...category,
      block_size: c.block_size,
      icon: c.icon ?? category.icon,
    });
  }

  return result;
});

function logCatalogOpened(from) {
  Analitycs.logEvent(EVENTS.CATALOG_OPENED, { from });
}
</script>
