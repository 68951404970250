<template>
  <MainLayout>
    <template #header>
      <ToolBar :back="onBackClick"> Детали заказа </ToolBar>
    </template>
    <UiRippleLoader class="mx-auto" />
  </MainLayout>
</template>

<script>
import { api } from '@shared/services/api';
import { mapGetters, mapState } from 'vuex';
import { Logger as LOG } from '@shared/services/logger';

import MainLayout from '@layout/MainLayout.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';

export default {
  name: 'PreorderView',
  components: {
    UiRippleLoader,
    MainLayout,
    ToolBar,
  },
  computed: {
    ...mapState(['initPromise']),
    ...mapGetters(['isAuthorized']),
  },
  async mounted() {
    const initPromise =
      this.initPromise instanceof Promise
        ? this.initPromise
        : Promise.resolve();

    initPromise
      .catch(err => {
        throw err;
      })
      .finally(() => {
        this.isAuthorizedUpdated();
      });
  },
  methods: {
    onBackClick() {
      this.$router.push({ name: 'main' });
    },
    async isAuthorizedUpdated() {
      const preorderId = this.$route.params.id;
      LOG.event('Preorder', { preorderId, isAuthorized: this.isAuthorized });

      if (this.isAuthorized) {
        api.lavka
          .orderIdFromPreorderId(preorderId)
          .then(res => {
            const orderId = res.data.id.toString();
            this.$router.push({
              name: 'order',
              params: { id: orderId },
              query: { ...this.$route.query, preorderId },
            });
          })
          .catch(() => {
            this.$router.push({ name: 'orders' });
          });
      } else {
        this.onBackClick();
      }
    },
  },
};
</script>
