<template>
  <div class="ReferralDesk referral-desk-view">
    <!-- Дарите друзьям 1500 ₸ — получайте 1500 ₸ -->
    <BannerDesk :title="$t('title')" />

    <div class="referral-desk-promocode">
      <PromocodeNotAvailable v-if="!promocode" />
      <PromocodeCopyDesk
        v-else
        :promocode="promocode"
        @click.native="$emit('onCopy')"
      />
    </div>

    <div class="referral-desk-content">
      <!-- Делитесь с друзьями своим промокодом... -->
      <p
        v-if="promocode"
        class="referral-desk-descr"
        v-html="$t('shareWithFriends')"
      />
      <template v-else>
        <p class="referral-desk-descr" v-html="$t('do3orders')" />
        <p class="mt-3 referral-desk-descr" v-html="$t('friendGets')" />
      </template>

      <!-- Подробнее об условиях -->
      <button class="referral-desk-link" @click="open = !open">
        <span v-html="$t('conditionsDetails')" />
        <UiInlineIcon
          name="arrow-right-orange"
          :class="`${open ? '-rotate-90' : 'rotate-90'}`"
          class="referral-desk-arrow"
        />
      </button>

      <UiCollapseAnimation :open="open">
        <ConditionsList
          class="referral-desk-conditions"
          :min-price="minPrice"
        />
      </UiCollapseAnimation>
    </div>
  </div>
</template>

<script>
import { i18n } from './i18n';
import UiCollapseAnimation from '@pure-ui/components/UiCollapseAnimation/UiCollapseAnimation.vue';
import ConditionsList from '@pure-ui/components/Referral/ConditionsList/ConditionsList.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import PromocodeNotAvailable from './components/PromocodeNotAvailable.vue';
import PromocodeCopyDesk from './components/PromocodeCopyDesk.vue';
import BannerDesk from './components/BannerDesk.vue';

export default {
  name: 'ReferralDesk',
  components: {
    PromocodeNotAvailable,
    UiCollapseAnimation,
    PromocodeCopyDesk,
    ConditionsList,
    UiInlineIcon,
    BannerDesk,
  },
  props: {
    promocode: {
      type: String,
      default: '',
    },
    minPrice: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    open: false,
  }),
  i18n,
};
</script>

<style scoped>
/* .referral-desk-view {
  @apply
} */

.referral-desk-promocode {
  @apply mt-3;
}
.referral-desk-content {
  @apply mt-8 ml-7;
  max-width: 466px;
}
.referral-desk-descr {
  @apply leading-6;
}
.referral-desk-link {
  @apply mt-7 flex items-center
    text-sirius-orange-100 font-bold leading-5;
}
.referral-desk-conditions {
  @apply mt-6 ml-3 text-sm leading-22;
}
.referral-desk-arrow {
  @apply ml-1  inline-block transition-transform duration-300;
}
</style>
