<template>
  <div class="MmTopHero">
    <div class="MmTopHero__container">
      <img
        v-if="!$isDesktop"
        src="@/views/micromarket/components/MmTopHero/images/m-visual.jpg"
        class="MmTopHero__img block ml-auto mr-auto"
        width="328"
        height="272"
      />
      <img
        v-if="$isDesktop"
        src="@/views/micromarket/components/MmTopHero/images/d-visual.jpg"
        class="MmTopHero__img block ml-auto mr-auto"
        width="640"
        height="580"
      />
      <h1 class="MmTopHero__title" v-html="$t('title')" />
      <p class="MmTopHero__descr" v-html="$t('descr')" />
      <MmButton class="MmTopHero__btnApply" size="m-52" @click="onButtonClick">
        <span v-html="$t('toApply')" />
      </MmButton>
    </div>
  </div>
</template>

<script>
import MmButton from '@/views/micromarket/components/MmButton/MmButton.vue';
import { ScrollTo } from '@/views/micromarket/utils/scroll-to';

export default {
  name: 'MmTopHero',
  components: { MmButton },
  inject: [],
  props: {},
  data: () => ({}),

  methods: {
    onButtonClick() {
      if (this.$isDesktop) {
        ScrollTo.form();
      } else {
        this.$router.push({ query: { form: true } });
      }
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'Choco-микромаркеті сіздің кеңсеңізде',
        descr: `
          Қызметкерлеріңіз аш қалмас үшін микромаркеттерді дайын
          тамағамымен қоса тегін орнатамыз.
        `,
        toApply: 'Өтінім қалдыру',
      },
      ru: {
        title: 'Choco-микромаркет в&nbsp;вашем офисе',
        descr: `
          Бесплатно установим микромаркет с&nbsp;готовой едой,
          чтобы ваши сотрудники
          не&nbsp;остались голодными.
        `,
        toApply: 'Оставить заявку',
      },
    },
  },
};
</script>

<style scoped>
.MmTopHero {
  padding: 16px 0 0;
  box-sizing: content-box;
}

.MmTopHero__container {
  width: calc(100% - 32px);
  margin: auto;
  max-width: 535px;
}

.MmTopHero__title {
  padding-top: 24px;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
}

.MmTopHero__descr {
  padding-top: 12px;
}

.MmTopHero__btnApply {
  width: 100%;
  display: block;
  margin-top: 52px;
}

@media (min-width: 768px) {
  .MmTopHero {
    padding-top: 0;
    overflow: hidden;
  }

  .MmTopHero__container {
    padding-top: 132px;
    min-height: calc(580px + 32px);
    position: relative;
    max-width: 988px;
    z-index: 1;
  }

  .MmTopHero__title {
    padding-top: 0;
    font-size: 56px;
    line-height: 64px;
    max-width: 676px;
  }

  .MmTopHero__descr {
    padding-top: 16px;
    font-size: 18px;
    line-height: 28px;
    max-width: 496px;
  }

  .MmTopHero__btnApply {
    margin-top: 64px;
    max-width: 340px;
  }

  .MmTopHero__img {
    position: absolute;
    right: -200px;
    top: 32px;
    z-index: -1;
  }
}
</style>
