<template>
  <transition name="slide-down" appear>
    <div class="UiToast" style="">
      <div
        class="UiToast_content"
        :class="{ __center: !status }"
        v-on="$listeners"
      >
        <div class="UiToast_text" v-html="content"></div>
        <div class="UiToast_icon">
          <component :is="iconComp" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconSuccess from './components/IconSuccess.vue';
import IconError from './components/IconError.vue';
import IconWarning from './components/IconWarning.vue';
import IconInfo from './components/IconInfo.vue';

const STATUSES = ['success', 'error', 'warning', 'info'];

export default {
  name: 'UiToast',
  props: {
    content: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: null,
      validator(v) {
        if (v) {
          return STATUSES.includes(v);
        }
        return true;
      },
    },
  },
  computed: {
    iconComp() {
      let icons = {
        success: IconSuccess,
        error: IconError,
        warning: IconWarning,
        info: IconInfo,
      };

      return icons[this.status];
    },
  },
};
</script>

<style scoped>
.UiToast {
  @apply container flex justify-end;
}
.UiToast_content {
  @apply p-4 
  w-full md:w-96 max-w-full 
  rounded-3xl 
  font-medium text-white leading-tight text-left 
  pointer-events-auto duration-300 
  flex items-center justify-between 
  cursor-pointer;
  background: rgba(0, 0, 0, 0.8);
  box-shadow:
    0px 62px 92px rgba(0, 0, 0, 0.08),
    0px 19px 126px rgba(0, 0, 0, 0.0334858),
    0px 10px 28px rgba(0, 0, 0, 0.06),
    0px 19px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 10px 9px rgba(0, 0, 0, 0.0132742),
    0px 11px 2px rgba(0, 0, 0, 0.00743532);
}
.UiToast_content.__center {
  @apply justify-center;
}
</style>
