<template>
  <UiTooltip
    :style="`--tooltip-width: ${$isDesktop ? 276 : 224}px`"
    :trigger="$isDesktop ? 'hover' : 'click'"
    :size="$isDesktop ? 'lg' : 'md'"
    :position="tooltipPosition"
    round="lg"
  >
    <UiInlineIcon
      :name="$isDesktop ? 'info-icon-desk' : 'info-icon'"
      class="text-sirius-gray-100 md:hover:text-black"
    />
    <template #tip>
      <div><slot name="tip"></slot></div>
    </template>
  </UiTooltip>
</template>

<script>
import UiTooltip from '@pure-ui/components/UiTooltip/UiTooltip.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  components: { UiTooltip, UiInlineIcon },
  props: {
    position: {
      type: String,
      default: '',
    },
  },
  computed: {
    tooltipPosition() {
      return this.position || 'right-start';
    },
  },
};
</script>

<style></style>
