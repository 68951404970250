import { createStorage } from '@shared/services/storage';

let storage = createStorage(window.localStorage);
let iinKey = 'additionalFeat::iin';
let toggleKey = 'additionalFeat::iin-toggle';

export function saveState(iin, toggle) {
  storage.set(iinKey, iin);
  storage.set(toggleKey, toggle);
}
export function loadState() {
  return {
    iin: storage.get(iinKey, ''),
    toggle: storage.parse(toggleKey, false),
  };
}
