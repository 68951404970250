export const i18n = {
  messages: {
    ru: {
      isCourierChangedStatusOnTime:
        'Вовремя ли курьер отметил статус «доставлено»?',
      deliveryTime: 'Время доставки:',
      onTime: 'вовремя',
      notOnTime: 'не вовремя',
      toChange: 'Изменить',
      rateOrderAndService: 'Оцените заказ и работу сервиса',
      problemWithOrder: 'проблема с заказом',
      thanksWhatDidYouLike: 'Спасибо! Что вам понравилось?',
      tellUsWhatShouldWeMakeBetter: 'Расскажите, что нам улучшить?',
      tellUsWhatWasWrong: 'Расскажите, что пошло не так?',
      toLeaveComment: 'оставить комментарий',
      problem: 'проблема',
      leaveTips: 'Оставить чаевые курьеру',
      ryadomCourier: 'Курьер Рядом',
      continue: 'Продолжить',
      done: 'Готово',
      provideTipsSum: 'Укажите сумму чаевых',
      cancel: 'Отменить',
      pleaseTellMore: 'Пожалуйста, <br /> расскажите подробнее',
      leaveComment: 'Оставьте комментарий',
      skip: 'Пропустить',
      anotherSum: 'другая сумма',
      plusAnotherSum: '+ другая сумма',
      pleaseRateOrder: 'Пожалуйста, оцените заказ',
      thanksForTips: 'Спасибо за чаевые',
      thanksForReview: 'Спасибо за отзыв',
      noTips: 'не оставлять',
      tags: {
        assortment: 'ассортимент',
        deliverySpeed: 'скорость доставки',
        deliveredNotOnTime: 'доставили позже',
        courierWork: 'работа курьера',
        service: 'сервис',
        somethingWrong: 'что-то не так с товаром в заказе',
      },
      reactions: {
        wowThanks: 'Ого! Спасибо! 😱',
        forDream: 'Почти хватает на мечту! 😎',
        wowThatMuch: 'Вот это да, спасибо! 😱',
        suchPleasure: 'Вау, так приятно! ❤',
        thanksFire: 'Спасибо 🔥🔥🔥',
        thanksCool: 'Благодарю 😎',
        yayThanks: 'Ухты! Спасибо 💥',
        whatPleasants: 'Какие приятности 🌸',
        veryPleasant: 'Очень приятно 🙊',
        omg: 'Ничего себе! 😳',
        madeMyDay: 'Вы сделали мой день! ⚡⚡⚡',
        plus100: 'Плюс 100 к моему настроению 💣',
      },
      descriptions: {
        sports: 'Люблю спорт ⚽️',
        walks: 'Люблю пешие прогулки 👟',
        eat: 'Люблю покушать 🥙',
        city: 'Люблю наш город ⛰',
        study: 'Люблю учиться 📕',
        travel: 'Люблю путешествовать 🎒',
        pets: 'Люблю животных 🐶',
        hills: 'Люблю горы ⛰',
        rain: 'Люблю дождь ☔️ ',
      },
      aims: {
        repair: 'Коплю на ремонт️  🔧',
        study: 'Коплю на учебу 📗',
        visa: 'Хочу поехать в отпуск заграницу 🏕',
        travel: 'Коплю на путешествие 🏝',
        flat: 'Коплю на квартиру 🏢',
        sports: 'Коплю на спортивный инвентарь 🏐',
        laptop: 'Коплю на ноутбук 💻',
        mamacar: 'Хочу купить маме автомобиль 🚗',
        university: 'Коплю на университет 📚',
        courses: 'Коплю на образовательные курсы 📓',
      },
    },
    kk: {
      isCourierChangedStatusOnTime:
        'Курьер «жеткізілді» статусын уақытында белгіледі ме?',
      deliveryTime: 'Жеткізу уақыты:',
      onTime: 'уақытылы',
      notOnTime: 'уақытылы емес',
      toChange: 'өзгерту',
      rateOrderAndService: 'Тапсырыс пен сервис жұмысын бағалаңыз',
      problemWithOrder: 'тапсырысқа қатысты қиындық',
      thanksWhatDidYouLike: 'Рахмет! Сізге не ұнады?',
      tellUsWhatShouldWeMakeBetter: 'Айтыңызшы, біз нені жақсарта аламыз?',
      tellUsWhatWasWrong: 'Айтыңызшы, не нәрсе ұнамады?',
      toLeaveComment: 'пікір қалдыру',
      problem: 'Қиындық туындады',
      leaveTips: 'Курьерге шайпұл қалдыру',
      ryadomCourier: 'Рядом курьеры',
      continue: 'Жалғастыру',
      done: 'Дайын',
      provideTipsSum: 'Шайпұлдың сомасын енгізіңіз',
      cancel: 'Бас тарту',
      pleaseTellMore: 'Толығырақ айтып беріңізші',
      leaveComment: 'Пікір қалдырыңыз',
      skip: 'Өткізу',
      anotherSum: 'басқа сома',
      plusAnotherSum: '+ басқа сома',
      pleaseRateOrder: 'Тапсырысты бағалаңызшы',
      thanksForTips: 'Шайпұл үшін рахмет',
      thanksForReview: 'Пікіріңіз үшін Рахмет',
      noTips: 'Қалдырмаймын',
      tags: {
        assortment: 'ассортимент',
        deliverySpeed: 'жеткізу жылдамдығы',
        deliveredNotOnTime: 'кеш жеткізді',
        courierWork: 'курьер жұмысы',
        service: 'қызмет',
        somethingWrong: 'тапсырыстағы тауар дұрыс болмады',
      },
      reactions: {
        wowThanks: 'УАУ! Рахмет! 😱',
        forDream: 'Арманға жетеді! 😎',
        wowThatMuch: 'Міне керемет, Рахмет! 😱',
        suchPleasure: 'УАУ, өте жақсы! ❤',
        thanksFire: 'Рахмет 🔥🔥🔥',
        thanksCool: 'Алғысым шексіз 😎',
        yayThanks: 'Мәссаған, рахмет 💥',
        whatPleasants: 'Қандай керемет 🌸',
        veryPleasant: 'Өте керемет 🙊',
        omg: 'Мәссаған! 😳',
        madeMyDay: 'Сіз менің күнімді ерекше еттіңіз! ⚡⚡⚡',
        plus100: 'Менің көңіл-күйіме 100к плюс 💣',
      },
      descriptions: {
        sports: 'Cпортты жақсы көремін ⚽️',
        walks: 'Жаяу серуендегенді жақсы көремін 👟',
        eat: 'Тамақтанғанды жақсы көремін 🥙',
        city: 'Біздің қаланы жақсы көремін ⛰',
        study: 'Оқуды жақсы көремін 📕',
        travel: 'Саяхаттағынды жақсы көремін 🎒',
        pets: 'Жануарларды жақсы көремін 🐶',
        hills: 'Тауларды жақсы көремін ⛰',
        rain: 'Жаңбырды жақсы көремін ☔️',
      },
      aims: {
        repair: 'Жөндеу жұмыстарына ақша жинап жатырмын 🔧',
        study: 'Оқуға ақша жианп жатырмын 📗',
        visa: 'Мен шет елде демалғым келеді 🏕',
        travel: 'Саяхатқа ақша жинап жатырмын 🏝',
        flat: 'Пәтерге ақша жинап жатырмын 🏢',
        sports: 'Мен спорттық жабдықтарға ақша жинап жатырмын 🏐',
        laptop: 'Ноутбукқа ақша жинап жатырмын 💻',
        mamacar: 'Мен анама көлік алғым келеді 🚗',
        university: 'Жоғарғы оқу орнына ақша жинап жатырмын 📚',
        courses: 'Білім алу курстарына ақша жинап жатырмын 📓',
      },
    },
  },
};
