/**
 * @typedef Condition
 * @prop {number} count какой по счету заказ
 * @prop {number} cashback сумма набранных кешбэков
 * @prop {number} percentage сколько процентов от стоимости заказа могут стать кешбэком
 * @prop {number} maxBonus максимальная сумма кешбэка
 * @prop {number} maxPrice сумма корзины что бы было максимальное количество кешбэка
 */

/**
 * Условия кешбэков они статичные.
 * Ну по идее регулируются в админке но на фронте пока так
 *
 * Но для задачи мы можем менять только поле cashback,
 * это чисто сколько юзер заработал кешбэка с одного заказа.
 * Мы это сами должны высчитать
 */
export const CASHBACK_CONDITIONS = [
  {
    count: 1,
    cashback: 0,
    percentage: 50,
    maxBonus: 1000,
    maxPrice: 2000,
  },
  {
    count: 2,
    cashback: 0,
    percentage: 50,
    maxBonus: 1000,
    maxPrice: 2000,
  },
  {
    count: 3,
    cashback: 0,
    percentage: 20,
    maxBonus: 500,
    maxPrice: 2500,
  },
  {
    count: 4,
    cashback: 0,
    percentage: 10,
    maxBonus: 500,
    maxPrice: 5000,
  },
  {
    count: 5,
    cashback: 0,
    percentage: 5,
    maxBonus: 500,
    maxPrice: 10000,
  },
];
