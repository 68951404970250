import { client } from '../../clients';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';

export default function (addressId, warehouseId, isAuthorized = false) {
  return client
    .get(
      `/lavka/v1${isAuthorizedUrl(
        isAuthorized
      )}/user_address/${addressId}/fees`,
      {
        params: {
          warehouse_id: warehouseId,
        },
        useDeviceId: true,
      }
    )
    .then(response => response.data);
}
