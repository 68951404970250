<template>
  <div class="UiInlineIcon">
    <component :is="icon" v-bind="$attrs" v-on="$listeners" />
  </div>
</template>

<script>
export default {
  name: 'UiInlineIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      const iconName = this.name;
      return () => import(`../../../pure-ui/icons/${iconName}.svg`);
    },
  },
};
</script>
