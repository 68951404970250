<template>
  <div
    class="FAQView faq-view"
    :class="$isWebView ? 'mt-6 md:mt-0' : 'mt-2 md:mt-0'"
  >
    <CommercialBanners
      v-if="banners.length"
      class="faq-banners"
      :banners="banners"
      :desktop-max-per-screen="4"
      @onStoryEnd="$emit('onStoryEnd', $event)"
      @onBannerClick="$emit('onBannerClick', $event)"
      @onCategoryButtonClick="$emit('onCategoryButtonClick', $event)"
    />

    <div id="faq-content" class="faq-content">
      <FAQCategory
        v-for="(cat, catId) in faqs"
        :id="cat.category_id"
        :key="`cat_${catId}`"
        class="faq-category"
      >
        <template #title>
          <p>{{ cat.title }}</p>
        </template>
        <FAQQuestion
          v-for="(question, index) in cat.questions"
          :key="`question_${index}`"
        >
          <template #question>{{ question.question }}</template>
          <template #answer>
            <div v-html="question.answer"></div>
          </template>
        </FAQQuestion>
      </FAQCategory>
    </div>
  </div>
</template>

<script>
import CommercialBanners from '@pure-ui/components/CommercialBanners/CommercialBanners.vue';
import FAQCategory from './components/FAQCategory.vue';
import FAQQuestion from './components/FAQQuestion.vue';
export default {
  name: 'FAQView',
  components: { CommercialBanners, FAQCategory, FAQQuestion },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
    faqs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
/* .faq-view {
  @apply;
}
.faq-banners {
  @apply;
} */
.faq-content {
  @apply mt-12 px-4;
}
.faq-category {
  @apply mt-10;
}
</style>
