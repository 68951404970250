<template>
  <UiPopup name="companyPassport">
    <template #hide-description>
      <div class="mt-auto"></div>
    </template>
    <div class="">
      <img :src="imageUrl" alt="" />
    </div>
  </UiPopup>
</template>

<script>
import UiPopup from '@ui/ui-popup/UiPopup.vue';

const warehouses = {
  1: () => import('@img/passports/sirius.jpg'),
  2: () => import('@img/passports/moskva.jpg'),
  3: () => import('@img/passports/cska.jpg'),
  4: () => import('@img/passports/shahristan.jpg'),
  5: () => import('@img/passports/samal.png'),
  6: () => import('@img/passports/zk.png'),
  7: () => import('@img/passports/zhetisu.png'),
  8: () => import('@img/passports/aksai.jpg'),
  9: () => import('@img/passports/aitieva.jpg'),
  10: () => import('@img/passports/radostovca.jpg'),
  11: () => import('@img/passports/kb.jpg'),
  12: () => import('@img/passports/miras.jpg'),
};

export default {
  name: 'CompanyPassportPopup',
  components: {
    UiPopup,
  },
  data: () => ({
    imageUrl: null,
  }),
  computed: {
    warehouseId() {
      return this.$store.getters['delivery/warehouseId'];
    },
  },
  watch: {
    warehouseId: {
      immediate: true,
      handler() {
        this.setImageUrl();
      },
    },
  },
  methods: {
    setImageUrl() {
      warehouses[this.warehouseId || 1]().then(({ default: v }) => {
        this.imageUrl = v;
      });
    },
  },
};
</script>
