<template>
  <component
    :is="`${$route.name === 'main' ? 'a' : 'router-link'}`"
    href="/"
    to="/"
    :class="`LogoDesk logo-desk ${mini ? '--mini' : ''}`"
  >
    <img
      v-show="mini"
      src="@/assets/images/logo/choco-desktop-mini.png"
      alt="logo"
      width="48"
      height="48"
    />

    <img
      v-show="!mini && $isDesktop"
      src="@/assets/images/logo/desktop-logo.png"
      alt="logo"
      width="196"
      height="28"
    />
    <img
      v-show="!mini && !$isDesktop"
      src="@/assets/images/logo/desktop-logo.png"
      alt="logo"
      width="155"
      height="24"
    />
  </component>
</template>

<script>
export default {
  name: 'LogoDesk',
  props: {
    mini: Boolean,
  },
};
</script>

<style scoped>
.logo-desk {
  @apply flex items-center justify-center cursor-pointer;

  width: 196px;
  height: 28px;
  min-width: 196px;
  min-height: 28px;
}
.logo-desk.--mini {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
}
@media (max-width: 768px) {
  .logo-desk {
    width: 155px;
    height: 24px;
    min-width: 155px;
    min-height: 24px;
  }
}
</style>
