<template>
  <div class="">
    <UiTooltipSimple
      v-if="type === 'delivery-context-mob'"
      :show="show"
      position="bottom-end"
    >
      <InfoIcon class="text-sirius-gray-100" @click.native="show = !show" />
      <template #tip>
        <p
          class="distant-mob-tooltip"
          @click="show = false"
          v-html="$t('deliveryInfoSmall', [time])"
        />
      </template>
    </UiTooltipSimple>

    <UiTooltip
      v-else
      style="--tooltip-width: 320px"
      position="bottom-end"
      round="lg"
    >
      <InfoIcon class="text-sirius-gray-100 hover:text-black" />
      <template #tip><div v-html="$t('deliveryInfo')"></div></template>
    </UiTooltip>
  </div>
</template>

<script>
import UiTooltipSimple from '@pure-ui/components/UiTooltip/UiTooltipSimple.vue';
import UiTooltip from '@pure-ui/components/UiTooltip/UiTooltip.vue';
import InfoIcon from './InfoIcon.vue';

export default {
  name: 'ExtendedDeliveryTooltip',
  components: { UiTooltipSimple, UiTooltip, InfoIcon },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'delivery-context-desk',
    },
    time: {
      type: String,
      default: '30-40',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onOpen() {
      console.log('onOpen');
    },
  },
  i18n: {
    messages: {
      kk: {
        deliveryInfo:
          'Жеткізу 30 – 40 минут, мекен-жайыңыз <br/>  кеңейтілген жеткізу аймағында',
        deliveryInfoSmall: `Жеткізу {0} минут алады,<br /> мекенжай кеңейтілген аймақта орналасқан`,
      },
      ru: {
        deliveryInfo:
          'Доставка за 30 – 40 минут, адрес <br/> находится в расширенной зоне доставки',
        deliveryInfoSmall: `Доставка за {0} минут,<br /> адрес находится <br /> в&nbsp;расширенной зоне`,
      },
    },
  },
};
</script>

<style scoped>
.distant-mob-tooltip {
  @apply p-3 pt-2 text-left text-xs font-medium leading-14 whitespace-nowrap;
}
</style>
