<template>
  <MainLayout>
    <template #header>
      <ToolBar> Проблемы с заказом </ToolBar>
    </template>
    <UiRippleLoader class="mx-auto" />
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { api } from '@shared/services/api';

import MainLayout from '@layout/MainLayout.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';

export default {
  name: 'ProblemsPreorderView',
  components: {
    MainLayout,
    ToolBar,
    UiRippleLoader,
  },
  computed: {
    ...mapGetters(['isAuthorized']),
  },
  watch: {
    isAuthorized(val) {
      if (val) {
        this.isAuthorizedUpdated();
      }
    },
  },
  methods: {
    isAuthorizedUpdated() {
      if (this.isAuthorized) {
        api.lavka
          .orderIdFromPreorderId(this.$route.params.id)
          .then(res => {
            this.$router.push(`/problems/${res.data.id}`);
          })
          .catch(() => {
            this.$router.push('/orders');
          });
      }
    },
  },
};
</script>
