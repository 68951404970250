<template>
  <MainLayout>
    <template #header>
      <ToolBar v-if="$isWebView">
        <p v-html="$t('xmasFatherNear')" />
      </ToolBar>
    </template>
    <TopHero />
    <ContestDone />
    <CurrentScore
      v-if="$store.getters['isAuthorized']"
      :score="$store.getters['nyContest/currentUserScore']"
      :place="$store.getters['nyContest/currentUserPlace']"
      @onScrollTo="scrollToId('ny-table')"
    />
    <ContestPrizes />
    <ContestTable
      :score="$store.getters['nyContest/currentUserScore']"
      :place="$store.getters['nyContest/currentUserPlace']"
      :list="$store.getters['nyContest/winnersList']"
      @onAuthBtnClick="authorize"
    />
    <IphoneLottery />
    <ContestRules />
  </MainLayout>
</template>

<script>
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';

import { AuthService } from '@shared/services/auth';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { NyContestService } from '@services/ny-contest';

import ContestRules from '@/views/ny-2023-contest/components/ContestRules/ContestRules.vue';
import TopHero from './components/TopHero/TopHero.vue';
import CurrentScore from './components/CurrentScore/CurrentScore.vue';
import ContestPrizes from './components/ContestPrizes/ContestPrizes.vue';
import ContestTable from './components/ContestTable/ContestTable.vue';
import ContestDone from './components/ContestDone/ContestDone.vue';
import IphoneLottery from './components/IphoneLottery/IphoneLottery.vue';

export default {
  name: 'NY2023ContestView',
  components: {
    ContestRules,
    ContestTable,
    ContestPrizes,
    MainLayout,
    ToolBar,
    ContestDone,
    IphoneLottery,

    TopHero,
    CurrentScore,
  },
  data: () => ({
    ratingData: null,
  }),
  mounted() {
    Analitycs.logEvent(EVENTS.NY_CONTEST_2023_PAGE_VIEWED, {
      from: this.$route.query.from,
    });
    NyContestService.loadDataToStore();
  },
  methods: {
    scrollToId(id) {
      const el = document.getElementById(id);

      const getHeaderHeight = () => {
        if (this.$isDesktop) return 173;
        if (!this.$isWebView) return 72;
        return 52;
      };

      if (el) {
        const top =
          el.getBoundingClientRect().y +
          window.pageYOffset -
          getHeaderHeight() -
          20;
        window.scrollTo({ top, behavior: 'smooth' });
      }
    },
    authorize() {
      AuthService.authorize();
    },
  },
  i18n: {
    messages: {
      ru: {
        xmasFatherNear: 'Дед&nbsp;Мороз&nbsp;Рядом',
      },
      kk: {
        xmasFatherNear: 'Рядом&nbsp;Аяз&nbsp;Атасы',
      },
    },
  },
};
</script>
