<template>
  <UiPopup
    :show="modelValue"
    :full-page="!$isDesktop"
    :with-title="$isDesktop"
    :closable="$isDesktop"
    style="--popup-width-d: 588px"
    @change="$emit('update:modelValue', $event)"
  >
    <template v-if="$isDesktop" #title>
      {{ $t('fillAddressDetails') }}
    </template>
    <div class="AddressFormModal">
      <HeaderBase
        v-if="!$isDesktop"
        class="AddressFormModal__header"
        @onBack="onGoBack"
      >
        {{ $t('title') }}
      </HeaderBase>

      <p class="AddressFormModal__title" @click="onGoBack">
        <IconArrow v-if="$isDesktop" class="mr-1" />
        {{ address }}
      </p>

      <AddressForm
        id="form"
        :state="state"
        @onFillAll="$emit('onFillAll', $event)"
        @onSubmit="$emit('onSubmit', $event)"
        @onValidationChange="$emit('onValidationChange', $event)"
      />

      <p v-if="$isDesktop" class="AddressFormModal__note">
        {{ $t('provideAddressName') }}
      </p>

      <UiButton form="form" class="AddressFormModal__btn" size="LG">
        {{ $t('saveAddress') }}
      </UiButton>
    </div>
  </UiPopup>
</template>

<script>
import AddressForm from '@pure-ui/components/Address/AddressForm/AddressForm.vue';
import HeaderBase from '@components/header/components/HeaderBase.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';
import IconArrow from './components/IconArrow.vue';

export default {
  components: {
    AddressForm,
    HeaderBase,
    IconArrow,
    UiButton,
    UiPopup,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
    state: {
      type: Object,
      default: null,
    },
    address: {
      type: String,
      default: 'Алматы, Байзакова, 280',
    },
  },
  methods: {
    onGoBack() {
      this.$emit('update:modelValue', false);
      this.$emit('onGoBack');
    },
  },
  i18n: {
    messages: {
      kk: {
        saveAddress: 'Мекенжайды сақтау',
        title: 'Жеткізу мекенжайы',
        fillAddressDetails: 'Мекен-жайыңызды толтырыңыз',
        provideAddressName: 'Ыңғайлы болу үшін мекен-жайға ат қоюыңызға болады',
      },
      ru: {
        saveAddress: 'Сохранить адрес',
        title: 'Адрес доставки',
        fillAddressDetails: 'Заполните данные адреса',
        provideAddressName:
          'Укажите название, чтобы легко ориентироваться между своими адресами',
      },
    },
  },
};
</script>

<style scoped>
.AddressFormModal {
  @apply h-full flex flex-col;
}
.AddressFormModal__header {
  @apply -mx-2;
}
.AddressFormModal__title {
  @apply my-4 text-lg font-bold leading-6
  text-sirius-orange-100
  
  md:mt-7 md:mb-5 md:flex md:items-center md:text-2xl md:leading-30;
}

.AddressFormModal__note {
  @apply pl-1 mt-1 
  font-normal text-xs leading-14
  text-sirius-gray-1000;
}

.AddressFormModal__btn {
  @apply mt-auto md:mt-8;
}
</style>
