<template>
  <div class="ArrowRightIcon">
    <svg
      v-if="$isDesktop"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 4.5L17.25 12L9.75 19.5"
        stroke="#242424"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

    <svg
      v-else
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.50389 5L16.5 12.021L9.5 19"
        stroke="#242424"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'ArrowRightIcon',
};
</script>

<style scoped>
.ArrowRightIcon {
}
</style>
