export const AMPLITUDE_KEY = 'fc5c415f8e0697ab9e6e4adb5663dbaf';
export const AMPLITUDE_ECOSYSTEM_INSTANCE_NAME = 'ecosystem';

const cashbackEvents = {
  CASHBACKS_PAGE_VIEWED: 'cashbacks_page_viewed',
  CASHBACKS_INIT: 'cashbacks_init',
};

const ProductReviewEvents = {
  REVIEW_SHOWN: 'review_shown',
  REVIEW_OPEN: 'review_open',
  REVIEW_CLOSE: 'review_close',
  REVIEW_DONE: 'review_done',
};
const heavyOrderEvents = {
  VIEW_HEAVY_ORDER_BASKET_POPUP: 'view_heavy_order_basket_popup',
  /*
    Работает тогда когда показываем попапку с полной корзиной

    VIEW_HEAVY_ORDER_BASKET_POPUP:  {from: from || unknown},
  */
};

const serviceFee = {
  /** когда мы показали сервисный сбор первый раз */
  SERVICE_FEE_FIRST_TIME_SEEN: 'service_fee_first_time_seen',
  /** когда открыли модалку сервисного сбора */
  SERVICE_FEE_MODAL_SHOWN: 'service_fee_modal_shown',
};

const deeplinks = {
  PROBLEM_QR: 'problem_qr',
  /*
    Работает когда юзер отсканироввал QR код в пакете и перешел в проблемы

    PROBLEM_QR: {
      lastOrderId: id
    },
  */
};
const paidOrderEvents = {
  // all_event_properties: {
  //   isPaidDelivery : true || false,
  //   deliveryPrice : 0 || 250 || 400
  // }
  PAID_ORDER_SHOWN: 'paid_order_shown',
  /*
    Работает тогда когда показываем над кнопкой "Перейти в корзину", плашку с условиями платной доставки

    PAID_ORDER_SHOWN: 'paid_order_shown',
  */
  PEYMENT_MODAL_OPENED: 'payment_modal_opened',
  /*
    При открываний модалки с условиями платной доставки
    PEYMENT_MODAL_OPENED: {
      clickedFrom: 'main' || 'basket-upper-block' || 'basket-lower-block',
      clickedOn: 'block' || 'arrow' || 'conditions',
    }
  */
};

const notifications = {
  ON_NOTIFICATIONS_INIT: 'on_notifications_init',
  ON_OPEN_NOTIFICATION_MODAL: 'on_open_notification_modal',
  ON_CLICK_NOTIFICATION_BUTTON: 'on_click_notification_button',
};

const samplings = {
  ON_GETTING_SAMPLINGS: 'ON_GETTING_SAMPLINGS',
};
const adults = {
  ON_ADULTS_MODAL: 'on_adults_modal',
};
const userAgreement = {
  VIEWED_USER_AGREEMENT: 'viewed_user_agreement',
};

const questionnaire = {
  QUESTIONNAIRE_MODAL_CLICKED: 'quesstionnaire_modal_clicked',
};
//TODO: аналитику проверить с астаной
const address = {
  /**
   * когда юзер открывает списов адресов
   *
   * address_list: [] - список адресов
   * from: 'no_delivery_modal' |
   *        'profile' |
   *        'desk_header' |
   *        'cart_mobile_header' |
   *        'main_mobile_header' |
   *        'on_payment_address_change_confirmation' |
   *        'address_confirmation_popover'
   */
  VIEWED_ADDRESS: 'viewed_address',

  /** когда юзер удаляет адрес
   *
   * addressId: number - айди адреса
   * from: 'address_list_mobile' | 'profile'
   */
  DELETED_ADDRESS: 'deleted_address',

  /** когда зашли в карту для добавления или изменения адреса
   * type: 'creating' | 'editing,
   * from: 'main_mobile_header' |
   *        'cart_mobile_header' |
   *        'address_list_mobile' |
   *        'address_list_desk' |
   *        'desk_header' |
   *        'on_adding_product_to_cart' |
   *        'on_payment' |
   *        'no_address_plank' |
   *        'profile',
   *        'no_delivery_modal',
   *         'no_address_reminder'
   */
  GEO_ADRESS_SCREEN: 'geo_adress_screen',

  /** срабатывает когда мы не доставляем по какому то адресу, только в карте,
   *  но не в модалках когда адрес выбирается из списка адресов
   *  city: 'Алматы' | 'Астана'
   *  street: string - адрес улицы и дома
   *  lat: координаты
   *  long: координаты
   *  isInCity: true/false - если юзер указал в/вне доставляемым городе
   * */
  NO_DELIVERY_TO_ADDRESS: 'no_delivery_to_address',

  /** когда юзер добавляет адрес
   *
   * address: {} - обект адреса
   * addressId: number - айди адреса
   * from: 'desktop_map' | 'mobile_map'
   */
  ADDED_ADDRESS: 'added_address',

  /** когда юзер редактирует адрес
   *
   * address: {} - обект адреса
   * addressId: number - айди адреса
   * from: 'desktop_map' | 'mobile_map'
   */
  EDITED_ADDRESS: 'edited_address',

  /** когда адрес доставки поменялся
   *
   * address: {} - обект адреса
   * addressId: number - айди адреса
   * from: 'user_selection' |
   *        'created_address' |
   *        'removed_address' |
   *        'edited_address' |
   *        'first_address_in_sync' |
   *        'first_address_in_nearest' |
   *        'first_address_in_catch' |
   *        'matched_address' |
   *        'user_id_added_address' |
   *        'nearest_address' |
   *        'main_address' |
   *        'sync_address_catch' |
   *        'no_addresses' |
   */
  DELIVERY_ADDRESS_CHANGED: 'delivery_address_changed',
};

const assortmentSurvey = {
  ASSORTMENT_SURVEY_BANNER_CLICK: 'assortment_survey_banner_click',
  ASSORTMENT_SURVEY_SCORE_PICK: 'assortment_survey_score_pick',
  ASSORTMENT_SURVEY_CLOSED: 'assortment_survey_closed',
  ASSORTMENT_SURVEY_SEND: 'assortment_survey_send',
};

export const firebaseEvents = {
  ...ProductReviewEvents,
  ...heavyOrderEvents,
  ...cashbackEvents,
  ...userAgreement,
  ...notifications,
  ...serviceFee,
  ...samplings,
  ...deeplinks,
  ...adults,
  ...questionnaire,
  ...address,
  ...assortmentSurvey,

  INIT: 'init',
  INIT_PAGE_LOAD_DONE: 'init_page_load_done',
  RECONNECT_TO_APP: 'reconnect_to_app',

  VIEWED_MAIN_PAGE: 'viewed_main_page',
  ENTERED_SEARCH: 'entered_search',
  VIEWED_SEARCH_RESULTS: 'viewed_search_results',
  VIEWED_PRODUCT: 'viewed_product',
  VIEWED_CART: 'viewed_cart',
  CHECKOUT: 'checkout',
  STARTED_PAYMENT: 'started_payment',
  VIEWED_ORDER: 'viewed_order',
  VIEWED_ORDERS: 'viewed_orders',
  CLOSED_APP: 'closed_app',
  FLUSHED_CART: 'flushed_cart',
  FLUSHED_PRODUCT: 'flushed_product',
  FILTERED_BY_BRANDS: 'filtered_by_brands',
  FILTERED_BY_SUBCATEGORIES: 'filtered_by_subcategories',
  SORTED_BY: 'sorted_by',
  VIEWED_REFERRAL_PROGRAM: 'viewed_referral_program',
  COPIED_REFERRAL_PROGRAM: 'copied_referral_program',
  VIEWED_SUBCATEGORY: 'viewed_subcategory',
  VIEWED_RECIPE: 'viewed_recipe',
  /* - - - - */
  HOME_1: 'home_1',
  HOME_2: 'home_2',
  ALGO_AGE_CONFIRMATION: 'algo_age_confirmation',
  ALGO_AGE_CHOICE: 'algo_age_choice',
  NEW_MAIN_ALGO_AGE_CHOICE: 'new_main_algo_age_choice',
  BACK_MAIN: 'back_main',
  BASKET_MAIN: 'basket_main',
  ORDERS_MAIN: 'orders_main',
  BACK_SEARCH: 'back_search',
  BACK_CATEGORY: 'back_category',
  FILTER_CATEGORY: 'filter_category',
  ALL_CATEGORY: 'all_category',
  BASKET_CATEGORY: 'basket_category',
  BASKET_PRODUCT: 'basket_product',
  PLUS_CATEGORY: 'plus_category',
  MINUS_CATEGORY: 'minus_category',
  TAB_SUBCAT_CATEGORY: 'tab_subcat_category',
  CLEAR_BASKET: 'clear_basket',
  BACK_BASKET: 'back_basket',
  PAYMENT_BASKET: 'payment_basket',
  PLUS_BASKET: 'plus_basket',
  MINUS_BASKET: 'minus_basket',
  BACK_PAYMENT: 'back_payment',
  DONE_PAYMENT: 'done_payment',
  LINK_CARD_PAYMENT: 'link_card_payment',
  BACK_STATUS: 'back_status',
  FOLLOW_STATUS: 'follow_status',
  PHONE_STATUS: 'phone_status',
  RANGE_CATEGORY_NO_FOOD: 'range_category_no_food',
  RANGE_CATEGORY_CLOSE: 'range_category_close',
  RANGE_CATEGORY_OK: 'range_category_ok',
  PROBLEM_NOWORK: 'problem_nowork',
  PROBLEM_TIME: 'problem_time',
  MAIN_BANNER_LOVE: 'main_banner_love',
  ORDER_LOVE_BANNER: 'order_love_banner',
  REC_ADD_TO_BASKET: 'rec_add_to_basket',
  REC_MINUS_PRODUCT: 'rec_minus_product',
  REC_PLUS_PRODUCT: 'rec_plus_product',
  MORE_MAIN_CATALOG_LOVE: 'more_main_catalog_love',
  MORE_BASKET_LOVE: 'more_basket_love',
  RECIPE_MAIN_BANNER: 'recipe_main_banner',
  NEW_MAIN_RECIPE_MAIN_BANNER: 'new_main_recipe_main_banner',
  RECIPE_PAGE: 'recipe_page',
  RECIPE_TAGS: 'recipe_tags',
  RECIPE_BACK: 'recipe_back',
  RECIPE_PLUS: 'recipe_plus',
  RECIPE_MINUS: 'recipe_minus',
  RECIPE_ADD_TO_BASKET: 'recipe_add_to_basket',
  RECIPE_CHANGE: 'recipe_change',
  RECIPE_ADD_ALL: 'recipe_add_all',
  RECIPE_CHANGE_PRODUCT: 'recipe_change_product',
  REF_SHARE: 'ref_share',
  NEW_MAIN_SEGMENTB: 'new_main_segmentb',
  BASKET_MAIN_SEARCH: 'basket_main_search',
  PLUS_MAIN_SEARCH: 'plus_main_search',
  MINUS_MAIN_SEARCH: 'minus_main_search',
  VIEWED_RECOMMENDATION: 'viewed_recommendation',
  // new feedback
  FEEDBACK_MODAL_STARTED: 'feedback_modal_started',
  // FEEDBACK_MODAL_STARTED:{
  //   type: 'Last_Unrated' || 'On_User_Active' || 'History_Buttons' || 'Deeplink',
  //   orderPaidAt: 'YYYY-MM-DD hh:mm:ss',
  //   modalShownAt: 'YYYY-MM-DD hh:mm:ss',
  //   orderId: '${id}',
  //   warehouseId: '${id}',

  //   isReviewShown: true || false,

  //   isTipsShown: true || false,
  //   fromTipsButton: true || false,
  // },
  FEEDBACK_MODAL_ENDED: 'feedback_modal_ended',
  // FEEDBACK_MODAL_ENDED: {
  //   type: 'Last_Unrated' || 'On_User_Active' || 'History_Buttons' || 'Deeplink' || 'Closed',
  //   orderId: '${id}',
  //   warehouseId: '${id}',

  //   isReviewShown: true || false,
  //   reviewDone: true || none
  //   tags: '${review tags}',
  //   rating: '${1-5}',

  //   isTipsShown: true || false,
  //   tipPrice: '${price of given tips}',
  //   courierId: '${id}',
  //   tipDone: true || none
  // },
  TIPS_PAYMENT_DONE: 'tips_payment_done',
  // TIPS_PAYMENT_DONE:{
  //   orderId: '${id}'
  // },

  // Order pages
  PROBLEM_ORDER: 'problem_order',
  REPEAT_ORDER: 'repeat_order',
  RECEIPT_IIN: 'receipt_iin',
  IIN_PAGE_ENTER: 'iin_page_enter',
  CANCEL_ORDER: 'cancel_order',
  DISCOUNT_PAYMENT: 'discount_payment',
  VIEWED_DISCOUNT: 'viewed_discount',

  PRODUCT_ADDED: 'product_added',
  PLUS_PRODUCT: 'plus_product',
  MINUS_PRODUCT: 'minus_product',

  SEARCH_CLICKED: 'search_clicked',

  CLICK_PAYOUT: 'click_payout',
  AB_TEST: 'ab_test',

  LOGIN_MSG: 'login_msg',
  GO_TO_GIFTS: 'go_to_gifts',

  PUSH_DEEPLINKS: 'push_deeplinks',
  CONTEXT_ADD_DEEPLINKS: 'context_add_deeplinks',

  GO_TO_CART: 'go_to_cart',

  SEARCH_MODE_TAB: 'search_mode_tab',
  BACK_TO_CHOCO: 'back_to_choco',

  CATALOG_BUTTON_CLICKED: 'catalog_button_clicked',
  VIEWED_MARATHON_PAGE: 'viewed_marathon_page',
  VIEWED_MARATHON_TABLE: 'viewed_marathon_table',

  VIEW_MAIN_BANNER_STAT: 'view_main_banner_stat',
  CLICK_MAIN_BANNER_STAT: 'click_main_banner_stat',
  VIEW_MAIN_BANNER_STAT_ALL: 'view_main_banner_stat_all',
  LANGUAGE_CHANGE: 'language_change',

  HELP_ORDER: 'help_order',

  FAVORITES_ADDED: 'favorites_added',
  MAIN_FAVORITES: 'main_favorites',
  ORDERS_HISTORY: 'orders_history',
  VIEWED_ORDERS_HISTORY: 'viewed_orders_history',

  INFO_BTN_CLICKED: 'info_btn_clicked',
  // INFO_BTN_CLICKED : {
  //  type: discount || high-demand
  // },

  ...paidOrderEvents,

  BANNER_CLICK: 'banner_click',
  STORIES_CLOSE: 'stories_close',
  STORIES_BUTTON_CLICK: 'stories_button_click',

  CROSS_EXIT_CONFIRM: 'cross_exit_confirm',
  CROSS_EXIT: 'cross_exit',

  /**
   * Отправляется если билд приложения
   * старше 2 недель
   */
  OUTDATED_BUILD: 'outdated_build',

  /**
   * Обнаружили старый билд, но версия нативки старая —
   * нет метода RahmetApp.reloadPage
   */
  OUTDATED_BUILD_NO_RELOAD: 'outdated_build_no_reload',

  /**
   * Клик по баннеру рефералки
   */
  REF_BANNER_CLICK: 'ref_banner_click',
  /**
   * Показ баннера рефералки
   */
  REF_BANNER_VIEWED: 'ref_banner_viewed',
  /**
   * Переход на страницу Поделиться
   */
  REF_PAGE_ENTER: 'ref_page_enter',
  /**
   * Клик по кнопке Поделиться
   */
  REF_PAGE_SHARE_CLICK: 'ref_page_share_click',
  /**
   * Клик по кнопке Скопировать
   */
  REF_PAGE_COPY_CLICK: 'ref_page_copy_click',
  /**
   * Переход на страницу Профиль
   */
  REF_PROFILE_PAGE_ENTER: 'ref_profile_page_enter',
  /**
   * Клик по пункту меню на странице Профиль
   */
  REF_PROFILE_PAGE_LINK_CLICK: 'ref_profile_page_link_click',
  /**
   * Клик по кнопке Применить у промокода на странице Промокоды
   */
  REF_PROMOCODE_PAGE_APPLY_PROMOCODE: 'ref_promocode_page_apply_promocode',
  /**
   * Клик по кнопке Условия у промокода на странице Промокоды
   */
  REF_PROMOCODE_PAGE_PROMOCODE_CONDITIONS:
    'ref_promocode_page_promocode_conditions',

  VIEWED_FAQ_PAGE: 'viewed_faq_page',
  INIT_AUTHORIZED_FLOW: 'init_authorized_flow',
  INIT_DEVICE_ID_FLOW: 'init_device_id_flow',

  DISTANT_CLICK_CONDITIONS_TIME: 'distant_click_conditions_time',

  /**
   * Нажатие на кнопку формы в модалке Добавить новый промокод
   * на странице профиля Мои промокоды.
   * promocode: string
   * error: string
   * type: string
   *  success | error | close
   *    success — сохранён
   *    error — ошибка
   *    close — оставили поле пустым и модалка закрылась без отправки на сервер
   */
  SUBMIT_ADD_PROMOCODE_MODAL: 'submit_add_promocode_modal',

  /**
   * Взаимодействие пользователя с промокодом в корзине
   * promocode: string
   * error: string
   * action: string
   *   success — подтвердил валидный промокод
   *   error — ошибка при применении введёного руками промокода
   */
  CART_PROMOCODE_USER_ACTION: 'cart_promocode_user_action',

  /**
   * Клик по кнопке Поделиться в сториз с новогодней статистикой
   */
  NY_STAT_2023_SHARE_BTN: 'ny_stat_2023_share_btn',

  /**
   * Клик по игрушке
   */
  NY_STAT_2023_TOY_BTN: 'ny_stat_2023_toy_btn',

  /**
   * Просмотр страницы конкурса
   * from: string
   *   main_page_banner | modal | stories
   */
  NY_CONTEST_2023_PAGE_VIEWED: 'ny_contest_2023_page_viewed',

  /**
   * Открытие модалки с условиями конкрурса
   * from: string
   *   main | cart
   */
  NY_CONTEST_2023_MODAL_OPENED: 'ny_contest_2023_modal_opened',

  CATEGORY_SELECTED: 'category_selected',

  SUBCATEGORY_SELECTED: 'subcategory_selected',

  CATEGORY_SORT_APPLY: 'category_sort_apply',

  AUTH_ERROR: 'auth_error',

  FEEDBACK_MODAL2: 'feedback_modal2',

  REPEAT_ORDER_CLICKED: 'repeat_order_button_clicked',

  REFUND_BANNER_CLICKED: 'refund_banner_clicked',

  CATALOG_OPENED: 'catalog_opened',
};

export const appsflyerEvents = {
  DOM_VIEWED_MAIN_PAGE: 'dom_viewed_main_page',
  DOM_NEW_MAIN_CATEGORY_MAIN: 'dom_new_main_category_main',
  DOM_PRODUCT_ADDED: 'dom_product_added',
  DOM_STARTED_PAYMENT: 'dom_started_payment',
  DOM_DONE_PAYMENT: 'dom_done_payment',
  DOM_FIRST_DONE_PAYMENT: 'dom_first_done_payment',
  DOM_VIEW_CONTENT: 'dom_view_content',
};

export const segmentEvents = {
  VIEW_MAIN_PAGE: 'View Main Page',
  CLICK_CATEGORY: 'Click Category',
  VIEW_PRODUCT_LIST: 'View Product List',
  FILTER_PRODUCT_LIST: 'Filter Product List',
  PRODUCT_ADDED: 'Product Added',
  CLICK_CART: 'Click Cart',
  CLICK_CHECKOUT: 'Click Checkout',
  CLICK_PAYOUT: 'Click Payout',
  AB_TEST: 'AB Test',
  RANGE_CATEGORY_NO_FOOD: 'Range Category No Food',
};
