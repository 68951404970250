import Vue from 'vue';

export const eventBus = new Vue();

export const BUS_EVENTS = {
  LOCALE_CHANGE: 'LOCALE_CHANGE',
  SHOW_NY_STAT: 'SHOW_NY_STAT',
  SHOW_STORIES_WATER: 'SHOW_STORIES_WATER',
  SHOW_STORIES_NAURYZ: 'SHOW_STORIES_NAURYZ',
  SHOW_STORIES_BAYAN: 'SHOW_STORIES_BAYAN',

  SHOW_STORIES_FRESH: 'SHOW_STORIES_FRESH',
  SHOW_STORIES_KAZMYASO: 'SHOW_STORIES_KAZMYASO',
  SHOW_STORIES_CROISSANT: 'SHOW_STORIES_CROISSANT',
};
