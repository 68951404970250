<template>
  <section class="MmWhatIs">
    <MmSubtitle id="whatis" class="MmWhatIs__title">
      <span v-html="$t('whatIs')" />
    </MmSubtitle>
    <p class="MmWhatIs__descr" v-html="$t('descr')" />
    <div class="MmWhatIs__note" v-html="$t('note')" />
    <MmSlider class="MmWhatIs__slider" />
  </section>
</template>

<script>
import MmSubtitle from '@/views/micromarket/components/MmSubtitle/MmSubtitle.vue';
import MmSlider from './MmSlider.vue';

export default {
  name: 'MmWhatIs',
  components: { MmSubtitle, MmSlider },
  inject: [],
  props: {},
  data: () => ({}),

  i18n: {
    messages: {
      kk: {
        whatIs: 'Микромаркет деген не?',
        descr: `
          Микромаркет — кеңсе немесе бизнес орталықтағы ақылды
          тоңазытқыш, ол жерде тағам, сусындар мен жүрек жалғарлық
          нәрселердің 160-тан астам ассортименті бар.
        `,
        note: `
          Олардың бірқатары мыналар:
        `,
      },
      ru: {
        whatIs: 'Что такое микромаркет?',
        descr: `
          Микромаркет&nbsp;— умный холодильник в&nbsp;вашем офисе
          или бизнес-центре с&nbsp;ассортиментом из&nbsp;160+&nbsp;позиций
          готовой еды, напитков и&nbsp;перекусов.
        `,
        note: `
          Вот некоторые из них:
        `,
      },
    },
  },
};
</script>

<style scoped>
.MmWhatIs {
  padding-top: 96px;
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.MmWhatIs__title {
}

.MmWhatIs__descr {
  margin-top: 16px;
  line-height: 24px;
}

.MmWhatIs__note {
  margin-top: 12px;
  color: #7b7a8c;
  margin-top: 16px;
  line-height: 24px;
}

.MmWhatIs__slider {
  padding-top: 8px;
}

@media (min-width: 768px) {
  .MmWhatIs {
    padding-top: 184px;
    max-width: 988px;
  }

  .MmWhatIs__descr {
    max-width: 636px;
    font-size: 18px;
    line-height: 28px;
    margin-top: 24px;
  }

  .MmWhatIs__note {
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
