<template>
  <div class="counter">
    <p class="counter-count">
      <slot></slot>
    </p>
    <p class="counter-text">
      {{ $t('count') }}
    </p>
  </div>
</template>

<script>
export default {
  i18n: {
    messages: {
      kk: {
        count: 'дана',
      },
      ru: {
        count: 'шт',
      },
    },
  },
};
</script>

<style scoped>
.counter {
  @apply flex flex-col items-center justify-center rounded-full bg-white;
  width: 48px;
  height: 46px;
}
.counter-count {
  @apply text-lg font-bold leading-6;
}
.counter-text {
  @apply -mt-1 text-xs font-medium leading-14 text-sirius-gray-700;
}
</style>
