<template>
  <div class="DPromocodeField">
    <SwitchRow :open="open" @change="$emit('onSwitch', $event)" />
    <InputRow
      v-if="open"
      :promocode="promocode"
      :not-applied-with-error-text="notAppliedWithErrorText"
      :applied-with-error-text="appliedWithErrorText"
      :applied-discount="appliedDiscount"
      :applied-free-delivery="appliedFreeDelivery"
      :free-delivery-not-applied="freeDeliveryNotApplied"
      @onInput="$emit('onInput', $event)"
      @onSubmit="$emit('onSubmit', $event)"
    />
  </div>
</template>

<script>
import SwitchRow from './SwitchRow.vue';
import InputRow from './InputRow.vue';

export default {
  name: 'DPromocodeField',
  components: {
    SwitchRow,
    InputRow,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    promocode: {
      type: String,
      default: '',
    },
    notAppliedWithErrorText: {
      type: String,
      default: '',
    },
    appliedWithErrorText: {
      type: String,
      default: '',
    },
    appliedDiscount: {
      type: Number,
      default: 0,
    },
    appliedFreeDelivery: {
      type: Boolean,
      default: false,
    },
    freeDeliveryNotApplied: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localPromocode: '',
  }),

  watch: {
    promocode(v) {
      this.localPromocode = v;
    },
  },
};
</script>

<style scoped>
.DPromocodeField {
}
</style>
