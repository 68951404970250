<template>
  <div class="FAQCategory faq-category">
    <div class="faq-category-title">
      <slot name="title"></slot>
    </div>
    <div class="faq-category-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQCategory',
};
</script>

<style scoped>
.faq-category {
  /* @apply text-lg font-bold leading-6; */
}
.faq-category-title {
  @apply text-lg font-bold leading-6 
    md:text-2xl md:leading-30;
}
.faq-category-content {
  @apply mt-1;
}
</style>
