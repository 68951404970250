<template>
  <div class="IphoneLottery">
    <div class="IphoneLottery__block">
      <NyPageSubtitle class="IphoneLottery__title">
        {{ $t('title') }}
      </NyPageSubtitle>

      <div class="IphoneLottery__text">
        <template v-if="$i18n.locale === 'kk'">
          <p class="IphoneLottery__p">
            Ұтыс ойнының жеңімпазы +7&nbsp;705&nbsp;****&nbsp;527 номерлі
            қолданушы
          </p>
          <p class="IphoneLottery__p">
            ТОП-50-ге кірмеген қатысушыларға Apple iPhone 15 Pro 128/8GB ұтыс
            ойыны 12 қаңтар Instagram (@ryadom.kz) парақшада өтетін тікелей
            эфирде ойнатылад.
          </p>
        </template>
        <template v-if="$i18n.locale === 'ru'">
          <p class="IphoneLottery__p">
            Розыгрыш супер-приза Apple&nbsp;iPhone&nbsp;15&nbsp;Pro&nbsp;128/8GB
            состоялся в&nbsp;прямом эфире в&nbsp;социальной сети Instagram
            (@ryadom.kz) 12–го января среди участников, набравших 50
            и&nbsp;более баллов.
          </p>
          <p class="IphoneLottery__p">
            Победитель розыгрыша пользователь с&nbsp;номером
            +7&nbsp;705&nbsp;****&nbsp;527
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import NyPageSubtitle from '@/views/ny-2023-contest/components/NyPageSubtitle/NyPageSubtitle.vue';

export default {
  name: 'IphoneLottery',
  components: {
    NyPageSubtitle,
  },
  i18n: {
    messages: {
      kk: {
        title: 'iPhone 15 ұтыс ойнының нәтижелері',
      },
      ru: {
        title: 'Результаты розыгрыша iPhone 15',
      },
    },
  },
};
</script>

<style scoped>
.IphoneLottery {
  padding-top: 24px;
  width: calc(100% - 24px);
  margin: auto;
}

.IphoneLottery__block {
  padding: 32px 24px 48px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 1px 8px 24px 0px rgba(110, 83, 52, 0.2);
  display: grid;
  grid-template-columns: auto;
  grid-gap: 16px;
}

.IphoneLottery__p + .IphoneLottery__p {
  padding-top: 16px;
}

@media screen and (min-width: 768px) {
  .IphoneLottery {
    max-width: 1188px;
    padding-top: 128px;
  }

  .IphoneLottery__block {
    position: relative;
    padding: 72px 100px;
    border-radius: 64px;
    grid-template-columns: 400px 1fr;
    grid-gap: 114px;
  }

  .IphoneLottery__text {
    font-size: 18px;
    line-height: 24px;
  }
}
</style>
