<template>
  <div class="BoxItem box-item">
    <UiInlineIcon class="box-item-icon" :name="item.icon" />
    <p class="box-item-title">{{ item.title }}</p>
    <p class="box-item-sub">{{ item.sub }}</p>
    <UiInlineIcon class="box-item-arrow" name="support-arrow" />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'BoxItem',
  components: { UiInlineIcon },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.box-item {
  @apply w-full flex items-center cursor-pointer;
}

.box-item-title {
  @apply ml-3 font-medium leading-5;
}

.box-item-sub {
  @apply ml-2 text-sirius-gray-900 text-sm leading-18;
}
.box-item-arrow {
  @apply ml-auto pl-3;
}
</style>
