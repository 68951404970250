<template>
  <div class="h-full bg-white font-sirius leading-tight">
    <TestSetupComponent v-if="!1" />

    <template v-if="loading || appLoader">
      <LoaderAnimation v-if="$isWebView" />
      <DesktopAdaptiveLoader v-else />
    </template>

    <router-view v-else />

    <ProductDetails />

    <template v-if="!loading && !appLoader">
      <!-- Active Orders -->
      <MiniOrderStatus v-if="!$isDesktop" />
      <CustomerReviews />
      <!-- Active Orders -->

      <!-- Error Modals -->
      <NoDeliveryModal />
      <NoCorrectAddress />
      <NoActiveWarehouse />
      <!-- Error Modals -->

      <!-- Support Modal  -->
      <SupportPopup />
      <!-- Support Modal  -->

      <!-- Aduls Warning popup -->
      <AdultsWarningModalWrapper />
      <!-- Aduls Warning popup -->

      <UiFullScreenLoader v-if="isPromocodeLoading || isAddressLoading" />

      <SamplingModalWrapper />

      <!-- PaidDelivery -->
      <PaidDeliveryModalWrapper />
      <!-- PaidDelivery -->

      <!-- Address -->
      <AddressRemoveConfirmationModalWrapper />
      <AddressEditOptionsModal />
      <AddressList />
      <AddressMapDesk v-if="$isDesktop" />
      <!-- Address -->

      <ApplianceReminderModalWrapper />

      <SupportCenterWrapper />
      <!-- heavy-order -->
      <FullCartPopup />
      <!-- heavy-order -->

      <BadWeatherModal v-if="false" />

      <AddedExtendedDeliveryModal />

      <UserAgreementReminderModal v-if="!$isWebView" />

      <LostGoodsModalContainer />
    </template>

    <CategoryNpsContainer />

    <template v-if="hasConsoleBtns">
      <UiButton
        size="sm"
        class="fixed z-overlay -right-8 px-3"
        style="transform: rotateZ(-90deg); bottom: calc(200px + 76px)"
        @click="onLoggerTap"
      >
        Console
      </UiButton>
    </template>
    <Logger />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

import { RahmetApp } from '@shared/RahmetApp';
import { UserService } from '@services/user';
import { SurgeService } from '@services/surge';
import { OrderService } from '@services/orders';
import { ABTestService } from '@services/abtest';
import { DevConfigs } from '@/utils/dev-config';
import { AuthService } from '@shared/services/auth';
import { SocketService } from '@shared/services/ws';
// import { CashbackService } from '@services/cashback';
import { ClientService } from '@shared/services/client';
import { HighPriceService } from '@services/high-price';
import { PromocodesService } from '@services/promocodes';
import { ERR_NO_DELIVERY } from '@shared/config/delivery';
// import { NewProductsService } from '@services/new-products';
import { NotificationService } from '@services/notifications';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { QuestionnaireService } from '@services/questionnaire';
import { OutdatedBuildWatcherService } from '@services/outdated-build-watcher';
import { CategoryNpsService } from '@services/category-nps';

import ProductDetails from '@shared/components/product/ProductDetails.vue';
import Logger from '@shared/components/logger/Logger.vue';
import DesktopAdaptiveLoader from './components/DesktopAdaptiveLoader.vue';
import LoaderAnimation from './components/LoaderAnimation.vue';

export default {
  name: 'App',
  components: {
    FullCartPopup: () => import('@components/heavy-order/FullCartPopup.vue'),
    AddressList: () => import('@shared/components/address/AddressList.vue'),
    UiButton: () => import('@pure-ui/components/UiButton/UiButton.vue'),
    UiFullScreenLoader: () => import('@ui/UiFullScreenLoader.vue'),
    AddressRemoveConfirmationModalWrapper: () =>
      import('@/components/address/AddressRemoveConfirmationModalWrapper.vue'),
    CategoryNpsContainer: () =>
      import(
        '@components/category-nps/CategoryNpsModalContainer/CategoryNpsModalContainer.vue'
      ),
    AddedExtendedDeliveryModal: () =>
      import('@components/extended-delivery/AddedExtendedDeliveryModal.vue'),
    UserAgreementReminderModal: () =>
      import('@components/user-agreement/UserAgreementReminderModal.vue'),
    ApplianceReminderModalWrapper: () =>
      import('@components/appliance/ApplianceReminderModalWrapper.vue'),
    TestSetupComponent: () =>
      import('@/components/TestSetupComponent/TestSetupComponent.vue'),
    SupportPopup: () =>
      import('@shared/components/support/components/SupportPopup.vue'),
    PaidDeliveryModalWrapper: () =>
      import('@components/paid-delivery/PaidDeliveryModalWrapper.vue'),
    LostGoodsModalContainer: () =>
      import('@components/lost-goods/LostGoodsModalContainer.vue'),
    BadWeatherModal: () =>
      import('@components/bad-weather-modal/BadWeatherModal.vue'),
    AddressEditOptionsModal: () =>
      import('@/components/address/AddressEditOptionsModal.vue'),
    NoActiveWarehouse: () =>
      import('@shared/components/delivery/NoActiveWarehouse.vue'),
    AdultsWarningModalWrapper: () =>
      import('@components/adults/AdultsWarningModalWrapper.vue'),
    NoCorrectAddress: () =>
      import('@shared/components/delivery/NoCorrectAddress.vue'),
    NoDeliveryModal: () =>
      import('@shared/components/delivery/NoDeliveryModal.vue'),
    CustomerReviews: () =>
      import('@shared/components/feedback/CustomerReviews.vue'),
    SamplingModalWrapper: () =>
      import('@components/samplings/SamplingModalWrapper.vue'),
    AddressMapDesk: () =>
      import('@shared/components/address/AddressMapDesk.vue'),
    MiniOrderStatus: () =>
      import('@/views/order/components/MiniOrderStatus.vue'),
    SupportCenterWrapper: () =>
      import('@components/support/SupportCenterWrapper.vue'),
    DesktopAdaptiveLoader,
    LoaderAnimation,
    ProductDetails,
    Logger,
  },
  inject: ['toast', 'bottomSheet', 'popup', 'bottomSheetPopup'],

  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters('user', ['userProfile', 'isAddressLoading', 'isDeveloper']),
    ...mapGetters('promocodes', ['isPromocodeLoading']),
    ...mapGetters('logger', ['isButtonShown']),
    ...mapGetters(['appLoader']),
    hasConsoleBtns() {
      return (
        DevConfigs.isStage ||
        DevConfigs.isDev ||
        this.isDeveloper ||
        this.isButtonShown
      );
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.setIsWebView(RahmetApp.isWebView());
    PromocodesService.init();
    OutdatedBuildWatcherService.init();
    this.init();
    OrderService.init();
    SocketService.init();
    SurgeService.init();
    ABTestService.init();
    HighPriceService.init();
    QuestionnaireService.init();
    // NewProductsService.init();
    // CashbackService.init();

    window.addEventListener('beforeunload', this.beforeWindowUnload);
    this.onResize();
    window.addEventListener('resize', this.onResize);

    NotificationService.init();
  },
  methods: {
    ...mapMutations({
      setIsWebView: 'SET_IS_WEB_VIEW',
    }),
    ...mapMutations('logger', {
      openLogger: 'OPEN_LOGGER',
    }),
    ...mapActions('catalog', {
      fetchMainCategories: 'FETCH_MAIN_CATEGORIES',
    }),
    init() {
      // если перешли на url, доступный только с авторизацией,
      // авторизация уже запущена в src/router/auth-guard.js,
      // нет смысла запускать её заново
      if (this.$store.getters['appLoader']) {
        this.loading = false;
        return;
      }

      const { state } = this.$route.query;
      let { track_id: trackId, code } = this.$route.query;

      if (trackId && trackId.length > 36) {
        trackId = undefined;
        code = this.$route.query.track_id;
      }

      this.$store.dispatch('cart/INIT_LOCAL_CART');

      const authPayload = {
        trackId,
        state,
        code,
        isInit: true,
      };
      this.$log('AppVue Auth');

      return AuthService.authorize(authPayload)
        .then(res => {
          if (!this.$isWebView && code) {
            Analitycs.logEcosystemAuthByCode();
            this.$log('Analitycs.logEcosystemAuthByCode');
          }
          this.$log('AppVue Auth res', res);
          return this.authorizedFlow(state).then(() => {
            this.$eventBus.emit('eb_init_page_load_done');
          });
        })
        .catch(err => {
          this.$log('AppVue Auth err', err);
          console.error('AppVue Auth err', err);
          UserService.loadUnauthorizedProfile();
          return this.initDeviceIdFlow().then(() => {
            this.$eventBus.emit('eb_init_page_load_done');
          });
        });
    },
    initDeviceIdFlow() {
      const { deviceId } = ClientService.getContext();
      this.$log('initDeviceIdFlow', { deviceId });

      if (deviceId !== undefined) {
        return this.$store
          .dispatch('INIT_DEVICE_ID_FLOW')
          .then(() => {
            Analitycs.logEvent(EVENTS.INIT_DEVICE_ID_FLOW, {
              addresses: this.$store.state.user.addresses,
            });
            return Promise.resolve();
          })
          .catch(err => {
            console.error(err);
            Analitycs.logEvent(EVENTS.INIT_DEVICE_ID_FLOW, {
              error: {
                message: err.message,
                stack: err.stack,
              },
            });
            if (
              err.message !== 'Не удалось определить адрес доставки' &&
              err.message !== 'Не удалось определить зону доставки' &&
              err.message !== ERR_NO_DELIVERY
            ) {
              this.toast.show(err.message, 'error');
            }
            if (this.$route.name === 'auth') {
              this.$router.push({ name: 'main' });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
      this.loading = false;
      return Promise.resolve();
    },
    authorizedFlow(state = '') {
      this.$log('authorizedFlow', { state });

      return this.$store
        .dispatch('INIT_AUTHORIZED_FLOW')
        .then(() => {
          this.loading = false;

          if (this.$route.name !== 'main') {
            this.fetchMainCategories();
          }

          const route = state ? AuthService.parseOauthStateRoute(state) : null;
          if (route) this.$router.push(route);
        })
        .then(() => {
          return Promise.all([
            this.$store.dispatch('favorites/FETCH_FAVORITES_IDS'),
            CategoryNpsService.loadSurveys(),
          ]);
        })
        .then(() => {
          Analitycs.logEvent(EVENTS.INIT_AUTHORIZED_FLOW, {
            addresses: this.$store.state.user.addresses,
          });
          return Promise.resolve();
        })
        .catch(err => {
          Analitycs.logEvent(EVENTS.INIT_AUTHORIZED_FLOW, {
            error: {
              message: err.message,
              stack: err.stack,
            },
          });
          this.$log('authorizedFlow err', err);
          this.initDeviceIdFlow();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    retryAuth() {
      // по какой-то причине на ios, если перейти по пушу в то время,
      // как приложение было свёрнуто, то происходит странное:
      // страница загружается с самого начала, но нативка дополнительно
      // вызывает "didBecomeActive", что кажется нелогичным
      if (this.$store.getters['appLoader']) {
        this.loading = false;
        return;
      }
      return AuthService.authorize({ isRefresh: true })
        .then(() => this.authorizedFlow('', false))
        .catch(() => (this.loading = false));
    },
    beforeWindowUnload() {
      Analitycs.logEvent(EVENTS.CLOSED_APP);
    },
    onLoggerTap() {
      this.openLogger();
    },
    onResize() {
      this.$store.commit('SET_IS_DESKTOP', window.innerWidth > 768);
    },
  },
  eventBusEvents: {
    eb_on_reconnect_to_app() {
      this.$log('retryAuth', null, 'EventBus');
      this.retryAuth();
    },
    eb_open_address_create_container(data) {
      let id = data && data.id ? data.id.toString() : 'create';
      let from = data.from;

      Analitycs.logEvent(EVENTS.GEO_ADRESS_SCREEN, {
        from,
        type: id === 'create' ? 'creating' : 'editing',
      });

      if (this.$isDesktop) {
        this.$eventBus.emit('eb_open_address_desk_container', { id, from });
      } else {
        this.$router.push({ name: 'address', params: { id, from } });
      }
    },
    eb_on_address_update() {
      this.fetchMainCategories();
    },
    eb_on_address_change() {
      this.fetchMainCategories();
    },
  },
};
</script>

<style lang="postcss">
.desk {
  @apply hidden md:block;
}
.mob {
  @apply block md:hidden;
}
</style>
