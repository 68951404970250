import { client } from '../../clients';

export default function () {
  const params = {
    useFingerprint: true,
    useIdempotency: true,
    headers: {
      'Content-Type': 'application/json',
    },
    paramsSerializer: v => JSON.stringify(v),
    transformRequest: v => JSON.stringify(v),
  };
  return client
    .post('/api/v1/oauth2/tracks', null, params)
    .then(({ data }) => data);
}
