import { AddressService } from '@services/address';
import { CityService } from '@services/city';
import { MapService } from '@services/map';
import { mapGetters } from 'vuex';
import { rFetchAddressById } from '@shared/services/api/apis';

import AddedExtendedDeliveryModal from '@components/extended-delivery/AddedExtendedDeliveryModal.vue';

export default {
  inject: ['toast'],
  data() {
    return {
      inputCoords: [],
      state: {
        flat_number: '',
        entrance: '',
        floor: '',
        comment: '',
        domofon_code: '',
        street: '',
        is_private_home: false,
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),
    ...mapGetters('user', ['addressCount', 'addresses']),
  },
  methods: {
    checkIsSavedAddress(address, addresses, isPrivate = false) {
      if (!addresses || addresses.length === 0) return null;

      const toString = x => (x !== null && x !== undefined ? x.toString() : '');

      const sameAddresses = [...addresses].filter(e => {
        if (isPrivate) {
          return (
            toString(address.street) === toString(e.street) &&
            e.is_private_home === true
          );
        }
        return (
          toString(address.street) === toString(e.street) &&
          e.is_private_home === false &&
          toString(address.flat_number) === toString(e.flat_number) &&
          toString(address.entrance) === toString(e.entrance) &&
          toString(address.floor) === toString(e.floor)
        );
      });
      return sameAddresses && sameAddresses.length > 0
        ? sameAddresses[0]
        : null;
    },
    submit(form, GeoObject, id, isExtended = false, from) {
      if (!GeoObject) {
        this.toast.show(this.$t('addressNotSelected'), 'error');
        return;
      }

      let parsed = MapService.parseGeoObject(GeoObject);
      let cityId = CityService.findCityIdByName(parsed.cityName);
      let name = (form.name || this.$t('address')).toString().slice(0, 30);

      let payload = {
        ...form,
        ...parsed,
        name,
        city_id: cityId,
        domofon_code: form.doorbell || form.domofon_code,
      };
      payload.is_private_home = payload.is_private_home ? 1 : 0;
      const savedAddress = this.checkIsSavedAddress(
        payload,
        this.addresses,
        !!form.is_private_home
      );

      if (savedAddress) {
        return Promise.resolve({ savedAddress });
      }

      let request = Number(id)
        ? AddressService.updateAddress
        : AddressService.createAddress;
      if (Number(id)) payload.id = id;

      return request(payload, from)
        .then(res => {
          console.log('AddressService request', id, res);
          let msg = Number(id) ? 'addressUpdated' : 'addressCreated';
          this.toast.show(this.$t(msg), 'success');
          if (isExtended) AddedExtendedDeliveryModal.show();
        })
        .catch(err => {
          this.toast.show(err.message, 'error');
        });
    },
    resetState() {
      this.state = {
        flat_number: '',
        entrance: '',
        floor: '',
        comment: '',
        domofon_code: '',
        street: '',
        is_private_home: false,
        name: '',
      };
    },
    initState(address) {
      this.state = {
        flat_number: address.flat_number ?? '',
        entrance: address.entrance ?? '',
        floor: address.floor ?? '',
        comment: address.comment ?? '',
        domofon_code: address.domofon_code ?? '',
        street: address.street ?? '',
        is_private_home: address.is_private_home || false,
        name: address.name ?? '',
      };
      this.inputCoords = [address.lat, address.long];
    },
    onEdit(id) {
      rFetchAddressById(id, this.isAuthorized)
        .then(data => this.initState(data.data))
        .catch(err => this.toast.show(err.message, 'error'));
    },
    // accessable from outside
    init(id = 'create') {
      if (this.addressCount === 0) {
        this.toast.show('Укажите адрес доставки', 'info');
      }

      if (id === 'create') this.resetState();
      if (Number(id)) this.onEdit(id);
    },
  },
  i18n: {
    messages: {
      ru: {
        address: 'Адрес',
        addressCreated: 'Адрес добавлен',
        addressUpdated: 'Изменения сохранены',
        selectAddress: 'Укажите адрес доставки',
        addressNotSelected: 'Адрес не указан',
      },
      kk: {
        address: 'Мекен-жай',
        addressCreated: 'Мекенжай қосылды',
        addressUpdated: 'Өзгерістер сақталды',
        selectAddress: 'Мекенжайды таңдаңыз',
        addressNotSelected: 'Мекенжай таңдалмаған',
      },
    },
  },
  render() {
    return this.$scopedSlots.default({
      submit: this.submit,
      state: this.state,
      inputCoords: this.inputCoords,
    });
  },
};
