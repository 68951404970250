export const OrderProblemsModule = {
  namespaced: true,
  state: {
    products: {},
    features: {},
    items: [],
    feedbackPayload: null,
    tipsPayload: null,
    submitPromise: null,
    feedbackState: null,
  },
  getters: {
    getFeatures(state) {
      return state.features;
    },
    items(state) {
      return state.items;
    },
  },
  mutations: {
    SET_PRODUCTS(state, value) {
      state.products = value;
    },
    SET_FEATURES(state, value) {
      state.features = value;
    },
    SET_ITEMS(state, value) {
      state.items = value;
    },
    SET_FEEDBACK_PAYLOAD(state, value) {
      state.feedbackPayload = value;
    },
    SET_TIPS_PAYLOAD(state, value) {
      state.tipsPayload = value;
    },
    SET_SUBMIT_PROMISE(state, value) {
      state.submitPromise = value;
    },
    SET_FEEDBACK_STATE(state, value) {
      state.feedbackState = value;
    },
    RESET_FEEDBACK_STATE(state) {
      state.feedbackPayload = null;
      state.tipsPayload = null;
      state.submitPromise = null;
      state.feedbackState = null;
    },
  },
  actions: {},
};
