<template>
  <div
    class="w-full flex flex-col p-5 bg-gray-600 rounded-lg bg-cover bg-no-repeat"
    :style="`background-image: url(${recipe.icon_main})`"
    @click="onClick"
  >
    <h3 class="text-white text-xl font-bold leading-tight">
      {{ recipe.name }}
    </h3>
    <p class="text-white text-sm">
      {{ recipe.ingredient_quantity }} продуктов / ~{{ recipe.cook_time }} мин.
    </p>
  </div>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'RecipeCard',
  props: {
    recipe: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClick() {
      Analitycs.logEvent(EVENTS.RECIPE_PAGE, { recipe_id: this.recipe.id });
      this.$router.push({
        name: 'recipe-full',
        params: { id: this.recipe.id },
      });
    },
  },
};
</script>
