<template>
  <MainLayout :with-footer="false">
    <template v-if="$isWebView" #header>
      <ToolBar> {{ $t('title') }} </ToolBar>
    </template>
    <ProfileLayout :container-width="788" :has-support="!$isDesktop">
      <div class="docs-view" :class="{ 'mt-2': $isWebView }">
        <p v-if="$isDesktop" class="docs-title">{{ $t('title') }}</p>
        <div
          v-for="item in routes"
          :key="item.name"
          class="docs-link"
          :class="{ '--border': !$isDesktop }"
          @click="onClick(item)"
        >
          {{ $t(item.title) }}

          <UiInlineIcon name="docs-arrow" class="docs-arrow" />
        </div>
      </div>

      <CompanyPassportPopup />
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import {
  PUBLIC_OFFER_LINKS,
  DELIVERY_POLICY_LINKS,
} from '@shared/config/public-offer-links';
import { RahmetApp } from '@shared/RahmetApp';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import CompanyPassportPopup from '@layout/CompanyPassportPopup.vue';
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@layout/ToolBar.vue';

export default {
  name: 'FaqView',
  components: {
    CompanyPassportPopup,
    UiInlineIcon,
    ProfileLayout,
    MainLayout,
    ToolBar,
  },
  inject: ['popup'],
  data() {
    return {
      routes: [
        {
          name: 'policy',
          title: 'policy',
        },
        {
          name: 'deliveryPolicy',
          title: 'deliveryPolicy',
        },
        {
          name: 'pasports',
          title: 'pasports',
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      if (this.$isWebView) {
        RahmetApp.openLink({ url: link });
      } else window.open(link, '_blank');
    },
    onClick(item) {
      if (item.name === 'policy') {
        this.openLink(PUBLIC_OFFER_LINKS[this.$i18n.locale]);
      } else if (item.name === 'deliveryPolicy') {
        this.openLink(DELIVERY_POLICY_LINKS[this.$i18n.locale]);
      } else if (item.name === 'pasports') {
        this.popup.show('companyPassport');
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Правовые документы',
        policy: 'Пользовательское соглашение',
        deliveryPolicy: 'Политика возвратов и доставки',
        pasports: 'Паспорт предприятия',
      },
      kk: {
        title: 'Құжаттар',
        policy: 'Пайдаланушы келісімі',
        deliveryPolicy: 'Жеткізу және қайтару ережелері',
        pasports: 'Мекеме құжаттары',
      },
    },
  },
};
</script>

<style scoped>
.docs-view {
  @apply px-4;
}
.docs-title {
  @apply mb-6 md:mb-9 text-4xl font-bold;
  line-height: 44px;
}
.docs-link {
  @apply mb-1 py-3 first-of-type:pt-0
    md:mb-6 md:py-0 
    flex items-center justify-between cursor-pointer
    md:text-lg md:font-medium md:leading-6
    md:text-sirius-orange-100 hover:md:underline;
}
.docs-link.--border {
  @apply border-b-2 border-sirius-gray-200;
}
.docs-arrow {
  @apply md:hidden;
}
</style>
