<template>
  <transition name="popup-fade" @after-enter="onOpen" @after-leave="onClose">
    <div
      v-if="show"
      class="popup-backdrop"
      @click.stop
      @click.self="closeModal"
    >
      <div role="dialog" class="popup-dialog">
        <template v-if="closable">
          <img
            class="desk z-10 absolute w-10 h-10 top-6 right-6 cursor-pointer"
            :src="ImgDeskCloseBtn"
            alt="close"
            @click="closeModal"
          />
          <img
            class="mob z-10 absolute w-6 h-6 top-3 right-3 cursor-pointer"
            :src="ImgMobCloseBtn"
            alt="close"
            @click="closeModal"
          />
        </template>

        <div class="text-base md:text-2xl font-bold">
          <slot name="title"> </slot>
        </div>
        <div class="popup-dialog-content desk-scrollbar">
          <slot></slot>
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import { isElement } from '@/utils/functions/is-node';

export default {
  name: 'UiPopupV2',
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    size: {
      type: String,
      default: 'md',
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ImgDeskCloseBtn: new URL(
        '@img/icons/mob-close-modal.svg',
        import.meta.url
      ).href,
      ImgMobCloseBtn: new URL(
        '@img/icons/desk-close-modal.svg',
        import.meta.url
      ).href,
      sizes: {
        xs: '--xs',
        sm: '--sm',
        md: '--md',
        lg: '--lg',
        xl: '--xl',
        'full-page': '--full-page',
      },
    };
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  beforeDestroy() {
    if (this.$el && isElement(this.$el)) document.body.removeChild(this.$el);
  },
  methods: {
    closeModal() {
      this.$emit('change', false);
    },
    onCloseFromMobile() {
      this.closeModal();
      return true;
    },
    onClose() {
      this.$emit('onClose');
      RahmetApp.onBackPressed(() => false);
    },
    onOpen() {
      RahmetApp.onBackPressed(this.onCloseFromMobile);
      this.$emit('onOpen');
    },
  },
};
</script>

<style lang="postcss" scoped>
.popup-backdrop {
  @apply fixed inset-0 flex items-center justify-center bg-overlay z-modal;
}
.popup-dialog {
  @apply mx-5 px-4 pt-4 pb-6 md:px-8 md:pt-8 md:pb-12 relative z-modal bg-white rounded-2xl md:rounded-4xl;
  max-width: 100%;
  width: var(--popup-width, 100%);
  margin-bottom: var(--popup-offset);

  box-shadow:
    0px 92px 92px rgba(0, 0, 0, 0.05),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);

  @media (min-width: 768px) {
    width: var(--popup-width-d, 100%);
  }
}
.popup-fade {
  &-enter,
  &-leave-to {
    @apply opacity-0;
  }

  &-enter-active {
    @apply ease-in duration-200;
  }

  &-leave-active {
    @apply ease-in duration-200 delay-75;
  }

  &-enter-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 100ms,
      transform ease-in-out 75ms 100ms;
  }

  &-leave-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 50ms,
      transform ease-in-out 100ms;
  }

  &-enter > .popup-dialog,
  &-leave-to > .popup-dialog {
    @apply opacity-0 transform scale-75;
  }
}
</style>
