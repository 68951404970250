export const OrdersModule = {
  namespaced: true,
  state: {
    activeOrders: [],
  },
  getters: {
    activeOrders(state) {
      return state.activeOrders;
    },
  },
  mutations: {
    SET_ACTIVE_ORDERS(state, value) {
      state.activeOrders = value;
    },
  },
};
