<template>
  <transition name="fade">
    <div v-if="show" class="ShowCartBtn show-cart-btn">
      <UiInlineIcon class="mr-2" name="catalog-show-cart" />{{ $t('cart') }}
    </div>
  </transition>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'ShowCartBtn',
  components: { UiInlineIcon },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      ru: {
        cart: 'корзина',
      },
      kk: {
        cart: 'себет',
      },
    },
  },
};
</script>

<style>
.show-cart-btn {
  @apply w-full absolute right-0 
    flex justify-end items-center 
    font-medium text-sirius-orange-100 
    duration-300 ease-in cursor-pointer;
}
</style>
