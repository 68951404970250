<template>
  <transition name="slide-up">
    <button
      v-show="upButton"
      v-scroll="{ onScrolled: handleScroll }"
      v-floating-element="'up-button'"
      class="up-button"
      @click="goUp"
    >
      <IconUpButton class="up-button-icon" />
    </button>
  </transition>
</template>

<script>
import IconUpButton from '@shared/components/icons/IconUpButton.vue';
import Scroll from '@/utils/directives/scroll';

export default {
  name: 'UpButton',
  components: {
    IconUpButton,
  },
  directives: {
    Scroll,
  },
  data: () => ({
    upButton: false,
  }),
  methods: {
    goUp() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top < 150) {
        this.upButton = false;
      } else {
        this.upButton = true;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.up-button {
  @apply fixed z-backdrop right-4 rounded-full 
  duration-300  
  bg-white md:hover:bg-sirius-orange-100 
  text-sirius-black-100 md:hover:text-white 
  flex items-center justify-center 
  focus:outline-none;

  width: 52px;
  height: 52px;
  top: 100vh;
  box-shadow:
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px 92px 92px rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    width: 88px;
    height: 88px;
    bottom: 45px;
    top: auto;
  }
}
.up-button-icon {
  @apply flex items-center justify-center
  w-8 h-8 md:w-14 md:h-14;
}
</style>
