<template>
  <div class="WelcomeModal">
    <UiBottomSheetWrapper
      :show="show"
      with-back-button
      @change="$emit('change', $event)"
    >
      <div class="referral-welcome__decor">
        <img src="@img/referral/welcome.png" alt="" />
      </div>
      <div class="referral-welcome__wrapper">
        <p class="referral-welcome__msg">
          <span v-html="$t('msg')" />
        </p>
        <p class="referral-welcome__warning">
          <span v-html="$t('warning')" />
        </p>
        <UiButton
          class="referral-welcome__btn-ok"
          size="lg"
          @click="$emit('change', false)"
        >
          {{ $t('btn') }}
        </UiButton>
      </div>
    </UiBottomSheetWrapper>
  </div>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

/**
 * Модалка показывается всем пользователям,
 * перешедшим по реферальной ссылке
 */
export default {
  name: 'WelcomeModal',
  components: {
    UiBottomSheetWrapper,
    UiButton,
  },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      ru: {
        msg: `
          Чтобы&nbsp;получить скидку по&nbsp;реферальной программе,
          добавьте товары и&nbsp;введите промокод друга в&nbsp;корзине.
        `,
        warning: `
          Промокод действует только<br>
          на&nbsp;первый заказ.
        `,
        btn: 'Всё понятно!',
      },
      kk: {
        msg: `
          Жолдама бағдарламасы бойынша жеңілдік алу үшін
          себетке досыңыздың промокодын енгізіңіз.
        `,
        warning: `
          Промокод тек бірінші тапсырысқа жарамды.
        `,
        btn: 'Түсінікті!',
      },
    },
  },
};
</script>

<style scoped>
.referral-welcome__decor {
  padding-top: calc(200 / 375 * 100%);
  position: relative;
  z-index: 5;
}

.referral-welcome__decor img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.referral-welcome__msg {
  padding-top: 20px;
  line-height: 18px;
  letter-spacing: 0.005em;
}

.referral-welcome__warning {
  margin-top: 16px;
  line-height: 18px;
  font-weight: 500;
  padding-left: 36px;
  letter-spacing: 0.005em;
  position: relative;
}
.referral-welcome__warning::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  background: url('@img/warning.svg');
}

.referral-welcome__btn-ok {
  font-size: 18px;
  font-weight: 900;
  width: 100%;
  margin-top: 36px;
}
</style>
