<template>
  <div class="collapse-wrapper" :class="{ open: open }">
    <div class="collapse-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiCollapseAnimation',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.collapse-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.3s ease;
}
.collapse-wrapper.open {
  grid-template-rows: 1fr;
}
.collapse-content {
  min-height: 0;
}
</style>
