export const RATE_TAGS = [
  {
    text: 'tags.assortment',
    code: 'range',
    isPositive: [1, 2, 3, 4, 5],
  },
  {
    text: 'tags.deliverySpeed',
    code: 'delivery_speed',
    isPositive: [4, 5],
  },
  {
    text: 'tags.deliveredNotOnTime',
    code: 'not_on_time',
    isPositive: [1, 2, 3],
  },
  {
    text: 'tags.courierWork',
    code: 'courier_work',
    isPositive: [1, 2, 3, 4, 5],
  },
  {
    text: 'tags.service',
    code: 'service',
    isPositive: [1, 2, 3, 4, 5],
  },
  {
    text: 'tags.somethingWrong',
    code: 'order_problem',
    isPositive: [1, 2, 3],
  },
];

export const RATE_POINTS = [
  {
    img: new URL('@/assets/images/feedback/point-1.png', import.meta.url).href,
    text: 'Ужасно',
    rate: 1,
  },
  {
    img: new URL('@/assets/images/feedback/point-2.png', import.meta.url).href,
    text: 'Плохо',
    rate: 2,
  },
  {
    img: new URL('@/assets/images/feedback/point-3.png', import.meta.url).href,
    text: 'Средне',
    rate: 3,
  },
  {
    img: new URL('@/assets/images/feedback/point-4.png', import.meta.url).href,
    text: 'Хорошо',
    rate: 4,
  },
  {
    img: new URL('@/assets/images/feedback/point-5.png', import.meta.url).href,
    text: 'Классно!',
    rate: 5,
  },
];

export const TIPS = {
  GROUP_CONTROL_V2: {
    FIRST: {
      messages: [
        'reactions.suchPleasure',
        'reactions.thanksFire',
        'reactions.thanksCool',
      ],
      text: '200 ₸',
      price: 200,
      type: 'FIRST',
    },
    SECOND: {
      messages: [
        'reactions.yayThanks',
        'reactions.whatPleasants',
        'reactions.veryPleasant',
      ],
      text: '400 ₸',
      price: 400,
      type: 'SECOND',
    },
    THIRD: {
      messages: ['reactions.omg', 'reactions.madeMyDay', 'reactions.plus100'],
      text: '500 ₸',
      price: 500,
      type: 'THIRD',
    },
    FOURTH: {
      messages: [
        'reactions.wowThanks',
        'reactions.forDream',
        'reactions.wowThatMuch',
      ],
      text: '700 ₸',
      price: 700,
      type: 'FOURTH',
    },
    CUSTOM_PRICE: {
      messages: [
        'reactions.wowThanks',
        'reactions.forDream',
        'reactions.wowThatMuch',
      ],
      text: 'plusAnotherSum',
      price: null,
      type: 'CUSTOM_PRICE',
    },
    NO_PRICE: {
      messages: [],
      text: 'noTips',
      price: 0,
      type: 'NO_PRICE',
    },
  },
  GROUP_CONTROL: {
    FIRST: {
      messages: [
        'reactions.suchPleasure',
        'reactions.thanksFire',
        'reactions.thanksCool',
      ],
      text: '200 ₸',
      price: 200,
      type: 'first',
    },
    SECOND: {
      messages: [
        'reactions.yayThanks',
        'reactions.whatPleasants',
        'reactions.veryPleasant',
      ],
      text: '400 ₸',
      price: 400,
      type: 'second',
    },
    THIRD: {
      messages: ['reactions.omg', 'reactions.madeMyDay', 'reactions.plus100'],
      text: '500 ₸',
      price: 500,
      type: 'third',
    },
    FOURTH: {
      messages: [
        'reactions.wowThanks',
        'reactions.forDream',
        'reactions.wowThatMuch',
      ],
      text: '700 ₸',
      price: 700,
      type: 'fourth',
    },
    OTHER: {
      messages: [
        'reactions.wowThanks',
        'reactions.forDream',
        'reactions.wowThatMuch',
      ],
      text: 'plusAnotherSum',
      price: '',
      type: 'other',
    },
    NOTHING: {
      messages: [],
      text: 'noTips',
      price: 0,
      type: 'nothing',
    },
  },
};

export const DESCRIPTION_MESSAGES = [
  'descriptions.sports',
  'descriptions.walks',
  'descriptions.eat',
  'descriptions.city',
  'descriptions.study',
  'descriptions.travel',
  'descriptions.pets',
  'descriptions.hills',
  'descriptions.rain',
  'descriptions.pets',
];

export const AIMS = [
  'aims.repair',
  'aims.study',
  'aims.visa',
  'aims.travel',
  'aims.flat',
  'aims.sports',
  'aims.laptop',
  'aims.mamacar',
  'aims.university',
  'aims.courses',
];
