<template>
  <div class="flex w-full items-center justify-start">
    <OrderStatus :active="step === STEPS.CREATED">
      <svg
        v-if="step === STEPS.CREATED"
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="52" height="52" rx="26" fill="#FFA100" />
        <path
          d="M38.375 18.125L22.625 33.875L14.75 26"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        v-else
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="52" height="52" rx="26" fill="#F1F2F7" />
        <path
          d="M38.375 18.125L22.625 33.875L14.75 26"
          stroke="#7B7A8C"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </OrderStatus>

    <AnimatedDots
      :is-active="step === STEPS.CREATED"
      :is-passed="step > STEPS.CREATED"
    />

    <OrderStatus :active="step === STEPS.PREPARING">
      <svg
        v-if="step === STEPS.PREPARING"
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="52" height="52" rx="26" fill="#FFA100" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.1135 29.369L16.811 18.5H35.75C36.7265 18.5 37.442 19.4165 37.205 20.3645L35.183 28.4525C34.8755 29.681 33.8315 30.584 32.5715 30.71L22.3475 31.733C20.8235 31.8845 19.43 30.866 19.1135 29.369Z"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.811 18.5L15.836 14H13.25"
          fill="#D9D9D9"
        />
        <path
          d="M16.811 18.5L15.836 14H13.25"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M33.6635 36.9005C33.3605 36.9005 33.1145 37.1465 33.1175 37.4495C33.1175 37.7525 33.3635 37.9985 33.6665 37.9985C33.9695 37.9985 34.2155 37.7525 34.2155 37.4495C34.214 37.1465 33.968 36.9005 33.6635 36.9005Z"
          fill="#D9D9D9"
        />
        <path
          d="M33.6635 36.9005C33.3605 36.9005 33.1145 37.1465 33.1175 37.4495C33.1175 37.7525 33.3635 37.9985 33.6665 37.9985C33.9695 37.9985 34.2155 37.7525 34.2155 37.4495C34.214 37.1465 33.968 36.9005 33.6635 36.9005"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.0456 36.9004C20.7426 36.9004 20.4966 37.1464 20.4996 37.4494C20.4966 37.7539 20.7441 37.9999 21.0471 37.9999C21.3501 37.9999 21.5961 37.7539 21.5961 37.4509C21.5961 37.1464 21.3501 36.9004 21.0456 36.9004Z"
          fill="#D9D9D9"
        />
        <path
          d="M21.0456 36.9004C20.7426 36.9004 20.4966 37.1464 20.4996 37.4494C20.4966 37.7539 20.7441 37.9999 21.0471 37.9999C21.3501 37.9999 21.5961 37.7539 21.5961 37.4509C21.5961 37.1464 21.3501 36.9004 21.0456 36.9004"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <svg
        v-else
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="52" height="52" rx="26" fill="#F1F2F7" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.113 29.369L16.8105 18.5H35.7495C36.726 18.5 37.4415 19.4165 37.2045 20.3645L35.1825 28.4525C34.875 29.681 33.831 30.584 32.571 30.71L22.347 31.733C20.823 31.8845 19.4295 30.866 19.113 29.369Z"
          stroke="#7B7A8C"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.811 18.5L15.836 14H13.25"
          stroke="#7B7A8C"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.663 36.9005C33.36 36.9005 33.114 37.1465 33.117 37.4495C33.117 37.7525 33.363 37.9985 33.666 37.9985C33.969 37.9985 34.215 37.7525 34.215 37.4495C34.2135 37.1465 33.9675 36.9005 33.663 36.9005"
          stroke="#7B7A8C"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.0456 36.9004C20.7426 36.9004 20.4966 37.1464 20.4996 37.4494C20.4966 37.7539 20.7441 37.9999 21.0471 37.9999C21.3501 37.9999 21.5961 37.7539 21.5961 37.4509C21.5961 37.1464 21.3501 36.9004 21.0456 36.9004"
          stroke="#7B7A8C"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </OrderStatus>

    <AnimatedDots
      :is-active="step === STEPS.PREPARING"
      :is-passed="step > STEPS.PREPARING"
    />

    <OrderStatus :active="step === STEPS.DELIVERING">
      <template v-if="isCarDelivery">
        <svg
          v-if="step === STEPS.DELIVERING"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="52" height="52" rx="26" fill="#FFA100" />
          <path
            d="M41.0003 21.5L37.0703 23"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.945 23L11 21.5"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.375 29H31.625"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.375 29H16.625"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.0694 23L35.8109 16.982C35.4464 15.245 33.9149 14 32.1389 14H20.0189C18.2774 14 16.7639 15.2 16.3679 16.8965L14.9414 23"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.875 38H16.25C17.492 38 18.5 36.992 18.5 35.75V34.25H33.5V35.75C33.5 36.992 34.508 38 35.75 38H39.125C40.16 38 41 37.16 41 36.125V27.5C41 25.0145 38.9855 23 36.5 23H15.5C13.0145 23 11 25.0145 11 27.5V36.125C11 37.16 11.84 38 12.875 38Z"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-else
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="52" height="52" rx="26" fill="#F1F2F7" />
          <path
            d="M41.0003 21.5L37.0703 23"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.945 23L11 21.5"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.375 29H31.625"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.375 29H16.625"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M37.0694 23L35.8109 16.982C35.4464 15.245 33.9149 14 32.1389 14H20.0189C18.2774 14 16.7639 15.2 16.3679 16.8965L14.9414 23"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.875 38H16.25C17.492 38 18.5 36.992 18.5 35.75V34.25H33.5V35.75C33.5 36.992 34.508 38 35.75 38H39.125C40.16 38 41 37.16 41 36.125V27.5C41 25.0145 38.9855 23 36.5 23H15.5C13.0145 23 11 25.0145 11 27.5V36.125C11 37.16 11.84 38 12.875 38Z"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
      <template v-else>
        <svg
          v-if="step === STEPS.DELIVERING"
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="52" height="52" rx="26" fill="#FFA100" />
          <circle
            cx="16.4794"
            cy="31.1471"
            r="5.14713"
            stroke="white"
            stroke-width="2.5"
          />
          <circle
            cx="37.1874"
            cy="29.5927"
            r="5.14713"
            stroke="white"
            stroke-width="2.5"
          />
          <path
            d="M19.5755 25.9642C18.9239 26.1923 18.5805 26.9054 18.8086 27.557C19.0367 28.2086 19.7498 28.5519 20.4014 28.3238L19.5755 25.9642ZM20.4014 28.3238L34.7529 23.3004L33.927 20.9408L19.5755 25.9642L20.4014 28.3238Z"
            fill="white"
          />
          <path
            d="M28.5734 16.4602C28.5734 16.4602 32.7145 15.9262 34.1183 19.7595L37.1874 29.8124"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
          />
          <path
            d="M24.3143 24.4454L23.6553 21.7156"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
          />
          <path
            d="M21.6265 20.5439L25.4985 21.5691"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
          />
          <rect
            x="12.097"
            y="17.1765"
            width="6.42657"
            height="4.53912"
            stroke="white"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-else
          width="52"
          height="52"
          viewBox="0 0 52 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="52" height="52" rx="26" fill="#F1F2F7" />
          <circle
            cx="16.4792"
            cy="31.1471"
            r="5.14713"
            stroke="#7B7A8C"
            stroke-width="2.5"
          />
          <circle
            cx="37.1872"
            cy="29.5927"
            r="5.14713"
            stroke="#7B7A8C"
            stroke-width="2.5"
          />
          <path
            d="M19.5753 25.9642C18.9237 26.1923 18.5804 26.9054 18.8084 27.557C19.0365 28.2086 19.7496 28.5519 20.4012 28.3238L19.5753 25.9642ZM20.4012 28.3238L34.7527 23.3004L33.9268 20.9408L19.5753 25.9642L20.4012 28.3238Z"
            fill="#7B7A8C"
          />
          <path
            d="M28.5732 16.4602C28.5732 16.4602 32.7144 15.9262 34.1181 19.7595L37.1872 29.8124"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
          />
          <path
            d="M24.3142 24.4454L23.6553 21.7156"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
          />
          <path
            d="M21.627 20.5439L25.4989 21.5691"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
          />
          <rect
            x="12.0967"
            y="17.1765"
            width="6.42657"
            height="4.53912"
            stroke="#7B7A8C"
            stroke-width="2.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
    </OrderStatus>

    <AnimatedDots
      :is-active="step === STEPS.DELIVERING"
      :is-passed="step > STEPS.DELIVERING"
    />

    <OrderStatus :active="step === STEPS.DELIVERED">
      <svg
        v-if="step === STEPS.DELIVERED"
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="52" height="52" rx="26" fill="#FFA100" />
        <path
          d="M37.25 22.625C37.25 32.75 26 40.625 26 40.625C26 40.625 14.75 32.75 14.75 22.625C14.75 19.6413 15.9353 16.7798 18.045 14.6701C20.1548 12.5603 23.0163 11.375 26 11.375C28.9837 11.375 31.8452 12.5603 33.955 14.6701C36.0647 16.7798 37.25 19.6413 37.25 22.625Z"
          stroke="white"
          stroke-width="2.5"
        />
        <path
          d="M26 27.125C28.4853 27.125 30.5 25.1103 30.5 22.625C30.5 20.1397 28.4853 18.125 26 18.125C23.5147 18.125 21.5 20.1397 21.5 22.625C21.5 25.1103 23.5147 27.125 26 27.125Z"
          stroke="white"
          stroke-width="2.5"
        />
      </svg>
      <svg
        v-else
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="52" height="52" rx="26" fill="#F1F2F7" />
        <path
          d="M37.25 22.625C37.25 32.75 26 40.625 26 40.625C26 40.625 14.75 32.75 14.75 22.625C14.75 19.6413 15.9353 16.7798 18.045 14.6701C20.1548 12.5603 23.0163 11.375 26 11.375C28.9837 11.375 31.8452 12.5603 33.955 14.6701C36.0647 16.7798 37.25 19.6413 37.25 22.625Z"
          stroke="#7B7A8C"
          stroke-width="2.5"
        />
        <path
          d="M26 27.125C28.4853 27.125 30.5 25.1103 30.5 22.625C30.5 20.1397 28.4853 18.125 26 18.125C23.5147 18.125 21.5 20.1397 21.5 22.625C21.5 25.1103 23.5147 27.125 26 27.125Z"
          stroke="#7B7A8C"
          stroke-width="2.5"
        />
      </svg>
    </OrderStatus>
  </div>
</template>

<script>
import { STEPS } from '../config';

const AnimatedDots = {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isPassed: {
      type: Boolean,
      default: false,
    },
  },
  render(h) {
    return h(
      'div',
      {
        staticClass: 'animated-dots mx-auto',
        class: [
          this.isActive ? 'animated-dots-animation' : '',
          this.isPassed ? '' : '',
        ],
      },
      [
        h('div', { staticClass: 'animated-dots-dot ' }),
        h('div', { staticClass: 'animated-dots-dot ' }),
        h('div', { staticClass: 'animated-dots-dot ' }),
        h('div', { staticClass: 'hidden md:block animated-dots-dot ' }),
        h('div', { staticClass: 'hidden md:block animated-dots-dot ' }),
      ]
    );
  },
};

const OrderStatus = {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  render(h) {
    return h(
      'div',
      {
        staticClass: `
          order-status-icon-size flex items-center justify-center
          rounded-full ease-in duration-100
        `,
      },
      [
        h(
          'div',
          {
            staticClass: 'flex items-center justify-center',
            style: 'width: 100%; height: 100%;',
          },
          this.$slots.default
        ),
      ]
    );
  },
};

export default {
  name: 'StatusBar',
  components: {
    AnimatedDots,
    OrderStatus,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    isCarDelivery: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.STEPS = STEPS;
  },
};
</script>

<style lang="postcss">
@keyframes scaling {
  0%,
  100% {
    background: #ffa520;
  }
  50% {
    opacity: 0;
    background: #d4d3df;
  }
}

.order-status-icon-size {
  width: 52px;
  height: 52px;
  @media (min-width: 768px) {
    width: 48px;
    height: 48px;
  }
}
.animated-dots {
  display: flex;
  height: 2px;

  &-dot {
    width: 2px;
    height: 2px;
    margin: 0 2.8px;
    background: #d4d3df;

    &:nth-child(2) {
      width: 4px;
    }
  }

  &-active {
    .animated-dots-dot {
      background: #ffa520;
    }
  }

  &-animation {
    .animated-dots-dot {
      animation: scaling 1.5s ease-in-out infinite;
      &:nth-child(1) {
        animation-delay: 0s;
        background: #ffa520;
      }
      &:nth-child(2) {
        animation-delay: 0.5s;
        background: #ffa520;
      }
      &:nth-child(3) {
        animation-delay: 1s;
        background: #ffa520;
      }
    }
  }
}
@media (min-width: 768px) {
  .animated-dots {
    height: 8px;

    &-dot {
      height: 4px;
      margin: 0 4px;
      border-radius: 50%;

      &:nth-child(1),
      &:nth-child(5) {
        width: 3px;
      }

      &:nth-child(2),
      &:nth-child(4) {
        width: 5px;
      }

      &:nth-child(3) {
        width: 7px;
      }
    }

    &-animation {
      .animated-dots-dot {
        animation: scaling 2.5s ease-in-out infinite;
        &:nth-child(1) {
          animation-delay: 0s;
          background: #ffa520;
        }
        &:nth-child(2) {
          animation-delay: 0.5s;
          background: #ffa520;
        }
        &:nth-child(3) {
          animation-delay: 1s;
          background: #ffa520;
        }
        &:nth-child(4) {
          animation-delay: 1.5s;
          background: #ffa520;
        }
        &:nth-child(5) {
          animation-delay: 2s;
          background: #ffa520;
        }
      }
    }
  }
}
</style>
