import { client } from '../../clients';
import fetchCategoryBannersMock from './mocks/fetch-category-banners.json';

/**
 * Получить список активных баннеров в категориях
 */
export default function (mock = false) {
  if (mock) {
    return Promise.resolve(fetchCategoryBannersMock);
  }

  return client
    .get(`/lavka/v1/active_banners?type=story_in_category`, {
      headers: {
        'X-City-Id': 1,
      },
    })
    .then(response => response.data);
}
