import { rFetchSurveys } from '@/shared/services/api/requestsV2/category-feedback/fetch-surveys';

export function fetchSurveys() {
  return new Promise((resolve, reject) => {
    rFetchSurveys()
      .then(surveysResponse => {
        resolve(surveysResponse.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
