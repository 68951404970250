function findIndex(products, productId, featureID) {
  return products.findIndex(p => {
    if (p.feature_id) {
      return p.product_id === productId && p.feature_id === featureID;
    }
    return p.product_id === productId;
  });
}

// Этот метод нужен для того что б в корзине форсировано изменить количество товара/добавить товар/удалить товар для того что б убрать лоудеры при этих операциях, после того как мы из бека получаем корзину, она с валидирует корзину

/**
 * Этот метод нужен для того что б в корзине форсировано
 * изменить количество товара/добавить товар/удалить товар
 * для того что б убрать лоудеры при этих операциях,
 * после того как мы из бека получаем корзину респонс с валидирует корзину
 * И мы этот метод используем для того что б управлять локальной корзиной, когда юзер без авторизаций
 *
 * Добавляет в текущию корзину новый продукт с увеличением количества и возвращяет измененный массив корзины
 * @param {Product[]} products массив из продуктов, товары которые в корзине сейчас находятся
 * @param {Product} payload инфо о добавление продукта (productId, featureID, addedFrom, price)
 * @returns {Product[]} изменненая корзина
 */
export function tempAddToCart(products, payload) {
  products = JSON.parse(JSON.stringify(products));
  const { productId, featureID, addedFrom, price } = payload;
  const product = {
    ...payload,
    product_id: productId,
    feature_id: featureID,
    quantity: 1,
    added_from: addedFrom,
    price: price || 0,
  };

  const index = findIndex(products, productId, featureID);
  let foundProduct = products[findIndex(products, productId, featureID)];
  if (index !== -1) foundProduct.quantity += 1;
  else products.push(product);

  return products;
}

/**
 * Этот метод нужен для того что б в корзине форсировано
 * изменить количество товара/добавить товар/удалить товар
 * для того что б убрать лоудеры при этих операциях,
 * после того как мы из бека получаем корзину респонс с валидирует корзину
 * И мы этот метод используем для того что б управлять локальной корзиной, когда юзер без авторизаций
 *
 * Уменьшает количество товара либо удаляет товар из корзины если количество этого товара 1 и возвращяет измененный массив корзины
 * @param {Product[]} products массив из продуктов, товары которые в корзине сейчас находятся
 * @param {Product} payload инфо о добавление продукта (productId, featureID, addedFrom, price)
 * @returns {Product[]} изменненая корзина
 */
export function tempRemoveFromCart(products, { productId, featureID }) {
  products = JSON.parse(JSON.stringify(products));
  const index = findIndex(products, productId, featureID);
  if (index !== -1 && products[index].quantity <= 1) {
    products.splice(index, 1);
  } else if (index !== -1) {
    products[index].quantity -= 1;
  }
  return products;
}

/**
 * Этот метод нужен для того что б в корзине форсировано
 * изменить количество товара/добавить товар/удалить товар
 * для того что б убрать лоудеры при этих операциях,
 * после того как мы из бека получаем корзину респонс с валидирует корзину
 * И мы этот метод используем для того что б управлять локальной корзиной, когда юзер без авторизаций
 *
 * Удаляет товар из корзины и возвращяет измененный массив корзины
 * @param {Product[]} products массив из продуктов, товары которые в корзине сейчас находятся
 * @param {Product} payload инфо о добавление продукта (productId, featureID, addedFrom, price)
 * @returns {Product[]} изменненая корзина
 */
export function tempFullRemoveFromCart(products, { productId, featureID }) {
  products = JSON.parse(JSON.stringify(products));
  const index = findIndex(products, productId, featureID);
  if (index !== -1) products.splice(index, 1);
  return products;
}
