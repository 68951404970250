import imageStory1Ru from '@img/commercial-banners/cashback-story-1-ru.jpg';
import imageStory1Kk from '@img/commercial-banners/cashback-story-1-kk.jpg';
import imageDesktop from '@img/commercial-banners/cashback-desktop.jpg';
import imageMobile from '@img/commercial-banners/cashback-mobile.jpg';

export const cashbacksBanner = {
  type: 'story',
  code: 'cashbacks',
  textKk: `Тапсырыс үшін&nbsp;кешбэк`,
  textRu: `Кешбэк за&nbsp;заказы`,
  imageDesktop,
  imageMobile,
  url: '/cashbacks?from=stories-btn',
  fixedPosition: true,
  stories: [
    {
      imageKk: imageStory1Kk,
      imageRu: imageStory1Ru,
    },
  ],
  buttonTextKk: 'Толығырақ',
  buttonTextRu: 'Подробнее',
};
