<template>
  <div class="ContestPrizes">
    <div class="ContestPrizes__container">
      <NyPageSubtitle id="ny-prizes">{{ $t('title') }}</NyPageSubtitle>
      <ul class="ContestPrizes__list">
        <li
          v-for="(prize, i) in visiblePrizes"
          :key="i"
          class="ContestPrizes__listItem"
        >
          <PrizeItem
            :image="prize.image"
            :title="prize.title[$i18n.locale]"
            :place="prize.place[$i18n.locale]"
            :is-additional="i === 0"
          />
        </li>
      </ul>
      <UiButtonNew
        v-if="!showAll"
        class="ContestPrizes__btnExpand"
        variant="gray"
        size="lg"
        @click="showAll = true"
      >
        {{ $t('btnExpand') }}
        <IconArrowDown class="ml-2" />
      </UiButtonNew>
    </div>
  </div>
</template>

<script>
import UiButtonNew from '@ui/UiButtonNew.vue';
import NyPageSubtitle from '../NyPageSubtitle/NyPageSubtitle.vue';
import PrizeItem from './components/PrizeItem/PrizeItem.vue';
import { prizes } from './constants';
import IconArrowDown from './components/IconArrowDown/IconArrowDown.vue';

export default {
  name: 'ContestPrizes',
  components: {
    NyPageSubtitle,
    PrizeItem,
    UiButtonNew,
    IconArrowDown,
  },
  data: () => ({ prizes, showAll: false }),
  computed: {
    visiblePrizes() {
      return this.showAll ? this.prizes : this.prizes.slice(0, 6);
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Призы',
        btnExpand: 'Раскрыть весь список',
      },
      kk: {
        title: 'Сыйлықтар',
        btnExpand: 'Тізімді толық көру',
      },
    },
  },
};
</script>

<style scoped>
.ContestPrizes {
  padding-top: 64px;
}

.ContestPrizes__container {
  width: calc(100% - 32px);
  max-width: 988px;
  margin: auto;
}

.ContestPrizes__list {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContestPrizes__btnExpand {
  margin-top: 32px;
  width: 100%;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .ContestPrizes {
    padding-top: 196px;
  }

  .ContestPrizes__list {
    padding-top: 48px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 56px 90px;
  }

  .ContestPrizes__btnExpand {
    margin-top: 80px;
    width: 332px;
    font-size: 20px;
  }
}
</style>
