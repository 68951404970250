<template>
  <div
    v-scroll="{
      onDirectionChanged: v => (collapsed = v === 'down'),
    }"
    class="WebMobileHeaderCategory"
    :class="{ WebMobileHeaderCategory_collapsed: collapsed }"
  >
    <WebMobileHeader />

    <div class="WebMobileHeaderCategory__searchRow">
      <div class="pb-3 px-4 bg-white flex items-center">
        <IconBack class="mr-3" @click.native="$router.push({ name: 'main' })" />
        <SearchTrigger disabled @click.native="onSearchFocus" />
        <IconSortingM
          v-if="hasSort"
          class="ml-3"
          :modified="!isDefaultSorting"
          @click.native="$emit('onSortClick')"
        />
      </div>
    </div>
    <div
      v-if="hasTags"
      class="WebMobileHeaderCategory__tagsRow pb-3 pl-4 bg-white flex items-center"
    >
      <UiInlineIcon
        name="icon-menu"
        class="mr-3"
        @click.native="openTagsMenu"
      />
      <UiChips
        id="tagsChips"
        :value="selectedTags"
        class="flex items-center overflow-x-scroll hidden-scrollbar rounded-l-xl"
        size="lg"
        text="name"
        :chips="tags"
        @onChange="toggleTags"
      />
    </div>
  </div>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';

import UiChips from '@ui/UiChips.vue';
import Scroll from '@/utils/directives/scroll';
import IconSortingM from '../../views/category/components/IconSortingM/IconSortingM.vue';
import IconBack from '@shared/components/icons/IconBack.vue';
import WebMobileHeader from '@components/header/WebMobileHeader.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';

const arr = () => ({ type: Array, default: () => [] });
const fn = () => ({ type: Function, default: () => {} });
const bool = () => ({ type: Boolean, default: false });

export default {
  name: 'WebMobileHeaderCategory',
  components: {
    UiChips,
    IconBack,
    IconSortingM,
    UiInlineIcon,
    SearchTrigger,
    WebMobileHeader,
  },
  directives: {
    Scroll,
  },
  props: {
    tags: arr(),
    hasTags: bool(),
    selectedTags: arr(),
    toggleTags: fn(),
    openTagsMenu: fn(),
    isDefaultSorting: bool(),
    hasSort: bool(),
  },
  data: () => ({
    collapsed: false,
  }),
  methods: {
    onSearchFocus() {
      Analitycs.logEvent(EVENTS.SEARCH_CLICKED, { page: 'main' });
      this.$router.push({ name: 'search' });
    },
  },
};
</script>

<style>
.WebMobileHeaderCategory {
  transition: transform 0.3s;
}
.WebMobileHeaderCategory_collapsed {
  transform: translateY(-56px);
}
</style>
