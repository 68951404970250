import { rFetchWarehouses } from '@shared/services/api/apis';

let res = null;

export const fetchWarehouseZoneCoordinates = () => {
  if (res && res.regular && res.far) return Promise.resolve(res);

  return new Promise(resolve => {
    res = {
      regular: [],
      far: [],
    };
    rFetchWarehouses().then(({ data }) => {
      // для каждого склада
      data.forEach(elem => {
        const deliveryZones = JSON.parse(elem.delivery_zone);
        deliveryZones.features.forEach((feature, index) => {
          const { geometry } = feature;
          geometry.coordinates[0].map(v => v.reverse());
          if (index === 0) {
            res.regular.push(geometry.coordinates);
          } else if (index === 1) {
            res.far.push(geometry.coordinates);
          }
        });
      });
      resolve(res);
    });
  });
};
