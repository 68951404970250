// import { DevConfigs } from '@/utils/dev-config';
import { client } from '../../clients';
// import response1 from './mocks/samplings1';

export default function (products, warehouse_id = 1) {
  // if (DevConfigs.isDev) {
  //   return new Promise(resolve => setTimeout(() => resolve(response1), 200));
  // }
  return client
    .post('/lavka/v1/sample/apply', {
      products,
      warehouse_id,
    })
    .then(res => res.data);
}
