import { rFetchSaleProducts } from '@shared/services/api/requestsV2/sale-category';
import { store } from '@/store';
import { shuffleProducts } from './shuffle-products';
import { removeDublicates } from './remove-duplicates';

const getParentIdOfPayed = () => {
  return 981;
};
const getCategoryIdOfPayed = () => {
  return 982;
};
const getParentId = () => {
  return 798;
};
const getCategoryId = () => {
  return 204;
};

const setSaleCategoryToStore = payload => {
  store.commit('catalog/SET_SALE_CATEGORY', payload);
};

const getTests = () => {
  return store.getters['abtest/testGroups'];
};
const getWarehouseId = () => {
  return store.getters['delivery/warehouseId'];
};

function fetchCatalog(parentId, categoryId, limit) {
  return new Promise(resolve => {
    const payload = {
      parent_id: parentId,
      is_active: 1,
      page: 1,
      limit,
      category_ids: [categoryId],
      order: 'popular_asc',
      warehouse_id: getWarehouseId(),
      ab_groups: getTests(),
    };

    rFetchSaleProducts(payload)
      .then(res => {
        if (res.data?.items?.length) return resolve(res.data.items);
        return resolve([]);
      })
      .catch(err => {
        console.error(err);
        return resolve([]);
      });
  });
}

/**
 * В эту категорию товары заводят вручную
 */
function fetchPayedSaleCatalog() {
  return fetchCatalog(getParentIdOfPayed(), getCategoryIdOfPayed(), 10);
}

/**
 * В эту категорию автоматически попадают товары
 * когда в 1С заводят промоакцию
 */
function fetchSaleCatalog() {
  return fetchCatalog(getParentId(), getCategoryId(), 20);
}

/**
 * Вызываем метод во время загрузки приложения
 * сразу после получения склада
 *
 * Получаем список продуктов категорий Акции
 * и проплаченых акционных товаров
 *
 * Делаем шафл не проплаченых акционных товаров
 *
 * Сохранеяем их в Store catalog/saleCategory
 */
async function loadSliderCatalog() {
  let saleCategory = null;

  const payedProducts = await fetchPayedSaleCatalog();
  const products = await fetchSaleCatalog();

  const shuffledProducts = shuffleProducts([...products]);
  let results = [...payedProducts, ...shuffledProducts];
  results = removeDublicates(results);
  results = results.splice(0, 10);

  saleCategory = {
    id: getParentId(),
    childId: getCategoryId(),
    items: results,
  };
  setSaleCategoryToStore(saleCategory);
}

export const SaleCategoryService = {
  fetchPayedSaleCatalog,
  loadSliderCatalog,
  getParentId,
};
