<template>
  <div class="CurrentResultItem">
    <div class="CurrentResultItem__value">
      {{ value }}
    </div>
    <div class="CurrentResultItem__text">
      <span v-html="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrentResultItem',
  props: {
    value: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.CurrentResultItem {
  display: grid;
  grid-template-columns: 84px 130px;
  gap: 8px;
}

.CurrentResultItem__value {
  color: var(--main-black, #ffa100);
  font-size: 36px;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
}

@supports (-webkit-background-clip: text) and
  (-webkit-text-fill-color: transparent) {
  .CurrentResultItem__value {
    background: url('./images/foil.png') left top / 100% auto;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.CurrentResultItem__text {
  color: var(--text-text-additional, #acaaba);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px; /* 125% */
}

@media screen and (min-width: 768px) {
  .CurrentResultItem {
    gap: 16px;
    grid-template-columns: 180px 180px;
    align-items: center;
  }

  .CurrentResultItem__value {
    font-size: 72px;
    line-height: 1;
    text-align: right;
  }

  .CurrentResultItem__text {
    font-size: 28px;
    line-height: 32px;
  }
}
</style>
