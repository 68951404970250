<template>
  <div class="UiTooltipSimple relative inline-block">
    <div ref="hoverableBox" class="flex cursor-pointer">
      <slot> </slot>
    </div>
    <transition name="popup-fade">
      <div
        v-if="show"
        class="popup-dialog"
        :class="`${position}`"
        :style="`${tooltipPos.box}`"
      >
        <IconArrow
          class="popup-dialog-arrow"
          :position="arrowDirection"
          :style="`${tooltipPos.arrow}`"
        />
        <div id="contentBox" class="popup-dialog-content">
          <slot name="tip"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import IconArrow from './IconArrow.vue';

export default {
  name: 'UiTooltipSimple',
  components: {
    IconArrow,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom',
    },
  },
  computed: {
    arrowDirection() {
      return this.position.split('-')[0];
    },
    tooltipPos() {
      let box = null;
      let width = 0;
      let height = 0;
      let padding = 16;
      const arrowHeight = 8;
      const arrowWidth = 16;

      try {
        box = this.$refs.hoverableBox;
        width = box.getBoundingClientRect().width;
        height = box.getBoundingClientRect().height;
      } catch (e) {
        console.log(e);
      }
      if (this.position === 'bottom-end') {
        const x = (width + arrowHeight) / 2;
        const y = arrowHeight;
        return {
          box: `transform: translate(${`${x}px`}, ${`${y}px`})`,
          arrow: `transform: translate(${`-${padding}px`}, ${`-${y}px`})`,
        };
      }
      if (this.position === 'right-start') {
        const x = arrowHeight;
        const y = (height - arrowWidth) / 2;
        padding = 12;
        return {
          box: `transform: translate(${`${x}px`}, ${`${-y}px`})`,
          arrow: `transform: translate(${`-${padding - 1}px`}, ${'12px'})`,
        };
      }
      return ``;
    },
  },
};
</script>

<style scoped>
.popup-fade-enter,
.popup-fade-leave-to {
  @apply opacity-0;
}

.popup-fade-enter-active {
  @apply ease-in duration-200;
}

.popup-fade-leave-active {
  @apply ease-in duration-200 delay-75;
}

.popup-fade-enter-active > .popup-dialog {
  transition:
    opacity ease-in 75ms 100ms,
    transform ease-in-out 75ms 100ms;
}

.popup-fade-leave-active > .popup-dialog {
  transition:
    opacity ease-in 75ms 50ms,
    transform ease-in-out 100ms;
}

.popup-fade-enter > .popup-dialog,
.popup-fade-leave-to > .popup-dialog {
  @apply opacity-0 transform scale-75;
}

.popup-dialog {
  @apply absolute z-modal bg-black;
  width: fit-content;
  border-radius: var(--tooltip-radius, 8px);
}

.popup-dialog-content {
  @apply relative  font-medium text-sm leading-4 text-white;
}
.popup-dialog-arrow {
  @apply absolute z-modal bg-transparent;
  width: 16px;
  height: 8px;
}

.right.popup-dialog {
  top: 50%;
  left: 100%;
  transform: translate(8px, -50%);
}
.right .popup-dialog-arrow {
  left: 0;
  top: 50%;
  transform: translate(-12px, -50%);
}

.left.popup-dialog {
  top: 50%;
  right: 100%;
  transform: translate(-8px, -50%);
}
.left .popup-dialog-arrow {
  right: 0;
  top: 50%;
  transform: translate(12px, -50%);
}

.bottom.popup-dialog {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 8px);
}
.bottom .popup-dialog-arrow {
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.top.popup-dialog {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -8px);
}
.top .popup-dialog-arrow {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}

.bottom-end.popup-dialog {
  top: 100%;
  right: 0;
}
.bottom-end .popup-dialog-arrow {
  top: 0;
  right: 0;
}

.right-start.popup-dialog {
  top: 0;
  left: 100%;
}
.right-start .popup-dialog-arrow {
  top: 0;
  left: 0;
}
</style>
