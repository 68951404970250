<template>
  <UiPopup
    :show="modelValue"
    class="CityNotSameModal cnsm"
    :radius="$isDesktop ? '4xl' : '3xl'"
    :closable="$isDesktop"
    @change="$emit('update:modelValue', $event)"
  >
    <p class="cnsm__title">{{ $t('title') }}</p>
    <p class="cnsm__descr" v-html="$t('descr', { cityName })"></p>

    <div class="cnsm__btns">
      <UiButton size="LG" variant="gray" @click.native="change">
        {{ $t('change') }}
      </UiButton>
      <UiButton size="LG" @click.native="rightAddress">
        {{ $t('rightAddress') }}
      </UiButton>
    </div>
  </UiPopup>
</template>

<script>
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
export default {
  components: { UiPopup, UiButton },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
    cityName: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    change() {
      this.closeModal();
      this.$emit('onChange');
    },
    rightAddress() {
      this.closeModal();
      this.$emit('onRight');
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Подтвердите верность адреса',
        descr:
          'Выбранный адрес находится в&nbsp;г.&nbsp;{cityName}, это&nbsp;верный&nbsp;адрес?',
        change: 'Изменить',
        rightAddress: 'Адрес верный',
      },
      kk: {
        title: 'Мекенжайдың дұрыстығын растаңыз',
        descr:
          'Таңдалған мекенжай {cityName}&nbsp;қ. ораналасқан, бұл&nbsp;дұрыс&nbsp;па?',
        change: 'Өзгерту',
        rightAddress: 'Мекенжай дұрыс',
      },
    },
  },
};
</script>

<style scoped>
.cnsm {
  --popup-width: 343px;
  --popup-width-d: 588px;
}
.cnsm__title {
  @apply my-2 
  text-xl font-bold leading-6
  
  md:mt-1 md:mb-6 
  md:text-28 md:leading-8;
}
.cnsm__descr {
  @apply mt-3 leading-5
  
  md:text-lg md:leading-6;
}
.cnsm__btns {
  @apply mt-6 flex space-x-2
  
  md:mt-9;
}
</style>
