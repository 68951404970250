import { parse } from 'qs';
import { AuthService } from '@shared/services/auth';
import { Logger as LOG } from '@shared/services/logger';
import { AUTH_URLS } from '@shared/services/auth/constants';

const logout = () => {
  AuthService.logout();
  return Promise.reject(new Error('DefaultReject'));
};

const convertToInt = string => {
  let clone = string;
  try {
    clone = parseInt(clone, 10);
    if (Number.isNaN(clone)) clone = string;
  } catch (err) {
    clone = string;
  }
  return clone;
};

const parseConfigData = (data, jsonParser = false) => {
  if (!data) return {};

  if (jsonParser) {
    return JSON.parse(data);
  }

  const parsed = parse(data);
  return Object.fromEntries(
    Object.entries(parsed).map(e => [e[0], convertToInt(e[1])])
  );
};

function isRefreshError(err) {
  return (
    err.response?.data?.errors?.length &&
    err.response.data.errors[0].detail === 'The refresh token is invalid.'
  );
}

export function isAuthError(err) {
  return (
    (err.error_code && err.error_code === 401) ||
    err.message === 'Request failed with status code 401' ||
    isRefreshError(err)
  );
}

export function handleUnauthorizedResponse(response) {
  if (AUTH_URLS.includes(response.config.url)) {
    return logout();
  }

  return new Promise(resolve => {
    LOG.event(
      `handleUnauthorizedResponse ${response.config.url}`,
      response.config,
      'Network'
    );
    if (
      Object.prototype.hasOwnProperty.call(response.config, '_retryAttempts')
    ) {
      return logout();
    }
    const config = { ...response.config, _retryAttempts: 1 };

    return AuthService.authorize()
      .then(() => {
        config.data = parseConfigData(config.data, config.useJSONParser);
        return resolve(config);
      })
      .catch(err => {
        LOG.event('handleUnauthorizedResponse catch', err);
        return logout();
      });
  });
}
