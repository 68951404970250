<template>
  <ToolBar>
    {{ $t('title') }}
  </ToolBar>
</template>

<script>
import ToolBar from '@shared/components/layout/ToolBar.vue';

export default {
  name: 'CashbackHeader',
  components: {
    ToolBar,
  },
  i18n: {
    messages: {
      ru: {
        title: 'Кешбэк до 5 заказа',
      },
      kk: {
        title: '5 тапсырысқа дейін кешбэк',
      },
    },
  },
};
</script>
