<template>
  <CommonDeskView v-if="isDesktop" v-on="$listeners" />

  <UiButton
    v-else
    class="w-full"
    variant="gray"
    size="lg"
    @click="$emit('openModal')"
  >
    <img :src="IconSupport" width="24" height="24" />
    <p class="ml-2 font-bold text-lg">
      {{ $t('careService') }}
    </p>
  </UiButton>
</template>

<script>
import { mapGetters } from 'vuex';
import UiButton from '@ui/UiButtonNew.vue';

import { i18n } from '../utils';
import CommonDeskView from './CommonDeskView.vue';

export default {
  name: 'ActiveOrderView',
  components: {
    UiButton,
    CommonDeskView,
  },
  data() {
    return {
      IconSupport: new URL('@img/icons/support.svg', import.meta.url).href,
    };
  },
  computed: {
    ...mapGetters(['isDesktop']),
  },
  i18n,
};
</script>

<style></style>
