<template>
  <MainLayout>
    <template v-if="!$isDesktop" #header>
      <WebMobileHeader />
    </template>
    <UiFullScreenLoader v-if="loading" />
  </MainLayout>
</template>

<script>
import MainLayout from '@shared/components/layout/MainLayout.vue';
import UiFullScreenLoader from '@shared/components/ui/UiFullScreenLoader.vue';
import WebMobileHeader from '@components/header/WebMobileHeader.vue';

export default {
  name: 'AuthView',
  components: { UiFullScreenLoader, MainLayout, WebMobileHeader },
  inject: ['toast'],
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.$router.push({ name: 'main' });
    }, 1000);
  },
};
</script>
