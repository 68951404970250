<template>
  <ul class="MmLangSwitcher">
    <li
      v-for="item in items"
      :key="item.code"
      class="MmLangSwitcher__item"
      :class="{ MmLangSwitcher__item_active: $i18n.locale === item.code }"
    >
      <button class="focus:outline-none" @click="setLocale(item.code)">
        {{ item.label }}
      </button>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'MmLangSwitcher',
  computed: {
    items() {
      return [
        {
          label: 'қаз',
          code: 'kk',
        },
        {
          label: 'рус',
          code: 'ru',
        },
      ];
    },
  },
  methods: {
    setLocale(code) {
      this.$root.$i18n.locale = code;
    },
  },
};
</script>

<style scoped>
.MmLangSwitcher {
  display: flex;
}

.MmLangSwitcher__item {
  padding: 0 12px;
}

.MmLangSwitcher__item_active {
  color: #ffa100;
}

@media (min-width: 768px) {
  .MmLangSwitcher {
    padding: 12px 4px 14px;
    border-radius: var(--spacing-spacing-2-m, 16px);
    background: var(--main-grey-light, #f1f2f7);
  }

  .MmLangSwitcher__item {
    position: relative;
    line-height: 22px;
  }

  .MmLangSwitcher__item + .MmLangSwitcher__item::before {
    content: '';
    display: block;
    position: absolute;
    left: -1px;
    top: 3px;
    width: 1.5px;
    height: 20px;
    background: #d4d3df;
  }
}
</style>
