<template>
  <div
    class="ProductPhoto product-photo"
    :class="{ __blured: blured, __disabled: disabled }"
  >
    <img
      :src="imgSrc"
      :alt="altText"
      v-bind="$attrs"
      class="product-photo-img"
      :class="{ '__mix-blend': mixBlend, __fullWidth: fullWidth }"
      @error="failed = true"
    />
  </div>
</template>

<script>
import NoPhoto from './img/no-photo.png';

export default {
  name: 'ProductPhoto',
  components: {},
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    mixBlend: {
      type: Boolean,
      default: false,
    },
    blured: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: Boolean,
  },
  data() {
    return {
      failed: false,
    };
  },
  computed: {
    isFailed() {
      return this.failed || !this.src;
    },
    imgSrc() {
      return this.isFailed ? NoPhoto : this.src;
    },
    altText() {
      if (this.alt) return this.alt;
      return this.imgSrc.split('/').pop().split('.')[0];
    },
  },
};
</script>

<style scoped>
.product-photo {
  @apply w-full h-full;
}
.__blured {
  filter: blur(3.5px);
}
.__disabled {
  filter: grayscale(100%);
}
.__blured.__disabled {
  filter: grayscale(100%) blur(3.5px);
}
.product-photo-img {
  @apply m-auto h-full object-cover object-center rounded-2xl md:rounded-3xl;
}
.__mix-blend {
  mix-blend-mode: multiply;
}
.__fullWidth {
  width: 100%;
}
</style>
