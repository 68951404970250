<template>
  <UiButton variant="gray" size="LG" @click="$router.push(link)">
    {{ $t('allCategories') }}
    <!-- prettier-ignore -->
    <svg width="25" height="24" class="ml-2" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 16L14.5 12L10.5 8" stroke="#242424" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </UiButton>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'AllCategoriesButton',
  components: {
    UiButton,
  },
  props: { link: { type: String, default: '' } },
  i18n: {
    messages: {
      kk: {
        allCategories: 'Барлық категорияларды көрсету',
      },
      ru: {
        allCategories: 'Показать все категории',
      },
    },
  },
};
</script>
