import imageDesktop from '@img/static-banners/referral-d.jpg';
import imageMobile from '@img/static-banners/referral-m.jpg';
import imageKk from '@img/static-stories/referral-kk.jpg';
import imageRu from '@img/static-stories/referral-ru.jpg';

export const referralBanner = {
  type: 'story',
  code: 'referral',
  fixedPosition: false,
  imageDesktop,
  imageMobile,
  textKk: `
    Бөлісіп, 1500&nbsp;₸ алыңыз
  `,
  textRu: `
    Дарите и&nbsp;получайте 1500&nbsp;₸
  `,
  buttonTextRu: 'Подробнее',
  buttonTextKk: 'Толығырақ',
  stories: [{ imageKk, imageRu }],
  url: '/referral-share',
};
