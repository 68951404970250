import { parse } from 'qs';
import { v4 as uuidv4 } from 'uuid';
import { $log } from '@/utils/plugins/logger';
import { SentryService } from '@services/sentry';
import { applicationBecomeActive } from 'choco-app';
import { eventBus } from '@/utils/plugins/event-bus';
import { DeliveryService } from '@services/delivery';
import { ClientService } from '@shared/services/client';
import { LocaleService } from '@shared/services/locale';
import { getDeviceParams } from '@/utils/get-device-params';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { YandexMetrikaService } from '@services/yandex-metrika';
import { ProductReviewService } from '@services/product-review';
import { getPageLoadTime } from '@/utils/performance/page-load-time';
import { getEndpointTimeEntries } from '@/utils/performance/endpoint-time-entries';
import { RahmetApp } from '@shared/RahmetApp';
import { WebApp } from '@shared/WebApp';
import { CartService } from '@services/cart';
import { CityService } from '@services/city';

function generateDeviceId() {
  const deviceIdUuid =
    localStorage.getItem('deviceIdUuid') ?? `${uuidv4()}--${uuidv4()}`;
  localStorage.setItem('deviceIdUuid', deviceIdUuid);
  return deviceIdUuid;
}

function getDeviceId(deviceId) {
  return deviceId ?? generateDeviceId();
}

eventBus.on('eb_init_page_load_done', () => {
  Analitycs.logEvent(EVENTS.INIT_PAGE_LOAD_DONE, {
    pageLoadTime: getPageLoadTime(),
    endpointsData: getEndpointTimeEntries(),
  });
});

function init() {
  const query = parse(document.location.search.replace('?', ''));
  const {
    session_id: sessionId,
    push_token: pushToken,
    language: lang,
    platform,
    town_id: cityId,
    v,
  } = query;

  const deviceParams = getDeviceParams();
  const deviceId = getDeviceId(query.device_id);
  CityService.setCity(cityId);
  /**
   * При инициализация просто запускаем что б в дельнейшем брать сохраненную
   */
  ClientService.getFingerprint();
  ClientService.updateContext({ deviceId, sessionId, platform, v, lang });

  const initContext = {
    ...deviceParams,
    locationHref: document.location.href,
    sessionId,
    pushToken,
    deviceId,
    platform,
    v,
    /**
     * Логируем url, на который нас кинула нативка.
     * Часто бывает не понятно какие параметры передала
     * нативка, например, при переходе с пуша.
     */
    VITE_APP_RELEASE: import.meta.env.VITE_APP_RELEASE || 'none',
    VITE_APP_RELEASE_TIME: import.meta.env.VITE_APP_RELEASE_TIME || 'none',
    checkAuth: !!window.RahmetApp?.checkAuth,
  };

  Analitycs.init();
  Analitycs.setPushToken(pushToken);
  Analitycs.logEvent(EVENTS.INIT, initContext);
  YandexMetrikaService.init();
  $log('INIT', initContext);

  SentryService.init();
  SentryService.setDeviceId(deviceId);
  LocaleService.setLocale(lang);

  ProductReviewService.init();

  DeliveryService.init();
  CartService.init();

  if (!RahmetApp.isWebView()) {
    WebApp.applicationBecomeActive(() => {
      Analitycs.logEvent(EVENTS.RECONNECT_TO_APP);
      eventBus.emit('eb_on_reconnect_to_app');
    });
  }
  if (RahmetApp.isWebView()) {
    applicationBecomeActive(() => {
      Analitycs.logEvent(EVENTS.RECONNECT_TO_APP);
      eventBus.emit('eb_on_reconnect_to_app');
    });
  }
}

export const AppService = {
  init,
};
