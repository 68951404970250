<template>
  <div class="MBlockError" v-on="$listeners">
    <div class="MBlockError__row flex pt-3">
      <UiInlineIcon name="promocode" />
      <div class="ml-3 mr-auto flex flex-col">
        <span>{{ promocode }}</span>
      </div>
      <UiInlineIcon name="tooltip" style="color: #f91f01" />
      <UiInlineIcon
        class="MBlockError__iconErr"
        name="arrow-right"
        style="color: #a4a2b7"
      />
    </div>
    <div class="MBlockError__errMsg text-sirius-red-200 pb-5">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MBlockError',
  components: {
    UiInlineIcon,
  },
  props: {
    promocode: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    // todo: может быть и такое
    discount: {
      type: Number,
      default: -1,
    },
  },
};
</script>

<style scoped>
.MBlockError__row {
  padding-bottom: 8px;
}

.MBlockError__iconErr {
  margin-left: 6px;
  margin-right: -6px;
}

.MBlockError__errMsg {
  letter-spacing: 0.08px;
  line-height: 18px;
}
</style>
