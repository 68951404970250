import { DevConfigs } from '@/utils/dev-config';

export function lavka(client) {
  return {
    categories(warehouse_id = 1) {
      const version = 'v3';
      // if (import.meta.env.VITE_DEPLOYMENT === 'PRODUCTION') version = 'v2';

      return client
        .get(`/lavka/${version}/categories/main`, { params: { warehouse_id } })
        .then(response => response.data);
    },
    categoryInfo(id) {
      return client
        .get(`/lavka/v1/category/${id}`)
        .then(response => response.data);
    },
    searchInAllCategory(payload) {
      return client.get(`/lavka/v2/catalog/search`, {
        params: payload,
      });
    },
    catalogProducts(payload) {
      return client
        .get('/lavka/v1/catalog', {
          params: payload,
        })
        .then(response => response.data);
    },
    catalogCategories(parentId) {
      return client
        .get('/lavka/v1/categories', {
          params: {
            parent_id: parentId,
            is_active: 1,
          },
        })
        .then(response => response.data);
    },
    catalogBrands(parentId, warehouseId) {
      return client
        .get('/lavka/v1/brand/category', {
          params: {
            category_id: parentId,
            is_active: 1,
            warehouse_id: warehouseId,
          },
        })
        .then(response => response.data);
    },
    productById(id, abGroups) {
      return client.get(`/lavka/v1/product/full/${id}`, {
        params: {
          ab_groups: abGroups,
        },
      });
    },
    // fetchExpectedDeliveryTime(
    //   warehouseId = null,
    //   deliveryAddressId = null,
    //   orderId = null
    // ) {
    //   return client
    //     .get('/lavka/v2/delivery/minutes_range/main', {
    //       params: {
    //         ...(warehouseId && { warehouse_id: warehouseId }),
    //         ...(deliveryAddressId && { user_address_id: deliveryAddressId }),
    //         ...(orderId && { order_id: orderId })
    //       }
    //     })
    //     .then(response => response.data);
    // },
    // fetchExpectedDeliveryTimeOfOrder(orderId = null) {
    //   return client
    //     .get('/lavka/v2/delivery/minutes_range/order', {
    //       params: {
    //         ...(orderId && { order_id: orderId })
    //       }
    //     })
    //     .then(response => response.data);
    // },
    addToCart(productId, featureID, warehouseId, abGroups) {
      return client
        .post('/lavka/v2/cart/add', {
          product_id: productId,
          warehouse_id: warehouseId,
          ...(featureID && { feature_id: featureID }),
          ab_groups: abGroups,
        })
        .then(response => response.data);
    },
    removeFromCart(productId, featureID, warehouseId, quantity, abGroups) {
      return client
        .post('/lavka/v2/cart/remove', {
          product_id: productId,
          warehouse_id: warehouseId,
          quantity,
          ...(featureID && { feature_id: featureID }),
          ab_groups: abGroups,
        })
        .then(response => response.data);
    },
    clearCart(warehouseId, abGroups) {
      return client
        .post('/lavka/v2/cart/clear', {
          warehouse_id: warehouseId,
          ab_groups: abGroups,
        })
        .then(response => response.data);
    },
    removeFullProduct(productId, featureID, warehouseId, abGroups) {
      return client
        .post('/lavka/v2/cart/delete', {
          product_id: productId,
          warehouse_id: warehouseId,
          ...(featureID && { feature_id: featureID }),
          ab_groups: abGroups,
        })
        .then(response => response.data);
    },
    fetchCart(warehouseId, abGroups) {
      return client
        .get('/lavka/v2/cart', {
          params: {
            warehouse_id: warehouseId,
            ab_groups: abGroups,
          },
        })
        .then(response => response.data);
    },
    removeUnavailable(warehouseId, abGroups) {
      return client
        .post('lavka/v2/cart/delete_not_available', {
          warehouse_id: warehouseId,
          ab_groups: abGroups,
        })
        .then(response => response.data);
    },
    fetchPaymentMethods() {
      return client
        .get('/lavka/v1/payment_methods/active')
        .then(response => response.data);
    },
    createOrder(payload) {
      return client
        .post('/lavka/v2/order', payload)
        .then(response => response.data);
    },
    createPreorder(payload) {
      if (DevConfigs.isDev) {
        return Promise.resolve({ data: { id: 1234, hash: 'hashtest' } });
      }
      /**
       * Пытаемся дебажить ошибку
       * https://sentry.chocodev.kz/organizations/sentry/issues/746632/
       * Отправляется не валидный uuidv4
       */
      const key = payload.idempotency_key;
      const rules = [typeof key === 'string', key.length === 36];
      const isIdempotencyKeyValid = rules.every(v => v);
      if (!isIdempotencyKeyValid) {
        throw new Error(`Invalid uuid in lavka/v2/preorder: ${key}`, {
          cause: { key, rules },
        });
      }

      return client
        .post('/lavka/v2/preorder', payload)
        .then(response => response.data);
    },
    orderItemCancel(payload) {
      return client
        .post('/lavka/v2/return_request', payload)
        .then(response => response.data);
    },
    productWithCharacteristics(productID, warehouseId = 1, abGroups) {
      return client
        .get(`/lavka/v2/product/full/${warehouseId}/${productID}`, {
          params: {
            ab_groups: abGroups,
          },
        })
        .then(response => response.data);
    },
    recommendationList(payload) {
      return client
        .get('/lavka/v1/suggestion/products', {
          params: payload,
        })
        .then(response => response.data);
    },
    recipes(payload) {
      return client
        .get('/lavka/v1/recipes', {
          params: payload,
        })
        .then(response => response.data);
    },
    recipesByTags(payload) {
      return client
        .get('/lavka/v1/recipes', {
          params: payload,
        })
        .then(response => response.data);
    },
    recipe(id) {
      return client
        .get(`/lavka/v1/recipe/${id}`)
        .then(response => response.data);
    },
    recipeIngredients(warehouseId, id) {
      return client
        .get(`/lavka/v1/recipe/${id}/ingredients`, {
          params: { warehouse_id: warehouseId },
        })
        .then(response => response.data);
    },
    addIngredientsIntoCart(payload) {
      return client
        .post('/lavka/v1/recipe/ingredient/cart', payload)
        .then(response => response.data);
    },
    replaceIngredient(payload) {
      return client
        .post(
          `/lavka/v1/recipe/${payload.recipe_id}/ingredient/replace`,
          payload
        )
        .then(response => response.data);
    },
    closedReason() {
      return client
        .get('/lavka/v1/setting/main_page_popup_text')
        .then(response => response.data);
    },
    lastNotReviewedOrder() {
      return client
        .get('/lavka/v1/order/not_reviewed')
        .then(response => response.data);
    },
    orderHelp(payload) {
      return client
        .post('/lavka/v1/order/help', payload)
        .then(response => response.data);
    },
    courierInfo(id) {
      /**
       * Дебажим ошибку https://sentry.chocodev.kz/organizations/sentry/issues/651508/?project=27
       */
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(id)) {
        throw Error(`That strange thing happened again, orderId: ${id}`);
      }
      return client
        .get(`/courier/v2/courier/order/${id}/assigned`)
        .then(response => response.data);
    },
    orderIdFromPreorderId(id) {
      return client
        .get(`/lavka/v1/order/by_preorder/${id}`)
        .then(response => response.data);
    },
    fetchAppliances(warehouseId) {
      // const productID = 11700;
      // const url = `/lavka/v2/product/full/${warehouseId}/${productID}`;
      const url = `/lavka/v1/product/cutlery_set/${warehouseId}`;

      return client.get(url).then(response => response.data);
    },
    getRahmetUser() {
      return client.get(`/api/v3/user`).then(res => res.data);
    },
    updateRahmetUser() {
      return client.put(`/api/v3/user`).then(res => res.data);
    },
    uploadUserAvatar(payload) {
      return client
        .post(`/api/v3/user/avatar`, payload, {
          headers: {
            'Content-Type': 'image/png',
          },
        })
        .then(res => res.data);
    },
    deleteUserAvatar(payload) {
      return client
        .delete(`/api/v3/user/avatar`, payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then(res => res.data);
    },
    fetchNYCompetitionMarathonRating() {
      return client
        .get('lavka/v1/authorized/ny_competition_marathon/rating')
        .then(response => response.data);
    },
    fetchNYCompetitionOrderPoints(orderId) {
      return client
        .get(
          `lavka/v1/ny_competition_marathon/statistics_for_single_participant/${orderId}`
        )
        .then(response => response.data);
    },
    fetchNYStat() {
      return client
        .get('/lavka/v1/ny_statistic/user_data')
        .then(response => response.data);
    },
    applyPromocode(warehouseId, promocode, abGroups) {
      return client
        .post(`/lavka/v2/cart/apply_promocode`, {
          warehouse_id: warehouseId,
          promocode,
          ab_groups: abGroups,
        })
        .then(response => response.data);
    },
  };
}
