<template>
  <transition name="popup-fade-slide">
    <div
      v-if="showMe"
      class="CatalogMenu fixed inset-0 z-modal"
      @click="closeModal"
    >
      <div
        class="popup-blackout z-minus w-full h-full bg-sirius-black-900"
      ></div>

      <div
        class="w-full h-full md:w-1/2 popup-dialog fixed left-0 top-0 bg-white duration-500 flex flex-col min-h-0"
        @click.stop
      >
        <div
          v-if="$isDesktop"
          class="w-5/6 ml-auto flex items-center justify-between pt-14 px-8"
        >
          <LogoDesk />
          <IconCancel
            class="cursor-pointer z-10"
            width="32"
            height="32"
            @click.native="closeModal"
          />
        </div>
        <div v-else class="py-3 px-4 flex items-center">
          <IconMobileClose class="mr-3" @click.native="closeModal" />
          <LogoDesk />
          <UiInlineIcon
            class="ml-auto"
            name="support-headphones"
            @click.native="clickSupport"
          />
        </div>
        <UiRippleLoader v-if="!hasMainCategories" class="mx-auto mt-10" />
        <div v-else class="">
          <div v-if="$isDesktop" class="w-5/6 ml-auto mt-10 px-8 flex pb-8">
            <div
              class="w-full mr-2 overflow-y-scroll overscroll-contain hidden-scrollbar"
              style="max-width: 268px; max-height: 80vh"
            >
              <div
                v-for="(item, index) in categoriesByGroupComp"
                :key="index"
                class=""
              >
                <CategoryBlock
                  class="mt-5"
                  :category="item"
                  :is-active="parentCategory === item.name"
                  @onHover="onHover"
                />
              </div>
            </div>

            <div
              v-if="childCategories"
              class="w-full ml-2 pl-4 overflow-y-scroll overscroll-contain hidden-scrollbar"
              :style="`border-left: 1px solid #F1F2F7;
                transform: translateY(-60px);
                max-width: 268px;
                max-height: 80vh`"
            >
              <div v-for="(item, index) in childCategories" :key="index">
                <CategoryBlock
                  class="CategoryBlock__child mt-5"
                  :category="item"
                  :is-active="isActive(item)"
                  :expandable="false"
                  is-child
                  @onNavigate="goToCategory(item)"
                />
              </div>
            </div>
          </div>
          <div
            v-else
            class="overflow-y-scroll overscroll-contain hidden-scrollbar px-4"
            style="height: 100vh"
          >
            <ul>
              <MobileCatalogItem
                v-for="(item, index) in categoriesByGroupComp"
                :key="index"
                class="mt-5"
                :item="item"
                @onNavigate="goToCategory($event)"
              />
            </ul>
            <ul
              class="mt-9 pt-3.5 w-full"
              style="
                min-height: 500px;
                border-top: 2px solid rgba(241, 242, 247, 0.7);
              "
            >
              <li v-for="(item, index) in links" :key="index">
                <a
                  :href="item.path"
                  target="_blank"
                  class="block py-3.5 text-sirius-gray-700 cursor-pointer"
                >
                  {{ $t(item.name) }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { LINKS, LINKS_I18N } from '@shared/config/links';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { isCoffeeAvailable } from '@/lib/coffee/is-coffee-available';
import { COFFEE_CATEGORY_ID } from '@/lib/coffee/config';

import LogoDesk from '@components/header/desk-header/components/LogoDesk.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import IconMobileClose from '../icons/IconMobileClose.vue';
import MobileCatalogItem from './MobileCatalogItem.vue';
import UiRippleLoader from '../ui/UiRippleLoader.vue';
import IconCancel from '../icons/IconCancel.vue';
import CategoryBlock from './CategoryBlock.vue';

export default {
  name: 'CatalogMenu',
  components: {
    LogoDesk,
    IconCancel,
    UiInlineIcon,
    CategoryBlock,
    UiRippleLoader,
    IconMobileClose,
    MobileCatalogItem,
  },
  inject: ['bottomSheetPopup'],
  props: {
    showMe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      childCategories: null,
      parentCategory: null,
      links: LINKS,
    };
  },
  computed: {
    ...mapGetters('catalog', ['hasMainCategories', 'isCatalogLoading']),
    ...mapState('catalog', ['categoriesByGroup']),
    categoriesByGroupComp() {
      const result = {};
      Object.keys(this.categoriesByGroup).forEach(key => {
        const group = this.categoriesByGroup[key];
        result[key] = {
          ...group,
        };
        if (!isCoffeeAvailable()) {
          result[key].categories = group.categories.filter(
            v => v.id !== COFFEE_CATEGORY_ID
          );
        }
      });
      return result;
    },
  },
  watch: {
    showMe() {
      if (!this.isCatalogLoading && !this.hasMainCategories) {
        this.fetchMainCategories();
      }
    },
  },
  methods: {
    ...mapActions('catalog', { fetchMainCategories: 'FETCH_MAIN_CATEGORIES' }),
    navigateTo() {
      this.closeModal();
    },
    isActive(item) {
      return this.$route.params?.id === item.id.toString();
    },
    onHover(item) {
      if (item.categories && item.categories.length > 0) {
        this.parentCategory = item.name;
        this.childCategories = item.categories;
      }
    },
    closeModal() {
      this.$emit('onClose');
    },
    goToCategory(item) {
      if (!item) return;
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
        categoryId: item.id,
        categoryName: item.name,
        from: 'catalog',
      });
      this.$router.push({
        name: 'category',
        params: {
          id: item.id.toString(),
        },
      });
      this.closeModal();
    },
    clickSupport() {
      this.bottomSheetPopup.show('support-popup');
    },
  },
  i18n: {
    messages: {
      ru: {
        ...LINKS_I18N.messages.ru,
      },
      kk: {
        ...LINKS_I18N.messages.kk,
      },
    },
  },
};
</script>

<style lang="postcss" scoped>
.popup-fade-slide {
  &-enter,
  &-leave-to {
    @apply opacity-0;
  }

  &-enter-active {
    @apply ease-in duration-500;
  }

  &-leave-active {
    @apply ease-in duration-500 delay-300;
  }

  &-enter-active > .popup-blackout {
    transition: all ease-in 200ms 100ms;
  }

  &-leave-active > .popup-blackout {
    transition: all ease-in 200ms 50ms;
  }

  &-enter-active > .popup-dialog {
    transition:
      all ease-in 200ms 100ms,
      transform ease-in-out 600ms 100ms;
  }

  &-leave-active > .popup-dialog {
    transition:
      all ease-in 200ms 50ms,
      transform ease-in-out 300ms;
  }

  &-enter > .popup-dialog,
  &-leave-to > .popup-dialog {
    @apply opacity-0 transform -translate-x-full;
  }
}
</style>
