<template>
  <div class="custom-border">
    <div v-if="!selectedRate" class="">
      <p class="font-bold text-lg md:text-2xl text-center">
        {{ $t('rateOrderAndService') }}
      </p>
      <div class="mt-5 mx-2 flex justify-between">
        <div
          v-for="(item, index) in ratePoints"
          :key="index"
          class="w-full flex justify-center cursor-pointer"
          @click="changeRate(item)"
        >
          <div
            class="w-14 h-14 flex items-center justify-center bg-sirius-gray-200 rounded-full"
          >
            <img
              class="w-10 h-10 rounded-full"
              :src="item.img"
              alt="rate-point"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col items-center">
      <div class="w-12 h-12 relative cursor-pointer" @click="changeRate(null)">
        <img class="w-full h-full" :src="selectedRate.img" alt="" />
        <IconFeedbackBack class="absolute -bottom-1 -left-1" />
      </div>

      <p class="mt-4 text-lg font-bold text-center">
        {{ reaction }}
      </p>

      <div class="flex justify-center flex-wrap mt-4">
        <div v-for="(tag, index) in feedbackTags" :key="index">
          <div
            v-if="tag.isPositive.includes(selectedRate.rate)"
            class="h-9 mx-1 mb-2 flex items-center rounded-xl px-2 leading-tight cursor-pointer"
            :class="[
              isSelected(tag)
                ? 'bg-sirius-orange-100 text-white'
                : 'bg-sirius-gray-200',
            ]"
            @click="selectTag(tag)"
          >
            {{ $t(tag.text) }}
          </div>
        </div>
        <div
          v-if="isPositiveRate"
          class="h-9 mx-1 mb-2 px-2 flex items-center rounded-xl cursor-pointer"
          :class="[
            !comment
              ? 'w-min whitespace-nowrap bg-sirius-gray-200'
              : 'bg-sirius-orange-100',
          ]"
          @click="onCommentClick"
        >
          <p v-if="comment" class="text-white line-clamp-1">
            {{ comment }}
          </p>
          <p v-else><span class="mr-1"> +</span> {{ $t('toLeaveComment') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconFeedbackBack from '@shared/components/icons/IconFeedbackBack.vue';
import { RahmetApp } from '@shared/RahmetApp';
import { RATE_POINTS, RATE_TAGS } from '../constants';
import { i18n } from '../i18n';

export default {
  nmae: 'ReviewBlock',
  components: {
    IconFeedbackBack,
  },
  props: {
    selectedRate: {
      type: Object,
      default: null,
    },
    selectedTags: {
      type: Array,
      default: () => [],
    },
    comment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ratePoints: RATE_POINTS,
      feedbackTags: RATE_TAGS,
    };
  },
  computed: {
    reaction() {
      if (this.selectedRate.rate === 5) return this.$t('thanksWhatDidYouLike');
      if (this.selectedRate.rate === 4)
        return this.$t('tellUsWhatShouldWeMakeBetter');
      return this.$t('tellUsWhatWasWrong');
    },
    isPositiveRate() {
      return this.selectedRate && this.selectedRate.rate > 3;
    },
  },
  methods: {
    changeRate(rate) {
      RahmetApp.hapticSelection();
      this.$emit('onChangeRate', rate);
    },
    selectTag(tag) {
      RahmetApp.hapticSelection();
      this.$emit('onSelectTag', tag);
    },
    isSelected(tag) {
      return this.selectedTags.includes(tag);
    },
    onCommentClick() {
      this.$emit('onCommentClick', !!this.comment);
    },
  },
  i18n,
};
</script>

<style scoped>
.custom-border {
  border-color: transparent;
  border-right-width: 20px;
  border-left-width: 20px;
}
</style>
