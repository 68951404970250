<template>
  <div
    class="flex items-center space-x-2 overflow-scroll pr-10 hidden-scrollbar rounded-r-2"
  >
    <div
      v-for="item in tabs"
      :key="`tab_${item.tab}`"
      class="px-2 py-1 flex items-center justify-center font-bold text-lg rounded-2xl focus:outline-none"
      :class="[
        tab === item.tab
          ? 'bg-sirius-gray-200 text-black'
          : 'bg-black text-white border border-sirius-gray-200',
      ]"
      @click="toggleTab(item)"
    >
      {{ item.text ? item.text : item.tab }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTabs',
  model: {
    prop: 'tab',
    event: 'change',
  },
  props: {
    tab: { type: String, required: true },
    tabs: { type: Array, required: true },
  },
  methods: {
    toggleTab(item) {
      this.$emit('change', item.tab);
    },
  },
};
</script>
