import Story1kk from '@img/static-stories/fresh-1-kk.jpg';
import Story1ru from '@img/static-stories/fresh-1-ru.jpg';
import Story2kk from '@img/static-stories/fresh-2-kk.jpg';
import Story2ru from '@img/static-stories/fresh-2-ru.jpg';
import Story3kk from '@img/static-stories/fresh-3-kk.jpg';
import Story3ru from '@img/static-stories/fresh-3-ru.jpg';
import imageDesktop from '@img/static-banners/fresh-d.jpg';
import imageMobile from '@img/static-banners/fresh-m.jpg';

export const mainFreshBanner = {
  type: 'story',
  code: 'fresh_main',
  fixedPosition: true,
  imageDesktop,
  imageMobile,
  textKk: `
    Балғын көкөніс
  `,
  textRu: `
    Отбираем фреш
  `,
  buttonTextRu: 'Всё понятно!',
  buttonTextKk: 'Түсінікті!',
  stories: [
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
    },
    {
      imageKk: Story2kk,
      imageRu: Story2ru,
    },
    {
      imageKk: Story3kk,
      imageRu: Story3ru,
    },
  ],
};
