import { EventEmitter } from '@services/emitter';

export const ORDER_EVENTS = {
  ON_UPDATE_ORDER: 'ON_UPDATE_ORDER',
  ON_ORDER_DELIVERED: 'ON_ORDER_DELIVERED',
  ON_ACTIVE_ORDER_ADDED: 'ON_ACTIVE_ORDER_ADDED',
  ON_ACTIVE_ORDER_REMOVED: 'ON_ACTIVE_ORDER_REMOVED',
  OPEN_LAST_ADDED_ACTIVE_ORDER: 'OPEN_LAST_ADDED_ACTIVE_ORDER',
  /** From Support center  */
  ON_REVIEW_CLICKED: 'ON_REVIEW_CLICKED',
};

export const orderEmitter = new EventEmitter();
