<template>
  <div
    class="flex justify-between font-medium leading-5"
    :class="`${disabled ? 'text-sirius-gray-300' : 'text-sirius-gray-800'}`"
  >
    <div class="flex items-center">
      <slot name="left"> </slot>
    </div>

    <span
      class="font-medium"
      :class="`${disabled ? 'text-sirius-gray-300' : 'text-sirius-black-100'}`"
    >
      <slot name="right"> </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'CheckoutText',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
