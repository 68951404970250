import { store } from '@/store';

function init() {
  if (window.localStorage.getItem('questionnaire')) {
    store.commit('questionnaire/DISABLE');
  }
}

function disable() {
  store.commit('questionnaire/DISABLE');
  window.localStorage.setItem('questionnaire', 1);
}

export const QuestionnaireService = {
  init,
  disable,
};
