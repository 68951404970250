<template>
  <div
    v-scroll="{ disabled: !hideable, onDirectionChanged: onDirectionChanged }"
    class="HeaderBaseWrapper header-wrapper"
    :class="{ '--show': showTop }"
  >
    <div class="header-wrapper-top">
      <slot name="header-top"> </slot>
    </div>
    <div class="header-wrapper-middle">
      <slot name="header-middle"></slot>
    </div>
    <slot name="header-bottom"></slot>
  </div>
</template>

<script>
import Scroll from '@/utils/directives/scroll';
export default {
  components: {},
  directives: { Scroll },
  props: {
    hideable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTop: true,
    };
  },
  methods: {
    scrollUp() {
      this.showTop = true;
      this.$emit('showTop');
    },
    scrollDown() {
      this.showTop = false;
      this.$emit('hideTop');
    },
    onDirectionChanged(value) {
      value === 'up' ? this.scrollUp() : this.scrollDown();
    },
  },
};
</script>

<style scoped>
.header-wrapper {
  @apply w-full transition-all duration-200;
  transform: translateY(-56px);
  background: white;
}
.header-wrapper.--show {
  transform: translateY(0px);
}

.header-wrapper-top {
  @apply px-2 w-full;
}
.header-wrapper-middle {
  @apply py-3 px-4 flex items-center space-x-3;
}
</style>
