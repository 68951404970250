<template>
  <div>
    <slot></slot>
    <UiRippleLoader v-if="isLoading" class="flex justify-center mx-auto" />
    <div ref="infiniteScroll" class="h-1"></div>
  </div>
</template>

<script>
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import debounce from 'debounce';

const options = {
  root: null,
  threshold: 0.1, // Увеличение порога для более точного срабатывания
  rootMargin: '200px', // Увеличение корневого отступа для предварительного срабатывания
};

function isInViewport(elem) {
  const bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default {
  name: 'UiInfiniteScroll',
  components: {
    UiRippleLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
      isIntersecting: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScrollDebounced);

    if (this.observer !== null) {
      this.observer.disconnect();
    }
  },
  mounted() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver(this.callback, options);
      this.observer.observe(this.$refs.infiniteScroll);
    } else {
      this.onScrollDebounced = debounce(this.onScroll, 200);
      window.addEventListener('scroll', this.onScrollDebounced);
    }

    this.onScrollDebounced = debounce(this.onScroll, 200);
    window.addEventListener('scroll', this.onScrollDebounced);
  },
  methods: {
    callback(entries) {
      entries.forEach(entry => {
        if (entry && entry.isIntersecting) {
          this.$emit('callback');
        }
      });
    },
    onScroll() {
      const isIntersecting = isInViewport(this.$refs.infiniteScroll);

      if (isIntersecting && !this.isIntersecting) {
        this.$emit('callback');
      }

      this.isIntersecting = isIntersecting;

      // Ручная проверка на достижение конца страницы
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.$emit('callback');
      }
    },
  },
};
</script>
