import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { ClientService } from '@shared/services/client';
import { authEmitter, AUTH_EVENTS } from '@shared/services/auth/events';
import { DevConfigs } from '@/utils/dev-config';
import router from '@/router';

function setTags(key, value) {
  Sentry.setTag(key, value);
}

function setUserId() {
  setTags('userId', ClientService.getUserId());
}

function setDeviceId(deviceId) {
  setTags('deviceId', deviceId);
}

function init() {
  if (DevConfigs.isDev) return;

  Sentry.init({
    // app // TODO: vue3 add
    Vue, // TODO: vue2 remove
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_DEPLOYMENT,
    release: import.meta.env.VITE_APP_RELEASE,
    trackComponents: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'https://webryadom.chocodev.kz',
      'https://webryadom.choco.kz',
      'https://ryadom.kz',
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  authEmitter.on(AUTH_EVENTS.ON_AUTH, setUserId);
}

export const SentryService = {
  init,
  setDeviceId,
};
