const DevConfigs = {
  isDev: import.meta.env.VITE_DEPLOYMENT === 'DEVELOPMENT',
  isStage: import.meta.env.VITE_DEPLOYMENT === 'STAGING',
  isProd: import.meta.env.VITE_DEPLOYMENT === 'PRODUCTION',

  login: import.meta.env.VITE_DEV_LOGIN === 'true',
  loginRefreshToken: import.meta.env.VITE_DEV_REFRESH_LOGIN === 'true',
  isMiniApp: import.meta.env.VITE_DEV_IS_MINIAPP === 'true',
  consoleLogs: import.meta.env.VITE_DEV_LOGS === 'true',
  mockPromocodes: import.meta.env.VITE_DEV_MOCK_PROMOCODES === 'true',
  developers: [
    15102560 /* Magzhan */, 14019785 /* Magzhan */, 11989918 /* Magzhan */,
    14520106 /* Vladimir */, 11994448 /* Daniyar */, 11990427 /* Daniyar */,
  ],
};

export { DevConfigs };
