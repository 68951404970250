<template>
  <div class="ProgressSweets sweets md:px-0">
    <div v-for="i in 5" :key="i" class="sweets-wrap">
      <div class="sweets-item" :style="`z-index: ${5 - i}`">
        <img
          class="sweets-item-img"
          :src="i <= active ? ImgRed : ImgGray"
          alt="sweet"
          width="128"
          height="128"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImgRed from './img/star-red.png';
import ImgGray from './img/star-gray.png';

export default {
  name: 'ProgressSweets',
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ImgRed,
      ImgGray,
    };
  },
};
</script>

<style scoped>
.sweets {
  width: 100%;
  padding: 0 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sweets-wrap {
  width: 20%;
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
}
.sweets-item {
  position: relative;
  flex-shrink: 0;
  display: block;
  height: 128px;
  width: 128px;
}
.sweets-item-img {
  height: 128px;
  width: 128px;
}

@media (min-width: 768px) {
  .sweets {
    padding: 0;
  }
  .sweets-item {
    height: 140px;
    width: 140px;
  }

  .sweets-item-img {
    height: 140px;
    width: 140px;
  }
}
</style>
