<script>
export default {
  name: 'IconSortingD',
  props: {
    modified: Boolean,
  },
};
</script>

<template>
  <div class="IconSortingD" :class="{ IconSortingD_modified: modified }">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="12" fill="#F1F2F7" />
      <path
        d="M18 16L15 13L12 16"
        stroke="#242424"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 27V13"
        stroke="#242424"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 24L25 27L28 24"
        stroke="#242424"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M25 13V27"
        stroke="#242424"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style scoped>
.IconSortingD {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
}

.IconSortingD_modified::after {
  --r: 6px;
  content: '';
  display: block;
  position: absolute;
  width: calc(var(--r) * 2);
  height: calc(var(--r) * 2);
  top: -2px;
  right: -2px;
  border-radius: 50%;
  background: #ffa100;
}
</style>
