<template>
  <div class="TimeLine" :style="{ '--progress': progress }">
    <!-- [1, count] -->
    <div
      v-for="i in count"
      :key="`${i}_${count}`"
      class="TimeLine__item"
      :class="{
        TimeLine__item_watched: i - 1 < currentIdx,
        TimeLine__item_current: i - 1 === currentIdx,
      }"
    />
  </div>
</template>

<script>
export default {
  name: 'TimeLine',
  props: {
    count: {
      type: Number,
      default: 0,
    },
    currentIdx: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.TimeLine {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.TimeLine__item {
  position: relative;
  flex-basis: 100%;
  height: 4px;
  border-radius: 12px;
  background: rgb(255, 255, 255, 0.5);
  overflow: hidden;
}

.TimeLine__item_watched {
  background: rgb(255, 255, 255);
}

.TimeLine__item_current::before {
  content: '';
  display: block;
  position: absolute;
  background: #fff;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  right: 100%;
  transform: translateX(calc(1% * var(--progress)));
}

@media screen and (min-width: 768px) {
  .TimeLine {
    padding-top: 48px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
</style>
