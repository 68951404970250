<template>
  <div class="">
    <div
      v-for="(item, index) in radios"
      :key="index"
      class="flex items-center"
      @click="onClick(item[valueId])"
    >
      <div class="pr-2 py-2">
        <div
          class="w-7 h-7 flex items-center justify-center rounded-full border-2 border-sirius-black-100"
          :class="
            value === item[valueId]
              ? 'border-sirius-orange-100'
              : 'border-sirius-gray-300'
          "
        >
          <input
            :id="index"
            type="radio"
            :value="item[valueId]"
            class="appearance-none focus:outline-none block rounded-full w-4 h-4"
            :class="[
              value === item[valueId] ? 'bg-sirius-orange-100' : 'bg-white',
            ]"
          />
        </div>
      </div>
      <label :for="index">
        {{ item[text] }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiRadio',
  props: {
    value: {
      type: String,
      default: null,
    },
    radios: {
      type: Array,
      default: () => [],
    },
    valueId: {
      type: String,
      default: 'id',
    },
    text: {
      type: String,
      default: 'title',
    },
  },
  methods: {
    onClick(item) {
      this.$emit('input', item);
      this.$emit('onChange', item);
    },
  },
};
</script>

<style></style>
