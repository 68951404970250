<template>
  <div v-if="!review" class="ProductReviewBlock review-block">
    <p class="review-block-title" v-html="$t('title')"></p>

    <LikeDislike
      class="review-block-like-dislike"
      @onLike="onLike"
      @onDislike="onDislike"
    />
  </div>
  <div v-else class="ProductReviewBlock review-block" @click="onOpenComment">
    <img
      :src="review === 'dislike' ? IconDislike : IconLike"
      alt="like-dislike"
      class="review-block-like"
      @click="reset"
    />

    <div class="review-block-review">
      <template v-if="comment">
        <p class="review-block-review__gray" v-html="$t('comment')"></p>
        <p class="review-block-review__black line-clamp-1">{{ comment }}</p>
      </template>
      <template v-else>
        <p class="review-block-review__black" v-html="$t('thanksReview')"></p>
        <p class="review-block-review__gray" v-html="$t('tellMore')"></p>
      </template>
    </div>

    <UiInlineIcon name="review-arrow" />
  </div>
</template>

<script>
import LikeDislike from '@pure-ui/components/ProductReview/components/LikeDislike.vue';
import IconDislike from '@pure-ui/components/ProductReview/icons/white-dislike.jpg';
import IconLike from '@pure-ui/components/ProductReview/icons/white-like.jpg';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'ProductReviewBlock',
  components: { LikeDislike, UiInlineIcon },
  props: {
    review: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      IconLike,
      IconDislike,
    };
  },
  methods: {
    reset() {
      this.$emit('onUpdate', '');
    },
    onLike() {
      this.$emit('onUpdate', 'like');
    },
    onDislike() {
      this.$emit('onUpdate', 'dislike');
    },
    onOpenComment() {
      this.$emit('onOpenComment', this.review);
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'Тауарды бағалаңыз',
        thanksReview: 'Бағаңызға рақмет',
        tellMore: 'Толығырақ айту',
        comment: 'Түсініктеме',
      },
      ru: {
        title: 'Оцените этот товар',
        thanksReview: 'Спасибо за оценку',
        tellMore: 'Расскажите подробнее',
        comment: 'Комментарий',
      },
    },
  },
};
</script>

<style scoped>
.review-block {
  @apply w-full py-2 pl-4 pr-3
    rounded-2xl
    flex items-center space-x-3
    bg-sirius-gray-200;
}
.review-block-title {
  @apply w-full text-lg font-medium leading-6;
}
.review-block-like-dislike {
  @apply min-w-fit;
}

.review-block-review {
  @apply w-full font-medium;
}
.review-block-review p:last-child {
  @apply mt-1;
}
.review-block-review__black {
  @apply leading-5;
}
.review-block-review__gray {
  @apply text-sm text-sirius-gray-1000 leading-4;
}

.review-block-like {
  @apply min-w-12 h-12 rounded-full;
}
</style>
