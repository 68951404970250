import {
  EVENTS,
  Analitycs,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';
import { SCREENS } from '@shared/config/product';
import {
  extracktMeasurement,
  formatWeightText,
  extractWeight,
} from '@services/product/utils/extract-weight';
import { wordDeclination } from '@shared/utils/delivery/word-declination';
import { isApplianceCategory, isAppliance } from '@services/appliances';
import { formatPrice } from '@shared/utils/formatters/price';
import { onAdd, onDec } from '@services/product/analytics';
import { mapGetters, mapActions, mapState } from 'vuex';
import { PRODUCT_ADDED } from '@shared/config/product';
import { api } from '@shared/services/api';

export const productFull = {
  name: 'productFull',
  inject: ['toast', 'bottomSheet'],
  data() {
    return {
      productId: null,
      product: null,
      feature: null,
      query: null,
      loading: true,
      PRODUCT_ADDED,
    };
  },
  computed: {
    ...mapState('cart', ['appliances']),
    ...mapGetters('abtest', ['testGroups']),
    ...mapGetters('cart', [
      'cartSize',
      'getFromCart',
      'cartTotalPriceWithDiscount',
      'checkoutReminder',
      'minAmountReminder',
    ]),
    ...mapGetters('delivery', ['warehouseId']),
    imageSrc() {
      if (!this.product) return [];

      const { images } = this.product;
      return Array.isArray(images) && images.length > 0 ? images : [];
    },
    uid() {
      return `${this.query?.category_id || 0}::${this.product?.id}`;
    },
    productDeclination() {
      return wordDeclination(this.cartSize, ['товар', 'товара', 'товаров']);
    },
    nutritionals() {
      return this.product?.nutritionals ? this.product.nutritionals : null;
    },
    hasDescription() {
      if (!this.product) return null;
      return this.product.full_description !== null;
    },
    hasManufacturer() {
      return false;
    },
    showShareLink() {
      return import.meta.env.VITE_DEPLOYMENT !== 'PRODUCTION';
    },
    oldPrice() {
      return this.feature?.old_price || this.product?.old_price || 0;
    },
    price() {
      return this.feature
        ? formatPrice(this.feature.price)
        : formatPrice(this.product.price);
    },
    productWeight() {
      const weight = extractWeight(this.product, this.feature);
      const feat = extracktMeasurement(this.feature);
      return formatWeightText(weight, feat);
    },
    maxQuantity() {
      if (!this.product) return 0;
      if (this.feature) return this.feature.max_quantity;
      return this.product.max_quantity;
    },
    formatedAppliances() {
      if (
        isApplianceCategory(this.product.analytical_category_id) &&
        !isAppliance(this.product.id)
      ) {
        return (
          this.getFromCart(`${this.appliances.id}_0`) || {
            ...this.appliances,
            product_id: this.appliances.id,
            qunatity: 0,
          }
        );
      }
      return null;
    },
  },
  methods: {
    formatPrice,
    ...mapActions('cart', {
      fetchAppliances: 'FETCH_APPLIANCES',
    }),
    ...mapActions('component', {
      resetCategoryState: 'RESET_CATEGORY_STATE',
    }),
    defineFromAndId() {
      let categoryId = null;
      if (this.query?.added_from) {
        categoryId = this.query.added_from.split('_')[1];
      }
      if (this.query?.category_id) categoryId = this.query.category_id;
      return [this.PRODUCT_ADDED.PRODUCT, categoryId];
    },
    onAddToCart(addToCart) {
      const [from, id] = this.defineFromAndId();
      onAdd(from, { ...this.product, openedFrom: this.query.opened_from });
      addToCart(from, id);
    },
    onIncrement(addToCart) {
      const [from, id] = this.defineFromAndId();
      onAdd(
        from,
        { ...this.product, openedFrom: this.query.opened_from },
        true
      );
      addToCart(from, id);
    },
    onDecrement(removeFromCart) {
      const [from] = this.defineFromAndId();
      onDec(from, { ...this.product, openedFrom: this.query.opened_from });
      removeFromCart();
    },
    onReplaceProduct(replaceFromFull) {
      replaceFromFull().then(() => {
        this.toast.show('Замена ингредиента прошла успешна');
        this.$router.push({
          name: 'recipe-full',
          params: { id: this.product.replacement.recipe_id },
        });
      });
    },
    onTagTapped(item) {
      this.feature = item;
    },
    configureAppliances() {
      if (
        isApplianceCategory(this.product.analytical_category_id) &&
        !isAppliance(this.product.id)
      ) {
        return this.fetchAppliances();
      }
      return Promise.resolve();
    },
    configureReplacement() {
      if (this.query && Boolean(this.query.replacement) === true) {
        const replacement = {
          replacement: true,
          product_id: this.query.product_id,
          recipe_id: this.query.recipe_id,
          ...(this.query.feature_id && {
            feature_id: this.query.feature_id,
          }),
        };
        this.product = Object.assign(this.product, {
          replacement,
        });
      }
    },
    resetState() {
      this.productId = null;
      this.product = null;
      this.features = null;
      this.feature = null;
      this.query = null;
      this.resetCategoryState();
    },
    fetchProduct() {
      this.loading = true;

      api.lavka
        .productWithCharacteristics(
          this.productId,
          this.warehouseId,
          this.testGroups
        )
        .then(res => {
          if (res.data.is_sample) {
            this.$eventBus.emit('eb_close_product_details_modal');
            this.$router.push('/');
            return Promise.resolve();
          }
          this.product = res.data;
          this.features = this.product.features ?? null;
          this.feature = this.getSelectedFeature();

          this.$gtm.trackEvent({
            event: 'viewProduct',
            productId: this.product.id,
            productName: this.product.name,
            productPrice: this.feature
              ? this.feature.price
              : this.product.price,
          });
          Analitycs.logEvent(EVENTS.VIEWED_PRODUCT, {
            product_id: this.product.id,
            product: this.product,
            from: SCREENS[this.query.opened_from],
          });

          Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_VIEW_CONTENT, {
            product_id: this.product.id,
            product: this.product,
            content_ids: [`${this.product.id}`],
            content_type: 'product',
          });

          this.configureReplacement();
          return this.configureAppliances();
        })
        .catch(err => {
          this.toast.show(err.message);

          if (this.$isDesktop) this.$router.push({ name: 'main' });
          else this.$eventBus.emit('eb_close_product_details_modal');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async shareLink() {
      const shareOptions = {
        title: this.product.name ?? '',
        url: `https://rahmetapp.kz/webapp/1/product/${this.productId}`,
      };
      try {
        await navigator.share(shareOptions);
      } catch (err) {
        this.toast.show('Не удалось получить ссылку продукта');
      }
    },
    /**
     * Получаем выбранный вариант при открытии модалки или страницы с товаром.
     * Если есть добавленный в корзину, первым выбираем его
     */
    getSelectedFeature() {
      if (!this.features) {
        return null;
      }

      for (let feature of this.features) {
        for (let cartItem of this.$store.getters['cart/items']) {
          if (feature.id === cartItem.feature_id) {
            return feature;
          }
        }
      }

      return this.features[0];
    },
  },
};
