import { ABTestEmitter, ABTEST_EVENTS } from '@services/abtest/emitter';
import { ORDER_EVENTS, orderEmitter } from '@services/orders/events';
import { USER_EVENTS, UserEmitter } from '@services/user/emitter';
import { rFetchOrders } from '@shared/services/api/apis';
import { createStorage } from '@shared/services/storage';
import { Logger as LOG } from '@shared/services/logger';
import { ABTestService } from '@services/abtest';
import { TESTS } from '@services/abtest/tests';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { DevConfigs } from '@/utils/dev-config';
import { store } from '@/store';

import { CASHBACK_CONDITIONS } from './constants';

const storage = createStorage(window.localStorage);

const getMaxOrdersCount = () => {
  if (!DevConfigs.isProd) {
    return storage.parse('cashbacks::maxOrdersCount', 5);
  }
  return 5;
};
const getLoadCount = () => {
  if (!DevConfigs.isProd) {
    return store.getters['user/userOrdersCount'] - (getMaxOrdersCount() - 5);
  }
  return 5;
};

function setFinishedPageViewed() {
  storage.set('cashbacks::finishPageViewed', true);
}
function switchOnCashbacks() {
  Analitycs.logEvent(EVENTS.CASHBACKS_INIT);
  store.commit('cashbacks/SET_CASHBACKS_PROGRAM', true);
}
function switchOffCashbacks() {
  store.commit('cashbacks/SET_CASHBACKS_PROGRAM', false);
}

async function fetchOrders() {
  const request = (tab, limit = 5) => {
    if (limit <= 0) return Promise.resolve([]);
    return rFetchOrders({ tab, limit })
      .then(({ data }) => data.items.reverse())
      .catch(() => []);
  };

  const activeOrders = await request('active');

  const loadCount = getLoadCount();
  const maxOrderCount = getMaxOrdersCount();

  LOG.event('CashbackService fetchOrders', { maxOrderCount, loadCount });

  let leftOrdersCount = loadCount - activeOrders.length;
  leftOrdersCount = leftOrdersCount < 0 ? 0 : leftOrdersCount;
  const finishedOrders = await request('finished', leftOrdersCount);
  return [...activeOrders, ...finishedOrders];
}

function calculateCashback(orderPrice, condition) {
  let cashback = (orderPrice * condition.percentage) / 100;
  if (cashback > condition.maxBonus) cashback = condition.maxBonus;
  return +Number.parseFloat(cashback).toFixed();
}

async function loadCashbacks() {
  const orders = await fetchOrders();

  const conditions = [...CASHBACK_CONDITIONS].map((condition, index) => {
    const order = orders[index];
    if (!order) return condition;
    const price =
      order.price + (order.service_fee || 0) + (order.delivery_price || 0);
    return {
      ...condition,
      cashback: calculateCashback(price, condition),
    };
  });

  return conditions;
}

function showCashbackLinksIfNeed() {
  const finishPageViewed = storage.parse('cashbacks::finishPageViewed', false);
  store.commit('cashbacks/SET_CASHBACKS_LINKS', !finishPageViewed);
  LOG.event('CashbackService links show', { shown: !finishPageViewed });
}

function validateByUser() {
  return !!store.getters['user/userProfile'];
}

function validateByOrders() {
  const user = store.getters['user/userProfile'];
  const { orders_count: ordersCount } = user;

  const maxOrderCount = getMaxOrdersCount();
  const loadCount = getLoadCount();
  LOG.event('CashbackService ordersCount', {
    ordersCount,
    maxOrderCount,
    loadCount,
  });

  if (ordersCount === maxOrderCount) showCashbackLinksIfNeed();
  return ordersCount < maxOrderCount;
}
function validateByABtest() {
  return ABTestService.getGroupByCode(TESTS.cashbacks.code) === 'test';
}

function showChasbacks(type) {
  if (!validateByABtest()) {
    LOG.event('CashbackService showChasbacks abtest fail', { type });
    switchOffCashbacks();
    return;
  }
  if (!validateByUser()) {
    LOG.event('CashbackService showChasbacks user fail', { type });
    switchOffCashbacks();
    return;
  }
  if (!validateByOrders()) {
    LOG.event('CashbackService showChasbacks orders fail', { type });
    switchOffCashbacks();
    return;
  }
  LOG.event('CashbackService ShowChasbacks', { type });

  switchOnCashbacks();
}

/**
 * При авторизаций я должен проверить кешбеки
 * При входе должен проверить кешбеки
 * И каждый раз кога заказ сделан тоже проверить кешбеки
 * При получение абтестов
 * И тогда когда 5 заказ сделан и ее еще не смотрели можно кешбеки делать доступными
 * Сделать отдельный модуль стора для кешбека
 *
 */

function init() {
  showChasbacks('init');
  UserEmitter.on(USER_EVENTS.ON_USER_LOADED, () => showChasbacks('user'));
  ABTestEmitter.on(ABTEST_EVENTS.ON_FETCH_TESTS, () => showChasbacks('test'));
  orderEmitter.on(ORDER_EVENTS.ON_ACTIVE_ORDER_ADDED, () =>
    showChasbacks('orders')
  );
}

export const CashbackService = {
  setFinishedPageViewed,
  calculateCashback,
  loadCashbacks,
  init,
};
