<template>
  <div class="NoDelivery" :class="{ __modal: modal, '--gray': gray }">
    <p class="NoDelivery__title">{{ $t('notDeliverYet') }}</p>
    <p class="NoDelivery__desc" v-html="$t(descr)"></p>
    <div class="NoDelivery__btns">
      <UiButton
        class="NoDelivery__btn"
        :size="$isDesktop && variant !== 'DefaultNoDelivery' ? 'SM' : 'LG'"
        @click.native="$emit(orangeBtn)"
      >
        {{ $t(orangeBtn) }}
      </UiButton>

      <UiButton
        v-if="
          variant !== 'DefaultNoDelivery' ||
          (variant === 'DefaultNoDelivery' && hasAddresses)
        "
        class="NoDelivery__btn"
        :size="$isDesktop && variant !== 'DefaultNoDelivery' ? 'SM' : 'LG'"
        variant="gray"
        @click.native="$emit(grayBtn)"
      >
        {{ $t(grayBtn) }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'NoDelivery',
  components: { UiButton },
  props: {
    variant: {
      type: String,
      default: 'DefaultNoDelivery',
      // default: 'NearToDelivery'
      // default: 'FarFromDelivery'
    },
    modal: Boolean,
    gray: Boolean,
    hasAddresses: Boolean,
  },
  computed: {
    descr() {
      if (this.variant === 'NearToDelivery') return 'youCanSave';
      if (this.variant === 'FarFromDelivery') return 'noDeliveryFar';
      return 'noDelivery';
    },
    orangeBtn() {
      if (this.variant === 'NearToDelivery') return 'typeNew';
      if (this.variant === 'FarFromDelivery') return 'typeNew';
      return 'setNew';
    },
    grayBtn() {
      if (this.variant === 'NearToDelivery') return 'saveAnyway';
      if (this.variant === 'FarFromDelivery') return 'goToMain';
      return 'chooseAnother';
    },
  },
  mounted() {
    this.$emit('onMount');
  },
  i18n: {
    messages: {
      ru: {
        notDeliverYet: '😟 Сюда пока не доставляем',

        noDelivery: 'К сожалению, мы пока не доставляем по&nbsp;этому адресу',
        noDeliveryFar: `
          К сожалению, мы пока не доставляем по&nbsp;этому адресу,
          но&nbsp;постоянно работаем над&nbsp;расширением зоны доставки
        `,
        youCanSave: `Сохраните этот адрес, 
          и&nbsp;мы уведомим вас, 
          как&nbsp;только доставка 
          по&nbsp;этому адресу станет доступна
       `,

        typeNew: 'Ввести другой адрес',
        saveAnyway: 'Сохранить этот адрес',
        goToMain: 'Вернуться на главную',
        chooseAnother: 'Выбрать другой адрес',
        setNew: 'Указать новый адрес',
      },
      kk: {
        notDeliverYet: '😟 Бұл жерге әлі жеткізбейміз',

        noDelivery: 'Өкінішке орай бұл мекенжайға әлі жеткізбейміз',
        noDeliveryFar: `
          Өкінішке орай бұл мекенжайға әлі жеткізбейміз,
          бірақ әрдайым жеткізу аймағын ұлғайту үшін жұмыс істеудуміз
        `,
        youCanSave: `Мекенжайыңызды сақтап қойсаңыз болады,
        сіздің мекенжай бойынша жеткізу болғанда, сізге хабарын беретін боламыз`,

        typeNew: 'Басқа мекенжай енгізу',
        saveAnyway: 'Мекенжайды сақтау',
        goToMain: 'Бастапқы бетке қайту',
        chooseAnother: 'Басқа мекенжай таңдау',
        setNew: 'Жаңа мекенжай таңдау',
      },
    },
  },
};
</script>

<style scoped>
.NoDelivery {
  @apply pt-1;
}
.NoDelivery__title {
  @apply mb-2 text-lg font-bold leading-22;
}
.NoDelivery__desc {
  @apply mb-8 leading-5;
}
.--gray .NoDelivery__desc {
  @apply mb-6 text-sirius-gray-900;
}

.NoDelivery__btns {
  @apply flex flex-col space-y-3 
  md:flex-row md:space-y-0 md:space-x-3;
}
.--gray .NoDelivery__btns {
  margin-bottom: 8px;
  max-width: 412px;
}

.__modal .NoDelivery__title {
  @apply md:mb-6 md:text-28 md:leading-8;
}
.__modal .NoDelivery__desc {
  @apply md:mb-9 md:text-lg md:leading-6;
}
.__modal .NoDelivery__btns {
  @apply md:flex-row-reverse md:space-x-0;
}
.__modal .NoDelivery__btn:first-child {
  @apply md:ml-3;
}
</style>
