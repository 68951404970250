import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const fingerprint = uuidv4();

export const client = axios.create({
  baseURL: import.meta.env.VITE_API_PROXY,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: v => JSON.stringify(v),
  transformRequest: v => JSON.stringify(v),
});

client.interceptors.request.use(config => {
  config.headers['X-Idempotency-key'] = uuidv4();
  config.headers['X-Fingerprint'] = fingerprint;
  return config;
});
