/**
 * Шафлит продукты, каждый день возвращяет разный порядок списка продуктов
 * @param {Array} products
 * @returns
 */
export const shuffleProducts = products => {
  let day = new Date().getDay();
  day = day === 0 ? 7 : day;
  products = products
    .map(e => {
      const mod = (e.id % 7) + 1;
      return {
        ...e,
        mod,
      };
    })
    .sort((a, b) => a.mod - b.mod || b.price - a.price);

  const dayIndex = [...products].findIndex(e => e.mod === day);
  if (dayIndex === -1) return products;
  const after = [...products].splice(dayIndex, [...products].length - 1);
  const before = [...products].splice(0, dayIndex);
  const sorted = [...after, ...before];
  return sorted;
};
