<template>
  <transition name="fade">
    <div
      v-show="showMe"
      class="flex font-medium text-sirius-black-100 rounded-2xl bg-sirius-red-100 cursor-pointer"
      :class="[lessInfo ? 'items-center px-4 py-3' : 'items-start p-4 pt-3']"
      @click="openModal"
    >
      <div class="mr-2">
        <img
          v-if="lessInfo"
          src="@img/icons/lost-goods-smile.png"
          width="24"
          height="24"
          alt=""
        />
        <img
          v-else
          src="@img/icons/lost-goods-smile.png"
          width="28"
          height="30"
          alt=""
        />
      </div>
      <div class="mr-2 pt-1">
        <p
          class="font-bold leading-tight"
          :class="lessInfo ? 'text-sm' : 'text-lg'"
        >
          {{ $t('someItemsAreOutOfStock') }}
        </p>
        <p v-if="!lessInfo" class="leading-tight">
          {{ $t('pleaseEditOrder') }}
        </p>
      </div>
      <div class="ml-auto text-sirius-gray-100 hover:text-sirius-black-100">
        <svg
          class="cursor-pointer fill-current"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click.stop="showMe = false"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.9999 13.4142L17.6566 19.071L19.0708 17.6568L13.4141 12L19.0711 6.34305L17.6569 4.92883L11.9999 10.5858L6.34292 4.92883L4.92871 6.34305L10.5857 12L4.92897 17.6568L6.34318 19.071L11.9999 13.4142Z"
          />
        </svg>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LostGoodsReminder',
  props: {
    lessInfo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showMe: false,
  }),
  computed: {
    ...mapGetters('cart', ['cartNoItemsSize']),
  },
  watch: {
    showMe() {
      this.$emit('showMe', this.showMe);
    },
    cartNoItemsSize() {
      this.checkUpdates();
    },
  },
  created() {
    this.checkUpdates();
  },
  methods: {
    checkUpdates() {
      if (this.cartNoItemsSize !== 0) this.showMe = true;
      else this.showMe = false;
    },
    openModal() {
      this.$eventBus.emit('eb_open_lost_goods_modal');
    },
  },
  i18n: {
    messages: {
      ru: {
        someItemsAreOutOfStock: 'Некоторые товары закончились',
        pleaseEditOrder: 'Пожалуйста, откорректируйте заказ',
      },
      kk: {
        someItemsAreOutOfStock: 'Кейбір тауарлар таусылып қалды',
        pleaseEditOrder: 'Тапсырысты түзетуіңізді сұраймыз',
      },
    },
  },
};
</script>
