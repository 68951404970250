<template>
  <div class="WebMobileHeaderBase header">
    <div class="header-left">
      <slot name="left"> </slot>
    </div>

    <div class="header-center">
      <slot>
        <LogoDesk class="m-auto" />
      </slot>
    </div>

    <div class="header-right">
      <slot name="right"> </slot>
    </div>
  </div>
</template>

<script>
import LogoDesk from '@components/header/desk-header/components/LogoDesk.vue';

export default {
  name: 'WebMobileHeaderBase',
  components: {
    LogoDesk,
  },
};
</script>

<style scoped>
.header {
  @apply w-full py-4 px-4
    flex items-center justify-between space-x-2
    bg-white border-sirius-gray-300;
}
.header-center {
  @apply w-full;
}
</style>
