<template>
  <div class="AddressConfirmationPopover">
    <div class="AddressConfirmationPopover__container">
      <p class="AddressConfirmationPopover__q">
        {{ $t('q') }}
      </p>
      <p class="AddressConfirmationPopover__presumptiveAddress">
        {{ presumptiveAddress }}
      </p>
      <div class="AddressConfirmationPopover__buttonsWrap">
        <UiButton size="M" variant="gray" @click="$emit('onAnotherAddress')">
          {{ $t('noAnother') }}
        </UiButton>
        <UiButton size="M" variant="primary" @click="$emit('onConfirm')">
          {{ $t('yes') }}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@/pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'AddressConfirmationPopover',
  components: { UiButton },
  props: {
    presumptiveAddress: {
      type: String,
      required: true,
    },
  },
  i18n: {
    messages: {
      kk: {},
      ru: {
        q: 'Закажете на этот адрес?',
        yes: 'Да',
        noAnother: 'Нет, на другой',
      },
    },
  },
};
</script>

<style scoped>
.AddressConfirmationPopover {
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.01),
    0 2px 6px 0 rgba(0, 0, 0, 0.01),
    0 6px 12px 0 rgba(0, 0, 0, 0.02),
    0 -3px 21px 0 rgba(0, 0, 0, 0.06),
    0 3px 38px 0 rgba(0, 0, 0, 0.03),
    0 92px 92px 0 rgba(0, 0, 0, 0.05);
  background: #fff;
  border-radius: 0 0 24px 24px;
}

.AddressConfirmationPopover__container {
  padding: 24px 16px;
}

.AddressConfirmationPopover__q {
  font-weight: 500;
  color: #767485;
  font-size: 16px;
  line-height: 20px;
}

.AddressConfirmationPopover__presumptiveAddress {
  margin-top: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #242424;
}

.AddressConfirmationPopover__buttonsWrap {
  padding-top: 24px;
  font-weight: 700;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
</style>
