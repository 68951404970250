<template>
  <UiButtonNew
    class="ExpandButton"
    :size="$isDesktop ? 'xl' : 'lg'"
    variant="gray"
    v-on="$listeners"
  >
    {{ $i18n.locale === 'kk' ? 'Тізімді толық көру' : 'Раскрыть весь список' }}
    <IconArrowDown class="ml-2" />
  </UiButtonNew>
</template>

<script>
import UiButtonNew from '@ui/UiButtonNew.vue';
import IconArrowDown from '@/views/ny-2023-contest/components/ContestTable/components/ExpandButton/components/IconArrowDown/IconArrowDown.vue';

export default {
  name: 'ExpandButton',
  components: { UiButtonNew, IconArrowDown },
};
</script>

<style scoped>
.ExpandButton {
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .ExpandButton {
    font-size: 20px;
    line-height: 24px;
    border-radius: 24px;
  }
}
</style>
