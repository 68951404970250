<template>
  <UiBottomSheetWrapper
    class="extended-time"
    :show="show"
    with-title
    with-back-button
    @change="
      $event => {
        if (!$event) $emit('close');
      }
    "
  >
    <template #title>{{ $t('showFirst') }}</template>
    <div class="SortingPopup__content">
      <UiRadio
        v-model="localSelectedSort"
        :radios="sortOptions"
        value-id="order"
      />
      <div class="SortingPopup__buttonsWrap">
        <UiButton
          v-if="selected !== 'popular_asc'"
          key="reset"
          class="SortingPopup__btn"
          size="LG"
          variant="gray"
          @click="onResetClick"
        >
          {{ $t('reset') }}
        </UiButton>
        <UiButton
          key="apply"
          class="SortingPopup__btn"
          size="LG"
          @click="onSubmitClick"
        >
          {{ $t('apply') }}
        </UiButton>
      </div>
    </div>
  </UiBottomSheetWrapper>
</template>

<script>
import { sortOptions } from '@/views/category/utils/index.js';
import UiRadio from '@/pure-ui/components/UiRadio/UiRadio.vue';
import UiButton from '@/pure-ui/components/UiButton/UiButton.vue';
import UiBottomSheetWrapper from '@/pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';

export default {
  name: 'SortingPopup',
  components: {
    UiBottomSheetWrapper,
    UiRadio,
    UiButton,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    show: Boolean,
    selected: {
      type: String,
      default: sortOptions[0].order,
    },
  },
  data: () => ({
    localSelectedSort: sortOptions[0],
  }),
  computed: {
    sortOptions() {
      const lc = this.$i18n.locale;
      return sortOptions.map(v => ({ ...v, title: v[`title.${lc}`] }));
    },
  },
  watch: {
    show(v) {
      if (!v) {
        this.localSelectedSort = sortOptions.find(
          o => o.order === this.selected
        );
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onSubmitClick() {
      this.$emit('update:modelValue', this.localSelectedSort);
      this.close();
    },
    onResetClick() {
      this.localSelectedSort = sortOptions[0];
      this.onSubmitClick();
    },
  },
  i18n: {
    messages: {
      kk: {
        showFirst: 'Бірінші көрсету',
        done: 'Дайын',
        reset: 'Тазарту',
        apply: 'Қолдану',
      },
      ru: {
        showFirst: 'Показывать сначала',
        done: 'Готово',
        reset: 'Сбросить',
        apply: 'Применить',
      },
    },
  },
};
</script>

<style scoped>
.SortingPopup__content {
  padding-top: 16px;
}

.SortingPopup__buttonsWrap {
  padding-top: 36px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 8px;
}
</style>
