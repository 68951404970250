<template>
  <div class="MBlockDefault flex pt-3 pb-4" v-on="$listeners">
    <UiInlineIcon name="promocode" />
    <span class="ml-3 mr-auto">
      <!-- У меня есть промокод -->
      {{ $t('iHavePromocode') }}
    </span>
    <UiInlineIcon
      class="MBlockDefault__iconArr"
      name="arrow-right"
      style="color: #a4a2b7"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MBlockDefault',
  components: {
    UiInlineIcon,
  },
  i18n: {
    messages: {
      ru: {
        iHavePromocode: 'У меня есть промокод',
      },
      kk: {
        iHavePromocode: 'Менде промокод бар',
      },
    },
  },
};
</script>

<style scoped>
.MBlockDefault__iconArr {
  margin-right: -6px;
}
</style>
