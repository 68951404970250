<template>
  <form v-bind="$attrs" class="AddressForm" @submit.prevent="onSubmit">
    <UiTextField
      v-if="!$isDesktop"
      v-model="v$.name.$model"
      :size="$isDesktop ? 'M' : 'md'"
      :label="$t('addressName')"
      class="AddressForm__input AddressForm__name"
      name="name"
      type="text"
      :invalid="v$.name.$error"
      @onFocus="$emit('onFocus')"
    />

    <div class="AddressForm__private">
      <p>{{ $t('privateBuilding') }}</p>
      <UiToggleSwitch v-model="isPrivate" />
    </div>

    <template v-if="!isPrivate">
      <!-- Квартира/офис -->
      <UiTextField
        v-model="v$.flat.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="AddressForm__input"
        :error-message="$t('requiredField')"
        :label="$t($isDesktop ? 'flatOfficeDesk' : 'flatOffice')"
        name="flat"
        type="text"
        is-required
        maxlength="10"
        clearable
        clear-btn="sm"
        :invalid="v$.flat.$error"
        @onFocus="$emit('onFocus')"
      />
      <!-- Подъезд -->
      <UiTextField
        v-model="v$.entrance.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="AddressForm__input"
        :error-message="$t('requiredField')"
        :label="$t('entrance')"
        name="entrance"
        type="text"
        is-required
        maxlength="5"
        clearable
        clear-btn="sm"
        transform-uppercase
        transform-trim
        :transform-regexp="/[^A-Za-z0-9\\\/]/g"
        :invalid="v$.entrance.$error"
        @onFocus="$emit('onFocus')"
      />

      <!-- Этаж -->
      <UiTextField
        v-model="v$.floor.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="AddressForm__input"
        :error-message="
          v$.floor.required.$invalid
            ? $t('requiredField')
            : v$.floor.validateFloor.$invalid
              ? $t('floorError')
              : ''
        "
        :label="$t('floor')"
        name="floor"
        type="number"
        is-required
        maxlength="2"
        min="-5"
        max="88"
        clearable
        clear-btn="sm"
        :invalid="v$.floor.$error"
        @onFocus="$emit('onFocus')"
      />

      <!-- Код домофона -->
      <UiTextField
        v-model="v$.doorbell.$model"
        :size="$isDesktop ? 'M' : 'md'"
        class="AddressForm__input"
        :error-message="$t('requiredField')"
        :label="$t($isDesktop ? 'doorbellDesk' : 'doorbell')"
        name="doorbell"
        maxlength="60"
        type="text"
        :invalid="v$.doorbell.$error"
        @onFocus="$emit('onFocus')"
      />
    </template>

    <UiTextarea
      v-model="v$.comment.$model"
      class="AddressForm__input AddressForm__comment"
      :size="$isDesktop ? 'M' : 'md'"
      name="message"
      :label="$t('commentForCourier')"
      maxlength="460"
      :rows="2"
      no-resize
      @onFocus="$emit('onFocus')"
    />

    <UiTextField
      v-if="$isDesktop"
      v-model="v$.name.$model"
      :size="$isDesktop ? 'M' : 'md'"
      :label="$t('addressName')"
      class="AddressForm__input AddressForm__name"
      name="name"
      type="text"
      :invalid="v$.name.$error"
      @onFocus="$emit('onFocus')"
    />
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import UiToggleSwitch from '@pure-ui/components/UiToggleSwitch/UiToggleSwitch.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiTextarea from '@pure-ui/components/UiTextArea/UiTextArea.vue';

export default {
  name: 'AddressForm',
  components: {
    UiToggleSwitch,
    UiTextField,
    UiTextarea,
  },
  props: {
    state: {
      type: Object,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      flat: '',
      entrance: '',
      floor: '',
      comment: '',
      doorbell: '',
      name: '',
      isPrivate: false,
    };
  },
  computed: {
    isFormValid() {
      if (this.isPrivate) return true;

      return [
        this.v$.flat?.$model,
        this.v$.entrance?.$model,
        this.v$.floor?.$model,
        !this.v$.$invalid,
      ].every(v => !!v);
    },
  },
  watch: {
    state: {
      deep: true,
      handler() {
        this.initState();
      },
    },
    isFormValid: {
      immediate: true,
      handler(v) {
        this.$emit('onValidationChange', v);
      },
    },
  },
  validations: {
    flat: {
      required,
    },
    entrance: {
      required,
    },
    floor: {
      required,
      validateFloor: v => {
        const intgr = parseInt(v, 10);
        const regexpPass = /^-?\d+$/.test(v);
        const valPass = intgr >= -5 && intgr <= 88;
        return regexpPass && valPass;
      },
    },
    doorbell: {},
    comment: {},
    name: {},
  },
  mounted() {
    if (this.state) {
      this.$nextTick(() => {
        this.initState();
      });
    }
  },
  methods: {
    initState() {
      const toString = x => (x !== null && x !== undefined ? x.toString() : '');
      this.flat = toString(this.state.flat_number);
      this.entrance = toString(this.state.entrance);
      this.floor = toString(this.state.floor);
      this.comment = toString(this.state.comment);
      this.doorbell = toString(this.state.domofon_code);
      this.name = toString(this.state.name);
      this.isPrivate = Boolean(this.state.is_private_home);
    },
    onSubmit() {
      this.v$.$touch();

      if ((this.v$.$invalid || this.v$.floor.$error) && !this.isPrivate) {
        this.$emit('onFillAll');
        // this.toast.show(this.$t('fillAll'));
        return;
      }

      this.$emit('onSubmit', {
        entrance: this.entrance,
        flat_number: this.flat,
        floor: this.floor,
        comment: this.comment,
        domofon_code: this.doorbell,
        name: this.name,
        is_private_home: this.isPrivate,
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        privateBuilding: 'Частный дом',
        requiredField: 'Обязательное поле',
        floorError: 'Этаж от -5 до 88',
        flatOffice: 'Кв/офис',
        flatOfficeDesk: 'Кв/офис',
        entrance: 'Подъезд',
        floor: 'Этаж',
        doorbell: 'Код домофона',
        doorbellDesk: 'Домофон',
        commentForCourier: 'Комментарий для курьера',
        fillAll: 'Заполните обязательные поля: квартира, подъезд и этаж',
        enterEntranceNumber: 'Введите номер подьезда в цифрах',
        addressName: 'Название адреса, например «Дом»',
        tooLong: 'Введите корректные данные',
      },
      kk: {
        privateBuilding: 'Жер үй',
        requiredField: 'Міндетті жол',
        floorError: '-5-тен 88-ге дейін',
        flatOffice: 'Пәтер, кеңсе',
        flatOfficeDesk: 'Пт/кеңсе',
        entrance: 'Кіреберіс',
        floor: 'Қабат',
        doorbell: 'Домофон коды',
        doorbellDesk: 'Домофон',
        commentForCourier: 'Курьерге комментарий',
        fillAll:
          'Барлық міндетті жолақтарды толтырыңыз: пәтер, кіреберіс ж/ә қабат',
        enterEntranceNumber: 'Кіреберіс нөмірін сандармен енгізіңіз',
        addressName: 'Мекен-жай аты, мысалға "Уй"',
        tooLong: 'Дұрыс ақпарат толтырыңыз',
      },
    },
  },
};
</script>

<style scoped>
.AddressForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.AddressForm__input {
  @apply w-full;
}
.AddressForm__private {
  @apply pt-1 pb-2 w-full 
  flex items-center justify-between
  font-medium leading-5;
}

.AddressForm__name,
.AddressForm__private,
.AddressForm__comment {
  grid-column: 1 / 3;
}

@media (min-width: 768px) {
  .AddressForm {
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
  }

  .AddressForm__name,
  .AddressForm__private,
  .AddressForm__comment {
    grid-column: 1 / 5;
  }
}
</style>
