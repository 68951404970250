<script>
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
export default {
  name: 'ExpandableTransition',
  render(h) {
    return h(
      'transition',
      {
        props: {
          name: 'expand',
        },
        on: {
          afterEnter,
          leave,
          enter,
        },
      },
      this.$slots.default
    );
  },
};

function forceRepaint(element) {
  // eslint-disable-next-line no-unused-expressions
  getComputedStyle(element).height;
}

function afterEnter(element) {
  element.style.height = 'auto';
}

function enter(element) {
  const { width } = getComputedStyle(element);

  element.style.width = width;
  element.style.position = 'absolute';
  element.style.visibility = 'hidden';
  element.style.height = 'auto';

  const { height } = getComputedStyle(element);

  element.style.removeProperty('width');
  element.style.position = 'static';
  element.style.visibility = 'visible';
  element.style.height = 0;

  forceRepaint(element);
  requestAnimationFrame(() => (element.style.height = height));
}

function leave(element) {
  const { height } = getComputedStyle(element);

  element.style.height = height;
  forceRepaint(element);
  requestAnimationFrame(() => (element.style.height = 0));
}
</script>

<style lang="postcss">
.expand-enter-active,
.expand-leave-active {
  @apply overflow-hidden;
  transition-property: height;
}

.expand-enter,
.expand-leave-to {
  @apply h-0;
}
</style>
