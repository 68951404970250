<template>
  <div class="TellMoreModal">
    <UiBottomSheetWrapper
      :show="modelValue"
      with-back-button
      with-title
      @change="onChange"
    >
      <template #title> {{ name }} </template>

      <div class="modal">
        <UiTextArea
          v-model="innerComment"
          class="modal-comment"
          rows="4"
          no-resize
          autofocus
          :label="$t('tellMore')"
        />

        <div
          v-if="callMe"
          class="modal-callme"
          @click="innerChecked = !innerChecked"
        >
          <p v-html="$t('callMe')"></p>
          <UiInlineIcon
            class="modal-check"
            :name="innerChecked ? 'review-check' : 'review-uncheck'"
          />
        </div>

        <UiButton size="LG" class="modal-btn" @click="onReady">
          {{ $t('ready') }}
        </UiButton>
      </div>
    </UiBottomSheetWrapper>
  </div>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiTextArea from '@pure-ui/components/UiTextArea/UiTextArea.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'TellMoreModal',
  components: {
    UiBottomSheetWrapper,
    UiInlineIcon,
    UiTextArea,
    UiButton,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      required: true,
    },
    callMe: {
      type: Boolean,
      default: false,
    },
    comment: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerChecked: false,
    innerComment: '',
  }),
  watch: {
    checked: {
      immediate: true,
      handler() {
        this.update();
      },
    },
    comment: {
      immediate: true,
      handler() {
        this.update();
      },
    },
  },
  mounted() {
    this.preventKeyboardCloseOnChecking();
  },
  methods: {
    preventKeyboardCloseOnChecking() {
      const btn = document.querySelector('.modal-callme');
      if (btn) {
        btn.addEventListener('touchend', e => {
          e.preventDefault();
        });
      }
    },
    update() {
      this.innerChecked = this.checked;
      this.innerComment = this.comment;
    },
    onChange(event) {
      this.$emit('update:modelValue', event);
      if (!event) {
        this.$emit('onReady', this.innerComment, this.innerChecked);
      }
      this.update();
    },
    onReady() {
      this.$emit('update:modelValue', false);
      this.$emit('onReady', this.innerComment, this.innerChecked);
    },
  },
  i18n: {
    messages: {
      kk: {
        tellMore: 'Толығырақ айтсаңыз',
        ready: 'Дайын',
        callMe: 'Менімін осы мәселе бойынша хабарласқандарыңызды сұраймын',
      },
      ru: {
        tellMore: 'Расскажите подробнее',
        ready: 'Готово',
        callMe: 'Я хочу, чтобы со&nbsp;мной связались по&nbsp;этой проблеме',
      },
    },
  },
};
</script>

<style scoped>
.modal {
  @apply mt-3;
}
.modal-comment {
  @apply mt-3;
}
.modal-btn {
  @apply mt-6;
}
.modal-callme {
  @apply mt-4 pb-2 w-full flex justify-between space-x-2
    leading-5;
}
.modal-check {
  @apply min-w-8 h-8 flex justify-center;
}
</style>
