<template>
  <NotFoundProducts v-if="isEmpty" />
  <div v-else>
    <section v-for="rec in recipes" :key="rec.id">
      <h2
        class="truncate dark:bg-night text-xl z-10 font-bold leading-tight dark:text-white sticky pl-4 pr-24 pt-3 pb-2 bg-gray-400 category-name"
        :style="{ top: stickyCategoryPos === 'toolbar' ? '3.4rem' : '0' }"
      >
        {{ rec.name }}
      </h2>
      <div class="mt-3 flex flex-col px-4 pb-2">
        <RecipeCard
          v-for="prod in rec.products"
          :key="uniqueKey(prod.id, prod.category_id)"
          class="mb-2"
          :recipe="prod"
        />
      </div>
    </section>
    <UpButton
      v-if="upButton"
      :style="{ top: stickyCategoryPos === 'toolbar' ? '4rem' : '0.5rem' }"
      @click="goUp"
    />
  </div>
</template>

<script>
import NotFoundProducts from '@shared/components/product/NotFoundProducts.vue';
import UpButton from '@shared/components/product/UpButton.vue';
import RecipeCard from './RecipeCard.vue';

export default {
  name: 'RecipeSearchResult',
  components: {
    NotFoundProducts,
    RecipeCard,
    UpButton,
  },
  props: {
    recipes: {
      type: Object,
      default: () => ({}),
    },
    stickyCategoryPos: {
      type: String,
      default: 'toolbar',
    },
    productDeeplink: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      upButton: false,
    };
  },
  computed: {
    isEmpty() {
      return Object.keys(this.recipes).length === 0;
    },
  },
  mounted() {
    this.handleScroll();
  },
  methods: {
    uniqueKey: (id, categoryId) => {
      return `${id}_${categoryId}`;
    },
    goUp() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll() {
      const firstCategoryName =
        document.getElementsByClassName('category-name')[0];
      if (firstCategoryName) {
        window.addEventListener('scroll', () => {
          const { y } = firstCategoryName.getBoundingClientRect();
          let top = 0;
          if (this.stickyCategoryPos === 'toolbar') {
            top = 56;
          }
          if (this.stickyCategoryPos === 'top') {
            top = 0;
          }

          if (y > top) {
            this.upButton = false;
          } else if (y <= top) {
            this.upButton = true;
          }
        });
      }
    },
  },
};
</script>
