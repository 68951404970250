<template>
  <ul class="LinkItems">
    <li v-for="link in links" :key="link.kk" class="LinkItems__item">
      <a
        href="#"
        class="LinkItems__itemLink"
        @click.prevent="$emit('onLinkClick', link.scrollTo)"
      >
        {{ link[$i18n.locale] }}
        <ItemIcon class="LinkItems__itemIcon" />
      </a>
    </li>
  </ul>
</template>

<script>
import { menuItems } from '@/views/micromarket/utils/constants';
import ItemIcon from './ItemIcon.vue';

export default {
  name: 'LinkItems',
  components: { ItemIcon },
  inject: [],
  props: {},
  data: () => ({ links: menuItems }),

  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.LinkItems {
}

.LinkItems__item {
}

.LinkItems__itemLink {
  display: block;
  width: 100%;
  position: relative;
  padding: 12px 0;
}

.LinkItems__itemIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
</style>
