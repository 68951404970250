import { Centrifuge } from 'centrifuge';
import { DevConfigs } from '@/utils/dev-config';
import { $log } from '@/utils/plugins/logger';

function notify(listeners, data) {
  listeners.forEach(listener => {
    try {
      listener(data);
    } catch (err) {
      if (DevConfigs.isDev) console.error(err);
    }
  });
}

export class Socket {
  constructor(name) {
    this.listenersMap = {};
    this.socket = null;
    this.channel = '';
    this.name = name;
  }

  getListeners(msg) {
    return this.listenersMap[msg] || [];
  }

  on(msg, listener) {
    $log(`ON ${this.channel} - ${this.name} - ${msg}`, { listener }, 'WS');
    this.listenersMap[msg] = [...this.getListeners(msg), listener];
  }

  off(msg, listener) {
    $log(`OFF ${this.channel} - ${this.name} - ${msg}`, { listener }, 'WS');
    const updated = this.getListeners(msg).filter(f => f !== listener);
    this.listenersMap[msg] = updated;
  }

  emit(msg, data) {
    $log(`EMIT ${this.channel} - ${this.name} - ${msg}`, { data }, 'WS');
    notify(this.getListeners(msg), data);
  }

  onDisconnect(context) {
    this.emit('disconnect', context);
  }

  disconnect() {
    if (this.socket !== null) {
      this.socket.disconnect();
    }
  }

  onError(err) {
    this.emit('error', err);
  }

  onConnect(channel) {
    const oldSub = this.socket.getSubscription(channel);
    $log(`${this.channel} - ${this.name} connected`, { oldSub }, 'WS');
    if (oldSub) this.socket.removeSubscription(oldSub);

    const sub = this.socket.newSubscription(channel);
    sub.on('publication', ({ data }) => {
      this.emit(data.type, data.data);
    });
    sub.subscribe();
  }

  connect(url, token, channel) {
    this.channel = channel;
    this.socket = new Centrifuge(url);
    this.socket.setToken(token);
    $log(`${this.channel} - ${this.name} connect`, null, 'WS');
    this.socket.on('disconnected', context => this.onDisconnect(context));
    this.socket.on('error', err => this.onError(err));
    this.socket.on('connected', () => this.onConnect(channel));
    this.socket.connect();
  }
}
