import { INITIAL_COORDS } from '../constants';

/**
 * Добавляет в DOM невидимую карту для yandex api
 */
function createHiddenMapElement() {
  if (document.getElementById('hiddenMap')) return;
  const map = document.createElement('div');
  map.className = 'hidden hidden w-full h-screen';
  map.id = 'hiddenMap';
  document.body.appendChild(map);
}

// function isInPolygons(coords) {}
function createMapPolygon(ymaps, map, polygonCoords) {
  let mapPolygons = polygonCoords.map(c => new ymaps.geometry.Polygon(c));
  mapPolygons.forEach(mp => {
    mp.options.setParent(map.options);
    mp.setMap(map);
  });
  return mapPolygons;
}

function hasCoordsInPolygon(polygons, coords) {
  return polygons.some(elem => elem.contains(coords));
}

export function isCoordsInDeliveryZone(ymaps, coords, polygonCoords) {
  createHiddenMapElement();
  const hiddenMap = new ymaps.Map('hiddenMap', {
    center: INITIAL_COORDS,
    zoom: 7,
  });
  const polygons = createMapPolygon(ymaps, hiddenMap, polygonCoords);
  let result = hasCoordsInPolygon(polygons, coords);
  hiddenMap.destroy();
  return result;
}
