<template>
  <div>
    <div
      v-for="(catGroup, key) in categories"
      :key="key"
      class="contains mb-7 md:mb-16 last:mb-0"
    >
      <div>
        <UiNavigationTitle class="px-4 md:px-0" main>
          {{ key }}
        </UiNavigationTitle>

        <div
          class="px-4 md:px-0 mt-3 md:mt-5 gap-2 md:gap-3"
          style="display: grid; grid-template-columns: repeat(6, 1fr)"
        >
          <CategoryCard
            v-for="item in catGroup.categories"
            :key="item.id"
            from="catalog"
            :category="item"
            :grid="calcGrid(catGroup, item)"
          />
        </div>
      </div>
      <slot name="after-group" :group="catGroup" />
    </div>
  </div>
</template>

<script setup>
import UiNavigationTitle from '@/shared/components/ui/UiNavigationTitle.vue';
import CategoryCard from '@/views/main/components/CategoryCard.vue';

defineProps({
  categories: {
    type: Object,
    default: () => ({}),
  },
});

function calcGrid(parentCategory, category) {
  const sizes = {
    1: 6,
    '1/2': 3,
    '1/3': 2,
    '2/3': 4,
  };
  let result = '1/7';
  if (parentCategory.block_size) {
    const add = parentCategory.block_size + sizes[category.block_size];
    if (add > 6) {
      parentCategory.block_size = sizes[category.block_size];
      result = `1/${sizes[category.block_size] + 1}`;
    } else {
      result = `${parentCategory.block_size + 1}/${add + 1}`;
      parentCategory.block_size = add;
    }
  } else {
    parentCategory.block_size = sizes[category.block_size];
    result = `1/${parentCategory.block_size + 1}`;
  }
  return result;
}
</script>
