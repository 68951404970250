import { store } from '@/store';

const isPolygonWithCoffee = () =>
  store.getters['delivery/warehouse']?.is_coffee_available;

/**
 * Для теста, пока, договорились, что заказать можно только если адрес
 * Байзакова 280 и этаж 5
 */
const isAddressWithCoffee = () =>
  store.getters['user/deliveryAddress']?.floor === 5;

export function isCoffeeAvailable() {
  return isPolygonWithCoffee() && isAddressWithCoffee();
}
