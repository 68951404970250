/**
 *
 * @param n 1
 * @param textForms ['день', 'дня', 'дней']
 * @returns {*}
 */
export function pluralize(n, textForms) {
  n = Math.abs(n) % 100;
  const n1 = n % 10;

  if (n > 10 && n < 20) {
    return textForms[2];
  }

  if (n1 > 1 && n1 < 5) {
    return textForms[1];
  }

  return n1 === 1 ? textForms[0] : textForms[2];
}
