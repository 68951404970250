<template>
  <UiBottomSheet
    :show="show"
    :disable-touch-on-content="disableTouchOnContent"
    @change="v => $emit('change', v)"
    @onOpen="$emit('onOpen')"
    @onClose="$emit('onClose')"
  >
    <div class="UiBottomSheetWrapper bottom-sheet-wrapper">
      <div v-if="withTitle" class="bottom-sheet-wrapper-white-space"></div>
      <div
        v-if="header"
        class="bottom-sheet-wrapper-header"
        :class="{ 'bottom-sheet-wrapper-header__title': withTitle }"
      >
        <slot name="left">
          <UiInlineIcon
            v-if="withBackButton"
            name="bottomsheet-back-arrow"
            class="cursor-pointer"
            @click.native="onBackClick"
          />
        </slot>

        <div v-if="withTitle" class="bottom-sheet-wrapper-title" @click.stop>
          <slot name="title"></slot>
        </div>

        <slot name="right">
          <div v-if="withBackButton" class="w-6 h-6" @click.stop></div>
        </slot>
      </div>

      <div
        class="bottom-sheet-wrapper-content"
        :class="{
          '--remove-padding': removePadding,
          '--with-buttons': withButtons,
        }"
      >
        <slot></slot>
      </div>

      <div v-if="withButtons" class="bottom-sheet-wrapper-buttons">
        <slot name="buttons"> </slot>
      </div>
    </div>
  </UiBottomSheet>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiBottomSheet from '@pure-ui/components/UiBottomSheet/UiBottomSheet.vue';

export default {
  name: 'UiBottomSheetWrapper',
  components: { UiInlineIcon, UiBottomSheet },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    withBackButton: {
      type: Boolean,
      default: false,
    },
    withButtons: {
      type: Boolean,
      default: false,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    removePadding: {
      type: Boolean,
      default: false,
    },
    disableTouchOnContent: {
      type: Boolean,
      default: false,
    },
    onBackCb: {
      type: Function,
      default: null,
    },
  },
  computed: {
    header() {
      return this.withBackButton || this.withTitle;
    },
    whiteSpace() {
      return this.withTitle;
    },
  },
  methods: {
    closeModal() {
      this.$emit('onArrowClick');
      this.$emit('change', false);
    },
    onBackClick() {
      this.$emit('onBack');
      if (this.onBackCb) this.onBackCb();
      else this.closeModal();
    },
  },
};
</script>

<style scoped>
.bottom-sheet-wrapper {
  @apply relative;
}
.bottom-sheet-wrapper-white-space {
  height: 64px;
  width: 100%;
  background-color: white;
}
.bottom-sheet-wrapper-header {
  @apply h-12 w-full top-6 left-0
    px-4
    fixed flex items-center 
    z-block;
}
.bottom-sheet-wrapper-header__title {
  @apply top-0 pt-6 pb-2 rounded-t-3xl;
  height: 64px;
  background-color: white;
}
.bottom-sheet-wrapper-content {
  @apply px-4 pb-4;
}
.--remove-padding {
  @apply px-0;
}
.--with-buttons {
  @apply pb-14;
}
.bottom-sheet-wrapper-title {
  @apply pt-1 pb-1 px-3 mx-auto text-center text-xl leading-6 font-bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bottom-sheet-wrapper-buttons {
  @apply px-4 pt-10 pb-4 fixed w-full flex z-modal bottom-0;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0) 0.51%,
    #fff 22.76%
  );
}
</style>
