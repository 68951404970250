<template>
  <div class="MPromocodeField">
    <component :is="component" v-bind="$props" v-on="$listeners" />
    <MPromocodeModal
      :open="open"
      :value="promocode"
      :error-message="modalErrorMessage"
      :free-delivery-not-applied="freeDeliveryNotApplied"
      @onSubmit="$emit('onSubmit', $event)"
      @onModalChange="$emit('onModalChange', $event)"
    />
  </div>
</template>

<script>
import MBlockDefault from './MBlockDefault.vue';
import MBlockSuccess from './MBlockSuccess.vue';
import MBlockError from './MBlockError.vue';
import MBlockFreeDelivery from './MBlockFreeDelivery.vue';
import MBlockFreeDeliveryNotApplied from './MBlockFreeDeliveryNotApplied.vue';

import MPromocodeModal from './MPromocodeModal.vue';

export { getState } from './states';

export default {
  name: 'MPromocodeField',
  components: { MPromocodeModal },
  props: {
    promocode: {
      type: String,
      default: '',
    },
    discount: {
      type: Number,
      default: 0,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    modalErrorMessage: {
      type: String,
      default: '',
    },
    freeDeliveryNotApplied: {
      type: Boolean,
      default: false,
    },
    freeDelivery: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    component() {
      if (this.discount) {
        return MBlockSuccess;
      }
      if (this.errorMessage) {
        return MBlockError;
      }
      if (this.freeDeliveryNotApplied) {
        return MBlockFreeDeliveryNotApplied;
      }
      if (this.freeDelivery) {
        return MBlockFreeDelivery;
      }
      return MBlockDefault;
    },
  },
};
</script>
