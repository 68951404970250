<template>
  <div class="SearchInput flex items-center justify-between w-full">
    <input
      ref="searchInput"
      :value="value"
      type="search"
      name="search"
      :placeholder="placeholderComp"
      :disabled="disabled"
      autocomplete="off"
      class="w-full px-2 searchPlaceholder appearance-none focus:outline-none bg-transparent font-normal"
      v-on="$listeners"
      @input="$emit('onInput', $event.target.value)"
    />
    <transition name="scale">
      <button
        v-if="value.length > 0"
        aria-label="Сбросить"
        class="transition ease-in duration-100 focus:outline-none active:opacity-50"
        style="--scale: 0.1"
        @click="$emit('onInput', '')"
      >
        <IconCancel class="text-sirius-black-100" width="32" height="32" />
      </button>
    </transition>
  </div>
</template>

<script>
import IconCancel from '@shared/components/icons/IconCancel.vue';

export default {
  name: 'SearchInput',
  components: {
    IconCancel,
  },
  model: {
    prop: 'value',
    event: 'onInput',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      deafult: false,
    },
  },
  computed: {
    placeholderComp() {
      return this.$t('findProducts');
    },
  },
  methods: {
    focusIt() {
      if (this.$refs.searchInput && this.$refs.searchInput.focus) {
        this.$refs.searchInput.focus();
      }
    },
    blurIt() {
      this.$refs.searchInput.blur();
    },
  },
  i18n: {
    messages: {
      ru: {
        findProducts: 'Найти продукты и товары',
      },
      kk: {
        findProducts: 'Өнімдер мен тауарларды іздеу',
      },
    },
  },
};
</script>

<style lang="postcss">
.searchPlaceholder {
  text-overflow: ellipsis;

  &::placeholder {
    @apply text-sirius-gray-300 opacity-100;
  }

  &:-ms-input-placeholder {
    @apply text-sirius-gray-300 opacity-100;
  }

  &::-ms-input-placeholder {
    @apply text-sirius-gray-300 opacity-100;
  }
}
</style>
