<template>
  <div
    class="AddressReminder"
    :class="$isWebView ? 'rounded-t-2xl' : 'rounded-2xl'"
    @click="onClick"
  >
    <UiInlineIcon name="marker" class="mr-2" />

    <div class="leading-tight">
      <p class="font-bold">
        {{
          reason !== 'address'
            ? $t('weDoNotDeliverHereYet')
            : $t('provideAddress')
        }}
      </p>
      <p class="text-sirius-gray-300 text-sm mt-0.5">
        {{
          reason !== 'address'
            ? $t('weWillBeNearYouSoon')
            : $t('toSeeCurrentProducts')
        }}
      </p>
    </div>
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'AddressReminder',
  components: { UiInlineIcon },
  props: {
    reason: {
      type: String,
      default: 'address',
    },
  },
  methods: {
    onClick() {
      this.$emit('reminderClick');
    },
  },
  i18n: {
    messages: {
      kk: {
        weDoNotDeliverHereYet: 'Бізде әзірге бұл жерге жеткізу жоқ',
        weWillBeNearYouSoon: 'Жақында сіз жақта да боламыз ❤️',
        provideAddress: 'Мекенжайды көрсетіңіз',
        toSeeCurrentProducts: 'Бұл қолжетімді тауарларды көру үшін қажет',
      },
      ru: {
        weDoNotDeliverHereYet: 'Мы пока сюда не доставляем',
        weWillBeNearYouSoon: 'Скоро будем и возле вас ❤️',
        provideAddress: 'Укажите адрес',
        toSeeCurrentProducts: 'Чтобы видеть актуальные товары',
      },
    },
  },
};
</script>

<style scoped>
.AddressReminder {
  @apply p-2 pb-6 md:pt-3 md:pr-7 md:pb-4 md:pl-5 
    mx-auto
    md:max-w-max
    flex items-start justify-center 
    text-white bg-sirius-black-100   
    transition ease-in duration-100 
    cursor-pointer;
}
</style>
