import { DeliveryConfigService } from '@services/delivery-config';
import { PUBLIC_MESSAGE_TYPES } from '@shared/services/ws/config';
import { publicWs } from '@shared/services/ws';
import { $log } from '@/utils/plugins/logger';
import { store } from '@/store';

function onUpdated(data) {
  const currentDeliveryType = store.getters['paidOrder/paidDeliveryType'];
  const config = data.find(e => e.type === currentDeliveryType);

  let highDeliveryCosts = null;
  if (config?.high_delivery_costs?.length) {
    highDeliveryCosts = config.high_delivery_costs;
  }

  $log('HighPriceService', highDeliveryCosts, 'highDeliveryCosts');

  DeliveryConfigService.setHighPricePaymentConfigs(highDeliveryCosts);
}
function init() {
  $log('HighPriceService', null, 'Init');
  publicWs.on(PUBLIC_MESSAGE_TYPES.HIGH_PRICE, onUpdated);
}

export const HighPriceService = {
  init,
};

// const ResponseSample = {
//   data: [
//     {
//       type: 4,
//       high_delivery_costs: [
//         {
//           price_to: 2999,
//           price_from: 0,
//           delivery_price: 248,
//         },
//         {
//           price_to: null,
//           price_from: 3000,
//           delivery_price: 0,
//         },
//       ],
//     },
//     {
//       type: 5,
//       high_delivery_costs: [
//         {
//           price_to: 1200,
//           price_from: 0,
//           delivery_price: 156,
//         },
//         {
//           price_to: null,
//           price_from: 1201,
//           delivery_price: 0,
//         },
//       ],
//     },
//     {
//       type: 5,
//       high_delivery_costs: [
//         {
//           price_to: 2999,
//           price_from: 0,
//           delivery_price: 217,
//         },
//         {
//           price_to: null,
//           price_from: 3000,
//           delivery_price: 0,
//         },
//       ],
//     },
//   ],
// };
