<template>
  <footer class="bg-sirius-gray-200 px-4 md:px-0 pt-8 md:pt-14">
    <div
      class="md:pt-1 mb-6 md:pb-10 container grid grid-cols-1 md:grid-cols-4 items-start border-b border-transparent md:border-sirius-gray-300"
    >
      <LogoDesk class="mb-6" />
      <ExpandableBlock
        :value="expanded"
        :name="'mainLinks'"
        @onExpand="onExpand"
      >
        <template #title>
          {{ $t('mainInfo') }}
        </template>
        <ListOfLinks :links="mainLinks" />
      </ExpandableBlock>

      <ExpandableBlock
        :value="expanded"
        :name="'vacancyLinks'"
        @onExpand="onExpand"
      >
        <template #title>
          <div>
            {{ $t('vacancies') }}
          </div>
        </template>
        <ListOfLinks :links="vacancyLinks" />
      </ExpandableBlock>

      <ExpandableBlock
        class="block md:hidden"
        :value="expanded"
        :name="'contactList'"
        @onExpand="onExpand"
      >
        <template #title>
          {{ $t('contacts') }}
        </template>
        <SupportButtons view="footerMob" />
      </ExpandableBlock>

      <ExpandableBlock
        class="block md:hidden"
        :value="expanded"
        :name="'policyLinks'"
        @onExpand="onExpand"
      >
        <template #title>
          {{ $t('privacy') }}
        </template>
        <div class="text-sirius-gray-700">
          <a
            :href="publicOfferLink"
            class="block hover:underline text-sm leading-4"
            target="_blank"
          >
            {{ $t('publicOffer') }}
          </a>
        </div>
      </ExpandableBlock>

      <div class="hidden md:block">
        <SupportButtons view="footerDesk" />

        <div class="mt-5 flex space-x-9">
          <a
            class="text-sirius-black-100 hover:text-sirius-orange-100 active:text-sirius-orange-300"
            href="https://www.instagram.com/ryadom.kz/"
            target="_blank"
          >
            <IconFooterInstagram
              class="w-9 h-9 flex items-center justify-center"
            />
          </a>
          <a
            class="text-sirius-black-100 hover:text-sirius-orange-100 active:text-sirius-orange-300"
            href="https://www.tiktok.com/@ryadom.kz"
            target="_blank"
          >
            <IconFooterTiktok
              class="w-9 h-9 flex items-center justify-center"
            />
          </a>
        </div>
      </div>
    </div>

    <div
      class="pb-10 md:pb-2 md:pt-3 container flex flex-col md:grid grid-cols-4"
    >
      <div class=""></div>
      <div class="hidden md:block">
        <a
          :href="publicOfferLink"
          class="block hover:underline text-sm leading-4"
          target="_blank"
        >
          {{ $t('publicOffer') }}
        </a>
        <p class="text-sirius-gray-700 text-sm mt-4 leading-4">
          © {{ $t('allRightReserved') }}
        </p>
      </div>
      <div class="flex md:block items-center">
        <p class="text-sirius-gray-700 text-sm">
          {{ $t('chocoIsAvailableInApp') }}
        </p>
        <a
          class="ml-1 md:ml-0 md:mt-1"
          href="https://rahmetapp.kz/webapp/1"
          target="_blank"
        >
          <img
            src="@img/desktop/choco-logo.png"
            width="68"
            height="20"
            alt="logo"
          />
        </a>
      </div>

      <div class="flex flex-wrap">
        <div class="w-full mt-5 block md:hidden">
          <a class="" href="https://rahmetapp.kz/webapp/1" target="_blank">
            <UiButton
              class="w-full font-bold text-lg"
              size="md"
              variant="white"
            >
              {{ $t('downloadApp') }}
            </UiButton>
          </a>

          <div class="mt-8 flex space-x-9">
            <a href="https://www.instagram.com/ryadom.kz/" target="_blank">
              <IconFooterInstagram
                class="w-9 h-9 flex items-center justify-center"
              />
            </a>
            <a href="https://www.tiktok.com/@ryadom.kz" target="_blank">
              <IconFooterTiktok
                class="w-9 h-9 flex items-center justify-center"
              />
            </a>
          </div>
        </div>
        <a
          class="hidden md:block"
          href="https://rahmetapp.kz/webapp/1"
          target="_blank"
        >
          <img
            class="mr-3"
            src="@img/desktop/app store.png"
            style="width: 132px; height: 42px"
            width="132"
            height="42"
            alt="appstore"
          />
        </a>
        <a
          class="hidden md:block"
          href="https://rahmetapp.kz/webapp/1"
          target="_blank"
        >
          <img
            src="@img/desktop/google play.png"
            style="width: 132px; height: 42px"
            width="132"
            height="42"
            alt="googleplay"
          />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { PUBLIC_OFFER_LINKS } from '@shared/config/public-offer-links';
import LogoDesk from '@components/header/desk-header/components/LogoDesk.vue';
import SupportButtons from '@shared/components/support/SupportButtons.vue';
import IconFooterInstagram from '../icons/IconFooterInstagram.vue';
import IconFooterTiktok from '../icons/IconFooterTiktok.vue';
import IconArrowUp from '../icons/IconArrowUp.vue';
import UiButton from '../ui/UiButtonNew.vue';

const ListOfLinks = {
  name: 'ListOfLinks',
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  render(h) {
    return h(
      'ul',
      {},
      this.links.map((item, index) => {
        return h(
          'li',
          {
            key: index,
            class:
              'mb-7 last:mb-0 md:mb-4 text-sm leading-4 hover:underline cursor-pointer text-sirius-gray-700 md:text-sirius-black-100 hover:text-sirius-orange-100 active:text-sirius-orange-300',
          },
          [h('a', { attrs: { href: item.link, target: '_blank' } }, item.title)]
        );
      })
    );
  },
};

const ExpandableBlock = {
  name: 'ExpandableBlock',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    onExpand() {
      this.$emit('onExpand', this.name);
    },
  },
  render(h) {
    return h(
      'div',
      {
        class:
          'pt-4 md:pt-0 pb-5 md:pb-0 border-b-2 md:border-none border-sirius-white-400',
      },
      [
        h(
          'div',
          {
            class:
              'md:hidden w-full flex items-center justify-between cursor-pointer',
            on: {
              click: this.onExpand,
            },
          },
          [
            h('p', { class: 'font-medium' }, this.$slots.title),
            h(IconArrowUp, { class: 'transition-all transform rotate-180' }),
          ]
        ),
        h(
          'div',
          {
            class: `${
              this.value === this.name ? 'pt-5 md:pt-0' : 'hidden md:block'
            }`,
          },
          this.$slots.default
        ),
      ]
    );
  },
};

export default {
  name: 'FooterDesk',
  components: {
    IconFooterInstagram,
    IconFooterTiktok,
    ExpandableBlock,
    SupportButtons,
    ListOfLinks,
    LogoDesk,
    UiButton,
  },
  data() {
    return {
      expanded: '',
    };
  },
  computed: {
    mainLinks() {
      return [
        {
          title: this.$t('aboutUs'),
          link: 'https://landing.choco.kz/',
        },
        {
          title: this.$t('answersToQuestions'),
          link: 'https://ryadom.kz/faq',
        },
        {
          title: this.$t('deliveryZones'),
          link: 'https://landing.choco.kz/',
        },
      ];
    },
    vacancyLinks() {
      return [
        {
          title: this.$t('toCouriers'),
          link: 'https://job.ryadom.kz/courier?utm_source=ryadom&utm_medium=link&utm_campaign=header-menu',
        },
        // {
        //   title: this.$t('toPickers'),
        //   link: 'https://ryadom.kz/'
        // },
        // {
        //   title: this.$t('toPromoters'),
        //   link: 'https://ryadom.kz/'
        // },
        {
          title: this.$t('toSuppliers'),
          link: 'https://landing.choco.kz/supplier',
        },
        {
          title: this.$t('toWarehouse'),
          link: 'https://landing.choco.kz/warehouse',
        },
      ];
    },
    publicOfferLink() {
      return PUBLIC_OFFER_LINKS[this.$i18n.locale];
    },
  },
  methods: {
    onExpand(value) {
      if (value === this.expanded) {
        this.expanded = '';
        return;
      }

      this.expanded = value;
    },
  },
  i18n: {
    messages: {
      ru: {
        mainInfo: 'Основная информация',
        vacancies: 'Вакансии',
        contacts: 'Контакты',
        privacy: 'Конфиденциальность',
        downloadApp: 'Скачать приложение',

        aboutUs: 'О нас',
        answersToQuestions: 'Ответы на вопросы',
        deliveryZones: 'Зоны доставки',

        toCouriers: 'Курьерам',
        toPickers: 'Пикерам',
        toPromoters: 'Промоутерам',
        toSuppliers: 'Поставщикам',
        toWarehouse: 'Помещение',

        publicOffer: 'Пользовательское соглашение',
        allRightReserved: 'Все права защищены',

        chocoIsAvailableInApp: 'Choco Рядом доступен в приложении',
        writeToWA: 'Написать в WhatsApp',
      },
      kk: {
        mainInfo: 'Негізгі ақпарат',
        vacancies: 'Бос жұмыс орындары',
        contacts: 'Контактілер',
        privacy: 'Құпиялылық',
        downloadApp: 'Қосымшаны жүктеу',

        aboutUs: 'Біз туралы',
        answersToQuestions: 'Сұрақтарға жауап',
        deliveryZones: 'Жеткізу аймақтары',

        toCouriers: 'Курьерлерге',
        toPickers: 'Пикерге\\тапсырыс жинаушға',
        toPromoters: 'Промоутерлерге',
        toSuppliers: 'Жеткізушілерге',
        toWarehouse: 'Бөлме',

        publicOffer: 'Пайдаланушы келісімі',
        allRightReserved: 'Барлық құқық қорғалған',

        chocoIsAvailableInApp: 'Choco Рядом қолдаңбада қолжетімді',
        writeToWA: 'WhatsApp-қа жазыңыз',
      },
    },
  },
};
</script>
