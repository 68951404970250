<template>
  <div class="CashbackReminder" @click="showModal = true">
    <div class="cashbacks-reminder">
      <UiInlineIcon
        class="cashbacks-reminder-icon"
        name="cashbacks-reminder-romb"
      />
      <div class="cashbacks-reminder-content">
        <p class="main-text" v-html="$t('mainText', [cashbackPrice])" />
        <p
          v-if="cashbackPrice < condition.maxBonus"
          class="add-text"
          v-html="$t('addText', [condition.maxBonus, condition.maxPrice])"
        />
      </div>

      <UiInlineIcon class="cashbacks-reminder-info" name="cashbacks-info" />
    </div>
    <ConditionsModal
      v-model="showModal"
      more-button
      :condition="condition"
      @onClickMore="$emit('onClickMore', $event)"
      @onClickReady="$emit('onClickReady', $event)"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import ConditionsModal from '../Conditions/ConditionsModal.vue';

export default {
  name: 'CashbackReminder',
  components: { UiInlineIcon, ConditionsModal },
  props: {
    cashbackPrice: {
      type: Number,
      default: 0,
    },
    condition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  i18n: {
    messages: {
      kk: {
        mainText: 'Сіз бұл тапсырыс үшін {0} бонус аласыз',
        addText: '{0} бонус алу үшін, тапсырыс құнын {1} ₸-ге жеткізіңіз',
      },
      ru: {
        mainText: 'Вы получите {0} бонусов за&nbsp;этот заказ',
        addText:
          'Чтобы получить {0}&nbsp;бонусов, доберите сумму заказа до&nbsp;{1}&nbsp;₸',
      },
    },
  },
};
</script>

<style scoped>
.cashbacks-reminder {
  @apply w-full flex space-x-2 cursor-pointer;
}
.cashbacks-reminder-content {
  @apply w-full text-base leading-18;
}
.cashbacks-reminder-content .main-text {
  @apply text-sirius-orange-100 font-medium;
}
.cashbacks-reminder-content .add-text {
  @apply text-sirius-gray-700 mt-3;
}
.cashbacks-reminder-info {
  @apply cursor-pointer;
}
</style>
