import { authEmitter, AUTH_EVENTS } from '@shared/services/auth/events';
import { rFetchOrder, rFetchOrders } from '@shared/services/api/apis';
import { PRIVATE_MESSAGE_TYPES } from '@shared/services/ws/config';
import { createStorage } from '@shared/services/storage';
import { api } from '@shared/services/api';
import { ws } from '@shared/services/ws';
import { store } from '@/store';

import { eventBus } from '@/utils/plugins/event-bus';
import { ORDER_EVENTS, orderEmitter } from './events';
import { ORDER_STATUSES } from './constants';

const storage = createStorage(window.localStorage);

function getActiveOrders() {
  return store.getters['orders/activeOrders'];
}
function setActiveOrders(orders) {
  store.commit('orders/SET_ACTIVE_ORDERS', orders);
}

/**
 * Обновляет список активных заказов который сохранены в Store.
 *
 * Эмитит событие когда новый заказ добавился что б открыть модалку Мини Статусов
 *
 * Эмитит событие когда заказ завершается что б закрыть модалку Мини статуса
 * @param {Object} item объект заказ с сокета
 * @returns
 */
function updateActiveOrders(item) {
  const activeOrders = [...store.getters['orders/activeOrders']];

  const index = activeOrders.length
    ? activeOrders.findIndex(el => el.id === item.id)
    : -1;

  if (index === -1) {
    if (item.status >= ORDER_STATUSES.DELIVERED) return;
    activeOrders.push({ ...item });

    orderEmitter.emit(ORDER_EVENTS.ON_UPDATE_ORDER, item);
    setActiveOrders(activeOrders);
    orderEmitter.emit(ORDER_EVENTS.ON_ACTIVE_ORDER_ADDED, item);
    return;
  }

  const order = { ...activeOrders[index] };
  const updatedOrder = Object.assign(order, item);
  activeOrders[index] = updatedOrder;

  if (updatedOrder.status >= ORDER_STATUSES.DELIVERED) {
    activeOrders.splice(index, 1);
    orderEmitter.emit(ORDER_EVENTS.ON_ACTIVE_ORDER_REMOVED);
  }

  orderEmitter.emit(ORDER_EVENTS.ON_UPDATE_ORDER, item);
  setActiveOrders(activeOrders);
}

/**
 * Эмитит ивент для открытия модалки для оценки
 * заказа когда с сокета прилетает что заказ завершен
 * @param {Object} order объект заказа из сокета
 */
function ifNeedReview(order) {
  if (
    order.id &&
    order.status === ORDER_STATUSES.DELIVERED &&
    !order.is_reviewed
  ) {
    const orderId = order.id.toString();
    orderEmitter.emit(ORDER_EVENTS.ON_ORDER_DELIVERED, orderId);
  }
}
function addFullOrdersInfo(orders) {
  orders.forEach(o => {
    rFetchOrder(o.id)
      .then(data => {
        let currentOrders = [...getActiveOrders()];
        let index = currentOrders.findIndex(o => data.id === o.id);
        if (index != -1) {
          let updatedOrder = { ...o, ...data };
          currentOrders[index] = updatedOrder;
          orderEmitter.emit(ORDER_EVENTS.ON_UPDATE_ORDER, updatedOrder);
        }
        setActiveOrders(currentOrders);
      })
      .catch(() => null);
  });
}
function fetchActiveOrders() {
  if (!store.state.isAuthorized) return Promise.resolve([]);

  return rFetchOrders({ tab: 'active' })
    .then(({ data }) => {
      const orders = data.items;
      if (orders.length === 0) {
        orderEmitter.emit(ORDER_EVENTS.ON_ACTIVE_ORDER_REMOVED);
        return Promise.resolve([]);
      }

      return orders;
    })
    .then(orders => {
      orders.forEach(o => orderEmitter.emit(ORDER_EVENTS.ON_UPDATE_ORDER, o));
      setActiveOrders(orders);
      addFullOrdersInfo(orders);
      return orders;
    })
    .catch(() => {
      orderEmitter.emit(ORDER_EVENTS.ON_ACTIVE_ORDER_REMOVED);
      setActiveOrders([]);
    });
}

/**
 * Получаем с бека последний не оцененный заказ.
 * @returns {Promise<Number|null>} И возвращаем orderId, если нет то null
 */
function fetchLastUnRatedOrder() {
  return new Promise((resolve, reject) => {
    api.lavka.lastNotReviewedOrder().then(res => {
      const orderId = res.data;
      if (!orderId) return reject();

      /**
       * Проверяем показывали ли мы модалку отзывов этого заказа.
       *
       * Если да то не показываем модалку.
       * Если нет то показываем и сохраняем в локалсторадж.
       */
      const order = storage.get('order::lastUnrated');

      if (order && order === orderId.toString()) return reject();
      storage.set('order::lastUnrated', orderId);

      return resolve(orderId.toString());
    });
  });
}

/**
 * Подписывается к сокетам статусов активных заказов
 */
function onUpdateStatuses(data) {
  updateActiveOrders(data);
  ifNeedReview(data);
}

/**
 * Привязываемся к Авторизаций
 *
 * вызываем fetchActiveOrders для получение активных заказов
 *
 * подключения к сокетам на обновления статусов заказа
 */
function init() {
  authEmitter.on(AUTH_EVENTS.ON_AUTH, fetchActiveOrders);
  eventBus.on('eb_on_reconnect_to_app', fetchActiveOrders);
  ws.on(PRIVATE_MESSAGE_TYPES.ORDER_STATUS, onUpdateStatuses);
}

async function fetchLastOrder() {
  let order = null;
  if (!store.state.isAuthorized) return Promise.resolve(order);

  const fetchOrder = (tab = 'active') =>
    rFetchOrders({ tab, limit: 1 })
      .then(({ data }) => data.items[0] || null)
      .catch(() => null);

  order = await fetchOrder();
  if (!order) order = await fetchOrder('finished');
  if (order) order = await rFetchOrder(order.id).catch(() => null);
  return Promise.resolve(order);
}

export const OrderService = {
  fetchLastUnRatedOrder,
  fetchActiveOrders,
  fetchLastOrder,
  init,
};
