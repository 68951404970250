const LS_KEY = 'banners::watched';

function get() {
  const str = window.localStorage.getItem(LS_KEY);
  return str ? JSON.parse(str) : [];
}

function append(code) {
  const current = get();
  if (!current.includes(code)) {
    window.localStorage.setItem(LS_KEY, JSON.stringify([...current, code]));
  }
}

export const WatchedBanners = {
  append,
  get,
};
