<template>
  <a href="https://wa.me/77014000079" class="WaButton">
    <WaIcon class="WaButton__icon" />
    <slot />
  </a>
</template>

<script>
import WaIcon from './icons/whatsapp.svg';

export default {
  name: 'WaButton',
  components: { WaIcon },
};
</script>

<style scoped>
.WaButton {
  display: inline-block;
  text-align: center;
  -webkit-appearance: none;
  padding: 12px;
  border-radius: 12px;
  background: #fff;
  color: #00c724;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.WaButton:focus {
  outline: none;
}

.WaButton__icon {
  margin-right: 8px;
  display: inline-block;
}
</style>
