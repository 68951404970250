<template>
  <div class="BannerDesk banner">
    <p class="banner-title" v-html="title"></p>
    <img class="banner-img" src="../img/hands-desk.png" alt="" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.banner {
  @apply p-8 relative
  bg-sirius-gray-200 rounded-3xl overflow-hidden;
  height: 180px;
}
.banner-title {
  @apply font-bold text-2xl leading-30;
  max-width: 322px;
}
.banner-img {
  @apply h-full absolute right-0 top-0 object-contain;
}
</style>
