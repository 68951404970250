<template>
  <div class="Slider">
    <div ref="scrollable" class="Slider__scrollable">
      <slot />
    </div>

    <!-- button left -->
    <button
      v-if="$isDesktop && hasLeftButton"
      class="Slider__btn Slider__btnPrev"
      @click="prevScreen"
    >
      <UiInlineIcon name="button-arrow-left-desktop" />
    </button>

    <!-- button left -->
    <button
      v-if="$isDesktop && hasRightButton"
      class="Slider__btn Slider__btnNext"
      @click="nextScreen"
    >
      <UiInlineIcon name="button-arrow-right-desktop" />
    </button>
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'Slider',
  components: {
    UiInlineIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    desktopMaxPerScreen: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    currentIdx: 0,
  }),
  computed: {
    count() {
      return this.items.length;
    },
    massArr() {
      return this.items.map(v => (v.type === 'story' ? 1 : 2));
    },
    totalMass() {
      return this.massArr.reduce((acc, cur) => acc + cur, 0);
    },
    prevIdx() {
      let idx = Math.max(0, this.currentIdx - 1);
      let sum = this.desktopMaxPerScreen;
      while (idx > 0) {
        sum -= this.massArr[idx];
        if (sum < 1) {
          return idx;
        }
        idx -= 1;
      }
      return 0;
    },
    nextIdx() {
      let idx = this.currentIdx;
      let sum = 0;
      while (idx < this.massArr.length) {
        sum += this.massArr[idx];
        if (sum > this.desktopMaxPerScreen) {
          return idx;
        }
        idx += 1;
      }
      return this.currentIdx;
    },
    hasLeftButton() {
      return this.currentIdx !== 0;
    },
    hasRightButton() {
      return (
        this.totalMass > this.desktopMaxPerScreen &&
        this.currentIdx !== this.nextIdx
      );
    },
  },
  mounted() {},
  methods: {
    scrollToItem(idx) {
      this.$slots.default[0].children[idx].elm.scrollIntoView({
        behavior: 'smooth',
        inline: 'start',
        block: 'nearest',
      });
    },
    prevScreen() {
      this.scrollToItem(this.prevIdx);
      this.currentIdx = this.prevIdx;
    },
    /**
     * Прокручивает слайдер на следующий экран, но
     * если последний элемент предыдущего экрана не уместился —
     * следующий экран начинается с этого элемента
     */
    nextScreen() {
      this.scrollToItem(this.nextIdx);
      this.currentIdx = this.nextIdx;
    },
  },
};
</script>

<style>
.Slider {
  position: relative;
}

.Slider__scrollable {
  overflow: auto;
}

.Slider__scrollable::-webkit-scrollbar {
  display: none;
}

.Slider__btn {
  display: none;
  position: absolute;
  top: 50%;
  border-radius: 24px;
  margin-top: -26px;
  box-shadow:
    0px 0.7051687836647034px 2.5458760261535645px 0px rgba(0, 0, 0, 0.01),
    0px 2.4036810398101807px 6.118093967437744px 0px rgba(0, 0, 0, 0.01),
    0px 5.793686389923096px 11.519827842712402px 0px rgba(0, 0, 0, 0.02),
    0px -3px 20.54940414428711px 0px rgba(0, 0, 0, 0.06),
    0px 3px 38.435401916503906px 0px rgba(0, 0, 0, 0.03),
    0px 92px 92px 0px rgba(0, 0, 0, 0.05);
}

.Slider__btn:focus {
  outline: none;
}

.Slider__btnPrev {
  left: 0;
  margin-left: -26px;
}

.Slider__btnNext {
  right: 0;
  margin-right: -26px;
}

@media (min-width: 768px) {
  .Slider__scrollable {
    overflow: hidden;
  }

  .Slider__btn {
    display: block;
  }
}
</style>
