<template>
  <MainLayout>
    <template #header>
      <ToolBar :back="onBackClick">
        <p class="truncate">{{ catalogName }}</p>
      </ToolBar>
    </template>
    <transition name="fade">
      <div
        v-if="tagAndBrandList"
        class="p-4 bg-white dark:bg-night ease-in duration-300"
      >
        <div class="flex">
          <FilterToggle
            class="justify-start-important truncate"
            :is-expanded="isTagExpanded"
            :title="filterTagsTitle"
            @click="expandFilterToggle('tags')"
          />
        </div>
        <FilterTags
          :is-expanded="isTagExpanded"
          :tags="tags"
          :disabled="loading"
          :tag-deeplink="tagDeeplink"
          @onSelect="onTagSelect"
        />
        <FilterTags
          :is-expanded="isBrandsExpanded"
          :tags="brands"
          :disabled="loading"
          :tag-deeplink="brandDeeplink"
          @onSelect="onBrandSelect"
        />
      </div>
    </transition>
    <div class="bg-gray-400 dark:bg-night" :class="{ 'h-full': loading }">
      <transition name="fade" mode="out-in">
        <UiRippleLoader v-if="loading" class="mx-auto" />
        <template v-else>
          <UiInfiniteScroll
            :is-loading="isNextPageLoading"
            class="duration-100"
            @callback="fetchNextPage"
          >
            <RecipeSearchResult
              :recipes="sortedProducts"
              :product-deeplink="productDeeplink"
            />
          </UiInfiniteScroll>
        </template>
      </transition>
      <WishButton :show-button="wishButton" :class="{ 'mb-24': !emptyCart }" />
    </div>
  </MainLayout>
</template>

<script>
import { api } from '@shared/services/api';
import { RahmetApp } from '@shared/RahmetApp';
import { mapState, mapGetters, mapActions } from 'vuex';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { groupByCategory } from '@shared/utils/product/group-by';
import { sortRecipes } from '@shared/utils/product/sort-products-by';
import { mergeCategories } from '@shared/utils/product/merge-categories';

import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import RecipeSearchResult from '@shared/components/recipe/RecipeSearchResult.vue';
import UiInfiniteScroll from '@ui/UiInfiniteScroll.vue';
import FilterToggle from '@shared/components/filter/FilterToggle.vue';
import FilterTags from '@shared/components/filter/FilterTags.vue';

import WishButton from '@shared/components/catalog/WishButton.vue';

import { sortOptions } from './utils';

export default {
  name: 'RecipeView',
  components: {
    FilterToggle,
    FilterTags,
    MainLayout,
    ToolBar,
    UiRippleLoader,
    RecipeSearchResult,
    UiInfiniteScroll,
    WishButton,
  },
  inject: ['bottomSheet', 'toast', 'popup', 'bottomSheetPopup'],
  beforeRouteLeave(to, from, next) {
    if (from.name === 'recipe' && to.name === 'recipe-full') {
      this.setRecipeState({
        page: this.page,
        tags: this.selectedTags,
        brands: this.selectedBrands,
        tagList: this.tags,
        brandList: this.brands,
        products: this.products,
        maxPage: this.maxPage,
        sort: this.selectedSort,
      });
    }
    next();
  },
  props: {
    id: {
      type: String,
      default: '291',
    },
  },
  data() {
    return {
      isTagExpanded: true,
      isBrandsExpanded: false,
      selectedSort: sortOptions[0].order,
      loading: true,
      catalogName: '',
      page: 0,
      maxPage: undefined,
      sortOptions,
      products: {},
      tags: [],
      brands: [],
      selectedTags: [],
      selectedBrands: [],
      pageCache: {},
      isNextPageLoading: false,
      productDeeplink: -1,
      tagDeeplink: {},
      brandDeeplink: {},
    };
  },
  computed: {
    ...mapGetters(['isDesktop']),
    ...mapGetters('delivery', ['warehouseId']),
    ...mapState('delivery', ['warehouse']),
    ...mapGetters('cart', ['cartSize']),
    ...mapGetters('component', [
      'recipeYPos',
      'recipePage',
      'recipeMaxPage',
      'recipeSort',
      'hasRecipeState',
      'recipeTags',
      'recipeBrands',
      'recipeTagList',
      'recipeBrandList',
      'recipeProducts',
      'isRecipeTagsEmpty',
      'isRecipeBrandsEmpty',
      'isRecipeTagListEmpty',
      'isRecipeBrandListEmpty',
      'isRecipeProductsEmpty',
    ]),
    tagAndBrandList() {
      return this.tags.length !== 0 || this.brands.length !== 0;
    },
    sortedProducts() {
      return sortRecipes(this.products);
    },
    nextPage() {
      return this.page + 1;
    },
    wishButton() {
      if (this.loading) return false;
      if (!this.maxPage) return true;
      return this.page === this.maxPage;
    },
    emptyCart() {
      return this.cartSize === 0;
    },
    wishTextareaPlaceholder() {
      return 'Чего Вам не хватило в ассортименте Рядом? Напишите здесь, что Вы не смогли найти у нас';
    },
    filterTagsTitle() {
      if (this.selectedTags.length !== 0) {
        return this.selectedTags.reduce((acc, v) => {
          const tag = this.tags.filter(t => t.id === Number(v));
          if (!tag || tag.length === 0) {
            return 'Все тэги';
          }
          return acc === ''
            ? `${acc} ${tag[0].name}`
            : `${acc}, ${tag[0].name}`;
        }, '');
      }
      return 'Все тэги';
    },
    filterBrandsTitle() {
      if (this.selectedBrands.length !== 0) {
        return this.selectedBrands.reduce((acc, v) => {
          const brand = this.brands.filter(t => t.id === Number(v));
          if (!brand || brand.length === 0) {
            return 'Все бренды';
          }
          return acc === ''
            ? `${acc} ${brand[0].name}`
            : `${acc}, ${brand[0].name}`;
        }, '');
      }
      return 'Все бренды';
    },
  },
  created() {
    api.lavka
      .categoryInfo(this.id)
      .then(({ data }) => (this.catalogName = data.name));

    if (!this.hasRecipeState && !this.$route.query.tag) {
      this.fetchFromCatalog().finally(() => (this.loading = false));
    }
    if (this.isRecipeTagsEmpty) {
      api.lavka.catalogCategories(this.id).then(({ data }) => {
        this.tags = data.items;
      });
    }

    if (this.isRecipeBrandListEmpty) {
      api.lavka
        .catalogBrands(this.id)
        .then(({ data }) => (this.brands = data.items));
    }

    this.controlDeeplink();
  },
  methods: {
    ...mapActions('component', {
      setRecipeState: 'SET_RECIPE_STATE',
      resetRecipeState: 'RESET_RECIPE_STATE',
    }),
    onTagSelect(tags) {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.RECIPE_TAGS, { ids: Object.keys(tags) });
      this.loading = true;
      this.resetData();

      this.selectedTags = Object.keys(tags).filter(k => tags[k]);

      this.fetchFromCatalogWithTags().finally(() => (this.loading = false));
    },
    onBrandSelect(brands) {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.RECIPE_TAGS, { ids: Object.keys(brands) });
      this.loading = true;
      this.resetData();

      this.selectedBrands = Object.keys(brands).filter(k => brands[k]);

      this.fetchFromCatalogWithTags().finally(() => (this.loading = false));
    },
    fetchNextPage() {
      const inCache = this.pageCache.hasOwnProperty(this.nextPage);
      if (this.nextPage <= this.maxPage && !inCache) {
        this.pageCache[this.nextPage] = true;
        this.isNextPageLoading = true;
        const promise =
          this.selectedTags.length === 0 && this.selectedBrands.length === 0
            ? this.fetchFromCatalog()
            : this.fetchFromCatalogWithTags();
        promise.finally(() => (this.isNextPageLoading = false));
      }
    },
    fetchFromCatalog() {
      const payload = {
        warehouse_id: this.warehouseId,
        parent_id: this.id,
        is_active: 1,
        page: this.nextPage,
        order: this.selectedSort,
      };

      return api.lavka
        .recipes(payload)
        .then(({ data }) => {
          const temp = groupByCategory(data.items);
          this.products = mergeCategories(this.products, temp);
          this.updatePagination(data.pagination);
        })
        .catch(err => this.toast.show(err.message));
    },
    fetchFromCatalogWithTags() {
      const payload = {
        parent_id: this.id,
        is_active: 1,
        category_ids: this.selectedTags,
        brand_ids: this.selectedBrands,
        page: this.nextPage,
        order: this.selectedSort,
      };

      return api.lavka
        .recipesByTags(payload)
        .then(({ data }) => {
          const temp = groupByCategory(data.items);
          this.products = mergeCategories(this.products, temp);
          this.updatePagination(data.pagination);
        })
        .catch(err => this.toast.show(err.message));
    },
    updatePagination(paging) {
      this.page = Number(paging.current_page);
      this.maxPage = paging.total_pages;
    },
    resetData() {
      this.page = 0;
      this.products = {};
      this.pageCache = {};
    },

    onBackClick() {
      this.$router.push({ name: 'main' });
    },
    onSearchClick() {
      this.$router.push({
        name: 'search',
        params: this.$route.params.id,
      });
    },
    expandFilterToggle(button) {
      if (button === 'tags') {
        this.isBrandsExpanded = false;
        this.isTagExpanded = !this.isTagExpanded;
      } else if (button === 'brands') {
        this.isBrandsExpanded = !this.isBrandsExpanded;
        this.isTagExpanded = false;
      }
    },
    controlDeeplink() {
      if (this.hasRecipeState) {
        if (!this.isRecipeTagsEmpty) {
          this.selectedTags = this.recipeTags;
          this.selectedTags.forEach(id => {
            this.$set(this.tagDeeplink, id, !this.tagDeeplink[id]);
          });
        }

        if (!this.isRecipeBrandsEmpty) {
          this.selectedBrands = this.recipeBrands;
          this.selectedBrands.forEach(id => {
            this.$set(this.brandDeeplink, id, !this.brandDeeplink[id]);
          });
        }

        if (this.recipePage !== 0) {
          this.page = this.recipePage;
        }

        if (!this.isRecipeTagListEmpty) {
          this.tags = this.recipeTagList;
        }

        if (!this.isRecipeBrandListEmpty) {
          this.brands = this.recipeBrandList;
        }

        if (this.recipeMaxPage !== 0) {
          this.maxPage = this.recipeMaxPage;
        }

        if (this.recipeSort) {
          this.selectedSort = this.recipeSort;
        }

        if (!this.isRecipeProductsEmpty) {
          this.products = this.recipeProducts;
        }
        this.loading = false;
        this.resetRecipeState();
      } else if (this.$route.query.product) {
        this.productDeeplink = Number(this.$route.query.product);
      } else if (this.$route.query.tag) {
        const { tag } = this.$route.query;
        this.$set(this.tagDeeplink, tag, !this.tagDeeplink[tag]);
        this.onTagSelect(this.tagDeeplink);
      }
    },
  },
};
</script>

<style lang="postcss">
.justify-start-important {
  justify-content: flex-start !important;
}
</style>
