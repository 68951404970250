<template>
  <div class="cashbackbox-wrapper">
    <template v-if="cashbacks.gainedCashbacks.length">
      <p class="mb-4 font-bold leading-5 md:text-2xl md:leading-30">
        {{ $t('doneTitle') }}
      </p>

      <CashbackBox
        v-for="condition in cashbacks.gainedCashbacks"
        :key="condition.count"
        :max-count="conditions.length"
        :condition="condition"
        class="mt-3"
      />
    </template>

    <template v-if="cashbacks.notGainedCashbacks.length">
      <p class="mt-9 mb-4 font-bold leading-5 md:text-2xl md:leading-30">
        {{ $t('unDoneTitle') }}
      </p>

      <CashbackBox
        v-for="condition in cashbacks.notGainedCashbacks"
        :key="condition.count"
        :max-count="conditions.length"
        :condition="condition"
        class="mt-3"
      />
    </template>
  </div>
</template>

<script>
import CashbackBox from './CashbackBox.vue';

export default {
  name: 'CashbackBoxWrapper',
  components: { CashbackBox },
  props: {
    conditions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    cashbacks() {
      const gainedCashbacks = [];
      const notGainedCashbacks = [];

      this.conditions.forEach(cond => {
        const box = {
          ...cond,
          maxCount: this.conditions.length,
        };
        if (cond.cashback) gainedCashbacks.push(box);
        else notGainedCashbacks.push(box);
      });

      return {
        gainedCashbacks,
        notGainedCashbacks,
      };
    },
  },
  i18n: {
    messages: {
      kk: {
        doneTitle: 'Алынған кешбэктер',
        unDoneTitle: 'Алынбаған кешбэктер',
      },
      ru: {
        doneTitle: 'Начисленные кешбэки',
        unDoneTitle: 'Ещё не начисленные кешбэки',
      },
    },
  },
};
</script>

<style scoped></style>
