<template>
  <Transition name="bottom-sheet" @after-enter="onOpen" @after-leave="onClose">
    <div v-if="show" class="UiBottomSheet bottom-sheet-modal">
      <div
        class="blackout bottom-sheet-overlay"
        @touchstart.passive="onBlackoutTouchStart"
        @touchend.passive="onBlackoutTouchEnd"
        @click="closeModal"
      />

      <div class="sheet" style="max-height: 95%">
        <div
          id="sheetShift"
          aria-modal="true"
          role="dialog"
          class="sheet-shift"
          :style="{ transform: `translateY(${sheetShift}px)` }"
          @touchstart.passive="onSheetTouchStart"
          @touchmove.passive="onSheetTouchMove"
          @touchend="onSheetTouchEnd"
        >
          <!-- полоска за которую дёргать -->
          <div id="shiftContent" class="shift-content" @click="closeModal" />

          <div
            id="bottomSheetContainer"
            ref="content"
            class="bottom-sheet-container hidden-scrollbar"
            :style="`background: ${backgroundColor}`"
          >
            <div id="bottomSheetContent" class="bottom-sheet-content">
              <slot></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { isElement } from '@/utils/functions/is-node';
import { SwipeHandler } from './swipe-handler';
import {
  whenNavigationToBack,
  cancelHandlingToBack,
  handleNavigationToBack,
} from 'choco-app';

function isModalContent(el) {
  let stopPropogationEls = [
    'sheetShift',
    'shiftContent',
    'bottomSheetContainer',
    'bottomSheetContent',
  ];
  return !stopPropogationEls.includes(el.id);
}
export default {
  name: 'UiBottomSheet',
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    disableTouchOnContent: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    blackoutTouchStarted: false,
    sheetTouchStarted: false,
    sheetTouchStart: 0,
    sheetShift: 0,
    swipeHandler: null,
  }),
  watch: {
    show: {
      handler(val, old) {
        if (val) this.disableScroll();
        else if (old !== undefined) this.enableScroll();
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.$el && isElement(this.$el)) document.body.removeChild(this.$el);
  },
  mounted() {
    document.body.appendChild(this.$el);
    this.swipeHandler = new SwipeHandler(this.closeModal);
  },
  methods: {
    onBlackoutTouchStart() {
      this.blackoutTouchStarted = true;
    },
    onBlackoutTouchEnd() {
      if (this.blackoutTouchStarted) {
        this.blackoutTouchStarted = false;
        this.closeModal();
      }
    },
    onSheetTouchStart(e) {
      let isContent = isModalContent(e.target);
      if (this.disableTouchOnContent && isContent) return;

      this.swipeHandler.onSheetTouchStart(e, this.$refs.content);
    },
    onSheetTouchMove(e) {
      this.swipeHandler.onSheetTouchMove(e);
      this.sheetShift = this.swipeHandler.sheetShift;
    },
    onSheetTouchEnd() {
      this.swipeHandler.onSheetTouchEnd();
      this.sheetShift = this.swipeHandler.sheetShift;
    },
    closeModal() {
      this.$emit('change', false);
    },
    disableScroll() {
      document.body.style.position = 'relative';
      document.body.style.overflow = 'hidden';
      document.body.style.overscrollBehavior = 'contain';
    },
    enableScroll() {
      let hasModalsOnPage = document.querySelectorAll('.UiBottomSheet').length;
      if (hasModalsOnPage > 1) {
        this.disableScroll();
        return;
      }
      document.body.style.removeProperty('position');
      document.body.style.overflow = 'auto';
      document.body.style.overscrollBehavior = 'auto';
    },
    onClose() {
      this.$emit('onClose');
      cancelHandlingToBack();
    },
    onOpen() {
      this.$emit('onOpen');
      whenNavigationToBack(this.closeModal);
      handleNavigationToBack();
    },
  },
};
</script>

<style scoped>
.blackout {
  @apply inset-0 fixed z-modal;
}
.sheet {
  @apply z-modal;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
}
.sheet-shift {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 300ms;
}
.shift-content {
  position: absolute;
  top: var(--shift-plank-top, 0);
  left: 50%;
  z-index: 9;
  width: 72px;
  height: 6px;
  margin: 0.5rem 0;
  background-color: #d4d3df;
  border-radius: 2.5rem;
  opacity: 0.4;
  transform: translateX(-50%);
}

.bottom-sheet-enter-active,
.bottom-sheet-leave-active {
  transition: 500ms;
}

.bottom-sheet-enter-active > .blackout,
.bottom-sheet-leave-active > .blackout {
  transition: 500ms;
}

.bottom-sheet-enter > .blackout,
.bottom-sheet-leave-to > .blackout {
  opacity: 0;
}

.bottom-sheet-enter-active > .sheet,
.bottom-sheet-leave-active > .sheet {
  transition: 200ms ease-in;
}

.bottom-sheet-enter > .sheet,
.bottom-sheet-leave-to > .sheet {
  transform: translateY(100%);
}

.bottom-sheet-overlay {
  background: rgba(0, 0, 0, 0.45);
}

.bottom-sheet-modal {
  @apply inset-0 fixed z-modal;
  display: flex;
}

.bottom-sheet-container {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}
</style>
