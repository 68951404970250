<template>
  <MainLayout :with-footer="false" class="text-sirius-black-100">
    <template #header>
      <ToolBar v-if="isWebView" :back="onBackClick">
        {{ $t('somethingWrongWithOrder') }}
      </ToolBar>
    </template>
    <ProfileLayout :container-width="488" :bread-links="links">
      <UiRippleLoader v-if="loading" class="mx-auto" />
      <template v-else>
        <h1 v-if="!isWebView" class="font-bold text-2xl pl-4 mb-4">
          {{ $t('somethingWrongWithOrder') }}
        </h1>
        <ul class="flex flex-col">
          <ProblemType link="problems-order" :order-id="id" type="stale">
            {{ $t('somethingWrongWithProduct') }}
          </ProblemType>
          <ProblemType link="problems-order" :order-id="id" type="incomplete">
            {{ $t('notAllWasDelivered') }}
          </ProblemType>
          <ProblemType link="problems-order" :order-id="id" type="notexactly">
            {{ $t('wrongProductsWasDelivered') }}
          </ProblemType>
          <ProblemType
            v-if="!isProductProblem"
            link="problems-request"
            :order-id="id"
            type="courier"
          >
            {{ $t('courierHasNotCome') }}
          </ProblemType>
          <ProblemType
            v-if="!isProductProblem"
            link="problems-request"
            :order-id="id"
            type="doublepay"
          >
            {{ $t('doubleOrderPayment') }}
          </ProblemType>
          <ProblemType
            v-if="!isProductProblem"
            link="problems-request"
            :order-id="id"
            type="notreturned"
          >
            {{ $t('moneyStillHadNotBeenReturned') }}
          </ProblemType>
          <ProblemType
            v-if="!isProductProblem"
            link="problems-request"
            :order-id="id"
            type="other"
          >
            {{ $t('anotherReason') }}
          </ProblemType>
        </ul>
      </template>
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { rFetchOrder } from '@shared/services/api/apis';

import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import { dateAndTime } from '@/utils/formatters/date';

import ProblemType from './components/ProblemType.vue';

export default {
  name: 'ProblemsView',
  components: {
    MainLayout,
    ToolBar,
    ProblemType,
    ProfileLayout,
    UiRippleLoader,
  },
  inject: ['toast'],
  props: {
    id: {
      type: String,
      default: '',
    },
    route: {
      type: Object,
      default: () => {
        return {
          name: 'main',
        };
      },
    },
  },
  data() {
    return {
      loading: true,
      order: null,
      title: this.$t('somethingWrongWithOrder'),
    };
  },
  computed: {
    ...mapGetters(['isWebView']),
    isProductProblem() {
      return this.$route.query.productProblem === 'true';
    },
    links() {
      const links = [
        { name: this.$t('home'), path: '/' },
        { name: this.$t('orders'), path: '/orders' },
      ];

      if (this.order) {
        links.push({
          name: dateAndTime(this.order.paid_at),
          path: `/order/${this.id}`,
        });

        links.push({
          name: this.title,
          path: '#',
        });
      }

      return links;
    },
  },
  created() {
    this.fetchOrder();
  },
  methods: {
    onBackClick() {
      if (this.id)
        this.$router.push({
          name: 'order',
          prams: {
            id: this.id,
          },
        });
      else
        this.$router.push({
          name: 'orders',
        });
    },
    fetchOrder() {
      rFetchOrder(this.id)
        .then(data => (this.order = data))
        .catch(err => this.toast.show(err.message))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  i18n: {
    messages: {
      ru: {
        orders: 'Все заказы',
        home: 'Главная',
        somethingWrongWithOrder: 'Что-то не так с заказом',
        somethingWrongWithProduct: 'Что-то не так с товаром',
        notAllWasDelivered: 'Привезли не все товары',
        wrongProductsWasDelivered: 'Привезли не те товары',
        courierHasNotCome: 'Курьер не приехал',
        doubleOrderPayment: 'Двойная оплата заказа',
        moneyStillHadNotBeenReturned: 'Деньги все еще не вернули',
        anotherReason: 'Другая причина',
      },
      kk: {
        orders: 'Тапсырыстар',
        home: 'Басты бет',
        somethingWrongWithOrder: 'Тапсырыста қателік бар',
        somethingWrongWithProduct: 'Тауарда қателік бар',
        notAllWasDelivered: 'Тауарлар толық әкелінбеді',
        wrongProductsWasDelivered: 'Дұрыс емес тауарлар әкелінді',
        courierHasNotCome: 'Курьер келмеді',
        doubleOrderPayment: 'Тапсырыс екі рет төленді',
        moneyStillHadNotBeenReturned: 'Ақша әлі қайтарылған жоқ',
        anotherReason: 'Басқа себеп',
      },
    },
  },
};
</script>
