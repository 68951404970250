<template>
  <li class="BarElement bar-element" :class="{ '--active': active }">
    <div class="bar-element-content">
      <slot name="icon"></slot>
      <p class="bar-element-title"><slot name="text"> </slot></p>
    </div>
  </li>
</template>

<script>
export default {
  name: 'BarElement',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.bar-element {
  @apply pb-0.5 w-full text-sirius-gray-1000 text-center;
  font-size: 11px;
  line-height: 10px;
  letter-spacing: -0.015em;
}
.--active {
  @apply text-sirius-orange-100;
}
.bar-element-content {
  @apply flex flex-col justify-center items-center 
    transition duration-100 ease-in 
    active:opacity-50;
}
.bar-element-title {
  @apply mt-1 text-sirius-gray-900;
}
.--active .bar-element-title {
  @apply text-sirius-orange-100;
}
</style>
