import { WebApp } from '@shared/WebApp';
import { RahmetApp } from '@shared/RahmetApp';
import { DevConfigs } from '@/utils/dev-config';

export const GeoStatuses = ['granted', 'notDetermined', 'denied'];

/**
 * @typedef Location
 * @prop {number} latitude
 * @prop {number} longitude
 * @prop {number} accuracy
 *
 * Получаем локацию юзера
 * @returns {Promise<Location>}
 */
function getGeoLocation() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        resolve({
          latitude: coords.latitude,
          longitude: coords.longitude,
          accuracy: coords.accuracy,
        });
      },
      err => {
        reject(err);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  });
}

/**
 * Делает запрос на разрешение сбора геолокации
 * @returns {Promise<String>}
 */
function requestPermission() {
  if (DevConfigs.isDev) {
    return WebApp.requestGeoPermissionStatus();
    // return new Promise(resolve => {
    //   setTimeout(resolve('granted'), 1000);
    // });
  }

  if (RahmetApp.isWebView()) return RahmetApp.requestGeoPermissionStatus();

  return WebApp.requestGeoPermissionStatus();
}

function requestGeoPermission() {
  return requestPermission()
    .then(res => res)
    .catch(() => 'denied');
}

/**
 * Возвращает информацию о локации юзера, или же ошибку
 *
 * @prop {number} latitude
 * @prop {number} longitude
 * @prop {number} accuracy
 *
 * @returns {Promise<Location|Error>}
 */
function getUserLocation() {
  return requestPermission().then(res => {
    if (res === 'granted' || res === 'notDetermined') return getGeoLocation();
    return Promise.reject(new Error('Geo denied'));
  });
}

export const GeoService = {
  requestGeoPermission,
  getUserLocation,
};
