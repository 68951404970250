<template>
  <div class="DeliveryOption option" :class="{ option__disabled: disabled }">
    <div class="option-content" @click="setValue">
      <UiInlineIcon
        :name="option.icon"
        class="option-icon"
        :class="{ 'option-icon__disabled': disabled }"
      />
      <div class="option-descr">
        <p>{{ option.title }}</p>
        <p v-if="option.descr">{{ option.descr }}</p>
      </div>
      <div class="option-toggle" @click.stop>
        <UiToggleSwitch :value="value" :disabled="disabled" @input="setValue" />
      </div>
    </div>
    <div class="option-bottom">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import UiToggleSwitch from '@pure-ui/components/UiToggleSwitch/UiToggleSwitch.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'DeliveryOption',
  components: {
    UiToggleSwitch,
    UiInlineIcon,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setValue() {
      if (this.disabled) return;
      this.$emit('change', !this.value);
    },
  },
};
</script>

<style scoped>
.option {
  @apply mt-0.5 pt-3 pb-4 text-sirius-black-100 border-b-2 border-sirius-gray-400 last:border-0 cursor-pointer;
}
.option:first-child {
  @apply border-b-2;
}
.option__disabled {
  @apply text-sirius-gray-100;
}
.option-content {
  @apply flex items-start;
}
.option-icon {
  @apply mr-3;
}
.option-icon__disabled {
  @apply text-sirius-gray-300;
}
.option-descr {
  @apply pr-5;
}
.option-descr p:nth-child(2) {
  @apply text-sm font-normal text-sirius-gray-800;
}
.option-toggle {
  @apply ml-auto;
}
</style>
