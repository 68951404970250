<template>
  <MainLayout with-checkout-reminder>
    <template v-if="$isWebView || !$isDesktop" #header>
      <ToolBar :back="onBackClick">
        <SearchTrigger v-model="query" autofocus />
        <template #right-side><div class=""></div></template>
      </ToolBar>
    </template>
    <div
      class="h-full flex flex-col mt-5 md:mt-16 container"
      style="max-width: 1088px"
    >
      <template v-if="$isDesktop">
        <UiBreadCrumbs :links="[{ name: 'returnToMain', path: '/' }]" />
        <h1 v-if="!loading" class="mt-8 mb-12 font-bold text-32">
          {{ $t('foundItems', { productsDeclination, query, totalItems }) }}
          <!-- Найдено {{ productsDeclination }} по запросу «{{ query }}» -->
        </h1>
      </template>

      <UiRippleLoader v-if="loading" class="mx-auto" />
      <div v-else>
        <!-- Список категорий на мобилке -->
        <template v-if="!$isDesktop">
          <!-- По-умолчанию видны все -->
          <ul
            v-if="!query && !productList.length"
            class="overflow-y-scroll overscroll-contain hidden-scrollbar px-4 pb-5"
          >
            <div
              v-for="(item, index) in compCategoriesByGroup"
              :key="index"
              class="mt-5 first:mt-0"
            >
              <MobileCatalogItem
                :item="item"
                @onNavigate="goToCategory($event)"
              />
            </div>
          </ul>
          <!-- при поиске фильтруются -->
          <div v-if="searchedCategories && !$isDesktop" class="-mt-3 px-4 pb-4">
            <div
              v-for="(item, index) in searchedCategories"
              :key="index"
              class="py-3 border-b border-sirius-gray-400"
            >
              <CategoryBlock
                class="w-full"
                type="search"
                :category="item"
                @onNavigate="goToCategory(item)"
              />
            </div>
          </div>
        </template>
        <UiInfiniteScroll
          v-if="firstQuery"
          :is-loading="isNextPageLoading"
          @callback="fetchNextPage"
        >
          <ProductsSearchResult
            class="duration-100 px-4 md:px-0"
            :product-list="productList"
            :categories="sortedProducts"
            :added-from="PRODUCT_ADDED.SEARCH"
            type="ungrouped"
            sticky-category-pos="top"
          />
          <WishButton
            :show-button="hasWishButton"
            class="px-4 md:px-0 mt-10 md:mt-12"
          />
        </UiInfiniteScroll>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'debounce';

import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { excludedCategories } from '@shared/utils/product/excluded-categories';
import { mergeCategories } from '@shared/utils/product/merge-categories';
import { sortProductsBy } from '@shared/utils/product/sort-products-by';
import { groupBySortWeight } from '@shared/utils/product/group-by';
import { PRODUCT_ADDED } from '@shared/config/product';
import { pluralize } from '@shared/utils/text';
import { api } from '@shared/services/api';
import { getFilteredProducts } from '@/lib/coffee/get-filtered-products';
import { isCoffeeAvailable } from '@/lib/coffee/is-coffee-available';
import { COFFEE_CATEGORY_ID } from '@/lib/coffee/config.js';

import ProductsSearchResult from '@shared/components/product/ProductsSearchResult.vue';
import UiBreadCrumbs from '@ui/UiBreadCrumbs.vue';
import WishButton from '@shared/components/catalog/WishButton.vue';
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import UiInfiniteScroll from '@ui/UiInfiniteScroll.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import MainLayout from '@layout/MainLayout.vue';
import MobileCatalogItem from '@shared/components/catalog/MobileCatalogItem.vue';
import CategoryBlock from '@shared/components/catalog/CategoryBlock.vue';

const filtered = list => {
  const excluded = excludedCategories();
  return list.filter(v => !excluded.includes(v.parent_id));
};

export default {
  name: 'SearchView',
  components: {
    ProductsSearchResult,
    UiInfiniteScroll,
    UiRippleLoader,
    MainLayout,
    ToolBar,
    SearchTrigger,
    UiBreadCrumbs,
    WishButton,
    MobileCatalogItem,
    CategoryBlock,
  },
  inject: ['bottomSheet', 'toast', 'popup', 'bottomSheetPopup'],
  data() {
    return {
      query: '',
      firstQuery: false,
      /**
       * На десктопе при попадании на страницу визуально
       * сразу происходит загрузка.
       * На мобилке изначально видны категории, загрузка не происходит
       */
      loading: false,
      page: 0,
      /**
       * Список товаров.
       * То же, что и `this.productList`, но разбиты по категориям.
       */
      result: {},
      pageCache: {},
      maxPage: undefined,
      isNextPageLoading: false,
      PRODUCT_ADDED,
      /**
       * Список товаров.
       * То же, что и `this.result`, но просто списком
       */
      productList: [],
      totalItems: 0,
      /**
       * Статичные категории, которые уже получили на главной
       */
      searchedCategories: null,
    };
  },
  computed: {
    ...mapState('catalog', ['categoriesByGroup', 'categories']),
    ...mapGetters('delivery', ['warehouseId']),
    ...mapGetters('abtest', ['testGroups']),
    compCategoriesByGroup() {
      if (!isCoffeeAvailable()) {
        const result = {};
        if (!this.categoriesByGroup) return result;
        Object.keys(this.categoriesByGroup).forEach(key => {
          const group = this.categoriesByGroup[key];
          result[key] = {
            ...group,
            categories: group.categories.filter(
              c => c.id !== COFFEE_CATEGORY_ID
            ),
          };
        });
        return result;
      } else {
        return this.categoriesByGroup;
      }
    },
    sortedProducts() {
      return sortProductsBy('popular_asc', this.result);
    },
    nextPage() {
      return this.page + 1;
    },
    productsDeclination() {
      if (this.$i18n.locale === 'kk') return 'тауар';
      return ` ${pluralize(this.totalItems, ['товар', 'товара', 'товаров'])}`;
    },
    hasWishButton() {
      if (this.loading) return false;
      if (!this.query) return false;
      if (!this.maxPage) return true;
      return this.page === this.maxPage;
    },
  },
  watch: {
    '$route.query.search': function onChange() {
      this.configureDeeplink();
    },
    query(v) {
      if (v.trim()) {
        this.onInput();
      } else {
        this.resetData();
      }
    },
  },
  created() {
    if (this.$route.query.search) {
      this.configureDeeplink();
    }
  },
  methods: {
    configureDeeplink() {
      this.query = this.$route.query.search;
      this.loading = true;
      this.onInput();
    },
    onInput: debounce(function onInput() {
      this.resetData();
      if (this.query.trim() === '') {
        return;
      }

      if (this.query.length > 3) {
        Analitycs.logEvent(EVENTS.ENTERED_SEARCH, { search_query: this.query });
      }

      if (this.query !== '') {
        this.loading = true;
        this.searchedCategories = this.getCategoriesByQuery();
        this.search().finally(() => {
          this.loading = false;
        });
      }
    }, 1000),
    search() {
      this.firstQuery = true;

      return this.$store
        .dispatch('delivery/FIND_WAREHOUSE_IF_NEEDED')
        .then(() =>
          api.lavka.searchInAllCategory({
            warehouse_id: this.warehouseId,
            keyword: this.query,
            page: this.nextPage,
            ab_groups: this.testGroups,
          })
        )
        .then(({ data }) => {
          // data.data.items.map(el => {
          //   const search = {
          //     '1_c_s_w': el.sort_weight,
          //     '2_c_c_i': el.category_id,
          //     '4_has_brand': el.has_brand,
          //     '5_b_s_w': el.brand_sort_weight,
          //     '6_b_i': el.brand_id,
          //     '7_p_s_w': el.product_sort_weight,
          //     '8_id': el.id
          //   }
          //   return search
          // })

          // Убираем скрытые категории, например, табачку,
          // в нативном приложении
          let items = filtered(data.data.items);
          items = getFilteredProducts(items);

          this.productList = [...this.productList, ...items];
          let temp = {};
          temp = groupBySortWeight(items);
          this.result = mergeCategories(this.result, temp);
          this.updatePagination(data.data.pagination);

          Analitycs.logEvent(EVENTS.VIEWED_SEARCH_RESULTS, {
            search_results: data.data,
          });
          Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
            screenName: 'Search Page',
            warehouseId: this.warehouseId,
            query: this.query,
            logId: data.data.log_id,
          });
        })
        .catch(err => this.toast.show(err.message));
    },
    fetchNextPage() {
      const inCache = this.pageCache.hasOwnProperty(this.nextPage);
      if (this.page < this.maxPage && !inCache) {
        this.isNextPageLoading = true;
        this.pageCache[this.nextPage] = true;
        const promise = this.search();
        promise.finally(() => (this.isNextPageLoading = false));
      }
    },
    updatePagination(paging) {
      this.page = Number(paging.current_page);
      this.maxPage = paging.total_pages;
      this.totalItems = paging.total_items;
    },
    onBackClick() {
      Analitycs.logEvent(EVENTS.BACK_SEARCH);
      this.$router.go(-1);
    },
    resetData() {
      this.loading = false;
      this.productList = [];
      this.result = {};
      this.page = 0;
      this.pageCache = {};
      this.maxPage = undefined;
    },
    getCategoriesByQuery() {
      let result = null;
      if (this.categories && !this.$isDesktop) {
        result = this.categories.filter(e => e.name.search(this.query) !== -1);
        if (!isCoffeeAvailable()) {
          result = result.filter(c => c.id !== COFFEE_CATEGORY_ID);
        }
      }
      return result && result.length !== 0 ? result : null;
    },
    goToCategory(value) {
      if (!value) return;
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
        categoryId: value.id,
        categoryName: value.name,
        from: 'search',
      });
      this.$router.push({
        name: 'category',
        params: {
          id: value.id.toString(),
        },
      });
      // this.hideResults();
    },
  },
  i18n: {
    messages: {
      ru: {
        foundItems:
          'Найдено {totalItems} {productsDeclination} по запросу «{query}»',
      },
      kk: {
        foundItems:
          '«{query}» сұранысы бойынша {totalItems} {productsDeclination} табылды ',
      },
    },
  },
};
</script>
