<template>
  <div
    class="SidebarLinkTitle sidebar-link-title"
    :class="{ '--red': red, '--active': active }"
  >
    <slot></slot>

    <UiInlineIcon
      v-if="arrow"
      name="profile-sidebar-arrow"
      :class="`profile-sidebar-arrow ${toggled ? '--toggled' : ''}`"
    />
  </div>
</template>

<script>
export default {
  components: {
    UiInlineIcon: () =>
      import('@pure-ui/components/UiInlineIcon/UiInlineIcon.vue'),
  },
  props: {
    red: Boolean,
    arrow: Boolean,
    active: Boolean,
    toggled: Boolean,
  },
};
</script>

<style scoped>
.sidebar-link-title {
  @apply pb-2  
    inline-flex items-center cursor-pointer 
    font-medium 
    border-b-2 border-transparent
    
    md:pb-3 md:leading-5;
}

.--active {
  @apply border-sirius-orange-100 text-sirius-orange-100;
}
.--red {
  @apply text-sirius-red-300;
}
.--active.--red {
  @apply border-sirius-red-300 text-sirius-red-300;
}

.sidebar-link-title:hover {
  @apply md:text-sirius-orange-100;
}
.--red.sidebar-link-title:hover {
  @apply md:text-sirius-red-300 md:border-sirius-red-300;
}

.profile-sidebar-arrow {
  @apply transition-transform duration-300 
    rotate-180;

  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.--toggled {
  @apply rotate-0;
}
</style>
