<template>
  <div v-if="isCategoryInSurvey && !$isDesktop" class="pb-6">
    <CategoryNPSPlank @click.native="onClick" />
  </div>
</template>

<script>
import CategoryNPSPlank from '@/pure-ui/components/CategoryNPS/CategoryNPSPlank/CategoryNPSPlank.vue';
import { CategoryNpsService } from '@services/category-nps';

export default {
  name: 'CategoryNpsCategorypageContainer',
  components: {
    CategoryNPSPlank,
  },
  computed: {
    categoryId() {
      return parseInt(this.$route.params?.id);
    },
    isCategoryInSurvey() {
      const survey = this.$store.getters['categoryNps/surveys'].find(
        v => v.categoryId === this.categoryId
      );
      return !!survey;
    },
  },
  methods: {
    onClick() {
      CategoryNpsService.analytics.logBannerClick({
        categoryId: this.categoryId,
        screen: 'Category Page',
      });
      this.$eventBus.emit('eb_open_category_nps_modal', {
        categoryId: this.categoryId,
      });
    },
  },
};
</script>
