/**
 * Вызывает callback с заданной частой, пока callback
 * не вёрнет truthy значение или не истечёт timeoutMs.
 * Возвращает Promise, который или резолвит значение
 * из callback, или реджектится после таймаута
 * @param {*} callback
 * @param {*} rate
 * @param {*} timeoutMs
 */
export function pollUntillTimeout(callback, rate = 100, timeoutMs = 3000) {
  return new Promise((resolve, reject) => {
    let resetTimer, resultTimer;

    if (timeoutMs) {
      resetTimer = setTimeout(() => {
        clearInterval(resultTimer);
        reject(new Error('Poll timeout error'));
      }, timeoutMs);
    }

    resultTimer = setInterval(() => {
      const result = callback();
      if (result) {
        clearTimeout(resetTimer);
        clearInterval(resultTimer);
        resolve(result);
      }
    }, rate);
  });
}
