<template>
  <div class="SwiperArrow swiper-arrow" :class="`--${variant} --${type}`">
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7917 5.25L19.5417 14L10.7917 22.75"
        stroke="black"
        stroke-width="2.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SwiperArrow',
  props: {
    variant: {
      type: String,
      default: 'white',
    },
    type: {
      type: String,
      default: 'next',
    },
  },
};
</script>

<style>
.swiper-arrow {
  @apply flex items-center justify-center rounded-full cursor-pointer;
}

.--white.swiper-arrow {
  @apply bg-white;
  box-shadow:
    0px 92px 92px rgba(0, 0, 0, 0.05),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);
}
.--gray.swiper-arrow {
  @apply bg-sirius-gray-200;
}

.--prev.swiper-arrow {
  @apply rotate-180;
}
.--next.swiper-arrow {
  @apply rotate-0;
}
</style>
