<template>
  <div class="flex justify-center">
    <transition :name="transition" appear>
      <div v-if="false" class="ripple-loader ease-in duration-75">
        <div class="ripple">
          <div class="border-sirius-orange-100"></div>
          <div class="border-sirius-orange-100"></div>
        </div>
      </div>
      <div v-else class="">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UiRippleLoader',
  props: {
    transition: {
      type: String,
      default: '',
    },
  },
};
</script>

<style>
@keyframes ripple {
  0% {
    top: 48px;
    left: 48px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 9px;
    left: 9px;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}

.ripple div {
  position: absolute;
  border-width: 2px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple-loader {
  width: 80px;
  height: 80px;
}

.ripple {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.8);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ripple div:last-child {
  animation-delay: -0.5s;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 4px solid #ffa100;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffa100 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@media (min-width: 768px) {
  .lds-ring {
    width: 70px;
    height: 70px;
  }
  .lds-ring div {
    width: 54px;
    height: 54px;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
