export const sortOptions = [
  {
    order: 'popular_asc',
    title: 'Более популярные',
  },
  {
    order: 'popular_desc',
    title: 'Менее популярные',
  },
  {
    order: 'price_asc',
    title: 'Сначала подешевле',
  },
  {
    order: 'price_desc',
    title: 'Сначала подороже',
  },
];
