import axios from 'axios';
import { stringify } from 'qs';
import { installAxiosMeasureTime } from './utils/install-axios-measure-time';

const mapClient = axios.create({
  paramsSerializer: stringify,
  transformRequest: [data => stringify(data)],
});

installAxiosMeasureTime(mapClient);

export { mapClient };
