<template>
  <div class="">
    <div class="flex items-center font-medium text-xl">
      <img :src="IconSupport" width="32" height="32" />
      <p class="ml-3">
        {{ $t('careService') }}
      </p>
      <p
        class="ml-2 text-sirius-orange-100 hover:underline"
        @click="$emit('supportClicked', 'call')"
      >
        +{{ callNumber }}
      </p>
    </div>

    <a
      target="_blank"
      class="mt-2 font-medium text-xl flex items-center text-sirius-orange-100 hover:underline"
      @click="$emit('supportClicked', 'whatsapp')"
    >
      <img class="block mr-3" :src="IconWhatsapp" width="32" height="32" />
      {{ $t('writeWH') }}
    </a>
  </div>
</template>

<script>
import { i18n } from '../utils';
import { SupportService } from '@services/support';

export default {
  name: 'CommonDeskView',
  data() {
    return {
      callNumber: SupportService.formattedSupportNumber('call'),
      whatsAppNumber: SupportService.formattedSupportNumber('whatsapp'),
      IconSupport: new URL('@img/icons/support.svg', import.meta.url).href,
      IconWhatsapp: new URL('@img/icons/whatsapp-orange.svg', import.meta.url)
        .href,
    };
  },
  i18n,
};
</script>

<style></style>
