<template>
  <UiBottomSheetWrapper
    :show="show"
    with-buttons
    with-back-button
    @change="$emit('change', $event)"
    @onClose="$emit('onClose')"
  >
    <div class="notification-modal-content">
      <img
        class="notification-modal-img"
        :src="$i18n.locale === 'kk' ? ImgKZ : ImgRU"
        alt="image"
        height="254px"
        width="375px"
      />
      <p class="notification-modal-title">{{ $t('title') }}</p>
      <p class="notification-modal-descr" v-html="$t('descr')"></p>
    </div>
    <template #buttons>
      <div class="notification-modal-btns">
        <UiButton size="LG" @click="$emit('onSwitchOn')">
          {{ $t('btnSwitchOn') }}
        </UiButton>
        <UiButton size="LG" variant="gray" @click="$emit('onNotNow')">
          {{ $t('btnNotNow') }}
        </UiButton>
      </div>
    </template>
  </UiBottomSheetWrapper>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

import ImgRU from './img/modal-img.png';
import ImgKZ from './img/modal-img-kz.png';

export default {
  name: 'NotificationReminderModalView',
  components: { UiBottomSheetWrapper, UiButton },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      ImgKZ,
      ImgRU,
    };
  },
  i18n: {
    messages: {
      kk: {
        title: 'Хабарландыруды қосыңыз',
        descr:
          'Тапсырысыңыздың жай-күйі туралы ақпарат алып, тиімді науқандар, жеңілдіктер мен жаңа тауарлар жайлы хабардар болыңыз.',
        btnSwitchOn: 'Хабарландыруды қосу',
        btnNotNow: 'Қазір емес',
      },
      ru: {
        title: 'Включите уведомления',
        descr:
          'Получайте информацию о&nbsp;статусах вашего заказа и&nbsp;узнавайте о&nbsp;выгодных акциях, скидках и&nbsp;новинках.',
        btnSwitchOn: 'Включить уведомления',
        btnNotNow: 'Не сейчас',
      },
    },
  },
};
</script>

<style scoped>
.notification-modal-content {
  @apply pt-14 pb-28;
}
.notification-modal-img {
  @apply mx-auto;
}
.notification-modal-title {
  @apply mt-4 font-bold text-2xl leading-7;
}
.notification-modal-descr {
  @apply mt-3 text-base leading-18;
}
.notification-modal-btns {
  @apply flex flex-col w-full space-y-2;
}
</style>
