<template>
  <div class="LoaderFailed">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.3015 33.8174C32.8524 34.4362 32.7995 35.3902 32.1417 35.8938C23.9051 42.1999 12.0715 41.172 5.06219 33.299C-2.28265 25.0491 -1.54896 12.4071 6.70093 5.06226C14.5739 -1.94705 26.4469 -1.59907 33.8988 5.61745C34.4939 6.19376 34.4361 7.14755 33.8174 7.69842C33.1986 8.24928 32.2541 8.18795 31.6515 7.61951C25.3084 1.63621 15.3309 1.39571 8.69578 7.30292C1.68337 13.546 1.05974 24.2918 7.30286 31.3042C13.2101 37.9393 23.1484 38.8546 30.1411 33.6452C30.8054 33.1503 31.7507 33.1987 32.3015 33.8174Z"
        fill="white"
      />
      <path
        d="M34.1895 2.09146L35.817 8.47486C35.8312 8.53029 35.7958 8.5862 35.7396 8.59712L29.596 9.79167"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoaderFailed',
  components: {},
  inject: [],
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.LoaderFailed {
}
</style>
