<template>
  <div class="b-invite-friends">
    <p class="b-invite-friends__txt">
      <span v-html="$t('inviteFriends')" />
    </p>
    <UiButtonNew
      class="b-invite-friends__btn"
      @click="$router.push({ name: 'referral-share' })"
    >
      {{ $t('toInvite') }}
    </UiButtonNew>
  </div>
</template>

<script>
import UiButtonNew from '@ui/UiButtonNew.vue';

export default {
  name: 'InviteFriends',
  components: {
    UiButtonNew,
  },
  i18n: {
    messages: {
      ru: {
        inviteFriends: `
          Получайте промокоды за&nbsp;первые заказы друзей
        `,
        toInvite: 'Пригласить',
      },
      kk: {
        inviteFriends: `
          Достарыңыздың бірінші тапсырысы үшін промокод алыңыз
        `,
        toInvite: 'Шақыру',
      },
    },
  },
};
</script>

<style scoped>
.b-invite-friends {
  padding-top: 24px;
  padding-bottom: 16px;
  display: grid;
  grid-template-columns: auto 138px;
  gap: 4px;
  color: #7b7a8c;
  font-size: 14px;
}

.b-invite-friends__btn {
  font-size: 18px;
  font-weight: 900;
}

.b-invite-friends__txt {
  padding-top: 6px;
  letter-spacing: 0.005em;
}
</style>
