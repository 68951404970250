/**
 * Из массива товаров делает объект типа:
 * ```
 * {
 *  [`${product.sort_weight}_${product.category_id}`]: {
 *    id: 529,
 *    name: 'Плавленные сыры',
 *    products: [...products],
 *  },
 * }
 * ```
 *
 */
export function groupBySortWeight(products) {
  return products.reduce((acc, product) => {
    const key = `${product.sort_weight}_${product.category_id}`;
    if (!acc.hasOwnProperty(key)) {
      acc[key] = {
        id: product.category_id,
        name: product.category_name,
        products: [],
      };
    }
    acc[key].products.push(product);
    return acc;
  }, {});
}

export function groupByCategory(products) {
  return products.reduce((acc, product) => {
    if (!acc.hasOwnProperty(product.category_id)) {
      acc[product.category_id] = {
        id: product.category_id,
        name: product.category_name,
        products: [],
      };
    }
    acc[product.category_id].products.push(product);
    return acc;
  }, {});
}
