import { ClientService } from '@shared/services/client';
import { v4 as uuidv4 } from 'uuid';

/**
 * todo: сейчас работает не совсем корректно.
 * Ключ идемпотентности должен сохраняться
 * и передаваться со следующим запросом если предыдущий
 * завершился неудачей. Сейчас ключ просто при каждом запросе
 * передаётся новый ключ
 */
export function injectIdempotencyKey(config) {
  const { accessToken } = ClientService.getContext();
  if (config.useIdempotency || accessToken) {
    config.headers['X-Idempotency-key'] = uuidv4();
  }
  return config;
}
