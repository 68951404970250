<template>
  <UiBottomSheetWrapper
    :show="modelValue"
    with-back-button
    with-buttons
    with-title
    @change="$emit('update:modelValue', $event)"
  >
    <template #title>{{ $t('applyConditions') }}</template>
    <div class="conditions-content">
      <div>
        <ConditionsList :min-price="minPrice" />
      </div>
    </div>
    <template #buttons>
      <UiButton size="LG" @click="$emit('update:modelValue', false)">
        {{ $t('gotIt') }}
      </UiButton>
    </template>
  </UiBottomSheetWrapper>
</template>

<script>
import ConditionsList from '@pure-ui/components/Referral/ConditionsList/ConditionsList.vue';
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'ConditionsModal',
  components: { UiBottomSheetWrapper, UiButton, ConditionsList },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    minPrice: {
      type: Number,
      required: true,
    },
  },
  i18n: {
    messages: {
      ru: {
        applyConditions: 'Условия применения',
        cond2: `Делиться промокодом можно после 3-го заказа. Срок действия промокода за&nbsp;приглашённых друзей — 7 дней. Количество приглашений — не ограничено.`,
        cond3: `Чтобы ваш друг мог воспользоваться промокодом, ему необходимо скачать приложение Choco, зарегистрироваться, при оформлении заказа ввести ваш промокод в корзине. 1500&nbsp;₸ отнимется от&nbsp;общей суммы заказа в&nbsp;качестве скидки. Для&nbsp;использования промокода, нужно быть новым клиентом. `,
        cond4: `Компания Choco Рядом вправе отказать в&nbsp;скидке, списать бонусы или&nbsp;деактивировать промокод, в случае подозрительной активности. Скидка по&nbsp;реферальному промокоду не&nbsp;суммируется с другими промокодами. `,
        cond5: `
         Промокод действует при заказе от {minPrice}&nbsp;₸. За&nbsp;1 заказ можно использовать только 1&nbsp;промокод. Если у вас возникли проблемы, обратитесь в&nbsp;службу заботы.
        `,
        gotIt: 'Понятно',
      },
      kk: {
        applyConditions: 'Қолдану шарттары',
        cond2: `Промокодпен 3-ші тапсырыстан кейін ғана 
        бөлісуге болады. Шақырған досыңыз үшін 
        берілген промокод мерзімі – 7 күн. 
        Шақырулар санында шектеу жоқ.`,
        cond3: `Досыңыз промокодты пайдалана алуы үшін, 
        оған Choco қосымшасын жүктеп, тіркеліп, 
        тапсырыс рәсімдеу кезінде себетте 
        сіздің&nbsp;промокодыңызды еңгізуі керек. 
        Тапсырыстың жалпы сомасынан 1500&nbsp;₸ 
        жеңілдік есебінде шегеріледі. 
        Промокодты&nbsp;қолдану үшін сервисіміздің жаңа қолданушысы болу қажет.`,
        cond4: `Choco Рядом компаниясы күмәнді әрекет 
        байқаса жеңілдік жасаудан, бонус беруден 
        бас тартуға, промокодты жоюға құқылы. 
        Рефералдық бағдарлама бойынша 
        жеңілдіктер өзге промокодтардан 
        бөлек&nbsp;есептеледі.`,
        cond5: `
          Промокод {minPrice}&nbsp;₸ жоғары тапсырыстар 
          үшін&nbsp;жарамды. 1&nbsp;тапсырысқа тек&nbsp;1&nbsp;промокод 
          пайдалануға болады. Егер сізде 
          қиындықтар туындаса, қолдау қызметіне 
          хабарласыңыз.
        `,
        gotIt: 'Түсінікті',
      },
    },
  },
};
</script>

<style scoped>
.conditions-content {
  @apply px-2 pb-13 pt-2;
}
</style>
