<template>
  <ul
    class="conds-box"
    :style="$isDesktop ? 'max-width:427px;' : 'max-width: 326px;'"
  >
    <li class="conds-box-item" v-html="conds1" />
    <li class="conds-box-item" v-html="$t('cond2')" />
    <li class="conds-box-item" v-html="$t('cond3')" />
  </ul>
</template>

<script>
export default {
  name: 'CondsBox',
  props: {
    condition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      counts: {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fives',
      },
    };
  },
  computed: {
    conds1() {
      const conds = {
        ...this.condition,
        count: this.$t(this.counts[this.condition.count]),
      };
      return this.$t('cond1', { ...conds });
    },
  },
  i18n: {
    messages: {
      kk: {
        first: 'бірінші',
        second: 'екінші',
        third: 'үшінші',
        fourth: 'төртінші',
        fives: 'бесінші',

        cond1: `{count} тапсырыс үшін кешбэк жалпы соманың {percentage}% құнын құрайды, бірақ {maxBonus} бонустан аса алмайды`,
        cond2: `тапсырысты қайтарған жағдайда, кешбэк тапсырыс құнына байланысты пропорционалды ұсталады`,
        cond3: `толық қайтарым болғанда: кешбэкте толық ұсталады`,
      },
      ru: {
        first: 'первый',
        second: 'второй',
        third: 'третий',
        fourth: 'четвертый',
        fives: 'пятый',

        cond1: `кешбэк за {count} заказ составляет {percentage}% суммы заказа, но не более {maxBonus} бонусов;`,
        cond2: `при возврате заказа частично: кешбэк возвращается пропорцилнально стоимости товара (–ов) в заказе.`,
        cond3: `при полном возврате заказа: кешбэк возвращается полностью.`,
      },
    },
  },
};
</script>

<style scoped>
.conds-box {
  @apply pl-0.5 text-sirius-gray-700;
  list-style-type: disc;
}
.conds-box-item::marker {
  @apply text-sm leading-5;
}
</style>
