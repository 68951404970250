<template>
  <div class="WaQuestion">
    <p class="WaQuestion__title" v-html="$t('askQuestion')" />
    <p class="WaQuestion__descr" v-html="$t('writeToManager')" />
    <WaButton class="WaQuestion__waBtn">
      <span v-html="$t($isDesktop ? 'toWrite' : 'toWriteToWa')" />
    </WaButton>
  </div>
</template>

<script>
import WaButton from '@/views/micromarket/components/MmQuestions/WaButton.vue';

export default {
  components: { WaButton },
  i18n: {
    messages: {
      ru: {
        askQuestion: 'Задайте вопрос',
        writeToManager: 'Напишите нашему менеджеру на&nbsp;WhatsApp',
        toWrite: 'Написать',
        toWriteToWa: 'написать в Whatsapp',
      },
      kk: {
        askQuestion: 'Сұрақ қойыңыз',
        writeToManager: 'Менеджерімізге WhatsApp-қа жазыңыз',
        toWrite: 'Жазыңыз',
        toWriteToWa: 'WhatsApp-қа жазыңыз',
      },
    },
  },
};
</script>

<style scoped>
.WaQuestion {
  border-radius: 16px;
  padding: 20px 20px 24px;
  background: #f1f2f7;
}

.WaQuestion__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px; /* 120% */
  margin: 0;
}

.WaQuestion__descr {
  padding-top: 12px;
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}

.WaQuestion__waBtn {
  margin-top: 32px;
  width: 100%;
}

.WaQuestion__waBtnIcon {
  margin-right: 8px;
  vertical-align: -5px;
}

@media (min-width: 768px) {
  .WaQuestion {
    padding: 32px 24px;
  }

  .WaQuestion__title {
    font-size: 24px;
    line-height: 30px;
  }

  .WaQuestion__descr {
    font-size: 18px;
    line-height: 28px;
  }

  .WaQuestion__waBtnIcon {
    width: 28px;
  }

  .WaQuestion__waBtn {
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
