<template>
  <li class="QItem" @click="opened = !opened">
    <div class="QItem__question">
      <slot name="question">
        <span v-html="question" />
      </slot>
    </div>
    <div v-if="opened" class="QItem__answer">
      <slot name="answer">
        <span v-html="answer" />
      </slot>
    </div>
    <ArrowDownIcon class="QItem__arrow" :class="{ QItem__arrow_up: opened }" />
  </li>
</template>

<script>
import ArrowDownIcon from './icons/arrow-down.svg';

export default {
  name: 'QItem',
  components: { ArrowDownIcon },
  props: {
    question: {
      type: String,
      default: '',
    },
    answer: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    opened: false,
  }),
};
</script>

<style scoped>
.QItem {
  -webkit-tap-highlight-color: transparent;
  border-radius: 16px;
  display: grid;
  grid-template-columns: auto 24px;
  gap: 0 12px;
  padding: 20px 20px 24px;
  background: #f1f2f7;
  cursor: pointer;
}

.QItem__question {
  font-weight: 500;
}

.QItem__answer {
  padding-top: 4px;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
  color: #7b7a8c;
  grid-row: 2;
}

.QItem__arrow {
  width: 24px;
  grid-column: 2;
}

.QItem__arrow_up {
  transform: rotate(180deg);
}

@media (min-width: 768px) {
  .QItem {
    padding: 20px 24px 20px;
    gap: 0 48px;
    border-radius: 24px;
  }

  .QItem__question {
    font-size: 18px;
    line-height: 24px;
  }

  .QItem__answer {
    font-size: 16px;
    line-height: 22px;
    padding-top: 9px;
  }
}
</style>
