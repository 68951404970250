<template>
  <div class="TellMoreComment tell-more-comment">
    <UiTextArea :value="modelValue" rows="1" no-resize :label="$t('comment')" />
    <div class="tell-more-comment-cover" @click.stop="$emit('onComment')"></div>
  </div>
</template>

<script>
import UiTextArea from '@pure-ui/components/UiTextArea/UiTextArea.vue';

export default {
  name: 'TellMoreComment',
  components: { UiTextArea },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  i18n: {
    messages: {
      kk: {
        comment: 'Түсініктеме',
      },
      ru: {
        comment: 'Комментарий',
      },
    },
  },
};
</script>

<style scoped>
.tell-more-comment {
  @apply relative;
}
.tell-more-comment-cover {
  @apply top-0 left-0 w-full h-full absolute bg-transparent;
}
</style>
