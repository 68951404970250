<template>
  <MainLayout>
    <template v-if="!isDesktop" #header>
      <WebMobileHeader />
    </template>
    <div class="mt-28 md:mt-40" style="min-height: 40vh">
      <UiRippleLoader class="flex justify-center" />
    </div>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import MainLayout from '@shared/components/layout/MainLayout.vue';
import UiRippleLoader from '@shared/components/ui/UiRippleLoader.vue';
import WebMobileHeader from '@components/header/WebMobileHeader.vue';

export default {
  name: 'AuthView',
  components: {
    WebMobileHeader,
    UiRippleLoader,
    MainLayout,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['isDesktop']),
  },
};
</script>
