export const QuestionnaireModule = {
  namespaced: true,
  state: {
    isVisible: true,
  },
  getters: {
    isVisible(state) {
      return state.isVisible;
    },
  },
  mutations: {
    DISABLE(state) {
      state.isVisible = false;
    },
  },
};
