<template>
  <HeaderBaseWrapper hideable>
    <template #header-top>
      <HeaderBase>
        <template #left>
          <IconBack class="header-icon" @click.native="$emit('onBackClick')" />
        </template>
        <AddressContext class="header-center" style="" />
        <template #right>
          <IconClose class="header-icon" @click.native="$emit('onClose')" />
        </template>
      </HeaderBase>
    </template>
    <template #header-middle>
      <SearchTrigger disabled @click.native="$emit('onSearch')" />
      <IconFavorite @click.native="$emit('onFavorite')" />
    </template>
  </HeaderBaseWrapper>
</template>

<script>
import SearchTrigger from '@pure-ui/components/SearchTrigger/SearchTrigger.vue';
import AddressContext from '@components/address-context/AddressContext.vue';
import HeaderBaseWrapper from './components/HeaderBaseWrapper.vue';
import IconFavorite from './components/IconFavorite.vue';
import IconBack from './components/IconBack.vue';
import HeaderBase from './components/HeaderBase.vue';
import IconClose from './components/IconClose.vue';

export default {
  components: {
    HeaderBaseWrapper,
    AddressContext,
    SearchTrigger,
    IconFavorite,
    IconBack,
    HeaderBase,
    IconClose,
  },
};
</script>

<style scoped>
.header-icon {
  @apply my-1;
}
.header-center {
  padding-top: 8px;
}
</style>
