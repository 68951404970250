<template>
  <ul class="text-base leading-18 text-sirius-black-100">
    <li
      v-for="(item, index) in listItems"
      :key="index"
      class="flex items-center justify-between h-8 mb-3"
      :class="{ 'font-bold': value.includes(item[valueId]) }"
      @click="selectItem(item[valueId])"
    >
      {{ item[text] }}
      <div v-if="value.includes(item[valueId])" class="">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M27 9L13 23L6 16" stroke="black" stroke-width="2" />
        </svg>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'UiList',
  props: {
    value: {
      type: Array,
      default: () => [],
      validator: e => {
        if (Array.isArray(e)) return e;
        return [];
      },
    },
    valueId: {
      type: String,
      default: 'id',
    },
    text: {
      type: String,
      default: 'title',
    },
    listItems: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    selectItem(index) {
      const newVal = [];

      const foundIdx = this.value.findIndex(e => e === index);

      if (foundIdx === -1) {
        newVal.push(...this.value, this.listItems.find(v => v.id === index).id);
      } else {
        newVal.push(...this.value.filter(v => v !== index));
      }

      const emitData = {
        action: foundIdx === -1 ? 'select' : 'deselect',
        categoryId: this.listItems.find(v => v.id === index).id,
        categoryName: this.listItems.find(v => v.id === index).name,
        tags: newVal,
      };

      this.$emit('onChange', emitData);
    },
  },
};
</script>

<style></style>
