import {
  getLocalStorageLongestKey,
  getLocalStorageSize,
} from '@/utils/functions/localstorage';

export const getDeviceParams = () => {
  const root = document.documentElement;
  const { fontSize } = window.getComputedStyle(root);
  const longestKey = getLocalStorageLongestKey();
  return {
    screen: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    fontSize,
    localStorage: { ...window.localStorage },
    localStorageSize: getLocalStorageSize(),
    localStorageLongestKey: longestKey,
    localStorageLongestKeyLength: window.localStorage[longestKey]?.length,
    localStorageKeysCount: window.localStorage?.length,
  };
};
