<template>
  <div
    class="CategoryBlock w-full flex items-center cursor-pointer"
    @mouseover="onMouseOver"
    @mouseleave="isHovered = false"
    @click="onNavigate"
  >
    <div
      v-if="withImage"
      class="rounded-lg overflow-hidden"
      style="
        background: #f7f3f1;
        min-width: 40px;
        width: 40px;
        min-height: 40px;
        height: 40px;
      "
    >
      <img
        class="w-full h-full rounded-lg overflow-hidden object-contain object-left-bottom"
        :src="getImg(category)"
        width="100%"
        height="100%"
        alt=""
      />
    </div>
    <p
      class="ml-2 transition-all duration-100"
      :class="{
        'text-sirius-orange-100': isHovered || isActive,
        'font-medium': type !== 'child',
      }"
    >
      {{ category.name }}
    </p>

    <IconRightArrowLine
      v-if="expandable"
      class="ml-auto transition-all duration-100"
      :class="arrowClasses"
    />
  </div>
</template>

<script>
import IconRightArrowLine from '@shared/components/icons/IconRightArrowLine.vue';
import NoPhoto from '@img/no-photo.png';

export default {
  name: 'CategoryBlock',
  components: { IconRightArrowLine },
  props: {
    category: {
      type: Object,
      required: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    withImage: {
      type: Boolean,
      default: true,
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'catalog',
    },
    isGroupIcon: {
      type: Boolean,
      default: false,
    },
    isChild: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    arrowClasses() {
      if (this.type === 'category') {
        if (this.isHovered) return 'text-sirius-orange-100';
        return this.isActive ? 'text-transparent' : 'text-sirius-gray-100';
      }
      if (this.type === 'catalog') {
        return this.expandable && (this.isActive || this.isHovered)
          ? 'text-sirius-orange-100'
          : 'text-transparent';
      }
      if (this.type === 'search') {
        return this.isHovered
          ? 'text-sirius-orange-100'
          : 'text-sirius-gray-100';
      }
      return '';
    },
  },
  methods: {
    onMouseOver() {
      if (this.type === 'catalog') {
        if (this.category.categories?.length > 0) {
          this.isHovered = true;
          if (this.expandable) this.$emit('onHover', this.category);
        }
      } else {
        this.isHovered = true;
      }
    },
    onNavigate() {
      if (this.isActive && !this.isCategory) return;
      this.$emit('onNavigate');
    },
    getImg(category) {
      if (this.isChild) {
        return category.icon_small ?? NoPhoto;
      }
      return category.icon ?? NoPhoto;
    },
  },
};
</script>
