<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M23.5879 11.4526C23.5879 17.7777 15.198 23.1899 12.1353 23.1899C9.0725 23.1899 0.682617 17.7777 0.682617 11.4526C0.682617 5.12764 5.81026 0 12.1353 0C18.4603 0 23.5879 5.12764 23.5879 11.4526Z"
      fill="url(#paint0_radial_1201_3510)"
    />
    <path
      d="M23.1487 11.4525C23.1487 17.5347 14.8901 22.7509 12.1354 22.7509C9.38058 22.7509 1.12207 17.5352 1.12207 11.4525C1.12207 5.3698 6.05265 0.439209 12.1354 0.439209C18.2181 0.439209 23.1487 5.3698 23.1487 11.4525Z"
      fill="url(#paint1_linear_1201_3510)"
    />
    <path
      d="M22.3289 11.4526C22.3289 17.1797 14.4231 22.1078 12.1349 22.1078C9.84669 22.1078 1.94092 17.1802 1.94092 11.4526C1.94092 5.72508 6.50514 1.08252 12.1349 1.08252C17.7646 1.08252 22.3289 5.72557 22.3289 11.4526Z"
      fill="url(#paint2_radial_1201_3510)"
    />
    <g opacity="0.5">
      <path
        d="M6.22108 15.3512C5.7647 15.0982 5.24508 14.9644 4.71768 14.9644H4.70599C4.70599 14.9644 4.70454 14.9644 4.70356 14.9644C4.65004 14.9644 3.93335 14.9716 3.68668 15.3453C3.41373 15.7594 3.5422 16.026 3.61177 16.1695C3.62102 16.189 3.62929 16.2065 3.6361 16.2211C3.64486 16.2405 3.66576 16.2785 3.6935 16.3301C3.88665 16.6901 4.33817 17.5333 4.36104 18.2066C4.37417 18.6056 4.25936 19.0907 4.14794 19.5602C4.04577 19.9913 3.94018 20.4365 3.9538 20.7483C3.96596 21.0295 4.1781 21.9467 6.3831 21.9467C6.74119 21.9467 8.54429 21.9014 8.85908 21.0086C8.95249 20.743 8.9384 20.4506 8.88877 20.093C8.66351 18.4791 8.01787 16.3461 6.22205 15.3507L6.22108 15.3512Z"
        fill="#FA9103"
      />
      <path
        d="M17.9396 15.3512C18.3959 15.0982 18.9156 14.9644 19.443 14.9644H19.4547C19.4547 14.9644 19.4561 14.9644 19.4571 14.9644C19.5106 14.9644 20.2273 14.9716 20.4739 15.3453C20.7469 15.7594 20.6185 16.026 20.5489 16.1695C20.5396 16.189 20.5314 16.2065 20.5245 16.2211C20.5158 16.2405 20.4949 16.2785 20.4672 16.3301C20.274 16.6901 19.8225 17.5333 19.7996 18.2066C19.7865 18.6056 19.9013 19.0907 20.0127 19.5602C20.1149 19.9913 20.2205 20.4365 20.2069 20.7483C20.1947 21.0295 19.9825 21.9467 17.7776 21.9467C17.4195 21.9467 15.6163 21.9014 15.3015 21.0086C15.2081 20.743 15.2222 20.4506 15.2719 20.093C15.4971 18.4791 16.1428 16.3461 17.9386 15.3507L17.9396 15.3512Z"
        fill="#FA9103"
      />
    </g>
    <path
      d="M3.58797 13.8134C3.58797 13.8134 2.52441 13.7827 2.12399 14.5841C1.72308 15.3854 1.98532 15.925 2.04711 16.1099C2.1089 16.2947 2.72292 17.6356 2.74725 18.5912C2.77157 19.5468 2.30935 21.0263 2.34001 21.9508C2.37066 22.8752 3.09512 24.0005 5.25292 24.0005C6.37585 24.0005 7.86515 23.6429 8.19648 22.4032C8.31617 21.9546 8.28842 21.4803 8.23977 21.0185C8.00282 18.7771 7.32896 15.8462 5.37745 14.4177C4.85588 14.0362 4.23749 13.8139 3.58845 13.8139L3.58797 13.8134Z"
      fill="#ED6200"
    />
    <path
      d="M5.09167 14.8077C4.6353 14.474 4.11568 14.2974 3.58827 14.2974H3.57658C3.57658 14.2974 3.57513 14.2974 3.57416 14.2974C3.52064 14.2974 2.80397 14.3071 2.55729 14.8004C2.28435 15.3468 2.41279 15.6991 2.48237 15.8883C2.49161 15.9141 2.49988 15.937 2.50669 15.9565C2.51545 15.9822 2.53638 16.0319 2.56411 16.1005C2.75727 16.5758 3.20876 17.6885 3.23163 18.5784C3.24477 19.1049 3.12995 19.7456 3.01853 20.3655C2.91636 20.9342 2.81077 21.5225 2.82439 21.9341C2.83656 22.3053 3.04869 23.5158 5.25369 23.5158C5.61178 23.5158 7.4149 23.456 7.72969 22.2776C7.82311 21.9273 7.80899 21.5405 7.75937 21.0685C7.5341 18.938 6.88846 16.1219 5.09264 14.8073L5.09167 14.8077Z"
      fill="#FA9103"
    />
    <path
      d="M4.04397 15.5765C2.68506 15.5487 3.66303 17.5289 3.74622 18.1931C3.8591 19.0956 3.54477 19.9898 3.43092 20.8924C3.31853 21.7823 3.6669 22.533 4.58014 22.7704C5.38292 22.9791 6.55598 22.8994 7.06296 22.1369C7.3724 21.6713 7.3067 21.0714 7.24296 20.5465C7.16609 19.9105 7.00991 19.2868 6.84059 18.6704C6.50829 17.4589 5.92737 16.3418 4.75529 15.7633C4.53684 15.6558 4.27994 15.5818 4.04397 15.5769V15.5765Z"
      fill="#FDC304"
    />
    <path
      d="M20.5313 13.8131C20.5313 13.8131 21.5949 13.7825 21.9953 14.5838C22.3962 15.3852 22.134 15.9247 22.0722 16.1096C22.0104 16.2945 21.3964 17.6354 21.372 18.591C21.3477 19.5465 21.8099 21.0261 21.7793 21.9505C21.7486 22.8749 21.0242 24.0003 18.8664 24.0003C17.7434 24.0003 16.2541 23.6427 15.9228 22.403C15.8031 21.9544 15.8308 21.48 15.8795 21.0183C16.1164 18.7768 16.7903 15.8459 18.7418 14.4174C19.2634 14.036 19.8818 13.8136 20.5308 13.8136L20.5313 13.8131Z"
      fill="#ED6200"
    />
    <path
      d="M19.0279 14.8077C19.4843 14.474 20.0039 14.2974 20.5313 14.2974H20.543C20.543 14.2974 20.5445 14.2974 20.5455 14.2974C20.599 14.2974 21.3157 14.3071 21.5623 14.8004C21.8353 15.3468 21.7068 15.6991 21.6372 15.8883C21.628 15.9141 21.6197 15.937 21.6129 15.9565C21.6042 15.9822 21.5833 16.0319 21.5555 16.1005C21.3624 16.5758 20.9108 17.6885 20.888 18.5784C20.8748 19.1049 20.9897 19.7456 21.1011 20.3655C21.2033 20.9342 21.3088 21.5225 21.2952 21.9341C21.2831 22.3053 21.0709 23.5158 18.8659 23.5158C18.5078 23.5158 16.7047 23.456 16.3899 22.2776C16.2965 21.9273 16.3106 21.5405 16.3602 21.0685C16.5855 18.938 17.2312 16.1219 19.027 14.8073L19.0279 14.8077Z"
      fill="#FA9103"
    />
    <path
      d="M20.0776 15.5765C21.4365 15.5487 20.4586 17.5289 20.3754 18.1931C20.2625 19.0956 20.5768 19.9898 20.6906 20.8924C20.803 21.7823 20.4547 22.533 19.5414 22.7704C18.7386 22.9791 17.5656 22.8994 17.0586 22.1369C16.7492 21.6713 16.8149 21.0714 16.8786 20.5465C16.9555 19.9105 17.1117 19.2868 17.281 18.6704C17.6133 17.4589 18.1942 16.3418 19.3663 15.7633C19.5847 15.6558 19.8416 15.5818 20.0776 15.5769V15.5765Z"
      fill="#FDC304"
    />
    <path
      d="M10.2764 8.85859C10.0847 7.89232 9.5374 7.10413 8.77548 6.69641C8.00431 6.28383 7.08914 6.29939 6.1978 6.74068C5.37555 7.14743 4.6642 7.86994 4.19372 8.77588C3.20215 10.6851 3.68287 13.0034 5.2432 13.8378C5.60811 14.0329 6.00512 14.1254 6.41187 14.1254C7.68903 14.1254 9.06447 13.2151 9.84342 11.7155C10.31 10.8169 10.4638 9.80247 10.2764 8.85907V8.85859Z"
      fill="#FFD459"
    />
    <path
      d="M4.61452 9.00137C3.75578 10.6551 4.13235 12.6971 5.46449 13.4099C6.79664 14.1227 8.5618 13.1433 9.42054 11.49C10.2793 9.83626 9.88421 7.83756 8.55207 7.12478C7.21992 6.412 5.47326 7.34811 4.61452 9.00137Z"
      fill="#FFD459"
    />
    <path
      d="M10.3767 8.54755C10.182 7.58129 9.62642 6.79309 8.85331 6.38538C8.07047 5.97279 7.14166 5.98836 6.23719 6.42965C5.40277 6.83639 4.68026 7.55891 4.20296 8.46484C3.1968 10.374 3.6848 12.6924 5.26848 13.5268C5.63874 13.7219 6.04161 13.8143 6.45468 13.8143C7.75131 13.8143 9.14668 12.904 9.93731 11.4045C10.4107 10.5059 10.5669 9.49144 10.3767 8.54804V8.54755Z"
      fill="#706E6B"
    />
    <path
      d="M4.63157 8.69009C3.76018 10.3438 4.14212 12.3858 5.4947 13.0986C6.84679 13.8114 8.63822 12.832 9.51009 11.1787C10.3815 9.52499 9.98057 7.52628 8.62848 6.8135C7.27639 6.10072 5.50346 7.03683 4.63207 8.69009H4.63157Z"
      fill="#CECECE"
    />
    <path
      d="M4.83161 9.08264C4.12807 10.5286 4.52557 12.2792 5.72197 12.8616C6.91885 13.444 8.44221 12.5575 9.14575 11.1115C9.84928 9.66553 9.43719 7.95242 8.2403 7.37052C7.04342 6.78813 5.53515 7.63714 4.83161 9.08264Z"
      fill="white"
    />
    <path
      d="M13.8414 8.85859C14.0331 7.89232 14.5805 7.10413 15.3424 6.69641C16.1136 6.28383 17.0287 6.29939 17.9201 6.74068C18.7423 7.14743 19.4537 7.86994 19.9241 8.77588C20.9157 10.6851 20.435 13.0034 18.8747 13.8378C18.5098 14.0329 18.1128 14.1254 17.706 14.1254C16.4288 14.1254 15.0534 13.2151 14.2744 11.7155C13.8078 10.8169 13.6541 9.80247 13.8414 8.85907V8.85859Z"
      fill="#FFD459"
    />
    <path
      d="M19.502 9.00137C20.3607 10.6551 19.9841 12.6971 18.652 13.4099C17.3199 14.1227 15.5547 13.1433 14.696 11.49C13.8372 9.83626 14.2323 7.83756 15.5644 7.12478C16.8966 6.412 18.6432 7.34811 19.502 9.00137Z"
      fill="#FFD459"
    />
    <path
      d="M13.7416 8.54755C13.9362 7.58129 14.4918 6.79309 15.2649 6.38538C16.0477 5.97279 16.9765 5.98836 17.881 6.42965C18.7154 6.83639 19.4379 7.55891 19.9152 8.46484C20.9214 10.374 20.4334 12.6924 18.8497 13.5268C18.4795 13.7219 18.0766 13.8143 17.6636 13.8143C16.3669 13.8143 14.9715 12.904 14.1809 11.4045C13.7075 10.5059 13.5513 9.49144 13.7416 8.54804V8.54755Z"
      fill="#706E6B"
    />
    <path
      d="M19.4889 8.69033C20.3603 10.3441 19.9783 12.3861 18.6258 13.0989C17.2737 13.8116 15.4822 12.8322 14.6103 11.179C13.739 9.52523 14.1399 7.52653 15.492 6.81375C16.8441 6.10097 18.617 7.03707 19.4884 8.69033H19.4889Z"
      fill="#CECECE"
    />
    <path
      d="M19.2875 9.08264C19.991 10.5286 19.5935 12.2792 18.3971 12.8616C17.2002 13.444 15.6769 12.5575 14.9733 11.1115C14.2698 9.66553 14.6819 7.95242 15.8788 7.37052C17.0756 6.78813 18.5839 7.63714 19.2875 9.08264Z"
      fill="white"
    />
    <path
      d="M12.0997 12.2676H12.018C10.6513 12.2676 9.54346 13.3755 9.54346 14.7421V17.492C9.54346 18.8587 10.6513 19.9666 12.018 19.9666H12.0997C13.4664 19.9666 14.5743 18.8587 14.5743 17.492V14.7421C14.5743 13.3755 13.4664 12.2676 12.0997 12.2676Z"
      fill="#F2A602"
    />
    <path
      d="M12.0589 12.6016C10.6756 12.6016 9.54346 13.7337 9.54346 15.117V15.7276C9.54346 14.3667 10.6571 13.253 12.018 13.253H12.1002C13.4611 13.253 14.5748 14.3662 14.5748 15.7276V15.117C14.5748 13.7337 13.4426 12.6016 12.0593 12.6016H12.0589Z"
      fill="#6B2300"
    />
    <path
      d="M12.0589 20.5584C10.6756 20.5584 9.54346 19.4262 9.54346 18.043V18.4779C9.54346 19.8388 10.6571 20.9525 12.018 20.9525H12.1002C13.4611 20.9525 14.5748 19.8388 14.5748 18.4779V18.043C14.5748 19.4262 13.4426 20.5584 12.0593 20.5584H12.0589Z"
      fill="#FDD966"
    />
    <path
      d="M12.1002 13.2529H12.018C10.6571 13.2529 9.54346 14.3661 9.54346 15.7275V18.0434C9.54346 19.4266 10.6756 20.5588 12.0589 20.5588C13.4426 20.5588 14.5743 19.4266 14.5743 18.0434V15.7275C14.5743 14.3666 13.4606 13.2529 12.0997 13.2529H12.1002Z"
      fill="#9F4201"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1201_3510"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.2009 0.0287059) scale(22.9598 22.9598)"
      >
        <stop stop-color="#444EC9" />
        <stop offset="0.28" stop-color="#464FC5" />
        <stop offset="0.51" stop-color="#855F80" />
        <stop offset="0.77" stop-color="#D55E00" />
        <stop offset="1" stop-color="#E57100" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_1201_3510"
        x1="12.1354"
        y1="0.314168"
        x2="12.1354"
        y2="22.6862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#066FF5" />
        <stop offset="1" stop-color="#F87E02" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_1201_3510"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.1923 1.22702) scale(20.7986 20.7986)"
      >
        <stop stop-color="white" />
        <stop offset="0.28" stop-color="#C1D2F3" />
        <stop offset="0.51" stop-color="#C1C8CB" />
        <stop offset="0.65" stop-color="#FBD000" />
        <stop offset="0.77" stop-color="#FBC802" />
        <stop offset="1" stop-color="#F89401" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'EmojiScream',
};
</script>
