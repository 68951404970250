export function getObjectDifferences(obj1, obj2) {
  const differences = {};

  function compareObjects(innerObj1, innerObj2, path = []) {
    if (innerObj2 === null) innerObj2 = {};
    if (innerObj1 === null) innerObj1 = {};
    Object.keys(innerObj1).forEach(key => {
      const newPath = path.concat(key);
      if (innerObj1[key] !== innerObj2[key]) {
        if (
          typeof innerObj1[key] === 'object' &&
          typeof innerObj2[key] === 'object'
        ) {
          compareObjects(innerObj1[key], innerObj2[key], newPath);
        } else {
          differences[newPath.join('.')] = [innerObj1[key], innerObj2[key]];
        }
      }
    });

    Object.keys(innerObj2).forEach(key => {
      if (!innerObj1.hasOwnProperty(key)) {
        differences[path.concat(key).join('.')] = [undefined, innerObj2[key]];
      }
    });
  }

  compareObjects(obj1, obj2);
  return differences;
}

export function hasDifferences(obj1, obj2) {
  return Object.keys(getObjectDifferences(obj1, obj2)).length === 0
    ? false
    : obj2;
}
