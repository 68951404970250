<template>
  <MainLayout
    class="text-sirius-black-100"
    with-checkout-reminder
    with-bottom-toolbar
    :sticky-header="!$isWebView && hasReferralPlank"
  >
    <template v-if="$isWebView" #header>
      <CatalogHeader
        @onBackClick="onBackClick"
        @onClose="onClose"
        @onSearch="goToSearch"
        @onFavorite="goFavorite"
      />
    </template>
    <!-- <template v-else-if="!$isDesktop" #header>
      <WebMobileHeaderMain />
    </template> -->
    <template v-if="!$isWebView" #header-wrapper>
      <header
        class="main-header-desk"
        :class="[hasReferralPlank ? '--sticky' : '--fixed']"
      >
        <DeskHeader v-if="$isDesktop" />
        <WebMobileHeaderMain v-else />
      </header>
    </template>

    <div class="flex flex-col container mt-4 md:mt-16" style="max-width: 988px">
      <UiRippleLoader
        v-if="!$store.getters['catalog/hasMainCategories']"
        class="mx-auto mt-16"
      />
      <div v-else class="md:mt-16">
        <SimpleCategories
          :categories="$store.state.catalog['simpleCategories']"
        />
      </div>
    </div>
    <UpButton />
  </MainLayout>
</template>

<script>
import SimpleCategories from '@/components/SimpleCategories/SimpleCategories.vue';
import DeskHeader from '@/components/header/desk-header/DeskHeader.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import CatalogHeader from '@/components/header/CatalogHeader.vue';
import MainLayout from '@layout/MainLayout.vue';
import WebMobileHeaderMain from '@/components/header/WebMobileHeaderMain.vue';
import { RahmetApp } from '@shared/RahmetApp';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import UpButton from '@shared/components/product/UpButton.vue';

export default {
  name: 'CatalogView',
  components: {
    UpButton,
    SimpleCategories,
    DeskHeader,
    UiRippleLoader,
    CatalogHeader,
    MainLayout,
    WebMobileHeaderMain,
  },
  methods: {
    onBackClick() {
      this.$router.back();
    },
    closeMiniApp() {
      Analitycs.logEvent(EVENTS.CROSS_EXIT, { from: 'main' });
      RahmetApp.hapticSelection();
      RahmetApp.backToRahmetApp();
    },
    onClose() {
      RahmetApp.hapticSelection();
      if (this.$store.getters['cart/cartSize']) {
        Analitycs.logEvent(EVENTS.CROSS_EXIT_CONFIRM);
        this.popup.show('confirm-close-cart');
        return;
      }

      this.closeMiniApp();
    },
    goFavorite() {
      RahmetApp.hapticSelection();
      this.$router.push({
        name: 'favorites',
        params: { from: 'main' },
      });
    },
    goToSearch() {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.SEARCH_CLICKED, { page: 'main' });
      this.$router.push({ name: 'search' });
    },
  },
};
</script>
