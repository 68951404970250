<template>
  <div
    class="UiTextField ui-text-field"
    :class="`--${size}
      ${value || focused ? '--focused' : ''}
      ${clearable ? '--clearable' : ''}
      ${invalid ? '--invalid' : ''}
    `"
  >
    <label
      :for="`${$attrs.name}_id`"
      class="ui-text-field-box"
      :class="`${borderColor} --${variant}`"
    >
      <div v-if="label" class="ui-text-field-label">
        {{ label }}
      </div>

      <input
        :id="`${$attrs.name}_id`"
        :ref="$attrs.name"
        v-auto-focus="{ disabled: !autofocus }"
        :value="value"
        class="ui-text-field-input"
        :class="{ 'ui-text-field-input__labeled': label }"
        v-bind="$attrs"
        v-on="$listeners"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
      />

      <UiInlineIcon
        v-if="isRequired"
        name="input-required"
        class="ui-text-field-required"
      />

      <div class="ui-text-field-right">
        <transition name="scale">
          <slot name="icon">
            <UiInlineIcon
              v-if="hasClearBtn"
              name="input-cancel"
              :class="`ui-text-field-icon --${clearBtn}`"
              :width="clearBtn === 'sm' ? 24 : 32"
              :height="clearBtn === 'sm' ? 24 : 32"
              viewBox="0 0 32 32"
              @click="onClear"
            />
          </slot>
        </transition>
      </div>
    </label>

    <slot name="message">
      <div v-if="hasMessage" class="ui-text-field-message">
        {{ errorMessage }}
      </div>
    </slot>
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import AutoFocus from '@/utils/directives/autofocus';

export default {
  name: 'UiTextField',
  components: {
    UiInlineIcon,
  },
  directives: {
    AutoFocus,
  },
  model: {
    prop: 'value',
    event: 'onInput',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: 'gray',
    },
    size: {
      type: String,
      default: 'md',
    },

    label: {
      type: String,
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    clearable: {
      type: Boolean,
      default: false,
    },
    clearBtn: {
      type: String,
      default: 'md',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    transformUppercase: {
      type: Boolean,
      default: false,
    },
    transformTrim: {
      type: Boolean,
      default: false,
    },
    transformRegexp: {
      type: RegExp,
      default: null,
    },
  },
  data: () => ({
    focused: false,
  }),
  computed: {
    borderColor() {
      if (this.invalid) return '--state-invalid';
      if (this.focused) return '--state-focused';
      return '--state-default';
    },
    hasClearBtn() {
      return this.clearable && this.value.length > 0;
    },
    hasMessage() {
      return this.invalid && this.errorMessage.length > 0;
    },
  },
  methods: {
    onInput(event) {
      let v = event.target.value;
      if (this.transformRegexp) {
        v = event.target.value.replace(this.transformRegexp, '');
      }
      if (this.transformUppercase) {
        v = v.toUpperCase();
      }
      if (this.transformTrim) {
        v = v.trim();
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
      this.$emit('onInput', v);
    },
    focusIt() {
      if (this.$refs[this.$attrs.name]) this.$refs[this.$attrs.name].focus();
    },
    onFocus() {
      this.focused = true;
      this.$emit('onFocus');
    },
    onBlur() {
      this.focused = false;
      this.$emit('onBlur');
    },
    onClear() {
      this.$emit('onInput', '');
      this.$emit('onClear');
    },
  },
};
</script>

<style scoped>
.ui-text-field-box {
  @apply w-full relative
    flex flex-col cursor-pointer
    rounded-xl border
    font-normal text-base leading-5
    transition-colors ease-linear duration-200;
}

.ui-text-field-label {
  @apply px-4 md:px-3 ease-linear duration-200
    text-sirius-gray-1000;
  transition-property: font-size, line-height, transform;
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
}
.--focused .ui-text-field-label {
  @apply text-xs leading-14;
  transform: translateY(-4px);
}

.ui-text-field-input {
  @apply px-4 md:px-3 w-full
    appearance-none focus:outline-none
    transition-transform ease-linear duration-200
    bg-transparent
    font-normal
    whitespace-nowrap placeholder-transparent;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.--focused .ui-text-field-input__labeled {
  @apply pt-1;
  transform: translateY(-4px);
}

.--clearable .ui-text-field-label,
.--clearable .ui-text-field-input {
  @apply pr-10;
}

.--invalid .ui-text-field-input {
  /* @apply text-sirius-red-400; */
}
.ui-text-field-right {
  @apply absolute right-2 top-0 bottom-0
     text-sirius-black-100
    flex items-center justify-center;
}
.ui-text-field-icon {
  @apply transition ease-in duration-100
  focus:outline-none active:opacity-50;
  --scale: 0.1;
}

.ui-text-field-icon.--sm {
  width: 24px;
  height: 24px;
  margin: auto;
}
.ui-text-field-required {
  @apply absolute top-1.5 right-2;
}
.ui-text-field-message {
  @apply mt-1 pl-2 text-sirius-red-400 text-sm font-normal leading-tight;
}
.--md .ui-text-field-box {
  @apply h-12 font-medium;
}
.--M .ui-text-field-box {
  @apply h-12;
}
.--M .ui-text-field-message {
  @apply pl-0;
}
.--lg .ui-text-field-box {
  @apply h-13 font-medium;
}

.--white {
  @apply bg-white;
}
.--gray {
  @apply bg-sirius-gray-200;
}

.--state-invalid {
  @apply border-sirius-red-400;
}
.--state-focused {
  @apply border-sirius-orange-100;
}
.--state-default {
  @apply border-sirius-gray-200;
}
.--white.--state-default {
  @apply border-transparent;
}

input:-webkit-autofill {
  background: transparent;
}
input:autofill {
  background: transparent;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
