<template>
  <AddressMapContainer
    v-slot="{
      coords,
      loading,
      savedAddressResults,

      noDeliveryType,
      addressValidation,
      searchedAddresses,

      onInput,
      onClear,
      saveAnyway,
      onMapCenterUpdated,
      chooseOtherAddress,
      selectSavedAddress,
      searchAddressClick,
      onNoDeliveryToAddress,
    }"
    class="AddressMap h-full"
    @onFocusInput="onFocusInput"
    @getGeoObject="getGeoObject"
    @onSavedAddressSelected="returnToMain"
    @onUpdateGeocode="onUpdateGeocode"
    @onAddressSelected="closeSearchListModal"
    @onNotInDeliveryZoneAfterUpdateLocation="v => openSearchListModal(v, true)"
  >
    <MapContainer
      ref="mapContainerRef"
      :input-coords="coords"
      :geo-btn="showGeoBtn"
      @onMapInit="onMapInited"
      @onMapActionEnd="$emit('onActionEnd')"
      @onMapActionStart="$emit('actionBegin')"
      @onMapCenterUpdated="onMapCenterUpdated"
    />

    <div class="AddressMap__container">
      <ExtendedDeliveryPlank v-show="isExtendedDelivery" />
      <div id="searchListModal" class="AddressMap__content">
        <div class="AddressMap__header">
          <p class="AddressMap__title">
            {{ $t('selectAddress') }}
          </p>
        </div>
        <div class="AddressMap__input" @click="openSearchListModal(street)">
          <UiTextField
            ref="streetInput"
            :value="street"
            :label="$t('streetBuildingHouse')"
            type="text"
            name="streetInput"
            clearable
            :invalid="v$.street.$error || !isStreetValid"
            @input="onInput"
            @onClear="(street = ''), onClear"
          />
          <div class="AddressMap__inputCover"></div>
        </div>

        <UiButton size="LG" class="AddressMap__btn" @click="onContinue">
          {{ $t('continue') }}
        </UiButton>
      </div>
    </div>

    <!-- Модалка ввода улицы и дома -->
    <SearchListModal
      ref="searchListModal"
      v-model="showModal"
      :street="street"
      :autofocus="autofocusEnabled"
      :no-delivery="noDeliveryType"
      :loading="loading"
      :message-type="addressValidation"
      :saved-addresses="savedAddressResults"
      :searched-addresses="searchedAddresses"
      @update:street="onInput"
      @typeNew="chooseOtherAddress"
      @goToMain="returnToMain"
      @saveAnyway="closeSearchListModal(), saveAnyway()"
      @onClear="onClear"
      @onSelectSavedAddress="selectSavedAddress"
      @onReturnToMap="closeSearchListModal(), $emit('actionBegin')"
      @onSelectSearchedAddress="searchAddressClick"
      @onNoDeliveryToAddress="onNoDeliveryToAddress"
      @update:modelValue="onUpdateModal"
    />

    <RequestCityModal
      v-model="cityModal"
      :cities="cities"
      @onSelect="onCitySelect"
      @onClose="onCloseCityModal"
    />
    <RequestGeoModal
      v-model="geoModal"
      @onClose="onCloseGeo"
      @onAllow="onGeoAllow"
    />
  </AddressMapContainer>
</template>

<script>
import { GeoService } from '@services/geo';
import { CityService } from '@services/city';
import { RahmetApp } from '@shared/RahmetApp';
import { useVuelidate } from '@vuelidate/core';
import { CITIES } from '@services/city/constants';
import { required } from '@vuelidate/validators';

import RequestCityModal from '@pure-ui/components/Address/RequestCityModal/RequestCityModal.vue';
import RequestGeoModal from '@pure-ui/components/Address/RequestGeoModal/RequestGeoModal.vue';
import SearchListModal from '@pure-ui/components/Address/SearchListModal/SearchListModal.vue';
import AddressMapContainer from '@components/address/AddressMapContainer.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import ExtendedDeliveryPlank from './ExtendedDeliveryPlank.vue';
import MapContainer from '@/components/map/MapContainer.vue';

export default {
  name: 'AddressMap',
  components: {
    ExtendedDeliveryPlank,
    AddressMapContainer,
    RequestCityModal,
    SearchListModal,
    RequestGeoModal,
    MapContainer,
    UiTextField,
    UiButton,
  },
  inject: ['toast'],
  props: {
    /**
     * Адрес, на котором тапнули Редактировать
     */
    inputCoords: {
      type: Array,
      default: null,
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      street: '',
      streetTitle: '',

      geoAllowed: false,
      showModal: false,
      showGeoBtn: false,
      autofocusEnabled: true,

      // нужен для отлова момента когда он вернулся после настройек
      onGeoAllowing: false,

      sameAsSavedAddress: null,

      isCorrectAddress: false,
      isStreetValid: true,
      GeoObject: null,

      cityModal: false,
      geoModal: false,
      cities: Object.values(CITIES),

      isExtendedDelivery: false,
    };
  },
  validations: {
    street: {
      required,
    },
  },
  watch: {
    isExtendedDelivery: {
      handler(v) {
        const btn = document.getElementById('geoLocationBtn');
        btn.style.top = v ? '-8rem' : '-4rem';
      },
    },
  },
  methods: {
    onContinue() {
      if (!this.isCorrectAddress) {
        this.toast.show(this.$t('placePinOnBuilding'), 'error');
        return;
      }
      this.$emit('onContinue', {
        GeoObject: this.GeoObject,
        streetTitle: this.streetTitle,
        isExtended: this.isExtendedDelivery,
      });
    },
    moveGeoLocationBtn() {
      const btn = document.getElementById('geoLocationBtn');
      const block = document.getElementById('searchListModal');
      if (btn && block) {
        btn.className = btn.className + ' absolute right-4 -top-20';
        block.appendChild(btn);
      }
    },
    getGeoObject({ address, isCorrectAddress }) {
      console.log('getGeoObject', address, isCorrectAddress);

      let {
        hasStreet,
        hasBuilding,
        isInCity,
        isInExtended,
        GeoObject,
        cityName,
      } = address;
      this.isCorrectAddress = hasStreet && hasBuilding && isInCity;
      this.isStreetValid =
        this.street.length === 0 ? true : this.isCorrectAddress;
      this.GeoObject = this.isCorrectAddress ? GeoObject : null;
      this.street = GeoObject.name;
      this.streetTitle = cityName + ', ' + GeoObject.name;
      this.isExtendedDelivery = !!isInExtended;
    },
    onUpdateModal(value) {
      if (!value) this.$emit('onActionEnd');
    },
    onUpdateGeocode(geocode) {
      this.street = geocode;
    },
    onFocusInput() {
      this.$refs.searchListModal.focusInput();
    },
    /**
     * Открывает попап с вводом адреса и здания.
     * Вызывается в двух случаях:
     * 1. при тапе на "Улица, здание/дом" в форме
     * 2. если на карте тапнули на адрес, который
     *    не входит в зону доставки
     */
    openSearchListModal(street = null, isNoDelivery = false) {
      this.street = street; // TODO: check it
      console.log('openSearchListModal', isNoDelivery);

      if (street) this.street = street || '';

      this.autofocusEnabled = !isNoDelivery;
      this.showModal = true;
      this.$emit('actionBegin');
    },
    // доступен снаружи
    closeSearchListModal() {
      this.showModal = false;
      this.$emit('onActionEnd');
    },
    returnToMain() {
      this.$router.push({ name: 'main' });
    },

    closeCityModal() {
      this.cityModal = false;
    },
    openCityModal() {
      this.cityModal = true;
    },
    onCloseCityModal() {
      this.$log('onCloseCityModal');
      this.$refs.mapContainerRef.defineDefaults();
      localStorage.setItem('city::is-city-variants-modal-shown', true);
    },
    onCitySelect(city) {
      this.$log('onCitySelect', { city });
      CityService.setCity(city.id);
      this.closeCityModal();
      localStorage.setItem('city::is-city-variants-modal-shown', true);
    },

    closeGeoModal() {
      this.geoModal = false;
    },
    openGeoModal() {
      this.geoModal = true;
    },
    onGeoAllow() {
      this.$log('onGeoAllow');
      localStorage.setItem('geo::is-geo-modal-shown', true);
      this.onGeoAllowing = true;
      this.closeGeoModal();
      RahmetApp.openAppSettings();
      this.geoAllowed = true;
    },
    onCloseGeo() {
      if (this.geoAllowed) return;
      localStorage.setItem('geo::is-geo-modal-shown', true);
      this.closeGeoModal();
      this.openCityModal();
    },

    checkIsGeoModalCanOpen() {
      let hasMethod = typeof window.RahmetApp?.openAppSettings === 'function';
      if (!hasMethod) return false;

      let isShown = Boolean(localStorage.getItem('geo::is-geo-modal-shown'));
      return !isShown;
    },
    checkIsCityModalCanOpen() {
      let isShown = Boolean(
        localStorage.getItem('city::is-city-variants-modal-shown')
      );
      return !isShown;
    },
    async onMapInited() {
      this.showGeoBtn = true;
      this.$nextTick(() => {
        this.moveGeoLocationBtn();
      });
      console.log('AddressMap onMapInited', this.inputCoords);

      if (this.inputCoords?.length === 2) {
        this.$refs.mapContainerRef.defineByCoords(this.inputCoords);
        return;
      }

      let status = await GeoService.requestGeoPermission();
      if (status === 'granted') {
        this.$refs.mapContainerRef.defineGeolocation(true);
        return;
      }

      let canOpenGeoModal = this.checkIsGeoModalCanOpen();
      let canOpenCityModal = this.checkIsCityModalCanOpen();

      if (canOpenGeoModal) {
        this.openGeoModal();
        return;
      }

      if (canOpenCityModal) {
        this.openCityModal();
        return;
      }

      this.$refs.mapContainerRef.defineDefaults(true);
    },
    async defineGeoLocationAfterReconnect() {
      let status = await GeoService.requestGeoPermission();
      if (status === 'granted')
        this.$refs.mapContainerRef.defineGeolocation(true);
      else this.openCityModal();
    },
  },
  eventBusEvents: {
    eb_on_reconnect_to_app() {
      this.$log('eb_on_reconnect_to_app', null, 'EventBus');
      if (this.onGeoAllowing) {
        this.onGeoAllowing = false;
        this.defineGeoLocationAfterReconnect();
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        placePinOnBuilding: 'Расположите указатель на дом или&nbsp;здание',
        streetBuildingHouse: 'Улица и здание/дом',
        returnToMap: 'Вернуться к выбору на карте',
        selectAddress: 'Укажите адрес доставки',
        continue: 'Продолжить',
      },
      kk: {
        placePinOnBuilding: 'Нұсқаулықты үй немесе ғимараттың үстіне қойыңыз',
        streetBuildingHouse: 'Көше және үй/ғимарат',
        returnToMap: 'Картаға қайта оралу',
        selectAddress: 'Жеткізу мекен жайын енгізіңіз',
        continue: 'Жалғастыру',
      },
    },
  },
};
</script>

<style scoped>
.AddressMap__container {
  @apply w-full fixed z-10 bottom-0;
}
.AddressMap__content {
  @apply px-4 pb-4 flex flex-col 
  
  w-full bg-white
  rounded-t-3xl;
}
.AddressMap__header {
  @apply pt-6 pb-2;
}
.AddressMap__title {
  @apply py-1
  text-xl font-bold leading-6 
  text-center;
}
.AddressMap__content.--with-title {
  @apply mt-4;
}
.AddressMap__input {
  @apply mt-4 relative;
}
.AddressMap__inputCover {
  @apply absolute top-0 w-full h-full bg-transparent;
}
.AddressMap__btn {
  @apply mt-4;
}
</style>
