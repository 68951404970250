import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';

export function logMounted() {
  Analitycs.logEvent(EVENTS.VIEWED_ORDERS_HISTORY);
}

export function logViewed(logId, amount) {
  Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_PRODUCT_LIST, {
    screenName: 'History Slider Cart Page',
    logId,
    pageNum: 1,
    amount,
  });
}
