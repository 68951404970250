import axios from 'axios';
import { stringify } from 'qs';
import { Logger as LOG } from '@shared/services/logger';
import {
  injectCity,
  injectToken,
  injectDeviceId,
  transformError,
  injectIdempotencyKey,
} from './utils';

import { installAxiosMeasureTime } from './utils/install-axios-measure-time';

const reviewClient = axios.create({
  baseURL: import.meta.env.VITE_API_PROXY,
  paramsSerializer: stringify,
});

installAxiosMeasureTime(reviewClient);

reviewClient.interceptors.request.use(
  config => {
    injectCity(config);
    injectToken(config);
    injectDeviceId(config);
    injectIdempotencyKey(config);
    LOG.event(`Config ${config.url}`, config, 'Network');
    return config;
  },
  err => Promise.reject(err)
);

reviewClient.interceptors.response.use(
  res => {
    LOG.event(`Res: ${res.config.url}`, res, 'Network');

    if (res.data.data || res.data.error_code === 0) return res;

    return Promise.reject(transformError(res.data));
  },
  err => {
    LOG.event(`Err: ${err.config.url}`, { err, config: err.config }, 'Network');

    return Promise.reject(transformError(err));
  }
);

export { reviewClient };
