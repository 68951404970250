export const replaceUrlQuery = (...regxs) => {
  try {
    let query = document.location.search;

    regxs.forEach(element => {
      query = query.replace(element, '');
    });
    const route = document.location.origin + document.location.pathname + query;
    window.history.replaceState(null, null, route);
  } catch (e) {
    console.log(e);
  }
};
