export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  render(h) {
    return h(
      'div',
      { class: 'pt-4' },
      this.value
        ? [
            h('dt', { class: 'text-sirius-gray-700 text-sm pb-1' }, this.title),
            h('dd', this.value),
          ]
        : null
    );
  },
};
