<template>
  <AddedExtendedDeliveryModalView :show="show" @change="show = $event" />
</template>

<script>
import AddedExtendedDeliveryModalView from './AddedExtendedDeliveryModalView.vue';

let instance = null;

/**
 * Модалка показывается при добавлении пользователем
 * адреса, входящего в зону расширенной доставки
 */
export default {
  name: 'AddedExtendedDeliveryModal',
  components: {
    AddedExtendedDeliveryModalView,
  },
  data: () => ({
    show: false,
  }),
  mounted() {
    instance = this;
    // setTimeout(() => {
    //   this.show = true;
    // }, 3000);
  },
  beforeDestroy() {
    instance = null;
  },
  show() {
    if (instance) {
      instance.show = true;
    }
  },
};
</script>
