<template>
  <component
    :is="comp"
    @supportClicked="supportClicked"
    @openModal="openModal"
  ></component>
</template>

<script>
import { SupportService } from '@services/support';
import FooterMobView from './components/FooterMobView.vue';
import FooterDeskView from './components/FooterDeskView.vue';
import ActiveOrderView from './components/ActiveOrderView.vue';
import FinishedOrderView from './components/FinishedOrderView.vue';

export default {
  name: 'SupportButtons',
  components: {},
  inject: ['bottomSheetPopup'],
  props: {
    view: {
      type: String,
      default: 'activeOrder',
    },
  },
  computed: {
    comp() {
      if (this.view === 'footerMob') return FooterMobView;
      if (this.view === 'footerDesk') return FooterDeskView;
      if (this.view === 'finishedOrder') return FinishedOrderView;
      return ActiveOrderView;
    },
  },
  methods: {
    openModal() {
      this.bottomSheetPopup.show('support-popup');
    },
    supportClicked(type) {
      SupportService.clickSupport(type);
    },
  },
};
</script>

<style></style>
