export const getLocalCart = () => {
  return JSON.parse(window.localStorage.getItem('localCart') || '[]');
};

export const setLocalCart = cart => {
  window.localStorage.setItem('localCart', JSON.stringify(cart));
};

export const resetLocalCart = () => {
  window.localStorage.setItem('localCart', JSON.stringify([]));
};

export const initLocalCart = () => {
  const localCart = getLocalCart();
  setLocalCart(localCart);
  return localCart;
};
