import Story1kk from '@img/static-stories/leave-at-door-1-kk.jpg';
import Story1ru from '@img/static-stories/leave-at-door-1-ru.jpg';
import Story2kk from '@img/static-stories/leave-at-door-2-kk.jpg';
import Story2ru from '@img/static-stories/leave-at-door-2-ru.jpg';
import Story3kk from '@img/static-stories/leave-at-door-3-kk.jpg';
import Story3ru from '@img/static-stories/leave-at-door-3-ru.jpg';
import imageDesktop from '@img/static-banners/leave-at-door-d.jpg';
import imageMobile from '@img/static-banners/leave-at-door-m.jpg';

export const leaveAtDoorBanner = {
  type: 'story',
  code: 'leave_at_door_main',
  fixedPosition: true,
  imageDesktop,
  imageMobile,
  textKk: `
    Есіктің алдына қалдыру
  `,
  textRu: `
    Оставим заказ у&nbsp;двери
  `,
  buttonTextRu: 'Всё понятно!',
  buttonTextKk: 'Түсінікті!',
  stories: [
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
    },
    {
      imageKk: Story2kk,
      imageRu: Story2ru,
    },
    {
      imageKk: Story3kk,
      imageRu: Story3ru,
    },
  ],
};
