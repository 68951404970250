/* eslint-disable no-underscore-dangle */
export function initState() {
  return {
    promocode: '',
    discount: 0,
    errorMessage: '',
    modalErrorMessage: '',
    freeDeliveryNotApplied: false,
    freeDelivery: false,
    open: false,
  };
}

function _merge(v) {
  return {
    ...initState(),
    ...v,
  };
}

export function getState(stateName, params) {
  if (stateName === 'Default') {
    return initState();
  }
  if (stateName === 'Success') {
    return _merge({
      promocode: params.promocode,
      discount: params.discount,
    });
  }
  if (stateName === 'Error') {
    return _merge({
      promocode: params.promocode,
      errorMessage: params.errorMessage,
    });
  }
  if (stateName === 'FreeDelivery') {
    return _merge({
      promocode: params.promocode,
      freeDelivery: true,
    });
  }
  if (stateName === 'FreeDeliveryNotApplied') {
    return _merge({
      promocode: params.promocode,
      freeDeliveryNotApplied: params.freeDeliveryNotApplied,
    });
  }
  if (stateName === 'ModalOpen') {
    return _merge({ open: true });
  }
  if (stateName === 'ModalHasValidPromocode') {
    return _merge({
      open: true,
      promocode: params.promocode,
      discount: params.discount,
    });
  }
  if (stateName === 'ModalFreeDeliveryNotApplied') {
    return _merge({
      open: true,
      promocode: params.promocode,
      freeDeliveryNotApplied: true,
    });
  }
  if (stateName === 'ModalError') {
    return _merge({
      open: true,
      promocode: params.promocode,
      modalErrorMessage: params.modalErrorMessage,
    });
  }
  return initState();
}
