<template>
  <WelcomeModal v-model="show" />
</template>

<script>
import { REF_DEEPLINK_URL_KEY } from '@services/referral/constants';
import WelcomeModal from '@pure-ui/components/Referral/WelcomeModal/WelcomeModal.vue';

/**
 * Модалка показывается всем пользователям,
 * перешедшим по реферальной ссылке
 */
export default {
  name: 'WelcomeModalWrapper',
  components: {
    WelcomeModal,
  },
  data: () => ({
    show: false,
  }),
  mounted() {
    if (
      this.$route.fullPath.includes(REF_DEEPLINK_URL_KEY) &&
      this.$isWebView
    ) {
      this.show = true;
    }
  },
};
</script>
