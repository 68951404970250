<template>
  <WebMobileHeaderBase>
    <template #left><CatalogIcon /></template>
    <template #right><ProfileIcon /></template>
  </WebMobileHeaderBase>
</template>

<script>
import CatalogIcon from '@shared/components/header/CatalogIcon.vue';
import ProfileIcon from '@shared/components/header/ProfileIcon.vue';
import WebMobileHeaderBase from '@components/header/web-mobile-header/WebMobileHeaderBase.vue';

export default {
  name: 'WebMobileHeader',
  components: {
    CatalogIcon,
    ProfileIcon,
    WebMobileHeaderBase,
  },
};
</script>
