const arrayUniqueByKey = (arr, key) => [
  ...new Map(arr.map(v => [v[key], v])).values(),
];

/**
 * Смёрживает подобные структуры,
 * отфильтровывает неуникальные id товаров (на всякий случай)
 * ```
 * {
 *  [`${product.sort_weight}_${product.category_id}`]: {
 *    id: 529,
 *    name: 'Плавленные сыры',
 *    products: [...products],
 *  },
 * }
 * ```
 */
export function mergeCategories(oldProducts, newProducts) {
  return Object.keys(newProducts).reduce(
    (acc, id) => {
      if (acc.hasOwnProperty(id)) {
        acc[id].products = arrayUniqueByKey(
          [...acc[id].products, ...newProducts[id].products],
          'id'
        );
      } else {
        acc[id] = newProducts[id];
      }
      return acc;
    },
    { ...oldProducts }
  );
}
