<template>
  <p class="price">
    <span :class="{ sale: oldPrice }"> {{ formatPrice(price) }} ₸/шт </span>
    <span v-if="oldPrice" class="old"> {{ formatPrice(oldPrice) }} ₸ </span>
  </p>
</template>

<script>
import { formatPrice } from '@/utils/formatters/price';

export default {
  name: 'ProductCardTitle',
  props: {
    price: {
      type: Number,
      default: 0,
    },
    oldPrice: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>

<style scoped>
.price {
  @apply text-xs leading-4 font-normal text-sirius-gray-100;
}
.sale {
  @apply text-sirius-red-200;
}
.old {
  @apply mx-1 line-through;
}
</style>
