<template>
  <section class="MmRequirements">
    <img
      v-if="!$isDesktop"
      src="./images/m-visual.jpg"
      class="MmRequirements__img"
      loading="lazy"
      width="296"
      height="300"
    />
    <img
      v-if="$isDesktop"
      src="./images/d-visual.jpg"
      class="MmRequirements__img"
      loading="lazy"
      width="460"
      height="516"
    />
    <MmSubtitle id="requirements" class="MmRequirements__title">
      <span v-html="$t('title')" />
    </MmSubtitle>
    <div class="MmRequirements__content">
      <h3 class="MmRequirements__listTitle">
        {{ $t('listTitle') }}
      </h3>
      <ul class="MmRequirements__list">
        <li
          v-for="item in items"
          :key="item[$i18n.locale]"
          class="MmRequirements__item"
        >
          <span v-html="item[$i18n.locale]" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import MmSubtitle from '@/views/micromarket/components/MmSubtitle/MmSubtitle.vue';

export default {
  name: 'MmRequirements',
  components: { MmSubtitle },
  inject: [],
  props: {},
  data: () => ({
    items: [
      {
        ru: 'Площадь 1&nbsp;м²',
        kk: '1 м² алаң',
      },
      {
        ru: 'Прямой доступ к&nbsp;заземлённым розеткам (2&nbsp;шт.)',
        kk: 'Тікелей жерлестірілген розетка',
      },
      {
        ru: 'Более 100&nbsp;потенциальных покупателей',
        kk: '100-ден астам ықтималды тұтынушы',
      },
      {
        ru: 'Доступ для&nbsp;нашего экспедитора, чтобы&nbsp;он&nbsp;мог беспрепятственно пополнять холодильник',
        kk: 'Экспедиторымыз тоңазытқыштарды кедергісіз толтыруы үшін емін-еркін кіріп-шығуына рұхсат',
      },
    ],
  }),

  i18n: {
    messages: {
      kk: { title: 'Өзіміз келіп орнатамыз', listTitle: 'Ол үшін не керек:' },
      ru: {
        title: 'Приедем и установим сами',
        listTitle: 'Что для этого нужно:',
      },
    },
  },
};
</script>

<style scoped>
.MmRequirements {
  padding-top: 84px;
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.MmRequirements__img {
  display: block;
  margin: auto;
}

.MmRequirements__title {
  margin-top: 16px;
}

.MmRequirements__listTitle {
  padding-top: 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.MmRequirements__list {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.MmRequirements__item {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  padding-left: 18px;
}

.MmRequirements__item::before {
  content: '';
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ffa100;
}

@media (min-width: 768px) {
  .MmRequirements {
    position: relative;
    max-width: 988px;
    position: relative;
    padding-top: 200px;
    padding-bottom: 110px;
  }

  .MmRequirements__content {
    max-width: 450px;
  }

  .MmRequirements__listTitle {
    padding-top: 48px;
    font-size: 24px;
    line-height: 30px;
  }

  .MmRequirements__list {
    padding-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .MmRequirements__item {
    font-size: 18px;
    line-height: 28px;
  }

  .MmRequirements__item::before {
    top: 10px;
    width: 8px;
    height: 8px;
  }

  .MmRequirements__img {
    position: absolute;
    top: 156px;
    right: 0;
  }
}
</style>
