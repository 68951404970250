import { EventEmitter } from '@services/emitter';

export const ADDRESS_EVENTS = {
  /**
   * Эмитится после успешной смены адреса доставки
   */
  ON_ADDRESS_CHANGE: 'ON_ADDRESS_CHANGE',
};

export const AddressEmitter = new EventEmitter();
