<template>
  <button class="MmButton" :class="className" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
const SIZES = ['m', 'm-52'];

export default {
  name: 'MmButton',
  components: {},
  inject: [],
  props: {
    size: {
      type: String,
      default: 'm',
      validator: v => SIZES.includes(v),
    },
  },
  data: () => ({}),
  computed: {
    className() {
      return {
        [`MmButton_size_${this.size}`]: true,
      };
    },
  },

  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.MmButton {
  background: #ffa100;
  color: #fff;
  line-height: 22px;
  border-radius: 12px;
}

.MmButton:focus {
  outline: none;
}

.MmButton_size_m {
  padding: 13px;
  font-weight: 700;
}

.MmButton_size_m-52 {
  padding: 16px;
  font-size: 18px;
  font-weight: 900;
}
</style>
