import dayjs from 'dayjs';

const LS_PREFIX = 'promo-modal-';

const today = () => dayjs().format('YYYY-MM-DD');

/**
 * The modal should be shown only once per day.
 * upd.: only once for all time since some modals
 * can be active for month
 */
export function wasAlreadyShown(id) {
  // return window.localStorage[LS_PREFIX + id] === today();
  return !!window.localStorage[LS_PREFIX + id];
}

/**
 * Remember that the modal was shown by its id.
 */
export function setShown(id) {
  window.localStorage[LS_PREFIX + id] = today();
}
