/**
 * Пользователи у которых отключен mix-blend-mode: mulitply
 *
 * Нужен для того что б видели контент менеджеры в каких картинках есть фон а в каких их нет
 */
export const USERS_WITH_REAL_BG_OF_IMAGE = [
  '11031999',
  '14543384',
  '12331580',
  '13129926',
  // От коммерции:
  '15684608', // +77021939100
  '15351485', // +77772783359
  '15224621', // +77052620309
];
