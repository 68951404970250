<template>
  <div class="TopBlock">
    <button
      style="vertical-align: middle"
      class="focus:outline-none"
      @click="$emit('onClose')"
    >
      <img
        class=""
        :src="icon"
        alt=""
        width="40"
        height="40"
        style="vertical-align: top"
      />
    </button>
    <MmLangSwitcher />
  </div>
</template>

<script>
import MmLangSwitcher from '@/views/micromarket/components/MmLangSwitcher/MmLangSwitcher.vue';

export default {
  name: 'TopBlock',
  components: { MmLangSwitcher },
  inject: [],
  props: {},
  data: () => ({
    icon: new URL('@/views/micromarket/images/cross.svg', import.meta.url).href,
  }),

  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.TopBlock {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 0.5px solid #d4d3df;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
