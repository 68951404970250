import {
  Analitycs,
  EVENTS,
  SEGMENT_EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';
import { SCREENS } from '@shared/config/product';

export const onAdd = (from, product, isInc = false, quantity = 1) => {
  let screen = SCREENS[from];
  let {
    id,
    category_id: catId,
    analytical_category_id: analyticalCatId,
    name,
    openedFrom,
  } = product;
  let price = product.feature ? product.feature.price : product.price;

  Analitycs.logEvent(isInc ? EVENTS.PLUS_PRODUCT : EVENTS.PRODUCT_ADDED, {
    screenName: screen,
    product_id: id,
    productName: name,
    category_id: catId,
    analytical_category_id: analyticalCatId,
    from: SCREENS[openedFrom],
  });
  Analitycs.logSegmentEvent(SEGMENT_EVENTS.PRODUCT_ADDED, {
    screenName: screen,
    productId: id,
    productName: name,
    categoryId: catId,
    analyticalCategoryId: analyticalCatId,
  });
  Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_PRODUCT_ADDED, {
    dom_screen_name: screen,
    dom_product_id: id,

    content_ids: [`${id}`],
    content_type: 'product',
  });

  window.dataLayer?.push({
    event: 'addToCart',
    productId: id,
    productName: name,
    productPrice: price,
    quantity,
  });
};

export const onDec = (from, product) => {
  let screen = SCREENS[from];
  let {
    id,
    category_id: catId,
    analytical_category_id: analyticalCatId,
    name,
    openedFrom,
  } = product;
  Analitycs.logEvent(EVENTS.MINUS_PRODUCT, {
    screenName: screen,
    product_id: id,
    productName: name,
    category_id: catId,
    analytical_category_id: analyticalCatId,
    from: SCREENS[openedFrom],
  });
};
