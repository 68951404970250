import { isStreet } from './is-street';
import { isBuilding } from './is-building';
import { isDeliveryCity, parseDeliveryCity } from './is-delivery-city';
import { isDeliveryCountry } from './is-delivery-country';
import { fetchWarehouseZoneCoordinates } from './fetch-warehouse-zone-coordinates';
import { isCoordsInDeliveryZone } from './map-polygon';
import { CityService } from '@services/city';

const insertValidationsToGeoObject = geoObjects => {
  return new Promise(resolve => {
    fetchWarehouseZoneCoordinates().then(coords => {
      console.log('fetchWarehouseZoneCoordinates', coords);

      // Мы тут и фильтруем, и в объекте поле редактируем, осторожно)
      const res = geoObjects.map(value => {
        const [lng, lat] = value.GeoObject.Point.pos.split(' ');

        let isInTerritory = isCoordsInDeliveryZone(
          window.ymaps,
          [lat, lng],
          coords.regular
        );
        let isInExtended = isCoordsInDeliveryZone(
          window.ymaps,
          [lat, lng],
          coords.far
        );
        value.isInTerritory = isInTerritory;
        value.isInExtended = isInExtended;

        const { Address, kind } =
          value.GeoObject.metaDataProperty.GeocoderMetaData;

        value.isInCountry = isDeliveryCountry(Address);
        value.isInCity = isDeliveryCity(Address);
        value.cityName = parseDeliveryCity(Address)?.name || '';
        value.hasStreet = isStreet(kind);
        value.hasBuilding = isBuilding(kind);

        return value;
      });

      resolve(res);
    });
  });
};

export const validateGeoObjectsCollection = (
  GeoObjectCollection,
  initialCoords
) => {
  const { featureMember } = GeoObjectCollection;

  let hasStreet = false;
  let hasBuilding = false;

  featureMember.forEach(member => {
    const { kind } = member.GeoObject.metaDataProperty.GeocoderMetaData;
    if (!hasStreet) hasStreet = isStreet(kind);
    if (!hasBuilding) hasBuilding = isBuilding(kind);
  });

  return insertValidationsToGeoObject(featureMember, initialCoords).then(
    res => {
      let addresses = res || [];
      let address = res[0] || null;

      return {
        addresses,
        address,
        hasStreet,
        hasBuilding,
      };
    }
  );
};

export const filterGeoObjectsByDelivery = GeoObjects => {
  return GeoObjects.filter(
    value => value.isInCountry && value.isInCity && value.hasBuilding
  );
};

export const sortGeoObjectsByDelivery = GeoObjects => {
  let currentCity = CityService.getCityData().name;
  return GeoObjects.sort(a => (a.cityName === currentCity ? -1 : 1));
};
