<template>
  <UiButton class="CatalogBtn max-w-fit" size="LG">
    <IconCatalog class="mr-2" />
    <span class="pr-1">
      {{ $t('catalog') }}
    </span>
  </UiButton>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import IconCatalog from './icons/IconCatalog.vue';
export default {
  components: {
    IconCatalog,
    UiButton,
  },
  i18n: {
    messages: {
      ru: { catalog: 'Каталог' },
      kk: { catalog: 'Каталог' },
    },
  },
};
</script>

<style></style>
