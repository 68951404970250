// SwipeHandler.js
const SWIPE_THRESHOLD = 50;

export class SwipeHandler {
  constructor(onSwipeEnd) {
    this.onSwipeEnd = onSwipeEnd;
    this.initialTouch = { x: 0, y: 0 };
    this.sheetTouchStarted = false;
    this.sheetShift = 0;
  }

  extractTouch(event) {
    const touch = event.changedTouches[0];
    return { x: touch.clientX, y: touch.clientY };
  }

  onSheetTouchStart(e, contentRef) {
    this.sheetTouchStarted =
      contentRef &&
      contentRef.scrollTop !== undefined &&
      contentRef.scrollTop === 0;

    this.initialTouch = this.extractTouch(e);
  }

  onSheetTouchMove(e) {
    if (this.sheetTouchStarted) {
      const touch = this.extractTouch(e);
      const dx = touch.x - this.initialTouch.x;
      const dy = touch.y - this.initialTouch.y;

      if (Math.abs(dx) > Math.abs(dy) && Math.abs(dx) > SWIPE_THRESHOLD) {
        // Horizontal swipe detected, ignore bottom sheet movement
        return;
      }

      if (dy > SWIPE_THRESHOLD) {
        this.sheetShift = dy;
      }
    }
  }

  onSheetTouchEnd() {
    const shift = this.sheetShift;
    if (this.sheetTouchStarted && shift >= 150) {
      this.onSwipeEnd();
    }
    this.sheetTouchStarted = false;
    this.sheetShift = 0;
  }
}
