import { DELIVERY_CITIES } from '@services/city/constants';

export function parseDeliveryCity(Address) {
  return Address.Components.find(a => a.kind === 'locality');
}
/**
 * Проверяем доставляем ли в этот город
 * @param {Address} Address GeoObject.metaDataProperty.GeocoderMetaData.Address
 * @returns
 */
export function isDeliveryCity(Address) {
  let locality = parseDeliveryCity(Address);
  return locality ? DELIVERY_CITIES.includes(locality.name) : false;
}
