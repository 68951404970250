<template>
  <UiBottomSheetPopup
    :show="show"
    closable
    with-buttons
    with-back-button
    style="--popup-width-d: 588px"
    v-on="$listeners"
  >
    <div class="added-extended-delivery">
      <div class="added-extended-delivery__decor">
        <img
          :src="icon"
          :width="$isDesktop ? '240' : '254'"
          :height="$isDesktop ? '206' : '185'"
          alt="map-img"
        />
      </div>
      <div class="added-extended-delivery__wrapper">
        <p class="added-extended-delivery__msg">
          <span v-html="$t('msg')" />
        </p>
        <p class="added-extended-delivery__descr">
          <span v-html="$t('descr')" />
        </p>
      </div>
    </div>

    <template #buttons>
      <UiButton size="LG" @click="$emit('change', false)">
        {{ $t('btn') }}
      </UiButton>
    </template>
  </UiBottomSheetPopup>
</template>

<script>
import UiBottomSheetPopup from '@pure-ui/components/UiBottomSheetPopup/UiBottomSheetPopup.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import IconMobile from '@img/extended-delivery/map.png';
import IconDesktop from '@img/extended-delivery/map-desk.png';

export default {
  name: 'AddedExtendedDeliveryModalView',
  components: {
    UiBottomSheetPopup,
    UiButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.$isDesktop ? IconDesktop : IconMobile;
    },
  },
  i18n: {
    messages: {
      ru: {
        msg: `
          Этот адрес в&nbsp;расширенной зоне доставки
        `,
        descr: `
          Заказ будет в&nbsp;пути дольше обычного&nbsp;—
          <span class="whitespace-nowrap">30 – 40</span>&nbsp;минут.
          Стоимость доставки варьируется от&nbsp;0&nbsp;до&nbsp;500&nbsp;тенге.
        `,
        btn: 'Хорошо',
      },
      kk: {
        msg: `
          Бұл мекенжай кеңейтілген жеткізу аймағында орналасқан
        `,
        descr: `
          Тапсырыс әдеттегіден ұзақ жеткізіледі, шамамен —
          <span class="whitespace-nowrap">30 – 40</span>&nbsp;минут.
          Тапсырыс бағасы 0 мен 500 теңге арасында болады.
        `,
        btn: 'Жақсы',
      },
    },
  },
};
</script>

<style lang="postcss" scoped>
.added-extended-delivery {
  @apply mt-1 pb-13 md:pb-9;

  &__decor {
    @apply mt-4 md:mt-0 ml-6 md:ml-0 flex justify-center;
  }
  &__msg {
    @apply mt-5 md:mt-0 text-2xl md:text-32 md:text-center leading-7 md:leading-10 font-bold md:mx-auto;
    max-width: 500px;
  }

  &__descr {
    @apply mt-4 font-normal text-base md:text-lg leading-18 md:leading-6 md:text-center md:mx-auto;
    max-width: 413px;
  }
}
</style>
