import router from '@/router';

let init = false;
let done = false;

export function onCartLeave(callback) {
  if (init) {
    done = false;
    return;
  }
  init = true;

  const onUnload = () => {
    callback();
    window.removeEventListener('beforeunload', onUnload);
    done = true;
  };

  window.addEventListener('beforeunload', onUnload);

  router.beforeEach((_to, from, next) => {
    if (from.name === 'cart' && !done) {
      onUnload();
    }
    next();
  });
}
