<template>
  <BottomSheetPopup
    style="--modal-width: 588px"
    name="filter-popup"
    close-btn
    with-title
    with-back-button
    @onClose="$emit('onClose')"
  >
    <template #title>
      <h2 class="md:hidden font-bold text-2xl text-center mx-auto">
        {{ $t('filters') }}
      </h2>
    </template>
    <div class="pt-6 px-4 md:pt-3 md:pb-10 md:px-10">
      <h2 class="md:mt-7 text-lg font-bold dark:text-white">
        {{ $t('toShowFirst') }}
      </h2>
      <UiRadio
        :value="sortValue"
        :radios="sortOptions"
        class="mt-2"
        @input="$emit('onSortChange', $event)"
      >
      </UiRadio>

      <div
        class="pb-9 md:pb-0 mt-10 md:mt-8 w-full md:w-min flex items-center space-x-2"
      >
        <UiButtonNew
          class="md:px-12 w-full font-bold text-lg"
          size="lg"
          @click="$emit('onSubmit')"
        >
          {{ $t('done') }}
        </UiButtonNew>
      </div>
    </div>
  </BottomSheetPopup>
</template>

<script>
import BottomSheetPopup from '@ui/bottom-sheet-popup/BottomSheetPopup.vue';
import UiButtonNew from '@ui/UiButtonNew.vue';
import UiRadio from '@ui/UiRadio.vue';

export default {
  name: 'FilterSheetPopup',
  components: {
    BottomSheetPopup,
    UiButtonNew,
    UiRadio,
  },
  inject: ['bottomSheetPopup', 'popup', 'bottomSheet'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    sortValue: {
      type: String,
      default: '',
    },
  },
  watch: {
    show: {
      immediate: true,
      handler: function handler(v) {
        if (v) {
          this.bottomSheetPopup.show('filter-popup');
        } else {
          this.popup.hide();
          this.bottomSheet.hide();
          this.bottomSheetPopup.hide();
        }
      },
    },
  },
  i18n: {
    messages: {
      ru: {
        filters: 'Фильтры',
        toShowFirst: 'Показывать сначала',
        reset: 'Сбросить',
        done: 'Готово',
      },
      kk: {
        filters: 'Сүзгілер',
        toShowFirst: 'Алдымен көрсету',
        reset: 'Қалпына келтіру',
        done: 'Дайын',
      },
    },
  },
};
</script>

<style scoped></style>
