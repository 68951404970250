<template>
  <div class="referral-promocode">
    <span class="referral-promocode-value"> {{ promocode }} </span>
    <UiInlineIcon name="promocode-copy" class="referral-promocode-copy" />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
export default {
  components: { UiInlineIcon },
  props: {
    promocode: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.referral-promocode {
  @apply p-3 h-13 relative 
    flex items-center justify-center space-x-2
    bg-sirius-gray-200 rounded-xl cursor-pointer;
}
.referral-promocode-value {
  @apply text-base font-bold leading-5 text-center select-all;
}
</style>
