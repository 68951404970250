import { client } from '../../clients';
import response1 from './mocks/product-reviews.json';

export default function (mock) {
  if (mock) {
    return new Promise(resolve => setTimeout(() => resolve(response1), 200));
  }

  return client
    .get('ryadom-review/api/v1/review/products', {
      params: {
        limit: 99999,
      },
    })
    .then(response => response.data);
}
