<template>
  <div class="CatalogIcon">
    <IconMobileMenu @click.native="setVisibility(true)" />

    <CatalogMenu :show-me="showCatalog" @onClose="setVisibility(false)" />
  </div>
</template>

<script>
import CatalogMenu from '@shared/components/catalog/CatalogMenu.vue';
import IconMobileMenu from '@shared/components/icons/IconMobileMenu.vue';

export default {
  name: 'CatalogIcon',
  components: {
    CatalogMenu,
    IconMobileMenu,
  },
  data: () => ({
    showCatalog: false,
  }),
  methods: {
    setVisibility(v) {
      this.showCatalog = v;
    },
  },
};
</script>
