<template>
  <div
    class="UiRadioCircle radio-circle"
    :class="`${modelValue ? 'active' : ''} --${variant}`"
  >
    <div v-if="modelValue" class="radio-circle-inner"></div>
  </div>
</template>

<script>
export default {
  name: 'UiRadioCircle',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
    variant: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style scoped>
.radio-circle {
  @apply w-6 h-6 bg-white
    flex items-center justify-center 
    rounded-full border-2 border-sirius-gray-300
    transform transition duration-200 ease-linear;
}
.radio-circle.active {
  @apply border-sirius-orange-100;
}
.radio-circle-inner {
  @apply w-3 h-3 rounded-full bg-sirius-orange-100;
}

.--secondary.radio-circle {
  @apply border border-sirius-black-100;
}
.--secondary.active.radio-circle {
  @apply border border-sirius-orange-100;
}
</style>
