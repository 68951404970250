export const prizes = [
  {
    place: {
      ru: 'дополнительный розыгрыш',
      kk: 'қосымша ұтыс ойны',
    },
    title: {
      ru: 'Смартфон Apple iPhone 15 Pro 128/8GB Blue Titanium',
      kk: 'Apple iPhone 15 Pro 128/8GB Blue Titanium смартфоны',
    },
    image: new URL('./images/prizes/iphone-additional.png', import.meta.url)
      .href,
  },
  {
    place: {
      ru: '1 место',
      kk: '1 орын',
    },
    title: {
      ru: 'Ноутбук Apple MacBook Air 15 Midnight 2023 M2 / 8ГБ',
      kk: 'Apple MacBook Air 15 Midnight 2023 M2 / 8ГБ ноутбугы',
    },
    image: new URL('./images/prizes/macbook.png', import.meta.url).href,
  },
  {
    place: {
      ru: '2 место',
      kk: '2 орын',
    },
    title: {
      ru: 'Смартфон Apple iPhone 15 Pro 128/8GB Blue Titanium',
      kk: 'Apple iPhone 15 Pro 128/8GB Blue Titanium смартфоны',
    },
    image: new URL('./images/prizes/iphone.png', import.meta.url).href,
  },
  {
    place: {
      ru: '3 место',
      kk: '3 орын',
    },
    title: {
      ru: 'Стайлер Dyson Airwrap HS05 Long синий/медь',
      kk: 'Dyson Airwrap HS05 Long көк/мыс стайлері',
    },
    image: new URL('./images/prizes/dyson.png', import.meta.url).href,
  },
  {
    place: {
      ru: '4 место',
      kk: '4 орын',
    },
    title: {
      ru: 'Игровая консоль Sony PlayStation&nbsp;5',
      kk: 'Sony PlayStation 5 ойын консолі',
    },
    image: new URL('./images/prizes/playstation.png', import.meta.url).href,
  },
  {
    place: {
      ru: '5 место',
      kk: '5 орын',
    },
    title: {
      ru: 'Робот-пылесос Xiaomi Robot Vacuum X10 Белый',
      kk: 'Xiaomi Robot Vacuum X10 ақ робот-шаңсорғышы',
    },
    image: new URL('./images/prizes/vacuum.png', import.meta.url).href,
  },
  {
    place: {
      ru: '6, 7 место',
      kk: '6,7 орын',
    },
    title: {
      ru: 'Смарт часы Apple Watch SE 2022',
      kk: 'Apple Watch SE 2022 смарт сағаты',
    },
    image: new URL('./images/prizes/watch.png', import.meta.url).href,
  },
  {
    place: {
      ru: '8, 9 место',
      kk: '8, 9 орын',
    },
    title: {
      ru: 'Умный электрогриль Tefal Optigrill+',
      kk: 'Tefal Optigrill+ ақылды электргрилі',
    },
    image: new URL('./images/prizes/grill.png', import.meta.url).href,
  },
  {
    place: {
      ru: '10 – 12 место',
      kk: '10 – 12 орын',
    },
    title: {
      ru: 'Кофемашина Philips',
      kk: 'Philips кофемашинасы',
    },
    image: new URL('./images/prizes/coffee.png', import.meta.url).href,
  },
  {
    place: {
      ru: '13 – 15 место',
      kk: '13 – 15 орын',
    },
    title: {
      ru: 'Наушники Apple AirPods 3rd Generation',
      kk: 'Apple AirPods 3rd Generation наушниктері',
    },
    image: new URL('./images/prizes/airpods.png', import.meta.url).href,
  },
  {
    place: {
      ru: '16 – 19 место',
      kk: '16 – 19 орын',
    },
    title: {
      ru: 'Мясорубка Bosch',
      kk: 'Bosch еттартқышы',
    },
    image: new URL('./images/prizes/meat-grinder.png', import.meta.url).href,
  },
  {
    place: {
      ru: '20 – 24 место',
      kk: '20 – 24 орын',
    },
    title: {
      ru: 'Мультиварка Polaris',
      kk: 'Polaris мультипісіргіштері',
    },
    image: new URL('./images/prizes/multicooker.png', import.meta.url).href,
  },
  {
    place: {
      ru: '25 – 29 место',
      kk: '25 – 29 орын',
    },
    title: {
      ru: 'Увлажнитель воздуха Polaris',
      kk: 'Polaris ауа ылғалдандырғыштары',
    },
    image: new URL('./images/prizes/humidifier.png', import.meta.url).href,
  },
  {
    place: {
      ru: '30 – 35 место',
      kk: '30 – 35 орын',
    },
    title: {
      ru: 'Умная колонка Яндекс Станция Лайт, Ультрафиолет',
      kk: 'Яндекс Станция Лайт, Ультракүлгін ақылды колонкалары',
    },
    image: new URL('./images/prizes/smart-speaker.png', import.meta.url).href,
  },
  {
    place: {
      ru: '36 – 42 место',
      kk: '36 – 42 орын',
    },
    title: {
      ru: 'Блендер погружной Bosch',
      kk: 'Bosch терең блендері',
    },
    image: new URL('./images/prizes/blender.png', import.meta.url).href,
  },
  {
    place: {
      ru: '43 – 50 место',
      kk: '43 – 50 орын',
    },
    title: {
      ru: 'Тостер Braun',
      kk: 'Braun тостері',
    },
    image: new URL('./images/prizes/toaster.png', import.meta.url).href,
  },
];
