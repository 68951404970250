export const STATUS_STEPS = {
  CREATED: 1,
  PREPARING: 2,
  DELIVERING: 3,
  DELIVERED: 4,
};

export const ORDER_STATUSES = {
  CREATED: 1,
  READY: 2,
  IS_BEING_FORMED_BY_PICKER: 3,
  FORMED_BY_PICKER: 4,
  GIVEN_TO_COURIER: 5,
  ARRIVED_TO_ADDRESS: 6,
  DELIVERED: 7,
  CANCELED: 8,
  FINISHED: 10,
};

export const CANCEL_REASONS = [
  {
    name: 'REASON_TYPE_CANCELED_BY_CLIENT_FORGOT_PRODUCTS',
    reason: 'Забыл(а) добавить товар',
    reason_ru: 'Забыл(а) добавить товар',
    reason_kk: 'Тауар қосуды ұмытып кеттім',
    reason_id: 10,
  },
  {
    name: 'REASON_TYPE_CANCELED_BY_CLIENT_PTHER_ADDRESS',
    reason: 'Я указал(а) неверный адрес доставки',
    reason_ru: 'Я указал(а) неверный адрес доставки',
    reason_kk: 'Басқа мекенжай таңдап қойдым',
    reason_id: 11,
  },
  // {
  //   name: 'REASON_TYPE_CANCELED_BY_CLIENT_PLANS_CHANGED',
  //   reason: 'У меня поменялись планы',
  //   reason: 'У меня поменялись планы',
  //   reason_id: 12
  // },
  {
    name: 'REASON_TYPE_CANCELED_BY_CLIENT_LONG_TIME-COLLECTION',
    reason: 'Мой заказ долго собирают',
    reason_ru: 'Мой заказ долго собирают',
    reason_kk: 'Менің тапсырысым ұзақ жиналды',
    reason_id: 13,
  },
  {
    name: 'REASON_TYPE_CANCELED_BY_CLIENT',
    reason: 'Другая причина',
    reason_ru: 'Другая причина',
    reason_kk: 'Басқа себеп',
    reason_id: 14,
    comment: '',
  },
];
