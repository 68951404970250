<template>
  <section class="MmFormDesktop">
    <div class="MmFormDesktop__container">
      <MmForm />
    </div>
  </section>
</template>

<script>
import MmForm from '@/views/micromarket/components/MmForm/MmForm.vue';

export default {
  name: 'MmFormDesktop',
  components: { MmForm },
  inject: [],
  props: {},
  data: () => ({}),

  i18n: {
    messages: {
      kk: {},
      ru: {},
    },
  },
};
</script>

<style scoped>
.MmFormDesktop {
  margin-top: 226px;
  background: linear-gradient(
    141deg,
    rgba(255, 179, 64, 0.2) -8.67%,
    rgba(255, 138, 72, 0.2) 106.38%
  );
}

.MmFormDesktop__container {
  margin: auto;
  width: calc(100% - 32px);
  max-width: 988px;
}

@media (min-width: 768px) {
  .MmFormDesktop {
    margin-top: 116px;
  }
}
</style>
