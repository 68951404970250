export function bannerAdapter(v) {
  return {
    type: 'banner',
    code: v.code,
    textKk: v.title_kk,
    textRu: v.title_ru,
    imageMobile: v.image_mobile,
    imageDesktop: v.image_desktop,
    url: v.url,
    fixedPosition: v.is_fixed_position,
  };
}
