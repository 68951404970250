<template>
  <div class="ContestTable">
    <div class="ContestTable__block">
      <NyPageSubtitle id="ny-table" class="ContestTable__title">
        {{ $t('title') }}
      </NyPageSubtitle>
      <template v-if="$store.getters['isAuthorized']">
        <div class="ContestTable__descr">{{ $t('descr') }}</div>
        <div class="ContestTable__current">
          <CurrentResultItem
            class="ContestTable__currentItem"
            :value="(score || 0).toString()"
            :text="$t('earned')"
          />
          <CurrentResultItem
            class="ContestTable__currentItem"
            :value="place > 999 ? '999+' : `${place}`"
            :text="$t('place')"
          />
        </div>
        <ul class="ContestTable__list">
          <li
            v-for="item in visibleList"
            :key="item.phone"
            class="ContestTable__listItem"
          >
            <TableItem
              :place="item.place"
              :score="item.score"
              :phone="item.phone"
            />
          </li>
        </ul>
        <ExpandButton
          v-if="!showAll"
          class="ContestTable__expandButton"
          @click="showAll = true"
        />
      </template>
      <template v-if="!$store.getters['isAuthorized']">
        <div class="ContestTable__descr">{{ $t('authDescr') }}</div>
        <UiButtonNew
          class="ContestTable__btnAuth"
          size="lg"
          @click="$emit('onAuthBtnClick')"
          >{{ $t('authBtn') }}</UiButtonNew
        >
      </template>
    </div>
  </div>
</template>

<script>
import UiButtonNew from '@ui/UiButtonNew.vue';

import NyPageSubtitle from '@/views/ny-2023-contest/components/NyPageSubtitle/NyPageSubtitle.vue';
import TableItem from '@/views/ny-2023-contest/components/ContestTable/components/TableItem/TableItem.vue';
import ExpandButton from '@/views/ny-2023-contest/components/ContestTable/components/ExpandButton/ExpandButton.vue';
import CurrentResultItem from './components/CurrentResult/CurrentResultItem.vue';

export default {
  name: 'ContestTable',
  components: {
    UiButtonNew,
    CurrentResultItem,
    NyPageSubtitle,
    TableItem,
    ExpandButton,
  },
  props: {
    place: {
      type: Number,
      default: 0,
    },
    score: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showAll: false,
  }),
  computed: {
    visibleList() {
      if (this.showAll) {
        return this.list;
      }
      return this.list.slice(0, 3);
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'Қолданушылардың ұпай кестесі',
        descr: 'Алғашқы 50 қолданушының рейтингі',
        earned: 'қоржындағы ұпай',
        place: 'рейтингтегі орын',
        authDescr: 'Нәтижелерді көргіңіз келсе, тіркеліңіз',
        authBtn: 'Тіркелу',
      },
      ru: {
        title: 'Таблица баллов пользователей',
        descr: 'Рейтинг первых 50 пользователей',
        earned: 'баллов заработано',
        place: 'место в&nbsp;рейтинге',
        authDescr: 'Авторизуйтесь, чтобы увидеть результаты',
        authBtn: 'Авторизоваться',
      },
    },
  },
};
</script>

<style scoped>
.ContestTable {
  padding-top: 56px;
  width: calc(100% - 24px);
  margin: auto;
}

.ContestTable__block {
  padding: 32px 24px 48px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 1px 8px 24px 0px rgba(110, 83, 52, 0.2);
}

.ContestTable__descr {
  padding-top: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #767485;
}

.ContestTable__current {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f1f2f7;
}

.ContestTable__list {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ContestTable__expandButton {
  margin-top: 32px;
  width: 100%;
}

.ContestTable__btnAuth {
  margin-top: 32px;
  width: 280px;
  font-weight: bold;
}

@media screen and (min-width: 768px) {
  .ContestTable {
    max-width: 1188px;
    padding-top: 128px;
  }

  .ContestTable__block {
    position: relative;
    padding: 72px 100px;
    border-radius: 64px;
  }

  .ContestTable__descr {
    padding-top: 24px;
    font-size: 18px;
    line-height: 24px;
  }

  .ContestTable__current {
    border-bottom: 0;
    position: absolute;
    right: 100px;
    top: 204px;
    padding-top: 0;
  }

  .ContestTable__currentItem + .ContestTable__currentItem {
    padding-top: 24px;
  }

  .ContestTable__expandButton {
    margin-top: 64px;
    width: 332px;
  }

  .ContestTable__btnAuth {
    margin-top: 64px;
  }
}
</style>
