<template>
  <MapView
    ref="MapViewRef"
    class="MapContainer"
    :polygons="polygons"
    :geo-btn="geoBtn"
    :zoom-btns="zoomBtns"
    :input-coords="inputCoords"
    @onMapInit="onMapInit"
    @onMapActionEnd="$emit('onMapActionEnd', $event)"
    @onMapActionStart="$emit('onMapActionStart', $event)"
    @onMapCenterUpdated="onMapCenterUpdated"
  />
</template>

<script>
import MapView from '@pure-ui/components/Map/MapView/MapView.vue';
import { CityService } from '@services/city';
import { getPolygonsFromResponse } from '@services/map/polygons';
import { rFetchWarehouses } from '@shared/services/api/requestsV2';

export default {
  components: {
    MapView,
  },
  props: {
    inputCoords: {
      type: Array,
      default: () => [],
    },
    geoBtn: Boolean,
    zoomBtns: Boolean,
  },
  data() {
    return {
      map: null,
      polygons: {
        regular: [],
        extended: [],
      },
    };
  },
  created() {
    this.fetchDeliveryZones();
  },
  methods: {
    fetchDeliveryZones() {
      rFetchWarehouses().then(({ data }) => {
        let polygons = getPolygonsFromResponse(data);
        console.log('polygons', polygons);

        this.polygons = {
          regular: polygons.regular,
          extended: polygons.extended,
        };
      });
    },
    // outside access
    defineDefaults() {
      this.map.defineByCoords(CityService.getCityData().defaultCoords);
    },
    // outside access
    defineByCoords(coords) {
      this.map.defineByCoords(coords);
    },
    // outside access
    async defineGeolocation(isInit = false) {
      this.map.findGeoLocation(isInit).catch(() => {
        if (isInit) this.defineDefaults();
        else this.toast.show(this.$t('unableToGetGeoposition'), 'warning');
      });
    },
    onMapCenterUpdated(data) {
      this.$emit('onMapCenterUpdated', data);
    },
    onMapInit(data) {
      this.map = data.map;
      this.$emit('onMapInit', data.options);
    },
  },
  i18n: {
    messages: {
      ru: {
        errorHappened: 'Произошла ошибка',
        unableToGetGeoposition:
          'Не удалось определить геопозицию, разрешите сбор геоданных в&nbsp;настройках браузера',
      },
      kk: {
        errorHappened: 'Қате орын алды',
        unableToGetGeoposition:
          'Тұрған жеріңіз табылмады, браузер параметріндегі геодеректерді жинауға рұхсат етіңіз',
      },
    },
  },
};
</script>

<style></style>
