export const i18n = {
  messages: {
    kk: {
      call: 'Қоңырау шалу',
      writeWH: 'Whatsapp-қа жазу',
      careService: 'Қамқорлық қызметі',
      writeMessage: 'Хат жазу',
      contactWithSuppotService: 'Қолдау көрсету қызметімен хабарласу',
    },
    ru: {
      call: 'Позвонить',
      writeWH: 'написать Whatsapp',
      careService: 'Служба заботы',
      writeMessage: 'Написать сообщение',
      contactWithSuppotService: 'Связаться со службой заботы',
    },
  },
};
