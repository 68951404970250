<template>
  <p class="total-price">{{ formatPrice(quantity * price) }} ₸</p>
</template>

<script>
import { formatPrice } from '@/utils/formatters/price';

export default {
  name: 'ProductTotalPrice',
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    price: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    formatPrice,
  },
};
</script>

<style scoped>
.total-price {
  @apply text-sm font-medium leading-4;
}
</style>
