import Vue from 'vue';
import dayjs from 'dayjs';

import { DevConfigs } from '@/utils/dev-config';

const state = Vue.observable({
  logs: [],
  amplitudeLogs: [],
  segmentLogs: [],
  appsflyerLogs: [],
  networkLogs: [],
  routerLogs: [],
  wsLogs: [],
  eventBus: [],
});

const getLogType = type => {
  const logsTitle = {
    Appsflyer: 'appsflyerLogs',
    Amplitude: 'amplitudeLogs',
    Segment: 'segmentLogs',
    Network: 'networkLogs',
    EventBus: 'eventBus',
    Router: 'routerLogs',
    WS: 'wsLogs',
  };
  return logsTitle[type] ?? 'logs';
};

const setLog = payload => {
  const logType = getLogType(payload.subtitle);
  state[logType].unshift(payload);
};

const clear = type => {
  const logType = getLogType(type);
  state[logType] = [];
};

const printEvent = payload => {
  const { title, data, subtitle } = payload;
  if (getLogType(subtitle) === 'amplitudeLogs')
    console.log(
      `%c[amplitude] ${title}`,
      'background: blue; color: #ccc;',
      data.payload
    );
  else if (data && Object.keys(data).length)
    console.log(title, data, subtitle ? `-- ${subtitle}` : '');
  else console.log(title, subtitle ? `-- ${subtitle}` : '');
};

const event = (title, data = {}, subtitle = '') => {
  const payload = {
    title,
    subtitle,
    data,
    time: dayjs().format('HH:mm:ss.SSS'),
  };

  if (
    (DevConfigs.isDev || DevConfigs.isStage) &&
    ['logs', 'amplitudeLogs'].includes(getLogType(subtitle)) &&
    DevConfigs.consoleLogs
  ) {
    printEvent(payload);
  }

  setLog(payload);
};

export const Logger = {
  state,
  clear,
  event,
};
