<template>
  <div
    class="UiLoader ui-loader"
    :class="`--${variant} --${size} ${fullScreen ? '--full' : ''}`"
  >
    <transition :name="transition" appear>
      <div class="duration-500">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'UiLoader',
  props: {
    transition: {
      type: String,
      default: 'fade',
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.ui-loader {
  @apply flex items-center justify-center;
}
.--full.ui-loader {
  @apply fixed z-overlay inset-0 bg-overlay ease-in duration-200 overflow-hidden;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  /* margin: 8px; */
  border: 4px solid #ffa100;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffa100 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.--sm .lds-ring {
  width: 28px;
  height: 28px;
}
.--sm .lds-ring div {
  width: 18px;
  height: 18px;
  border-width: 2px;
}
.--md .lds-ring {
  width: 32px;
  height: 32px;
}
.--md .lds-ring div {
  width: 22px;
  height: 22px;
  border-width: 2px;
}
.--lg .lds-ring {
  width: 36px;
  height: 36px;
}
.--lg .lds-ring div {
  width: 24px;
  height: 24px;
  border-width: 2px;
}
.--white .lds-ring div {
  border-color: white transparent transparent transparent;
}
.--gray .lds-ring div {
  border-color: #acaaba transparent transparent transparent;
}
.--gray2 .lds-ring div {
  border-color: #e7e9f0 transparent transparent transparent;
}
@media (min-width: 768px) {
  .lds-ring {
    width: 70px;
    height: 70px;
  }
  .lds-ring div {
    width: 54px;
    height: 54px;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
