<template>
  <UiBottomSheetPopup
    :show="show"
    closable
    with-title
    with-back-button
    style="--popup-width-d: 588px"
    @change="$emit('change', $event)"
  >
    <template #title>
      <p class="conditons-modal-title">{{ title }}</p>
    </template>
    <div class="conditons-modal-content">
      <CondsBox class="conditons-modal-text" :condition="condition" />
      <UiButton class="conditons-modal-btn" size="lg" @click="onClickReady">
        {{ $t('ready') }}
      </UiButton>

      <UiButton
        v-if="moreButton && !$isDesktop"
        class="mt-2 conditons-modal-btn"
        size="lg"
        variant="gray"
        @click="onClickMore"
      >
        {{ $t('moreBtn') }}
      </UiButton>
    </div>
  </UiBottomSheetPopup>
</template>

<script>
import UiBottomSheetPopup from '@pure-ui/components/UiBottomSheetPopup/UiBottomSheetPopup.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import CondsBox from './CondsBox.vue';

export default {
  name: 'ConditionsModal',
  components: {
    UiBottomSheetPopup,
    CondsBox,
    UiButton,
  },
  model: {
    prop: 'show',
    event: 'change',
  },
  props: {
    show: Boolean,
    condition: {
      type: Object,
      required: true,
    },
    moreButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      counts: {
        1: 'first',
        2: 'second',
        3: 'third',
        4: 'fourth',
        5: 'fives',
      },
    };
  },
  computed: {
    title() {
      if (this.$isDesktop) return this.$t('conditionsTitle');
      const count = this.$t(this.counts[this.condition.count]);
      return this.$t('title', [count]);
    },
  },
  methods: {
    onClickReady() {
      this.$emit('change', false);
      this.$emit('onClickReady');
    },
    onClickMore() {
      this.$emit('change', false);
      this.$emit('onClickMore');
    },
  },
  i18n: {
    messages: {
      kk: {
        first: 'Бірінші',
        second: 'Екінші',
        third: 'Үшінші',
        fourth: 'Төртінші',
        fives: 'Бесінші',
        title: '{0} тапсырысқа кешбэк',
        ready: 'Түсінікті',
        moreBtn: 'Кешбэктер туралы',
        conditionsTitle: 'Кешбэк шарттары',
      },
      ru: {
        first: 'первый',
        second: 'второй',
        third: 'третий',
        fourth: 'четвертый',
        fives: 'пятый',
        title: 'Кешбэк за {0} заказ',
        ready: 'Готово',
        moreBtn: 'Подробнее о кешбэках',
        conditionsTitle: 'Условия кешбэка',
      },
    },
  },
};
</script>

<style scoped>
.conditons-modal-title {
  @apply md:text-32 md:leading-10;
}
.conditons-modal-content {
  @apply pb-3 md:pb-0;
}
.conditons-modal-text {
  @apply mb-9 mt-4 ml-4  text-sirius-black-100 flex flex-col space-y-3 md:mb-12 md:mt-6 md:ml-2;
}

.conditons-modal-btn {
  @apply w-full text-lg font-bold leading-5;
}
</style>
