import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { createStorage } from '@shared/services/storage';
import dayjs from 'dayjs';

const storage = createStorage(window.localStorage);
const KEY = 'user-agreement::is-shown';

export const STATES = {
  notViewed: 'не увидел',
  viewed: 'увидел',
  crestik: 'нажал крестик',
  close: 'нажал закрыть',
  closeModal: 'закрыл модалку',
  look: 'нажал ознакомится',
};

function isShown() {
  return storage.parse(KEY, false);
}
export function setShown() {
  storage.set(KEY, true);
}
export function canShowReminder() {
  let end = '2024-07-25';
  let validDate = dayjs().isBefore(end, 'day', '[]');
  return validDate && !isShown();
}

// view = mobile | modal
export function logAnalytics(state, view) {
  if (state !== STATES.notViewed) {
    Analitycs.logEvent(EVENTS.VIEWED_USER_AGREEMENT, {
      state,
      view,
    });
  }
}
