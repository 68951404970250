const LS_KEY = 'used-promocodes';

function saveToLocalStorage(value) {
  let v = window.localStorage.getItem(LS_KEY) || '';
  if (v) {
    v = v.split(',');
    v.push(value);
  } else {
    v = [value];
  }
  v = v.join(',');
  window.localStorage.setItem(LS_KEY, v);
}

/**
 * Сохраняем использованные промокоды на какое-то время,
 * т. к. после оплаты lavka-promoactions может обновить состояние
 * через не определённое время. На тесте было несколько секунд,
 * от 5 до 19, но могут быть и минуты
 */
export function saveUsedPromocode(promocode) {
  saveToLocalStorage(promocode);
}

/**
 * Возвращает промокоды, которые были применены в корзине.
 * Только те, что были сохранены в localStorage на конкретном
 * устройстве.
 * @returns {Array<string>} список использованных промокодов
 */
export function getUsedPromocodes() {
  const v = window.localStorage.getItem(LS_KEY);
  if (v) {
    return v.split(',');
  }
  return [];
}
