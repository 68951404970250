<template>
  <div class="OrderStatus">
    <div
      class="flex flex-col md:flex-row-reverse md:items-center md:justify-between"
    >
      <p class="font-medium text-sm text-sirius-gray-700">
        <span v-if="paidAt" class="mr-6">
          {{ paidAt }}
        </span>
        <span> # {{ order.code }} </span>
      </p>
      <h1 class="mt-6 md:mt-0 font-bold text-2xl">
        {{ $t(title) }}
      </h1>
    </div>

    <p class="mt-5 md:mt-6 font-medium md:text-lg text-sirius-gray-100">
      {{ $t(description) }}
    </p>
    <StatusBar
      class="mt-7 mb-6 md:mb-9"
      :step="step"
      :is-car-delivery="order.delivery_type === 1"
    />

    <div v-if="hasCourierNumber" class="hidden md:flex items-center mb-7">
      <img :src="phoneIcon" width="32" height="32" />
      <p class="ml-3 font-medium text-lg">
        {{
          $t(hasCourierName ? 'courierName' : 'callCourier', {
            name: courierName,
          })
        }}
      </p>
      <a
        :href="numberCallLink"
        class="ml-3 text-sirius-orange-100 text-lg font-bold"
      >
        {{ courierNumber }}
      </a>
    </div>
    <a v-if="hasCourierNumber" :href="numberCallLink" class="block md:hidden">
      <UiButton class="w-full mb-3" variant="gray" size="lg">
        <img :src="phoneIcon" width="24" height="24" />
        <p
          class="ml-1 font-bold text-lg whitespace-nowrap overflow-hidden text-ellipsis"
        >
          {{
            $t(hasCourierName ? 'courierName' : 'callCourier', {
              name: courierName,
            })
          }}
        </p>
      </UiButton>
    </a>

    <SupportButtons class="mb-3 md:mb-10" />

    <UiButton
      v-if="status < ORDER_STATUSES.FORMED_BY_PICKER"
      class="w-full mb-3 last:mb-0 text-lg font-bold"
      variant="gray"
      size="lg"
      @click="$emit('openCancelOrder')"
    >
      {{ $t('toCancelOrder') }}
    </UiButton>

    <p
      v-if="isMini"
      class="w-full text-sirius-orange-100 font-bold text-sm text-center cursor-pointer"
      @click="goToOrder"
    >
      {{ $t('toGoToOrderDetails') }}
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateAndTime } from '@/utils/formatters/date';
import { ORDER_STATUSES } from '@services/orders/constants';
import {
  matchStatusWithInfo,
  LOCALIZATION,
} from '@services/orders/utils/match-status-with-info';

import SupportButtons from '@shared/components/support/SupportButtons.vue';
import UiButton from '@ui/UiButtonNew.vue';
import StatusBar from './StatusBar.vue';

import { STEPS } from '../config';

export default {
  name: 'OrderStatus',
  components: {
    SupportButtons,
    StatusBar,
    UiButton,
  },
  inject: ['toast', 'popup', 'bottomSheet'],
  props: {
    order: {
      type: Object,
      required: true,
    },
    isMini: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      courierName: null,
      courierNumber: null,
      status: -1,
      ORDER_STATUSES,
      phoneIcon: new URL('@img/icons/phone.svg', import.meta.url).href,
    };
  },
  computed: {
    ...mapGetters(['isDesktop']),
    statusInfo() {
      return matchStatusWithInfo(this.status);
    },
    orderId() {
      return this.order.id.toString();
    },
    step() {
      return this.statusInfo.step;
    },
    description() {
      return this.statusInfo.description;
    },
    title() {
      return this.statusInfo.title;
    },
    numberCallLink() {
      return this.courierNumber ? `tel:${this.courierNumber}` : null;
    },
    paidAt() {
      return this.order.paid_at
        ? dateAndTime(this.order.paid_at, true, this.$i18n.locale)
        : '';
    },
    hasCourierNumber() {
      return (
        this.courierNumber &&
        this.statusInfo &&
        (this.step === STEPS.DELIVERING || this.step === STEPS.DELIVERED)
      );
    },
    hasCourierName() {
      return (
        this.courierName &&
        this.statusInfo &&
        (this.step === STEPS.DELIVERING || this.step === STEPS.DELIVERED)
      );
    },
    hasTwoCouriers() {
      return this.order?.couriers?.length > 1;
    },
  },
  watch: {
    'order.status': function onPropsChange() {
      this.updateData();
    },
    'order.is_reviewed': function onPropsChange() {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    goToOrder() {
      this.$router.push({
        name: 'order',
        params: { id: this.order.id.toString() },
      });
    },
    updateData() {
      if (this.order.courier_name) {
        this.courierName = this.order.courier_name;
      }
      if (this.order.courier_phone_number) {
        this.courierNumber = this.order.courier_phone_number;
      }
      this.status = this.order.status;
    },
  },
  i18n: {
    messages: {
      ru: {
        ...LOCALIZATION.messages.ru,
        courierName: 'Курьер {name}',
        callCourier: 'Позвонить курьеру',
        toCancelOrder: 'Отменить заказ',
        toGoToOrderDetails: 'Перейти в детали заказа',
        confirmOrderCanceling: 'Подтвердите отмену заказа',
        discountForFirstOrderWillBeLost:
          'Скидка {discount} ₸ за первый заказ сгорит.',
        somethingWrongWithOrder: 'Что-то не так с заказом',
        moneyWillBeReturned: `
          Оплаченные вами деньги <br />
          за заказ вернутся.
        `,
        moneyMayBeDelayed: `
          Часто деньги приходят сразу, <br />
          но могут задержаться из-за <br />
          правил банка, в таком случае <br />
          они придут в течение трех <br />
          рабочих дней.
        `,
        close: 'Закрыть',
      },
      kk: {
        ...LOCALIZATION.messages.kk,
        courierName: 'Курьер {name}',
        callCourier: 'Курьерге хабарласу',
        toCancelOrder: 'Тапсырыстан бас тарту',
        toGoToOrderDetails: 'Тапсырыс мәліметтеріне өту',
        confirmOrderCanceling: 'Тапсырыстан бас тартуды растаңыз',
        discountForFirstOrderWillBeLost:
          'Бірінші тапсырыс үшін {discount} ₸ жеңілдік мерзімі аяқталады.',
        somethingWrongWithOrder: 'Что-то не так с заказом',
        moneyWillBeReturned: 'Тапсырыс үшін төлеген ақшаңыз қайтарылады',
        moneyMayBeDelayed: `
          Көбінесе ақша бірден түседі, бірақ банк ережелеріне байланысты
          кешіктірілуі мүмкін, бұл жағдайда ақша үш жұмыс күні ішінде түседі.
        `,
        close: 'Жабу',
      },
    },
  },
};
</script>

<style scoped>
.text-32px {
  font-size: 32px;
}
</style>
