<template>
  <div class="RemoveAccountPage">
    <LogoDesk />
    <div class="RemoveAccountPage__container">
      <h1 class="RemoveAccountPage__title">Удаление аккаунта</h1>

      <template v-if="state.name === 'unauthorized'">
        <p class="RemoveAccountPage__descr">
          Для удаления аккаунта необходимо авторизоваться:
        </p>
        <UiButton class="RemoveAccountPage__btn" @click="$emit('onAuth')">
          Авторизоваться
        </UiButton>
        <DescriptionText class="RemoveAccountPage__descriptionText" />
      </template>

      <template v-if="state.name === 'authorized'">
        <p class="RemoveAccountPage__descr">
          Чтобы удалить аккаунт, вам потребуется ввести код из СМС
        </p>
        <UiButton class="RemoveAccountPage__btn" @click="$emit('onRemove')">
          Удалить аккаунт
        </UiButton>
        <DescriptionText class="RemoveAccountPage__descriptionText" />
      </template>

      <template v-if="state.name === 'codeHasBeenSent'">
        <p class="RemoveAccountPage__descr">Введите код из СМС:</p>
        <form @submit.prevent="$emit('onSubmitCode', smsCode)">
          <UiInput v-model="smsCode" pattern="[0-9]*" maxlehgth="4" />
          <p v-if="state.errorMessage" class="RemoveAccountPage__errorMessage">
            {{ state.errorMessage }}
          </p>
          <UiButton class="RemoveAccountPage__btn">
            Подтвердить удаление
          </UiButton>
        </form>
      </template>

      <template v-if="state.name === 'success'">
        <p class="RemoveAccountPage__descr">Ваш аккаунт успешно удалён</p>
        <DescriptionText class="RemoveAccountPage__descriptionText" />
      </template>

      <template v-if="state.name === 'loading'">
        <UiLoader style="padding-top: 36px" />
      </template>

      <template v-if="state.name === 'error'">
        <p class="RemoveAccountPage__descr">
          Произошла ошибка: "{{ state.errorMessage }}"
        </p>
        <UiButton class="RemoveAccountPage__btn" @click="$emit('onRefresh')">
          Повторить
        </UiButton>
      </template>
    </div>
  </div>
</template>

<script>
import UiButton from '@/pure-ui/components/UiButton/UiButton.vue';
import UiInput from '@/pure-ui/components/UiInput/UiInput.vue';
import UiLoader from '@/pure-ui/components/UiLoader/UiLoader.vue';
import LogoDesk from '@components/header/desk-header/components/LogoDesk.vue';
import DescriptionText from './components/DescriptionText/DescriptionText.vue';

export default {
  name: 'RemoveAccountPage',
  components: {
    UiButton,
    UiInput,
    UiLoader,
    DescriptionText,
    LogoDesk,
  },
  inject: [],
  props: {
    state: {
      type: Object,
      default: () => ({ name: 'unauthorized' }),
      validator: v => {
        const options = [
          'unauthorized',
          'authorized',
          'codeHasBeenSent',
          'success',
          'loading',
          'error',
        ];
        return options.includes(v.name);
      },
    },
  },
  data: () => ({ smsCode: '' }),
  computed: {},
  methods: {},
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.RemoveAccountPage {
  padding: 16px;
}

.RemoveAccountPage__container {
  max-width: 588px;
  width: 100%;
  margin: auto;
}

.RemoveAccountPage__title {
  padding-top: 48px;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}

.RemoveAccountPage__descr {
  padding-top: 32px;
}

.RemoveAccountPage__btn {
  margin-top: 8px;
  font-weight: 700;
}

.RemoveAccountPage__errorMessage {
  color: red;
}

.RemoveAccountPage__descriptionText {
  padding-top: 36px;
}
</style>
