import { bannerAdapter } from '@services/banners/adapters/banner';
import { storyAdapter } from '@services/banners/adapters/story';
import { categoryBannerAdapter } from '@services/banners/adapters/categoryBanner';

export function extractBanners(banners) {
  return banners.reduce((acc, v) => {
    if (v.type === 'banner') {
      acc.push(bannerAdapter(v));
    }

    if (v.type === 'banner_story') {
      acc.push(storyAdapter(v));
    }

    if (v.type === 'story_in_category') {
      acc.push(categoryBannerAdapter(v));
    }

    return acc;
  }, []);
}
