<template>
  <div class="PrizeItem">
    <div class="PrizeItem__imgWrap">
      <img v-if="image && !$isDesktop" :src="image" width="88" loading="lazy" />
      <img
        v-if="image && $isDesktop"
        :src="image"
        width="200"
        height="188"
        loading="lazy"
      />
    </div>
    <div
      class="PrizeItem__place"
      :class="{ PrizeItem__place_additional: isAdditional }"
    >
      <span v-html="place" />
    </div>
    <div class="PrizeItem__title">
      <span v-html="title" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrizeItem',
  props: {
    image: {
      type: String,
      default: '',
    },
    place: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isAdditional: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scope>
.PrizeItem {
  display: grid;
  grid-template-columns: 88px auto;
  grid-gap: 4px 8px;
}

.PrizeItem__imgWrap {
  grid-row: 1/3;
}

.PrizeItem__place {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  align-self: flex-end;
}

.PrizeItem__place_additional {
  color: #ffa100;
}

.PrizeItem__title {
  font-size: 16px;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .PrizeItem {
    grid-template-columns: 264px;
  }

  .PrizeItem__place {
    font-size: 28px;
    line-height: 32px;
  }

  .PrizeItem__title {
    padding-top: 8px;
  }
}
</style>
