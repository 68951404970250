import {
  rFetchPublicSocketConfig,
  rFetchPrivateSocketConfig,
} from '@shared/services/api/apis';
import { DELIVERY_EVENTS, DeliveryEmitter } from '@services/delivery/emitter';
import { authEmitter, AUTH_EVENTS } from '@shared/services/auth/events';
import { ClientService } from '@shared/services/client';
import { eventBus } from '@/utils/plugins/event-bus';
import { $log } from '@/utils/plugins/logger';
import { store } from '@/store';
import { Socket } from './socket';

export const publicWs = new Socket('public_ws');
export const ws = new Socket('private_ws');

function connectPublicSockets(warehouseId) {
  publicWs.disconnect();
  if (!warehouseId) return;
  $log('SocketService', { warehouseId }, 'connectPublicSockets');

  rFetchPublicSocketConfig()
    .then(({ data }) => {
      $log('SocketService', data, 'fetchPublic SocketConnectionConfig');
      const { token, client_url: url, channel_name: name } = data;
      const channel = `${name}:${warehouseId}`;
      publicWs.connect(url, token, channel);
    })
    .catch(err => {
      console.error(err);
    });
}

function connectPrivateSockets() {
  ws.disconnect();
  const userId = ClientService.getUserId();
  if (!userId || userId === -1) return;
  $log('SocketService', { userId }, 'connectPrivateSockets');

  rFetchPrivateSocketConfig(userId)
    .then(({ data }) => {
      $log('SocketService', data, 'fetchPrivate SocketConnectionConfig');
      const { token, client_url: url, channel_name: name } = data;
      const channel = `${name}#${userId}`;
      ws.connect(url, token, channel);
    })
    .catch(err => {
      console.error(err);
    });
}

/**
 * Для переподключения при входе в апку после сварачивания
 * @param {Number} warehouseId
 */
function onReconnectToApp(warehouseId) {
  $log('onReconnectToApp', null, 'EventBus');
  $log('SocketService ', null, 'onReconnectToApp');
  if (!warehouseId) warehouseId = store.getters['delivery/warehouseId'];

  connectPrivateSockets();
  connectPublicSockets(warehouseId);
}

function onChangeWarehouse(data) {
  const { newId: warehouseId } = data;
  connectPublicSockets(warehouseId);
}

function onAuth() {
  connectPrivateSockets();
}

/**
 * Устанавливаем листенеры для подключения сокетов к нужным каналам(Приватный и Публичный)
 */
function init() {
  $log('SocketService', null, 'init');
  authEmitter.on(AUTH_EVENTS.ON_AUTH, onAuth);
  eventBus.on('eb_on_reconnect_to_app', onReconnectToApp);
  DeliveryEmitter.on(DELIVERY_EVENTS.ON_WAREHOUSE_ID_CHANGE, onChangeWarehouse);
}

export const SocketService = {
  init,
};
