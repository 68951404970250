export class Timer {
  constructor({ timeoutMs, onProgressUpdate }) {
    this.timeoutMs = timeoutMs;
    this.onProgressUpdate = onProgressUpdate;
    this.progress = 0;
    this.isRunning = false;
    this.lastStartId = -1;
  }

  start(progress = 0) {
    this.isRunning = true;
    this.progress = 0;
    let startId;

    let start = Date.now();

    if (progress) {
      this.progress = progress;
      start -= (this.timeoutMs * this.progress) / 100;
    }

    const frame = () => {
      const c = Date.now();
      const percent = Math.min(((c - start) / this.timeoutMs) * 100, 100);

      if (
        this.progress < 100 &&
        this.isRunning &&
        this.lastStartId === startId
      ) {
        this.progress = percent;
        this.onProgressUpdate(percent);
        requestAnimationFrame(frame);
      }
    };

    startId = requestAnimationFrame(frame);
    this.lastStartId = startId;
  }

  pause() {
    this.isRunning = false;
  }

  resume() {
    if (this.progress && !this.isRunning) {
      this.start(this.progress);
    }
  }

  reset() {
    this.progress = 0;
    this.isRunning = false;
    this.onProgressUpdate(0);
  }
}
