import { rSendFeedback } from '@/shared/services/api/requestsV2/category-feedback/send-feedback';
import { store } from '@/store';

export function sendFeedback(payload) {
  const rateMap = {
    bad: 1,
    notGood: 2,
    ok: 3,
    good: 4,
  };

  const data = {
    call_back: !!payload.needsFeedback,
    category_survey_id: payload.surveyId,
    comment: payload.message?.trim() || '',
    grade: rateMap[payload.rate] || 0,
  };

  // /* eslint-disable */
  // if (!0) {
  //   return new Promise(resolve => {
  //     store.commit('categoryNps/REMOVE_SURVEY', payload.surveyId);
  //     resolve(data);
  //   });
  // }

  return rSendFeedback(data).then(() => {
    store.commit('categoryNps/REMOVE_SURVEY', payload.surveyId);
  });
}
