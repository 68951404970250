import { store } from '@/store';
import { isCoffeeAvailable } from '@/lib/coffee/is-coffee-available';

/**
 * Эндпоинты, которые отдают товары не в курсе, что кофе есть
 * только на одном полигоне, в них эти товары везде доступны.
 * Поэтому при смене склада и при загрузке приложения
 * нужно удалять из корзины кофе если
 * текущий адрес не в нужном полигоне
 */
export function removeCoffeeFromCart() {
  if (isCoffeeAvailable()) return;
  store.getters['cart/items'].forEach(product => {
    if (product.is_coffee) {
      store.dispatch('cart/REMOVE_PRODUCT_FROM_CART', {
        productId: product.product_id,
      });
    }
  });
}
