<template>
  <div class="SamplingPhoto sampling-photo" :class="`--${size} `">
    <ProductPhoto class="sampling-photo-img" :src="src" mix-blend />
    <div v-if="present" class="sampling-photo-present">
      <p>{{ price }} ₸</p>
    </div>
  </div>
</template>

<script>
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';

export default {
  name: 'SamplingPhoto',
  components: {
    ProductPhoto,
  },
  props: {
    price: {
      type: Number,
      default: 1,
    },
    src: {
      type: String,
      default: '',
    },
    present: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
};
</script>

<style scoped>
.sampling-photo {
  position: relative;
}

.sampling-photo,
.sampling-photo-img {
  @apply border-sirius-gray-300;
  border-radius: 24px;
}
.sampling-photo {
  border-width: 0.5px;
}
.sampling-photo-img {
  overflow: hidden;
}
.--sm.sampling-photo {
  border-width: 0;
}
.--sm.sampling-photo,
.--sm .sampling-photo-img {
  max-width: 48px;
  min-width: 48px;
  max-height: 48px;
  min-height: 48px;
  height: 48px;

  border-radius: 16px;
}
.--md.sampling-photo,
.--md .sampling-photo-img {
  max-width: 72px;
  min-width: 72px;
  max-height: 72px;
  min-height: 72px;
  height: 72px;
}
.--lg.sampling-photo,
.--lg .sampling-photo-img {
  max-width: 88px;
  min-width: 88px;
  max-height: 88px;
  min-height: 88px;
  height: 88px;

  border-radius: 28px;
}
.--xl.sampling-photo,
.--xl .sampling-photo-img {
  max-width: 140px;
  min-width: 140px;
  max-height: 140px;
  min-height: 140px;
  height: 140px;

  border-radius: 40px;
}

.sampling-photo-present {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-weight: 700;
  text-align: center;

  width: 100%;
  height: 100%;

  background-image: url('./img/gift-sticker-big-big.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.--sm .sampling-photo-present {
  bottom: 0;
  right: -8px;
  max-width: 32px;
  min-width: 32px;
  max-height: 32px;
  min-height: 32px;

  font-size: 10.667px;
  line-height: 12px;
  letter-spacing: 0.053px;
}
.--md .sampling-photo-present {
  bottom: 0;
  right: -19px;
  max-width: 52px;
  min-width: 52px;
  max-height: 52px;
  min-height: 52px;

  font-size: 17.333px;
  line-height: 19.5px;
  letter-spacing: 0.087px;
}
.--lg .sampling-photo-present {
  bottom: 6px;
  right: -28px;

  max-width: 56px;
  min-width: 56px;
  max-height: 56px;
  min-height: 56px;

  font-size: 18.667px;
  line-height: 21px;
  letter-spacing: 0.093px;
}
.--xl .sampling-photo-present {
  bottom: 12px;
  right: -37px;
  max-width: 80px;
  min-width: 80px;
  max-height: 80px;
  min-height: 80px;

  font-size: 26.667px;
  line-height: 30px;
  letter-spacing: 0.133px;
}
</style>
