import { Analitycs, EVENTS } from '@/shared/services/analitycs';

export function logBannerClick({ categoryId, screen }) {
  Analitycs.logEvent(EVENTS.ASSORTMENT_SURVEY_BANNER_CLICK, {
    category_id: categoryId,
    screen,
  });
}

export function logScorePick({ categoryId, score, flow }) {
  Analitycs.logEvent(EVENTS.ASSORTMENT_SURVEY_SCORE_PICK, {
    category_id: categoryId,
    score,
    flow,
  });
}

export function logClosed({ categoryId, screen, flow }) {
  Analitycs.logEvent(EVENTS.ASSORTMENT_SURVEY_CLOSED, {
    category_id: categoryId,
    screen,
    flow,
  });
}

export function logSend({
  categoryId,
  score,
  wantCallbackCheckbox,
  comment,
  flow,
}) {
  Analitycs.logEvent(EVENTS.ASSORTMENT_SURVEY_SEND, {
    category_id: categoryId,
    score,
    want_call_back_checkbox: wantCallbackCheckbox,
    comment_attached: !!comment,
    comment,
    flow,
  });
}
