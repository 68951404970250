<template>
  <div class="iin-page">
    <p v-if="$isDesktop" class="iin-page-title" v-html="$t('title')"></p>

    <p class="iin-page-descr" v-html="$t('descr')" />
    <div class="iin-page-toggle">
      <p v-html="$t('iinPlank')" />
      <UiToggleSwitch :value="toggle" @input="$emit('update:toggle', $event)" />
    </div>
    <template v-if="toggle">
      <div class="iin-page-form">
        <UiTextField
          v-mask="`## ## ## ### ###`"
          :value="iin"
          :label="$t('inputPlaceholder')"
          class="iin-page-input"
          maxlength="16"
          pattern="[0-9]*"
          inputmode="numeric"
          clearable
          :invalid="state === 'error'"
          @onInput="$emit('update:iin', $event)"
        >
          <template v-if="state === 'success'" #icon>
            <UiInlineIcon name="input-success" />
          </template>
          <template v-if="state !== 'default'" #message>
            <span
              class="iin-page-input-message"
              :class="`${state === 'success' ? '--success' : '--error'}`"
            >
              {{
                state === 'success' ? $t('successMessage') : $t('errorMessage')
              }}
            </span>
          </template>
        </UiTextField>
        <UiButton class="iin-page-input-btn" size="M" @click="$emit('onSave')">
          {{ $t($isDesktop ? 'addIINLess' : 'addIIN') }}
        </UiButton>
      </div>

      <p class="iin-page-input-descr" v-html="$t('offOption')" />
    </template>

    <p class="iin-page-conds-title" v-html="$t('needDocs')" />
    <p class="iin-page-conds-descr" v-html="$t('conds')" />
    <ul class="iin-page-conds-list">
      <li class="iin-page-conds-item"><p v-html="$t('cond1')" /></li>
      <li class="iin-page-conds-item"><p v-html="$t('cond2')" /></li>
      <li class="iin-page-conds-item"><p v-html="$t('cond3')" /></li>
    </ul>

    <div class="iin-page-support">
      <p class="iin-page-support-title">{{ $t('contacts') }}</p>
      <template v-if="$isDesktop">
        <div class="iin-page-support-desk">
          <div class="support-center-btn" @click="$emit('onPhone')">
            <UiInlineIcon name="support-mob-phone" />
            <span>
              {{ phone.sub }}
            </span>
          </div>
          <div class="support-center-btn" @click="$emit('onWhatsapp')">
            <UiInlineIcon name="support-mob-whatsapp" />
            <span> Whatsapp </span>
          </div>
        </div>
      </template>
      <template v-else>
        <BoxItem
          class="iin-page-support-btn"
          :item="whatsapp"
          @click.native="$emit('onWhatsapp')"
        />
        <BoxItem
          class="iin-page-support-btn"
          :item="phone"
          @click.native="$emit('onPhone')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { formatPhoneNumber } from '@/utils/formatters/phone';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiToggleSwitch from '@pure-ui/components/UiToggleSwitch/UiToggleSwitch.vue';
import BoxItem from '@pure-ui/components/Support/SupportCenter/components/BoxItem.vue';

export default {
  name: 'IINPage',
  components: { UiToggleSwitch, BoxItem, UiTextField, UiButton, UiInlineIcon },
  props: {
    iin: {
      type: String,
      default: '',
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    state: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      iinInput: '',
      isValidated: false,
      isValid: false,
      validState: '',
    };
  },
  computed: {
    whatsapp() {
      return {
        icon: 'support-whatsapp',
        title: this.$t('write'),
        sub: 'whatsapp',
      };
    },
    phone() {
      return {
        icon: 'support-phone',
        title: this.$t('call'),
        sub: formatPhoneNumber(this.phoneNumber, '-'),
      };
    },
  },
  i18n: {
    messages: {
      kk: {
        title: 'БИН/ЖСН чекте көрсету',
        descr:
          'Егер чекте ұйымның БИН номері немесе өзіңіздің ЖСН номеріңіз көрсетілгенін қаласаңыз, осы опцияны таңдап, төменге енгізіңіз',
        iinPlank: 'БИН/ЖСН чекте көрсету',
        needDocs: 'Жабылым құжаттары қажет',
        offOption:
          'Сігзе чектің ішінде БСН не ЖСН қажет болмаған кезде, осы жерге қайра оралып өшірсеңіз болады',
        inputPlaceholder: 'БСН не ЖСН енгізіңіз',
        addIIN: 'БСН/ЖСН чекке қосу',
        addIINLess: 'Чекке қосу',
        successMessage: 'Жазылған БСН/ЖСН тапсырыстан кейін чекте көрсетіледі',
        errorMessage: 'БСН/ЖСН дұрыс емес, тексеріп көріңіз',

        conds: 'Егер тапсырысыңызға жабылым құжаттарын алғыңыз келсе:',
        cond1:
          'cебетте тапсырыс бойнша түсініктемеде «жабылым құжаттары қажет» дегенді таңдап, көшірмеге қажетті реквизитті, жүкқұжат жөнелтуге керекті электрондық адресті көрсетіңіз.',
        cond2:
          'міндетті түрде «чекте БИН/ЖСН номерін көрсету» опциясын қосып, оны толтырыңыз',
        cond3:
          'қолдау қызметіне хабарласып, жабылым құжаттары керектігін айтыңыз, олар сұранысыңызды бухгалтерияға жолдайды',

        contacts: 'Қолдау көрсету қызметімен хабарласу',
        write: 'Хат жазу',
        call: 'Қоңырау шалу',
      },
      ru: {
        title: 'Указывать БИН/ИИН в чеке',
        descr:
          'Если вы хотите, чтобы&nbsp;в&nbsp;чеке был указан БИН организации или&nbsp;ваш ИИН, включите эту&nbsp;опцию и&nbsp;введите его ниже',
        iinPlank: 'Указывать БИН/ИИН&nbsp;в&nbsp;чеке',
        needDocs: 'Нужны закрывающие документы',
        offOption:
          'Когда вам перестанет быть необходим БИН или&nbsp;ИИН в&nbsp;чеке, вернитесь в этот раздел и&nbsp;отключите эту опцию',
        inputPlaceholder: 'Введите БИН или ИИН',
        addIIN: 'Добавить БИН/ИИН в чек',
        addIINLess: 'Добавить в чек',
        successMessage:
          'Введенный БИН/ИИН отобразится в чеке после доставки заказа',
        errorMessage: 'Неверный БИН/ИИН, проверьте корректность',

        conds: 'Если вы хотите получить закрывающие документы к заказу:',
        cond1:
          'в корзине, в комментарии к заказу укажите «нужны закрывающие документы», реквизиты для выписки, а&nbsp;также электронный адрес, куда следует направить накладную.',
        cond2:
          'обязательно включите опцию «Указывать БИН/ИИН&nbsp;в&nbsp;чеке» и&nbsp;заполните его',
        cond3:
          'свяжитесь со службой заботы и&nbsp;сообщите им, что вам нужны закрывающие документы, они отправят запрос в&nbsp;бухгалтерию.',

        contacts: 'Связаться со службой заботы',
        write: 'Написать',
        call: 'Позвонить',
      },
    },
  },
};
</script>

<style scoped>
.iin-page {
  @apply leading-6;
}
.iin-page-title {
  @apply mb-6 text-4xl font-bold text-nowrap;
  line-height: 44px;
}
.iin-page-toggle {
  @apply mt-4 p-3 pl-4 rounded-2xl
  bg-sirius-gray-200
  flex items-center justify-between space-x-3
  leading-5;
}
.iin-page-input {
  @apply mt-4;
}
.iin-page-input-message {
  @apply mt-1  text-sm leading-18;
}
.iin-page-input-message.--success {
  @apply text-sirius-green-200;
}
.iin-page-input-message.--error {
  @apply text-sirius-red-400;
}
.iin-page-form {
  @apply flex flex-col md:flex-row md:space-x-2;
}
.iin-page-input {
  @apply w-full;
}
.iin-page-input-btn {
  @apply mt-4;
}
@media (min-width: 768px) {
  .iin-page-input-btn {
    height: 48px;
    max-width: 169px;
  }
}
.iin-page-input-descr {
  @apply mt-4 text-sirius-gray-900
  md:mt-6;
}
.iin-page-conds-title {
  @apply mt-8 font-bold leading-5 
    md:mt-12 md:leading-6  md:text-xl;
}
.iin-page-conds-descr {
  @apply mt-2 md:mt-3;
}
.iin-page-conds-list {
  list-style: disc;
  margin: 12px;
  margin-top: 14px;
  padding-left: 12px;
}
.iin-page-conds-item {
  @apply mb-3;
}
.iin-page-conds-item:last-child {
  @apply mb-0;
}
.iin-page-support {
  @apply mt-6 md:mt-8;
}

.iin-page-support-title {
  @apply md:text-xl font-bold leading-5 md:leading-6;
}
.iin-page-support-btn {
  @apply mt-6 px-3 py-2 rounded-2xl bg-sirius-gray-200;
}
.iin-page-support-desk {
  @apply mt-4 flex items-center space-x-6;
}
.support-center-btn {
  @apply flex items-center space-x-2
    text-base font-medium leading-5 
    text-sirius-orange-100  hover:text-sirius-orange-200
    cursor-pointer;
}
</style>
