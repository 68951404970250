import { images } from './images-loader';

export const INITIAL_COORDS = [76.909588, 43.237038];

export const YMAPS_SETTINGS = {
  apiKey: import.meta.env.VITE_YANDEX_MAP_API_KEY,
  lang: 'ru_RU',
  coordorder: 'longlat',
  version: '2.1',
};

export const MARKS = [
  {
    name: '1FIT',
    coords: [76.881053, 43.234812],
    icon: images['1fit.png'],
  },
  {
    name: 'Aviata',
    coords: [76.895572, 43.238023],
    icon: images['aviata.png'],
  },
  {
    name: 'Choco',
    coords: [76.915076, 43.237169],
    icon: images['choco.png'],
  },
  {
    name: 'Freedom Mobile',
    coords: [76.937546, 43.222963],
    icon: images['freedom.png'],
  },
  {
    name: 'KazTour',
    coords: [76.914452, 43.210386],
    icon: images['kaztour.png'],
  },
  {
    name: 'KPMG',
    coords: [76.959662, 43.232325],
    icon: images['kpmg.png'],
  },
  {
    name: 'Philip Morris',
    coords: [76.948719, 43.238056],
    icon: images['philipmorris.png'],
  },
  {
    name: 'Dentsu',
    coords: [76.905143, 43.217744],
    icon: images['dentsu.png'],
  },
  {
    name: 'Citix',
    coords: [76.952471, 43.218974],
    icon: images['citix.png'],
  },
  {
    name: 'Intant',
    coords: [76.911591, 43.26065],
    icon: images['instant.png'],
  },
  {
    name: 'Apple City Corps',
    coords: [76.84701, 43.238695],
    icon: images['applecity.png'],
  },
  {
    name: 'Kulan Oil',
    coords: [76.947228, 43.240688],
    icon: images['kulan.png'],
  },
  {
    name: 'Сентрас Иншуранс',
    coords: [76.881628, 43.235915],
    icon: images['centrasinsurance.png'],
  },
  {
    name: 'Mars',
    coords: [76.94946, 43.25806],
    icon: images['mars.png'],
  },
  {
    name: 'Ситибанк Казахстан',
    coords: [76.95711, 43.257767],
    icon: images['citibank.png'],
  },
  {
    name: 'Сентрас Холдинг',
    coords: [76.928627, 43.239883],
    icon: images['centrasholding.png'],
  },
  {
    name: '31 канал',
    coords: [76.89119, 43.211073],
    icon: images['31.png'],
  },
  {
    name: 'Алма+',
    coords: [76.904205, 43.250486],
    icon: images['almaplus.png'],
  },
  {
    name: 'AlemAgro Holding',
    coords: [76.928627, 76.942087],
    icon: images['alemagro.png'],
  },
  {
    name: 'ПАРАГРАФ',
    coords: [76.931661, 43.292192],
    icon: images['paragraph.png'],
  },
  {
    name: 'Spark Логистика',
    coords: [76.924584, 43.253975],
    icon: images['sparkkz.png'],
  },
];
