<template>
  <nav class="NavList nav-list">
    <ul class="nav-list-ul">
      <li v-for="(item, index) in links" :key="index" class="nav-list-li">
        <router-link v-if="item.inner" :to="item.path">
          {{ item.name }}
        </router-link>
        <a v-else :href="item.path" target="_blank">
          {{ item.name }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.nav-list {
  @apply pt-2 w-full ml-auto;
  max-width: 732px;
}
.nav-list-ul {
  @apply w-full flex items-center justify-between;
}
.nav-list-li {
  @apply leading-18 cursor-pointer
    text-sirius-gray-700 
    hover:text-sirius-orange-100 
    active:text-sirius-orange-300 
    transition duration-300 
    hover:underline;

  letter-spacing: 0.005em;
}
</style>
