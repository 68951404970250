import { api } from '@shared/services/api';
import { store } from '@/store';

function loadDataToStore() {
  if (!store.getters.isAuthorized) {
    return Promise.resolve({});
  }

  return api.lavka.fetchNYCompetitionMarathonRating().then(data => {
    const rating = data.data.rating || [];
    const userRank = data.data.userRank || {};

    store.commit(
      'nyContest/SET_WINNERS_LIST',
      rating.map(v => ({ place: v.rank, score: v.scores, phone: v.phone }))
    );
    store.commit('nyContest/SET_CURRENT_USER', {
      place: userRank.rank,
      score: userRank.scores,
    });
  });
}

export const NyContestService = {
  loadDataToStore,
};
