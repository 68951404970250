<template>
  <div class="WishButton">
    <div v-if="showButton" class="flex justify-center py-4">
      <UiButton
        class="w-full mx-auto text-lg font-bold"
        variant="gray"
        size="lg"
        @click="openModal"
      >
        {{ $t('thereIsNothingIamLookingFor') }}
      </UiButton>
    </div>
    <UiBottomSheetPopup
      v-model="show"
      closable
      with-title
      with-back-button
      style="--popup-width-d: 472px"
    >
      <div class="pb-3 md:mt-8 md:pb-0 flex flex-col">
        <h3 class="text-2xl font-bold">
          {{ $t('whatProductsShouldWeAdd') }}
        </h3>
        <UiTextField
          v-model="wishMessage"
          class="mb-0.5 mt-4 md:mt-6"
          :label="$t('provideProductTypeAndBrand')"
          :size="$isDesktop ? 'M' : 'md'"
        />
        <div class="mt-5 md:mt-8 flex items-center space-x-3">
          <UiButton size="LG" variant="gray" @click="closeModal">
            {{ $t('cancel') }}
          </UiButton>
          <UiButton size="LG" @click="onWishPopupSend">
            {{ $t('done') }}
          </UiButton>
        </div>
      </div>
    </UiBottomSheetPopup>
  </div>
</template>

<script>
import { Analitycs, EVENTS, SEGMENT_EVENTS } from '@shared/services/analitycs';
import { sendFeedback } from '@/lib/send-feedback';

import UiBottomSheetPopup from '@pure-ui/components/UiBottomSheetPopup/UiBottomSheetPopup.vue';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'WishButton',
  components: {
    UiBottomSheetPopup,
    UiTextField,
    UiButton,
  },
  inject: ['toast'],
  props: {
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wishMessage: '',
      show: false,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
    },
    openModal() {
      this.show = true;
    },
    onWishPopupSend() {
      if (this.wishMessage.trim() === '') {
        this.wishMessage = '';
        this.toast.show(this.$t('pleaseWriteYourWishes'));
        return;
      }

      Analitycs.logEvent(EVENTS.RANGE_CATEGORY_NO_FOOD, {
        message: this.wishMessage,
        categoryId: parseInt(this.$route.params.id),
      });

      Analitycs.logSegmentEvent(SEGMENT_EVENTS.RANGE_CATEGORY_NO_FOOD, {
        message: this.wishMessage,
      });

      this.closeModal();

      sendFeedback({
        topic: 'unfound',
        message: this.wishMessage,
        needsFeedback: false,
      })
        .then(() => {
          this.toast.show(this.$t('thanksForFeedback'), 'success');
        })
        .finally(() => {
          this.wishMessage = '';
        });
    },
  },
  eventBusEvents: {
    eb_open_wish_product_popup() {
      this.openModal();
    },
  },
  i18n: {
    messages: {
      ru: {
        provideProductTypeAndBrand: 'Укажите тип товара и бренд',
        thereIsNothingIamLookingFor: 'Нет того, что ищу',
        cancel: 'Отмена',
        hide: 'скрыть',
        done: 'Готово',
        whatProductsShouldWeAdd:
          'Какие товары нам нужно добавить в ассортимент?',
        pleaseWriteYourWishes: 'Пожалуйста, напишите ваши пожелания',
        thanksForFeedback: 'Спасибо за обратную связь!',
      },
      kk: {
        provideProductTypeAndBrand: 'Тауар түрі мен брендін көрсетіңіз',
        thereIsNothingIamLookingFor: 'Мен іздеген нәрсе жоқ',
        cancel: 'Жою',
        hide: 'Жасыру',
        done: 'Дайын',
        whatProductsShouldWeAdd: 'Ассортиментке қандай өнімдерді қосу керек?',
        pleaseWriteYourWishes: 'Өзіңіздің талап-тілегіңізді қосыңыз',
        thanksForFeedback: 'Кері байланыс үшін рахмет!',
      },
    },
  },
};
</script>
