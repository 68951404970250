import Vue from 'vue';

export { default as UiPopup } from './UiPopup.vue';

export function createPopup() {
  const state = Vue.observable({
    activeName: '',
  });
  const setName = name => {
    state.activeName = name;
  };
  const assignParams = params => {
    Object.assign(state, params);
  };
  const addOnPageChangeListener = () => {
    window.addEventListener(
      'popstate',
      () => {
        setName('');
      },
      false
    );
  };
  const removeOnPageChangeListener = () => {
    window.removeEventListener(
      'popstate',
      () => {
        setName('');
      },
      false
    );
  };

  return {
    state,
    show(name, params) {
      setName(name);
      if (name) {
        assignParams(params);
        addOnPageChangeListener();
      }
    },
    hide() {
      setName('');
      removeOnPageChangeListener();
    },
  };
}
