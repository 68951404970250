import axios from 'axios';
import { stringify } from 'qs';
import { Logger as LOG } from '@shared/services/logger';
import {
  injectCity,
  injectLang,
  injectToken,
  injectDeviceId,
  transformError,
  injectFingerprints,
  injectIdempotencyKey,
} from './utils';

import { installAxiosMeasureTime } from './utils/install-axios-measure-time';

const authClient = axios.create({
  baseURL: import.meta.env.VITE_API_PROXY,
  paramsSerializer: stringify,
  transformRequest: [data => stringify(data)],
});

installAxiosMeasureTime(authClient);

authClient.interceptors.request.use(
  config => {
    injectCity(config);
    injectLang(config);
    injectToken(config);
    injectDeviceId(config);
    injectFingerprints(config);
    injectIdempotencyKey(config);
    LOG.event(`Config ${config.url}`, config, 'Network');
    return config;
  },
  err => Promise.reject(err)
);

authClient.interceptors.response.use(
  res => {
    LOG.event(`Res: ${res.config.url}`, res, 'Network');

    return res.data.error_code && res.data.error_code !== 0
      ? Promise.reject(transformError(res.data))
      : res;
  },
  err => {
    LOG.event(`Err: ${err.config.url}`, { err, config: err.config }, 'Network');

    return Promise.reject(transformError(err));
  }
);

export { authClient };
