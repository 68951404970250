<template>
  <div
    class="UiToggleSwitch toggle"
    :class="{ active: value, disabled: disabled }"
    @click="onClick"
  >
    <div class="toggle-point"></div>
  </div>
</template>

<script>
export default {
  name: 'UiToggleSwitch',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style scoped>
.toggle {
  @apply ml-0.5 p-1  
    flex items-center 
    bg-sirius-gray-300 rounded-3xl 
    transition-all duration-300 
    cursor-pointer;
  width: 42px;
  height: 26px;
}
.active {
  @apply bg-sirius-orange-100;
}
.disabled {
  @apply opacity-50 bg-sirius-gray-300;
}
.toggle-point {
  @apply bg-white rounded-full transition-transform duration-75 ease-in-out;
  height: 18px;
  width: 18px;
}
.active .toggle-point {
  transform: translateX(16px);
}
</style>
