<template>
  <transition name="popup-fade">
    <div
      v-if="isActive"
      class="fixed inset-0 flex items-center justify-center bg-overlay z-modal"
      :class="`${withKeyboard ? 'pb-32 md:pb-0' : ''}`"
      @click.stop
      @click.self="onClose"
    >
      <div
        class="popup-dialog"
        role="dialog"
        aria-modal="modal"
        :class="`${sizes[size]}`"
      >
        <div
          v-if="closeBtn"
          class="absolute cursor-pointer z-10"
          :class="[
            closeBtnType === 'arrow'
              ? 'w-6 h-6 top-4 mt-0.5 left-4'
              : 'w-10 h-10 top-6 right-6',
          ]"
          @click="onClose"
        >
          <svg
            v-if="closeBtnType === 'arrow'"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.44913 11.4403L10.1823 6.70718L8.76804 5.29297L1.58594 12.4751L8.76804 19.6572L10.1823 18.243L5.3796 13.4403H20.9754V11.4403H5.44913Z"
              fill="black"
            />
          </svg>
          <svg
            v-else
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="#F1F2F7" />
            <path
              d="M28.5715 12.8215L27.1785 11.4285L20 18.607L12.8215 11.4285L11.4285 12.8215L18.607 20L11.4285 27.1785L12.8215 28.5715L20 21.393L27.1785 28.5715L28.5715 27.1785L21.393 20L28.5715 12.8215Z"
              fill="#7B7A8C"
            />
          </svg>
        </div>
        <div class="popup-dialog-content desk-scrollbar">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiPopup',
  components: {},
  inject: ['popup'],
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
    closeBtnType: {
      type: String,
      default: 'circle',
    },
    withKeyboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sizes: {
        xs: '--xs',
        sm: '--sm',
        md: '--md',
        lg: '--lg',
        xl: '--xl',
        'full-page': '--full-page',
      },
    };
  },
  computed: {
    isActive() {
      this.$emit('isActivated', this.popup.state.activeName === this.name);
      return this.popup.state.activeName === this.name;
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
      this.popup.hide();
    },
  },
};
</script>

<style lang="postcss" scoped>
.popup-fade {
  &-enter,
  &-leave-to {
    @apply opacity-0;
  }

  &-enter-active {
    @apply ease-in duration-200;
  }

  &-leave-active {
    @apply ease-in duration-200 delay-75;
  }

  &-enter-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 100ms,
      transform ease-in-out 75ms 100ms;
  }

  &-leave-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 50ms,
      transform ease-in-out 100ms;
  }

  &-enter > .popup-dialog,
  &-leave-to > .popup-dialog {
    @apply opacity-0 transform scale-75;
  }
}
.popup-dialog {
  @apply mx-5 relative z-modal bg-white overflow-hidden;
  width: var(--modal-width, 100%);
  max-width: 100%;
  box-shadow:
    0px 92px 92px rgba(0, 0, 0, 0.05),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);

  &.--md {
    border-radius: 12px;
  }
  &.--lg {
    border-radius: 32px;
  }
  &.--full-page {
    @apply w-full h-full mx-0;
  }

  &-content {
    @apply overflow-y-auto;
    max-height: 90vh;
  }
}
</style>
