<template>
  <transition name="popup-fade" @after-enter="onOpen">
    <div
      v-if="isActive"
      class="UiPopup fixed inset-0 flex items-center justify-center bg-overlay"
      :class="`${fullscreen ? 'z-footer' : 'z-modal'} ${
        withKeyboard ? 'pb-32' : ''
      }`"
      @click.stop
      @click.self="onClose"
    >
      <div
        role="dialog"
        aria-modal="modal"
        class="popup-dialog z-modal bg-white dark:bg-black"
        :class="`${
          fullscreen
            ? 'w-full h-full overflow-scroll'
            : 'rounded-3xl overflow-hidden'
        } 
        `"
        :style="`${
          size === 'xs' ? 'width: 100%; margin: 0 16px;' : ''
        } margin-bottom: ${offset}px;`"
      >
        <slot name="icon"></slot>
        <slot name="title"></slot>
        <slot name="subtitle"></slot>
        <slot name="hide-description">
          <div
            class="py-6 px-10 text-lg font-bold text-center leading-tight text-black dark:text-white"
          >
            <slot name="description"></slot>
          </div>
        </slot>
        <ul class="text-center text-sm text-black dark:text-gray-400">
          <slot name="action"></slot>
        </ul>
        <div class="relative">
          <div
            v-if="closable"
            class="absolute w-6 h-6 top-3 right-3"
            @click="onClose"
          >
            <!-- <IconClose /> -->
            <IconCloseButton />
          </div>
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconCloseButton from '@shared/components/icons/IconCloseButton.vue';

export default {
  name: 'UiPopup',
  components: { IconCloseButton },
  inject: ['popup'],
  props: {
    name: {
      type: String,
      required: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    offset: {
      type: Number,
      default: 0,
    },
    withKeyboard: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActive() {
      this.$emit('isActivated', this.popup.state.activeName === this.name);
      return this.popup.state.activeName === this.name;
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose');
      this.popup.hide();
    },
    onOpen() {
      this.$emit('onOpen');
    },
  },
};
</script>

<style lang="postcss" scoped>
.popup-fade {
  &-enter,
  &-leave-to {
    @apply opacity-0;
  }

  &-enter-active {
    @apply ease-in duration-200;
  }

  &-leave-active {
    @apply ease-in duration-200 delay-75;
  }

  &-enter-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 100ms,
      transform ease-in-out 75ms 100ms;
  }

  &-leave-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 50ms,
      transform ease-in-out 100ms;
  }

  &-enter > .popup-dialog,
  &-leave-to > .popup-dialog {
    @apply opacity-0 transform scale-75;
  }
}
</style>
