<template>
  <div data-component="src/views/micromarket/view.vue">
    <MmMHeader v-if="!$isDesktop" @onMenuClick="isMenuVisible = true" />
    <MmDHeader v-if="$isDesktop" />
    <MmMMenu
      v-if="isMenuVisible"
      @onClose="isMenuVisible = false"
      @onApplyClick="() => {}"
    />
    <MmFormModal
      v-if="$route.query.form"
      @onClose="() => $router.replace({ query: { form: undefined } })"
    />
    <MmTopHero />
    <MmWhatIs />
    <MmNumbers />
    <MmRoutine />
    <MmAdvantages />
    <MmRequirements />
    <MmFormDesktop v-if="$isDesktop" />
    <MmMap />
    <MmQuestions />
    <MmFooter />
  </div>
</template>

<script>
import MmMHeader from './components/MmMHeader/MmMHeader.vue';
import MmMMenu from './components/MmMMenu/MmMMenu.vue';
import MmTopHero from './components/MmTopHero/MmTopHero.vue';
import MmWhatIs from './components/MmWhatIs/MmWhatIs.vue';
import MmNumbers from './components/MmNumbers/MmNumbers.vue';
import MmRoutine from './components/MmRoutine/MmRoutine.vue';
import MmAdvantages from './components/MmAdvantages/MmAdvantages.vue';
import MmRequirements from './components/MmRequirements/MmRequirements.vue';
import MmMap from './components/MmMap/MmMap.vue';
import MmQuestions from './components/MmQuestions/MmQuestions.vue';
import MmFooter from './components/MmFooter/MmFooter.vue';
import MmFormModal from './components/MmFormModal/MmFormModal.vue';
import MmDHeader from './components/MmDHeader/MmDHeader.vue';
import MmFormDesktop from './components/MmFormDesktop/MmFormDesktop.vue';

export default {
  components: {
    MmFormDesktop,
    MmDHeader,
    MmFormModal,
    MmFooter,
    MmQuestions,
    MmMap,
    MmRequirements,
    MmAdvantages,
    MmRoutine,
    MmMHeader,
    MmMMenu,
    MmTopHero,
    MmWhatIs,
    MmNumbers,
  },
  inject: [],
  props: {},
  data: () => ({
    isMenuVisible: false,
  }),

  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped></style>
