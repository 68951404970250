<template>
  <UiPopup
    :name="name"
    class="text-sirius-black-100"
    :offset="200"
    :size="$isDesktop ? 'md' : 'xs'"
    @onOpen="onOpen"
    @onClose="onClose"
  >
    <template #hide-description><div class="d-none"></div></template>
    <div class="px-4 pt-6 pb-4 w-full relative">
      <IconCloseButton class="absolute top-2 right-4" @click.native="onClose" />
      <p class="px-3 font-bold text-center">
        <span v-if="tellMore" v-html="$t('pleaseTellMore')" />
        <span v-else v-html="$t('leaveComment')" />
      </p>

      <UiTextArea
        ref="commentRef"
        v-model="value"
        class="mt-5 w-full"
        name="comment"
        rows="5"
        no-resize
        clearable
      />

      <div class="flex items-center justify-between">
        <UiButton
          size="lg"
          variant="gray"
          class="w-full text-lg font-bold mt-4 mr-2"
          @click.self="onCancel"
        >
          {{ tellMore ? $t('skip') : $t('cancel') }}
        </UiButton>
        <UiButton
          size="lg"
          class="w-full text-lg font-bold mt-4"
          @click.self="onClose"
        >
          {{ $t('done') }}
        </UiButton>
      </div>
    </div>
  </UiPopup>
</template>

<script>
import UiPopup from '@shared/components/ui/ui-popup/UiPopup.vue';
import UiTextArea from '@pure-ui/components/UiTextArea/UiTextArea.vue';
import UiButton from '@ui/UiButtonNew.vue';
import IconCloseButton from '../icons/IconCloseButton.vue';
import { i18n } from './i18n';

export default {
  name: 'ReviewPopup',
  components: {
    IconCloseButton,
    UiTextArea,
    UiButton,
    UiPopup,
  },
  inject: ['popup'],
  props: {
    name: {
      type: String,
      required: true,
    },
    tellMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    onOpen() {
      this.$refs.commentRef.onFocus();
    },
    onClose() {
      this.popup.hide(this.name);
      this.$emit('onClose', this.value);
    },
    onCancel() {
      this.value = '';
      this.onClose();
    },
  },
  i18n,
};
</script>
