<template>
  <div class="LikeDislike like-dislike" :class="`--${variant}`">
    <img
      :src="variant === 'white' ? WhiteDislike : GrayDislike"
      alt="dislike"
      @click="$emit('onDislike')"
    />
    <img
      :src="variant === 'white' ? WhiteLike : GrayLike"
      alt="like"
      @click="$emit('onLike')"
    />
  </div>
</template>

<script>
import WhiteLike from '../icons/white-like.jpg';
import WhiteDislike from '../icons/white-dislike.jpg';
import GrayLike from '../icons/gray-like.png';
import GrayDislike from '../icons/gray-dislike.png';

export default {
  name: 'LikeDislike',
  components: {},
  props: {
    variant: {
      type: String,
      default: 'white',
    },
  },
  data() {
    return {
      WhiteLike,
      WhiteDislike,
      GrayLike,
      GrayDislike,
    };
  },
};
</script>

<style scoped>
.like-dislike {
  @apply flex items-center justify-center;
}
.like-dislike img {
  @apply rounded-full cursor-pointer;
}

.--white.like-dislike {
  @apply space-x-2;
}
.--white.like-dislike img {
  @apply w-12 h-12;
}

.--gray.like-dislike {
  @apply space-x-4;
}
.--gray.like-dislike img {
  width: 100px;
  height: 100px;
}
</style>
