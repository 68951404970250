import { client } from '../../clients';

export default function (trackId) {
  const body = {
    client_id: import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID,
    grant_type: 'authorization_track_id',
    track_id: trackId,
  };
  const params = {
    useFingerprint: true,
    useIdempotency: true,
    headers: {
      'Content-Type': 'application/json',
    },
    paramsSerializer: v => JSON.stringify(v),
    transformRequest: v => JSON.stringify(v),
  };
  return client
    .post('/api/v2/oauth2/tokens', body, params)
    .then(({ data }) => data);
}
