export const LoggerModule = {
  namespaced: true,
  state: {
    isLoggerShown: false,
    isButtonShown: false,
  },
  getters: {
    isLoggerShown(state) {
      return state.isLoggerShown;
    },
    isButtonShown(state) {
      return state.isButtonShown;
    },
  },
  mutations: {
    OPEN_LOGGER(state) {
      state.isLoggerShown = true;
    },
    CLOSE_LOGGER(state) {
      state.isLoggerShown = false;
    },
    SET_BUTTON_VISIBILITY(state, value) {
      state.isButtonShown = value;
    },
    TOGGLE_BUTTON_VISIBILITY(state) {
      state.isButtonShown = !state.isButtonShown;
    },
  },
};
