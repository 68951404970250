<template>
  <HProductCard
    class="SamplingProduct sampling"
    :class="{ present: present }"
    :product="product"
    @onGoProduct="$emit('onGoProduct', $event)"
  >
    <template #photo>
      <SamplingPhoto
        class="sampling-photo"
        :class="{ 'mr-1': present }"
        :src="product.path"
        :price="product.price"
        :present="present"
        @click.native="$emit('onGoProduct', $event)"
      />
    </template>
    <template #right>
      <Counter> {{ product.quantity }} </Counter>
    </template>
  </HProductCard>
</template>

<script>
import HProductCard from '@pure-ui/components/Product/HProductCard/HProductCard.vue';
import SamplingPhoto from '@pure-ui/components/Sampling/SamplingPhoto/SamplingPhoto.vue';
import Counter from './components/Counter.vue';

export default {
  name: 'SamplingProduct',
  components: {
    SamplingPhoto,
    HProductCard,
    Counter,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    present: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.sampling {
  @apply bg-sirius-gray-200;
}
.present {
  @apply bg-sirius-orange-500;
}
.sampling-photo {
  @apply bg-white;
}
</style>
