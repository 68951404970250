import { client } from '../../clients';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';

/**
 * Выдача всех АБ тестов юзера
 */

// eslint-disable-next-line camelcase
export default function (isAuthorized = false, segmentId) {
  return client
    .get(`/lavka-ab/v1${isAuthorizedUrl(isAuthorized)}/get_user_group`, {
      params: {
        segmentId,
      },
    })
    .then(({ data }) => data);
}
