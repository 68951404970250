<template>
  <div class="HideCartBtn hide-cart-btn">
    <span>{{ $t('hide') }}</span>
    <UiInlineIcon name="catalog-hide-cart" />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'HideCartBtn',
  components: { UiInlineIcon },
  i18n: {
    messages: {
      ru: {
        hide: 'скрыть',
      },
      kk: {
        hide: 'жасыру',
      },
    },
  },
};
</script>

<style>
.hide-cart-btn {
  @apply flex items-center space-x-2 
    font-medium text-sirius-orange-100 
    cursor-pointer;
}
</style>
