<template>
  <div class="px-1 flex items-center cursor-pointer">
    <IconConditionsMob v-if="!$isDesktop" />

    <div v-else @mouseover="mouseOver" @mouseleave="mouseLeave">
      <IconConditionsDeskGray v-if="hovered" />
      <IconConditionsDeskBlack v-else />
    </div>
  </div>
</template>

<script>
import IconConditionsDeskBlack from '@shared/components/icons/IconConditionsDeskBlack.vue';
import IconConditionsDeskGray from '@shared/components/icons/IconConditionsDeskGray.vue';
import IconConditionsMob from '@shared/components/icons/IconConditionsMob.vue';

export default {
  name: 'CheckoutTextConditions',
  components: {
    IconConditionsDeskBlack,
    IconConditionsDeskGray,
    IconConditionsMob,
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    mouseOver() {
      this.hovered = true;
    },
    mouseLeave() {
      this.hovered = false;
    },
  },
};
</script>
