<template>
  <li>
    <div
      class="MobileCatalogItem flex items-center font-medium relative"
      @click="open = !open"
    >
      <img
        class="w-full h-full rounded-lg object-contain object-left-bottom mr-3"
        :src="icon"
        style="background: #f7f3f1; width: 40px; height: 40px"
        width="40"
        height="40"
        alt=""
      />
      {{ item.name }}
      <svg
        style="transform: rotate(var(--tw-rotate))"
        class="absolute inset-y-0 m-auto right-0 w-5"
        :class="{ 'rotate-180': open }"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.25 8.125L10 14.375L3.75 8.125"
          stroke="#A4A2B7"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <UiCollapseAnimation
      :open="open && item.categories && item.categories.length"
    >
      <ul>
        <li
          v-for="category in item.categories"
          :key="category.name"
          class="pl-13 mt-5 flex items-center"
          style="min-height: 40px"
          @click="$emit('onNavigate', category)"
        >
          {{ category.name }}
        </li>
      </ul>
    </UiCollapseAnimation>
  </li>
</template>

<script>
import UiCollapseAnimation from '@pure-ui/components/UiCollapseAnimation/UiCollapseAnimation.vue';
export default {
  components: {
    UiCollapseAnimation,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    open: false,
  }),
  computed: {
    icon() {
      return (
        this.item?.icon || new URL('@img/no-photo.png', import.meta.url).href
      );
    },
  },
};
</script>
