<template>
  <div class="StoriesCube" v-on="{ touchstart, touchmove, touchend }">
    <div
      class="StoriesCube__viewport"
      :style="{ transform: `rotate3d(0, 1, 0, calc(${angle}deg * -1))` }"
    >
      <StoriesGroup
        v-if="prevGroup"
        :key="`${prevGroup.code}`"
        class="StoriesCube__face StoriesCube__face_prev"
        v-bind="{
          ...$attrs,
          group: prevGroup,
          currentSlideIdx: 0,
          progress: 0,
        }"
      />
      <StoriesGroup
        ref="storyGroup"
        :key="`${$attrs.stories[localCurrentGroupIdx].code}`"
        class="StoriesCube__face"
        v-bind="{
          ...$attrs,
          group: $attrs.stories[localCurrentGroupIdx],
          currentSlideIdx: localCurrentSlideIdx,
        }"
        v-on="{
          ...$listeners,
          onGoToPrevGroup,
          onGoToNextGroup,
        }"
      />
      <StoriesGroup
        v-if="nextGroup"
        :key="`${nextGroup.code}`"
        class="StoriesCube__face StoriesCube__face_next"
        v-bind="{
          ...$attrs,
          group: nextGroup,
          currentSlideIdx: 0,
          progress: 0,
        }"
      />
    </div>
  </div>
</template>

<script>
import StoriesGroup from './components/StoriesGroup/StoriesGroup.vue';

export default {
  name: 'StoriesCube',
  components: { StoriesGroup },
  inheritAttrs: false,
  data: () => ({
    localCurrentGroupIdx: 0,
    localCurrentSlideIdx: 0,
    angle: 0,
  }),
  computed: {
    currentGroup() {
      return this.$attrs.stories[this.localCurrentGroupIdx];
    },
    prevGroup() {
      if (this.$isDesktop) return null;
      return this.$attrs.stories[this.localCurrentGroupIdx - 1] || null;
    },
    nextGroup() {
      if (this.$isDesktop) return null;
      return this.$attrs.stories[this.localCurrentGroupIdx + 1] || null;
    },
    animationDurationMs() {
      return this.$isDesktop ? 0 : 300;
    },
  },
  watch: {
    '$attrs.currentGroupIdx': {
      immediate: true,
      handler(v) {
        this.localCurrentGroupIdx = v;
      },
    },
    '$attrs.currentSlideIdx': {
      immediate: true,
      handler(v) {
        this.localCurrentSlideIdx = v;
      },
    },
  },
  mounted() {
    this.localCurrentGroudIdx = this.$attrs.currentGroupIdx;
  },
  methods: {
    touchstart(e) {
      e.target.startX = e.changedTouches[0].clientX;
      e.target.startY = e.changedTouches[0].clientY;
    },
    touchmove(e) {
      const x = e.changedTouches[0].clientX;
      const y = e.changedTouches[0].clientY;
      let deltaX = e.target.startX - x;
      const deltaY = e.target.startY - y;

      if (e.target.isYMovementStarted) {
        return;
      }

      if (Math.abs(deltaY) > 10 && !e.target.isXMovementStarted) {
        e.target.isYMovementStarted = true;
        return;
      }

      if (Math.abs(deltaX) < 10 && !e.target.isXMovementStarted) {
        return;
      }

      e.target.isXMovementStarted = true;
      deltaX = deltaX / 4;

      if (!this.prevGroup) {
        deltaX = Math.max(-25, deltaX);
      }
      if (!this.nextGroup) {
        deltaX = Math.min(25, deltaX);
      }
      this.angle = deltaX;
    },
    touchend(e) {
      const x = e.changedTouches[0].clientX;
      e.target.isXMovementStarted = false;
      e.target.isYMovementStarted = false;

      if (Math.abs(e.target.startX - x) < 5) {
        return;
      }
      e.target.startX = null;
      const $viewport = this.$el.querySelector('.StoriesCube__viewport');
      $viewport.style.transition = 'transform .3s';
      if (this.angle > 45) this.angle = 90;
      else if (this.angle < -45) this.angle = -90;
      else this.angle = 0;
      setTimeout(() => {
        $viewport.style.transition = '';
        if ([-90, 90].includes(this.angle)) {
          const next =
            this.angle === 90
              ? this.localCurrentGroupIdx + 1
              : this.localCurrentGroupIdx - 1;
          this.$emit('onSetCurrentGroupIdx', next);
        }
        this.angle = 0;
      }, this.animationDurationMs);
    },
    rotateTo({ direction = 'next' }) {
      return new Promise(resolve => {
        const $viewport = this.$el.querySelector('.StoriesCube__viewport');
        $viewport.style.transition = 'transform .3s';

        this.angle = direction === 'next' ? 90 : -90;

        setTimeout(() => {
          $viewport.style.transition = '';
          this.angle = 0;
          resolve();
        }, this.animationDurationMs);
      });
    },
    onGoToPrevGroup() {
      if (this.$attrs.prevGroup) {
        this.rotateTo({ direction: 'prev' }).then(() => {
          this.$emit('onSetCurrentGroupIdx', this.$attrs.currentGroupIdx - 1);
          this.localCurrentGroupIdx = this.$attrs.currentGroupIdx - 1;
        });
      } else {
        this.$emit('onClose', 'end');
      }
    },
    onGoToNextGroup() {
      if (this.$attrs.nextGroup) {
        this.rotateTo({ direction: 'next' }).then(() => {
          this.$emit('onSetCurrentGroupIdx', this.$attrs.currentGroupIdx + 1);
          this.localCurrentGroupIdx = this.$attrs.currentGroupIdx + 1;
        });
      } else {
        this.$emit('onClose', 'end');
      }
    },
  },
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.StoriesCube {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  perspective: 800px;
}

.StoriesCube__viewport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform-origin: 50% 50% -50vw;
}

.StoriesCube__face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(36, 36, 36, 1);
}

.StoriesCube__face_prev {
  left: -100%;
  transform: rotate3d(0, 1, 0, -90deg);
  transform-origin: right;
}

.StoriesCube__face_next {
  left: 100%;
  transform: rotate3d(0, 1, 0, 90deg);
  transform-origin: left;
}

@media screen and (min-width: 768px) {
  .StoriesCube__viewport {
    transform: none !important;
  }
}
</style>
