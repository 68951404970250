<template>
  <section class="ContestDone">
    <div class="ContestDone__container">
      <div class="ContestDone__inner">
        <NyPageSubtitle class="ContestDone__title">{{
          $t('title')
        }}</NyPageSubtitle>
        <template v-if="$i18n.locale === 'kk'">
          <div class="ContestDone__p">
            Ұтыс ойны 11 қаңтар 23:00-де аяқталды.
          </div>
          <div class="ContestDone__p">
            Нәтиже конкурс аяқталғаннан кейін 5 жұмыс күнінің ішінде Choco
            қосымшасындағы «Рядом — Азық-түлік жеткізу» бөлімінде, Choco Рядом
            сайтында және Instagram әлеуметтік желісінде (@ryadom.kz)
            жарияланады
          </div>
        </template>
        <template v-if="$i18n.locale === 'ru'">
          <div class="ContestDone__p">
            Розыгрыш завершился 11&nbsp;января в&nbsp;23:00
          </div>
          <div class="ContestDone__p">
            Результаты будут объявлены в&nbsp;течение 5&nbsp;рабочих дней
            со&nbsp;дня окончания конкурса в&nbsp;приложении Choco,
            в&nbsp;разделе «Рядом&nbsp;— Доставка продуктов», на&nbsp;сайте
            Choco Рядом и&nbsp;в&nbsp;социальной сети Instagram @ryadom.kz
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import NyPageSubtitle from '@/views/ny-2023-contest/components/NyPageSubtitle/NyPageSubtitle.vue';

export default {
  name: 'ContestDone',
  components: {
    NyPageSubtitle,
  },
  i18n: {
    messages: {
      kk: {
        title: 'Ұтыс ойнының нәтижелері',
      },
      ru: {
        title: 'Результаты розыгрыша',
      },
    },
  },
};
</script>

<style scoped>
.ContestDone {
  color: #242424;
}

.ContestDone__container {
  width: calc(100% - 32px);
  max-width: 988px;
  margin: auto;
}

.ContestDone__inner {
  max-width: 488px;
}

.ContestDone__title {
  padding-bottom: 8px;
}

.ContestDone__p {
  padding-top: 16px;
}

@media (min-width: 768px) {
  .ContestDone {
    padding-top: 64px;
  }

  .ContestDone__p {
    font-size: 18px;
    line-height: 24px;
  }

  .ContestDone__title {
    padding-bottom: 16px;
  }
}
</style>
