import { rFetchWarehouses } from '@shared/services/api/apis';
import { rSearchAddress } from '@shared/services/api/apis';
import { loadYmap } from 'vue-yandex-maps';
import { stringify } from 'qs';

import {
  YMAPS_SETTINGS,
  QUERY_SEARCH_SETTINGS,
  INITIAL_COORDS,
} from './constants';
import { getPolygonsFromResponse } from './polygons';
import { defineGeolocation, geocodeSinglePosition } from './utils';
import {
  filterGeoObjectsByDelivery,
  sortGeoObjectsByDelivery,
  validateGeoObjectsCollection,
} from './utils/validate-geo-objects-collection.js';
import { GeoService } from '@services/geo';

export * from './constants';

/**
 * Лоакальная переменная для yandexAPI
 *
 * @type {Object|undefined}
 */
let ymaps;
/**
 * сохраняем значение window.ymaps в переменную ymaps
 */
function setYmaps() {
  if (window.ymaps) ymaps = window.ymaps;
  else ymaps = undefined;
}

/**
 * Поиск совпадающих адресов из сохраненных
 * @param {Address[]} searched адреса с поиска yandexapi
 * @param {Address[]} saved сохраненые адреса пользователя
 * @returns
 */
function searchAddressFromSaved(searched, saved) {
  const savedAddresses = saved;
  const result = [];
  if (!savedAddresses || !savedAddresses.length > 0) return result;
  if (!searched || !searched.length > 0) return result;

  savedAddresses.forEach(savedItem => {
    searched.forEach(searchItem => {
      if (
        searchItem.GeoObject &&
        searchItem.GeoObject.name === savedItem.street
      ) {
        result.push(savedItem);
      }
    });
  });
  return result;
}
/**
 * Поиск совпадающих адресов из сохраненных
 * @param {String} query ввод в пойске
 * @param {Address[]} addresses сохраненые адреса пользователя
 * @returns
 */
function searchAddressFromSavedByQuery(query, addresses) {
  if (!query) return [];
  if (!addresses || !addresses.length > 0) return [];

  let filtered = addresses.filter(address => {
    let { street, name } = address;
    street = (street ?? '').toLowerCase();
    name = (name ?? '').toLowerCase();
    query = (query ?? '').toLowerCase();
    return street.includes(query) || name.includes(query);
  });
  return filtered;
}

/**
 * Валидирует принятый гео-объект проверяет на a
 * @param {Object} geoObject объект geoObject c yandexAPI
 * @returns {Promise<{hasStreet: boolean; hasBuilding: boolean; addresses: address[];address:address}
 */
function validateGeoObject(geoObject) {
  return validateGeoObjectsCollection(geoObject, INITIAL_COORDS);
}

function addToObject(obj, obj2) {
  Object.assign(obj, { ...obj2 });
}

function extractGeoObject(collection) {
  if (!collection) return null;
  const featureMember = collection.featureMember[0];
  const GeoObject = featureMember?.GeoObject || null;
  return GeoObject;
}
function parseGeoObject(GeoObject) {
  let streetName = '';
  let cityName = '';
  let building = '';

  if (GeoObject.metaDataProperty?.GeocoderMetaData?.Address?.Components) {
    GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.forEach(
      item => {
        if (
          item.kind === 'street' ||
          item.kind === 'district' ||
          item.kind === 'locality'
        )
          streetName = item.name;
        if (item.kind === 'house') building = item.name;
        if (item.kind === 'locality') cityName = item.name;
      }
    );
  }

  return {
    street: GeoObject.name,
    lat: GeoObject.Point.pos.split(' ')[1],
    long: GeoObject.Point.pos.split(' ')[0],
    street_name: streetName, // нужен для yandex taxi
    cityName: cityName,
    building,
  };
}
function getGeoObjectCollectionByCoords(coords) {
  return geocodeSinglePosition(ymaps, coords);
}

function defineAddress(coords) {
  const data = { coords };

  return getGeoObjectCollectionByCoords(coords).then(collection => {
    addToObject(data, { collection });
    return data;
  });
}

async function defineGeoLocation() {
  const data = {};
  let status = await GeoService.requestGeoPermission();

  // Если нет доступа к гео этот промис может очень долго не завершаться
  // Принудительно завершаем его через 3с
  const definePromise = defineGeolocation(ymaps)
    .then(coords => {
      addToObject(data, { coords });
      return getGeoObjectCollectionByCoords(coords);
    })
    .then(collection => {
      addToObject(data, { collection });
      return data;
    });

  const timeoutPromise = new Promise((_resolve, reject) =>
    setTimeout(reject, 3000)
  );

  let promises = [definePromise];
  if (status !== 'notDetermined') {
    promises.push(timeoutPromise);
  }
  return Promise.race(promises);
}

/**
 * Выдает промис с результатами адресов от yandex api по названию адреса
 * @param {String} query принимает название адреса
 * @returns {Object} featureMember
 */
function findAddressByQuery(query) {
  const payload = stringify({
    ...QUERY_SEARCH_SETTINGS,
    geocode: query,
  });

  return rSearchAddress(payload).then(({ response }) => {
    console.log('findAddressByQuery', payload, response);
    return validateGeoObjectsCollection(
      response.GeoObjectCollection,
      INITIAL_COORDS
    ).then(res => {
      console.log('findAddressByQuery', res);
      let filteredAddresses = filterGeoObjectsByDelivery(res.addresses);
      let isNoDeliveryToAddress =
        res.address &&
        res.address.hasBuilding &&
        res.address.hasStreet &&
        filteredAddresses.length === 0 &&
        res.addresses.length > 0;
      sortGeoObjectsByDelivery(filteredAddresses);
      return { ...res, isNoDeliveryToAddress, addresses: filteredAddresses };
    });
  });
}

/**
 *  Получает склады с бека и возвращает полигоны зон доставки
 * @returns {Number[][]} возвращает массив кординат полигонов массивов складов
 */
function fetchDeliveryZones() {
  return rFetchWarehouses().then(({ data }) => getPolygonsFromResponse(data));
}

/**
 * Промис для инициализаций api яндекса ymaps
 * Записывает апи в window.ymaps
 * Позволяет вне Vue библиотеки пользоваться фичи яндекс карт (yandexAPI)
 * @returns {String} Возвращает стейт loaded|failed
 */
function initMap() {
  return new Promise(resolve => {
    loadYmap(YMAPS_SETTINGS)
      .then(() => {
        setYmaps();
        resolve(window.ymaps !== undefined ? 'loaded' : 'failed');
      })
      .catch(() => resolve('failed'));
  });
}

export const MapService = {
  searchAddressFromSavedByQuery,
  searchAddressFromSaved,
  fetchDeliveryZones,
  findAddressByQuery,
  defineGeoLocation,
  validateGeoObject,
  extractGeoObject,
  parseGeoObject,
  defineAddress,
  initMap,
  initYmaps: initMap,
};
