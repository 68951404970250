<template>
  <div data-view="src/views/remove-choco-user/view.vue">
    <RemoveAccountPageVue
      v-bind="{ state }"
      v-on="{ onAuth, onRemove, onSubmitCode, onRefresh }"
    />
  </div>
</template>

<script>
import RemoveAccountPageVue from '@/pure-ui/components/RemoveAccountPage/RemoveAccountPage.vue';
import { requestSMScode } from './lib/requestSMScode/requestSMScode';
import { confirmRemovingWithCode } from './lib/confirmRemovingWithCode/confirmRemovingWithCode';
import { AuthService } from '@/shared/services/auth';

export default {
  name: 'RemoveChocoUserView',
  components: { RemoveAccountPageVue },
  data: () => ({
    state: {
      name: 'unauthorized',
    },
    removeRequestId: '',
  }),
  computed: {},
  mounted() {
    if (this.$store.getters.isAuthorized) {
      this.state = { name: 'authorized' };
    }
  },
  methods: {
    onAuth() {
      this.state = { name: 'loading' };
      AuthService.authorize();
    },
    onRemove() {
      this.state = { name: 'loading' };
      requestSMScode(this.accessToken)
        .then(result => {
          if (result.status === 'ok') {
            this.removeRequestId = result.removeRequestId;
            this.state = { name: 'codeHasBeenSent' };
          }
          if (result.status === 'too_many_requests') {
            const errorMessage = `Превышен лимит, повторите через ${result.remaining_at} сек.`;
            this.state = { name: 'error', errorMessage };
          }
        })
        .catch(this.handleError);
    },
    onSubmitCode(codeFromSMS) {
      this.state = { name: 'loading' };
      confirmRemovingWithCode(
        codeFromSMS,
        this.removeRequestId,
        this.accessToken
      )
        .then(result => {
          if (result.status === 'ok') {
            this.state = { name: 'success' };
          }
          if (result.status === 'wrong_code') {
            this.state = {
              name: 'codeHasBeenSent',
              errorMessage: 'Неправильный код',
            };
          }
        })
        .catch(this.handleError);
    },
    onRefresh() {
      document.location.reload();
    },
    handleError(err) {
      this.state = { name: 'error', errorMessage: err.message };
      throw err;
    },
  },
};
</script>
