import { getNotificationStatus, requestNotificationStatus } from 'choco-app';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { createStorage } from '@shared/services/storage';
import { RahmetApp } from '@shared/RahmetApp';
import { monthsBetweenDates } from '@/utils/formatters/date';
/**
 * Узнаем Статус уведомлений приложения Choco
 *
 * denied - не разрешен
 * granted - разрешен
 * notDetermined - не определен
 * notAvailable - бывает тогда когда у юзера не нашелся этот метод. То есть старый билд приложения
 * @returns {Promise<String>} denied|granted|notDetermined|notAvailable
 */
function getStatus() {
  return new Promise(resolve => {
    getNotificationStatus()
      .then(res => resolve(res))
      .catch(() => resolve('notAvailable'));
  });
}
/**
 * Узнаем что не положительный результат статуса уведомления
 * @returns {Promise<Boolean>}
 */
function isNotificationsDisabled() {
  return getStatus().then(res => res === 'denied' || res === 'notDetermined');
}

/**
 * Перенаправить в настройки приложения что б включить уведомления
 * @returns {Promise<String>}
 */
function requestEnabling() {
  return requestNotificationStatus().catch(err => console.error(err));
}

function resetPeriodOfReminder() {
  const storage = createStorage(window.localStorage);
  storage.set('notifications::remindedDate', new Date());
}

/**
 *  Проверяем период когда последний раз видел модалку по уведомлениям
 * если больше 2 месяцев то ему снова напоминаем
 * @returns {Boolean}
 */
function isRemindPeriodExpired() {
  const storage = createStorage(window.localStorage);
  let remindedDate = storage.get('notifications::remindedDate', null);
  if (!remindedDate) return true;

  const today = new Date();
  remindedDate = new Date(remindedDate);
  const monthDiff = monthsBetweenDates(today, remindedDate);
  return monthDiff <= -3;
}

/**
 * Возвращяет разрешено ли показывать
 * модалку с запросом на разрешение уведомлений или нет
 *
 * Если нет то показываем модалку SuccessPaymentModal
 *
 * Если да то показываем модалку NotificationReminderModal
 * @returns {Promise<Boolean>}
 */
async function canShowModal() {
  if (!RahmetApp.isWebView()) return false;

  const isDisabledNotifications = await isNotificationsDisabled();
  const isNotReminded = isRemindPeriodExpired();
  return isDisabledNotifications && isNotReminded;
}

/**
 * Узнаем статус уведомлений приложения
 * И кидаем в Амлитуду статус
 */
function init() {
  getStatus().then(res =>
    Analitycs.logEvent(EVENTS.ON_NOTIFICATIONS_INIT, { status: res })
  );
}

export const NotificationService = {
  resetPeriodOfReminder,
  requestEnabling,
  canShowModal,
  init,
};
