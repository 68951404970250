/**
 * Для верефицирование адреса что оно складской и он только для сотрудников склада
 * @param {Object} state
 * @param {Object} rootGetters
 * @returns Boolean -- возвращяет это сотрудник или нет
 */
export function isEmployee(whAddress, userAddress) {
  if (!whAddress || !userAddress) return false;
  return (
    whAddress.address === userAddress.street &&
    userAddress.flat_number === '666'
  );
}

export function areDatesSameDay(date1, date2) {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    return false;
  }

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

/**
 * Is date past than nowDate
 * @param {Date} date
 * @param {Date} nowDate
 * @returns
 */
export function isDatePast(date, nowDate) {
  if (!(date instanceof Date) || !(nowDate instanceof Date)) {
    return true;
  }
  return date < nowDate;
}

/**
 *
 * @param {String | Date} start YYYY-MM-DD hh:mm
 * @param {String | Date} end YYYY-MM-DD hh:mm
 * @param {String | Date} today YYYY-MM-DD hh:mm
 * @returns
 */
export function isTimeBetween(start, end, today = new Date()) {
  start = new Date(start);
  end = new Date(end);
  today = new Date(today);
  if (
    !(start instanceof Date) ||
    !(end instanceof Date) ||
    !(today instanceof Date)
  ) {
    return false;
  }

  return start <= today && today <= end;
}
