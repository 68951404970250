import { rUploadFile } from '@shared/services/api/requestsV2/file/file-upload';
import { Logger } from '@shared/services/logger';
import Compressor from 'compressorjs';

const IMAGE_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

const filePathToBlob = async path => {
  const blob = await fetch(path).then(res => res.blob());
  return blob;
};

export const compressImage = file => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-new
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 4000,
      maxHeight: 3500,
      // resize: 'contain',
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

function uploadFile(file, base64) {
  const payload = {
    'image[extension]': file.type.split('/')[1],
    'image[filename]': file.name,
    'image[type]': file.type,
    'image[body]': base64.split(',')[1],
    'image[size][width]': '900',
    'image[size][height]': '900',
    folder: 'lavka/products',
  };
  return rUploadFile(payload);
}

/**
 * Принимает File с инпута сжимает и загружает в базу и возвращяет путь к картинке
 * @param {File} file
 * @returns {Promise<String>} возвращяет путь к картинке или же Error
 */
function uploadImage(file) {
  Logger.event('uploadImage file', file.type);
  if (!IMAGE_FORMATS.includes(file.type)) {
    return Promise.reject(Error('format not supported'));
  }

  return compressImage(file)
    .then(res => {
      Logger.event('uploadImage compressImage', res);
      return fileToBase64(res);
    })
    .then(res => {
      Logger.event('uploadImage fileToBase64', res);
      return uploadFile(file, res);
    })
    .then(res => {
      Logger.event('uploadImage uploadFile', res);
      if (!res || !res.data) throw Error('image not uploaded');
      return res.data;
    });
}

export const FileService = {
  uploadImage,
  fileToBase64,
  filePathToBlob,
};
