<template>
  <ToolBar>
    {{ $t('myPromocodes') }}
  </ToolBar>
</template>

<script>
import ToolBar from '@shared/components/layout/ToolBar.vue';

export default {
  name: 'PromocodesView',
  components: {
    ToolBar,
  },
  i18n: {
    messages: {
      ru: {
        myPromocodes: 'Мои промокоды',
      },
      kk: {
        myPromocodes: 'Менің промокодтарым',
      },
    },
  },
};
</script>
