<template>
  <button
    class="ui-button"
    :class="` rounded
      ${sizes[size]}
      ${variants[variant]}
      ${useDefaultPadding ? sizes_padding[size] : ''}
      rounded-${rounded}
      `"
    v-on="$listeners"
  >
    <slot></slot>
  </button>
</template>

<script>
const SIZES = {
  sm: '--sm',
  md: '--md',
  lg: '--lg',
  xl: '--xl',
  '3xl': '--3xl',
  20: '--20',
  full: '--full',
  custom: '--custom',
};

const SIZES_PADDING = {
  sm: 'px-2 py-1',
  md: 'px-4 py-2',
  lg: 'px-6 py-3',
  20: 'p-3',
  'no-padding': '',
};

const VARIANTS = {
  primary: '--primary',
  'primary-light': '--primary-light',
  'tag-primary': '--tag-primary',
  neutral: '--neutral',
  outline: '--outline',
  'red-outline': '--red-outline',
  'orange-new': '--orange-new',
  'orange-new-light': '--orange-new-light',
  gray: '--gray',
  transparent: '--transparent',
  white: '--white',
};

export default {
  name: 'UiButton',
  //
  props: {
    size: {
      type: String,
      default: 'md',
      validator: v => SIZES.hasOwnProperty(v),
    },
    variant: {
      type: String,
      default: 'primary',
      validator: v => VARIANTS.hasOwnProperty(v),
    },
    useDefaultPadding: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sizes: SIZES,
      variants: VARIANTS,
      sizes_padding: SIZES_PADDING,
    };
  },
};
</script>

<style lang="postcss" scoped>
.ui-button {
  @apply inline-flex
  items-center
  justify-center
  transition ease-in duration-75;

  &.--sm {
    height: 30px;
  }

  &.--md {
    height: 44px;
  }

  &.--lg {
    @apply h-12 rounded-xl;
  }

  &.--xl {
    @apply h-12 rounded-2xl;
  }

  &.--20 {
    @apply h-12;
    border-radius: 20px;
  }

  &.--3xl {
    @apply h-12 rounded-3xl;
  }

  &.--full {
    @apply rounded-full;
  }

  &:focus {
    @apply outline-none;
  }

  &.--primary {
    @apply bg-orange-500 text-white;

    &:active {
      @apply bg-orange-700;
    }

    &:disabled {
      @apply bg-gray-700;
    }
  }

  &.--primary-light {
    @apply bg-orange-200 text-orange-400 dark:bg-orange-400 dark:text-white;

    &:hover {
      @apply bg-orange-400 text-white dark:bg-orange-700;
    }

    &:active {
      @apply bg-orange-400 text-white dark:bg-orange-700;
    }

    &:disabled {
      @apply bg-gray-700 text-white;
    }
  }

  &.--tag-primary {
    @apply bg-orange-500 text-white;

    &:hover {
      @apply bg-orange-400;
    }

    &:active {
      @apply bg-orange-600;
    }

    &:disabled {
      @apply bg-gray-700;
    }
  }

  &.--neutral {
    @apply bg-gray-400 text-gray-700 dark:bg-black;

    &:active {
      @apply bg-gray-500 dark:bg-night;
    }

    &:disabled {
      @appy bg-gray-700;
    }
  }

  &.--outline {
    @apply text-orange-600
    border border-orange-600;

    &:active {
      @apply bg-orange-600
      text-white;
    }

    &:disabled {
      @apply border-gray-700
      text-gray-700
      bg-white;
    }
  }

  &.--red-outline {
    @apply text-red-500
    border border-red-500;

    &:active {
      @apply bg-red-500
      text-white;
    }

    &:disabled {
      @apply border-gray-700
      text-gray-700
      bg-white;
    }
  }

  &.--orange-new {
    @apply bg-orange-new-500 text-white dark:text-night;

    &:hover {
      @apply bg-orange-new-500;
    }

    &:active {
      @apply bg-orange-new-700;
    }

    &:disabled {
      @apply bg-orange-new-300 text-orange-new-200 dark:text-night;
    }
  }

  &.--orange-new-light {
    @apply bg-orange-new-100 text-orange-new-500;

    /* &:hover {
      @apply bg-orange-new-500;
    }

    &:active {
      @apply bg-orange-new-700;
    }

    &:disabled {
      @apply bg-gray-700;
    } */
  }

  &.--gray {
    @apply bg-gray-new-100 text-black-new-400;

    &--full {
      @apply w-full;
    }

    &:hover {
      @apply bg-gray-new-400;
    }

    &:active {
      @apply bg-gray-new-300;
    }

    &:disabled {
      @apply bg-gray-new-100 text-gray-new-600;
    }
  }

  &.--transparent {
    @apply bg-transparent;
  }

  &.--white {
    @apply bg-white;
  }
}
</style>
