<template>
  <div class="AddPromocode">
    <UiButtonNew
      class="AddPromocode__btn font-bold"
      size="lg"
      @click="open = true"
    >
      {{ $t('addNewPromocode') }}
    </UiButtonNew>
    <AddPromocodeModal
      :open="open"
      @onClose="open = false"
      @onSuccess="onSuccess"
    />
  </div>
</template>

<script>
import UiButtonNew from '@ui/UiButtonNew.vue';
import AddPromocodeModal from './AddPromocodeModal.vue';

export default {
  name: 'AddPromocode',
  components: { UiButtonNew, AddPromocodeModal },
  inject: ['toast'],
  props: {},
  data: () => ({
    open: false,
  }),

  methods: {
    onSuccess() {
      this.open = false;
      this.toast.show(this.$t('promocodeHasBeenAdded'), 'success');
      this.$emit('reloadPromocodesList');
    },
  },
  i18n: {
    messages: {
      kk: {
        addNewPromocode: 'Жаңа промокод қосу',
        promocodeHasBeenAdded: 'Промокод қосылды',
      },
      ru: {
        addNewPromocode: 'Добавить новый промокод',
        promocodeHasBeenAdded: 'Промокод добавлен',
      },
    },
  },
};
</script>

<style scoped>
.AddPromocode {
  position: fixed;
  z-index: 1;
  background: #fff;
  border-radius: 24px 24px 0 0;
  padding: 12px 16px 20px;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow:
    0px 32px 56px 0px rgba(0, 0, 0, 0.06),
    0px 3px 24px 0px rgba(0, 0, 0, 0.02),
    0px -3px 16px 0px rgba(0, 0, 0, 0.04),
    0px 5.79369px 12px 0px rgba(0, 0, 0, 0.01),
    0px 2.40368px 2px 0px rgba(0, 0, 0, 0.01),
    0px 0.70517px 2.54588px 0px rgba(0, 0, 0, 0.01);
}

.AddPromocode__btn {
  width: 100%;
}
</style>
