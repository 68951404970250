<template>
  <div class="HeaderBase header-base">
    <div class="header-base-left">
      <slot name="left"><IconBack @click.native="$emit('onBack')" /> </slot>
    </div>
    <div class="header-base-center" :class="{ 'truncate brake-all': truncate }">
      <slot> </slot>
    </div>
    <div class="header-base-right">
      <slot name="right"> </slot>
    </div>
  </div>
</template>

<script>
import IconBack from './IconBack.vue';
export default {
  components: { IconBack },
  props: {
    truncate: Boolean,
  },
};
</script>

<style scoped>
.header-base {
  @apply flex items-center justify-between;
}
.header-base-center {
  @apply text-xl font-bold leading-6;
}
.header-base-left {
  @apply min-w-12;
}
.header-base-right {
  @apply min-w-12;
}
</style>
