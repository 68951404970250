<template>
  <div class="flex items-center cursor-pointer" v-on="$listeners">
    <p class="font-medium">
      {{ $t('filters') }}
    </p>
    <div
      class="ml-3 w-10 h-10 flex items-center justify-center bg-sirius-gray-200 rounded-xl"
    >
      <IconFilter class="w-6 h-6" />
    </div>
  </div>
</template>

<script>
import IconFilter from '@shared/components/icons/IconFilter.vue';

export default {
  name: 'DesktopFilterButton',
  components: {
    IconFilter,
  },
  i18n: {
    messages: {
      ru: {
        filters: 'Фильтры',
      },
      kk: {
        filters: 'Сүзгілер',
      },
    },
  },
};
</script>
