import { DevConfigs } from '@/utils/dev-config';
import { CASHBACK_CONDITIONS } from '@services/cashback/constants';

export const CashbacksModule = {
  namespaced: true,
  state: {
    hasCashbacksProgram: false,
    hasCashbacksLinks: false,
  },
  getters: {
    hasCashbacksProgram(state) {
      if (DevConfigs.isDev) return true;
      return state.hasCashbacksProgram;
    },
    hasCashbacksLinks(state) {
      return state.hasCashbacksLinks;
    },
    activeCashbacksCondition(state, getters, rootState, rootGetters) {
      if (!getters.hasCashbacksProgram) return null;
      const ordersCount = rootGetters['user/userOrdersCount'];
      const conditions = [...CASHBACK_CONDITIONS];
      return conditions[ordersCount % 5];
    },
  },
  mutations: {
    SET_CASHBACKS_PROGRAM(state, value) {
      state.hasCashbacksProgram = value;
    },
    SET_CASHBACKS_LINKS(state, value) {
      state.hasCashbacksLinks = value;
    },
  },
};
