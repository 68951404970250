import { addEndpointTimeEntry } from '@/utils/performance/endpoint-time-entries';

export function installAxiosMeasureTime(client) {
  client.interceptors.request.use(config => {
    if (window.performance && window.performance.now) {
      config.startTime = window.performance.now();
    }
    return config;
  });

  client.interceptors.response.use(response => {
    if (window.performance && window.performance.now) {
      const { config: c } = response;
      c.endTime = window.performance.now();
      c.duration = c.endTime - c.startTime;
      addEndpointTimeEntry(`${parseInt(c.duration)}:${c.url}`);
    }
    return response;
  });
}
