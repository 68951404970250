<template>
  <div class="SearchMessage">
    <component :is="icon" />
    <div class="SearchMessage__text">
      {{ $t(type) }}
      <span v-if="type === 'nothingFound'" @click="$emit('showOnMap')">
        {{ $t('showOnMap') }}
      </span>
    </div>
  </div>
</template>

<script>
import IconPin from './IconPin.vue';
import IconLoupe from './IconLoupe.vue';
export default {
  components: { IconPin, IconLoupe },
  props: {
    type: {
      type: String,
      default: 'isNoDeliveryToAddress', // 'nothingFound', 'notFullAddress'
    },
  },
  computed: {
    icon() {
      if (this.type === 'isNoDeliveryToAddress') return IconPin;
      if (this.type === 'nothingFound') return IconLoupe;
      if (this.type === 'notFullAddress') return IconLoupe;
      return IconLoupe;
    },
  },
  i18n: {
    messages: {
      ru: {
        notFullAddress: 'Введите полное название улицы и здания/дома',
        nothingFound: 'Не можем найти такой адрес.',
        showOnMap: 'Попробуйте найти его на карте',
        isNoDeliveryToAddress: 'Сюда пока не доставляем. Введите другой адрес',
      },
      kk: {
        notFullAddress: 'Көше атын және үй/ғимаратты толық жазыңыз',
        nothingFound: 'Бұндай мекенжайды таба алмадық.',
        showOnMap: 'Картада іздеп көріңіз',
        isNoDeliveryToAddress:
          'Бұл жерге әзір жеткізбейміз. Басқа мекенжай енгізіңіз',
      },
    },
  },
};
</script>

<style scoped>
.SearchMessage {
  @apply w-full flex space-x-2 
  font-medium leading-5 text-sirius-gray-900
   md:items-center;
}
.SearchMessage__text > span {
  @apply text-sirius-orange-100 md:text-sirius-gray-900;
}
</style>
