let time = 0;

export function getPageLoadTime() {
  let v = time;
  if (window.performance && window.performance.now) {
    v = performance.now() - v;
  }
  return v;
}

export function markPageLoadStart() {
  if (window.performance && window.performance.now) {
    time = window.performance.now();
  }
}
