<template>
  <div
    class="DeliveryContextDesk relative"
    :class="{ 'w-full': !!houseAddress }"
  >
    <!-- если у юзера есть сохранённый адрес -->
    <!-- v-else-if="houseAddress" -->
    <div class="delivery-context" @click="onDeliveryClick">
      <IconLocation class="w-6 h-6 mt-1" />
      <div class="ml-2">
        <div class="delivery-context-info">
          <p class="delivery-context-time">
            {{ $t('weWillDeliverFor', { time: expectedDeliveryTime }) }}
          </p>
          <ExtendedDeliveryTooltip
            v-if="isDistant"
            class="delivery-context-distant"
            @click.native.stop
          />
        </div>
        <div class="delivery-context-address">
          {{ !address ? $t('provideDeliveryAddress') : houseAddress }}
        </div>
      </div>
    </div>

    <UiToolPopup
      name="address-list-toolpopup"
      style="
        --modal-min-width: 388px;
        --modal-width: 100%;
        --modal-position: absolute;
      "
      size="lg"
    >
      <div class="pt-5 pl-4 pb-8" style="height: 100%; max-height: 368px">
        <div class="">
          <UiButton
            variant="light-gray"
            size="md"
            class="w-full mb-1 px-2 font-bold text-lg"
            :use-default-padding="false"
            @click="addAddress"
          >
            <div class="mr-auto flex items-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11 13V21H13V13H21V11H13V3H11V11H3V13H11Z"
                  fill="black"
                />
              </svg>
              <span class="pl-4">
                {{ $t('toAddNewAddress') }}
              </span>
            </div>
          </UiButton>

          <div
            v-for="address in addresses"
            :key="address.id"
            class="mt-2 mb-4 last:mb-0"
          >
            <CustomerAddress
              class="px-2"
              variant="address-list"
              radio
              :address="address"
              :active="address.id === deliveryAddressId"
              @onSelect="selectAddress"
            />
          </div>
        </div>
      </div>
    </UiToolPopup>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { AddressService } from '@services/address';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { getAddressText } from '@services/address/lib/get-address-text';

import ExtendedDeliveryTooltip from '@components/extended-delivery/ExtendedDeliveryTooltip.vue';
import CustomerAddress from '@pure-ui/components/Address/CustomerAddress/CustomerAddressOld.vue';
import IconLocation from '../icons/IconLocation.vue';
import UiToolPopup from '../ui/UiToolPopup.vue';
import UiButton from '../ui/UiButtonNew.vue';

export default {
  name: 'DeliveryContext',
  components: {
    ExtendedDeliveryTooltip,
    CustomerAddress,
    IconLocation,
    UiToolPopup,
    UiButton,
  },
  inject: ['toast', 'popup'],
  props: {
    isCart: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters('user', ['addressCount', 'deliveryAddressId']),
    ...mapGetters('paidOrder', ['isDistant']),
    ...mapState('user', {
      address: state => state.deliveryAddress,
      addresses: state => state.addresses,
    }),
    ...mapGetters('delivery', ['expectedDeliveryTime']),
    houseAddress() {
      return (
        getAddressText(this.address, {
          lang: this.$i18n.locale,
          withName: true,
        }) || this.$t('selectAddress')
      );
    },
  },
  mounted() {
    if (this.$route.params.changeAddress) {
      this.openAddressList();
    }
  },
  methods: {
    onDeliveryClick() {
      if (this.addressCount !== 0) this.openAddressList();
      else this.openMap('desk_header');
    },
    openMap(from) {
      this.$eventBus.emit('eb_open_address_create_container', { from });
    },
    openAddressList(from = 'desk_header') {
      this.popup.show('address-list-toolpopup');

      Analitycs.logEvent(EVENTS.VIEWED_ADDRESS, {
        address_list: this.addresses,
        from,
      });
    },
    closeAddressList() {
      this.popup.hide('address-list-toolpopup');
    },
    addAddress() {
      this.closeAddressList();

      this.openMap('address_list_desk');
    },
    selectAddress(address) {
      this.closeAddressList();
      AddressService.changeAddress(address).catch(err =>
        this.toast.show(err.message, 'error')
      );
    },
  },
  eventBusEvents: {
    eb_open_address_list(data) {
      if (this.$isDesktop) this.openAddressList(data.from);
    },
  },
  i18n: {
    messages: {
      ru: {
        weWillDeliverFor: 'доставим за {time} мин',
        toAddNewAddress: 'Добавить новый адрес',
        provideDeliveryAddress: 'Укажите адрес доставки',
        selectAddress: 'Укажите адрес',
      },
      kk: {
        weWillDeliverFor: '{time} минутта жеткіземіз',
        toAddNewAddress: 'Жаңа мекен-жайды қосу',
        provideDeliveryAddress: 'Тапсырыс мекен-жайын таңдаңыз',
        selectAddress: 'Мекен-жай таңдаңыз',
      },
    },
  },
};
</script>

<style scoped>
.delivery-context {
  @apply w-full 
    pt-1.5 pb-2 px-3 
    border border-sirius-orange-100 rounded-2xl 
    flex items-start cursor-pointer;

  min-width: 160px;
}
.delivery-context-info {
  @apply flex items-center;
}
.delivery-context-time {
  @apply whitespace-nowrap 
  text-sirius-orange-100 
  font-bold text-sm leading-4;
}
.delivery-context-distant {
  @apply flex items-center justify-center h-5 w-7;
}
.delivery-context-address {
  @apply mt-0.5 line-clamp-1 text-lg leading-5;
}
</style>
