<template>
  <BottomSheetPopup
    :name="name"
    style="--modal-width: 472px"
    close-btn
    size="lg"
    with-back-button
    with-title
  >
    <template #title>
      <p class="w-full text-center font-bold text-lg mx-auto">
        {{ $t('applyConditions') }}
      </p>
    </template>
    <div class="px-4 pb-4 pt-2 md:px-8 md:pt-8 md:pb-10">
      <div>
        <ul class="conditions-list">
          <li></li>
          <li>
            <span v-html="$t('conditions.min', [minimumSum])" />
          </li>
          <li v-if="maxDiscount > 0">
            <span v-html="$t('conditions.maxDiscount', [maxDiscount])" />
          </li>
          <li>
            <span v-html="$t('conditions.perOrder')" />
          </li>
        </ul>
      </div>
      <div
        class="mt-10 md:mt-8 w-full flex flex-col justify-between items-center space-y-2"
      >
        <UiButton
          v-if="!isArchive && !isApplied"
          class="w-full font-bold text-lg"
          size="lg"
          @click.stop.prevent="onActivate"
        >
          {{ $t('toApplyPromocode') }}
        </UiButton>
        <UiButton
          class="w-full font-bold text-lg"
          size="lg"
          :variant="!isArchive && !isApplied ? 'gray' : 'primary'"
          @click.stop.prevent="onClose"
        >
          {{ $t('done') }}
        </UiButton>
      </div>
    </div>
  </BottomSheetPopup>
</template>

<script>
import BottomSheetPopup from '@ui/bottom-sheet-popup/BottomSheetPopup.vue';
import UiButton from '@ui/UiButtonNew.vue';

export default {
  name: 'ConditionsModal',
  components: { BottomSheetPopup, UiButton },
  props: {
    name: {
      type: String,
      required: true,
    },
    isArchive: {
      type: Boolean,
      default: false,
    },
    isApplied: {
      type: Boolean,
      default: false,
    },
    /**
     * Минимальная сумма заказа для применения промокода
     */
    minimumSum: {
      type: Number,
      default: 0,
    },
    /**
     * Максимальная сумма скидки если промокод на процент от суммы заказа
     */
    maxDiscount: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    onActivate() {
      this.$emit('onActivate');
    },
    onClose() {
      this.$emit('onClose');
    },
  },
  i18n: {
    messages: {
      ru: {
        conditions: {
          maxDiscount: `максимальная сумма скидки {0}&nbsp;₸;`,
          min: `
            промокод действует при сумме товаров в заказе от&nbsp;{0}&nbsp;₸,
            без учета стоимости доставки и&nbsp;сервисного сбора;
          `,
          perOrder: `
            за&nbsp;1 заказ можно использовать только 1&nbsp;промокод.
          `,
        },
        applyConditions: 'Условия применения',
        toApplyPromocode: 'Применить промокод',
        done: 'Готово',
      },
      kk: {
        conditions: {
          maxDiscount: `максималды жеңілдік бағасы {0} ₸;`,
          min: `
            промокод тапсырысыңыздың бағасы {0}&nbsp;₸-ден асқан кезде қолданылады,
            жеткізу құны мен қызмет ақысы оған қосылмайды;
          `,
          perOrder: `
            1 тапсырысқа тек 1 промокод пайдалануға болады.
          `,
        },
        applyConditions: 'Қолдану шарттары',
        toApplyPromocode: 'Промокодты қолдану',
        done: 'Дайын',
      },
    },
  },
};
</script>

<style scoped>
.conditions-list li {
  position: relative;
  padding-left: 19px;
  margin-top: 8px;
}

.conditions-list li::before {
  content: '';
  display: block;
  position: absolute;
  top: 0.5em;
  left: 0;
  width: 4px;
  height: 4px;
  background: currentColor;
  border-radius: 50px;
}
</style>
