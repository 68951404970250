<template>
  <UiBottomSheetWrapper v-model="show" with-title>
    <template #title>{{ $t('selectLanguage') }}</template>
    <div class="">
      <button
        v-for="lang in languages"
        :key="lang.code"
        :style="{
          background: isActive(lang) ? 'rgba(255, 161, 0, 0.2)' : '#F1F2F7',
        }"
        :disabled="isActive(lang)"
        class="py-4 pl-5 pr-3 w-full mt-3 text-left rounded-xl flex justify-between font-medium relative"
        @click="selectLanguage(lang.code)"
      >
        {{ lang.label }}
        <IconCheckedCircle
          v-if="isActive(lang)"
          class="absolute top-0 bottom-0 right-3 m-auto"
        />
      </button>
    </div>
  </UiBottomSheetWrapper>
</template>

<script>
import { LocaleService } from '@shared/services/locale';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import IconCheckedCircle from './icons/IconCheckedCircle.vue';

export default {
  name: 'LangList',
  components: {
    IconCheckedCircle,
    UiBottomSheetWrapper,
  },
  data: () => ({
    show: false,
  }),
  computed: {
    languages() {
      return [
        {
          code: 'kk',
          label: 'Қазақша',
        },
        {
          code: 'ru',
          label: 'Русский',
        },
      ];
    },
  },
  methods: {
    isActive(lang) {
      return lang.code === this.$i18n.locale;
    },
    selectLanguage(code) {
      this.show = false;
      LocaleService.setLocale(code);
      this.$emit('onChange');
      Analitycs.logEvent(EVENTS.LANGUAGE_CHANGE, {
        language: code,
      });
    },
  },
  eventBusEvents: {
    eb_open_lang_switcher_popup() {
      this.show = true;
    },
  },
  i18n: {
    messages: {
      ru: {
        selectLanguage: 'Выберите язык',
      },
      kk: {
        selectLanguage: 'Тілді таңдаңыз',
      },
    },
  },
};
</script>
