import { Scroll } from './scroll';

const ScrollMap = new Map();
function add(el, onScrolled, onDirectionChanged) {
  let scroll = new Scroll(onScrolled, onDirectionChanged);

  if (ScrollMap.get(el)) return;

  ScrollMap.set(el, scroll);
  scroll.addListener();
}
function remove(el) {
  let scroll = ScrollMap.get(el);
  if (!scroll) return;

  scroll.removeListener();
  ScrollMap.delete(el, scroll);
}
export default {
  bind(el, binding) {
    const { disabled, onScrolled, onDirectionChanged } = binding.value;
    if (disabled === true) return;
    add(el, onScrolled, onDirectionChanged);
  },
  unbind(el) {
    remove(el);
  },
  update(el, binding) {
    const { disabled, onScrolled, onDirectionChanged } = binding.value;

    if (disabled) remove(el);
    else add(el, onScrolled, onDirectionChanged);
  },
};
