<template>
  <UiPopup
    :show="modelValue"
    class="NoGoodsPopup"
    :closable="$isDesktop"
    style="--popup-width-d: 588px;"
    radius="3xl"
    @change="$emit('update:modelValue', $event)"
  >
    <div class="ngp">
      <p class="ngp__title" v-html="$t('title')" />
      <p class="ngp__descr" v-html="$t('descr1')" />
      <p class="ngp__descr2" v-html="$t('descr2')" />
    </div>

    <div class="ngp__btn">
      <UiButton size="LG" variant="primary" @click.native="onClose">
        {{ $t('close') }}
      </UiButton>
    </div>
  </UiPopup>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';

export default {
  components: { UiButton, UiPopup },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
  },
  methods: {
    onClose() {
      this.$emit('update:modelValue', false);
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Нет товаров в наличии',
        descr1: 'Товары из этого заказа сейчас не в наличии. ',
        descr2: 'Попробуйте сделать повторный заказ позже.',
        close: 'Хорошо',
      },
      kk: {
        title: 'Тауарлар қолжетімді емес',
        descr1: 'Бұл тапсырыстың тауарлары қазір қолжетімді емес.',
        descr2: 'Кейінірек қайталап тапсырыс беріп көріңіз.',
        close: 'Жақсы',
      },
    },
  },
};
</script>

<style scoped>
.ngp {
  @apply flex flex-col justify-center items-center text-center;
}

.ngp__title {
  @apply mb-4 text-xl md:text-3xl font-bold mt-2;
}
.ngp__descr, .ngp__descr2 {
  @apply mt-2 md:text-lg md:mt-4 text-base text-sirius-black-100;
}
.ngp__btn {
  @apply mt-7 md:mt-10 md:mb-0;
}

@media (max-width: 768px) {
  .ngp__descr {
    max-width: 250px;
  }
  .ngp__descr2 {
    max-width: 300px;
  }

}
</style>
