<template>
  <RenderlessProduct
    v-slot="{ isPending, isSelected, quantity, addToCart, removeFromCart }"
    id-key="product_id"
    :product="product"
  >
    <div class="w-full py-5 flex">
      <img
        :src="product.path"
        class="mr-5 w-20 h-20"
        :class="{ 'opacity-50': !isContentVisible }"
        :alt="productName"
      />
      <div class="w-full flex flex-col">
        <div class="flex justify-between items-center mb-4">
          <h3
            class="text-sm leading-tight dark:text-white"
            style="width: calc(100% - 30px)"
          >
            {{ productName }}
          </h3>
          <button
            v-if="product.is_selectable && warehouseId"
            class="ui-button --outline w-6 h-6 p-1"
            @click="onReplaceClicked"
          >
            <img src="@/assets/images/icons/replace.svg" alt="Replace icon" />
          </button>
        </div>
        <div v-if="isContentVisible" class="flex justify-between items-end">
          <div class="flex flex-col mr-5">
            <p class="text-gray-700 line-through">{{ oldPrice }}</p>
            <p class="font-bold dark:text-gray-700 -mb-1.5">
              {{ product.price }}₸
            </p>
          </div>
          <transition name="fade" mode="out-in">
            <template v-if="isSelected">
              <ProductCounter
                :model-value="quantity"
                :max="product.max_quantity || Infinity"
                :loading="isPending"
                @onIncrement="onIncrement(addToCart)"
                @onDecrement="onDecrement(removeFromCart)"
              />
            </template>
            <template v-else>
              <UiButton
                class="duration-100 text-xs px-5"
                variant="primary-light"
                size="sm"
                @click="onAddToCart(addToCart)"
              >
                В корзину
              </UiButton>
            </template>
          </transition>
        </div>
        <p v-else class="leading-tight text-red-500">Упс, товар скоро будет</p>
      </div>
    </div>
  </RenderlessProduct>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { RahmetApp } from '@shared/RahmetApp';

import { RenderlessProduct } from '@shared/components/product/RenderlessProduct';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { onAdd, onDec } from '@services/product/analytics';
import { PRODUCT_ADDED } from '@shared/config/product';
import ProductCounter from '@pure-ui/components/Product/ProductCounter/ProductCounter.vue';
import UiButton from '@ui/UiButton.vue';

export default {
  name: 'RecipeProduct',
  components: {
    RenderlessProduct,
    ProductCounter,
    UiButton,
  },
  inject: ['bottomSheet'],
  props: {
    product: {
      required: true,
      type: Object,
    },
    recipeId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState(['isDesktop']),
    ...mapGetters('delivery', ['warehouseId']),
    isContentVisible() {
      return (
        this.product.max_quantity !== null && this.product.max_quantity !== 0
      );
    },
    uid() {
      return `${this.product.category_id}::${this.product.id}`;
    },
    oldPrice() {
      return this.product.old_price === null || this.product.old_price === 0
        ? ''
        : `${this.product.old_price}₸`;
    },
    productName() {
      if (!this.product.feature_name) {
        return this.product.name;
      }

      const arr = this.product.feature_name.split(' ');

      return `
        ${this.product.name}, ${Number(arr[0].replace(',', '.'))} ${arr[1]}`;
    },
  },
  mounted() {
    if (this.product.id === this.productDeeplink) {
      this.bottomSheet.show(`detailed-product-${this.uid}`);
    }
  },
  methods: {
    onAddToCart(addToCart) {
      RahmetApp.hapticSelection();
      onAdd(PRODUCT_ADDED.RECIPE, this.product);
      addToCart(PRODUCT_ADDED.RECIPE, this.recipeId);
    },
    onIncrement(addToCart) {
      RahmetApp.hapticSelection();
      onAdd(PRODUCT_ADDED.RECIPE, this.product, true);
      addToCart(PRODUCT_ADDED.RECIPE, this.recipeId);
    },
    onDecrement(removeFromCart) {
      RahmetApp.hapticSelection();
      onDec(PRODUCT_ADDED.RECIPE, this.product);
      removeFromCart();
    },
    onProductClick() {
      if (!this.product || this.product.is_sample) return;
      if (this.isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: this.product.id.toString(),
          },
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: this.product.id,
        });
      }
    },
    onReplaceClicked() {
      let query = {
        tag: this.product.category_id,
        replacement: true,
        product_id: this.product.product_id,
        recipe_id: this.recipeId,
      };

      Analitycs.logEvent(EVENTS.RECIPE_CHANGE, {
        recipe_id: this.recipeId,
        product_id: this.product.product_id,
      });

      if (this.product.feature_id) {
        query = Object.assign(query, {
          feature_id: this.product.feature_id,
        });
      }

      this.$router.push({
        name: 'category',
        params: {
          id: String(this.product.parent_id),
          route: this.$route,
        },
        query,
      });
    },
  },
};
</script>

<style scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
