<template>
  <MainLayout>
    <template v-if="$isWebView" #header>
      <!-- Дарите друзьям 1500 ₸ — получайте 1500 ₸ -->
      <HeaderBase truncate @onBack="goBack"> {{ $t('pageTitle') }} </HeaderBase>
    </template>
    <ProfileLayout>
      <ReferralDesk
        v-if="$isDesktop"
        :promocode="promocode"
        :min-price="minAmount"
        @onCopy="onCopy"
      />
      <ReferralMobile
        v-else
        :promocode="promocode"
        :min-price="minAmount"
        @onShare="onShare"
        @onCopy="onCopy"
      />
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { ReferralService } from '@services/referral';
import { RahmetApp } from '@shared/RahmetApp';
import { copy } from '@/utils/copy';
import { mapGetters } from 'vuex';
import MainLayout from '@shared/components/layout/MainLayout.vue';
import HeaderBase from '@components/header/components/HeaderBase.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import ReferralDesk from '@pure-ui/components/Referral/ReferralView/ReferralDesk.vue';
import ReferralMobile from '@pure-ui/components/Referral/ReferralView/ReferralMobile.vue';

export default {
  name: 'ReferralPage',
  components: {
    ReferralMobile,
    ReferralDesk,
    ProfileLayout,
    MainLayout,
    HeaderBase,
  },
  inject: ['toast'],
  data: () => ({
    promocode: '',
    minAmount: 2000,
  }),
  computed: {
    ...mapGetters('user', ['hasReferralPromocode']),
  },
  mounted() {
    Analitycs.logEvent(EVENTS.REF_PAGE_ENTER);
    this.fetchPromocode();
  },
  methods: {
    fetchPromocode() {
      if (!this.hasReferralPromocode) return;

      ReferralService.fetchPromocode().then(p => {
        const conditions = JSON.parse(p.conditions);
        this.promocode = p.promocode;
        this.minAmount = +conditions.amount_gt_than;
      });
    },
    onShare() {
      Analitycs.logEvent(EVENTS.REF_PAGE_SHARE_CLICK);
      ReferralService.sharePromocode(this.promocode, this.$i18n.locale);
    },
    onCopy() {
      copy(this.promocode).then(() => {
        let msg = this.$isDesktop ? 'copiedDesk' : 'copied';
        this.toast.show(this.$t(msg), 'success');
        Analitycs.logEvent(EVENTS.REF_PAGE_COPY_CLICK);
      });
    },
    goBack() {
      RahmetApp.hapticSelection();
      const emptyHistory = window.history.length < 3;
      if (emptyHistory) this.$router.push({ name: 'main' });
      else this.$router.go(-1);
    },
  },
  i18n: {
    messages: {
      ru: {
        copied: 'Скопировано',
        pageTitle: 'Дарите 1500 ₸, получайте 1500 ₸',
        copiedDesk: 'Промокод скопирован',
      },
      kk: {
        copied: 'Көшірілді',
        pageTitle: '1500 ₸ сыйлаңыз, 1500 ₸ алыңыз',
        copiedDesk: 'Промокод көшірілді',
      },
    },
  },
};
</script>
