import { client } from '../../clients';

/**
 * Выдача конфигов для подключения к сокетам
 * @returns {Promise}
 */
export default function () {
  return client
    .get('lavka/v2/socket/connection_data/public')
    .then(response => response.data);
}
