<template>
  <MainLayout :with-footer="false" class="text-sirius-black-100 leading-tight">
    <template #header>
      <ToolBar v-if="isWebView">
        {{ $t(constructToolbarTitle.title) }}
      </ToolBar>
    </template>

    <ProfileLayout :container-width="488" :bread-links="links">
      <StepIndicator step="2" />
      <h1 v-if="!isWebView" class="text-lg font-bold md:text-2xl pl-4 mb-4">
        {{ $t(constructToolbarTitle.title) }}
      </h1>
      <template v-if="type === 'courier'">
        <CheckDoor
          v-if="order && order.leave_at_the_door"
          class="ml-4 mr-4 mt-8 mb-9"
        />
      </template>
      <div class="flex flex-col h-full mt-4">
        <template v-if="isProductProblem">
          <div class="px-4">
            <p class="text-sirius-gray-700">
              {{ $t('productsWithProblems') }}
            </p>

            <p class="mt-5 font-bold">
              <template v-if="$i18n.locale === 'ru'">
                {{ totalCount }} {{ productCountDeclination }},
                {{ totlaPrice }} ₸
              </template>
              <template v-else-if="$i18n.locale === 'kk'">
                {{ totalCount }} тауар, {{ totlaPrice }} ₸
              </template>
            </p>
            <p v-if="productsCount === 0" class="dark:text-gray-new-700 mt-4">
              {{ $t('productsNotFound') }}
            </p>
            <div
              v-else
              class="mt-4 relative flex items-center h-16 mx-auto overflow-hidden"
              :class="{
                '-space-x-6 xs:-space-x-4 ': productsCount > 6,
                '-space-x-4 xs:-space-x-2 ': productsCount > 5,
                '-space-x-2 xs:-space-x-0 ': productsCount > 4,
                '-space-x-0 xs:-space-x-2 ': productsCount > 3,
              }"
            >
              <div
                v-for="(item, index) in allItems"
                :key="item.id"
                class="relative inline-block h-16 w-16 rounded-full border-2 border-sirius-gray-300 overflow-hidden bg-white"
                :class="{
                  hidden: productsCount === 7 ? index >= 7 : index >= 6,
                }"
              >
                <img
                  class="w-full h-full p-1"
                  :src="item.path"
                  alt=""
                  @click="goToFullProduct(item)"
                />
              </div>
              <div
                v-if="productsCount > 7"
                class="relative h-16 w-16 rounded-full border-2 border-sirius-gray-300 overflow-hidden bg-white flex items-center justify-center font-medium text-2xl"
              >
                +{{ productsCount - 6 }}
              </div>
            </div>
          </div>
        </template>

        <div class="px-4" :class="{ 'mt-9': isProductProblem }">
          <p class="font-bold">{{ $t('tellMoreAboutProblem') }}</p>
          <UiTextarea
            v-model="$v.comment.$model"
            error-message="Обязательное поле"
            name="comment"
            aria-label="Комментарий"
            class="mt-4"
            rows="3"
            no-resize
            :invalid="$v.comment.$error"
            @input="setActionButtonState"
          />
        </div>

        <FileLoader
          v-if="showPhotoUpload"
          class="mt-9"
          @getLoadedImages="imageLoaded"
          @uploadingFile="fileUploadingState"
        />

        <section v-if="isProductProblem" class="mt-9 px-4">
          <div class="flex md:flex-row-reverse pb-4 cursor-pointer">
            <label for="variant1" class="cursor-pointer">
              <p class="font-bold">{{ $t('justWannaLeaveComment') }}</p>
              <p class="mt-3 text-sirius-gray-700">
                {{ $t('weWillConsiderYourComment') }}
              </p>
            </label>
            <div class="radio-btn-box md:mr-3 cursor-pointer">
              <input
                id="variant1"
                v-model="flag_type"
                value="variant1"
                type="radio"
                class="radio-btn form-check-input appearance-none cursor-pointer"
                :class="{
                  'radio-btn-checked': flag_type === 'variant1',
                }"
                @change="setActionButtonState"
              />
            </div>
          </div>
          <div class="flex md:flex-row-reverse mt-4 pb-4 cursor-pointer">
            <label for="variant2" class="cursor-pointer">
              <p class="font-bold">{{ $t('wannaReturnMyMoney') }}</p>
              <p class="mt-3 text-sirius-gray-700">
                {{ $t('weWillReturnImmediatelyOrContact') }}
              </p>
            </label>
            <div class="radio-btn-box md:mr-3 cursor-pointer">
              <input
                id="variant2"
                v-model="flag_type"
                value="variant2"
                type="radio"
                class="radio-btn form-check-input appearance-none cursor-pointer"
                :class="{
                  'radio-btn-checked': flag_type === 'variant2',
                }"
                @change="setActionButtonState"
              />
            </div>
          </div>
          <div class="flex md:flex-row-reverse mt-4 pb-4 cursor-pointer">
            <label for="variant3" class="cursor-pointer">
              <p class="font-bold">{{ $t('wannaMyProducts') }}</p>
              <p class="mt-3 text-sirius-gray-700">
                {{ $t('weWillContactAboutConvientlyTime') }}
              </p>
            </label>
            <div class="radio-btn-box md:mr-3 cursor-pointer">
              <input
                id="variant3"
                v-model="flag_type"
                value="variant3"
                type="radio"
                class="radio-btn form-check-input appearance-none cursor-pointer"
                :class="{
                  'radio-btn-checked': flag_type === 'variant3',
                }"
                @change="setActionButtonState"
              />
            </div>
          </div>
        </section>

        <div class="pt-6 md:pt-0 mt-auto md:mt-16 dark:bg-night">
          <div class="px-4 pt-2 md:pt-0 pb-12">
            <UiButton
              class="font-bold text-lg w-full"
              size="lg"
              :disabled="isActionButtonDisabled"
              @click="sendRequest"
            >
              {{ $t('done') }}
            </UiButton>
          </div>
        </div>
      </div>
    </ProfileLayout>

    <BottomSheetPopup
      name="final-popup"
      class="text-center pb-28"
      with-back-button
      close-btn
      style="--modal-width: 572px"
      @onClose="closeFinalPopup"
    >
      <div class="px-4 pb-4 md:px-8 md:pt-8 md:pb-10">
        <template v-if="flag_type === 'money'">
          <img
            class="mx-auto"
            src="@img/problems/wallet.png"
            width="252px"
            height="240px"
            alt=""
          />

          <p
            class="mt-6 font-bold text-2xl"
            v-html="$t('moneyWillBeReturnedSoon')"
          ></p>
          <p
            class="mt-4 text-sirius-black-700"
            v-html="$t('usuallyItHappensInOneOrThreeDays')"
          ></p>
        </template>
        <template v-else>
          <img
            class="mx-auto"
            src="@img/problems/support.png"
            width="240px"
            height="192px"
            alt=""
          />

          <p class="mt-6 font-bold text-2xl">
            {{ $t('thanksForContacting') }}
          </p>
          <p class="mt-4 text-sirius-black-700">
            {{ $t('careServiceEmployeeWillContactYou') }}
          </p>
        </template>
        <UiButton
          size="lg"
          class="mt-10 w-full text-lg font-bold"
          @click="closeFinalPopup"
        >
          {{ $t('done') }}
        </UiButton>
      </div>
    </BottomSheetPopup>

    <UiFullScreenLoader v-if="isSending" />
  </MainLayout>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { wordDeclination } from '@shared/utils/delivery/word-declination';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { formatPrice } from '@shared/utils/formatters/price';
import { mapGetters, mapState, mapMutations } from 'vuex';
import { ClientService } from '@shared/services/client';
import { api } from '@shared/services/api';
import { v4 as uuidv4 } from 'uuid';
import { rFetchOrder } from '@shared/services/api/apis';

import BottomSheetPopup from '@shared/components/ui/bottom-sheet-popup/BottomSheetPopup.vue';
import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';

import UiFullScreenLoader from '@ui/UiFullScreenLoader.vue';
import UiTextarea from '@pure-ui/components/UiTextArea/UiTextArea.vue';
import UiButton from '@ui/UiButtonNew.vue';
import { dateAndTime } from '@/utils/formatters/date';

import FileLoader from '../../components/FileLoader.vue';
import StepIndicator from '../../components/StepIndicator.vue';
import CheckDoor from '../../components/CheckDoor.vue';

export default {
  name: 'ProblemsRequestView',
  components: {
    MainLayout,
    UiButton,
    UiTextarea,
    UiFullScreenLoader,
    FileLoader,
    ToolBar,
    ProfileLayout,
    StepIndicator,
    BottomSheetPopup,
    CheckDoor,
  },
  mixins: [validationMixin],
  inject: ['toast', 'bottomSheetPopup'],
  validations: {
    comment: {
      required,
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    comment: '',
    flag_type: '',
    isSending: false,
    isActionButtonDisabled: true,
    images: [],
    loading: true,
    isFileUploading: false,
    order: null,
  }),
  computed: {
    ...mapState(['isDesktop', 'isWebView']),
    ...mapState('orderProblems', [
      'features',
      'products',
      'submitPromise',
      'feedbackState',
    ]),
    ...mapGetters('orderProblems', ['items']),
    allItems() {
      const products = Object.values(this.products);
      const features = Object.values(this.features);
      return [...products, ...features];
    },
    productsCount() {
      return this.allItems.length;
    },
    totalCount() {
      return formatPrice(this.allItems.reduce((sum, e) => sum + e.counter, 0));
    },
    totlaPrice() {
      return formatPrice(
        this.allItems.reduce((sum, e) => sum + e.price * e.counter, 0)
      );
    },
    productCountDeclination() {
      return wordDeclination(this.totalCount, ['товар', 'товара', 'товара']);
    },
    showPhotoUpload() {
      const types = ['doublepay', 'notreturned', 'courier', 'incomplete '];
      if (types.includes(this.type)) return false;
      return true;
    },
    isProductProblem() {
      return (
        this.type === 'stale' ||
        this.type === 'incomplete' ||
        this.type === 'notexactly'
      );
    },
    constructToolbarTitle() {
      switch (this.type) {
        case 'stale':
          return {
            title: 'somethingWrongWithProduct',
            id: 1,
          };
        case 'incomplete':
          return {
            title: 'notAllWasDelivered',
            id: 3,
          };
        case 'notexactly':
          return {
            title: 'wrongProductsWasDelivered',
            id: 8,
          };
        case 'courier':
          return {
            title: 'courierHasNotCome',
          };
        case 'doublepay':
          return {
            title: 'doubleOrderPayment',
          };
        case 'notreturned':
          return {
            title: 'moneyStillHadNotBeenReturned',
          };
        case 'other':
          return {
            title: 'anotherReason',
          };
        default:
          return {
            title: 'Что-то пошло не так',
            id: 0,
          };
      }
    },
    isDiscount() {
      let result = Object.values(this.products).find(
        e => e.original_price === e.price
      );
      if (!result) {
        result = Object.values(this.features).find(
          e => e.original_price === e.price
        );
      }
      if (result) return true;
      return false;
    },
    links() {
      const links = [
        { name: 'Главная', path: '/' },
        { name: 'Все заказы', path: '/orders' },
      ];

      if (this.order) {
        links.push({
          name: dateAndTime(this.order.paid_at),
          path: `/order/${this.id}`,
        });

        links.push({
          name: this.$t(this.constructToolbarTitle.title),
          path: '#',
        });
      }

      return links;
    },
  },
  mounted() {
    this.fetchOrder();
  },
  methods: {
    ...mapMutations('orderProblems', {
      resetFeedbackState: 'RESET_FEEDBACK_STATE',
    }),
    formatPrice,
    fileUploadingState(value) {
      if (value) this.isFileUploading = true;
      else this.isFileUploading = false;
      this.setActionButtonState();
    },
    setActionButtonState() {
      if (this.isFileUploading) {
        this.isActionButtonDisabled = true;
        return;
      }

      if (this.isProductProblem) {
        if (this.flag_type) {
          this.isActionButtonDisabled = false;
        }
      } else if (this.comment) {
        this.isActionButtonDisabled = false;
      } else {
        this.isActionButtonDisabled = true;
      }
    },
    imageLoaded(images) {
      this.images = images;
      this.setActionButtonState();
    },
    openFinalModal() {
      this.$refs.finalPopup.openPopup();
    },
    sendRequest() {
      this.$v.$touch();

      if (!this.comment) {
        this.toast.show(this.$t('fillCommentField'), 'error');
        return;
      }
      if (this.isProductProblem && this.images.length === 0) {
        this.toast.show(this.$t('uploadPhoto'), 'error');
        return;
      }
      this.isSending = true;
      const { deviceId } = ClientService.getContext();
      const { items } = this;
      const methods = {
        stale: 'orderItemCancel',
        incomplete: 'orderItemCancel',
        notexactly: 'orderItemCancel',
        courier: 'orderHelp',
        doublepay: 'orderHelp',
        notreturned: 'orderHelp',
        other: 'orderHelp',
      };
      let comment = `${this.comment}`;

      let body = {
        device_id: deviceId || '',
        order_id: this.id,
        idempotency_key: uuidv4(),
        reason: this.$t(this.constructToolbarTitle.title, 'ru'),
        comment,
      };
      const location = this.$store.getters['user/userLocation'];

      Analitycs.logEvent(EVENTS.PROBLEM_ORDER, {
        reason: this.$t(this.constructToolbarTitle.title, 'ru'),
        order_id: this.id,
        user_order_count: 0,
        ...location,
      });

      if (this.isProductProblem) {
        let refund = 0;
        if (this.flag_type === 'variant2') {
          comment = `${this.$t('wannaReturnMyMoney', 'ru')}. ${
            this.comment ?? ' '
          }`;
          refund = 1;
        } else if (this.flag_type === 'variant3') {
          comment = `${this.$t('wannaMyProducts', 'ru')}. ${
            this.comment ?? ' '
          }`;
        } else {
          comment = `${this.$t('justWannaLeaveComment', 'ru')}. ${
            this.comment ?? ' '
          }`;
        }
        body = {
          ...body,
          items,
          refund,
          reason_type: this.constructToolbarTitle.id,
          comment,
          paths: this.images || [],
        };
      } else if (this.type === 'other') body.images = this.images;

      api.lavka[methods[this.type]](body)
        .then(res => {
          if (res.data.refunded) this.flag_type = 'money';
          else this.flag_type = 'support';
          this.openFinalPopup();

          if (this.submitPromise) return this.submitPromise();
          return Promise.resolve();
        })
        .then(() => {
          this.resetFeedbackState();
        })
        .catch(err => this.toast.show(err.message))
        .finally(() => (this.isSending = false));
    },
    goToFullProduct(item) {
      const query = item.added_from ? { added_from: item.added_from } : null;

      if (item.is_sample) return;
      if (this.$isDesktop) {
        this.$router.push({
          name: 'product-full',
          params: {
            id: item.product_id.toString(),
          },
          query,
        });
      } else {
        this.$eventBus.emit('eb_open_product_details_modal', {
          id: item.product_id,
          query,
        });
      }
    },
    closeFinalPopup() {
      this.bottomSheetPopup.hide('final-popup');
      if (this.feedbackState?.isMain === true) {
        this.$router.push({ name: 'main' });
      } else {
        this.$router.push({
          name: 'order',
          params: this.$route.params.id,
        });
      }
      this.resetFeedbackState();
    },
    openFinalPopup() {
      this.bottomSheetPopup.show('final-popup');
    },
    fetchOrder() {
      rFetchOrder(this.id)
        .then(data => (this.order = data))
        .catch(err => this.toast.show(err.message))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  i18n: {
    messages: {
      ru: {
        productsWithProblems: 'Товары, с которыми возникли проблемы',
        productsNotFound: 'Товары не найдены',
        tellMoreAboutProblem: 'Расскажите подробнее о проблеме',
        requiredField: 'Обязательное поле',
        comment: 'Комментарий',
        done: 'Готово',

        justWannaLeaveComment: 'Хочу просто оставить отзыв',
        weWillConsiderYourComment:
          'Учтем ваш отзыв и постараемся сделать так, чтобы такие ситуации не повторялись',

        wannaReturnMyMoney: 'Хочу вернуть деньги',
        weWillReturnImmediatelyOrContact:
          'Вернем сумму за товары до 2 000 ₸ сразу, а если сумма выше, то свяжемся, чтобы уточнить детали',

        wannaMyProducts: 'Хочу получить свои товары',
        weWillContactAboutConvientlyTime:
          'Свяжемся с вами и договоримся об удобном времени доставки товаров, с которыми возникли проблемы',

        moneyWillBeReturnedSoon: 'Деньги скоро вернутся',
        usuallyItHappensInOneOrThreeDays: `
            Обычно возврат средств занимает
            1&nbsp;–&nbsp;3 рабочих дня, в
            зависимости от правил банка, выпустившего карту.
        `,

        thanksForContacting: 'Спасибо за обращение',
        careServiceEmployeeWillContactYou:
          'Сотрудник службы заботы свяжется с вами в течение часа для уточнения деталей.',

        somethingWrongWithProduct: 'Что-то не так с товаром',
        notAllWasDelivered: 'Привезли не все товары',
        wrongProductsWasDelivered: 'Привезли не те товары',
        courierHasNotCome: 'Курьер не приехал',
        doubleOrderPayment: 'Двойная оплата заказа',
        moneyStillHadNotBeenReturned: 'Деньги все еще не вернули',
        anotherReason: 'Другая причина',
        uploadPhoto: 'Загрузите фото',
        fillCommentField: 'Заполните поле комментарий',
      },
      kk: {
        productsWithProblems: 'Мәселе туындаған тауарлар',
        productsNotFound: 'Тауарлар табылмады',
        tellMoreAboutProblem: 'Мәселе туралы толығырақ баяндаңызшы',
        requiredField: 'Міндетті жол',
        comment: 'Пікір қалдыру',
        done: 'Дайын',

        justWannaLeaveComment: 'Жай ғана пікір қалдырғым келеді',
        weWillConsiderYourComment:
          'Сіздің пікіріңізді ескеріп, мұндай жағдай қайталанбауына барымызды саламыз',

        wannaReturnMyMoney: 'Ақшамды қайтарғым келеді',
        weWillReturnImmediatelyOrContact:
          '2000 теңгеге дейінгі тауарларға соманы бірден қайтарамыз, ал сома одан жоғары болса, қосымша мәліметтерді нақтылау үшін хабарласамыз',

        wannaMyProducts: 'Өзімнің тауарларымды алғым келеді',
        weWillContactAboutConvientlyTime:
          'Сізге хабарласып, мәселе туындаған тауарларды жеткізудің ыңғайлы уақытын келісеміз',

        moneyWillBeReturnedSoon: 'Жақын арада ақша қайтарылады',
        usuallyItHappensInOneOrThreeDays: `
          Картаны шығарған банктің ережелеріне байланысты ақшаны
          қайтару әдетте 1&nbsp;-&nbsp;3 жұмыс күнін алады.
        `,

        thanksForContacting: 'Хабарласқаныңызға рақмет',
        careServiceEmployeeWillContactYou:
          'Қолдау көрсету қызметі тобының мүшесі мәліметтерді нақтылау үшін бір сағат ішінде сізбен байланысады.',

        somethingWrongWithProduct: 'Тауарда қателік бар',
        notAllWasDelivered: 'Тауарға байланысты мәселе туды',
        wrongProductsWasDelivered: 'Дұрыс емес тауарлар әкелінді',
        courierHasNotCome: 'Курьер келмеді',
        doubleOrderPayment: 'Тапсырыс екі рет төленді',
        moneyStillHadNotBeenReturned: 'Ақша әлі қайтарылған жоқ',
        anotherReason: 'Басқа себеп',
        uploadPhoto: 'Фотосуретті жүктеңіз',
        fillCommentField: 'Комментарий жолын толтырыңыз',
      },
    },
  },
};
</script>

<style lang="postcss" scoped>
.radio-btn {
  @apply w-7 h-7 rounded-full transform transition duration-300 ease-linear relative;
  border: 2px solid #d4d3df;
  &::after {
    @apply inset-0 m-auto absolute w-4 h-4 bg-white rounded-full transform transition duration-300 ease-linear;
    content: ' ';
  }

  &-checked {
    border: 2px solid #ffa100;
    &::after {
      @apply bg-sirius-orange-100;
    }
  }
}
</style>
