import { RahmetApp } from '@shared/RahmetApp';

/**
 * Добавляет глобальный проп isWebView
 */

let isWebView;

export default {
  install(VueLocal) {
    isWebView = VueLocal.observable({ value: RahmetApp.isWebView() });

    Object.defineProperty(VueLocal.prototype, '$isWebView', {
      get() {
        return isWebView.value;
      },
    });
  },
};
