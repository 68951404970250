export const NyContestModule = {
  namespaced: true,
  state: {
    winnersList: [],
    currentUser: null,
    forceShowTable: 0,
  },
  getters: {
    currentUserPlace(state) {
      return state.currentUser?.place || 99999;
    },
    currentUserScore(state) {
      if (state.forceShowTable) return 1;
      return state.currentUser?.score || 0;
    },
    winnersList(state) {
      return state.winnersList;
    },
    /**
     * Сколько баллов начислится, если оформить заказ
     * с текущей корзиной
     */
    cartScore(state, getters, rootState, rootGetters) {
      return rootGetters['cart/cart']?.nyCompetitionScore || 0;
    },
    /**
     * Возвращает название категории, за товары из который
     * начисляется дополнительный балл
     */
    additionalCategory() {
      return {
        enabled: true,
        link: '/category/1127',
        categoryId: '1127',
        ru: '+1 балл к розыгрышу',
        kk: 'Ұтыс ойынына +1 балл',
      };
    },
    /**
     * Условие, при котором показываются таблица с результатами
     * и текущие результаты п-ля
     * @returns {boolean}
     */
    showScore(state, getters, rootState, rootGetters) {
      // return getters['currentUserScore'];
      return rootGetters.isAuthorized;
    },
  },
  mutations: {
    SET_WINNERS_LIST(state, value) {
      state.winnersList = value;
    },
    SET_CURRENT_USER(state, value) {
      state.currentUser = value;
    },
    SET_FORCE_SHOW_TABLE(state, value) {
      state.forceShowTable = value;
    },
  },
};
