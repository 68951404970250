export const SEARCH_MODES = [
  {
    type: 'BY_CATEGORY',
    title: 'inCategory',
  },
  {
    type: 'EVERYWHERE',
    title: 'inAll',
  },
];
