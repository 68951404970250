<template>
  <p class="sale-price" :class="`--${size}`">
    <span class="sale-price-old"> {{ oldPrice }} ₸ </span>
    <span class="sale-price-new"> {{ price }} ₸ </span>
  </p>
</template>

<script>
export default {
  props: {
    price: {
      type: String,
      default: '',
    },
    oldPrice: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
};
</script>

<style scoped>
.sale-price {
  @apply flex items-center whitespace-nowrap;
}
.sale-price-old {
  @apply text-sirius-gray-1000 line-through;
}
.sale-price-new {
  @apply text-sirius-red-400;
}

.--xs .sale-price-old {
  @apply mr-1 text-xs font-medium leading-4;
}
.--xs .sale-price-new {
  @apply text-xs font-medium leading-4;
}
.--md .sale-price-old {
  @apply mr-2 text-sm font-medium leading-5;
}
.--md .sale-price-new {
  @apply text-lg font-medium leading-6;
}
.--lg .sale-price-old {
  @apply mr-2 text-base font-normal leading-5;
}
.--lg .sale-price-new {
  @apply text-base font-bold leading-5;
}
.--xl .sale-price-old {
  @apply mr-3 text-2xl font-normal leading-30;
}
.--xl .sale-price-new {
  @apply text-2xl font-bold leading-30;
}
</style>
