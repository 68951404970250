<template>
  <div class="product-counter" :class="`--${size} --${variant}`">
    <button
      class="product-counter-btn"
      :class="{ '--disabled': modelValue === min }"
      aria-label="Уменьшнить"
      @click="dec"
    >
      <MinusIcon class="product-counter-icon" width="24" height="24" />
    </button>

    <div class="product-counter-value">
      <slot> {{ modelValue }}</slot>
    </div>

    <button
      class="product-counter-btn"
      :class="{ '--disabled': modelValue === max }"
      aria-label="Увеличить"
      @click="inc"
    >
      <PlusIcon class="product-counter-icon" width="24" height="24" />
    </button>
  </div>
</template>

<script>
import MinusIcon from './components/MinusIcon.vue';
import PlusIcon from './components/PlusIcon.vue';
export default {
  components: {
    MinusIcon,
    PlusIcon,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER,
    },
    size: {
      type: String,
      default: 'md',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {};
  },
  computed: {
    loaderSize() {
      let dict = {
        xs: 'sm',
        sm: 'sm',
        md: 'md',
        xl: 'lg',
      };
      return dict[this.size];
    },
  },
  watch: {},
  methods: {
    inc() {
      if (this.loading === true) return;

      let value = this.modelValue + 1;
      if (value <= this.max) {
        this.$emit('update:modelValue', value);
        this.$emit('onIncrement', value);
      } else this.$emit('onMaxLimit');
    },
    dec() {
      if (this.loading === true) return;

      let value = this.modelValue - 1;
      if (value >= this.min) {
        this.$emit('update:modelValue', value);
        this.$emit('onDecrement', value);
      } else this.$emit('onMinLimit');
    },
  },
};
</script>

<style scoped>
.product-counter {
  @apply h-full w-full flex items-center justify-between;
}
.--primary.product-counter {
  @apply bg-sirius-orange-100 text-white;
}
.--secondary.product-counter {
  @apply bg-sirius-gray-200 text-sirius-black-100;
}
.--xs.product-counter {
  @apply h-9 rounded-xl;
}
.--sm.product-counter {
  @apply h-10 rounded-xl;
}
.--md.product-counter {
  @apply h-11 rounded-xl;
}
.--xl.product-counter {
  @apply h-13 rounded-2xl;
}

.product-counter-btn {
  @apply h-full focus:outline-none;
}
.product-counter-btn.--disabled,
.product-counter-btn:active {
  @apply opacity-30;
}
.--xs .product-counter-btn:first-child {
  padding-left: 3px;
}
.--xs .product-counter-btn:last-child {
  padding-right: 3px;
}
.--sm .product-counter-btn {
  @apply px-2;
}
.--md .product-counter-btn:first-child {
  padding-left: 8px;
  padding-right: 8px;
}
.--md .product-counter-btn:last-child {
  padding-left: 8px;
  padding-right: 8px;
}
.--xl .product-counter-btn {
  @apply px-4;
}

.product-counter-value {
  @apply w-full text-center;
  min-width: 20px;
}

.--xs .product-counter-value {
  @apply text-sm font-medium leading-4;
}
.--sm .product-counter-value {
  @apply text-sm font-medium leading-4;
}
.--md .product-counter-value {
  @apply text-lg font-medium leading-6;
}
.--xl .product-counter-value {
  @apply text-base font-bold leading-5;
}

.product-counter-icon {
  width: 24px;
  height: 24px;
}
.--md .product-counter-icon {
  width: 28px;
  height: 28px;
}
.--xl .product-counter-icon {
  width: 32px;
  height: 32px;
}
</style>
