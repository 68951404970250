<template>
  <MainLayout>
    <ProfileLayout>
      <h1 class="px-4 md:px-0 font-bold text-2xl md:text-32">Мои карты</h1>
      <div class="px-4 md:px-0 mt-7">
        <div
          v-for="(item, index) in 7"
          :key="index"
          class="flex items-center mb-2 last:mb-0"
        >
          <IconCreditCard class="mr-3" />
          <p class="font-medium mr-7">••••9315</p>

          <div
            class="ml-auto mr-0.5 w-10 h-10 flex items-center justify-center cursor-pointer"
          >
            <IconPen />
          </div>
          <div
            class="w-10 h-10 flex items-center justify-center cursor-pointer"
          >
            <IconTrashbin class="text-sirius-gray-100" />
          </div>
        </div>
      </div>
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import IconPen from '@shared/components/icons/IconPen.vue';
import MainLayout from '@shared/components/layout/MainLayout.vue';
import IconTrashbin from '@shared/components/icons/IconTrashbin.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import IconCreditCard from '../../shared/components/icons/IconCreditCard.vue';

export default {
  name: 'ProfileCardsView',
  components: {
    ProfileLayout,
    IconPen,
    IconTrashbin,
    IconCreditCard,
    MainLayout,
  },
  data() {
    return {
      name: 'Magzhan ',
    };
  },
};
</script>
