<template>
  <div
    class="CustomerAddressOld customer-address"
    :class="`--${variant} ${active ? '--active' : ''}`"
    @click="$emit('onSelect', address)"
  >
    <div v-if="leftIcon" class="customer-address-icon">
      <UiRadioCircle v-if="radio" :model-value="active" variant="secondary" />
      <UiInlineIcon v-else name="address-mark" />
    </div>
    <div class="customer-address-text">
      <p>{{ addressName }}</p>
      <p>{{ addressText }}</p>
    </div>
    <div class="customer-address-controls" @click.stop>
      <UiInlineIcon
        v-if="controls.includes('dots')"
        class="customer-address-control"
        name="address-dots"
        @click="$emit('onDots', address)"
      />
      <UiInlineIcon
        v-if="controls.includes('edit')"
        class="customer-address-control edit"
        name="address-pen"
        @click="$emit('onEdit', address)"
      />
      <UiInlineIcon
        v-if="controls.includes('delete')"
        class="customer-address-control delete"
        name="address-bin"
        @click="$emit('onDelete', address)"
      />
    </div>
  </div>
</template>

<script>
import { getAddressText } from '@services/address/lib/get-address-text';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiRadioCircle from '@pure-ui/components/UiRadioCircle/UiRadioCircle.vue';

export default {
  name: 'CustomerAddressOld',
  components: {
    UiInlineIcon,
    UiRadioCircle,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    leftIcon: {
      type: Boolean,
      default: true,
    },
    radio: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Array,
      default: () => [], // edit, controls, delete
    },
  },
  computed: {
    addressText() {
      return (
        getAddressText(this.address, {
          withCity: true,
          lang: this.$i18n.locale,
        }) || this.$t('selectAddress')
      );
    },
    addressName() {
      return (
        getAddressText(this.address, {
          onlyName: true,
          lang: this.$i18n.locale,
        }) || this.$t('address')
      );
    },
  },
  i18n: {
    messages: {
      kk: {
        selectAddress: 'Мекен-жай таңдаңыз',
        address: 'Мекен-жай',
      },
      ru: {
        selectAddress: 'Укажите адрес',
        address: 'Адрес',
      },
    },
  },
};
</script>

<style scoped>
.customer-address {
  @apply flex items-center cursor-pointer;
}
.customer-address-icon {
  @apply mr-4;
}
.customer-address-text {
  @apply w-full leading-tight;
}
.customer-address-text p:first-child {
  @apply font-bold text-lg leading-6 line-clamp-1;
}
.customer-address-text p:last-child {
  @apply mt-0.5 font-medium leading-5 text-sirius-gray-100 line-clamp-1;
}

.customer-address-controls {
  @apply ml-auto flex items-center;
}
.customer-address-control {
  @apply w-7 h-7 flex items-center justify-center;
}
.customer-address-control.edit {
  @apply w-10 h-10;
}
.customer-address-control.delete {
  @apply w-10 h-10 text-sirius-gray-100;
}

.--address-list .customer-address-text {
  @apply leading-22;
}
.--address-list.--active .customer-address-text p:first-child {
  @apply leading-22 text-sirius-orange-100;
}
.--address-list .customer-address-text p:last-child {
  @apply mt-0.5 leading-5;
}

.--desktop .customer-address-icon {
  @apply mr-3;
}
.--desktop .customer-address-text p:first-child {
  @apply text-sm;
}
.--desktop .customer-address-text p:last-child {
  @apply font-normal text-sm;
}

.--desktop-address-page .customer-address-text {
  @apply py-1 hover:text-sirius-orange-100 active:text-sirius-orange-300;
}
.--desktop-address-page.--active .customer-address-text p:first-child {
  @apply text-sirius-orange-100;
}
</style>
