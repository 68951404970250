<script>
export default {
  name: 'TagsGrid',
  render(h) {
    return h(
      'div',
      {
        class: '-mr-2 flex flex-wrap space-x-2 space-y-2 space-x-reverse',
      },
      this.$slots.default
    );
  },
};
</script>
