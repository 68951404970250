import imageDesktop from '@img/static-stories/first-order-discount-desktop.jpg';
import imageMobile from '@img/static-stories/first-order-discount-mobile.jpg';
import imageKk from '@img/static-stories/first-order-discount-kk.jpg';
import imageRu from '@img/static-stories/first-order-discount-ru.jpg';

export const firstOrderDiscountBanner = {
  type: 'story',
  code: 'promo40',
  fixedPosition: true,
  imageDesktop,
  imageMobile,
  textKk: `
    Бірінші тапсырысқа -40%
  `,
  textRu: `
    -40% на первый заказ
  `,
  stories: [{ imageKk, imageRu }],
};
