export const sortOptions = [
  {
    order: 'popular_asc',
    'title.kk': 'танымалдар',
    'title.ru': 'популярные',
  },
  {
    order: 'price_asc',
    'title.kk': 'арзандар',
    'title.ru': 'дешёвые',
  },
  {
    order: 'price_desc',
    'title.kk': 'қымбаттар',
    'title.ru': 'дорогие',
  },
];
