<template>
  <transition name="fade">
    <div
      v-show="isLoggerShown"
      class="Logger w-screen h-screen bg-black p-4 pt-12 fixed overflow-scroll left-0 top-0 z-toast text-gray-700 flex flex-col duration-500"
    >
      <div class="fixed top-0 pt-5 left-0 px-4 pb-4 w-full bg-black">
        <div class="flex items-center justify-between">
          <p class="font-bold text-center text-white text-2xl mr-4">
            Console logger
          </p>

          <button
            class="w-8 h-8 flex items-center justify-center bg-sirius-gray-200 text-black font-bold text-lg rounded-full border border-sirius-gray-200"
            @click="closeLogger"
          >
            x
          </button>
        </div>
        <div class="mt-2">
          <UiTabs v-model="type" :tabs="loggers" />
          <div class="mt-2 flex items-center justify-end space-x-2">
            <button
              class="w-14 h-8 flex items-center justify-center bg-sirius-gray-200 text-black font-bold text-2xl rounded-lg border border-sirius-gray-200 focus:border-sirius-gray-200"
              @click="clear"
            >
              clear
            </button>

            <button
              class="w-14 h-8 flex items-center justify-center bg-sirius-gray-200 text-black font-bold text-2xl rounded-lg border border-sirius-gray-200 focus:border-sirius-gray-200"
              @click="onCopy"
            >
              copy
            </button>
          </div>
        </div>
      </div>
      <div class="mt-28">
        <MethodsLogger v-if="type === 'Methods'" @close="closeLogger" />
        <StoreLogger v-else-if="type === 'Store'" />
        <LocalStorageLogger v-else-if="type === 'LocalStorage'" />
        <ConsoleView v-else :logs="logArray" :type="type" />
      </div>
    </div>
  </transition>
</template>

<script>
import { Logger as LOG } from '@shared/services/logger';
import { mapGetters, mapMutations } from 'vuex';
import LocalStorageLogger from './LocalStorageLogger.vue';
import MethodsLogger from './MethodsLogger.vue';
import ConsoleView from './ConsoleView.vue';
import StoreLogger from './StoreLogger.vue';
import UiTabs from '../ui/UiTabs.vue';
import { copy } from '@/utils/copy';

export default {
  name: 'Logger',
  components: {
    LocalStorageLogger,
    MethodsLogger,
    ConsoleView,
    StoreLogger,
    UiTabs,
  },
  inject: ['toast'],
  data() {
    return {
      type: 'Logs',
      logState: LOG.state,
      loggers: [
        { tab: 'Logs' },
        { tab: 'Network' },
        { tab: 'Methods' },
        { tab: 'Amplitude' },
        { tab: 'WS' },
        { tab: 'Router' },
        { tab: 'Store' },
        { tab: 'LocalStorage' },
        { tab: 'Segment' },
        { tab: 'Appsflyer' },
        { tab: 'EventBus' },
      ],
    };
  },
  computed: {
    ...mapGetters('logger', ['isLoggerShown']),

    logArray() {
      if (this.type === 'Amplitude') return this.logState.amplitudeLogs;
      if (this.type === 'Appsflyer') return this.logState.appsflyerLogs;
      if (this.type === 'Segment') return this.logState.segmentLogs;
      if (this.type === 'Network') return this.logState.networkLogs;
      if (this.type === 'Router') return this.logState.routerLogs;
      if (this.type === 'EventBus') return this.logState.eventBus;
      if (this.type === 'WS') return this.logState.wsLogs;
      return this.logState.logs;
    },
  },
  created() {
    document.body.style.overscrollBehavior = 'contain';
  },
  beforeDestroy() {
    document.body.style.overscrollBehavior = 'auto';
  },
  methods: {
    ...mapMutations('logger', ['CLOSE_LOGGER']),
    closeLogger() {
      this.CLOSE_LOGGER();
    },
    clear() {
      LOG.clear(this.type);
    },
    onCopy() {
      let logs = JSON.stringify(this.logArray);

      copy(logs)
        .then(() => {
          this.toast.show('Copied');
        })
        .catch(() => {
          this.toast.show('Not Copied');
        });
    },
  },
};
</script>
