import { createStorage } from '@shared/services/storage';
import { Logger as LOG } from '@shared/services/logger';
import { ClientService } from '@shared/services/client';
import { DevConfigs } from '@/utils/dev-config';
import { RahmetApp } from '@shared/RahmetApp';
import {
  rAuthByCode,
  rGetTokenByTrackId,
  rAuthByRefreshToken,
} from '@shared/services/api/apis';
import { store } from '@/store';
import { eventBus } from '@/utils/plugins/event-bus';

import { getAuthUrl, parseOauthStateRoute } from './utils';
import { MobAuthService } from './mobile-auth-service';
import { WebAuthService } from './web-auth-service';
import { AUTH_EVENTS, authEmitter } from './events';
import { logAuthError } from './utils/log-auth-error';

const storage = createStorage(window.localStorage);

function isWebApp() {
  return !RahmetApp.isWebView();
}
function isMiniApp() {
  return RahmetApp.isWebView();
}
function isMiniAppWithCheckAuth() {
  return (
    RahmetApp.isWebView() && typeof window.RahmetApp?.checkAuth === 'function'
  );
}

function hasTokens() {
  return storage.get('auth::token');
}

const parseOauthResponse = res => {
  return {
    accessToken: res.access_token,
    refreshToken: res.refresh_token,
    expireIn: res.expires_in,
  };
};

const updateAuthContext = res => {
  storage.set('auth::token', res.accessToken);
  storage.set('auth::refresh-token', res.refreshToken);
  storage.set('auth::expire-in', res.expireIn);
  ClientService.updateContext(res);
};

/**
 * Удаление всех токенов юзера
 * и с локального(сервисного) памяти
 * и с LocalStorage
 */
function logout() {
  LOG.event('AuthService logout');
  const logoutRes = {
    accessToken: '',
    refreshToken: '',
    expireIn: '',
  };
  updateAuthContext(logoutRes);
}

/**
 * Имитация истекания аксес токена
 *
 * Токен сам по себе истекает через час, этим методом мы форсированно имитируем этот процес для теста
 */
function expireAccessToken() {
  const currentContext = ClientService.getContext();
  updateAuthContext({
    ...currentContext,
    accessToken: 'aaaexpiredtoken',
  });
}

function logoutWeb() {
  logout();
  WebAuthService.logout();
}

/**
 * Получение токенов через code
 * @param {String} code
 */
const authByCode = async code => {
  const payload = {
    redirectUri: getAuthUrl(),
    code,
  };
  await ClientService.getFingerprint();

  const response = await rAuthByCode(payload);
  const parsedToken = parseOauthResponse(response);
  updateAuthContext(parsedToken);
};

/**
 * Получение токенов по track_id
 * @param {String} trackId
 */
const authByTrackId = async trackId => {
  await ClientService.getFingerprint();

  return new Promise((resolve, reject) => {
    return rGetTokenByTrackId(trackId)
      .then(res => {
        const parsedResponse = parseOauthResponse(res);
        updateAuthContext(parsedResponse);
        return resolve();
      })
      .catch(err => reject(err));
  });
};

/**
 * Получение токенов по refresh_token
 */
const authByRefreshToken = async () => {
  const { refreshToken } = ClientService.getContext();

  if (!(refreshToken && refreshToken !== 'undefined')) {
    return Promise.reject(new Error('no refreshToken '));
  }
  await ClientService.getFingerprint();

  return new Promise((resolve, reject) => {
    return rAuthByRefreshToken({ refreshToken })
      .then(res => {
        const parsedResponse = parseOauthResponse(res);
        updateAuthContext(parsedResponse);
        return resolve(res);
      })
      .catch(err => {
        return reject(err);
      });
  });
};

/**
 * Вызов модалки авторизации нативки
 * И получаем токны по track_id или же по code
 * идет обратная совместимость
 */
const authInMiniApp = () => {
  return MobAuthService.login()
    .then(res => {
      LOG.event('Auth MobAuthService ', res);

      if (res.trackId && res.trackId !== 'trackID') {
        return authByTrackId(res.trackId);
      }
      if (res.code && res.code !== 'code') {
        return authByCode(res.code);
      }
      return Promise.reject(
        new Error('DefaultReject', {
          cause: 'Закрыли модалку авторизации не вводя номер',
        })
      );
    })
    .catch(err => {
      LOG.event('Auth MobAuthService err ', err);
      return Promise.reject(err);
    });
};

/**
 *  Эмитится ивент ON_AUTH после того как юзер авторизовался
 */
function onAuth() {
  eventBus.emit('eb_on_auth');
  return authEmitter.emit(AUTH_EVENTS.ON_AUTH);
}

/**
 * @typedef AuthCTX
 * @prop {String?} trackId  with trackId we can get tokens of Ryadom
 * @prop {String?} code with code we can get tokens of Ryadom
 * @prop {Boolean?} isInit  on app initialization true
 * @prop {Boolean?} isRefresh  when user reconnected to app and has tokens
 * @prop {String?} state redirect back after web authorization
 * @prop {String?} path redirect to this path after web auth
 *
 * @param {AuthCTX} ctx
 * @returns {Promise}
 */
const authorize = (ctx = {}) => {
  const authReject = () => {
    logout();
    return Promise.reject(new Error('DefaultReject'));
  };

  const auth = () => {
    let { refreshToken } = ClientService.getContext();
    LOG.event('AuthService.authorize start', { ...ctx, refreshToken });

    if (DevConfigs.isDev && !DevConfigs.login) {
      LOG.event('Auth reject isDev', ctx);
      return authReject();
    }

    if (isMiniAppWithCheckAuth()) {
      if (ctx.isInit || ctx.isRefresh) {
        const v = window.RahmetApp.checkAuth();
        LOG.event('Auth isMiniAppWithCheckAuth check', {
          ctx,
          'window.RahmetApp.checkAuth()': v,
        });
        return v && v !== 'false' ? authInMiniApp() : authReject();
      } else {
        LOG.event('Auth isMiniAppWithCheckAuth immediate', ctx);
        return authInMiniApp();
      }
    }

    if (ctx.code) {
      LOG.event('Auth code', { ...ctx });
      return authByCode(ctx.code);
    }

    if (ctx.trackId) {
      LOG.event('Auth trackId', { ...ctx });
      return authByTrackId(ctx.trackId);
    }

    if (DevConfigs.isDev && !DevConfigs.loginRefreshToken) refreshToken = null;
    if (
      refreshToken &&
      refreshToken !== 'null' &&
      refreshToken !== 'undefined'
    ) {
      LOG.event('Auth refresh', { refreshToken });
      return authByRefreshToken();
    }

    if (ctx.isRefresh) {
      LOG.event('Auth reject refresh', { ...ctx });
      return authReject();
    }

    if (isWebApp() && ctx.isInit) {
      LOG.event('Auth checkOauth', { ...ctx });
      return WebAuthService.checkOauth().then(res => {
        LOG.event('Auth checkOauth', { res });
        if (res === 'auth') return WebAuthService.login(ctx.path);
        return authReject();
      });
    }

    if (ctx.isInit) {
      LOG.event('Auth reject isInit', { ...ctx });
      return authReject();
    }

    if (isWebApp() || (DevConfigs.isDev && DevConfigs.login)) {
      LOG.event('Auth desktop', { ...ctx });
      WebAuthService.login(ctx.path);
      return authReject();
    }

    if (isMiniApp()) {
      LOG.event('Auth miniapp', { ...ctx });
      return authInMiniApp();
    }

    LOG.event('Auth reject last', { ...ctx });
    return authReject();
  };

  return auth()
    .then(() => {
      return store.commit('SET_AUTH_STATE', true);
    })
    .then(() => {
      onAuth();
    })
    .catch(err => {
      LOG.event('Auth authorize reject', { err });
      store.commit('SET_AUTH_STATE', false);
      logAuthError(err);
      return authReject();
    });
};

export const AuthService = {
  parseOauthStateRoute,
  expireAccessToken,
  logoutWeb,
  authorize,
  hasTokens,
  logout,
};
