/* eslint-disable */
import { log, els, calculate } from './utils';

function isVisible(el) {
  if (el && el.getBoundingClientRect) {
    const rect = el.getBoundingClientRect();
    return rect.height > 0 || rect.width > 0;
  }
  return false;
}

function addOrRemove(el, binding) {
  log('!!! floating-element [resizeObserver]', binding.value);

  if (isVisible(el) && !els[binding.value]) {
    els[binding.value] = el;
    calculate();
    return;
  }

  if (!isVisible(el) && els[binding.value]) {
    delete els[binding.value];
    calculate();
    return;
  }
}

export default {
  bind(el, binding) {
    log('!!! floating-element [bind]', binding, this);
    el.resizeObserver = new ResizeObserver(addOrRemove.bind(this, el, binding));
    el.resizeObserver.observe(el);
  },
  unbind(el, binding) {
    log('!!! floating-element [unbind]', binding.value, el);

    if (el.resizeObserver) {
      el.resizeObserver.disconnect();
      delete el.resizeObserver;
    }

    delete els[binding.value];
    calculate();
  },
};
