<template>
  <div class="MmFormModal">
    <button
      class="MmFormModal__btnClose focus:outline-none"
      @click="$emit('onClose')"
    >
      <img :src="closeIcon" alt="" width="40" height="40" />
    </button>
    <MmForm @onDone="$emit('onClose')" />
  </div>
</template>

<script>
import MmForm from '@/views/micromarket/components/MmForm/MmForm.vue';

export default {
  name: 'MmFormModal',
  components: { MmForm },
  inject: [],
  props: {},
  data: () => ({
    closeIcon: new URL('./icons/close.svg', import.meta.url).href,
  }),

  i18n: {
    messages: {
      kk: {},
      ru: {},
    },
  },
};
</script>

<style scoped>
.MmFormModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f1f2f7;
  z-index: 10;
  overscroll-behavior: none;
  overflow: scroll;
}

.MmFormModal__btnClose {
  position: absolute;
  top: 12px;
  right: 12px;
}
</style>
