<template>
  <div class="AddressConfirmationPopoverWrapper">
    <AddressConfirmationPopover
      v-if="presumptiveAddress && !$isDesktop"
      v-bind="{ presumptiveAddress }"
      v-on="{ onConfirm, onAnotherAddress }"
    />
  </div>
</template>

<script>
import AddressConfirmationPopover from '@pure-ui/components/Address/AddressConfirmationPopover/AddressConfirmationPopover.vue';
import { getHouseAddress } from '@services/address/utils';

export default {
  name: 'AddressConfirmationPopoverWrapper',
  components: { AddressConfirmationPopover },
  inject: ['bottomSheet'],
  data: () => ({
    isVisible: true,
  }),
  computed: {
    presumptiveAddress() {
      const addr = this.$store.getters['user/presumptiveAddress'];
      return addr ? getHouseAddress(addr, this.$i18n.locale) : null;
    },
  },
  methods: {
    onConfirm() {
      this.$store.commit('user/SET_PRESUMPTIVE_ADDRESS', null);
    },
    onAnotherAddress() {
      this.$store.commit('user/SET_PRESUMPTIVE_ADDRESS', null);
      this.$eventBus.emit('eb_open_address_list', {
        from: 'address_confirmation_popover',
      });
    },
  },
};
</script>

<style scoped>
.AddressConfirmationPopoverWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
}
</style>
