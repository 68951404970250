<template>
  <div class="CancelOrderWrapper">
    <CancelOrder
      ref="cancelOrderRef"
      :reasons="reasons"
      @onCancel="onCancel('confirmation')"
      @onConfirm="onConfirm"
      @onSubmitReason="onSubmitReason"
    />
    <UiFullScreenLoader v-if="loading" />
  </div>
</template>

<script>
import {
  rCancelOrder,
  rCancelOrderSetReason,
} from '@shared/services/api/requestsV2/cancel-order';
import { mapActions } from 'vuex';
import { CANCEL_REASONS } from '@services/orders/constants';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

import CancelOrder from '@pure-ui/components/Orders/CancelOrder/CancelOrder.vue';
import UiFullScreenLoader from '@ui/UiFullScreenLoader.vue';

export default {
  name: 'CancelOrderWrapper',
  components: {
    UiFullScreenLoader,
    CancelOrder,
  },
  inject: ['toast'],
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'order',
      // default: 'mini'
      // default: 'support-center'
    },
  },
  data() {
    return {
      loading: false,
      show: false,
      reasons: CANCEL_REASONS,
    };
  },
  methods: {
    ...mapActions('cart', {
      fetchCart: 'FETCH_CART',
    }),
    logAnalytics(step, payload = {}) {
      Analitycs.logEvent(EVENTS.CANCEL_ORDER, {
        step,
        is_mini: this.type === 'mini',
        view: this.type,
        ...payload,
      });
    },
    closeModals() {
      this.$refs.cancelOrderRef.closeModals();
    },
    openModal() {
      this.logAnalytics(1);
      this.$refs.cancelOrderRef.openConfirmModal();
    },
    onCancel(from) {
      this.logAnalytics(5, { from });
    },
    onConfirm() {
      this.loading = true;
      rCancelOrder(this.id)
        .then(() => {
          this.logAnalytics(2);
        })
        .catch(err => {
          this.closeModals();
          this.toast.show(err.message);
        })
        .finally(() => (this.loading = false));
    },
    onSubmitReason(data) {
      this.loading = true;
      const payload = {
        ...data.selected,
      };
      if (data.comment) {
        payload.reason = `${payload.reason}: \n ${data.comment}`;
      }

      rCancelOrderSetReason(this.id, payload)
        .then(() => {
          this.$emit('onOrderCancelled');
          this.logAnalytics(4, payload);
          setTimeout(() => this.fetchCart(), 1000);
        })
        .catch(err => {
          this.closeModals();
          this.toast.show(err.message);
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
