const READY_MEAL_ANALYTICAL_CATEGORY_IDS = [11, 14, 15, 16, 18, 19];
const READY_MEAL_CATEGORY_IDS = [451];
const APPLIANCE_PRODUCT_ID = 11700;

export function isApplianceCategory(id) {
  return (
    READY_MEAL_ANALYTICAL_CATEGORY_IDS.includes(id) ||
    READY_MEAL_CATEGORY_IDS.includes(id)
  );
}
export function isAppliance(id) {
  return id === APPLIANCE_PRODUCT_ID;
}
