<template>
  <UiPopup class="font-sirius" name="confirm-close-cart" with-title size="xs">
    <template #hide-description>&nbsp;</template>
    <p class="mx-auto line-clamp-1 text-xl font-bold pl-4">
      <span v-html="$t('goToChoco')" />
    </p>
    <div class="p-4">
      <span v-html="$t('youHaveProductsAreYouSure')" />
    </div>
    <div class="flex gap-2 p-4 pt-2">
      <UiButtonNew
        variant="gray"
        size="lg"
        class="font-bold w-full"
        @click="$emit('close')"
      >
        {{ $t('toStay') }}
      </UiButtonNew>
      <UiButtonNew size="lg" class="font-bold w-full" @click="$emit('confirm')">
        {{ $t('toQuit') }}
      </UiButtonNew>
    </div>
  </UiPopup>
</template>

<script>
import UiPopup from '@shared/components/ui/ui-popup/UiPopup.vue';
import UiButtonNew from '@shared/components/ui/UiButtonNew.vue';

export default {
  name: 'ConfirmClose',
  components: {
    UiPopup,
    UiButtonNew,
  },
  inject: [],
  i18n: {
    messages: {
      ru: {
        goToChoco: 'Перейти в Choco?',
        youHaveProductsAreYouSure: `
          У вас остались товары в&nbsp;корзине,
          хотите выйти из&nbsp;корзины и&nbsp;перейти в&nbsp;Choco?
        `,
        toStay: 'Остаться',
        toQuit: 'Перейти',
      },
      kk: {
        goToChoco: 'Choco-ға өту',
        youHaveProductsAreYouSure: `
          Себетіңізде тауарлар қалып қойды, себеттен шығып, Choco-ға өткіңіз келе ме?
        `,
        toStay: 'Қалу',
        toQuit: 'Өту',
      },
    },
  },
};
</script>
