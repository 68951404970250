import { Logger } from '@shared/services/logger';
import { fetchProducts } from './utils';

export const ShoppingHistoryModule = {
  namespaced: true,
  state: {
    lastPurchasedProducts: [],
    logId: '',
  },
  getters: {
    lastPurchasedProducts: state => {
      return state.lastPurchasedProducts;
    },
    logId: state => {
      return state.logId;
    },
  },
  mutations: {
    SET_LAST_PURCHASED_PRODUCTS(state, value) {
      state.lastPurchasedProducts = value;
    },
    SET_LOG_ID(state, value) {
      state.logId = value;
    },
  },
  actions: {
    FETCH_LAST_PURCHASED_PRODUCTS({ commit, rootGetters }) {
      const warehouseId = rootGetters['delivery/warehouseId'];
      return fetchProducts({
        limit: 100,
        sorting: 'last_shopped_at:desc',
        warehouseId,
      }).then(data => {
        Logger.event('SET_LAST_PURCHASED_PRODUCTS', {
          data: data.data.items,
        });
        commit('SET_LAST_PURCHASED_PRODUCTS', data.data.items);
        commit('SET_LOG_ID', data.data.logId);
      });
    },
  },
};
