<template>
  <BaseCustomerAddress
    :class="`${!save ? 'items-center' : ''}`"
    :title="title"
    :description="description"
    :active="active"
    @click.native="$emit('onSelect')"
  >
    <template #left>
      <AddressCheckBox v-if="!save" :active="active" />
      <IconSave v-else />
    </template>

    <template v-if="!save" #right>
      <IconDots class="address__control" @click.native="$emit('onDots')" />
    </template>
  </BaseCustomerAddress>
</template>

<script>
import AddressCheckBox from './components/AddressCheckBox.vue';
import BaseCustomerAddress from './components/BaseCustomerAddress.vue';
import IconDots from './components/IconDots.vue';
import IconSave from './components/IconSave.vue';

export default {
  name: 'CustomerAddress',
  components: {
    BaseCustomerAddress,
    AddressCheckBox,
    IconDots,
    IconSave,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    save: Boolean,
  },
};
</script>

<style scoped></style>
