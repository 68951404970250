<template>
  <div class="DescriptionText">
    <p>Удаление аккаунта в приложении Даркстор и других проектов ChocoFamily</p>
    <ul>
      <li class="DescriptionText__li">
        — После удаления аккаунта вы потеряете доступ к аккаунту в приложении
        Даркстор и также потеряете доступ к другим проектам ChocoFamily
      </li>
      <li class="DescriptionText__li">
        — У вас будет 90 дней для восстановления аккаунта. Для восстановления
        нужно будет авторизоваться в одном из проектов ChocoFamily.
      </li>
      <li class="DescriptionText__li">
        — По истечению 90 дней ваши данные будут удалены безвозвратно
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DescriptionText',
  components: {},
  inject: [],
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.DescriptionText {
  font-size: 14px;
  color: #7b7a8c;
}

.DescriptionText__li {
  padding-top: 8px;
}
</style>
