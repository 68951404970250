export const HeavyOrderModule = {
  namespaced: true,
  state: {
    maxWeight: 15000,
  },
  getters: {
    maxWeight(state) {
      return state.maxWeight;
    },
  },
};
