/**
 * Wed Dec 20 2023 13:49:16 GMT+0600 (East Kazakhstan Time)
 * Заменяет в строке текущей даты время
 * на указанное в time и возвращает новую Date
 * /lavka/v1/warehouses/get_by_coordinate
 * /lavka/v1/authorized/warehouses/get_by_coordinate
 * @param {String} time — 00:00:00 — приходит c эндпоинтов выше
 * @returns {Date}
 */
export const replaceTime = time => {
  let date = new Date().toString();
  date = date.split(' ');
  date.splice(4, 1, time);
  date = date.join(' ');
  date = new Date(date);
  return date;
};
