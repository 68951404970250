<template>
  <transition name="popup-fade">
    <div
      v-if="popup.state.activeName === name"
      v-click-outside="popup.hide"
      class="popup-dialog"
      :class="`${sizes[size]} ${absolute ? '--absolute' : ''}`"
      @click.stop
    >
      <div
        class="popup-dialog-content"
        :class="hasScrollbar ? 'desk-scrollbar pr-4' : ''"
      >
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'UiToolPopup',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  inject: ['popup'],
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    hasScrollbar: {
      type: Boolean,
      default: true,
    },
    absolute: Boolean,
  },
  data() {
    return {
      sizes: {
        md: '--md',
        lg: '--lg',
      },
    };
  },
  /*
  видимо на router-link вызывается preventDefault,
  поэтому попап не закрывается при переходе по ссылкам.

  По идее его теперь можно удалить
  */
  mounted() {
    document.documentElement.addEventListener('click', this.hide, true);
  },
  beforeDestroy() {
    document.documentElement.removeEventListener('click', this.hide, true);
  },
  methods: {
    hide(event) {
      const isClickOutside =
        event.target !== this.$el && !this.$el.contains(event.target);
      if (isClickOutside && this.popup.state.activeName === this.name) {
        this.popup.hide();
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.popup-fade {
  &-enter,
  &-leave-to {
    @apply opacity-0;
  }

  &-enter-active {
    @apply ease-in duration-200;
  }

  &-leave-active {
    @apply ease-in duration-200 delay-75;
  }

  &-enter-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 100ms,
      transform ease-in-out 75ms 100ms;
  }

  &-leave-active > .popup-dialog {
    transition:
      opacity ease-in 75ms 50ms,
      transform ease-in-out 100ms;
  }

  &-enter > .popup-dialog,
  &-leave-to > .popup-dialog {
    @apply opacity-0 transform scale-75;
  }
}
.popup-dialog {
  @apply z-modal bg-white overflow-hidden;
  position: var(--modal-position, fixed);
  min-width: var(--modal-min-width, unset);
  max-width: var(--modal-max-width, 100%);
  width: var(--modal-width, 100%);
  transform: translate(
    var(--modal-translateX, 0px),
    var(--modal-translateY, 0px)
  );
  box-shadow:
    0px 92px 92px rgba(0, 0, 0, 0.05),
    0px 3px 38.4354px rgba(0, 0, 0, 0.0334858),
    0px -3px 20.5494px rgba(0, 0, 0, 0.06),
    0px 5.79369px 11.5198px rgba(0, 0, 0, 0.0189792),
    0px 2.40368px 6.11809px rgba(0, 0, 0, 0.0132742),
    0px 0.705169px 2.54588px rgba(0, 0, 0, 0.00743532);

  &.--md {
    border-radius: 24px;
  }
  &.--lg {
    border-radius: 32px;
  }

  &-content {
    @apply overflow-y-auto;
    max-height: 80vh;
  }
}

.--absolute {
  position: absolute;
  min-width: var(--modal-width, 100%);
}
</style>
