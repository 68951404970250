<template>
  <UiPopup class="address-confirm-popup" name="address-confirm-popup">
    <template #hide-description><div></div> </template>
    <div class="pt-4 px-4 pb-5">
      <p class="pt-1">
        {{ $t('deliveringAtAddress') }}
      </p>
      <p class="address-confirm-popup__text pt-1 font-bold text-lg truncate">
        {{ address }}
      </p>

      <div class="mt-4 flex items-center">
        <UiButton
          class="w-full font-lg font-bold mr-1"
          variant="gray"
          size="md"
          @click="$emit('change')"
        >
          {{ $t('toChange') }}
        </UiButton>
        <UiButton
          class="w-full font-lg font-bold ml-1"
          size="md"
          @click="$emit('confirm')"
        >
          {{ $t('allIsCorrect') }}
        </UiButton>
      </div>
    </div>
  </UiPopup>
</template>

<script>
import UiPopup from '@ui/ui-popup/UiPopup.vue';
import UiButton from '@ui/UiButtonNew.vue';
// import BottomSheetPopup from '@shared/components/ui/bottom-sheet-popup/BottomSheetPopup.vue';

export default {
  name: 'AddressConfirmPopup',
  components: {
    UiButton,
    UiPopup,
    // BottomSheetPopup,
  },
  computed: {
    address() {
      const addr = this.$store.getters['user/deliveryAddress'];
      if (!addr) return '';
      let res = '';
      if (addr.name) {
        res = `${addr.name}, `;
      }
      res += addr.street;
      return res;
    },
  },
  i18n: {
    messages: {
      ru: {
        deliveringAtAddress: 'Везём заказ по адресу:',
        toChange: 'Изменить',
        allIsCorrect: 'Всё верно',
      },
      kk: {
        deliveringAtAddress: 'Сіздің мекенжайыңыз:',
        toChange: 'Өзгерту',
        allIsCorrect: 'Бәрі дұрыс',
      },
    },
  },
};
</script>

<style scoped>
.address-confirm-popup__text {
  width: calc(100vw - 64px);
}

@media (min-width: 768px) {
  .address-confirm-popup__text {
    width: 300px;
  }
}
</style>
