import { createStorage } from '@shared/services/storage';
import { parseToken } from '@shared/utils/parse-token';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { RahmetApp } from '@shared/RahmetApp';

const storage = createStorage(window.localStorage);

function getInitRefresh() {
  if (RahmetApp.isWebView()) return undefined;
  return storage.get('auth::refresh-token')
    ? storage.get('auth::refresh-token')
    : undefined;
}

const clientContext = {
  deviceId: undefined,
  sessionId: undefined,
  platform: undefined,
  fingerprint: '',
  v: undefined,

  accessToken: import.meta.env.DEV ? storage.get('auth::token') : undefined,
  refreshToken: getInitRefresh(),
  expireIn: import.meta.env.DEV ? storage.get('auth::expire-in') : undefined,
};

const updateContext = newContext => {
  Object.assign(clientContext, newContext);
};
const getContext = () => {
  return clientContext;
};

function getFingerprint() {
  return new Promise(resolve => {
    let { fingerprint } = getContext();
    if (fingerprint) resolve(fingerprint);

    FingerprintJS.load()
      .then(res => res.get())
      .then(res => {
        fingerprint = res.visitorId;
        updateContext({ fingerprint });
        resolve(fingerprint);
      })
      .catch(() => {
        resolve(fingerprint);
      });
  });
}

const getUserId = () => {
  let userId = -1;
  let jwt = null;
  const { accessToken } = getContext();
  if (accessToken) jwt = parseToken(accessToken);
  if (jwt?.sub) userId = jwt.sub;
  return userId;
};

export const ClientService = {
  getFingerprint,
  updateContext,
  getContext,
  getUserId,
};
