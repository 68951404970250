<template>
  <div class="ReferralPlankDesk plank">
    <div class="container plank-content">
      <p class="plank-title" v-html="$t('msg')" />
      <UiButton v-if="$isDesktop" class="plank-btn" size="M" variant="white">
        <span class="min-w-2"></span> {{ $t('more') }} <IconArrow />
      </UiButton>
      <img
        v-if="$isDesktop"
        src="./images/hands-desk.png"
        alt=""
        class="plank-hands"
      />
      <img
        v-else
        src="./images/hands-mob.png"
        alt=""
        class="plank-hands --mob"
      />
    </div>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import IconArrow from './components/IconArrow.vue';

export default {
  components: { IconArrow, UiButton },
  i18n: {
    messages: {
      kk: {
        msg: 'Достарыңызға 1500 ₸ сыйлаңыз, 1500 ₸ ұтыңыз',
        more: 'Толығырақ',
      },
      ru: {
        msg: 'Дарите друзьям 1500 ₸ — получайте 1500 ₸',
        more: 'Подробнее',
      },
    },
  },
};
</script>

<style scoped>
.plank {
  @apply cursor-pointer;

  height: 88px;
  min-height: 88px;
  max-height: 88px;
  background: linear-gradient(
    317.86deg,
    #f7ecff -32.81%,
    #ffeeec 43.39%,
    #fffaee 114.71%
  );
}
.plank-content {
  @apply relative h-full flex items-center justify-between;
}
.plank-title {
  @apply font-bold md:text-xl leading-5 md:leading-6;
}
.plank-btn {
  max-width: 186px;
}
.plank-hands {
  @apply object-contain absolute top-0 right-28;
  height: 88px;
}
.plank-hands.--mob {
  @apply right-0;
  height: 60px;
}

@media (max-width: 768px) {
  .plank {
    padding-left: 16px;
    height: 60px;
    min-height: 60px;
    max-height: 60px;
  }
  .plank-title {
    max-width: 212px;
  }
}
</style>
