<template>
  <section class="MmNumbers">
    <MmSubtitle> <span v-html="$t('title')" /> </MmSubtitle>
    <ul class="MmNumbers__list">
      <li
        v-for="item in items"
        :key="item[$i18n.locale].number"
        class="MmNumbers__item"
      >
        <div class="MmNumbers__top">
          <span class="MmNumbers__number" v-html="item[$i18n.locale].number" />
          <span class="MmNumbers__descr" v-html="item[$i18n.locale].descr" />
        </div>
        <div class="MmNumbers__text" v-html="item[$i18n.locale].text" />
      </li>
    </ul>
  </section>
</template>

<script>
import MmSubtitle from '@/views/micromarket/components/MmSubtitle/MmSubtitle.vue';

export default {
  name: 'MmNumbers',
  components: { MmSubtitle },
  inject: [],
  props: {},
  data: () => ({
    items: [
      {
        ru: {
          number: '160+',
          descr: 'позиций',
          text: 'в&nbsp;меню с&nbsp;готовой едой и&nbsp;напитками',
        },
        kk: {
          number: '160+',
          descr: 'позицияны',
          text: 'дайын тағам мен сусындар мәзірінен таба аласыз',
        },
      },
      {
        ru: {
          number: '24/7',
          descr: 'время доступа',
          text: 'холодильника так&nbsp;что перекусить можно всегда',
        },
        kk: {
          number: '24/7',
          descr: 'қолжетімділік',
          text: 'әрқашан жүрек жалғап алуға мүмкіндік береді',
        },
      },
      {
        ru: {
          number: '30',
          descr: 'секунд',
          text: 'потребуется для&nbsp;выбора и&nbsp;оплаты продуктов',
        },
        kk: {
          number: '30',
          descr: 'секунд',
          text: 'керегіңді таңдап, төлеуге кететін уақыт',
        },
      },
      {
        ru: {
          number: '1',
          descr: 'квадратный метр',
          text: 'занимает площадь без&nbsp;отдельного помещения',
        },
        kk: {
          number: '1',
          descr: 'шаршы метр',
          text: 'осынша ғана орын алады',
        },
      },
    ],
  }),

  i18n: {
    messages: {
      kk: {
        title: 'Сандар сөйлейді',
      },
      ru: {
        title: 'Микромаркет в&nbsp;цифрах',
      },
    },
  },
};
</script>

<style scoped>
.MmNumbers {
  padding-top: 84px;
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.MmNumbers__list {
  padding-top: 24px;
  display: grid;
  gap: 16px;
}

.MmNumbers__item {
  border-radius: 16px;
  padding: 16px 20px 20px;
  background: linear-gradient(
      141deg,
      rgba(255, 179, 64, 0.2) -8.67%,
      rgba(255, 138, 72, 0.2) 106.38%
    ),
    #fff;
}

.MmNumbers__top {
  display: flex;
  align-items: center;
}

.MmNumbers__number {
  color: #ffa100;
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
}

.MmNumbers__descr {
  margin-left: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #7b7a8c;
}

.MmNumbers__text {
  padding-top: 4px;
  font-size: 14px;
  line-height: 18px;
}

@media (min-width: 768px) {
  .MmNumbers {
    max-width: 988px;
    padding-top: 165px;
  }

  .MmNumbers__list {
    padding-top: 48px;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
  }

  .MmNumbers__item {
    padding: 32px 20px 44px;
    border-radius: 24px;
  }

  .MmNumbers__top {
    flex-direction: column;
    align-items: flex-start;
  }

  .MmNumbers__number {
    display: block;
    font-size: 48px;
    line-height: 56px;
  }

  .MmNumbers__descr {
    font-size: 20px;
    line-height: 24px;
    margin-left: 0;
    white-space: nowrap;
  }

  .MmNumbers__text {
    padding-top: 20px;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
