<template>
  <div class="ProductReviewReminder reminder">
    <p
      class="reminder-title"
      v-html="imgs.length > 1 ? $t('title') : $t('titleForOne')"
    ></p>
    <div class="reminder-imgs" :style="imgsWidth">
      <div
        v-for="(img, index) in imgs"
        :key="index"
        class="reminder-imgs-wrap"
        :style="`width: ${100 / imgs.length}%`"
      >
        <div class="reminder-imgs-item" :style="`z-index: ${5 - index}`">
          <img
            class="reminder-imgs-item-img"
            :src="img"
            alt="product-img"
            width="48"
            height="48"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductReviewReminder',
  props: {
    imgs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    imgsWidth() {
      if (this.imgs.length === 3) return `width: 88px; padding: 0 15px;`;
      if (this.imgs.length === 2) return `width: 84px; padding: 0 6px;`;
      return `width: 48px; padding: 0`;
    },
  },
  methods: {},
  i18n: {
    messages: {
      kk: {
        title: 'Бұрын, осы заттарды<br/> алған едіңіз, сізге ұнады ма?',
        titleForOne: 'Бұрын, осы затты<br/> алған едіңіз, сізге ұнады ма?',
      },
      ru: {
        title: 'Ранее вы пробовали эти<br/> товары, они вам понравились?',
        titleForOne: 'Ранее вы пробовали этот<br/> товар, он вам понравился?',
      },
    },
  },
};
</script>

<style scoped>
.reminder {
  @apply w-full py-2 pr-3 pl-4
    rounded-2xl
    flex items-center justify-between space-x-2;

  box-shadow:
    0px 0.8255634307861328px 2.9805376529693604px 0px #00000003,
    0px 2.814065456390381px 7.162646293640137px 0px #00000005,
    0px 6.7828521728515625px 13.486627578735352px 0px #00000001,
    0px -3.512195110321045px 24.057838439941406px 0px #00000008,
    0px 3.512195110321045px 44.99754333496094px 0px #0000000a,
    0px 107.70731353759766px 107.70731353759766px 0px #00000008;
}
.reminder-title {
  @apply text-sm leading-18;
}
.reminder-imgs {
  @apply w-full flex items-center justify-end;
  max-width: 88px;
}
.reminder-imgs-wrap {
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
}
.reminder-imgs-item {
  @apply w-12 h-12 relative block;
  flex-shrink: 0;
}
.reminder-imgs-item-img {
  @apply w-full h-full
  border border-sirius-gray-1000 rounded-full bg-sirius-gray-200;
  mix-blend-mode: multiply;
}
</style>
