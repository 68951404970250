<template>
  <div class="CommercialBanners">
    <Slider
      :items="visibleBanners"
      :desktop-max-per-screen="desktopMaxPerScreen"
    >
      <div class="CommercialBanners__container">
        <div
          v-for="item in visibleBanners"
          :key="item.code"
          class="CommercialBanners__item"
        >
          <Banner
            v-if="item.type === 'banner'"
            v-bind="item"
            @click="$emit('onBannerClick', item)"
          />
          <Story
            v-if="item.type === 'story'"
            v-bind="item"
            @click="onStoryClick(item)"
          />
        </div>
      </div>
    </Slider>
    <InstaStories
      ref="stories"
      v-bind="{
        stories: visibleBanners,
        hideButtonIfNoUrl: true,
        showButtonOnEachSlide,
        buttonText: $t('goToCategory'),
      }"
      @onClose="$emit('onStoriesClose', $event)"
      @onStoryEnd="$emit('onStoryEnd', $event)"
      @onCategoryButtonClick="$emit('onCategoryButtonClick', $event)"
    />
  </div>
</template>

<script>
import InstaStories from '@pure-ui/components/InstaStories/InstaStories.vue';

import Story from './components/Story.vue';
import Banner from './components/Banner.vue';
import Slider from './components/Slider.vue';

export default {
  name: 'CommercialBanners',
  components: {
    InstaStories,
    Story,
    Banner,
    Slider,
  },
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
    desktopMaxPerScreen: {
      type: Number,
      default: 5,
    },
    showButtonOnEachSlide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    visibleBanners() {
      return [...this.banners].sort((a, b) => {
        if (a.watched && !b.watched && !b.fixedPosition) {
          return 1;
        }
        if (!a.watched && b.watched && !b.fixedPosition) {
          return -1;
        }
        return 0;
      });
    },
  },
  methods: {
    onStoryClick(item) {
      this.$emit('onStoryClick', item);
      this.$refs.stories.show(item.code);
    },
    show(...rest) {
      this.$refs.stories.show(...rest);
    },
  },
  i18n: {
    messages: {
      ru: {
        goToCategory: 'Перейти в категорию',
      },
      kk: {
        goToCategory: 'Санатқа өту',
      },
    },
  },
};
</script>

<style>
.CommercialBanners {
  --between: 8px;
  --height: 132px;
  --height-desktop: 172px;
  --x-padding: 16px;
}

.CommercialBanners__container {
  border-left: var(--x-padding) solid transparent;
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  gap: var(--between);
}

.CommercialBanners__container::after {
  content: '';
  display: block;
  min-width: calc(var(--x-padding) - var(--between));
}

.CommercialBanners__item {
  height: var(--height);
}

@media (min-width: 768px) {
  .CommercialBanners {
    --between: 12px;
    --height: 172px;
    width: 100%;
    max-width: 988px;
    margin: auto;
  }

  .CommercialBanners__container {
    border-left: 0;
  }

  .CommercialBanners__container::after {
    content: normal;
  }
}
</style>
