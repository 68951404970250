/*
  Допускаем, что сервисы могут ссылаться друг на друга.
  Только сервисы
*/

import { store } from '@/store';
import { api } from '@shared/services/api';
import { PromocodesService } from '@services/promocodes';
import { DeliveryConfigService } from '@services/delivery-config';
import { rApplyProducts } from '@shared/services/api/apis';
import { Logger as LOG } from '@shared/services/logger';
import { eventBus } from '@/utils/plugins/event-bus';
import { CART_EVENTS, CartEmitter } from './events';
import { removeCoffeeFromCart } from './lib/remove-coffee-from-cart';

function init() {
  eventBus.on('eb_on_address_change', removeCoffeeFromCart);
  eventBus.on('eb_init_page_load_done', removeCoffeeFromCart);
  eventBus.on('eb_on_address_update', removeCoffeeFromCart);
}

/**
 * Проверяет что промокод может быть применён
 * @param {string} promocode
 * @returns {Promise}
 */
function applyPromocode(promocode) {
  // console.trace('applyPromocode', promocode);
  const wId = store.getters['delivery/warehouseId'];
  const abGroups = store.getters['abtest/testGroups'];
  const isDeliveryFreeReached =
    store.getters['paidOrder/isDeliveryFreeReached'];

  // Если условия изменились, может отправиться
  // даже если не должен — поэтому сбрасываем
  store.commit('cart/SET_PROMOCODE', '');

  return new Promise((resolve, reject) => {
    api.lavka
      .applyPromocode(wId, promocode, abGroups)
      .then(r => {
        // если промокод на бесп. доставку, но условия по ней
        // уже выполнены — не применяем промокод
        if (
          !(r.data?.cartPromocodeData?.freeDelivery && isDeliveryFreeReached)
        ) {
          LOG.event('applyPromocode', {
            'r.data': r.data,
            isDeliveryFreeReached,
          });
          store.commit('cart/SET_PROMOCODE', promocode);
          store.commit('cart/UPDATE_CART', r.data);
          DeliveryConfigService.updateIsPaymentConfigShown();
          PromocodesService.setAppliedPromocode(promocode);
        }
        resolve(r);
      })
      .catch(err => {
        if (err.errorData?.conditions_failed) {
          api.lavka.fetchCart(wId, abGroups).then(data => {
            store.commit('cart/UPDATE_CART', data.data);
            reject(err);
          });
        } else {
          reject(err);
        }
      });
  });
}

/**
 * Удаляет применённый промокод и пересчитывает корзину
 */
function resetPromocode() {
  store.commit('cart/SET_PROMOCODE', '');
  store.dispatch('cart/FETCH_CART');
  PromocodesService.resetAppliedPromocodes();
}

/**
 * @returns {string} промокод
 */
function getAppliedPromocode() {
  return store.getters['cart/promocode'];
}

function applyCurrentPromocode() {
  applyPromocode(getAppliedPromocode());
}

function applyProducts(products) {
  const warehouseId = store.getters['delivery/warehouseId'];
  const abGroups = store.getters['abtest/testGroups'];
  return rApplyProducts(products, warehouseId, abGroups).then(({ data }) => {
    store.commit('cart/UPDATE_CART', data);
    CartEmitter.emit(CART_EVENTS.ON_CART_CHANGED);
  });
}

export const CartService = {
  init,
  applyPromocode,
  resetPromocode,
  applyCurrentPromocode,
  applyProducts,
  removeCoffeeFromCart,
};
