<template>
  <div class="UiAspectRatio relative" :style="style" v-on="$listeners">
    <div class="absolute inset-0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiAspectRatio',
  props: {
    ratio: {
      type: String,
      required: true,
    },
  },
  computed: {
    style() {
      const paddingTop = this.ratio
        .split(':')
        .map(Number)
        .reduce((x, y) => x / y);

      return {
        paddingTop: `calc(${(paddingTop < 1 ? 0.48 : paddingTop) * 100}%)`,
      };
    },
  },
};
</script>
