export const ProductReviewModule = {
  namespaced: true,
  state: {
    reviewsData: [],
  },
  getters: {
    /**
     * Фильтрирует все отзывы которые еще не были задействованы
     *
     * и те что с флагом isSkipped не должны показываться на этой сессий
     */
    filteredReviews(state) {
      return [...state.reviewsData].filter(r => !r.review && !r.isSkipped);
    },
    /**
     * Возвращяет список продуктов по которым доступно оставить отзыв.
     *
     * Максимум 5
     */
    reviewProducts(state, getters, rootState, rootGetters) {
      let filteredReviews = [...getters.filteredReviews].splice(0, 5);

      return filteredReviews.map(
        r => rootGetters['productDB/products'][`id_${r.product_id}`]
      );
    },
    /**
     * Возвращяет массив отзывов которые пришли с бека
     * или были как то обновлены пользователем
     *
     * Мы все ответы должны сохранять
     * @param {*} state
     * @returns
     */
    reviewsData(state) {
      return state.reviewsData;
    },
    hasReviewProducts(state, getters) {
      return !!getters.filteredReviews.length;
    },
    /**
     * Нужен для того что б показывать дополнительный экран если есть больше 5 товаров на оценку
     */
    hasMore(state, getters) {
      return getters.filteredReviews.length > 5;
    },
  },
  mutations: {
    SET_REVIEWS_DATA(state, value) {
      state.reviewsData = value;
    },
  },
};
