export function getProductInfoList(product) {
  let nuts = product.nutritionals || {};
  let list = [
    product.brand_name && { title: 'brand', value: product.brand_name },
    product.country && { title: 'country', value: product.country },

    nuts.calories && { title: 'calories', value: nuts.calories },
    nuts.proteins && { title: 'proteins', value: nuts.proteins },
    nuts.fats && { title: 'fats', value: nuts.fats },
    nuts.carbohydrates && { title: 'carbohydrates', value: nuts.carbohydrates },
    nuts.vitamins && { title: 'vitamins', value: nuts.vitamins },

    product.wine_color && { title: 'color', value: product.wine_color },
    product.wine_sugar_content && {
      title: 'sugar',
      value: product.wine_sugar_content,
    },
    product.degree_value && { title: 'strength', value: product.degree_value },
  ];
  return list.filter(v => !!v);
}
