<template>
  <div class="block bg-sirius-gray-300 mr-2 mb-2 overflow-hidden relative">
    <button
      class="absolute right-2 top-2 focus:outline-none active:opacity-75"
      @click="onDeleteButton"
    >
      <div class="w-6 h-6">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="11.5"
            fill="#A4A2B7"
          />
          <path
            d="M16.8981 7.89805L16.1021 7.10205L12.0001 11.2041L7.89805 7.10205L7.10205 7.89805L11.2041 12.0001L7.10205 16.1021L7.89805 16.8981L12.0001 12.7961L16.1021 16.8981L16.8981 16.1021L12.7961 12.0001L16.8981 7.89805Z"
            fill="white"
          />
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="11.5"
            stroke="white"
          />
        </svg>
      </div>
    </button>
    <img :src="src" class="w-full h-full object-cover rounded" />
  </div>
</template>

<script>
export default {
  name: 'FileBlock',
  props: {
    src: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    onDeleteButton() {
      this.$emit('onClick', this.index);
    },
  },
};
</script>

<style lang="postcss"></style>
