<template>
  <div class="DesktopCross" v-on="$listeners">
    <svg
      width="62"
      height="62"
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_130_9117)">
        <path
          d="M20.667 20.6667L41.3337 41.3334"
          stroke="currentColor"
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M41.3337 20.6667L20.667 41.3334"
          stroke="currentColor"
          stroke-width="3.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_130_9117">
          <rect width="62" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DesktopCross',
  components: {},
  inject: [],
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.DesktopCross {
  cursor: pointer;
  color: #d4d3df;
  transition: all 0.3s;
}

.DesktopCross:hover {
  color: #fff;
}
</style>
