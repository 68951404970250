export const CategoryNpsModule = {
  namespaced: true,
  state: {
    surveys: [],
  },
  getters: {
    surveys(state, getters, rootState) {
      const result = [];
      state.surveys.forEach(survey => {
        result.push({
          surveyId: survey.id,
          categoryId: survey.category_id,
          categoryName: rootState.catalog.categories?.find(v => {
            return v.id === survey.category_id;
          })?.name,
        });
      });
      return result;
    },
  },
  mutations: {
    SET_SURVEYS(state, value) {
      state.surveys = value;
    },
    REMOVE_SURVEY(state, surveyId) {
      state.surveys = state.surveys.filter(v => v.id !== surveyId);
    },
  },
};
