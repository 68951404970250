import { client } from '../../clients';

/**
 * Выдача конфигов для подключения к сокетам через userId
 * @param {String} userId Айди юзера
 * @returns {Promise}
 */
export default function (userId) {
  return client
    .get(`/lavka/v2/socket/connection_data/${userId}`)
    .then(response => response.data);
}
