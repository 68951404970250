function isAndroid() {
  return /android/i.test(navigator.userAgent);
}

function copyOldSchool(value) {
  const element = document.createElement('textarea');
  element.value = value;
  document.body.appendChild(element);
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
}

export function copy(value) {
  return new Promise(resolve => {
    if (isAndroid()) {
      copyOldSchool(value);
      return resolve();
    }

    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(value).then(() => {
        return resolve();
      });
    }
  });
}
