export const AdultstModule = {
  namespaced: true,
  state: {
    isAgreed: null,
  },
  getters: {
    isAgreed(state) {
      return state.isAgreed;
    },
  },
  mutations: {
    SET_IS_AGREED(state, payload) {
      state.isAgreed = payload;
    },
  },
};
