<template>
  <div
    ref="toolbar"
    v-scroll="{
      disabled: !headerSliding,
      onDirectionChanged: v => (v === 'up' ? scrollUp() : scrollDown()),
    }"
    class="ToolBar"
  >
    <div
      v-if="!$isDesktop"
      class="w-full transition-all duration-200 mob"
      :style="`
        ${
          hasBackground
            ? 'background: linear-gradient( 180deg, #ffffff 47.78%, rgba(255, 255,  255, 0.9) 67.89% );'
            : ''
        }
        backdrop-filter: blur(12px);
        ${
          showTopHeader
            ? 'transform: translateY(0px);'
            : 'transform: translateY(-52px);'
        }`"
    >
      <div
        class="w-full flex items-center px-4 pt-3 pb-2 text-sirius-black-100"
      >
        <div class="flex justify-start mr-2">
          <slot v-if="!hideArrow" name="left-side">
            <UiInlineIcon
              name="back-arrow"
              class="active:opacity-50 focus:outline-none"
              type="button"
              @click="back($router)"
            />
          </slot>
        </div>

        <div
          class="w-full flex items-center justify-center min-w-0 text-xl font-bold leading-6"
          style="min-height: 32px"
        >
          <slot> </slot>
        </div>

        <slot name="right-side">
          <div class="flex justify-end ml-2 min-w-6"></div>
        </slot>
      </div>
      <div
        v-if="bottomToolbar"
        class="pt-2 w-full transition-all duration-100"
        :class="[bottomBorder ? 'pb-2' : 'pb-3']"
      >
        <div>
          <slot name="bottom-toolbar"> </slot>
        </div>
        <slot name="bottom-toolbar2"> </slot>
      </div>
      <div v-if="bottomBorder" class="w-full px-4">
        <div class="w-full h-0.5 rounded-full bg-sirius-gray-400"></div>
      </div>
    </div>
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import Scroll from '@/utils/directives/scroll';

export default {
  name: 'ToolBar',
  components: {
    UiInlineIcon,
  },
  directives: {
    Scroll,
  },
  props: {
    back: {
      type: Function,
      default(router) {
        if (window.history.length < 3) {
          return router.push({ name: 'main' });
        }
        return router.go(-1);
      },
    },
    bottomToolbar: {
      type: Boolean,
      default: false,
    },
    headerSliding: {
      type: Boolean,
      default: false,
    },
    bottomBorder: {
      type: Boolean,
      default: false,
    },
    hideArrow: {
      type: Boolean,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showTopHeader: true,
  }),
  methods: {
    scrollUp() {
      this.showTopHeader = true;
      this.$emit('showTop');
    },
    scrollDown() {
      this.showTopHeader = false;
      this.$emit('hideTop');
    },
  },
  i18n: {
    messages: {
      ru: {},
      kk: {},
    },
  },
};
</script>
