<template>
  <div class="ProductReviewWrapper">
    <ProductReviewBlock
      :review="form.review"
      :comment="form.comment"
      @onUpdate="onUpdate"
      @onOpenComment="onOpenComment"
    />
    <TellMoreModal
      v-if="product"
      v-model="commentModal"
      :name="$i18n.locale === 'kk' ? product.name_kk : product.name"
      :call-me="form.review === 'dislike'"
      :comment="form.comment"
      :checked="form.callBack"
      @onReady="onTellMore"
    />
  </div>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { ProductReviewService } from '@services/product-review';
import ProductReviewBlock from '@pure-ui/components/ProductReview/ProductReviewBlock/ProductReviewBlock.vue';
import TellMoreModal from '@pure-ui/components/ProductReview/components/TellMoreModal.vue';

export default {
  name: 'ProductReviewWrapper',
  components: { ProductReviewBlock, TellMoreModal },
  inject: ['toast'],
  props: {
    product: {
      type: Object,
      required: true,
    },
    productReview: {
      type: Object,
      required: true,
    },
    screen: {
      type: String,
      default: 'product-details',
    },
  },
  data() {
    return {
      commentModal: false,
      form: {
        comment: '',
        callBack: false,
        review: '',
      },
    };
  },
  watch: {
    productReview: {
      handler() {
        this.updateReview();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.logAnalytics(EVENTS.REVIEW_SHOWN);
  },
  methods: {
    logAnalytics(event, data) {
      Analitycs.logEvent(event, {
        type: 'single',
        screen: this.screen,
        reviewProductIds: [this.product.id],
        comment: this.form.comment,
        callBack: this.form.callBack,
        review: this.form.review,
        ...data,
      });
    },
    updateReview() {
      this.form.comment = this.form.comment || this.productReview.comment;
      this.form.callBack = this.form.callBack || this.productReview.callBack;
      this.form.review = this.form.review || this.productReview.review;
    },
    submitReview(hasComment) {
      let payload = [
        {
          ...this.form,
          id: this.product.id,
        },
      ];
      this.$log('submitReview', payload);

      ProductReviewService.submitReview(payload).then(() => {
        if (hasComment) this.toast.show(this.$t('thanksForReview'), 'success');
      });
    },
    onTellMore(comment, callBack) {
      let hasChanges =
        (comment || callBack) &&
        (this.form.comment !== comment || this.form.callBack !== callBack);
      this.form.comment = comment;
      this.form.callBack = callBack;

      if (hasChanges) {
        this.logAnalytics(EVENTS.REVIEW_DONE, { when: 'onOpenComment' });
        this.submitReview(!!hasChanges);
      }
    },
    openModal() {
      this.commentModal = true;
    },
    onOpenComment(result) {
      RahmetApp.hapticSelection();
      this.form.review = result;
      this.openModal();
    },
    onUpdate(result) {
      RahmetApp.hapticSelection();
      this.form.review = result;

      if (this.form.review) {
        this.logAnalytics(EVENTS.REVIEW_DONE, { when: 'onReview' });
        this.submitReview();
      } else {
        this.logAnalytics(EVENTS.REVIEW_DONE, { when: 'onReset' });
        this.submitReview();
      }
    },
  },
  i18n: {
    messages: {
      kk: {
        thanksForReview: 'Бағалағаныңызға рақмет!',
      },
      ru: {
        thanksForReview: 'Спасибо за отзыв!',
      },
    },
  },
};
</script>

<style></style>
