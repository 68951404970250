<template>
  <UiBottomSheetWrapper
    class="extended-time"
    :show="show"
    with-title
    with-back-button
    @change="$emit('change', $event)"
    @onOpen="() => {}"
    @onClose="$emit('change', $event)"
  >
    <template #title>
      <span v-html="$t('title')"></span>
    </template>
    <div class="extended-time__wrapper">
      <div class="extended-delivery-note">
        <p class="extended-delivery-note__descr">
          <IconExtendedDelivery class="extended-delivery-note__decor" />
          <span v-html="$t('extendedDeliveryTimeDescr')"></span>
        </p>
      </div>
      <UiButton class="mt-12" size="LG" @click="$emit('change', false)">
        {{ $t('btn') }}
      </UiButton>
    </div>
  </UiBottomSheetWrapper>
</template>

<script>
import UiBottomSheetWrapper from '@pure-ui/components/UiBottomSheetWrapper/UiBottomSheetWrapper.vue';
import IconExtendedDelivery from '@shared/components/icons/IconExtendedDelivery.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

/**
 * Модалка показывается всем пользователям,
 * перешедшим по реферальной ссылке
 */
export default {
  name: 'ExtendedTimeModal',
  components: {
    UiBottomSheetWrapper,
    IconExtendedDelivery,
    UiButton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      ru: {
        title: 'Расширенная зона доставки',
        btn: 'Понятно',
        extendedDeliveryTimeDescr: `
          Привезем заказ
          <span class="whitespace-nowrap">за 30 – 40 минут</span>,
          так&nbsp;как адрес находится не&nbsp;в&nbsp;основной зоне доставки
        `,
      },
      kk: {
        title: 'Кеңейтілген жеткізу аймағы',
        btn: 'Түсінікті',
        extendedDeliveryTimeDescr: `
          Мекенжай кеңейтілген алаңда орналасқандықтан, тапсырыс
          <span class="whitespace-nowrap">30 - 40 минутта</span>
          жеткізіледі
        `,
      },
    },
  },
};
</script>

<style scoped>
.extended-time__msg {
  margin-top: -13px;
  font-size: 24px;
  line-height: 27.6px;
  letter-spacing: 0.015em;
  font-weight: 900;
}

.extended-time__btn-ok {
  font-size: 18px;
  font-weight: 900;
  width: 100%;
  margin-top: 48px;
}

.extended-delivery-note__descr {
  margin-top: 16px;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.005em;
  position: relative;
  padding-left: 36px;
}

.extended-delivery-note__decor {
  position: absolute;
  top: 0;
  left: 0;
  color: #7284e4;
}
</style>
