import { globals } from '@/lib/globals';

export function mapResponse(d) {
  const lc = globals.$i18n.locale;

  let buttonText = lc === 'kk' ? 'Түсінікті' : 'Понятно';

  if (d.url) {
    buttonText = lc === 'kk' ? 'Толығырақ' : 'Подробнее';
  }

  return {
    id: d.id,
    imageDesktop: d.image_desktop,
    imageMobile: d.image_mobile,
    title: d[`title_${lc}`],
    termsHtml: d[`terms_${lc}`],
    mandatoryTerms: d[`mandatory_terms_${lc}`],
    url: d.url,
    buttonText,
  };
}
