<template>
  <div class="WatermelonRefundPlank" @click="$emit('onPlankClick')">
    <div
      class="WatermelonRefundPlank__block"
      :style="$isDesktop ? 'border-radius:24px;' : 'border-radius: 16px;'"
    >
      <img
        src="../watermelon-refund/images/watermelon.png"
        width="95"
        height="73"
        class="WatermelonRefundPlank__visual"
      />
      <span v-html="$t('t1')" />
      <ArrowRightIcon class="WatermelonRefundPlank__arrow" />
    </div>
  </div>
</template>

<script>
import ArrowRightIcon from '@components/watermelon-refund/ArrowRightIcon.vue';

export default {
  name: 'WatermelonRefundPlank',
  components: { ArrowRightIcon },
  inject: [],
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
  i18n: {
    messages: {
      kk: {
        t1: 'Қарбыз ұнамаса — ақшаңызды қайтарамыз',
      },
      ru: {
        t1: 'Что-то не так с арбузом? Гарантируем возврат',
      },
    },
  },
};
</script>

<style scoped>
.WatermelonRefundPlank {
}

.WatermelonRefundPlank__block {
  @apply text-sm md:text-xl;
  overflow: hidden;
  height: 72px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 63px;
  padding-right: 40px;
  color: rgba(36, 36, 36, 1);
  font-weight: 700;
  letter-spacing: 0.07px;
  background: linear-gradient(0deg, #ffdada 0%, #ffeee6 70%);
}

.WatermelonRefundPlank__block span {
  margin-left: 55px;
}

.WatermelonRefundPlank__visual {
  position: absolute;
  left: 5px;
  bottom: 1px;
}

.WatermelonRefundPlank__arrow {
  position: absolute;
  top: 24px;
  right: 14px;
}

@media (max-width: 768px) {
  .WatermelonRefundPlank__block {
    height: 56px;
  }

  .WatermelonRefundPlank__block span {
    @apply font-medium;
    margin-left: 10px;
    max-width: 240px;
    line-height: 16px;
  }

  .WatermelonRefundPlank__visual {
    width: 64px;
    height: auto;
  }
  .WatermelonRefundPlank__arrow {
    position: absolute;
    top: 16px;
    right: 10px;
  }
}
</style>
