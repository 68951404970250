<template>
  <section class="MmRoutine">
    <MmSubtitle id="routine"> <span v-html="$t('title')" /> </MmSubtitle>
    <ul class="MmRoutine__list">
      <li
        v-for="item in items"
        :key="item[$i18n.locale].title"
        class="MmRoutine__item"
      >
        <img
          :src="$isDesktop ? item.dImage : item.mImage"
          alt=""
          class="MmRoutine__img"
          loading="lazy"
          :width="$isDesktop ? 144 : 100"
          :height="$isDesktop ? 148 : 88"
        />
        <div class="MmRoutine__title" v-html="item[$i18n.locale].title" />
        <div class="MmRoutine__descr" v-html="item[$i18n.locale].descr" />
      </li>
    </ul>
  </section>
</template>

<script>
import MmSubtitle from '@/views/micromarket/components/MmSubtitle/MmSubtitle.vue';
import Img1M from './images/m-1.jpg';
import Img1D from './images/d-1.jpg';
import Img2M from './images/m-2.jpg';
import Img2D from './images/d-2.jpg';
import Img3M from './images/m-3.jpg';
import Img3D from './images/d-3.jpg';
import Img4M from './images/m-4.jpg';
import Img4D from './images/d-4.jpg';

export default {
  name: 'MmRoutine',
  components: { MmSubtitle },
  inject: [],
  props: {},
  data: () => ({
    items: [
      {
        mImage: Img1M,
        dImage: Img1D,
        ru: {
          title: 'Клиент сканирует QR&#8209;код',
          descr: 'QR расположен на дверце холодильника.',
        },
        kk: {
          title: 'Клиент QR-кодты сканерлейді',
          descr: 'QR-код тоңазытқыштың есігінде орнатылған',
        },
      },
      {
        mImage: Img2M,
        dImage: Img2D,
        ru: {
          title: 'Выбирает товар',
          descr:
            'Товар можно приобрести как из&nbsp;меню приложения, так и&nbsp;отсканировав его&nbsp;штрихкод.',
        },
        kk: {
          title: 'Тауарды таңдайды',
          descr:
            'Тауарды қосымша мәзірінен немесе штрихкодын сканерлеп алуға болады',
        },
      },
      {
        mImage: Img3M,
        dImage: Img3D,
        ru: {
          title: 'Оплачивает',
          descr:
            'Регистрируется по&nbsp;номеру телефона и&nbsp;платит удобным способом.',
        },
        kk: {
          title: 'Төлейді',
          descr: 'Телефон номер арқылы тіркеліп, ыңғайлы тәсілмен төлейді.',
        },
      },
      {
        mImage: Img4M,
        dImage: Img4D,
        ru: {
          title: 'Забирает свой товар',
          descr: 'После оплаты забирает купленные продукты',
        },
        kk: {
          title: 'Тауарды алады',
          descr: 'Төлегеннен кейін сатып алынған тауарды алады',
        },
      },
    ],
  }),

  i18n: {
    messages: {
      kk: { title: 'Тауар сатып алу қалай жүзеге асады?' },
      ru: { title: 'Как происходит покупка товара' },
    },
  },
};
</script>

<style scoped>
.MmRoutine {
  padding-top: 84px;
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.MmRoutine__list {
  padding-top: 24px;
  display: grid;
  gap: 40px;
  margin-left: -8px;
}

.MmRoutine__item {
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: min-content 1fr;
  grid-gap: 4px 8px;
}

.MmRoutine__img {
  grid-row: 1 / 3;
}

.MmRoutine__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.MmRoutine__descr {
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .MmRoutine {
    max-width: 988px;
    padding-top: 180px;
  }

  .MmRoutine__list {
    padding-top: 48px;
    margin-left: 0;
    grid-template-columns: 1fr 1fr;
    gap: 52px 0;
    counter-reset: counter1;
  }

  .MmRoutine__item {
    counter-increment: counter1;
    gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: initial;
    grid-auto-rows: min-content;
  }

  .MmRoutine__title {
    padding-top: 8px;
    position: relative;
    font-size: 24px;
    line-height: 30px;
  }

  .MmRoutine__title::before {
    content: counter(counter1);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -48px;
    width: 32px;
    height: 32px;
    border-radius: 24px;
    color: #fff;
    font-weight: 900;
    background: var(--services-produkty, #ffa100);
  }

  .MmRoutine__descr {
    padding-top: 12px;
    max-width: 388px;
    font-size: 16px;
    line-height: 22px;
  }

  .MmRoutine__img {
    grid-row: auto;
  }
}
</style>
