<template>
  <div class="">
    <div
      class="flex items-start font-bold text-lg text-sirius-black-100 relative pl-9"
    >
      <img
        src="@img/icons/lost-goods-smile.png"
        alt="Нет результатов"
        height="24"
        width="24"
        class="absolute top-0 left-0"
      />
      <p class="" v-html="$t('title')" />
    </div>
    <p class="pl-9 text-sm" v-html="$t('descr')" />
  </div>
</template>

<script>
export default {
  name: 'NotFoundProducts',
  i18n: {
    messages: {
      kk: {
        title: `Ештеңе табылмады`,
        descr: `Бәлкім іздеу кезінде қате кетті немесе сіз іздеген тауар бізде әзірге жоқ`,
      },
      ru: {
        title: `Ничего не&nbsp;нашлось`,
        descr: `Возможно, вы неверно ввели запрос, или у&nbsp;нас такого товара пока нет`,
      },
    },
  },
};
</script>
