/* eslint-disable */
const OFFSET_BEFORE_CLOSE = window.innerHeight / 5;

const extractTouch = e => ({
  y: e.changedTouches[0].clientY,
  x: e.changedTouches[0].clientX,
});

export default {
  inserted(el, binding) {
    let shift = 0;
    let xStart;
    let yStart;
    let isXMovementStarted = false;
    let isYMovementStarted = false;

    const container = el.querySelector('[data-container]');
    // const bg = el.querySelector('[data-bg]');

    el.addEventListener('touchstart', e => {
      const { x, y } = extractTouch(e);
      xStart = x;
      yStart = y;
      container.style.transition = '';
      container.style.transformOrigin = 'center bottom';
    });

    el.addEventListener('touchmove', e => {
      const { x, y } = extractTouch(e);

      const deltaX = xStart - x;
      const deltaY = yStart - y;

      if (isXMovementStarted) {
        return;
      }

      if (Math.abs(deltaX) > 10 && !isYMovementStarted) {
        isXMovementStarted = true;
        return;
      }

      if (Math.abs(deltaY) < 10 && !isYMovementStarted) {
        return;
      }

      isYMovementStarted = true;

      shift = Math.max(0, y - yStart);

      const wHeight = window.innerHeight;
      const scale = (wHeight - shift / 12) / wHeight;
      container.style.transform = `translateY(${shift / 3}px)`;
      container.style.opacity = `${scale}`;
    });

    el.addEventListener('touchend', () => {
      isXMovementStarted = false;
      isYMovementStarted = false;

      if (shift >= OFFSET_BEFORE_CLOSE) {
        container.style.transition = 'all .3s';
        requestAnimationFrame(() => {
          container.style.transform = `translateY(${window.innerHeight}px)`;
          container.style.opacity = `0`;
          setTimeout(() => {
            binding.value.onClose();
          }, 300);
        });
      } else {
        container.style.transition = 'all .3s';
        container.style.transform = '';
        container.style.opacity = '1';
        // requestAnimationFrame(() => {});
      }
    });
  },
};
