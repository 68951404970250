/**
 * @see https://stackoverflow.com/a/15720835
 * @returns {number} размер localstorage в байтах
 */
export function getLocalStorageSize() {
  let _lsTotal = 0,
    _xLen,
    _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue;
    }
    // localStorage в UTF-16, они по два байта на символ
    _xLen = (localStorage[_x].length + _x.length) * 2;
    _lsTotal += _xLen;
  }
  return _lsTotal;
}

/**
 * Пытаемся понять из-за чего возникает ошибка QuotaExceededError.
 * Возможно Амплитуда не логирует слишком длинное значение,
 * поэтому пытаемся найти его на клиенте
 *
 * @see https://sentry.chocodev.kz/organizations/sentry/issues/1063647/?project=250
 * @return {*} Возвращает key элемента, у которого значение ключа
 *          или само значение имеет самый большой размер
 */
export function getLocalStorageLongestKey() {
  let longestLength = 0,
    key,
    _x;
  for (_x in localStorage) {
    if (!localStorage.hasOwnProperty(_x)) {
      continue;
    }
    if (localStorage[_x].length > longestLength) {
      key = _x;
      longestLength = localStorage[_x].length;
    }
    if (_x.length > longestLength) {
      key = _x;
      longestLength = _x.length;
    }
  }
  return key;
}
