import { rSendFeedback } from '@shared/services/api/requestsV2/feedback/send-feedback';

/**
 * @typedef {Object} Payload
 * @property {boolean}  needsFeedback
 * @property {string}   message
 * @property {string}   topic
 */

/**
 * @param {Payload} payload
 */
export function sendFeedback(payload) {
  return rSendFeedback({
    call_back: payload.needsFeedback,
    comment: payload.message,
    topic: payload.topic,
  });
}
