<template>
  <ComplexTransition
    bg-selector=".StoriesContainer__bg"
    container-selector=".StoriesContainer__inner"
  >
    <div
      v-swipeable-down="{ onClose: onCloseBySwipe }"
      class="StoriesContainer"
    >
      <div
        ref="bg"
        class="StoriesContainer__bg"
        @click="$listeners.onBackgroundClick"
      />
      <div ref="container" class="StoriesContainer__inner" data-container>
        <StoriesCube
          ref="storiesCube"
          class="StoriesContainer__cube"
          v-bind="$attrs"
          v-on="$listeners"
        />
        <DesktopArrowButtons
          v-if="$isDesktop"
          class="StoriesContainer__arrowButtons"
          v-on="$listeners"
        />
        <DesktopCross
          v-if="$isDesktop"
          class="StoriesContainer__btnCross"
          @click="$emit('onClose')"
        />
      </div>
    </div>
  </ComplexTransition>
</template>

<script>
import ComplexTransition from './transitions/ComplexTransition';
import SwipeableDownDirective from './directives/SwipeableDownDirective';
import StoriesCube from './components/StoriesCube/StoriesCube.vue';
import DesktopArrowButtons from './components/DesktopArrowButtons/DesktopArrowButtons.vue';
import DesktopCross from './components/DesktopCross/DesktopCross.vue';

export default {
  name: 'StoriesContainer',
  directives: { SwipeableDown: SwipeableDownDirective },
  components: {
    ComplexTransition,
    StoriesCube,
    DesktopArrowButtons,
    DesktopCross,
  },
  inheritAttrs: false,
  data: () => ({}),
  computed: {},
  methods: {
    onCloseBySwipe() {
      this.$emit('onClose', 'swipe');
    },
  },
};
</script>

<style scoped>
.StoriesContainer {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: contain;
  touch-action: none;
}

.StoriesContainer__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 1);
}

.StoriesContainer__inner {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .StoriesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .StoriesContainer__inner {
    width: auto;
    height: 80%;
    max-height: 712px;
    aspect-ratio: 400 / 712;
  }

  .StoriesContainer__bg {
    background: rgba(0, 0, 0, 0.65);
  }

  .StoriesContainer__arrowButtons {
    position: absolute;
    width: 100%;
    top: 50%;
  }

  .StoriesContainer__btnCross {
    position: fixed;
    left: calc(50% + (100vw / 2) - 62px - (100vw - 988px) / 2);
  }
}
</style>
