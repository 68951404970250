<template>
  <div class="final-page">
    <div class="final-page-img">
      <transition name="scale">
        <img
          v-if="show"
          src="../img/final-img.png"
          class="duration-500"
          alt="final"
          style="--scale: 0.5"
        />
      </transition>
    </div>
    <div class="final-page-content">
      <p class="final-page-title" v-html="$tc('thanksReview', innerCount)"></p>
      <p class="final-page-subtitle" v-html="$t('reviewHelps')"></p>
      <div class="final-page-btns">
        <UiButton size="LG" variant="gray" @click.native="$emit('onDone')">
          {{ $t('done') }}
        </UiButton>
        <UiButton size="LG" @click.native="$emit('onMore')">
          {{ $t('reviewMore') }}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
export default {
  name: 'FinalPage',
  components: {
    UiButton,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      innerCount: 0,
    };
  },
  watch: {
    count: {
      handler(val) {
        if (val) {
          this.innerCount = val;
        }
      },
      immediate: true,
    },
  },
  i18n: {
    messages: {
      kk: {
        thanksReview: 'Рақмет, сіз {count} өнімге баға бердіңіз!',
        reviewHelps:
          'Сіздің бағаңыз бізге сапалы өнім алуға және ұсынуға көмектеседі',
        reviewMore: 'Тағы бағалау',
        done: 'Дайын',
      },
      ru: {
        thanksReview:
          'Спасибо, вы оценили {count} товара! | Спасибо, вы оценили {count} товар! | Спасибо, вы оценили {count} товара! | Спасибо, вы оценили {count} товаров!',
        reviewHelps:
          'Ваши оценки помогают отбирать <br/> качественные товары в ассортимент',
        reviewMore: 'Оценить ещё',
        done: 'Готово',
      },
    },
  },
};
</script>

<style scoped>
.final-page {
  @apply pb-4;
}
.final-page-img {
  @apply w-full flex items-center justify-center;
  height: 300px;
}
.final-page-img img {
  @apply w-full h-full object-contain;
}
.final-page-content {
  @apply px-4;
}
.final-page-title {
  @apply mt-8 text-center text-lg font-medium leading-6;
}
.final-page-subtitle {
  @apply mt-3 text-sirius-gray-900 text-center text-sm leading-22;
}
.final-page-btns {
  @apply pt-1 flex items-center space-x-3;
  margin-top: 112px;
}
</style>
