import { PUBLIC_MESSAGE_TYPES } from '@shared/services/ws/config';
import { publicWs } from '@shared/services/ws';
import { $log } from '@/utils/plugins/logger';
import { store } from '@/store';

function getWarehouse() {
  return store.getters['delivery/warehouse'];
}
function setWarehouse(payload) {
  store.commit('delivery/SET_WAREHOUSE', payload);
}

function onUpdate(data) {
  const newMinAmount = +data.min_amount;
  const warehouse = getWarehouse();
  $log('SurgeService', { surgeData: data, warehouse }, 'data');
  setWarehouse({ ...warehouse, min_amount: newMinAmount });
}

function init() {
  $log('SurgeService', null, 'init');

  publicWs.on(PUBLIC_MESSAGE_TYPES.SURGE, onUpdate);
}

export const SurgeService = {
  init,
};
