<template>
  <div>
    <svg
      :class="`${position}`"
      width="16"
      height="8"
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 6.99382e-07L16 8L0 8L8 6.99382e-07Z" fill="black" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconArrow',
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
  },
};
</script>

<style scoped>
.top {
  transform: rotate(180deg);
}
.right {
  transform: rotate(-90deg);
}
.bottom {
  transform: rotate(0deg);
}
.left {
  transform: rotate(90deg);
}
</style>
