<template>
  <div class="relative" @click="onClick">
    <IconFavoriteHeaderMobile />
  </div>
</template>

<script>
import IconFavoriteHeaderMobile from '@shared/components/icons/IconFavoriteHeaderMobile.vue';

export default {
  name: 'FavoriteIcon',
  components: {
    IconFavoriteHeaderMobile,
  },
  props: {
    from: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClick() {
      const { from } = this;
      this.$router.push({
        name: 'favorites',
        params: { from },
      });
    },
  },
};
</script>
