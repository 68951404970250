<template>
  <UiRippleLoader v-if="!oneGroupCategories" class="mx-auto" />
  <div v-else class="CategoriesList">
    <div
      v-for="(category, index) in oneGroupCategories.categories"
      :key="index"
      class="mb-8"
    >
      <CategoryBlock
        :is-active="isCategoryActive(category)"
        :category="category"
        :type="'category'"
        is-category
        class="w-full"
        style="max-width: 236px"
        @onNavigate="navigateTo(category, 'category')"
      />
      <template v-if="isCategoryActive(category)">
        <div v-for="(child, childIndex) in tags" :key="childIndex" class="">
          <CategoryBlock
            :category="child"
            :is-active="isChildActive(child)"
            :expandable="false"
            :with-image="false"
            :type="'child'"
            class="w-full mt-4 h-10 pl-10"
            style="max-width: 236px"
            @onNavigate="navigateTo(child, 'subcategory')"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import CategoryBlock from '@shared/components/catalog/CategoryBlock.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';

export default {
  name: 'CategoriesList',
  components: { CategoryBlock, UiRippleLoader },
  props: {
    parentId: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('catalog', ['categoriesByGroup', 'categories']),
    ...mapGetters('catalog', ['hasMainCategories', 'isCatalogLoading']),
    oneGroupCategories() {
      if (!(this.categories && this.parentId)) return null;
      const parentCategory = this.categories.find(e => {
        return e.id.toString() === this.parentId;
      });
      if (parentCategory && parentCategory.group_name)
        return this.categoriesByGroup[parentCategory.group_name];
      return this.categoriesByGroup['Остальное'];
    },
  },
  mounted() {
    if (!this.isCatalogLoading && !this.hasMainCategories) {
      this.fetchMainCategories();
    }
  },
  methods: {
    ...mapActions('catalog', { fetchMainCategories: 'FETCH_MAIN_CATEGORIES' }),
    hasChild(child) {
      return child.id && child.has_product !== null && child.has_product;
    },
    isChildActive(child) {
      return child.id && child.id.toString() === this.$route.query.tag;
    },
    isCategoryActive(category) {
      return category.id && category.id.toString() === this.parentId;
    },
    hasChildCategories(category) {
      return (
        category.id &&
        category.id.toString() === this.parentId &&
        category.has_product &&
        category.categories &&
        category.categories.length > 0
      );
    },
    navigateTo(item, type) {
      if (type === 'subcategory') {
        Analitycs.logEvent(EVENTS.SUBCATEGORY_SELECTED, {
          categoryId: item.id,
          categoryName: item.name,
          from: 'desktop-sidebar',
        });
        this.$router.push({
          name: 'category',
          params: {
            id: this.parentId.toString(),
          },
          query: {
            tag: item.id.toString(),
          },
        });
        return;
      }

      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
        categoryId: item.id,
        categoryName: item.name,
        from: 'desktop-sidebar',
      });
      this.$router.push({
        name: 'category',
        params: {
          id: item.id.toString(),
        },
      });
    },
  },
};
</script>
