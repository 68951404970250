export const menuItems = [
  {
    ru: 'О продукте',
    kk: 'Өнім туралы',
    scrollTo: 'whatis',
  },
  {
    ru: 'Путь покупателя',
    kk: 'Қолдану ережесі',
    scrollTo: 'routine',
  },
  {
    ru: 'Почему мы',
    kk: 'Неліктен біз',
    scrollTo: 'advantages',
  },
  {
    ru: 'Требования',
    kk: 'Талабы',
    scrollTo: 'requirements',
  },
];
