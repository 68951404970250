<template>
  <h2 class="NyPageSubtitle">
    <slot />
  </h2>
</template>

<script>
export default {
  name: 'NyPageSubtitle',
};
</script>

<style scoped>
.NyPageSubtitle {
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .NyPageSubtitle {
    font-size: 36px;
    line-height: 44px;
  }
}
</style>
