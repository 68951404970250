<template>
  <div class="MBlockSuccess flex pt-3 pb-4" v-on="$listeners">
    <UiInlineIcon name="promocode" />
    <div class="ml-3 mr-auto flex flex-col">
      <span>{{ promocode }}</span>
      <span class="MBlockSuccess__msg">
        {{ $t('promocodeApplied') }}
      </span>
    </div>
    <div class="text-green-400 font-bold">{{ discount }} ₸</div>
    <UiInlineIcon
      class="MBlockSuccess__iconArr"
      name="arrow-right"
      style="color: #a4a2b7"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MBlockSuccess',
  components: {
    UiInlineIcon,
  },
  props: {
    promocode: {
      type: String,
      default: '',
    },
    discount: {
      type: Number,
      default: -1,
    },
  },
  i18n: {
    messages: {
      ru: {
        promocodeApplied: 'Промокод применён',
      },
      kk: {
        promocodeApplied: 'Промокод қолданылды',
      },
    },
  },
};
</script>

<style>
.MBlockSuccess__iconArr {
  margin-left: 6px;
  margin-right: -6px;
}

.MBlockSuccess__msg {
  color: #05bf0d;
  font-size: 14px;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.07px;
}
</style>
