<template>
  <div class="">
    <label
      :for="`${$attrs.name}_id`"
      class="ui-text-field cursor-pointer relative"
      :class="`${
        invalid
          ? 'border-sirius-red-200'
          : focused
            ? 'border-sirius-orange-100'
            : 'border-sirius-gray-200'
      }
        ${clearable ? 'pr-10' : ''}
        ${variants[variant]}
        ${sizes[size]}
        ${hasResults ? 'border rounded-t-xl' : 'rounded-xl border'}`"
    >
      <p
        v-if="$attrs.placeholder"
        class="transition-all ease-in-out duration-200 text-sirius-gray-100 mt-auto"
        :class="`${focused || value ? 'text-xs h-3.5' : ''}`"
      >
        {{ $attrs.placeholder }}
      </p>

      <input
        :id="`${$attrs.name}_id`"
        :ref="$attrs.name"
        :value="value"
        class="appearance-none focus:outline-none transition ease-in-out duration-200 w-full bg-transparent whitespace-nowrap placeholder-transparent"
        :class="`${focused || value ? 'h-6 pt-1' : 'h-0'}
          ${invalid ? 'text-sirius-red-200' : 'text-sirius-black-100'}
          ${!$attrs.placeholder ? 'my-auto' : 'mb-auto'}`"
        v-bind="$attrs"
        v-on="$listeners"
        @focus="onFocus"
        @blur="focused = false"
        @input="onInput"
      />
      <svg
        v-if="isRequired"
        class="absolute top-1.5 right-2"
        width="7"
        height="7"
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.056 1.296L3.536 2.976L2.272 1.712C1.84 1.248 1.184 1.216 0.896 1.696C0.624 2.144 0.896 2.656 1.584 2.848L3.312 3.344L1.6 3.824C0.96 4 0.624 4.496 0.912 4.976C1.184 5.424 1.808 5.456 2.272 4.976L3.52 3.712L3.056 5.408C2.864 6.048 3.136 6.576 3.744 6.576C4.368 6.576 4.624 6.032 4.432 5.408L3.952 3.712L5.2 4.976C5.68 5.472 6.288 5.456 6.576 4.992C6.88 4.528 6.576 4.016 5.872 3.824L4.16 3.344L5.888 2.848C6.544 2.688 6.864 2.176 6.576 1.696C6.288 1.216 5.664 1.216 5.2 1.696L3.936 2.976L4.432 1.28C4.624 0.672 4.352 0.127999 3.744 0.127999C3.136 0.127999 2.864 0.655999 3.056 1.296Z"
          fill="#F91F01"
        />
      </svg>

      <transition name="scale">
        <button
          v-if="clearable && value.length > 0"
          aria-label="Сбросить"
          class="absolute right-2 top-0 bottom-0 transition ease-in duration-100 focus:outline-none active:opacity-50"
          style="--scale: 0.1"
          type="button"
          @click="onClear"
        >
          <IconCancel class="text-sirius-black-100" width="32" height="32" />
        </button>
      </transition>
    </label>
    <div v-if="invalid && errorMessage.length > 0" class="mt-0.5 flex">
      <span class="pl-2 text-sirius-red-200 text-xs leading-tight">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import IconCancel from '@shared/components/icons/IconCancel.vue';

const VARIANTS = {
  gray: '--gray',
  white: '--white',
};
const SIZES = {
  md: '--md',
  lg: '--lg',
};
export default {
  name: 'MmInput',
  components: {
    IconCancel,
  },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'onInput',
  },
  props: {
    isnew: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'gray',
    },
    size: {
      type: String,
      default: 'md',
    },
    hasResults: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    transformUppercase: {
      type: Boolean,
      default: false,
    },
    transformTrim: {
      type: Boolean,
      default: false,
    },
    transformRegexp: {
      type: RegExp,
      default: null,
    },
  },
  data: () => ({
    focused: false,
    sizes: SIZES,
    variants: VARIANTS,
  }),
  methods: {
    onInput(event) {
      let v = event.target.value;
      if (this.transformRegexp) {
        v = event.target.value.replace(this.transformRegexp, '');
      }
      if (this.transformUppercase) {
        v = v.toUpperCase();
      }
      if (this.transformTrim) {
        v = v.trim();
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
      this.$emit('onInput', v);
    },
    focusIt() {
      this.$refs[this.$attrs.name].focus();
    },
    onFocus() {
      this.focused = true;
      this.$emit('onFocus');
    },
    onClear() {
      this.$emit('onInput', '');
      this.$emit('onClear');
      this.focusIt();
    },
  },
};
</script>

<style lang="postcss" scoped>
.ui-text-field {
  @apply px-4 md:px-3 w-full relative
  transition-all ease-in-out duration-200
  flex flex-col;

  &.--md {
    @apply h-12;
  }

  &.--lg {
    @apply h-13 pl-6;
  }

  &.--white {
    @apply bg-white;
  }

  &.--gray {
    @apply bg-sirius-gray-200;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
