<template>
  <CashbackReminder
    :cashback-price="cashbackPrice"
    :condition="activeCashbacksCondition"
    @onClickMore="onClickMore"
    @onClickReady="onClickReady"
  />
</template>

<script>
import CashbackReminder from '@pure-ui/components/Cashbacks/CashbackReminder/CashbackReminder.vue';
import { CashbackService } from '@services/cashback';
import { mapGetters } from 'vuex';

export default {
  components: { CashbackReminder },
  computed: {
    ...mapGetters('cart', ['cartTotalPriceWithDiscount']),
    ...mapGetters('cashbacks', ['activeCashbacksCondition']),
    cashbackPrice() {
      return CashbackService.calculateCashback(
        this.cartTotalPriceWithDiscount,
        this.activeCashbacksCondition
      );
    },
  },
  methods: {
    onClickMore() {
      this.$router.push({
        name: 'cashbacks',
        query: {
          from: 'cart',
        },
      });
    },
    onClickReady() {},
  },
};
</script>

<style></style>
