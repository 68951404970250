import { isCoffeeAvailable } from '../is-coffee-available';
import { COFFEE_CATEGORY_ID } from '../config';

export function getFilteredSpecialCategories(categories) {
  if (!isCoffeeAvailable()) {
    return categories.filter(c => c.id !== COFFEE_CATEGORY_ID);
  }

  return categories;
}
