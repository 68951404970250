<template>
  <component :is="ScrollComp">
    <div v-for="(item, index) in compLinks" :key="index">
      <SidebarLinkTitle
        :red="item.color === 'red'"
        :arrow="!!($isDesktop && item.childs.length)"
        :active="isActive(item)"
        :toggled="item.toggled"
        @click.native="navigateTo(item)"
      >
        {{ $t(item.name) }}
      </SidebarLinkTitle>

      <SidebarLinkChilds
        v-if="$isDesktop && item.childs.length"
        :links="item.childs"
        :toggled="item.toggled"
        @onClick="scrollToId"
      />
    </div>
    <SupportCenter
      v-if="$isDesktop"
      :phone="phoneNumber"
      @onPhone="onPhone"
      @onWhatsapp="onWhatsapp"
    />
  </component>
</template>

<script>
import {
  PROFILE_LINKS,
  PROFILE_LINKS_I18N,
} from '@shared/components/profile/constants';
import { mapGetters } from 'vuex';
import { TEL_NUM } from '@services/support/constants';
import { SupportService } from '@services/support';
import { FAQService } from '@services/faq';
import debounce from 'debounce';
import SidebarLinkTitle from './components/SidebarLinkTitle.vue';
import HorizontalScroll from './components/HorizontalScroll.vue';
import VerticalScroll from './components/VerticalScroll.vue';

export default {
  name: 'ProfileSidebar',
  components: {
    SidebarLinkTitle,
    HorizontalScroll,
    VerticalScroll,
    SupportCenter: () => import('./components/SupportCenter.vue'),
    SidebarLinkChilds: () => import('./components/SidebarLinkChilds.vue'),
  },
  data() {
    return {
      phoneNumber: TEL_NUM,
      links: [...PROFILE_LINKS],
    };
  },
  computed: {
    ...mapGetters('cashbacks', ['hasCashbacksProgram', 'hasCashbacksLinks']),
    ScrollComp() {
      return this.$isDesktop ? VerticalScroll : HorizontalScroll;
    },
    compLinks() {
      let links = this.links;
      const removeByName = name => {
        links = links.filter(v => v.name !== name);
      };
      if (!this.$isWebview) {
        removeByName('myPromocodes');
      }
      if (!this.hasCashbacksProgram && !this.hasCashbacksLinks) {
        removeByName('cashbacks');
      }
      return links;
    },
  },
  mounted() {
    this.loadFAQ();
  },
  methods: {
    onPhone: debounce(function () {
      SupportService.clickSupport('call');
    }, 300),
    onWhatsapp: debounce(function () {
      SupportService.clickSupport('whatsapp');
    }, 300),
    isActive(item) {
      return (
        item.routeName === this.$route.name ||
        (item.keywords && item.keywords.includes(this.$route.name))
      );
    },
    navigateTo(item) {
      const query = {};
      if (item.routeName === 'cashbacks') {
        query.from = 'profile-sidebar';
      }
      if (item.childs.length) {
        item.toggled = !item.toggled;
      }

      if (this.$route.name !== item.routeName)
        this.$router.push({
          name: item.routeName,
          query,
        });
    },
    scrollToId(id) {
      let el = document.getElementById(id);
      if (!el || !window) return;

      let y =
        el.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        120;

      window.scrollTo({
        behavior: 'smooth',
        top: y,
      });
    },
    loadFAQ() {
      FAQService.getCategories();
    },
    loadFAQCatagoryLinks(data) {
      let faqIndex = this.compLinks.findIndex(l => l.routeName === 'faq');
      let faqLink = this.compLinks[faqIndex];

      if (!faqLink || !data.length) return;

      faqLink.toggled = this.$route.name === faqLink.routeName;
      faqLink.childs = data.map(cat => {
        return {
          title: cat.name,
          id: cat.id,
        };
      });
    },
  },
  eventBusEvents: {
    eb_on_faq_categories_load(data) {
      this.loadFAQCatagoryLinks(data);
    },
  },
  i18n: {
    messages: {
      ru: {
        ...PROFILE_LINKS_I18N.messages.ru,
        myPromocodes: 'Мои промокоды',
        inviteFriend: 'Получить промокод',
      },
      kk: {
        ...PROFILE_LINKS_I18N.messages.kk,
        myPromocodes: 'Промокодтар',
        inviteFriend: 'Промокод алу',
      },
    },
  },
};
</script>

<style scoped></style>
