<template>
  <div class="StepIndicator flex items-center pl-4 pb-9">
    <div class="StepIndicator__icon">
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="18" :fill="colors['1'].bg" />
        <path
          d="M21.2126 9.32H17.3486L12.3806 12.464L13.9406 15.488L16.8686 13.832V26H21.2126V9.32Z"
          :fill="colors['1'].fg"
        />
      </svg>
    </div>
    <div class="StepIndicator__sep px-3">
      <svg
        width="150"
        height="8"
        viewBox="0 0 150 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="2.5"
          y1="4"
          x2="147.5"
          y2="4"
          stroke="#D4D3DF"
          stroke-width="4"
          stroke-miterlimit="5.75877"
          stroke-linecap="round"
          stroke-dasharray="4 12"
        />
      </svg>
    </div>
    <div class="StepIndicator__icon">
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="36" height="36" rx="18" :fill="colors['2'].bg" />
        <path
          d="M11.5727 11L13.3487 14.336C14.4527 13.592 15.6527 13.112 16.8767 13.112C18.1967 13.112 19.1327 13.832 19.1327 14.888C19.1327 15.992 18.2447 16.88 16.9007 18.08L11.8847 22.52V26H24.1007V22.064H17.7887L20.3327 19.952C22.3487 18.272 23.5727 16.784 23.5727 14.336C23.5727 11.576 21.6767 9.056 17.3567 9.056C15.1247 9.056 12.8447 9.968 11.5727 11Z"
          :fill="colors['2'].fg"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepIndicator',
  components: {},
  props: {
    step: {
      type: String,
      default: '1',
    },
  },
  computed: {
    colors() {
      const active = { bg: '#FFA100', fg: '#FFFFFF' };
      const inactive = { bg: '#F1F2F7', fg: '#D4D3DF' };

      return {
        1: this.step === '1' ? active : inactive,
        2: this.step === '2' ? active : inactive,
      };
    },
  },
};
</script>
