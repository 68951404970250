<template>
  <div class="CategoryNPSPlank">
    <img :src="iconHandPng" alt="" class="CategoryNPSPlank__iconHand" />
    <div class="CategoryNPSPlank__title" v-html="$t('title')" />
    <ArrowIconSvg />
  </div>
</template>

<script>
import ArrowIconSvg from './icons/arrow.svg';
import HandIconPng from './icons/hand.png';

export default {
  name: 'CategoryNPSPlank',
  components: { ArrowIconSvg },
  computed: {
    iconHandPng: () => HandIconPng,
  },
  i18n: {
    messages: {
      kk: {
        title: 'Ассортимент туралы сауалнамадан&nbsp;өтіңіз',
      },
      ru: {
        title: 'Пройдите опрос об&nbsp;ассортименте',
      },
    },
  },
};
</script>

<style scoped>
.CategoryNPSPlank {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  padding: 8px 12px 8px 56px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  box-shadow:
    0px 107.707px 107.707px 0px rgba(0, 0, 0, 0.03),
    0px 3.512px 44.998px 0px rgba(0, 0, 0, 0.04),
    0px -3.512px 24.058px 0px rgba(0, 0, 0, 0.03),
    0px 6.783px 13.487px 0px rgba(0, 0, 0, 0),
    0px 2.814px 7.163px 0px rgba(0, 0, 0, 0.02),
    0px 0.826px 2.981px 0px rgba(0, 0, 0, 0.01);
}

.CategoryNPSPlank__iconHand {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 56px;
  height: 64px;
}
</style>
