import {
  ERR_NO_DELIVERY,
  ERR_NO_ADDRESS,
  // ERR_INCORRECT_ADDRESS,
  ERR_NO_ACTIVE_WAREHOUSE,
} from '@shared/config/delivery';

export function validateState(state) {
  if (state.user.deliveryAddress == null) {
    return {
      invalid: true,
      err: new Error(ERR_NO_ADDRESS),
    };
  }

  // if (
  //   !/.*[, ][0-9].*/.test(state.user.deliveryAddress.street) ||
  //   state.user.deliveryAddress.is_private_house === 0
  // ) {
  //   return {
  //     invalid: true,
  //     err: new Error(ERR_INCORRECT_ADDRESS),
  //   };
  // }

  if (
    !state.delivery.isFailedToFindWarehouse &&
    state.delivery.warehouse == null
  ) {
    return {
      invalid: true,
      err: new Error(ERR_NO_DELIVERY),
    };
  }

  if (state.delivery.noActiveWarehouse) {
    return Promise.reject(new Error(ERR_NO_ACTIVE_WAREHOUSE));
  }

  return {
    invalid: false,
    err: null,
  };
}

export const calculateTotalPrice = products => {
  return products.reduce(
    (total, product) => total + product.quantity * product.price,
    0
  );
};
