<template>
  <div class="favorites-empty pt-4">
    <img
      src="@img/no-favorites.jpg"
      alt=""
      class="block m-auto"
      style="max-width: 343px; padding-bottom: 11px"
    />
    <p
      class="font-bold text-lg text-center leading-tight"
      style="margin: auto; max-width: 320px"
    >
      <span v-html="$t('favoritesProductsWillAppearHere')" />
    </p>
    <UiButtonNew
      class="mt-5 ml-auto mr-auto"
      style="width: 227px; display: block"
      @click="$emit('onGoToMain')"
    >
      <b class="font-bold text-lg">
        {{ $t('toMain') }}
      </b>
    </UiButtonNew>
  </div>
</template>

<script>
import UiButtonNew from '@ui/UiButtonNew.vue';

export default {
  name: 'FavoritesEmpty',
  components: { UiButtonNew },
  i18n: {
    messages: {
      ru: {
        favoritesProductsWillAppearHere: `
          Здесь появятся ваши<br>
          избранные товары
        `,
        toMain: 'На главную',
      },
      kk: {
        favoritesProductsWillAppearHere: `
          Мұнда сіздің таңдаулы<br>
          тауарларыңыз тұрады
        `,
        toMain: 'Басты бетке өту',
      },
    },
  },
};
</script>

<style scoped></style>
