import { client } from '../../clients';

/**
 * Выдача заказа по аиди
 */
export default function (id) {
  return client.get(`/lavka/v1/order/full/${id}`).then(res => {
    return res.data.data;
  });
}
