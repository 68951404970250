<template>
  <div>
    <p class="px-5 text-lg md:text-2xl font-bold text-center">
      {{ $t('leaveTips') }}
    </p>
    <div class="mt-4 px-5 flex items-start space-x-2">
      <img
        :src="picture || defaultCourierIcon"
        class="w-12 h-12 rounded-full flex items-center justify-center"
        alt="courier-img"
      />
      <div class="w-full">
        <h3 class="font-medium duration-1000">
          {{ name || $t('ryadomCourier') }}
        </h3>
        <p
          v-if="reactionText"
          class="mt-1 text-sirius-gray-100 text-sm line-clamp-3"
        >
          {{ reactionText }}
        </p>
        <div v-else class="mt-1 flex items-center h-5">
          <div class="dot-flashing"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { i18n } from '../i18n';

export default {
  nmae: 'TipsCourierView',
  components: {},
  props: {
    picture: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    reactionText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultCourierIcon: new URL(
        '@img/icons/courier-default.svg',
        import.meta.url
      ).href,
    };
  },
  i18n,
};
</script>

<style scoped lang="postcss">
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 18px;
  border-radius: 50%;
  background-color: #d4d3df;
  color: #d4d3df;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.2s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;

  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;

  animation: dotFlashing 1s infinite alternate;
}

.dot-flashing::before {
  left: -18px;
  color: #a4a2b7;
  background-color: #a4a2b7;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 18px;
  color: #e7e6ee;
  background-color: #e7e6ee;
  animation-delay: 0.4s;
}

@keyframes dotFlashing {
  0% {
    background-color: #a4a2b7;
  }
  50% {
    background-color: #d4d3df;
  }
  100% {
    background-color: #e7e6ee;
  }
}
</style>
