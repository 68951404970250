<template>
  <div class="">
    <div class="box-conditions" @click="onConditionsClick">
      <p class="box-conditions-text">
        {{ $isDesktop ? $t('conditionsMore') : $t('conditions') }}
      </p>
      <UiInlineIcon
        v-if="$isDesktop"
        name="cashbacks-arrow-bottom"
        class="box-conditions-icon box-conditions-icon__desk"
        :class="{ 'turn-up': toggled }"
      />
      <UiInlineIcon v-else name="cashbacks-arrow" class="box-conditions-icon" />
    </div>

    <UiCollapseAnimation :open="$isDesktop && toggled">
      <CondsBox class="mt-4 ml-4 space-y-2" :condition="condition" />
    </UiCollapseAnimation>

    <ConditionsModal v-model="showModal" :condition="condition" />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiCollapseAnimation from '@pure-ui/components/UiCollapseAnimation/UiCollapseAnimation.vue';
import ConditionsModal from './ConditionsModal.vue';
import CondsBox from './CondsBox.vue';

export default {
  name: 'Conditions',
  components: {
    UiCollapseAnimation,
    ConditionsModal,
    UiInlineIcon,
    CondsBox,
  },
  props: {
    condition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toggled: false,
      showModal: false,
    };
  },
  methods: {
    onConditionsClick() {
      if (this.$isDesktop) this.toggle();
      else this.openModal();
    },
    openModal() {
      this.showModal = true;
    },
    toggle() {
      this.toggled = !this.toggled;
    },
  },
  i18n: {
    messages: {
      kk: {
        conditions: 'шарттары',
        conditionsMore: 'қолдану шарттары',
      },
      ru: {
        conditions: 'условия',
        conditionsMore: 'условия применения ',
      },
    },
  },
};
</script>

<style scoped>
.box-conditions {
  @apply py-0.5 flex items-center cursor-pointer;
}
.box-conditions-text {
  @apply pb-0.5 text-sirius-gray-700 font-medium leading-4;
  height: 18px;
}
.box-conditions-icon {
  @apply transition-transform duration-300;
}
.box-conditions-icon__desk {
  @apply ml-1;
}
.turn-up {
  transform: rotate(180deg);
}
.box-conditions-content {
  @apply mt-4 text-sirius-gray-700;
}
.box-conditions-content-cond {
  @apply mt-2 flex space-x-3;
}
.box-conditions-content-cond-icon {
  @apply h-5 flex justify-center items-center;
}
</style>
