<template>
  <div class="CurrentScore">
    <div class="CurrentScore__container">
      <ContentBlock
        class="CurrentScore__block"
        :score="score"
        :place="place"
        :is-lottery="isLottery"
        v-on="$listeners"
      />
      <img
        v-if="!$isDesktop"
        class="CurrentScore__heart"
        src="./images/heart.png"
        width="204"
      />
      <img
        v-if="$isDesktop"
        class="CurrentScore__heart"
        src="./images/heart-d.png"
        width="355"
      />
    </div>
  </div>
</template>

<script>
import ContentBlock from './components/ContentBlock/ContentBlock.vue';

export default {
  name: 'CurrentScore',
  components: {
    ContentBlock,
  },
  props: {
    score: {
      type: Number,
      default: 0,
    },
    place: {
      type: Number,
      default: 0,
    },
    isLottery: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.CurrentScore {
  padding-top: 64px;
  min-height: 520px;
  overflow: hidden;
  margin-bottom: -120px;
}

.CurrentScore__container {
  position: relative;
  width: calc(100% - 24px);
  margin: auto;
  max-width: 400px;
}

.CurrentScore__block {
  max-width: 318px;
}

.CurrentScore__heart {
  position: absolute;
  left: 230px;
  top: 0;
}

@media screen and (min-width: 768px) {
  .CurrentScore {
    padding-top: 52px;
    min-height: calc(564px + 128px);
    margin-bottom: -196px;
  }
  .CurrentScore__container {
    max-width: 1188px;
  }

  .CurrentScore__block {
    max-width: 1188px;
  }

  .CurrentScore__heart {
    left: calc(100% - 223px);
    top: 62px;
  }
}
</style>
