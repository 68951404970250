<template>
  <FavoritesPage
    :loading="loading"
    :products="products"
    :filter-popup="filterPopup"
    :pagination="pagination"
    :added-from="PRODUCT_ADDED.FAVORITES"
    :category-nps-plank="
      !!($store.getters['categoryNps/currentSurvey'] && !$isDesktop)
    "
    @onGoToMain="$router.push({ name: 'main' })"
    @onFilterPopupSetShow="filterPopup.show = $event"
    @onFilterPopupSortChange="filterPopup.sortValue = $event"
    @onFilterPopupSubmit="onFilterPopupSubmit"
    @onNextPage="onNextPage"
    @backToRahmetApp="backToRahmetApp"
    @onConfirmClose="onConfirmClose"
    @onCategoryNPSPlank="onCategoryNPSPlank"
  />
</template>

<script>
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { PRODUCT_ADDED } from '@shared/config/product';
import { RahmetApp } from '@shared/RahmetApp';
import { CategoryNpsService } from '@/services/category-nps';

import FavoritesPage from './FavoritesPage.vue';
import { fetchFavoritesFromApi, logSegmentListView } from './utils';
import {getFilteredProducts} from "@/lib/coffee/get-filtered-products";

export default {
  name: 'FavoritesView',
  components: {
    FavoritesPage,
  },
  inject: ['popup'],
  data: () => ({
    products: [],
    loading: false,
    filterPopup: {
      show: false,
      sortValue: 'created_at:desc',
    },
    pagination: {
      loading: false,
      currentPage: 0,
      totalPages: 1,
      fetchedPages: [],
    },
    PRODUCT_ADDED,
  }),
  mounted() {
    Analitycs.logEvent(EVENTS.MAIN_FAVORITES, {
      from: this.$route.params.from,
    });
    this.fetchFavoritesInitial();
  },
  methods: {
    fetchFavoritesInitial() {
      this.reset();
      this.loading = true;
      this.fetchFavorites().finally(() => {
        this.loading = false;
      });
    },
    fetchFavorites(page = 1) {
      const { fetchedPages } = this.pagination;
      if (fetchedPages.includes(page)) {
        return Promise.resolve(null);
      }
      fetchedPages.push(page);
      return fetchFavoritesFromApi({
        limit: 100,
        sorting: this.filterPopup.sortValue,
        page,
      }).then(data => {
        const { products, pagination, logId } = data;
        this.products = getFilteredProducts(products);
        this.pagination.totalPages = pagination.total_pages;
        this.pagination.currentPage = pagination.current_page;
        logSegmentListView(logId, this.pagination.currentPage);
      });
    },
    onFilterPopupSubmit() {
      this.filterPopup.show = false;
      this.loading = true;
      this.fetchFavoritesInitial();
    },
    onNextPage() {
      const { currentPage, totalPages } = this.pagination;
      if (currentPage + 1 > totalPages) {
        return;
      }
      this.pagination.loading = true;
      this.fetchFavorites(currentPage + 1).finally(() => {
        this.pagination.loading = false;
      });
    },
    reset() {
      this.products = [];
      this.pagination = {
        loading: false,
        currentPage: 0,
        totalPages: 1,
        fetchedPages: [],
      };
    },
    backToRahmetApp() {
      if (this.$store.getters['cart/cartSize']) {
        Analitycs.logEvent(EVENTS.CROSS_EXIT_CONFIRM);
        this.popup.show('confirm-close-cart');
      } else {
        this.onConfirmClose();
      }
    },
    onConfirmClose() {
      Analitycs.logEvent(EVENTS.CROSS_EXIT, {
        from: 'favorites',
      });
      RahmetApp.hapticSelection();
      RahmetApp.backToRahmetApp();
    },
    onCategoryNPSPlank() {
      CategoryNpsService.analytics.logBannerClick({
        categoryId: this.$store.getters['categoryNps/currentSurvey'].categoryId,
        screen: 'Favorites Page',
      });
      this.$eventBus.emit('eb_open_category_nps_modal');
    },
  },
  eventBusEvents: {
    eb_on_address_change() {
      this.loading = true;
      this.fetchFavoritesInitial();
    },
  },
};
</script>
