const ProfileImg = new URL('@img/profile-icon.svg', import.meta.url).href;

export default {
  props: {
    photo: {
      type: String,
      default: '',
    },
  },
  render(h) {
    return h('picture', { class: 'ProfilePicture w-10 h-10 inline-block' }, [
      h('source', {
        class: 'w-full h-full rounded-full',
        attrs: {
          srcset: this.photo,
          width: '100%',
          height: '100%',
          alt: '',
        },
      }),
      h('img', {
        class: 'w-full h-full rounded-full',
        attrs: {
          src: ProfileImg,
          width: '100%',
          height: '100%',
          alt: '',
        },
      }),
    ]);
  },
};
