import { categoryFreshBanner } from './category-fresh-banner';
import { referralBanner } from './referral-banner';
import { mainFreshBanner } from './main-fresh-banner';
import { zonesBanner } from './zones-banner';
import { leaveAtDoorBanner } from './leave-at-door-banner';
import { deliveryBanner } from './delivery-banner';
import { assemblingBanner } from './assembling-banner';
import { cashbacksBanner } from './cashback-banner';
import { firstOrderDiscountBanner } from '@services/banners/static-banners/first-order-discount-banner';
import { categoryLamberBanner } from '@services/banners/static-banners/category-lamber-banner';

export const StaticBanners = {
  referralBanner,
  cashbacksBanner,
  categoryFreshBanner,
  firstOrderDiscountBanner,
  categoryLamberBanner,
  faq: {
    deliveryBanner,
    zonesBanner,
    assemblingBanner,
    mainFreshBanner,
    leaveAtDoorBanner,
  },
};
