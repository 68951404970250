export const defineGeolocation = ymaps =>
  new Promise((resolve, reject) =>
    ymaps.geolocation
      .get({ mapStateAutoApply: true, provider: 'browser', useMapMargin: true })
      .then(res => resolve(res.geoObjects.position))
      .catch(reject)
  );

export const geocodeSinglePosition = (ymaps, coords) =>
  new Promise((resolve, reject) =>
    ymaps
      .geocode(coords, { results: 1, json: true })
      .then(res => resolve(res.GeoObjectCollection))
      .catch(reject)
  );
