import { extractStories } from './extract-stories';

export function categoryBannerAdapter(v) {
  return {
    type: 'categoryBanner',
    code: v.code,
    categoryId: v.category_id,
    imageMobileKk: v.image_mobile_kk,
    imageMobileRu: v.image_mobile_ru,
    imageDesktopKk: v.image_desktop_kk,
    imageDesktopRu: v.image_desktop_ru,
    videoMobileKk: v.video_mobile_kk,
    videoMobileRu: v.video_mobile_ru,
    videoDesktopKk: v.video_desktop_kk,
    videoDesktopRu: v.video_desktop_ru,
    stories: extractStories(v),
  };
}
