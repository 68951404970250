<template>
  <p class="out-of-stock">
    {{ $t('outOfStock') }}
  </p>
</template>

<script>
export default {
  i18n: {
    messages: {
      kk: {
        outOfStock: 'Қазір жоқ',
      },
      ru: {
        outOfStock: 'Нет в наличии',
      },
    },
  },
};
</script>

<style>
.out-of-stock {
  @apply text-sirius-red-200 text-xs;
}
</style>
