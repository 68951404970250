import { isStreet } from './is-street';
import { isBuilding } from './is-building';
import { isDeliveryCity, parseDeliveryCity } from './is-delivery-city';
import { isDeliveryCountry } from './is-delivery-country';

export function validateGeoObject(geoObject) {
  const { Address, kind } =
    geoObject.GeoObject.metaDataProperty.GeocoderMetaData;
  geoObject.isInCountry = isDeliveryCountry(Address);
  geoObject.isInCity = isDeliveryCity(Address);
  geoObject.cityName = parseDeliveryCity(Address)?.name || '';
  geoObject.hasStreet = isStreet(kind);
  geoObject.hasBuilding = isBuilding(kind);
  return geoObject;
}
