<template>
  <div class="">
    <form
      class="px-4 py-6 border-sirius-gray-700 border-4 rounded-2xl"
      @submit.prevent="saveNewData"
    >
      <p class="mb-10 text-xl font-medium text-white text-center">
        If you need to create some LS data you can submit this form
      </p>

      <UiTextField
        v-model="lskey"
        size="md"
        type="text"
        label="Key for LS data"
        class="mb-4"
        name="lskey"
      />

      <UiTextField
        v-model="lsvalue"
        size="md"
        type="text"
        label="Value for LS data"
        class="mb-4"
        name="lsvalue"
      />

      <UiButton class="mt-4" variant="gray">Save</UiButton>
    </form>

    <p class="mt-10 mb-6 text-xl font-medium text-white text-center">
      If you type some thing in this inputs it will save it in localstorage
    </p>
    <div v-for="(value, key) in LStorage" :key="key" class="mb-5">
      <UiTextField
        type="text"
        :label="key"
        :value="value"
        size="md"
        :name="key"
        @input="onInput($event, key)"
      />
    </div>
  </div>
</template>

<script>
import debounce from 'debounce';
import UiTextField from '@pure-ui/components/UiTextField/UiTextField.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'StoreView',
  components: { UiTextField, UiButton },
  data() {
    return {
      LStorage: {},
      lskey: '',
      lsvalue: '',
    };
  },
  mounted() {
    this.getDataFromLocalStorage();
  },
  methods: {
    getDataFromLocalStorage() {
      this.LStorage = { ...localStorage };
    },
    onInput: debounce(function onInput(event, key) {
      window.localStorage.setItem(key, event.target.value);
      this.getDataFromLocalStorage();
    }, 300),
    saveNewData() {
      window.localStorage.setItem(this.lskey, this.lsvalue);
      this.getDataFromLocalStorage();
      this.lskey = '';
      this.lsvalue = '';
    },
  },
};
</script>
