<template>
  <section class="MmFooter">
    <div class="MmFooter__background">
      <div class="MmFooter__container">
        <img
          src="@/views/micromarket/images/logo.png"
          class="MmFooter__logo"
          width="159"
        />
        <div class="MmFooter__contacts">
          <WaLink class="MmFooter__wa" />
        </div>
        <AppStores class="MmFooter__stores" />
        <LegalBlock class="MmFooter__legal" />
        <SocialItems class="MmFooter__soc" />
      </div>
    </div>
  </section>
</template>

<script>
import SocialItems from './SocialItems.vue';
import WaLink from './WaLink.vue';
import LegalBlock from './LegalBlock.vue';
import AppStores from './AppStores.vue';

export default {
  name: 'MmFooter',
  components: {
    AppStores,
    SocialItems,
    WaLink,
    LegalBlock,
  },
};
</script>

<style>
.MmFooter {
  position: relative;
  background: #f1f2f7;
  margin-top: 100px;
  padding: 32px 0 148px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px 0;
}

.MmFooter__background {
  background: #f1f2f7;
}

.MmFooter__container {
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
  display: grid;
  gap: 40px;
}

@media (min-width: 768px) {
  .MmFooter {
    margin-top: 200px;
    padding-top: 72px;
    padding-bottom: 126px;
  }

  .MmFooter__container {
    max-width: 1188px;
    grid-template-columns: repeat(4, 1fr);
  }

  .MmFooter__soc {
    grid-column: 2;
  }

  .MmFooter__stores {
    grid-column: 3;
    grid-row: 1 / span 2;
  }
}
</style>
