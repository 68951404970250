<template>
  <MainLayout :with-footer="false">
    <template #header>
      <ToolBar v-if="isWebView" fixed :back="onBackClick">
        {{ constructToolbarTitle.title }}
      </ToolBar>
    </template>
    <ProfileLayout :container-width="488" :bread-links="links">
      <StepIndicator v-if="showSteps" step="1" />
      <h1 v-if="!isWebView" class="font-bold text-2xl pl-4 mb-4">
        {{ constructToolbarTitle.title }}
      </h1>
      <Order
        :id="id"
        :type="type"
        :reason="constructToolbarTitle"
        @order-loaded="showSteps = true"
      />
    </ProfileLayout>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { rFetchOrder } from '@shared/services/api/apis';
import { dateAndTime } from '@/utils/formatters/date';

import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import ProfileLayout from '@shared/components/layout/ProfileLayout.vue';
import StepIndicator from '../../components/StepIndicator.vue';
import Order from '../../components/Order.vue';

export default {
  name: 'ProblemsOrderView',
  components: {
    ProfileLayout,
    StepIndicator,
    MainLayout,
    ToolBar,
    Order,
  },
  inject: ['bottomSheetPopup'],
  beforeRouteLeave(to, from, next) {
    if (
      (to.name === 'order' || to.name === 'main') &&
      from.params.isFromFeedback === true
    ) {
      to.params.initReview = true;
      this.bottomSheetPopup.show(`feedback-modal::${this.id}`);
    }
    next();
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    route: {
      type: Object,
      default: () => {
        return {
          name: 'main',
        };
      },
    },
  },
  data() {
    return {
      order: null,
      showSteps: false,
    };
  },
  computed: {
    ...mapGetters(['isWebView']),
    constructToolbarTitle() {
      switch (this.type) {
        case 'stale':
          return {
            title: this.$t('somethingWrongWithProduct'),
            id: 1,
          };
        case 'incomplete':
          return {
            title: this.$t('notAllWasDelivered'),
            id: 3,
          };
        case 'notexactly':
          return {
            title: this.$t('wrongProductsWasDelivered'),
            id: 8,
          };
        case 'courier':
          return {
            title: this.$t('courierHasNotCome'),
          };
        case 'doublepay':
          return {
            title: this.$t('doubleOrderPayment'),
          };
        case 'notreturned':
          return {
            title: this.$t('moneyStillHadNotBeenReturned'),
          };
        case 'other':
          return {
            title: this.$t('anotherReason'),
          };
        default:
          return {
            title: this.$t('somethingWentWrong'),
            id: 0,
          };
      }
    },
    links() {
      const links = [
        { name: this.$t('home'), path: '/' },
        { name: this.$t('orders'), path: '/orders' },
      ];

      if (this.order) {
        links.push({
          name: dateAndTime(this.order.paid_at),
          path: `/order/${this.id}`,
        });

        links.push({
          name: this.$t(this.constructToolbarTitle.title),
          path: '#',
        });
      }

      return links;
    },
  },
  created() {
    this.fetchOrder();
  },
  methods: {
    onBackClick() {
      if (this.$route.query.isFeedback === true) {
        this.$router.push(this.route);
      } else {
        this.$router.go(-1);
      }
    },
    fetchOrder() {
      rFetchOrder(this.id)
        .then(data => (this.order = data))
        .catch(err => this.toast.show(err.message))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  i18n: {
    messages: {
      ru: {
        orders: 'Все заказы',
        home: 'Главная',
        somethingWentWrong: 'Что-то пошло не так',
        somethingWrongWithProduct: 'Что-то не так с товаром',
        notAllWasDelivered: 'Привезли не все товары',
        wrongProductsWasDelivered: 'Привезли не те товары',
        courierHasNotCome: 'Курьер не приехал',
        doubleOrderPayment: 'Двойная оплата заказа',
        moneyStillHadNotBeenReturned: 'Деньги все еще не вернули',
        anotherReason: 'Другая причина',
      },
      kk: {
        orders: 'Тапсырыстар',
        home: 'Басты бет',
        somethingWentWrong: 'Қателік бар',
        somethingWrongWithProduct: 'Тауарға байланысты мәселе туды',
        notAllWasDelivered: 'Тауарлар толық әкелінбеді',
        wrongProductsWasDelivered: 'Дұрыс емес тауарлар әкелінді',
        courierHasNotCome: 'Курьер келмеді',
        doubleOrderPayment: 'Тапсырыс екі рет төленді',
        moneyStillHadNotBeenReturned: 'Ақша әлі қайтарылған жоқ',
        anotherReason: 'Басқа себеп',
      },
    },
  },
};
</script>
