<template>
  <div>
    <div class="flex items-center">
      <IconFooterPhone class="mr-3" />
      <p class="font-bold" @click="$emit('supportClicked', 'call')">
        +{{ callNumber }}
      </p>
    </div>
    <div class="mt-5 flex items-start">
      <IconFooterWA class="mr-3" />
      <div class="">
        <a
          class="font-bold"
          target="_blank"
          @click="$emit('supportClicked', 'whatsapp')"
        >
          +{{ whatsAppNumber }}
        </a>
        <p class="text-sirius-gray-700">
          {{ $t('writeWH') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconFooterPhone from '@shared/components/icons/IconFooterPhone.vue';
import IconFooterWA from '@shared/components/icons/IconFooterWA.vue';
import { i18n } from '../utils';
import {SupportService} from "@services/support";

export default {
  name: 'FooterMobView',
  components: {
    IconFooterPhone,
    IconFooterWA,
  },
  data() {
    return {
      callNumber: SupportService.formattedSupportNumber('call'),
      whatsAppNumber: SupportService.formattedSupportNumber('whatsapp'),
    };
  },
  i18n,
};
</script>

<style></style>
