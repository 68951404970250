import { getAuthUrl } from './utils';

const checkSessionURL = `${import.meta.env.VITE_OAUTH_URL}/oauth/check_session`;
const loginURL = `${import.meta.env.VITE_OAUTH_URL}/oauth/login`;
const logoutURL = `${import.meta.env.VITE_OAUTH_URL}/oauth/logout`;

function createIframe(source, id) {
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', source);
  iframe.setAttribute('id', id);
  iframe.style.width = String(0);
  iframe.style.height = String(0);
  iframe.style.border = String(0);
  iframe.title = 'eco-system';
  iframe.style.position = 'absolute';
  return iframe;
}

function listen(cb) {
  window.addEventListener('message', cb, false);
}

function sendMessage(source, id) {
  return msg => {
    const iframeBlock = document.getElementById(id);
    iframeBlock.onload = () => {
      if (iframeBlock?.contentWindow) {
        iframeBlock.contentWindow.postMessage(msg, source);
      }
    };
  };
}

/**
 * Подключемся по урлу к сервису чоко для проверки авторизаций.
 *
 * Этот сервис общий для всех продуктов Чоко
 *
 * Создает айфрейм лоудит сайт в айфрейме и слушает ответ от сервера
 * @param {String} source это урл сервиса чоко
 */
function OpenIdConnect(source) {
  const self = this;
  self.iframeId = 'iframeOP';
  self.source = source;
  self.listen = listen;

  document.body.appendChild(createIframe(self.source, self.iframeId));

  self.sendMessage = sendMessage(self.source, self.iframeId);
}

function getQueriesFromUrl() {
  const urlQueries = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlQueries.entries());
}

function login(route = null) {
  const queries = getQueriesFromUrl();

  let redirectUrl = window.location.href.split('?')[0];
  redirectUrl = route ?? new URL(redirectUrl).pathname;

  const oauthUrl = new URL(loginURL);
  oauthUrl.searchParams.append(
    'client_id',
    import.meta.env.VITE_CHOCO_GATEWAY_CLIENT_ID
  );
  oauthUrl.searchParams.append('redirect_uri', getAuthUrl());
  oauthUrl.searchParams.append('login-by-mail', false);
  oauthUrl.searchParams.append(
    'state',
    btoa(JSON.stringify({ ...queries, route: redirectUrl }))
  );

  window.location.href = oauthUrl.href;
}

/**
 * Проверка авторизации в сервисе Чоко.
 * Если пользователь авторизован и переходить на страницу oauth
 * нет необходимости, возвращается no_auth, иначе auth
 */
function checkOauth() {
  return new Promise(resolve => {
    const op = new OpenIdConnect(checkSessionURL);

    op.sendMessage('OpenIdConnected');

    op.listen(e => {
      if (e.data === 'auth') {
        login();
      }
      if (e.data === 'no_auth') {
        resolve('no_auth');
      }
    });
  });
}

const logout = () => {
  const queries = getQueriesFromUrl();

  const oauthLogout = new URL(logoutURL);
  oauthLogout.searchParams.append(
    'state',
    btoa(JSON.stringify({ ...queries, route: '/auth', isLogout: true }))
  );

  window.location.href = oauthLogout.href;
};

export const WebAuthService = {
  checkOauth,
  logout,
  login,
};
