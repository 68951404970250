<template>
  <p class="card-title" :class="`line-clamp-${rows}`">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: 'HPCTitle',
  props: {
    rows: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.card-title {
  @apply text-sm leading-18 font-normal cursor-pointer;
}
</style>
