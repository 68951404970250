<template>
  <UiButton
    class="price-btn"
    :variant="variant === 'white' ? 'white' : 'primary'"
    :size="$isDesktop ? 'md' : 'sm'"
  >
    <slot> </slot>
  </UiButton>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  components: {
    UiButton,
  },
  props: {
    variant: {
      type: String,
      default: 'white',
      // default: 'orange',
    },
  },
};
</script>

<style scoped>
.price-btn {
  @apply w-full whitespace-nowrap rounded-xl
    font-medium text-sm leading-4 
    md:text-lg md:leading-6;
  padding: 4px 3px;
}
@media (min-width: 768px) {
  .price-btn {
    padding: 4px 8px;
  }
}
</style>
