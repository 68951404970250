<template>
  <div
    class="labels-container"
    :class="`--${type} hidden-scrollbar`"
    @click.stop
  >
    <slot v-for="label in labels" :label="label"></slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'column',
    },
    labels: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
.labels-container.--column {
  @apply flex flex-col;
}
.labels-container.--wrap {
  @apply flex flex-wrap;
}
.labels-container.--scrollable {
  @apply flex overflow-x-scroll;
}
</style>
