<template>
  <div class="MmDHeader">
    <div class="MmDHeader__container">
      <img
        class="shrink-0"
        src="@/views/micromarket/images/logo.png"
        alt=""
        width="150"
      />
      <div class="MmDHeader__content">
        <ul class="MmDHeader__linksWrap">
          <li v-for="link in links" :key="link.ru">
            <a
              href="#"
              class="MmDHeader__link"
              @click.prevent="onLinkClick(link.scrollTo)"
            >
              {{ link[$i18n.locale] }}
            </a>
          </li>
        </ul>
        <MmLangSwitcher class="MmDHeader__lang" />
        <MmButton class="MmDHeader__btn" @click="onButtonClick">
          {{ $t('leaveAppliance') }}
        </MmButton>
      </div>
    </div>
  </div>
</template>

<script>
import { menuItems } from '@/views/micromarket/utils/constants';
import MmButton from '@/views/micromarket/components/MmButton/MmButton.vue';
import { ScrollTo } from '@/views/micromarket/utils/scroll-to';
import MmLangSwitcher from '@/views/micromarket/components/MmLangSwitcher/MmLangSwitcher.vue';

export default {
  name: 'MmDHeader',
  components: { MmLangSwitcher, MmButton },
  data: () => ({
    links: menuItems,
  }),
  methods: {
    onLinkClick(id) {
      ScrollTo.elementId(id);
    },
    onButtonClick() {
      ScrollTo.form();
    },
  },
  i18n: {
    messages: {
      kk: {
        leaveAppliance: 'Өтінім қалдыру',
      },
      ru: {
        leaveAppliance: 'Оставить заявку',
      },
    },
  },
};
</script>

<style scoped>
.MmDHeader {
  background: rgba(255, 255, 255, 0.8);
  position: sticky;
  top: 0;
  backdrop-filter: blur(4px);
  z-index: 1;
  min-height: 104px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid var(--main-grey, #d4d3df);
  z-index: 10;
}

.MmDHeader__container {
  margin: auto;
  width: calc(100% - 32px);
  max-width: 1188px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MmDHeader__linksWrap {
  display: flex;
  gap: 0 32px;
}

.MmDHeader__content {
  display: flex;
  align-items: center;
}

.MmDHeader__lang {
  margin-left: 60px;
}

.MmDHeader__btn {
  margin-left: 20px;
}
</style>
