<template>
  <MainLayout
    class="text-sirius-black-100"
    with-checkout-reminder
    with-bottom-toolbar
    :sticky-header="!$isWebView && hasReferralPlank"
  >
    <template v-if="$isWebView" #header>
      <MainHeader
        @onSupport="openSupport"
        @onClose="onClose"
        @onSearch="goToSearch"
        @onFavorite="goFavorite"
      />
    </template>
    <!-- <template v-else-if="!$isDesktop" #header>
      <WebMobileHeaderMain />
    </template> -->
    <template v-if="!$isWebView" #header-wrapper>
      <ReferralPlankDesk
        v-if="hasReferralPlank"
        class="main-referral-plank-desk"
        @click.native="goToReferral"
      />
      <!-- Здесь есть баг когда есть реферальная плашка и шапка стики
      когда юзер возращяется на главную страницу
      то у него скрол ставится на прошле место где был
      и хедер становится стики и в этот момент иногда происходит баг со скролом
     -->
      <header
        class="main-header-desk"
        :class="[hasReferralPlank ? '--sticky' : '--fixed']"
      >
        <DeskHeader v-if="$isDesktop" />
        <WebMobileHeaderMain v-else />
      </header>
    </template>

    <div class="flex flex-col container mt-4 md:mt-16" style="max-width: 988px">
      <NoAddressReminderContainer />
      <div class="">
        <AddressConfirmationPopoverWrapper />
        <CommercialBannersWrapper ref="commercialBanners" />
        <ProductsReviewWrapper
          v-if="hasReviewProducts && !$isDesktop"
          class="mt-6 px-4"
          screen="main"
        />
      </div>

      <UiRippleLoader v-if="!hasMainCategories" class="mx-auto mt-16" />

      <div v-else class="mt-6 md:mt-16">
        <SaleCategory class="mb-7 md:mb-16" />

        <SpecialCategories class="mb-7 md:mb-16" />

        <!-- Тестовая группа, новая главная для миниаппки -->
        <template
          v-if="
            $store.getters['abtest/testsObj'].catalog_section === 'test' &&
            $isWebView
          "
        >
          <div v-if="hasReferralPlank" class="px-4 mt-6">
            <ReferralPlank @click.native="goToReferral" />
          </div>
          <TopCategories class="mt-6" />
        </template>
        <!-- Контрольная группа, старая главная для всех -->
        <template v-else>
          <SimpleCategories :categories="simpleCategories">
            <template #after-group="{ group }">
              <div v-if="group.id === 18 && $isWebView" class="px-4 mt-6">
                <ReferralPlank @click.native="goToReferral" />
              </div>
            </template>
          </SimpleCategories>
        </template>
      </div>

      <transition name="slide-up" appear>
        <div
          v-if="
            !isAddressLoading && (shouldRequireAddress || warehouse === null)
          "
          class="AddressReminder z-20 fixed left-0 bottom-0 w-full ease-in duration-300"
          :style="`transform: translateY(-${reminderPosition}px)`"
        >
          <AddressReminder
            :reason="shouldRequireAddress ? 'address' : 'warehouse'"
            @reminderClick="onAddressReminderClick"
          />
        </div>
      </transition>
      <OpenTime />
    </div>

    <ReferralWelcomeModalWrapper />

    <PromoModalWrapper />

    <LangList @onChange="onLocaleChange" />

    <ConfirmClose @confirm="closeMiniApp" @close="popup.hide()" />

    <UpButton />
    <WishButton :show-button="false" />
  </MainLayout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  Analitycs,
  EVENTS,
  SEGMENT_EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';
import { eventBus, BUS_EVENTS } from '@shared/utils/event-bus';
import { Logger as LOG } from '@shared/services/logger';
import { RahmetApp } from '@shared/RahmetApp';

import ReferralPlankDesk from '@pure-ui/components/Referral/ReferralPlank/ReferralPlankDesk.vue';
import ReferralWelcomeModalWrapper from '@components/referral/ReferralWelcomeModalWrapper.vue';
import ProductsReviewWrapper from '@components/product-review/ProductsReviewWrapper.vue';
import PromoModalWrapper from '@components/promo-modal/PromoModalWrapper.vue';

import ConfirmClose from '@shared/components/confirm-close/ConfirmClose.vue';
import DeskHeader from '@components/header/desk-header/DeskHeader.vue';
import WishButton from '@shared/components/catalog/WishButton.vue';
import UpButton from '@shared/components/product/UpButton.vue';
import MainHeader from '@/components/header/MainHeader.vue';
import SimpleCategories from '@/components/SimpleCategories/SimpleCategories.vue';
import TopCategories from '@/components/TopCategories/TopCategories.vue';
import LangList from '@shared/components/LangList.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import MainLayout from '@layout/MainLayout.vue';

import AddressConfirmationPopoverWrapper from './components/AddressConfirmationPopoverWrapper.vue';
import CommercialBannersWrapper from './components/CommercialBannersWrapper.vue';
import WebMobileHeaderMain from '@/components/header/WebMobileHeaderMain.vue';
import SpecialCategories from './components/SpecialCategories.vue';
import AddressReminder from './components/AddressReminder.vue';
import SaleCategory from './components/SaleCategory.vue';
import OpenTime from './components/OpenTime.vue';

export default {
  name: 'MainView',
  components: {
    TopCategories,
    ReferralPlank: () =>
      import('@pure-ui/components/Referral/ReferralPlank/ReferralPlank.vue'),
    NoAddressReminderContainer: () =>
      import('@components/address/NoAddressReminderContainer.vue'),
    AddressConfirmationPopoverWrapper,
    ReferralWelcomeModalWrapper,
    CommercialBannersWrapper,
    SimpleCategories,
    ProductsReviewWrapper,
    WebMobileHeaderMain,
    SpecialCategories,
    PromoModalWrapper,
    ReferralPlankDesk,
    AddressReminder,
    UiRippleLoader,
    SaleCategory,
    ConfirmClose,
    DeskHeader,
    WishButton,
    MainLayout,
    MainHeader,
    UpButton,
    OpenTime,
    LangList,
  },
  inject: ['toast', 'popup', 'bottomSheet'],

  beforeRouteLeave(to, from, next) {
    if (from.name === 'main' && to.name === 'category') {
      to.query.top =
        document.documentElement.scrollTop || document.body.scrollTop;
    }
    next();
  },
  data() {
    return {
      hasMiniBanners: 0,
      hasBanners: 0,
    };
  },
  computed: {
    ...mapGetters('user', [
      'shouldRequireAddress',
      'isAddressLoading',
      'userOrdersCount',
      'deliveryAddress',
    ]),
    ...mapGetters('cart', ['checkoutReminder', 'minAmountReminder']),
    ...mapGetters('delivery', ['warehouseId', 'warehouse']),
    ...mapGetters('productReview', ['hasReviewProducts']),
    ...mapGetters('catalog', ['hasMainCategories']),
    ...mapGetters('paidOrder', ['paymentConfigs']),
    ...mapGetters('abtest', ['getGroupByCode']),

    ...mapState('catalog', ['simpleCategories']),

    hasReferralPlank() {
      return this.$store.getters['user/hasReferralProgram'];
    },
    reminderPosition() {
      const isPaidDelivery = this.paymentConfigs && this.paymentConfigs.length;

      let pos = 0;

      if (this.$isDesktop) return 100;

      if (this.$isWebView) pos += 46;

      if (this.checkoutReminder) pos += 60;

      if (isPaidDelivery || this.minAmountReminder) pos += 34;

      return pos;
    },
  },
  watch: {
    warehouseId() {
      this.fetchCategories();
    },
  },
  created() {
    LOG.event('Main View');
    if (this.hasMainCategories) {
      this.configureFromState();
    } else {
      this.fetchCategories();
    }
  },
  beforeDestroy() {
    eventBus.$off(BUS_EVENTS.LOCALE_CHANGE);
  },
  mounted() {
    setTimeout(() => {
      return this.shouldRequireAddress
        ? Analitycs.logEvent(EVENTS.HOME_1)
        : Analitycs.logEvent(EVENTS.HOME_2, {
            address_id: this.deliveryAddress?.id || 'not defined',
          });
    }, 1500);

    Analitycs.logEvent(EVENTS.VIEWED_MAIN_PAGE);
    Analitycs.logSegmentEvent(SEGMENT_EVENTS.VIEW_MAIN_PAGE);
    Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_VIEWED_MAIN_PAGE, {
      dom_orders_count: this.userOrdersCount,
      dom_home: this.shouldRequireAddress ? 'home_1' : 'home_2',
      dom_warehouse_id: this.warehouseId,
    });

    eventBus.$on(BUS_EVENTS.LOCALE_CHANGE, this.onLocaleChange);
  },
  methods: {
    ...mapActions('catalog', {
      fetchMainCategories: 'FETCH_MAIN_CATEGORIES',
    }),
    scrollBackToCategory() {
      if (!this.$route.query.top) return;
      const { top } = this.$route.query;
      this.$nextTick(() => {
        window.scrollTo({ top });
      });
    },

    fetchCategories() {
      this.fetchMainCategories()
        .catch(err => {
          console.error(err);
          this.toast.show(err.message);
        })
        .finally(() => {
          this.scrollBackToCategory();
        });
    },
    configureFromState() {
      this.scrollBackToCategory();
    },
    onAddressReminderClick() {
      this.$eventBus.emit('eb_open_address_create_container', {
        from: 'no_address_plank',
      });
    },
    calcGrid(parentCategory, category) {
      const sizes = {
        1: 6,
        '1/2': 3,
        '1/3': 2,
        '2/3': 4,
      };
      let result = '1/7';
      if (parentCategory.block_size) {
        const add = parentCategory.block_size + sizes[category.block_size];
        if (add > 6) {
          parentCategory.block_size = sizes[category.block_size];
          result = `1/${sizes[category.block_size] + 1}`;
        } else {
          result = `${parentCategory.block_size + 1}/${add + 1}`;
          parentCategory.block_size = add;
        }
      } else {
        parentCategory.block_size = sizes[category.block_size];
        result = `1/${parentCategory.block_size + 1}`;
      }
      return result;
    },
    miniBannersLength(value) {
      this.hasMiniBanners = value;
    },
    bannersLength(value) {
      this.hasBanners = value;
    },
    onLocaleChange() {
      this.fetchCategories();
    },

    openSupport() {
      RahmetApp.hapticSelection();
      this.$eventBus.emit('eb_open_support_modal');
    },
    closeMiniApp() {
      Analitycs.logEvent(EVENTS.CROSS_EXIT, { from: 'main' });
      RahmetApp.hapticSelection();
      RahmetApp.backToRahmetApp();
    },
    onClose() {
      RahmetApp.hapticSelection();
      if (this.$store.getters['cart/cartSize']) {
        Analitycs.logEvent(EVENTS.CROSS_EXIT_CONFIRM);
        this.popup.show('confirm-close-cart');
        return;
      }

      this.closeMiniApp();
    },
    goFavorite() {
      RahmetApp.hapticSelection();
      this.$router.push({
        name: 'favorites',
        params: { from: 'main' },
      });
    },
    goToSearch() {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.SEARCH_CLICKED, { page: 'main' });
      this.$router.push({ name: 'search' });
    },
    goToReferral() {
      this.$router.push({ name: 'referral-share' });
    },
  },
  i18n: {
    messages: {
      ru: {},
      kk: {},
    },
  },
};
</script>

<style scoped>
.main-referral-plank-desk {
  @apply w-full;
}
.main-header-desk {
  @apply w-full sticky top-0 z-modal;
}
.--fixed.main-header-desk {
  @apply fixed;
}
</style>
