export const PaidOrderModule = {
  namespaced: true,
  state: {
    showPaymentConfig: false,
    paidDeliveryType: null,
    /**
     * Находится ли текущий выбранный адрес в расширенной зоне
     */
    isDistant: false,
    serviceFeePrice: null,
    defaultPaymentConfigs: null,
    highPricePaymentConfigs: null,
    /**
     * название промокода
     * @type {String} */
    freeDeliveryPromocode: '',
  },
  getters: {
    isHighPrice(state) {
      return !!state.highPricePaymentConfigs;
    },
    paymentConfigs(state, getters) {
      const { isHighPrice } = getters;
      return isHighPrice
        ? state.highPricePaymentConfigs
        : state.defaultPaymentConfigs;
    },
    freePaymentConfig(state, getters) {
      if (!getters.paymentConfigs) return null;
      return [...getters.paymentConfigs].pop();
    },
    minPriceForFreeDelivery(state, getters) {
      const { freePaymentConfig } = getters;
      return (freePaymentConfig && freePaymentConfig.price_from) || 0;
    },
    activePaymentConfig(state, getters, rootState, rootGetters) {
      const { paymentConfigs } = getters;
      if (!paymentConfigs) return null;
      const cartPrice = rootGetters['cart/cartTotalPrice'];

      return paymentConfigs.find(e => {
        const to = e.price_to ?? Number.MAX_VALUE;
        const from = e.price_from ?? 0;
        return cartPrice >= from && cartPrice <= to;
      });
    },
    /**
     * Достигла ли сумма в корзине предела, после которого
     * доставка становится бесплатной
     * @returns {Boolean}
     */
    isDeliveryFreeReached(state, getters, rootState, rootGetters) {
      return (
        rootGetters['cart/cartTotalPrice'] >= getters.minPriceForFreeDelivery
      );
    },
    showPaymentConfig(state) {
      return state.showPaymentConfig;
    },
    paidDeliveryType(state) {
      return state.paidDeliveryType;
    },
    isDistant(state) {
      return state.isDistant;
    },
    serviceFeePrice(state) {
      return state.serviceFeePrice;
    },
    freeDeliveryPromocode(state) {
      return state.freeDeliveryPromocode;
    },
  },
  mutations: {
    SET_PAID_DELIVERY_TYPE(state, value) {
      state.paidDeliveryType = value;
    },
    SET_SHOW_PAYMENT_CONFIG(state, value) {
      state.showPaymentConfig = value;
    },
    SET_IS_DISTANT(state, value) {
      state.isDistant = value;
    },
    SET_SERVICE_FEE_PRICE(state, value) {
      state.serviceFeePrice = value;
    },
    SET_DEFAULT_PAYMENT_CONFIGS(state, value) {
      state.defaultPaymentConfigs = value;
    },
    SET_HIGH_PRICE_PAYMENT_CONFIGS(state, value) {
      state.highPricePaymentConfigs = value;
    },
    SET_FREE_DELIVERY_PROMOCODE(state, value) {
      state.freeDeliveryPromocode = value;
    },
  },
};
