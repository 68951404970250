<template>
  <div class="">
    <UiTabs v-model="tab" class="mb-5" :tabs="tabs" />
    <div v-if="tab === 'state'" class="">
      <p class="mb-1 font-medium text-lg text-white">State</p>
      <div v-for="(item, index) in state" :key="`state_${index}`" class="mt-3">
        <div class="text-sm text-white">-- {{ item[0] }} --</div>
        <ShowMore :id="`state_${index}`" :data="{ key: item[1] }" />
      </div>
    </div>
    <div v-if="tab === 'getters'" class="">
      <p class="mb-1 font-medium text-lg text-white">Getters</p>
      <div
        v-for="(item, index) in getters"
        :key="`getters_${index}`"
        class="mt-3"
      >
        <div class="text-sm text-white">-- {{ item[0] }} --</div>
        <ShowMore :id="`state_${index}`" :data="{ key: item[1] }" />
      </div>
    </div>
  </div>
</template>

<script>
import UiTabs from '@shared/components/ui/UiTabs.vue';
import ShowMore from './ShowMore.vue';

export default {
  name: 'StoreView',
  components: {
    ShowMore,
    UiTabs,
  },
  props: {
    logs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tab: 'state',
      tabs: [
        { text: 'State', tab: 'state' },
        { text: 'Getters', tab: 'getters' },
      ],
      state: Object.entries(this.$store.state),
      getters: Object.entries(this.$store.getters),
    };
  },
};
</script>
