import { USERS_WITH_REAL_BG_OF_IMAGE } from '@shared/utils/product/mix-blend-mode';
import { DevConfigs } from '@/utils/dev-config';

export const UserModule = {
  namespaced: true,
  state: {
    developers: [...DevConfigs.developers],
    isGeoNearDelAddress: true,
    isAddressLoading: false,
    deliveryAddress: null,
    userLocation: null,
    addresses: [],
    user: null,
    ordersCount: 0,
    /**
     * Предположительный адрес юзера.
     * Показывается в модалке подтверждения адреса
     */
    presumptiveAddress: null,
  },
  getters: {
    addresses(state) {
      return state.addresses;
    },
    addressCount(state) {
      return state.addresses ? state.addresses.length : 0;
    },
    isAddressLoading(state) {
      return state.isAddressLoading;
    },
    shouldRequireAddress(state) {
      return !state.isAddressLoading && state.addresses.length === 0;
    },
    deliveryAddress(state) {
      return state.deliveryAddress;
    },
    findAddressById(state) {
      if (state.addresses && state.addresses.length > 0) {
        return id => state.addresses.find(x => x.id === id);
      }
      return undefined;
    },
    deliveryAddressId(state) {
      return state.deliveryAddress?.id;
    },
    addressesSize(state) {
      return state.addresses?.length;
    },
    isGeoNearDelAddress(state) {
      return state.isGeoNearDelAddress;
    },
    userProfile(state) {
      return state.user;
    },
    isDeveloper(state, getters) {
      const isUserInDevelopers = state.developers.includes(
        getters.userProfile?.user_id
      );
      return isUserInDevelopers;
    },
    userLocation(state) {
      return state.userLocation;
    },
    userOrdersCount(state) {
      return +state.ordersCount || 0;
    },
    /**
     * нужен для того что б показывать точки входа для ревералок
     */
    hasReferralProgram() {
      return true;
    },
    /**
     * нужен для того что б был доступен промокод по ревералки
     */
    hasReferralPromocode(state, getters) {
      const count = getters.userOrdersCount;
      return getters.hasReferralProgram && count >= 3;
    },
    hasRealBgOfPicture(state) {
      const userId = state.user?.user_id || null;
      return userId && USERS_WITH_REAL_BG_OF_IMAGE.includes(userId.toString());
    },
    presumptiveAddress(state) {
      return state.presumptiveAddress;
    },
  },
  mutations: {
    SET_ADDRESS_LOADING_STATE(state, value) {
      state.isAddressLoading = value;
    },
    SET_ADDRESSES(state, value) {
      state.addresses = value;
    },
    ADD_ADDRESS(state, value) {
      const addresses = [...state.addresses];
      addresses.push(value);
      state.addresses = addresses;
    },
    UPDATE_ADDRESS(state, value) {
      const addresses = [...state.addresses];
      const index = addresses.findIndex(add => add.id === value.id);
      addresses[index] = value;
      state.addresses = addresses;
    },
    SET_DELIVERY_ADDRESS(state, value) {
      state.deliveryAddress = value;
    },
    REMOVE_ADDRESS(state, id) {
      state.addresses = state.addresses.filter(x => x.id !== id);
    },
    SET_NEAR_TO_DELIVERY_ADDRESS(state, value) {
      state.isGeoNearDelAddress = value;
    },
    SET_USER_LOCATION(state, value) {
      state.userLocation = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    UPDATE_USER(state, value) {
      state.user = { ...state.user, ...value };
    },
    SET_ORDERS_COUNT(state, value) {
      state.ordersCount = value;
    },
    SET_PRESUMPTIVE_ADDRESS(state, value) {
      state.presumptiveAddress = value;
    },
  },
};
