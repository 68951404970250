<template>
  <div v-show="isReallyActive" class="">
    <UiPopupDesk
      v-if="isDesktop"
      :name="name"
      :close-btn="closeBtn"
      :size="size"
      :close-btn-type="closeBtnType"
      @onClose="onClosePopup"
    >
      <slot> </slot>
    </UiPopupDesk>
    <UiBottomSheetV2
      v-else
      :name="name"
      :background-color="backgroundColor"
      @onClose="onCloseBottomSheet"
    >
      <BottomSheetWrapper
        :white-space="whiteSpace"
        :with-buttons="withButtons"
        :with-title="withTitle"
        :with-back-button="withBackButton"
      >
        <template #title>
          <slot name="title"></slot>
        </template>
        <slot></slot>
        <template #buttons>
          <slot name="buttons"></slot>
        </template>
      </BottomSheetWrapper>
    </UiBottomSheetV2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Logger as LOG } from '@shared/services/logger';
import BottomSheetWrapper from '@ui/bottom-sheet/BottomSheetWrapper.vue';
import UiPopupDesk from '@ui/ui-popup/UiPopupDesk.vue';
import UiBottomSheetV2 from '@ui/bottom-sheet/UiBottomSheetV2.vue';

export default {
  name: 'BottomSheetPopup',
  components: { UiBottomSheetV2, BottomSheetWrapper, UiPopupDesk },
  inject: ['bottomSheet', 'popup', 'bottomSheetPopup'],
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
    closeBtnType: {
      type: String,
      default: 'circle',
    },
    withBackButton: {
      type: Boolean,
      default: false,
    },
    withButtons: {
      type: Boolean,
      default: false,
    },
    onBack: {
      type: Function,
      default: null,
    },
    whiteSpace: {
      type: Boolean,
      default: true,
    },
    withTitle: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '#fff',
    },
  },
  data: () => ({
    isReallyActive: false,
  }),
  computed: {
    ...mapGetters(['isDesktop']),
    isActive() {
      const isOpening = this.bottomSheetPopup.state.activeName === this.name;
      if (isOpening) this.openModal();
      return isOpening;
    },
  },
  watch: {
    name(newVal) {
      if (this.bottomSheetPopup.state.activeName === newVal) {
        this.openModal();
      }
    },
    /**
     * костыль чтобы работала анимация
     * на вложенном UiBottomSheetV2.
     */
    isActive: {
      immediate: true,
      handler(v) {
        if (v) {
          this.isReallyActive = v;
        } else {
          setTimeout(() => {
            this.isReallyActive = v;
          }, 500);
        }
      },
    },
  },
  methods: {
    openModal() {
      this.$emit('onOpen');
      if (this.isDesktop) this.popup.show(this.name);
      else this.bottomSheet.show(this.name);
    },
    onClosePopup() {
      this.popup.hide();
      this.bottomSheetPopup.hide();
      LOG.event('BottomSheet-Popup onClosePopup');
      this.closeModal();
    },
    onCloseBottomSheet() {
      this.bottomSheet.hide();
      this.bottomSheetPopup.hide();
      LOG.event('BottomSheet-Popup onCloseBottomSheet');
      this.closeModal();
    },
    closeModal() {
      LOG.event('BottomSheet-Popup closeModal');
      this.bottomSheetPopup.hide();
      this.$emit('onClose');
    },
  },
};
</script>
