<template>
  <li
    class="p-4 flex items-center justify-between hover:text-sirius-orange-100 active:text-sirius-orange-200 cursor-pointer"
    @click="onLinkClick"
  >
    <slot class=""></slot>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="stroke-current"
        d="M10 16L14 12L10 8"
        stroke="#AAAAAA"
        stroke-width="2"
        stroke-linecap="square"
      />
    </svg>
  </li>
</template>

<script>
export default {
  name: 'ProblemType',
  props: {
    link: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    onLinkClick() {
      if (this.type) {
        this.$router.push({
          name: this.link,
          params: {
            id: this.orderId,
            type: this.type,
          },
        });
      } else {
        this.$router.push({
          name: this.link,
          params: {
            id: this.orderId,
          },
        });
      }
    },
  },
};
</script>
