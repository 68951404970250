import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { TEL_NUM, WH_NUM } from './constants';
import { RahmetApp } from '@shared/RahmetApp';

function clickSupport(type) {
  Analitycs.logEvent(EVENTS.HELP_ORDER, { type });

  if (type === 'call') {
    window.open(`tel:+${TEL_NUM}`, '_self');
  }
  if (type === 'whatsapp') {
    let url = `https://api.whatsapp.com/send?phone=${WH_NUM}`;
    if (RahmetApp.isWebView()) RahmetApp.openLink({ url });
    else window.open(url, '_blank');
  }
}

// Функция используется для того, чтобы преобразовать номер из вида 77071513870 в вид 7 707 151-38-70 (как на макете)

function formattedSupportNumber(type) {
  switch (type) {
    case 'whatsapp':
      return WH_NUM.replace(
        /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
        '$1 $2 $3-$4-$5'
      );
    case 'call':
      return TEL_NUM.replace(
        /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
        '$1 $2 $3-$4-$5'
      );
    default:
      return '';
  }
}

export const SupportService = {
  clickSupport,
  formattedSupportNumber,
};
