<template>
  <router-link
    :to="navigation"
    class="text-center rounded-2xl bg-sirius-gray-200 flex flex-col justify-center items-center"
  >
    <IconArrorSeeMore />
    <div class="text-sm md:text-lg md:px-6 font-bold mt-3">
      {{ $t('toSeeMore') }}
    </div>
  </router-link>
</template>

<script>
import IconArrorSeeMore from '@shared/components/icons/IconArrorSeeMore.vue';

export default {
  name: 'SeeMore',
  components: { IconArrorSeeMore },
  props: {
    navigation: {
      type: Object,
      default: () => {
        return { name: 'main' };
      },
    },
  },
  i18n: {
    messages: {
      ru: {
        toSeeMore: 'Посмотреть ещё',
      },
      kk: {
        toSeeMore: 'Тағы қарау',
      },
    },
  },
};
</script>

<style scoped></style>
