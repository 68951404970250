<template>
  <UiTooltip v-if="$isDesktop" position="right-start" round="lg">
    <CheckoutTextConditions />
    <template #tip>
      <slot name="tooltip"> </slot>
    </template>
  </UiTooltip>
  <CheckoutTextConditions v-else />
</template>

<script>
import UiTooltip from '@pure-ui/components/UiTooltip/UiTooltip.vue';
import CheckoutTextConditions from './CheckoutTextConditions.vue';

export default {
  name: 'CheckoutTextTooltip',
  components: {
    CheckoutTextConditions,
    UiTooltip,
  },
};
</script>

<style></style>
