<template>
  <UiPopup
    :show="modelValue"
    class="RequestCityModal rcm"
    :radius="$isDesktop ? '4xl' : '3xl'"
    :closable="$isDesktop"
    @change="$emit('update:modelValue', $event)"
    @onClose="$emit('onClose')"
  >
    <p class="rcm__title" v-html="$t('whichCity')"></p>
    <div
      v-for="city in cities"
      :key="city.id"
      class="rcm__city"
      @click="$emit('onSelect', city)"
    >
      <p>{{ city.name }}</p>

      <IconArrow />
    </div>
  </UiPopup>
</template>

<script>
import UiPopup from '@pure-ui/components/UiPopup/UiPopup.vue';
import IconArrow from './components/IconArrow.vue';
export default {
  components: { UiPopup, IconArrow },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: Boolean,
    cities: {
      type: Array,
      default: () => [],
    },
  },
  i18n: {
    messages: {
      ru: {
        whichCity: 'В каком городе вы хотите сделать&nbsp;заказ?',
      },
      kk: {
        whichCity: 'Тапсырысты қай қалада жасағыңыз&nbsp;келеді?',
      },
    },
  },
};
</script>

<style scoped>
.rcm {
  --popup-width: 343px;
  --popup-width-d: 588px;
}
.rcm__title {
  @apply my-2 
  text-xl font-bold leading-6

  md:mt-1 md:mb-4 
  md:text-28 md:leading-8;
}
.rcm__city {
  @apply mb-2 last:mb-0 pt-4 pb-3 last:pb-2
    w-full border-b-2 last:border-b-0
    text-lg font-medium leading-6
    flex space-x-2 justify-between

    md:mb-3 md:last:mb-0 md:py-4 md:last:pb-0
    md:text-xl 
    md:last:border-b-0;
  border-color: rgba(241, 242, 247, 0.7);
}
</style>
