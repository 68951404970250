import { client } from '../../clients';
import { isAuthorizedUrl } from '../../utils/is-authorized-url';

export default function (isAuthorized = false) {
  return client
    .get(`/lavka/v1${isAuthorizedUrl(isAuthorized)}/user_addresses`, {
      useDeviceId: true,
    })
    .then(response => response.data);
}
