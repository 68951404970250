<template>
  <div class="AddressCheckBox checkbox" :class="{ '--active': active }">
    <svg
      v-show="active"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7297 7.94223L10.6135 16.0578L6.45068 11.8949"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: { active: Boolean },
};
</script>

<style scoped>
.checkbox {
  @apply flex items-center justify-center 
    bg-sirius-gray-200 rounded-full text-transparent
    transition-colors duration-300;
  width: 24px;
  height: 24px;
}
.checkbox.--active {
  @apply bg-sirius-orange-100 text-white;
}
</style>
