<template>
  <div class="SwitchRow">
    <UiInlineIcon name="promocode" class="SwitchRow__icon" />
    <div class="SwitchRow__label">
      {{ $t('iHavePromocode') }}
    </div>
    <UiToggleSwitch
      class="SwitchRow__switch"
      :value="open"
      @input="$emit('change', $event)"
    />
  </div>
</template>

<script>
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';
import UiToggleSwitch from '@pure-ui/components/UiToggleSwitch/UiToggleSwitch.vue';

export default {
  name: 'SwitchRow',
  components: { UiInlineIcon, UiToggleSwitch },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  i18n: {
    messages: {
      ru: {
        iHavePromocode: 'У меня есть промокод',
      },
      kk: {
        iHavePromocode: 'Менде промокод бар',
      },
    },
  },
};
</script>

<style scoped>
.SwitchRow {
  display: flex;
  padding-top: 12px;
}

.SwitchRow__label {
  padding-left: 12px;
  padding-top: 2px;
  line-height: 18px;
  letter-spacing: 0.08px;
}

.SwitchRow__switch {
  margin-left: auto;
}
</style>
