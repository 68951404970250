<template>
  <div class="CheckDoor">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="CheckDoor__icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3H18C19.105 3 20 3.895 20 5V11"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 21H5"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 11V13"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 5.00299V18.998C3 20.485 4.565 21.452 5.894 20.787L9.894 18.787C10.572 18.447 11 17.755 11 16.997V7.00299C11 6.24499 10.572 5.55299 9.894 5.21399L5.894 3.21399C4.565 2.54899 3 3.51599 3 5.00299Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.222 22H15.778C15.348 22 15 21.652 15 21.222V15.778C15 15.348 15.348 15 15.778 15H21.222C21.652 15 22 15.348 22 15.778V21.222C22 21.652 21.652 22 21.222 22Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 18H19"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <div class="CheckDoor__title" v-html="$t('title')" />
    <div class="CheckDoor__descr" v-html="$t('descr')" />
  </div>
</template>

<script>
export default {
  name: 'CheckDoor',
  i18n: {
    messages: {
      ru: {
        title: 'Проверьте заказ у&nbsp;двери',
        descr: 'К&nbsp;заказу применена опция «Оставить заказ у&nbsp;двери»',
      },
      kk: {
        title: 'Тапсырысты есік алдынан іздеп көріңіз',
        descr:
          'Тапсырысқа “Тапсырысты есік алдына қалдыру” опциясы қолданылған',
      },
    },
  },
};
</script>

<style scoped>
.CheckDoor {
  border-radius: 16px;
  background: rgba(255, 161, 0, 0.2);
  padding: 14px 20px 20px;
  display: grid;
  grid-template-columns: 24px auto;
  gap: 4px 9px;
}

.CheckDoor__icon {
  grid-row: 1 / 3;
  margin-top: 3px;
}

.CheckDoor__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.CheckDoor__descr {
  font-size: 14px;
  line-height: 20px;
}
</style>
