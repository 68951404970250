<template>
  <div
    v-scroll="{ onDirectionChanged: onScrollDirectionChanged }"
    class="DeskHeader header"
  >
    <div
      class="header-content"
      :class="{ '--hide': !showTop, '--paid': isPaidDelivery }"
    >
      <div class="header-first container">
        <LogoDesk />
        <NavList :links="links" />
      </div>
      <div class="header-second container">
        <LogoDesk v-show="!showTop" mini class="mr-5" />

        <CatalogBtn class="mr-5" @click.native="openCatalog" />

        <SearchTrigger class="mr-5" />

        <AddressContextDesk class="mr-5" />

        <LocaleToggle class="mr-5" />

        <ProfileIcon class="mr-5" />

        <FavoritesIcon class="mr-5" />

        <CartBtn v-if="totalPrice" :price="totalPrice" />

        <transition name="slide-up" @after-enter="paidOrderShown">
          <PaidDeliveryWrapper
            v-if="isPaidDelivery"
            class="header-paid-delivery"
            view="desk"
          />
        </transition>

        <MiniStatusContainer
          v-if="hasMiniStatuses"
          class="header-order-statuses"
        />
      </div>
    </div>
    <CatalogMenu :show-me="showCatalog" @onClose="closeCatalog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LINKS, LINKS_I18N } from '@shared/config/links';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import AddressContextDesk from '@shared/components/address/DeliveryContextDesk.vue';
import LocaleToggle from '@shared/components/localization/LocaleToggle.vue';
import SearchTrigger from '@shared/components/SearchTriggerDesk.vue';
import ProfileIcon from '@shared/components/header/ProfileIcon.vue';
import FavoritesIcon from './components/FavoriteIcon.vue';
import CatalogBtn from './components/CatalogBtn.vue';
import LogoDesk from './components/LogoDesk.vue';
import NavList from './components/NavList.vue';

import Scroll from '@/utils/directives/scroll';

export default {
  directives: { Scroll },
  components: {
    PaidDeliveryWrapper: () =>
      import('@components/paid-delivery/PaidDeliveryWrapper.vue'),
    MiniStatusContainer: () =>
      import('@components/orders/mini-status/MiniStatusContainer.vue'),
    CatalogMenu: () => import('@shared/components/catalog/CatalogMenu.vue'),
    CartBtn: () => import('./components/CartBtn.vue'),
    AddressContextDesk,
    FavoritesIcon,
    SearchTrigger,
    LocaleToggle,
    ProfileIcon,
    CatalogBtn,
    LogoDesk,
    NavList,
  },
  data() {
    return {
      showTop: true,
      showCatalog: false,

      isPaidDelivery: false,
      timer: null,
    };
  },
  computed: {
    ...mapGetters('orders', ['activeOrders']),
    ...mapGetters('cart', ['cartTotalPriceWithDiscount']),
    ...mapGetters('samplings', ['samplingTotalPrice']),
    ...mapGetters('paidOrder', ['showPaymentConfig']),
    links() {
      return LINKS.map(l => ({ ...l, name: this.$t(l.name) }));
    },
    totalPrice() {
      return this.cartTotalPriceWithDiscount + this.samplingTotalPrice;
    },
    hasMiniStatuses() {
      const routes = ['main', 'category', 'search'];
      const routeName = this.$route.name || '';
      return routes.includes(routeName) && this.activeOrders.length;
    },
  },
  watch: {
    showPaymentConfig: {
      immediate: true,
      handler(newVal) {
        this.onChangePaymentConfig(newVal);
      },
    },
  },
  methods: {
    onChangePaymentConfig(value) {
      clearTimeout(this.timer);

      if (value) {
        this.isPaidDelivery = true;
        return;
      }

      this.timer = setTimeout(() => {
        this.isPaidDelivery = false;
      }, 1500);
    },
    paidOrderShown() {
      Analitycs.logEvent(EVENTS.PAID_ORDER_SHOWN);
    },
    closeCatalog() {
      this.showCatalog = false;
    },
    openCatalog() {
      this.showCatalog = true;
      Analitycs.logEvent(EVENTS.CATALOG_BUTTON_CLICKED, {
        route: this.$route.name,
      });
    },
    onScrollDirectionChanged(v) {
      if (v === 'up') {
        this.showTop = true;
        this.$emit('showTop');
      } else {
        this.showTop = false;
        this.$emit('hideTop');
      }
    },
  },
  i18n: {
    messages: {
      ru: {
        ...LINKS_I18N.messages.ru,
      },
      kk: {
        ...LINKS_I18N.messages.kk,
      },
    },
  },
};
</script>

<style scoped>
.header-content {
  @apply py-6
    bg-white border-sirius-gray-300
    transition-all duration-300;

  border-bottom-width: 0.5px;
  transform: translateY(0px);
}
.--paid {
  @apply pb-11;
}
.--hide {
  transform: translateY(-68px);
}
.header-first {
  @apply flex items-center justify-between;
}
.header-second {
  @apply mt-7 relative w-full
  flex items-center justify-between;
}
.header-paid-delivery {
  @apply h-7 absolute right-0 -bottom-8
    flex items-center
    duration-500;
}
.header-order-statuses {
  @apply block absolute right-16 -bottom-20;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .header-first {
    max-width: unset;
  }
  .header-second {
    max-width: unset;
  }
}
</style>
