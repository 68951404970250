<template>
  <div
    class="ProductCard product-card"
    :class="{ '--desk': $isDesktop }"
    @click="$emit('onClickProduct')"
  >
    <!-- Блок с инфой -->
    <div
      class="product-card-image-container"
      :class="{ __fullWidth: fullWidthImage }"
    >
      <ProductPhoto
        :src="image"
        :mix-blend="!fullWidthImage && isMixBlend"
        :blured="isBlured"
        :full-width="fullWidthImage"
        class="product-card-image"
      />

      <VideoIcon v-if="hasVideo" class="product-photo-video" />

      <OverlayMsg v-if="!isAvailable">
        <div class="product-card-quantity">
          <p class="--text">{{ $t('ranOut') }}</p>
        </div>
      </OverlayMsg>
      <OverlayMsg v-else-if="quantity">
        <div class="product-card-quantity">
          <p :class="{ '--count': maxLimitReached }">
            {{ quantity }}
          </p>
          <p v-if="maxLimitReached" class="--text">
            {{ $t('maxLimit') }}
          </p>
        </div>
      </OverlayMsg>

      <!-- Лейблы товара -->
      <ProductLabels
        v-if="labels.length"
        :labels="labels"
        class="product-card-labels"
        @click.native.stop
      />
      <!-- / Лейблы товара -->

      <!-- Кнопки добавления в избранное -->
      <SaveBtn
        v-if="hasFavoriteBtn"
        :saved="isFavorite"
        :size="$isDesktop ? 'lg' : 'md'"
        class="product-card-save-btns"
        @click.native.stop
        @onSave="$emit('onSave')"
        @onRemove="$emit('onUnsave')"
      />
      <!-- / Кнопки добавления в избранное -->
    </div>
    <!-- / Блок с инфой -->

    <!-- Название товара и бренда -->
    <div class="product-card-info">
      <p
        class="product-card-title"
        :class="{ '--not-available': !isAvailable }"
      >
        {{ title }}
      </p>

      <p
        v-if="showBrandName"
        class="product-card-brand-title"
        :class="{ '--not-available': !isAvailable }"
      >
        {{ brandName }}
      </p>
    </div>

    <!-- / Название товара и бренда -->

    <!-- Кнопки -->
    <div v-if="isAvailable" class="product-card-btns" @click.stop>
      <PriceButton v-if="loading" variant="orange">
        <UiLoader variant="white" :size="$isDesktop ? 'md' : 'sm'" />
      </PriceButton>
      <PriceButton
        v-else-if="isWeightAdded"
        variant="primary"
        @click.native="onAdd('weight')"
      >
        <span class="whitespace-normal">{{ $t('added') }}</span>
      </PriceButton>
      <PriceButton v-else-if="isWeight" @click.native="onAdd('weight')">
        <span class="whitespace-normal">{{ $t('selectWeight') }}</span>
      </PriceButton>
      <PriceButton v-else-if="isWant" @click.native="onAdd('want')">
        {{ $t('iWant') }}
      </PriceButton>
      <PriceButton v-else-if="isReplace" @click.native="onAdd('replace')">
        {{ $t('toReplace') }}
      </PriceButton>
      <ProductCounter
        v-else-if="quantity"
        :model-value="quantity"
        :max="maxQuantity"
        :size="$isDesktop ? 'md' : 'xs'"
        @onIncrement="$emit('onIncrement', $event)"
        @onDecrement="$emit('onDecrement', $event)"
        @onMinLimit="$emit('onMinLimit')"
        @onMaxLimit="$emit('onMaxLimit')"
      >
        <p
          class="product-price"
          :class="`--length-${formatPrice(price).length}`"
        >
          {{ formatPrice(price) }} <span>₸</span>
        </p>
      </ProductCounter>
      <PriceButton v-else-if="oldPrice" @click.native="onAdd('sale')">
        <SalePrice
          :price="formatPrice(price)"
          :old-price="formatPrice(oldPrice)"
          :size="$isDesktop ? 'md' : 'xs'"
        />
      </PriceButton>
      <PriceButton v-else @click.native="onAdd('price')">
        {{ formatPrice(price) }} ₸
      </PriceButton>
    </div>
    <!-- / Кнопки -->
  </div>
</template>

<script>
import { formatPrice } from '@/utils/formatters/price';
import PriceButton from './components/PriceButton.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';
import ProductCounter from '@pure-ui/components/Product/ProductCounter/ProductCounter.vue';

export default {
  name: 'ProductCard',
  components: {
    ProductCounter,
    ProductPhoto,
    PriceButton,
    UiLoader,
    SalePrice: () => import('./components/SalePrice.vue'),
    VideoIcon: () => import('./components/VideoIcon.vue'),
    OverlayMsg: () => import('./components/OverlayMsg.vue'),
    SaveBtn: () => import('@pure-ui/components/Product/SaveBtn/SaveBtn.vue'),
    ProductLabels: () =>
      import('@pure-ui/components/Product/ProductLabels/ProductLabels.vue'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: 0,
    },
    oldPrice: {
      type: Number,
      default: 0,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    maxQuantity: {
      type: Number,
      default: 0,
    },
    isAvailable: {
      type: Boolean,
      default: true,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    hasFavoriteBtn: {
      type: Boolean,
      default: false,
    },

    hasVideo: Boolean,
    fullWidthImage: Boolean,
    isFavorite: Boolean,

    isReplace: {
      type: Boolean,
      default: false,
    },
    isWeight: {
      type: Boolean,
      default: false,
    },
    isWeightAdded: {
      type: Boolean,
      default: false,
    },
    isWant: {
      type: Boolean,
      default: false,
    },

    isMixBlend: {
      type: Boolean,
      default: true,
    },
    isBlured: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
    brandName: {
      type: String,
      default: null,
    },
    showBrandName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    maxLimitReached() {
      return this.maxQuantity > 0 && this.quantity === this.maxQuantity;
    },
  },
  methods: {
    formatPrice,
    onAdd(type) {
      this.$emit('onAdd', type);
    },
  },
  i18n: {
    messages: {
      ru: {
        ranOut: 'Ждём поступления',
        maxLimit: 'Больше нет',
        iWant: 'Хочу!',
        toReplace: 'Заменить',
        selectWeight: 'Выбрать вес',
        availableCount: 'в наличии {amount} шт.',
        availableYetCount: 'в наличии ещё {amount} шт.',
        added: 'Добавлено',
      },
      kk: {
        ranOut: 'Келуін күтудеміз',
        maxLimit: 'Қалмады',
        iWant: 'Мен қалаймын!',
        toReplace: 'Ауыстыру',
        selectWeight: 'Салмақты таңдаңыз',
        availableCount: '{amount} шт. бар',
        availableYetCount: 'тағы {amount} шт. бар',
        added: 'Себетте',
      },
    },
  },
};
</script>

<style scoped>
.product-card {
  @apply h-full flex flex-col
    rounded-2xl bg-sirius-gray-200
   text-sirius-black-100 cursor-pointer;
  height: 216px;
}

.product-card-quantity {
  @apply font-bold text-4xl text-center;
  line-height: 44px;
}
.product-card-quantity .--count {
  @apply text-28 leading-8;
}
.product-card-quantity .--text {
  @apply text-base leading-5;
}

.product-card-info {
  @apply px-1 mt-1 flex flex-col h-full;
}

.product-card-image-container {
  @apply relative rounded-2xl;
  min-height: 109px;
  max-height: 109px;
}

.product-card-image {
  @apply w-full h-full;
  height: 109px;
}

.product-card-labels {
  @apply absolute top-1 left-1 z-20;
}

.save-icon {
  @apply absolute top-1 right-1 z-20
    md:top-2 md:right-3
    flex items-start justify-between;
}

.product-card-save-btns {
  @apply absolute top-1 right-1 z-20;
}

.product-card-title {
  @apply font-medium  text-xs leading-14 line-clamp-3;
  word-break: break-word;
}

.product-card-brand-title {
  @apply font-medium md:text-sm text-xxs md:leading-4 leading-3 text-sirius-gray-1000 line-clamp-1;
  word-break: break-word;
}

.--not-available.product-card-title {
  @apply text-sirius-gray-100;
}

.product-card-btns {
  @apply px-1 pb-1 flex flex-col mt-auto;
}

.--desk.product-card {
  @apply rounded-3xl;
  height: 320px;
  width: 100%;
  max-width: 188px;
}

.--desk .product-card-quantity {
  line-height: 64px;
  font-size: 56px;
}
.--desk .product-card-quantity .--count {
  font-size: 48px;
  line-height: 56px;
}
.--desk .product-card-quantity .--text {
  font-size: 24px;
  line-height: 30px;
}

.--desk .product-card-image-container {
  @apply px-3 rounded-3xl;
  min-height: 164px;
  max-height: 164px;
}

.--desk .product-card-image-container.__fullWidth {
  padding: 0;
}

.--desk .product-card-image {
  height: 164px;
}

.--desk .product-card-labels {
  @apply top-2 left-3;
}

.--desk .save-icon {
  width: 32px;
  height: 32px;
}

.--desk .product-card-save-btns {
  @apply top-2 right-3;
}

.--desk .product-card-title {
  @apply font-normal text-base leading-tight;
  letter-spacing: 0.005em;
}

.--desk .product-card-btns {
  @apply px-3 pb-4;
}

.--desk .product-card-info {
  @apply px-3.5 mt-1.5;
}

.product-photo-video {
  position: absolute;
  right: 4px;
  bottom: 6px;
}

@media (max-width: 420px) {
  .--length-6.product-price {
    font-size: 11px;
  }
}
@media (max-width: 374px) {
  .product-price {
    white-space: nowrap;
    font-size: 13px;
  }
  .product-price span {
    font-size: 12px;
  }
  .--length-6.product-price {
    font-size: 10px;
  }
}
@media (max-width: 359px) {
  .--length-6.product-price {
    font-size: 14px;
  }
  .product-price {
    font-size: 14px;
  }
  .product-price span {
    font-size: 14px;
  }
}
/* @media (max-width: 374px) {
  .product-price {
    white-space: nowrap;
    font-size: 13px;
  }
  .product-price span {
    font-size: 12px;
  }
}

@media (max-width: 359px) {
  .product-price {
    font-size: 14px;
  }
  .product-price span {
    font-size: 14px;
  }
} */
</style>
