<template>
  <div class="flex flex-col h-full text-sirius-black-100">
    <UiRippleLoader v-if="loading" class="mx-auto" />
    <template v-else>
      <div class="pb-28">
        <p
          class="px-4 mt-4 mb-4 text-sirius-gray-700 leading-tight dark:text-white"
        >
          {{ $t(constructToolbarTitle.text) }}
        </p>
        <ProductItem
          v-for="(item, index) in order.items"
          :key="constructItemKey(item)"
          :product="item"
          :is-last="order.items.length - 1 === index"
          @onProductIncrement="onProductCounterChange"
          @onProductDecrement="onProductCounterChange"
        />
        <div
          class="mt-10 md:mt-10 w-full px-4 pt-2 pb-12 bg-white-new-100 dark:bg-night"
          :class="[$isWebView ? 'fixed bottom-0' : '']"
        >
          <UiButton
            :disabled="!hasCheckedItems"
            class="font-bold textx-lg w-full"
            size="lg"
            @click="onSendClick"
          >
            {{ $t('continue') }}
          </UiButton>
        </div>
      </div>
    </template>

    <!-- Похоже, старый попап, нужно удалить -->
    <UiPopup name="choose-products">
      <template #hide-description><div class=""></div> </template>
      <div class="py-6 px-4 text-black-new-500 dark:text-white">
        <h2 class="text-lg font-bold">{{ $t('provideProducts') }}</h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="text-sm mt-3" v-html="$t('provideProductsWithProblems2')"></p>
        <UiButton
          class="font-bold mt-6 w-full"
          size="lg"
          @click="popup.hide('choose-products')"
        >
          {{ $t('iGotIt') }}
        </UiButton>
      </div>
    </UiPopup>
  </div>
</template>

<script>
import { rFetchOrder } from '@shared/services/api/apis';

import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiButton from '@ui/UiButtonNew.vue';
import UiPopup from '@ui/ui-popup/UiPopup.vue';
import ProductItem from './ProductItem.vue';

export default {
  name: 'Stale',
  components: {
    UiButton,
    ProductItem,
    UiRippleLoader,
    UiPopup,
  },
  inject: ['toast', 'popup'],
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    reason: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      order: {},
      loading: true,
      products: {},
      features: {},
      items: {},
    };
  },
  computed: {
    constructToolbarTitle() {
      switch (this.type) {
        case 'stale':
          return {
            text: 'pleaseProvideProductsWithProblems',
            title: 'somethingWrongWithProduct',
            id: 1,
          };
        case 'incomplete':
          return {
            text: 'provideProductsNotDelivered',
            title: 'notAllWasDelivered',
            id: 3,
          };
        case 'notexactly':
          return {
            text: 'provideProductsWithProblems',
            title: 'wrongProductsWasDelivered',
            id: 8,
          };
        case 'courier':
          return {
            text: '',
            title: 'courierHasNotCome',
          };
        case 'doublepay':
          return {
            text: '',
            title: 'doubleOrderPayment',
          };
        case 'notreturned':
          return {
            text: '',
            title: 'moneyStillHadNotBeenReturned',
          };
        case 'other':
          return {
            text: '',
            title: 'anotherReason',
          };
        default:
          return {
            text: 'Что-то пошло не так',
            title: 'Что-то пошло не так',
            id: 0,
          };
      }
    },
    hasCheckedItems() {
      return !!Object.entries(this.items).length;
    },
  },
  created() {
    rFetchOrder(this.id)
      .then(data => {
        this.order = data;
        this.$emit('order-loaded');
      })
      .catch(err => this.toast.show(err.message))
      .finally(() => (this.loading = false));
  },
  methods: {
    onProductCounterChange(product, counter) {
      if (counter === 0) delete this.items[product.key];
      else if (this.items[product.key]) this.items[product.key] = counter;
      else this.items[product.key] = counter;

      // чтобы поле было реактивным
      this.items = { ...this.items };

      if (product.feature_id) {
        if (counter === 0) {
          if (this.features[product.product_id]) {
            delete this.features[product.product_id];
          }
        } else {
          this.features[product.product_id] = {
            ...product,
            counter,
          };
        }
      } else if (counter === 0) {
        if (this.products[product.product_id]) {
          delete this.products[product.product_id];
        }
      } else {
        this.products[product.product_id] = {
          ...product,
          counter,
        };
      }
    },
    onSendClick() {
      if (
        Object.keys(this.products).length === 0 &&
        Object.keys(this.features).length === 0
      ) {
        this.popup.show('choose-products');
        return;
      }

      const items = Object.entries(this.items).map(([key, value]) => {
        return { [key]: value };
      });

      this.$store.commit('orderProblems/SET_ITEMS', items);
      this.$store.commit('orderProblems/SET_PRODUCTS', this.products);
      this.$store.commit('orderProblems/SET_FEATURES', this.features);
      this.$router.push({
        name: 'problems-request',
        params: {
          id: this.id,
          type: this.type,
        },
      });
    },
    constructItemKey(item) {
      return item.feature_id
        ? `${item.product_id}_${item.feature_id}`
        : item.product_id;
    },
  },
  i18n: {
    messages: {
      ru: {
        pleaseProvideProductsWithProblems:
          'Пожалуйста, укажите товары, c которыми возникла проблема, и их количество',
        provideProductsNotDelivered:
          'Укажите товары, которые вам не привезли и их количество',
        provideProductsWithProblems:
          'Укажите товары, c которыми возникла проблема, и их количество',

        somethingWrongWithProduct: 'Что-то не так с товаром',
        notAllWasDelivered: 'Привезли не все товары',
        wrongProductsWasDelivered: 'Привезли не те товары',
        courierHasNotCome: 'Курьер не приехал',
        doubleOrderPayment: 'Двойная оплата заказа',
        moneyStillHadNotBeenReturned: 'Деньги все еще не вернули',
        anotherReason: 'Другая причина',

        continue: 'Продолжить',
        iGotIt: 'Понятно',
        provideProducts: 'Укажите продукты',
        provideProductsWithProblems2: `
          Пожалуйста укажите продукты, <br />
          с которыми возникли проблемы <br />
          и их количество.
        `,
      },
      kk: {
        pleaseProvideProductsWithProblems:
          'Мәселе туындаған тауарларды және олардың санын көрсетуіңізді сұраймыз',
        provideProductsNotDelivered:
          'Мәселе туған тауарды және оның санын көрсетіңіз',
        provideProductsWithProblems:
          'Қиындық туындаған тауарлар мен олардың санын көрсетуді сұраймыз',

        somethingWrongWithProduct: 'Тауарға байланысты мәселе туды',
        notAllWasDelivered: 'Тауарға байланысты мәселе туды',
        wrongProductsWasDelivered: 'Басқа тауар әкелген',
        courierHasNotCome: 'Курьер келмеді',
        doubleOrderPayment: 'Тапсырыс екі рет төленді',
        moneyStillHadNotBeenReturned: 'Ақша әлі қайтарылған жоқ',
        anotherReason: 'Басқа себеп',

        continue: 'Жалғастыру',
        iGotIt: 'Түсінікті',
        provideProducts: 'Өнімдерді еңгізіңіз',
        provideProductsWithProblems2: `
          Қиындық туындаған тауарлар  <br />
          мен олардың санын көрсетуді <br />
          сұраймыз
        `,
      },
    },
  },
};
</script>
