<template>
  <div></div>
</template>

<script>
import { Logger as LOG } from '@shared/services/logger';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'PushView',
  created() {
    LOG.event('Push Deeplink', this.$route.query);

    Analitycs.logEvent(EVENTS.PUSH_DEEPLINKS, {
      name: this.$route.query.name ?? '',
      link: this.$route.query.link,
    });
    if (this.$route.query.link) this.$router.push(this.$route.query.link);
    else this.$router.push({ name: 'main' });
  },
};
</script>
