<template>
  <div
    class="MinAmountReminder flex items-center cursor-pointer"
    @click="openHighDemandInfo"
  >
    <UiInlineIcon name="mini-flash" />

    <span class="ml-0.5 text-sm text-sirius-gray-700">
      {{ $t('notEnoughTillMin', { amount: formatPrice(minAmountReminder) }) }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatPrice } from '@shared/utils/formatters/price';
import { DeliveryEmitter, DELIVERY_EVENTS } from '@services/delivery/emitter';
import UiInlineIcon from '@pure-ui/components/UiInlineIcon/UiInlineIcon.vue';

export default {
  name: 'MinAmountReminder',
  components: {
    UiInlineIcon,
  },
  computed: {
    ...mapGetters('cart', ['minAmountReminder']),
  },
  methods: {
    formatPrice,
    openHighDemandInfo() {
      // Analitycs.logEvent(EVENTS.INFO_BTN_CLICKED, { type: 'high-demand' });
      DeliveryEmitter.emit(DELIVERY_EVENTS.OPEN_DELIVERY_CONDITIONS_MODAL, {
        clickedFrom: 'cart',
        clickedOn: 'mincheck-reminder',
        blockType: 'mincheck-reminder',
      });
    },
  },
  i18n: {
    messages: {
      ru: {
        notEnoughTillMin: 'до минимальной суммы не хватает {amount} ₸',
      },
      kk: {
        notEnoughTillMin: 'минималды сомаға {amount} ₸ жетпейді',
      },
    },
  },
};
</script>
