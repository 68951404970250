<script>
export default {
  name: 'IconSortingM',
  props: { modified: Boolean },
};
</script>

<template>
  <div class="IconSortingM">
    <svg
      v-if="!modified"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.667 9.3335L8.16699 5.8335L4.66699 9.3335"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.16732 22.1668V5.8335"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.334 18.667L19.834 22.167L23.334 18.667"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8333 5.8335V22.1668"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      v-if="modified"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.666 9.33301L8.16602 5.83301L4.66602 9.33301"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.16634 22.1663V5.83301"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.333 18.6665L19.833 22.1665L23.333 18.6665"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8324 5.83301V22.1663"
        stroke="#242424"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle
        cx="20"
        cy="8"
        r="6"
        fill="#FFA100"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<style scoped>
.IconSortingM {
}
</style>
