/* eslint-disable consistent-return, vue/return-in-computed-property */
export function getLegalText(locale) {
  if (locale === 'ru') {
    const link = `
          <a
            href="https://docs.google.com/document/d/16x0qXIUynHGna50e_4D5kOSUvFnvLPxSe71pmp-_b2Q/edit"
            class="FormScreen__legalLink"
            target="_blank"
          >
            Пользовательским соглашением
          </a>
        `;
    return `
          Продолжая я&nbsp;подтверждаю, что&nbsp;прочитал(а)
          и&nbsp;согласн(а) с&nbsp${link}
        `;
  }
  if (locale === 'kk') {
    const link = `
          <a
            href="https://docs.google.com/document/d/16x0qXIUynHGna50e_4D5kOSUvFnvLPxSe71pmp-_b2Q/edit"
            class="FormScreen__legalLink"
            target="_blank"
          >
            Қолданушы келісімін
          </a>
        `;
    return `
          Жалғастыра отырып ${link} оқығанымды
          және онымен келісетінімді растаймын
        `;
  }
}
