import { api } from '@shared/services/api';
import { AuthService } from '@shared/services/auth';

/**
 * Нужен для того что б не дожидаясь ответа от сервера
 * мы сразу же меняли значок что товар добавлен/удален в избранное
 * и если будет какая то ошибка
 * то мы заново получаем все избранные и убираем/добавляем значок
 *
 * Тем самым мы убираем лоудер при тогле избранных
 * @param {Object} ctx context store
 * @param {Number} id айди товара
 */
function toggle(ctx, id) {
  let copy = [...ctx.getters.favoritesIds];
  let index = copy.findIndex(v => v === id);
  if (index === -1) {
    copy.push(id);
  } else {
    copy.splice(index, 1);
  }
  ctx.commit('SET_FAVORITES', copy);
}

export const FavoritesModule = {
  namespaced: true,
  state: {
    favoritesIds: [],
  },
  getters: {
    favoritesCount: state => {
      return state.favoritesIds.length;
    },
    isFavorite: state => id => {
      return state.favoritesIds.includes(id);
    },
    favoritesIds: state => state.favoritesIds,
  },
  mutations: {
    SET_FAVORITES(state, value) {
      state.favoritesIds = value;
    },
  },
  actions: {
    ADD_TO_FAVORITES(ctx, { id }) {
      toggle(ctx, id);

      // eslint-disable-next-line dot-notation
      if (!ctx.rootGetters['isAuthorized']) {
        return AuthService.authorize();
      }
      return api.lavkaFavorites.favoritesProductsAdd(id).finally(() => {
        return ctx.dispatch('FETCH_FAVORITES_IDS');
      });
    },
    REMOVE_FROM_FAVORITES(ctx, { id }) {
      toggle(ctx, id);

      return api.lavkaFavorites.favoritesProductsRemove(id).finally(() => {
        return ctx.dispatch('FETCH_FAVORITES_IDS');
      });
    },
    FETCH_FAVORITES_IDS(ctx) {
      return api.lavkaFavorites
        .favoritesProductsList({ limit: 100 })
        .then(r => {
          const ids = r.data.items.map(v => v.product_id);
          ctx.commit('SET_FAVORITES', ids);
        });
    },
  },
};
