<template>
  <div class="">
    <BottomSheetPopup
      style="--modal-width: 588px"
      :name="`feedback-modal::${orderId}`"
      :white-space="false"
      close-btn
      @onClose="onClose"
    >
      <div class="mt-0.5 py-5 md:px-3 md:pt-12 md:pb-10">
        <UiRippleLoader v-if="loading" class="mx-auto" />
        <form v-else class="space-y-7" @submit.prevent="onSubmitNew">
          <ReviewBlock
            v-if="canLeaveFeedback"
            :comment="comment"
            :selected-rate="selectedRate"
            :selected-tags="selectedTags"
            @onSelectTag="onSelectTag"
            @onChangeRate="changeRatePoint"
            @onCommentClick="openCommentPopup"
          />
          <TipsBlock
            v-if="courier && canLeaveTip"
            :courier="courier"
            :is-not-leave-first="selectedRate && selectedRate.rate <= 3"
            @onChangeTipPrice="onChangeTipPrice"
          />
          <div class="px-5">
            <UiButton type="submit" size="lg" class="w-full font-bold text-lg">
              {{
                selectedRate && !isPositiveRate && !comment
                  ? $t('continue')
                  : $t('done')
              }}
            </UiButton>
          </div>
        </form>
      </div>
    </BottomSheetPopup>

    <ReviewPopup name="comment_popup" @onClose="onCloseReviewPopup" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { rLeaveReview, rLeaveTip } from '@shared/services/api/apis';
import { Analitycs, EVENTS } from '@shared/services/analitycs';
import { Logger as LOG } from '@shared/services/logger';
import { PaymentService } from '@services/payment';
import { computeDateDiff, getDefaultDateFormat } from '@/utils/formatters/date';

import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiButton from '@ui/UiButtonNew.vue';
import { i18n } from './i18n';

import BottomSheetPopup from '../ui/bottom-sheet-popup/BottomSheetPopup.vue';
import ReviewBlock from './components/ReviewBlock.vue';
import TipsBlock from './components/TipsBlock.vue';
import ReviewPopup from './ReviewPopup.vue';
import { ClientService } from '@shared/services/client';

export default {
  name: 'CustomerFeedback',
  components: {
    BottomSheetPopup,
    UiRippleLoader,
    ReviewBlock,
    ReviewPopup,
    TipsBlock,
    UiButton,
  },
  inject: ['popup', 'toast'],
  props: {
    order: {
      type: Object,
      default: null,
    },
    orderId: {
      type: String,
      default: '',
    },
    courier: {
      type: Object,
      default: null,
    },
    canLeaveFeedback: {
      type: Boolean,
      default: true,
    },
    canLeaveTip: {
      type: Boolean,
      default: true,
    },
    reviewType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tipPrice: 0,
      isCommentPopupOpenedOnSubmit: false,
      selectedRate: null,
      selectedTags: [],
      loading: false,
      comment: '',

      successMessages: [],

      isReviewShown: Boolean(this.canLeaveFeedback),
      isTipsShown: Boolean(this.courier && this.canLeaveTip),
      reviewAnalytics: null,
      tipsAnalytics: null,
    };
  },
  computed: {
    ...mapState('orderProblems', ['feedbackState']),
    ...mapGetters(['isDesktop', 'isWebView']),
    ...mapGetters('delivery', ['warehouseId']),
    isPositiveRate() {
      return this.selectedRate && this.selectedRate.rate > 3;
    },
  },
  mounted() {
    this.onComeFromProblems();
  },
  methods: {
    ...mapMutations('orderProblems', {
      setFeedbackPayload: 'SET_FEEDBACK_PAYLOAD',
      setTipsPayload: 'SET_TIPS_PAYLOAD',
      setSubmitPromise: 'SET_SUBMIT_PROMISE',
      setFeedbackState: 'SET_FEEDBACK_STATE',
      resetFeedbackState: 'RESET_FEEDBACK_STATE',
    }),
    resetTips() {
      this.tipPrice = 0;
    },
    onChangeTipPrice(price) {
      this.tipPrice = price;
    },
    openFeedbackModal() {
      if (this.isDesktop) this.popup.show(`feedback-modal::${this.orderId}`);
    },
    onClose() {
      const modalShownAt = getDefaultDateFormat(new Date());
      Analitycs.logEvent(EVENTS.FEEDBACK_MODAL_ENDED, {
        dayDiff: computeDateDiff(this.order.paid_at, modalShownAt),
        type: 'Closed',
        orderId: String(this.orderId),
        warehouseId: String(this.order.warehouse_id),

        isReviewShown: this.isReviewShown,
        isTipsShown: this.isTipsShown,
        ...this.reviewAnalytics,
        ...this.tipsAnalytics,
      });
      this.$emit('onClose');
    },
    openCommentPopup() {
      this.popup.show('comment_popup');
    },
    onCloseReviewPopup(value) {
      this.comment = value;
      if (this.isCommentPopupOpenedOnSubmit) {
        this.onSubmit();
        return;
      }
      this.openFeedbackModal();
    },
    spliceFromArray(array, object) {
      array.splice(
        array.findIndex(item => item === object),
        1
      );
      return array;
    },
    changeRatePoint(value) {
      this.resetComment();
      this.resetSelectedTags();
      this.selectedRate = value;
    },
    onSelectTag(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags = this.spliceFromArray([...this.selectedTags], tag);
      } else {
        this.selectedTags.push(tag);
      }
    },
    resetComment() {
      this.comment = '';
    },
    resetSelectedRate() {
      this.selectedRate = null;
    },
    resetSelectedTags() {
      this.selectedTags = [];
    },
    resetRateTags() {
      this.resetComment();
      this.resetSelectedTags();
      this.resetSelectedRate();
    },

    createWebPayload(paymentDeeplink) {
      const { name, params, query } = this.$route;
      const successUrl = this.$router.resolve({
        name,
        params,
        query: {
          ...query,
          tipsSuccessMessage: true,
          afterPayment: true,
        },
      }).href;
      const failureUrl = this.$router.resolve({
        name,
        params,
        query: { ...query, afterPayment: true },
      }).href;

      return {
        backlink: paymentDeeplink,
        successUrl,
        failureUrl,
      };
    },
    doPayment(deeplink) {
      const payload = {};
      if (this.isWebView) Object.assign(payload, { deeplink });
      else Object.assign(payload, this.createWebPayload(deeplink));

      return PaymentService.pay(payload)
        .then(() => {
          this.successMessages.push(this.$t('thanksForTips'));
          this.$emit('onTipDone');
          Analitycs.logEvent(EVENTS.TIPS_PAYMENT_DONE, {
            orderId: String(this.orderId),
          });
        })
        .catch(() => {
          this.onClose();
        });
    },
    buildFeedbackPayload() {
      const selectedTags = this.selectedTags.map(e => e.code);

      const body = {
        order_id: Number(this.orderId),
        warehouse_id: this.order.warehouse_id || this.warehouseId,
        comment: this.comment,
        tag_codes: selectedTags,
        userID: Number(ClientService.getUserId()),
        rating: this.selectedRate.rate,
      };

      this.setFeedbackPayload(body);
      return body;
    },
    submitingFeedback() {
      const payload = this.buildFeedbackPayload();
      return rLeaveReview(payload).then(res => {
        this.$emit('onFeedbackDone');
        this.successMessages.push(this.$t('thanksForReview'));
        const { attributes } = res.data;
        const selectedTags = this.selectedTags.map(e => e.code);

        this.isReviewShown = true;
        this.reviewAnalytics = {
          reviewDone: true,
          tags: selectedTags,
          rating: attributes.rating,
        };
        LOG.event('submitingFeedback', {
          res,
          analytics: this.reviewAnalytics,
        });
      });
    },
    buildTipsPayload() {
      const price = parseInt(this.tipPrice, 10);

      const body = {
        order_id: this.orderId,
        employee_id: this.courier.id,
        payment_method_id: 1,
        price,
        amount: price,
        idempotency_key: uuidv4(),
      };
      this.setTipsPayload(body);
      return body;
    },
    submitingTips() {
      const tipPrice = parseInt(this.tipPrice, 10);

      if (!this.courier?.id) return Promise.resolve();
      const payload = this.buildTipsPayload();
      return rLeaveTip(payload).then(res => {
        this.isTipsShown = true;
        this.tipsAnalytics = {
          tipDone: true,
          tipPrice,
          courierId: String(this.courier.id),
        };

        LOG.event('submitingTips', { res, analytics: this.tipsAnalytics });
        return this.doPayment(res.data.payment_deeplink);
      });
    },

    onComeFromProblems() {
      if (this.$route.params.initReview === true && this.feedbackState) {
        this.comment = this.feedbackState.comment;
        this.selectedTags = this.feedbackState.selectedTags;
        this.selectedRate = this.feedbackState.selectedRate;
        this.resetFeedbackState();
      }
    },
    goToProblems() {
      this.onClose();

      this.$router.push({
        name: 'problems-order',
        params: {
          id: this.orderId,
          type: 'stale',
          route: this.$route,
          isFromFeedback: true,
        },
      });
    },
    buildProblemsPayload() {
      this.buildFeedbackPayload();
      if (this.courier && this.order.delivery_type !== 1) {
        this.buildTipsPayload();
      }
      this.setSubmitPromise(this.onSubmit);
      this.setFeedbackState({
        selectedRate: this.selectedRate,
        selectedTags: this.selectedTags,
        comment: this.comment,
        isMain: this.$route.name === 'main',
      });
      this.goToProblems();
    },
    onSubmit() {
      if (!this.selectedRate && !this.tipPrice) {
        this.onClose();
        return;
      }

      const leaveReview =
        this.canLeaveFeedback && this.selectedRate
          ? this.submitingFeedback()
          : Promise.resolve();

      const leaveTip =
        this.canLeaveTip && this.tipPrice !== 0
          ? this.submitingTips()
          : Promise.resolve();

      this.loading = true;
      Promise.all([leaveReview, leaveTip])
        .then(res => {
          LOG.event('allSettled 2', {
            res,
            reviewAnalytics: this.reviewAnalytics,
            tipsAnalytics: this.tipsAnalytics,
          });

          const modalShownAt = getDefaultDateFormat(new Date());
          Analitycs.logEvent(EVENTS.FEEDBACK_MODAL_ENDED, {
            dayDiff: computeDateDiff(this.order.paid_at, modalShownAt),
            type: this.reviewType,
            orderId: String(this.orderId),
            warehouseId: String(this.order.warehouse_id),

            isReviewShown: this.isReviewShown,
            isTipsShown: this.isTipsShown,
            ...this.reviewAnalytics,
            ...this.tipsAnalytics,
          });
          this.$emit('onClose');

          this.successMessages.forEach(e => {
            this.toast.show(e, 'success');
          });
          this.resetSuccessMessages();
          this.resetRateTags();
          this.resetTips();
          this.resetFeedbackState();
        })
        .catch(err => this.toast.show(err.message, 'error'))
        .finally(() => {
          this.loading = false;
        });
    },
    resetSuccessMessages() {
      this.successMessages = [];
    },
    onSubmitNew() {
      if (!this.selectedRate && this.tipPrice === 0) {
        this.onClose();
        return;
      }

      const hasProblem = this.selectedTags.find(
        e => e.code === 'order_problem'
      );
      const createdAt = this.order.created_at;
      const dateDiff = computeDateDiff(createdAt);

      if (hasProblem && dateDiff <= 3) {
        this.buildProblemsPayload();
        return;
      }

      if (this.selectedRate && this.selectedRate.rate <= 3) {
        this.isCommentPopupOpenedOnSubmit = true;
        this.openCommentPopup();
        return;
      }
      this.isCommentPopupOpenedOnSubmit = false;
      this.onSubmit();
    },
  },
  i18n,
};
</script>
