<template>
  <div class="">
    <div class="modal-texts">
      <p class="modal-title" v-html="title"></p>
      <p class="modal-subtitle" v-html="subtitle"></p>
    </div>

    <TellMoreComment
      v-if="review && comment"
      :model-value="comment"
      class="modal-comment"
      @onComment="$emit('onComment')"
    />

    <CarouselPagination
      v-if="pagination.length > 1"
      class="modal-pagination"
      :length="pagination.length"
      :active="activeIndex"
      :done="pagination"
    />

    <div class="modal-bottom">
      <LikeDislike
        v-if="!review"
        variant="gray"
        class="modal-like-dislike"
        @onLike="$emit('onLike')"
        @onDislike="$emit('onDislike')"
      />

      <template v-else>
        <UiButton
          v-if="!comment"
          variant="gray"
          size="LG"
          class="modal-comment-btn"
          @click.native="$emit('onComment')"
        >
          {{ $t('leaveComment') }}
        </UiButton>
        <UiButton size="LG" @click.native="$emit('onGoNext')">
          {{ ready ? $t('ready') : $t('next') }}
        </UiButton>
      </template>
    </div>
  </div>
</template>

<script>
import LikeDislike from '@pure-ui/components/ProductReview/components/LikeDislike.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import CarouselPagination from './CarouselPagination.vue';
import TellMoreComment from './TellMoreComment.vue';
export default {
  name: 'ReviewProductsModalContent',
  components: {
    CarouselPagination,
    TellMoreComment,
    LikeDislike,
    UiButton,
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
    review: {
      type: String,
      default: '',
    },
    callBack: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    comment: {
      type: String,
      default: '',
    },
    pagination: {
      type: Array,
      default: () => [],
    },
    ready: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      if (this.review)
        return this.$t('thanksReviewProduct', { name: this.name });
      return this.$t('youLiked', { name: this.name });
    },
    subtitle() {
      if (this.callBack) return this.$t('weCallYou');
      return this.$t('reviewHelps');
    },
  },
  i18n: {
    messages: {
      kk: {
        youLiked: 'Сізге «{name}» <br/> өнімі ұнады ма?',
        thanksReviewProduct: '«{name}» <br/> өнімін бағалағаныңызға рақмет',

        reviewHelps:
          'Сіздің бағаңыз бізге сапалы өнім алуға <br/> және ұсынуға көмектеседі',
        weCallYou: 'Бұл өнім бойншы толығырақ білу үшін сізбен хабарласамыз',

        leaveComment: 'Түсініктеме қалдырыңыз',
        next: 'Келесі',
        ready: 'Дайын',

        thanksForReview: 'Бағалағаныңызға рақмет!',
      },
      ru: {
        youLiked: 'Вам понравился товар <br/> «{name}»?',
        thanksReviewProduct: 'Спасибо за оценку товара <br/> «{name}»',

        reviewHelps:
          'Ваши оценки помогают отбирать <br/> качественные товары в ассортимент',
        weCallYou: 'Мы свяжемся с вами для уточнения деталей по этому товару',

        leaveComment: 'Оставить комментарий',
        next: 'Далее',
        ready: 'Готово',

        thanksForReview: 'Спасибо за отзыв!',
      },
    },
  },
};
</script>

<style scoped>
.modal-texts {
  @apply mt-2 text-center;
  min-height: 138px;
}
.modal-texts__single {
  min-height: 170px;
}
.modal-title {
  @apply text-lg font-medium leading-6;
}
.modal-subtitle {
  @apply mt-3 text-sirius-gray-900  text-sm leading-22;
}
.modal-comment {
  @apply mt-4;
}
.modal-pagination {
  @apply mt-6 mb-6;
}
.modal-bottom {
  @apply mt-6 flex flex-col space-y-3;
}
.modal-comment-btn {
  @apply mt-2;
}
.modal-like-dislike {
  @apply pt-6;
}
</style>
