import { DevConfigs } from '@/utils/dev-config';
import { eventBus } from '@/utils/plugins/event-bus';
import {
  rFetchFAQCategories,
  rFetchFAQQuestions,
} from '@shared/services/api/requestsV2/faq';

const ROLE_CODE = DevConfigs.isProd ? 'user' : 'user';
const CATEGORY_TYPE = 'faq';

let categories = [];

async function getCategories() {
  if (!categories.length) {
    await fetchCategories();
  } else {
    eventBus.emit('eb_on_faq_categories_load', categories);
  }
}

function fetchCategories() {
  return rFetchFAQCategories(ROLE_CODE)
    .then(res => {
      categories = res.data.filter(cat => cat.role_code === ROLE_CODE);
      eventBus.emit('eb_on_faq_categories_load', categories);
      return categories;
    })
    .catch(() => []);
}

function fetchQuestions() {
  return rFetchFAQQuestions(CATEGORY_TYPE, ROLE_CODE)
    .then(res => {
      let faqs = res.data.map(q => {
        return {
          title: q.category_name,
          category_id: q.category_id,
          questions: q.faq,
        };
      });
      return faqs;
    })
    .catch(() => Promise.resolve(null));
}

export const FAQService = {
  getCategories,
  fetchQuestions,
};
