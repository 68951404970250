/* eslint-disable */

const clicked = [];

/**
 * Добавляет по сторонам элемента внизу две прозрачные кнопки.
 * Если нажать их в последовательности л-л-п-п — сработает обработчик
 */
export default {
  bind(el, binding, vnode) {
    const cb = v => {
      clicked.push(v);
      if (clicked.slice(-4).join('') === '1122') {
        binding.value();
      }
    };

    const btn1 = document.createElement('button');
    btn1.className = 'focus:outline-none absolute bottom-0 left-0 h-6 w-6';
    btn1.addEventListener('click', cb.bind(null, '1'));
    const btn2 = document.createElement('button');
    btn2.className = 'focus:outline-none absolute bottom-0 right-0 h-6 w-6';
    btn2.addEventListener('click', cb.bind(null, '2'));

    el.classList.add('relative');
    el.appendChild(btn1);
    el.appendChild(btn2);
  },
};
