<template>
  <section class="MmAdvantages">
    <MmSubtitle id="advantages"> <span v-html="$t('title')" /> </MmSubtitle>
    <ul class="MmAdvantages__list">
      <li
        v-for="item in items"
        :key="item[$i18n.locale].title"
        class="MmAdvantages__item"
      >
        <img
          v-if="!$isDesktop"
          :src="item.mImage"
          alt=""
          class="MmAdvantages__img"
          loading="lazy"
          width="156"
          height="156"
        />
        <img
          v-if="$isDesktop"
          :src="item.dImage"
          alt=""
          class="MmAdvantages__img"
          loading="lazy"
          width="170"
          height="170"
        />
        <div class="MmAdvantages__itemContent">
          <div class="MmAdvantages__title" v-html="item[$i18n.locale].title" />
          <div class="MmAdvantages__descr" v-html="item[$i18n.locale].descr" />
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import MmSubtitle from '@/views/micromarket/components/MmSubtitle/MmSubtitle.vue';
import Img1M from './images/m-1.png';
import Img1D from './images/d-1.png';
import Img2M from './images/m-2.png';
import Img2D from './images/d-2.png';
import Img3M from './images/m-3.png';
import Img3D from './images/d-3.png';
import Img4M from './images/m-4.png';
import Img4D from './images/d-4.png';

export default {
  name: 'MmAdvantages',
  components: { MmSubtitle },
  inject: [],
  props: {},
  data: () => ({
    items: [
      {
        mImage: Img1M,
        dImage: Img1D,
        ru: {
          title: 'Свежесть<br> и&nbsp;качество еды',
          descr: `
            Наш экспедитор пополняет холодильник с&nbsp;понедельника по&nbsp;пятницу.
            Если&nbsp;у&nbsp;чего-то истек срок годности&nbsp;— списываем.
        `,
        },
        kk: {
          title: 'Балғын өнімдер, сапалы тағам',
          descr: `
            Экспедитор тоңазытқышты дүйсенбі мен жұма аралығында
            толтырады. Мерзімі өткен өнімдер бірден алынады.
          `,
        },
      },
      {
        mImage: Img2M,
        dImage: Img2D,
        ru: {
          title: 'Контроль ассортимента',
          descr: `
          Подбираем и&nbsp;обновляем ассортимент, учитывая потребности ваших сотрудников.
        `,
        },
        kk: {
          title: 'Ассортиментті бақылау',
          descr: `
            Қызметкерлеріңіздің қажетіне қарай, ассортиментті іріктеп,
            жаңартып отырамыз.
          `,
        },
      },
      {
        mImage: Img3M,
        dImage: Img3D,
        ru: {
          title: 'Обслуживание',
          descr: `
          Поддерживаем на&nbsp;каждом этапе и&nbsp;берем на&nbsp;себя
          обслуживание микромаркетов: моем, обрабатываем и&nbsp;чиним.
        `,
        },
        kk: {
          title: 'Қызмет көрсету',
          descr: `
            Әр кезеңде қолдау көрсетіп, микромаркеттерді жуу, өңдеу,
            жөндеу сияқты жұмыстарды өз мойнымызға аламыз
          `,
        },
      },
      {
        mImage: Img4M,
        dImage: Img4D,
        ru: {
          title: 'Забота о&nbsp;покупателях',
          descr: `
          Всегда готовы выслушать ваши пожелания,
          а&nbsp;наша служба поддержки доступна в&nbsp;любой момент
        `,
        },
        kk: {
          title: 'Сатып алушыларға деген қамқорлық',
          descr: `
            Әрқашан талап-тілектеріңізге құлақ түруге дайынбыз, қолдау
            қызметі кез келген уақытта байланыста
          `,
        },
      },
    ],
  }),
  i18n: {
    messages: {
      kk: { title: 'Микромаркеттерімізді не үшін жақсы көреді' },
      ru: { title: 'За что любят наши микромаркеты' },
    },
  },
};
</script>

<style scoped>
.MmAdvantages {
  padding-top: 84px;
  width: calc(100% - 32px);
  max-width: 535px;
  margin: auto;
}

.MmAdvantages__list {
  padding-top: 24px;
  display: grid;
  gap: 16px;
}

.MmAdvantages__item {
  position: relative;
  display: grid;
  border-radius: 16px;
  background: #f1f2f7;
  padding: 28px 0 36px 24px;
  grid-template-columns: calc(100% - 112px);
  grid-gap: 8px;
}

.MmAdvantages__img {
  position: absolute;
  top: 0;
  right: 0;
}

.MmAdvantages__title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.MmAdvantages__descr {
  padding-top: 12px;
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .MmAdvantages {
    max-width: 988px;
    padding-top: 237px;
  }

  .MmAdvantages__list {
    padding-top: 54px;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .MmAdvantages__item {
    border-radius: 36px;
    padding-top: 40px;
    padding-left: 50px;
  }

  .MmAdvantages__itemContent {
    max-width: 264px;
  }

  .MmAdvantages__title {
    font-size: 24px;
    line-height: 30px;
  }

  .MmAdvantages__descr {
    padding-top: 12px;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
