<template>
  <div class="MmSlider">
    <ul class="MmSlider__wrapper">
      <li v-for="item in items" :key="item.ru" class="MmSlider__item">
        <div class="MmSlider__itemImg">
          <img v-if="!$isDesktop" :src="item.imgM" alt="" loading="lazy" />
          <img v-if="$isDesktop" :src="item.imgD" alt="" loading="lazy" />
        </div>
        <p class="MmSlider__itemLabel">
          <span v-html="item[$i18n.locale]" />
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import ImgM1 from './images/m-1.jpg';
import ImgD1 from './images/d-1.jpg';
import ImgM2 from './images/m-2.jpg';
import ImgD2 from './images/d-2.jpg';
import ImgM3 from './images/m-3.jpg';
import ImgD3 from './images/d-3.jpg';
import ImgM4 from './images/m-4.jpg';
import ImgD4 from './images/d-4.jpg';
import ImgM5 from './images/m-5.jpg';
import ImgD5 from './images/d-5.jpg';
import ImgM6 from './images/m-6.jpg';
import ImgD6 from './images/d-6.jpg';
import ImgM7 from './images/m-7.jpg';
import ImgD7 from './images/d-7.jpg';
import ImgM8 from './images/m-8.jpg';
import ImgD8 from './images/d-8.jpg';
import ImgM9 from './images/m-9.jpg';
import ImgD9 from './images/d-9.jpg';
import ImgM10 from './images/m-10.jpg';
import ImgD10 from './images/d-10.jpg';
import ImgM11 from './images/m-11.jpg';
import ImgD11 from './images/d-11.jpg';
import ImgM12 from './images/m-12.jpg';
import ImgD12 from './images/d-12.jpg';

export default {
  name: 'MmSlider',
  components: {},
  inject: [],
  props: {},
  data: () => ({
    items: [
      {
        ru: 'Плов по&#8209;ташкентски',
        kk: 'Ташкент палауы',
        imgM: ImgM1,
        imgD: ImgD1,
      },
      {
        ru: 'Клаб&#8209;сэндвич с&nbsp;ветчиной',
        kk: 'Ветчина клаб-сэндвичі',
        imgM: ImgM2,
        imgD: ImgD2,
      },
      {
        ru: 'Вода без&nbsp;газа',
        kk: 'Газсыз су',
        imgM: ImgM3,
        imgD: ImgD3,
      },
      {
        ru: 'Салат c&nbsp;лососем',
        kk: 'Лосось салаты',
        imgM: ImgM4,
        imgD: ImgD4,
      },
      {
        ru: 'Coca-cola',
        kk: 'Coca-cola',
        imgM: ImgM5,
        imgD: ImgD5,
      },
      {
        ru: 'Snickers',
        kk: 'Snickers',
        imgM: ImgM6,
        imgD: ImgD6,
      },
      {
        ru: 'Fuse Tea манго и&nbsp;ромашка',
        kk: 'Fuse Tea манго және түймедақ',
        imgM: ImgM7,
        imgD: ImgD7,
      },
      {
        ru: 'Паста с&nbsp;курицей и&nbsp;грибами',
        kk: 'Тауық пен саңырауқұлақ пастасы',
        imgM: ImgM8,
        imgD: ImgD8,
      },
      {
        ru: 'Red Bull',
        kk: 'Red Bull',
        imgM: ImgM9,
        imgD: ImgD9,
      },
      {
        ru: 'Круассан с&nbsp;шоколадом',
        kk: 'Шоколадты круассан',
        imgM: ImgM10,
        imgD: ImgD10,
      },
      {
        ru: 'Активия с&nbsp;клубникой',
        kk: 'Құлпынай дәмімен Активия',
        imgM: ImgM11,
        imgD: ImgD11,
      },
      {
        ru: 'Fitness злаки с&nbsp;шоколадом',
        kk: 'Шоколадты Fitness дәндері',
        imgM: ImgM12,
        imgD: ImgD12,
      },
    ],
  }),

  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.MmSlider {
  overflow: auto;
}

.MmSlider__wrapper {
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: 124px;
  gap: 0 24px;
}

.MmSlider__itemImg {
  height: 124px;
}

.MmSlider__itemLabel {
  text-align: center;
  /* margin-top: 16px; */
  line-height: 18px;
  padding-bottom: 2px;
}

@media (min-width: 768px) {
  .MmSlider__wrapper {
    display: inline-grid;
    grid-auto-flow: column;
    grid-auto-columns: 232px;
    gap: 0 20px;
  }

  .MmSlider__itemImg {
    height: 188px;
  }

  .MmSlider__itemLabel {
    padding-top: 12px;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
