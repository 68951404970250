<template>
  <DeliveryOption
    :value="toggle"
    :option="{
      icon: 'cart-leave-at-door',
      title: $t('leaveAtTheDoor'),
    }"
    :disabled="isDisabled"
    @change="onChange"
  >
    <p v-if="isDisabled" class="add-text">{{ $t('description') }}</p>
    <AdultsLeaveAtDoorModal v-model="showModal" />
  </DeliveryOption>
</template>

<script>
import { mapGetters } from 'vuex';
import { RahmetApp } from '@shared/RahmetApp';
import { AdultsService } from '@services/adults';
import { createStorage } from '@shared/services/storage';
import { AdultsEmitter, ADULTS_EVENTS } from '@services/adults/emitter';
import AdultsLeaveAtDoorModal from '@pure-ui/components/AdultsLeaveAtDoorModal/AdultsLeaveAtDoorModal.vue';
import DeliveryOption from '@pure-ui/components/DeliveryOption/DeliveryOption.vue';

export default {
  name: 'LeaveAtDoor',
  components: {
    AdultsLeaveAtDoorModal,
    DeliveryOption,
  },
  data() {
    return {
      toggle: false,
      showModal: false,
      storage: null,
    };
  },
  computed: {
    ...mapGetters('cart', ['cartProducts']),
    isDisabled() {
      return this.hasAdultProducts() && this.toggle;
    },
  },
  beforeDestroy() {
    AdultsEmitter.off(
      ADULTS_EVENTS.ON_ADD_ADULT_PRODUCT_FROM_HISTORY,
      this.onAddFromHistory
    );
  },
  mounted() {
    this.storage = createStorage(window.localStorage);
    this.toggle = this.getSavedStateOfToggle();

    if (
      this.toggle &&
      this.hasAdultProducts() &&
      !AdultsService.getAdultsLeaveAtDoorModalShownOnMount()
    ) {
      this.openModal();
      AdultsService.setAdultsLeaveAtDoorModalShownOnMount(true);
    }

    AdultsEmitter.on(
      ADULTS_EVENTS.ON_ADD_ADULT_PRODUCT_FROM_HISTORY,
      this.onAddFromHistory
    );
  },
  methods: {
    onAddFromHistory() {
      if (this.toggle) this.openModal();
    },
    hasAdultProducts() {
      return [...this.cartProducts].some(p =>
        AdultsService.checkIsAdultProduct(p)
      );
    },
    openModal() {
      this.showModal = true;
    },
    saveStateOfToggle(value) {
      this.storage.set('additionalFeat::contactlessDelivery', value);
    },
    getSavedStateOfToggle() {
      let state = this.storage.parse(
        'additionalFeat::contactlessDelivery',
        false
      );
      if (!state) {
        state = false;
        this.saveStateOfToggle(state);
      }
      return state;
    },
    onChange(value) {
      RahmetApp.hapticSelection();
      if (this.hasAdultProducts()) {
        this.openModal();
        return;
      }

      this.saveStateOfToggle(value);
      this.toggle = value;
    },
  },
  i18n: {
    messages: {
      kk: {
        leaveAtTheDoor: 'Есік алдында қалдыру',
        description:
          'Тапсырысты есік алдына қалдыра алмаймыз. Алкоголь және табак өнімдерін алғанда, бізге сіздің жеке куәлігіңізді тексеру керек болады.',
      },
      ru: {
        leaveAtTheDoor: 'Оставить заказ у двери',
        description:
          'Не сможем оставить заказ у двери. При покупке алкогольной или табачной продукции, нам нужно проверить ваше удостоверение',
      },
    },
  },
};
</script>

<style scoped>
.add-text {
  @apply ml-9 mr-14 mt-0.5 text-sm leading-4 text-sirius-gray-700;
  letter-spacing: 0.07px;
}
</style>
