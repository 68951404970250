const months = {
  ru: {
    1: 'января',
    2: 'февраля',
    3: 'марта',
    4: 'апреля',
    5: 'мая',
    6: 'июня',
    7: 'июля',
    8: 'августа',
    9: 'сентября',
    10: 'октября',
    11: 'ноября',
    12: 'декабря',
  },
  kk: {
    1: 'қаңтар',
    2: 'ақпан',
    3: 'наурыз',
    4: 'сәуір',
    5: 'мамыр',
    6: 'маусым',
    7: 'шілде',
    8: 'тамыз',
    9: 'қыркүйек',
    10: 'қазан',
    11: 'қараша',
    12: 'желтоқсан',
  },
};

const wordDays = {
  today: { kk: 'Бүгін', ru: 'Сегодня' },
  tomorrow: { kk: 'Кеше', ru: 'Вчера' },
};

export const normalizeDate = (date, isTime = true) => {
  if (!date) return '';
  if (isTime)
    return `${date.split(' ').shift().split('-').reverse().join('.')},
        ${date.split(' ').pop().split(':').slice(0, 2).join(':')} `;

  return `${date.split(' ').shift().split('-').reverse().join('.')}`;
};

export const dateAndTime = (dateTime, isTime = true, locale = 'ru') => {
  if (!dateTime) return '';
  const t1 = new Date().getTime();
  const t2 = new Date(dateTime.replace(/-/g, '/')).getTime();

  const dateDiff = Math.floor((t1 - t2) / (24 * 3600 * 1000));

  const date = dateTime.split(' ').shift().split('-');

  const time = dateTime.split(' ').reverse().shift().split(':');

  // const year = +date[0] ?? ''
  const month = +date[1] ?? '';
  const day = +date[2] ?? '';
  const monthName = months[locale][month];
  const hh = time[0] ?? '';
  const mm = time[1] ?? '';
  // const ss = time[2] ?? ''

  const tt1 = new Date().toISOString().split('T')[0].split('-')[2];
  const tt2 = dateTime.split(' ')[0].split('-')[2];

  if (dateDiff === 0) {
    if (tt1 - tt2 === 0) return `${wordDays.today[locale]}, ${hh}:${mm}`;
    return `${wordDays.tomorrow[locale]}, ${hh}:${mm}`;
  }
  if (dateDiff === 1) {
    if (tt1 - tt2 === 1) return `${wordDays.tomorrow[locale]}, ${hh}:${mm}`;
    return isTime ? `${day} ${monthName}, ${hh}:${mm}` : `${day} ${monthName}`;
  }
  return isTime ? `${day} ${monthName}, ${hh}:${mm}` : `${day} ${monthName}`;
};

export const computeDateDiff = (date, currentDate = null) => {
  const t1 = currentDate ? new Date(currentDate) : new Date();
  const t2 = new Date(date.replace(' ', 'T'));

  const countDownDate = t2.getTime() + 30 * 60 * 1000;
  const distance = countDownDate - t1.getTime();
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  return Math.abs(days);
};

export const getDiff = (date, currentDate = null) => {
  const t1 = new Date(currentDate) ?? new Date();
  const t2 = new Date(date);
  return Math.floor((t1 - t2) / (24 * 3600 * 1000));
};

export const getDefaultDateFormat = d => {
  const addZero = e => (e < 10 ? `0${e}` : `${e}`);

  const year = d.getFullYear();
  const month = addZero(d.getMonth() + 1);
  const day = addZero(d.getDate());
  const hour = addZero(d.getHours());
  const minute = addZero(d.getMinutes());
  const second = addZero(d.getSeconds());

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export function monthsBetweenDates(date1, date2) {
  // Calculate the difference in months
  let diffMonths =
    (date2.getFullYear() - date1.getFullYear()) * 12 +
    (date2.getMonth() - date1.getMonth());

  // Adjust for days
  if (date2.getDate() < date1.getDate()) {
    diffMonths -= 1;
  }

  return diffMonths;
}
