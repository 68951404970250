const sortedAscWithNull = (a, b) => {
  if (a === null) {
    return 1;
  }

  if (b === null) {
    return -1;
  }

  if (a === b) {
    return 0;
  }

  return a < b ? -1 : 1;
};

const sortedDescWithNull = (a, b) => {
  if (a === null) {
    return -1;
  }

  if (b === null) {
    return 1;
  }

  if (a === b) {
    return 0;
  }

  return a < b ? 1 : -1;
};

const sortBy = order => (a, b) => {
  switch (order) {
    case 'price_asc':
      return a.price - b.price;
    case 'price_desc':
      return b.price - a.price;
    case 'popular_asc':
      return (
        sortedAscWithNull(b.has_brand, a.has_brand) ||
        sortedAscWithNull(a.brand_sort_weight, b.brand_sort_weight) ||
        sortedAscWithNull(a.brand_id, b.brand_id) ||
        sortedAscWithNull(a.product_sort_weight, b.product_sort_weight) ||
        sortedAscWithNull(a.id, b.id)
      );
    // return (
    //   b.has_brand - a.has_brand ||
    //   a.brand_sort_weight - b.brand_sort_weight ||
    //   a.brand_id - b.brand_id ||
    //   a.product_sort_weight - b.product_sort_weight ||
    //   a.id - b.id
    // );
    case 'popular_desc':
      return (
        sortedDescWithNull(a.has_brand, b.has_brand) ||
        sortedDescWithNull(b.brand_sort_weight, a.brand_sort_weight) ||
        sortedDescWithNull(b.brand_id, a.brand_id) ||
        sortedDescWithNull(b.product_sort_weight, a.product_sort_weight) ||
        sortedDescWithNull(b.id, a.id)
      );
    case 'productSortWeight':
      return (
        sortedAscWithNull(a.product_sort_weight, b.product_sort_weight) ||
        sortedAscWithNull(b.has_brand, a.has_brand) ||
        sortedAscWithNull(a.brand_sort_weight, b.brand_sort_weight) ||
        sortedAscWithNull(a.brand_id, b.brand_id) ||
        sortedAscWithNull(a.id, b.id)
      );
    // return (
    //   a.has_brand - b.has_brand ||
    //   b.brand_sort_weight - a.brand_sort_weight ||
    //   b.brand_id - a.brand_id ||
    //   b.product_sort_weight - a.product_sort_weight ||
    //   b.id - a.id
    // );
    default:
      return 0;
  }
};

export function sortProductsBy(order, categories) {
  if (
    order === 'price_asc' ||
    order === 'price_desc' ||
    order === 'popular_asc' ||
    order === 'popular_desc' ||
    order === 'productSortWeight'
  ) {
    return Object.keys(categories).reduce((acc, id) => {
      acc[id] = { ...categories[id] };
      acc[id].products = categories[id].products.slice(0).sort(sortBy(order));
      return acc;
    }, {});
  }
  return categories;
}

export function sortRecipes(recipes) {
  return Object.keys(recipes).reduce((acc, id) => {
    acc[id] = { ...recipes[id] };
    acc[id].products = recipes[id].products.slice(0).sort((a, b) => {
      return a.sort_weight - b.sort_weight || a.id - b.id;
    });
    return acc;
  }, {});
}
