import { client } from '../../clients';
import fetchActiveBannersMock from './mocks/fetch-active-banners.json';

/**
 * Получить список активных баннеров
 */
export default function (payload, mock = false) {
  if (mock) {
    return Promise.resolve(fetchActiveBannersMock);
  }

  return client
    .get('/lavka/v1/active_banners', {
      params: payload,
    })
    .then(response => response.data);
}
