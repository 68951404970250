export const configureRouteParams = (type, id) => {
  let name = '';

  if (type === 'regular') {
    name = 'category';
  } else if (type === 'recipe') {
    name = 'recipe';
  } else {
    name = 'def';
  }
  return {
    name,
    params: { id },
  };
};
