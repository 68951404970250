import {
  INITIAL_COORDS,
  YMAPS_SETTINGS,
  NEW_POLYGON_SETTINGS,
} from '@services/map/constants';
import { defineGeolocation, geocodeSinglePosition } from '@services/map/utils';
import { validateGeoObject } from '@services/map/utils/validate-geoobject';
import { isCoordsInDeliveryZone } from '@services/map/utils/map-polygon';
import { loadYmap } from 'vue-yandex-maps-1';

export default class YandexMap {
  constructor(
    onMapInit,
    onMapClick,
    onMapActionEnd,
    onMapActionStart,
    onMapCenterUpdated
  ) {
    this.onMapInit = onMapInit;
    this.onMapClick = onMapClick;
    this.onMapActionEnd = onMapActionEnd;
    this.onMapActionStart = onMapActionStart;
    this.onMapCenterUpdated = onMapCenterUpdated;

    this.polygons = {
      regular: [],
      extended: [],
    };
    console.log('contructor');
  }

  checkPinIsInTerritory(currentCoords) {
    let regularPolygonCoords =
      this.polygons.regular.map(p => p.coordinates) || [];
    let extendedPolygonCoords =
      this.polygons.extended.map(p => p.coordinates) || [];

    let isInTerritory = isCoordsInDeliveryZone(
      window.ymaps,
      currentCoords,
      regularPolygonCoords
    );
    let isInExtended = isCoordsInDeliveryZone(
      window.ymaps,
      currentCoords,
      extendedPolygonCoords
    );

    return {
      isInTerritory,
      isInExtended,
    };
  }

  getOptions() {
    if (!this.map)
      return {
        coords: INITIAL_COORDS,
        zoom: 16,
      };

    const mapCoords = this.map.getCenter();
    return {
      coords: mapCoords,
      zoom: this.map.getZoom(),
    };
  }

  onCenterUpdated() {
    let options = this.getOptions();
    this.getGeoObjectByCoords(options.coords).then(validatedCollection => {
      let isPolygonsLoaded =
        this.polygons.regular.length || this.polygons.extended.length;
      let data = {
        ...options,
        ...validatedCollection,
        isPolygonsLoaded,
      };
      this.onMapCenterUpdated(data);
    });
  }

  getGeoObjectByCoords(coords) {
    return geocodeSinglePosition(window.ymaps, coords).then(collection => {
      let geoObject = collection.featureMember[0];
      const [long, lat] = geoObject.GeoObject.Point.pos.split(' ');

      let geoObjectValidations = validateGeoObject(geoObject);
      let isInDeliveryZoneValidations = this.checkPinIsInTerritory([lat, long]);

      let validatedGeoObject = {
        ...geoObject,
        ...geoObjectValidations,
        ...isInDeliveryZoneValidations,
      };
      return validatedGeoObject;
    });
  }
  // @prop {String} type in|out
  zoom(type = 'in') {
    let currentZoom = this.map.getZoom();
    if (type === 'in' && currentZoom === 20) return;
    if (type === 'out' && currentZoom === 2) return;

    this.map.setZoom(type === 'in' ? ++currentZoom : --currentZoom, {
      duration: 300,
    });
  }

  defineByCoords(coords) {
    this.map
      .setCenter(coords, 16, {
        duration: 500,
        timingFunction: 'ease-in-out',
      })
      .then(() => {
        console.log('defineByCoords', coords);
      });
  }

  findGeoLocation() {
    return defineGeolocation(window.ymaps)
      .then(coords => this.defineByCoords(coords))
      .then(() => this.onCenterUpdated());
  }

  onClick(e) {
    var coords = e.get('coords');
    this.map.panTo(coords).then(() => {
      console.log('onClick');
      this.onMapClick(this.getOptions());
      this.onCenterUpdated();
    });
  }

  setListeners() {
    this.map?.events.add('click', e => this.onClick(e));
    this.map?.events.add('actionbegin', data => {
      console.log('actionbegin data', data);
      this.onMapActionStart(this.getOptions());
    });

    this.map?.events.add('actionend', data => {
      console.log('actionend', data);
      this.onMapActionEnd(this.getOptions());
      this.onCenterUpdated();
    });
  }

  createMap(id, zoom, coords) {
    console.log('CreateMap', id);
    this.map = new window.ymaps.Map(
      id,
      {
        zoom: zoom || 16,
        center: coords || INITIAL_COORDS,
        controls: [],
      },
      {
        yandexMapDisablePoiInteractivity: true,
      }
    );
    console.log('map', this.map);
  }

  createPolygonMarker(type = 'regular', index, coords) {
    let polygonMap = {
      regular: NEW_POLYGON_SETTINGS.regular,
      regularNearExtended: NEW_POLYGON_SETTINGS.regularNearExtended,
      extended: NEW_POLYGON_SETTINGS.extended,
    };

    return new window.ymaps.GeoObject(
      {
        geometry: {
          type: 'Polygon',
          coordinates: coords,
          id: `${type}_${index}`,
        },
      },
      {
        ...polygonMap[type],
      }
    );
  }
  setPolygons(polygons) {
    if (!this.map) return;
    if (!polygons?.regular?.length) return;

    this.polygons = polygons;

    let paintPolygons = polygons => {
      polygons.forEach((polygon, index) => {
        let polygonObj = this.createPolygonMarker(
          polygon.type,
          index,
          polygon.coordinates
        );
        this.map.geoObjects.add(polygonObj);
        polygonObj.events.add('click', e => this.onClick(e));
      });
    };

    paintPolygons(polygons.extended);
    paintPolygons(polygons.regular);
    this.onCenterUpdated();
  }

  async initMap(id, zoom, coords) {
    await loadYmap(YMAPS_SETTINGS);
    console.log('initMap', id);

    this.createMap(id, zoom, coords);

    this.onMapInit(this.getOptions());
    this.onCenterUpdated();

    this.setListeners();

    // console.log('window.ymaps', window.ymaps);
  }
}
