<template>
  <div class="HProductCard card">
    <slot name="photo">
      <ProductPhoto
        class="card-photo"
        :src="imgSrc"
        @click.native="onGoProduct"
      />
    </slot>

    <div class="card-content">
      <HPCTitle :rows="2" @click.native="onGoProduct">{{ title }}</HPCTitle>

      <OutOfStock v-if="!product.max_quantity" />

      <template v-else>
        <Price :price="product.price" :old-price="product.old_price" />
        <TotalPrice :price="product.price" :quantity="product.quantity" />
      </template>
    </div>

    <div class="card-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
import ProductPhoto from '@pure-ui/components/Product/ProductPhoto/ProductPhoto.vue';
import TotalPrice from './components/TotalPrice.vue';
import OutOfStock from './components/OutOfStock.vue';
import Price from './components/Price.vue';
import HPCTitle from './components/HPCTitle.vue';

export default {
  name: 'HProductCard',
  components: {
    ProductPhoto,
    TotalPrice,
    OutOfStock,
    Price,
    HPCTitle,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imgSrc() {
      if (!this.product) return '';

      const { images } = this.product;
      if (images && images.length > 0 && images[0].path) {
        return images[0].path;
      }

      return this.product.path;
    },
    title() {
      const { name } = this.product;
      if (this.feature?.name) {
        const splitted = this.feature.name.split(' ');
        const weight = Number(splitted[0].replace(',', '.'));
        const unit = splitted[1];

        return `${name}, ${weight} ${unit}`;
      }
      return name;
    },
  },
  methods: {
    onGoProduct() {
      this.$emit('onGoProduct', this.product);
    },
  },
};
</script>

<style scoped>
.card {
  @apply w-full p-2 pb-3 flex items-start rounded-2xl;
}
.card-photo {
  @apply bg-white rounded-2xl cursor-pointer;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
}
.card-photo__outofstock {
  filter: grayscale(100%);
}
.card-content {
  @apply ml-3 flex flex-col space-y-0.5;
}
.card-right {
  @apply ml-auto pl-3;
}
</style>
