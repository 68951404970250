<template>
  <div class="SuccessScreen">
    <div class="SuccessScreen__container">
      <div class="SuccessScreen__textWrapper">
        <h2 class="SuccessScreen__title" v-html="$t('title')" />
        <p class="SuccessScreen__descr" v-html="$t('descr')" />
        <MmButton
          v-if="!$isDesktop"
          class="SuccessScreen__btn"
          @click="$emit('onDone')"
        >
          {{ $t('done') }}
        </MmButton>
      </div>
      <img
        v-if="!$isDesktop"
        src="./images/m-visual.png"
        width="220"
        height="220"
        class="SuccessScreen__img"
      />
      <img
        v-if="$isDesktop"
        src="./images/d-visual.png"
        width="342"
        height="317"
        class="SuccessScreen__img"
        @click="$emit('onDone')"
      />
    </div>
  </div>
</template>

<script>
import MmButton from '@/views/micromarket/components/MmButton/MmButton.vue';

export default {
  name: 'SuccessScreen',
  components: { MmButton },
  inject: [],
  props: {},
  data: () => ({}),

  i18n: {
    messages: {
      kk: {
        title: 'Рахмет, жақында сізге хабарласамыз',
        descr: `
          Менеджер сізге дүйсенбі мен жұма аралығында 09:00-ден 18:00-ге
          дейін Whatsapp арқылы хабарласады
        `,
        done: 'Дайын',
      },
      ru: {
        title: 'Спасибо, скоро мы&nbsp;с&nbsp;вами&nbsp;свяжемся',
        descr: `
          Менеджер свяжется с&nbsp;вами в&nbsp;Whatsapp с&nbsp;понедельника
          по&nbsp;пятницу, с&nbsp;09:00 до&nbsp;18:00
        `,
        done: 'Готово',
      },
    },
  },
};
</script>

<style scoped>
.SuccessScreen {
  min-height: 100%;
  padding-top: 196px;
  position: relative;
}

.SuccessScreen__container {
  width: calc(100% - 32px);
  margin: auto;
  max-width: 535px;
}

.SuccessScreen__img {
  position: absolute;
  top: 12px;
  right: 8px;
}

.SuccessScreen__title {
  color: var(--services-produkty, #ffa100);
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.SuccessScreen__descr {
  padding-top: 12px;
  font-size: 16px;
  line-height: 24px;
}

.SuccessScreen__btn {
  margin-top: 52px;
  width: 100%;
}

@media (min-width: 768px) {
  .SuccessScreen {
    padding-top: 80px;
    padding-bottom: 96px;
  }

  .SuccessScreen__title {
    font-size: 36px;
    line-height: 44px;
  }

  .SuccessScreen__descr {
    font-size: 18px;
    line-height: 28px;
  }

  .SuccessScreen__container {
    max-width: 836px;
    padding-right: 152px;
    box-sizing: content-box;
    display: grid;
    grid-template-columns: 400px auto;
    grid-gap: 0 100px;
  }

  .SuccessScreen__img {
    position: static;
  }
}
</style>
