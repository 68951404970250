<template>
  <div class="profile-card">
    <div
      class="profile-card__card bg-sirius-gray-200 px-5 pt-4 pb-4 rounded-2xl"
    >
      <div class="profile-card__top flex items-center">
        <ProfilePicture :photo="user.photo" />
        <span class="font-bold pl-3">
          {{ user.name || $t('noName') }}
        </span>
      </div>

      <template v-if="user.phone">
        <div
          :class="[
            'pt-3',
            'border-b-1',
            'border-sirius-gray-300',
            'border-solid',
          ]"
        />

        <div class="profile-card__contacts pb-3">
          <ContactField :title="$t('phoneNumber')" :value="user.phone" />
          <!-- <ContactField title="email" :value="user.email" /> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ProfilePicture from './ProfilePicture';
import ContactField from './ContactField';

export default {
  name: 'ProfileCard',
  components: {
    ProfilePicture,
    ContactField,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  i18n: {
    messages: {
      ru: {
        phoneNumber: 'номер телефона',
        noName: 'Имя не указано',
      },
      kk: {
        phoneNumber: 'телефон нөмірі',
        noName: 'Аты-жөні енгізілмеген',
      },
    },
  },
};
</script>
