<template>
  <NotificationReminderModalView
    v-model="show"
    @onNotNow="onNotNow"
    @onSwitchOn="onSwitchOn"
    @onClose="$emit('onClose')"
  />
</template>

<script>
import NotificationReminderModalView from '@pure-ui/components/NotificationReminderModal/NotificationReminderModalView.vue';
import { NotificationService } from '@services/notifications';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'NotificationReminderModal',
  components: { NotificationReminderModalView },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    openModal() {
      this.show = true;
      Analitycs.logEvent(EVENTS.ON_OPEN_NOTIFICATION_MODAL);
      NotificationService.resetPeriodOfReminder();
    },
    closeModal() {
      this.show = false;
    },
    onNotNow() {
      this.closeModal();
      Analitycs.logEvent(EVENTS.ON_CLICK_NOTIFICATION_BUTTON, {
        status: 'notDetermined',
        btn: 'not now',
      });
    },
    async onSwitchOn() {
      const result = await NotificationService.requestEnabling();
      Analitycs.logEvent(EVENTS.ON_CLICK_NOTIFICATION_BUTTON, {
        status: result,
        btn: 'switch on',
      });
      this.closeModal();
    },
  },
};
</script>

<style></style>
