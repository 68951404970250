export function formatWeightText(weight, feat) {
  let feats = {
    1: 'г',
    1000: 'кг',
  };
  if (feat === 'л' || feat === 'мл') {
    feats = {
      1: 'мл',
      1000: 'л',
    };
  }
  weight = parseInt(weight, 10);

  if (!weight) return `${weight} ${feats[1000]}`;
  if (weight < 100) return `${weight} ${feats[1]}`;
  return `${weight / 1000} ${feats[1000]}`;
}

/**
 * Получает объект весового товара с бека и выводит ее меру измерения
 * @param {Object} feature получает объект выбранного весового товара
 * @returns {String} тип меры измерения
 */
export function extracktMeasurement(feature) {
  let feat = 'кг';
  if (feature && feature.name) {
    const params = feature.name.split(' ');
    // eslint-disable-next-line prefer-destructuring
    feat = params[1];
  }

  return feat;
}

/**
 * Получает объект продукта с бека и выводит ее вес в граммах
 * Метод нужен для того что б узнавать вес с простого товара и весового
 * @param {Object} product получает объект продукта
 * @param {Object} feature получает объект выбранного весового товара
 * @returns {Number} вес продукта в граммах
 */
export function extractWeight(product, feature) {
  let { weight } = product || { weight: 0 };
  weight = weight || 0;
  if (feature && feature.weight) {
    weight = feature.weight;
  } else if (feature && feature.name) {
    const params = feature.name.split(' ');
    [weight] = params;
    weight = Number(weight.replace(',', '.')) * 1000 || 0;
  }

  return weight;
}
