<template>
  <UiBottomSheetPopup
    :show="show"
    name="promo-modal"
    class="PromoModal"
    with-back-button
    with-buttons
    closable
    style="--popup-width-d: 588px"
    @change="$emit('update:show', false)"
  >
    <div class="PromoModal__container">
      <img
        :src="$isDesktop ? imageDesktop : imageMobile"
        alt=""
        height="232"
        class="PromoModal__img"
      />
      <div class="PromoModal__title">
        {{ title }}
      </div>
      <div class="PromoModal__text">
        <div v-html="termsHtml" />
        <p class="PromoModal__mandatory">{{ mandatoryTerms }}</p>
      </div>
    </div>
    <template #buttons>
      <UiButton
        :size="$isDesktop ? 'xl' : 'lg'"
        class="PromoModal__btn"
        @click="$emit('onButtonClick')"
      >
        {{ buttonText }}
      </UiButton>
    </template>
  </UiBottomSheetPopup>
</template>

<script>
import UiBottomSheetPopup from '@/pure-ui/components/UiBottomSheetPopup/UiBottomSheetPopup.vue';
import UiButton from '@/pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'PromoModal',
  components: {
    UiBottomSheetPopup,
    UiButton,
  },
  model: {
    prop: 'show',
    event: 'update:show',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    imageDesktop: {
      type: String,
      default: '',
    },
    imageMobile: {
      type: String,
      default: '',
    },
    termsHtml: {
      type: String,
      default: '',
    },
    mandatoryTerms: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.PromoModal__container {
  @apply pb-20 md:pb-0;
  padding-top: 40px;
  width: 100%;
  margin: auto;
}

.PromoModal__img {
  max-width: none;
  width: calc(100% + 32px);
  position: relative;
  z-index: 0;
  margin: 0 -16px 0;
}

.PromoModal__title {
  padding-top: 36px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.PromoModal__text {
  padding-top: 16px;
  font-size: 16px;
  line-height: 24px;
}

.PromoModal__text >>> ul {
  padding-top: 12px;
  padding-left: 24px;
}

.PromoModal__text >>> ul li {
  position: relative;
}

.PromoModal__text >>> ul li::before {
  content: '•';
  position: absolute;
  left: -16px;
}

.PromoModal__text >>> ul li + li {
  margin-top: 16px;
}

.PromoModal__text >>> ul + p {
  padding-top: 16px;
}

.PromoModal__mandatory {
  padding-top: 16px;
}

.PromoModal__btn {
  /* margin-top: 44px; */
  /* margin-bottom: 48px; */
  width: 100%;
  font-weight: 900;
  font-size: 16px;
  border-radius: 16px;
}

@media screen and (min-width: 768px) {
  .PromoModal__img {
    margin: 0;
    width: calc(100% + 64px);
    margin-left: -32px;
    max-width: none;
    border-radius: 32px 32px 0 0;
  }

  .PromoModal__container {
    width: 100%;
    padding-top: 0;
    margin-top: -32px;
  }

  .PromoModal__title {
    padding-top: 0;
    max-width: 524px;
    margin: auto;
    font-size: 28px;
    line-height: 32px;
  }

  .PromoModal__text {
    padding-top: 24px;
    font-size: 18px;
    max-width: 524px;
    margin: auto;
  }

  .PromoModal__text ul {
    padding-top: 24px;
  }

  .PromoModal__text ul li + li {
    margin-top: 4px;
  }

  .PromoModal__text ul + p {
    padding-top: 24px;
  }

  .PromoModal__btn {
    margin-top: 40px;
    width: 100%;
    font-size: 24px;
    margin-bottom: 0;
  }
}
</style>
