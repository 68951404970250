import { ClientService } from '@/shared/services/client';
import { client } from '../client/client';

export function confirmRemovingWithCode(codeFromSMS, requestId) {
  const { accessToken } = ClientService.getContext();

  return new Promise((resolve, reject) => {
    client({
      url: '/api/v3/user/code',
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        data: {
          attributes: {
            code: codeFromSMS,
          },
          id: requestId,
          type: 'code',
        },
      },
    })
      .then(() => {
        resolve({ status: 'ok' });
      })
      .catch(err => {
        if (err.response?.data?.errors[0].code === '135') {
          return resolve({ status: 'wrong_code' });
        }
        reject(err);
      });
  });
}
