<template>
  <div class="SocialItems">
    <a
      href="https://www.instagram.com/ryadom.kz/"
      class="SocialItems__item"
      target="_blank"
    >
      <img :src="InstagramIcon" alt="" class="SocialItems__svg" />
      <!-- <InstagramIcon width="28" class="SocialItems__svg" /> -->
    </a>
    <a
      href="https://t.me/RyadomHolodosBot"
      class="SocialItems__item"
      target="_blank"
    >
      <img :src="TelegramIcon" width="28" class="SocialItems__svg" />
    </a>
    <a
      href="https://www.tiktok.com/@ryadom.kz"
      class="SocialItems__item"
      target="_blank"
    >
      <img :src="TiktokIcon" width="28" class="SocialItems__svg" />
    </a>
  </div>
</template>

<script>
export default {
  name: 'SocialItems',
  data() {
    return {
      InstagramIcon: new URL('./icons/instagram.svg', import.meta.url).href,
      TelegramIcon: new URL('./icons/telegram.svg', import.meta.url).href,
      TiktokIcon: new URL('./icons/tiktok.svg', import.meta.url).href,
    };
  },
};
</script>

<style scoped>
.SocialItems {
  display: grid;
  grid-template-columns: repeat(3, 28px);
  gap: 32px;
  align-items: center;
}

.SocialItems__svg {
  vertical-align: top;
}

.SocialItems__item {
  color: #000;
}

.SocialItems__item:hover {
  color: #ffa100;
}

@media screen and (min-width: 1188px) {
  .SocialItems {
    gap: 0 40px;
  }
}
</style>
