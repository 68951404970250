import { Logger } from '@shared/services/logger';

export const $log = Logger.event;

export const LoggerPlugin = {
  install(VueLocal) {
    Object.defineProperty(VueLocal.prototype, '$log', {
      get() {
        return $log;
      },
    });
  },
};
