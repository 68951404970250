import Vue from 'vue';

export function createBottomSheetPopup() {
  const state = Vue.observable({
    activeName: '',
  });

  return {
    state,
    show(name, params) {
      state.activeName = '';
      Object.assign(state, params);
      state.activeName = name;
      window.addEventListener(
        'popstate',
        () => {
          state.activeName = '';
        },
        false
      );
      document.body.style.overflow = 'hidden';
    },
    hide() {
      state.activeName = '';
      document.body.style.overflow = 'auto';
    },
  };
}
