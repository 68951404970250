<template>
  <div
    class="ProductDetails text-sirius-black-100 leading-tight"
    :data-product-id="product ? product.id : 'unknown'"
  >
    <UiBottomSheet v-model="show" @onClose="resetState">
      <UiRippleLoader
        v-if="loading || !product || !productId"
        class="mx-auto mt-10 mb-10"
      />
      <RenderlessProduct
        v-else
        id="productContainer"
        v-slot="{
          isSelected,
          isPending,
          quantity,
          addToCart,
          removeFromCart,
          replaceFromFull,
          isFavorite,
          addToFavorites,
          removeFromFavorites,
          showBrandName,
        }"
        :product="product"
        :feature="feature"
      >
        <div class="flex flex-col duration-300 relative hidden-scrollbar">
          <div class="flex items-center fixed z-10 w-full px-4 mt-5">
            <div class="mr-2" @click="closeModal">
              <IconBackArrow />
            </div>
            <div class="right-icons flex ml-auto">
              <div v-if="false" class="ml-auto" @click="shareLink">
                <IconShare />
              </div>
              <div v-if="$store.getters['isAuthorized']" class="ml-2">
                <SaveBtn
                  :saved="isFavorite"
                  size="xl"
                  @click.stop
                  @onSave="addToFavorites(product.id)"
                  @onRemove="removeFromFavorites(product.id)"
                />
              </div>
            </div>
          </div>
          <div class="pb-32">
            <UiImageSlider
              :images="imageSrc"
              :video="product.video"
              class="mx-auto"
            />

            <div class="mt-3 px-4">
              <ProductLabels
                v-if="product && product.labels && product.labels.length"
                :labels="product.labels"
                class="mb-4"
                full
                type="scrollable"
              />

              <h3 class="text-sirius-black-400 font-bold text-2xl">
                {{ $i18n.locale === 'kk' ? product.name_kk : product.name }}
              </h3>

              <h3
                v-if="showBrandName"
                class="text-sirius-gray-1000 font-bold text-2xl line-clamp-1 break-words"
              >
                {{ product.brand_name }}
              </h3>

              <div v-if="features && features.length > 1" class="mt-4">
                <p class="mb-2">
                  {{ $t('selectWeight') }}
                </p>

                <div class="flex flex-wrap mt-3">
                  <FeatureTag
                    v-for="item in features"
                    :key="item.id"
                    :item="item"
                    :active="feature.id === item.id"
                    @onClick="onTagTapped(item, isSelected)"
                  />
                </div>

                <div
                  class="bg-sirius-white-300 h-0.5 w-full rounded-full mt-4"
                ></div>
              </div>

              <div class="product-modal-info">
                <div class="product-modal-price">
                  <SalePrice
                    v-if="oldPrice"
                    :old-price="formatPrice(oldPrice)"
                    :price="price"
                    size="lg"
                  />
                  <template v-else>{{ price }} ₸</template>
                </div>

                <div
                  v-if="features && productWeight && features.length"
                  class="product-modal-weight"
                >
                  {{ productWeight }}
                </div>
              </div>
              <ProductReviewWrapper
                v-if="productReview"
                :product="product"
                :product-review="productReview"
                screen="product-details"
                class="mt-4 mb-6"
              />

              <div class="mt-7">
                <ProductInfo
                  v-for="({ title, value }, index) in infoList"
                  :key="index"
                  :title="$t(title)"
                  :value="value"
                  class="mt-4"
                />
                <ProductInfo
                  v-if="nutritionals && nutritionals.shelf_life"
                  :title="$t('shelfLife')"
                  :value="localizeShelfLife(nutritionals.shelf_life)"
                  class="mt-4"
                />
                <ProductInfo
                  v-if="productWeight && product.weight_visible"
                  :title="$t('productWeight')"
                  :value="productWeight"
                  class="mt-4"
                />
                <ProductInfo
                  v-if="hasDescription"
                  :title="$t('description')"
                  class="mt-7 flex-col space-y-2"
                >
                  <template #value>
                    <p class="mt-2" v-html="description"></p>
                  </template>
                </ProductInfo>
              </div>
              <template
                v-if="
                  formatedAppliances &&
                  formatedAppliances.max_quantity &&
                  formatedAppliances.price
                "
              >
                <div
                  class="mt-6 flex item-center p-4 pt-3 font-medium leading-tight rounded-2xl bg-sirius-gray-200"
                >
                  <IconInfo class="mr-3" white />
                  <!-- eslint-disable-next-line -->
                  <p v-html="$t('disposableAppliancesAreNotIncluded')" />
                </div>
                <p class="font-bold text-xl mt-7">
                  {{ $t('toAddDisposableAppliances') }}
                </p>
                <CartProduct
                  class="mt-5"
                  :product="formatedAppliances"
                  :is-cart="false"
                  :added-from="PRODUCT_ADDED.PRODUCT"
                />
              </template>

              <div class="product-modal-bottom">
                <UiButton v-if="isAuthorized && isPending" size="LG">
                  <UiLoader size="lg" variant="white" />
                </UiButton>
                <UiButton
                  v-else-if="!maxQuantity"
                  variant="gray"
                  size="LG"
                  disabled
                >
                  <span class="px-1"> {{ $t('outOfStock') }}</span>
                </UiButton>
                <UiButton
                  v-else-if="isReplaceable"
                  size="LG"
                  @click="haptic(), onReplaceProduct(replaceFromFull)"
                >
                  <span class="px-1"> {{ $t('toReplace') }}</span>
                </UiButton>
                <ProductCounter
                  v-else-if="quantity"
                  :model-value="quantity"
                  :max="maxQuantity"
                  size="xl"
                  @onIncrement="haptic(), onIncrement(addToCart)"
                  @onDecrement="haptic(), onDecrement(removeFromCart)"
                  @onMaxLimit="onMaxLimit"
                >
                  <p class="pl-1" @click="closeModal">
                    {{ quantity }} x {{ price }} ₸
                  </p>
                  <p
                    v-if="maxQuantity > 0 && quantity === maxQuantity"
                    class="max-limit pr-1"
                    @click="closeModal"
                  >
                    {{ $t('maxLimit') }}
                  </p>
                </ProductCounter>
                <UiButton
                  v-else
                  size="LG"
                  @click="haptic(), onAddToCart(addToCart)"
                >
                  <span class="pl-1">{{ $t('toAddToCart') }}</span>
                  <span class="ml-auto pr-1">{{ price }} ₸</span>
                </UiButton>

                <p
                  class="product-modal-bottom-text"
                  :class="{ hidden: cartSize === 0 }"
                >
                  {{
                    minAmountReminder
                      ? $t('untilMinimumOrder', {
                          amount: formatPrice(minAmountReminder),
                        })
                      : $t('amountOfProductsInCart', {
                          price: formatPrice(cartTotalPriceWithDiscount),
                          unit: productDeclination,
                          count: cartSize,
                        })
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </RenderlessProduct>
    </UiBottomSheet>
  </div>
</template>

<script>
import { RenderlessProduct } from '@shared/components/product/RenderlessProduct';
import { getProductInfoList } from '@shared/components/product/lib/product-info';
import { ProductReviewService } from '@services/product-review';
import { PRODUCT_ADDED } from '@shared/config/product';
import { productFull } from './product-full';
import { RahmetApp } from '@shared/RahmetApp';
import { mapGetters } from 'vuex';

import ProductInfo from '@pure-ui/components/Product/ProductModal/components/ProductInfo.vue';
import ProductCounter from '@pure-ui/components/Product/ProductCounter/ProductCounter.vue';
import SalePrice from '@pure-ui/components/Product/ProductCard/components/SalePrice.vue';
import ProductLabels from '@pure-ui/components/Product/ProductLabels/ProductLabels.vue';
import ProductReviewWrapper from '@components/product-review/ProductReviewWrapper.vue';
import UiBottomSheet from '@pure-ui/components/UiBottomSheet/UiBottomSheet.vue';
import IconBackArrow from '@shared/components/icons/IconBackArrow.vue';
import UiLoader from '@pure-ui/components/UiLoader/UiLoader.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import IconShare from '@shared/components/icons/IconShare.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiImageSlider from '@ui/UiImageSlider.vue';

import IconInfo from '../icons/IconInfo.vue';
import CartProduct from './CartProduct.vue';
import FeatureTag from './FeatureTag.vue';

export default {
  name: 'ProductDetails',
  components: {
    ProductReviewWrapper,
    RenderlessProduct,
    ProductCounter,
    UiRippleLoader,
    UiImageSlider,
    ProductLabels,
    UiBottomSheet,
    IconBackArrow,
    ProductInfo,
    CartProduct,
    FeatureTag,
    SalePrice,
    IconShare,
    UiButton,
    IconInfo,
    UiLoader,
    SaveBtn: () => import('@pure-ui/components/Product/SaveBtn/SaveBtn.vue'),
  },
  mixins: [productFull],
  data() {
    return {
      PRODUCT_ADDED,
      productReview: null,
      show: false,
    };
  },
  computed: {
    ...mapGetters(['isAuthorized']),
    infoList() {
      return getProductInfoList(this.product);
    },
    description() {
      return this.$i18n.locale === 'kk'
        ? this.product.full_description_kk
        : this.product.full_description;
    },
    isReplaceable() {
      return (
        this.product.replacement === true &&
        this.product.replacement.replacement
      );
    },
  },
  watch: {
    'product.id': {
      handler(id) {
        if (id) {
          this.productReview = ProductReviewService.getReviewableProduct(id);
        }
      },
    },
  },
  methods: {
    openModal(id, query = {}) {
      this.loading = true;
      this.resetState();
      if (!id) {
        this.loading = false;
        return;
      }
      this.productId = id.toString();
      this.query = query;
      this.fetchProduct();
      this.show = true;
    },
    closeModal() {
      this.show = false;
      this.resetState();
    },
    localizeShelfLife(value) {
      let num = '';
      let text = '';
      if (!Number.isNaN(+value)) {
        num = +value;
        text = 'месяц';
      } else {
        let splited = value.split(' ');
        num = splited[0] || '';
        text = splited[1] || '';
      }
      if (!text && !num) return '';

      if (text.includes('дн') || text.includes('день')) {
        return this.$tc('dayPlural', num);
      }
      if (text.includes('мес')) {
        return this.$tc('monthPlural', num);
      }
      if (text.includes('лет') || text.includes('год')) {
        return this.$tc('yearPlural', num);
      }
      return num + text;
    },
    haptic() {
      RahmetApp.hapticSelection();
    },
    onMaxLimit() {
      RahmetApp.hapticNotification('error');
    },
  },
  eventBusEvents: {
    eb_open_product_details_modal(data) {
      this.openModal(data.id, data.query);
    },
    eb_close_product_details_modal() {
      this.closeModal();
    },
  },
  i18n: {
    messages: {
      ru: {
        maxLimit: 'Больше нет',
        brand: 'Бренд',
        selectWeight: 'Выберите вес',
        mainPage: 'Главная',
        available: 'в наличии {amount} шт.',
        availableYet: 'в наличии ещё {amount} шт.',
        toReplace: 'Заменить',
        toAddToCart: 'Добавить в корзину',
        outOfStock: 'Нет в наличии',
        untilMinimumOrder: 'До минимального заказа {amount} ₸',
        amountOfProductsInCart: 'В корзине {count} {unit} на {price} ₸',
        disposableAppliancesAreNotIncluded:
          'Одноразовые приборы не&nbsp;прилагаются к&nbsp;блюду',
        toAddDisposableAppliances: 'Добавить одноразовые приборы?',
        specifications: 'Характеристики',
        calories: 'Калории',
        proteins: 'Белки',
        fats: 'Жиры',
        carbohydrates: 'Углеводы',
        shelfLife: 'Срок хранения',
        productWeight: 'Вес товара',
        description: 'Описание',
        vitamins: 'Витамины',
        manufacturer: 'Производитель',
        lactelQazaqstan: 'Lactel, Казахстан',
        country: 'Страна',
        color: 'Цвет',
        sugar: 'Сахар',
        strength: 'Крепость',
        dayPlural: '0 дней | {n} день | {n} дня | {n} дней',
        monthPlural: '0 месяцев | {n} месяц | {n} месяца | {n} месяцев',
        yearPlural: '0 лет | {n} год | {n} года | {n} лет',
      },
      kk: {
        maxLimit: 'Қалмады',
        brand: 'Бренд',
        selectWeight: 'Салмақ таңдаңыз',
        mainPage: 'Бастапқы',
        available: '{amount} шт. бар',
        availableYet: 'тағы {amount} шт. бар',
        toReplace: 'Ауыстыру',
        toAddToCart: 'Себетке салу',
        outOfStock: 'Әзірге жоқ',
        untilMinimumOrder: 'Төменгі шекті тапсырысқа дейін {amount} ₸',
        amountOfProductsInCart: 'Себетте {price} ₸-ге {count} тауар',
        disposableAppliancesAreNotIncluded:
          'Бір реттік ыдыстар тамақпен бірге берілмейді',
        toAddDisposableAppliances: 'Бір реттік ыдыстарды қосу қажет пе?',
        specifications: 'Сипаттамалар',
        calories: 'Калориялар',
        proteins: 'Ақуыздар',
        fats: 'Майлар',
        carbohydrates: 'Көмірсулар',
        shelfLife: 'Сақтау мерзімі',
        productWeight: 'Тауар салмағы',
        description: 'Сипаттама',
        vitamins: 'Дәрумендер',
        manufacturer: 'Өндіруші',
        lactelQazaqstan: 'Lactel, Қазақстан',
        country: 'Елі',
        color: 'Түсі',
        sugar: 'Қант түрі',
        strength: 'Күштілігі',
        dayPlural: '{n} күн',
        monthPlural: '{n} ай',
        yearPlural: '{n} жыл',
      },
    },
  },
};
</script>

<style scoped>
.product-modal-bottom {
  @apply w-full fixed bottom-0 left-0
    px-4 py-3;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
}
.product-modal-bottom-text {
  @apply mt-3
  text-xs font-medium leading-14
  text-sirius-gray-1000
  text-center;
}
.product-modal-info {
  @apply mt-4 flex items-center space-x-3;
}
.product-modal-price {
  @apply p-2 bg-sirius-gray-200 rounded-xl
    leading-5 font-bold;
}
.product-modal-weight {
  @apply font-medium leading-5 text-sirius-gray-100;
}
.max-limit {
  @apply text-sm font-medium leading-4;
  opacity: 0.7;
}
</style>
