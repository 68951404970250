import { DELIVERY_EVENTS, DeliveryEmitter } from '@services/delivery/emitter';

function onChangeWarehouse(data) {
  const { newVal, oldVal } = data;
  const oldId = oldVal?.id || undefined;
  const newId = newVal?.id || undefined;
  const hasDiff = newId !== oldId;
  if (hasDiff) {
    DeliveryEmitter.emit(DELIVERY_EVENTS.ON_WAREHOUSE_ID_CHANGE, {
      newId,
      oldId,
    });
  }
}

function init() {
  DeliveryEmitter.on(DELIVERY_EVENTS.ON_WAREHOUSE_CHANGE, onChangeWarehouse);
}

export const DeliveryService = {
  init,
};
