<template>
  <button class="GoToButton" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'GoToButton',
  props: {},
};
</script>

<style scoped>
.GoToButton {
  line-height: 24px;
  padding: 14px 12px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.9);
  font-weight: 700;
  backdrop-filter: blur(5px);
}

@media screen and (min-width: 768px) {
}
</style>
