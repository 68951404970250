import { DevConfigs } from '@/utils/dev-config';
import { requestGeoPermissionStatus } from 'choco-app';

const connect = callback => {
  return new Promise((resolve, reject) => {
    try {
      callback(resolve);
    } catch (err) {
      reject(err);
    }
  });
};

// window.RahmetApp
//     методы, которые нативка позволяет вызывать из миниапп
// window.RahmetWebApp
//     позволяет устанавливать обработчики,
//     которые будет вызывать нативка

export const RahmetApp = {
  isWebView() {
    return !!window.RahmetApp;
  },
  requestGeoPermissionStatus() {
    if (DevConfigs.isDev) return Promise.resolve('granted');
    return requestGeoPermissionStatus();
  },
  fetchRemoteConfigProperty(key) {
    return connect(resolve => {
      window.RahmetApp.fetchRemoteConfigProperty(key);
      window.RahmetWebApp.didGetRemoteConfigProperty = property =>
        resolve(property);
    });
  },
  authVersion() {
    if (
      window.RahmetApp &&
      window.RahmetApp.authorizeV2 &&
      window.RahmetWebApp.onAuthSuccessV2 &&
      typeof window.RahmetApp.authorizeV2 === 'function' &&
      typeof window.RahmetWebApp.onAuthSuccessV2 === 'function'
    )
      return 'v2';
    return 'v1';
  },
  authorizeV2(body) {
    return connect(resolve => {
      window.RahmetApp.authorizeV2(JSON.stringify(body));
      window.RahmetWebApp.onAuthSuccessV2 = (code, state) =>
        resolve({ code, state });
      window.RahmetWebApp.onAuthDismissed = () => resolve({ code: undefined });
      // window.RahmetWebApp.onAuthError = (errorCode, message) => resolve({ code: undefined });
      window.RahmetWebApp.onAuthError = () => resolve({ code: undefined });
    });
  },
  authorize() {
    return connect(resolve => {
      window.RahmetApp.authorize();
      window.RahmetWebApp.onAuthSuccess = (trackId, deviceId) =>
        resolve(trackId, deviceId);
      window.RahmetWebApp.onAuthDismissed = () => resolve(undefined);
    });
  },
  logAppsflyerEvent(event) {
    if (
      window.RahmetApp &&
      window.RahmetApp.hasOwnProperty('logAppsflyerEvent') &&
      typeof window.RahmetApp.logAppsflyerEvent === 'function'
    ) {
      window.RahmetApp.logAppsflyerEvent(event);
    }
  },
  shareReferralCode(text) {
    if (window.RahmetApp && window.RahmetApp.shareReferralCode) {
      window.RahmetApp.shareReferralCode(text);
    }
  },
  shareFile(base64, filename = 'name', mime = 'image/png') {
    if (
      window.RahmetApp &&
      window.RahmetApp.loadBase64File &&
      typeof window.RahmetApp.loadBase64File === 'function'
    ) {
      window.RahmetApp.loadBase64File(
        JSON.stringify({ base64, filename, mime })
      );
    }
  },
  shareToInstagram(base64, filename = 'name', mime = 'image/png') {
    if (
      window.RahmetApp &&
      window.RahmetApp.shareImage &&
      typeof window.RahmetApp.shareImage === 'function'
    ) {
      window.RahmetApp.shareImage(JSON.stringify({ base64, filename, mime }));
    }
  },
  shareText(text) {
    if (
      window.RahmetApp &&
      window.RahmetApp.shareText &&
      typeof window.RahmetApp.shareText === 'function'
    ) {
      window.RahmetApp.shareText(text);
    }
  },
  backToRahmetApp() {
    if (window.RahmetApp && window.RahmetApp.backToRahmetApp) {
      window.RahmetApp.backToRahmetApp();
    }
  },
  hapticSelection() {
    if (window.RahmetApp && window.RahmetApp.hapticSelection) {
      window.RahmetApp.hapticSelection();
    }
  },
  hapticNotification(type) {
    // success, warning, error
    if (window.RahmetApp && window.RahmetApp.hapticNotification) {
      window.RahmetApp.hapticNotification(type);
    }
  },
  hapticImpact(type) {
    // light, medium, heavy
    if (window.RahmetApp && window.RahmetApp.hapticImpact) {
      window.RahmetApp.hapticImpact(type);
    }
  },
  /**
   * Открыть ссылку в браузере
   * @param {Object} body
   * @param {string} body.url
   * @returns
   */
  openLink(body) {
    if (window.RahmetApp && window.RahmetApp.openLink) {
      window.RahmetApp.openLink(JSON.stringify(body));
      return true;
    }
    return false;
  },
  openAppSettings() {
    if (window.RahmetApp && window.RahmetApp.openAppSettings) {
      window.RahmetApp.openAppSettings();
      return true;
    }
    return false;
  },
  failedReferralCode(method) {
    window.RahmetWebApp.didFailReferralCode = method;
  },
  setOnNativePayViewClosed(method) {
    window.RahmetWebApp.onNativePayViewClosed = method;
  },
  applicationBecomeActive(method) {
    window.RahmetWebApp.didBecomeActive = method;
  },
  androidKeyboardClosed(method) {
    window.RahmetWebApp.onKeyboardClosed = method;
  },
  onBackPressed(method) {
    window.RahmetWebApp.onBackPressed = method;
  },
  /**
   * Обновить страницу
   * В отличие от document.location.reload
   * при перезагрузке нативное приложение
   * передаст параметры вроде code, device_id, session_id и т. п.
   */
  reloadPage() {
    if (window.RahmetApp && window.RahmetApp.reloadPage) {
      window.RahmetApp.reloadPage();
    }
  },

  setMocsForDevelopment() {
    if (import.meta.env.VITE_DEPLOYMENT !== 'DEVELOPMENT') return;
    window.RahmetApp = {
      openAppSettings() {
        return true;
      },
      getNotificationStatus() {
        setTimeout(
          () => window.RahmetWebApp.didGetIsNotificationStatus('granted'),
          1500
        );
      },
      requestNotificationStatus() {
        setTimeout(
          () => window.RahmetWebApp.didGetIsNotificationStatus('granted'),
          1500
        );
      },
      fetchRemoteConfigProperty() {
        setTimeout(
          () =>
            window.RahmetWebApp.didGetRemoteConfigProperty(
              '{"rate_tips_dev":"old"}'
            ),
          1500
        );
      },
      authorize() {
        setTimeout(() => window.RahmetWebApp.onAuthSuccess('trackID'), 1500);
        // setTimeout(() => window.RahmetWebApp.onAuthDismissed(), 1500);
      },
      authorizeV2() {
        setTimeout(
          () => window.RahmetWebApp.onAuthSuccessV2('code', 'state'),
          1500
        );
        // setTimeout(() => window.RahmetWebApp.onAuthDismissed(), 1500);
      },
      getGeoPermissionStatus() {
        return 'granted';
      },
    };
  },
  setupWebViewApi() {
    window.RahmetWebApp = {
      onAuthSuccess() {},
      onAuthSuccessV2() {},
      onAuthDismissed() {},
      onPaySuccess() {},
      onGeoPermissionStatusDefined() {},
      onNativePayViewClosed() {},
      didBecomeActive() {},
      loadReferralSuccess() {},
      onKeyboardClosed() {},
      didFailReferralCode() {},
      didGetRemoteConfigProperty() {},
      onBackPressed() {},
      didGetIsNotificationStatus() {},
    };
  },
};
