<template>
  <div class="MainAddressContext">
    <UiTooltipSimple
      v-scroll="{ onScrolled: onScroll }"
      :show="showTooltip && !scrolledDown"
    >
      <div class="main-address-context">
        <div class="main-address-context-line">
          <p class="line-clamp-1 break-all"><slot name="address"></slot></p>
          <IconArrow />
        </div>
        <div
          class="main-address-context-line main-address-context-time"
          :class="{ 'pr-3': !isDistant }"
        >
          <p class="line-clamp-1 break-all"><slot name="time"></slot></p>

          <ExtendedDeliveryTooltip
            v-if="isDistant"
            type="delivery-context-mob"
            class="main-address-context-icon"
            :time="expectedDeliveryTime"
            @click.native.stop
          />
        </div>
      </div>

      <template #tip>
        <p
          class="main-address-context-tooltip"
          @click.stop="showTooltip = false"
          v-html="$t('youAreFar')"
        />
      </template>
    </UiTooltipSimple>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExtendedDeliveryTooltip from '@components/extended-delivery/ExtendedDeliveryTooltip.vue';
import UiTooltipSimple from '@pure-ui/components/UiTooltip/UiTooltipSimple.vue';
import IconArrow from './components/IconArrow.vue';
import Scroll from '@/utils/directives/scroll';

export default {
  components: { ExtendedDeliveryTooltip, UiTooltipSimple, IconArrow },
  directives: { Scroll },
  props: {
    time: {
      type: String,
      default: '13-17',
    },
  },
  data() {
    return {
      showTooltip: false,
      scrolledDown: false,
      showDistant: true,
    };
  },
  computed: {
    ...mapGetters('delivery', ['expectedDeliveryTime']),
    ...mapGetters('paidOrder', ['isDistant']),
    ...mapGetters('user', [
      'isGeoNearDelAddress',
      'deliveryAddress',
      'addressCount',
    ]),
  },
  watch: {
    isGeoNearDelAddress(val) {
      this.showTooltip = !val;
      this.scrolledDown = false;
    },
  },
  methods: {
    onScroll() {
      this.scrolledDown = window.scrollY > 50;
    },
  },
  i18n: {
    messages: {
      ru: {
        youAreFar: `Вы находитесь далеко от указанного <br /> адреса, проверьте его корректность`,
      },
      kk: {
        youAreFar: `Сіз көрсетілген мекенжайдан алыс тұрсыз, <br /> оның дұрыстығын тексеріңіз`,
      },
    },
  },
};
</script>

<style scoped>
.main-address-context {
  @apply text-sm font-medium leading-4;
}
.main-address-context-line {
  @apply flex items-center justify-center;
}
.main-address-context-time {
  @apply text-sirius-gray-1000;
}
.main-address-context-tooltip {
  @apply p-2 text-xs font-medium leading-14 whitespace-nowrap;
}
.main-address-context-icon {
  @apply ml-1;
  width: 20px;
  max-width: 20px;
  max-height: 20px;
}
</style>
