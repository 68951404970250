<template>
  <div class="">
    <div v-for="(v, i) in logs" :key="`logger_${i}`" class="flex flex-col mb-6">
      <p
        class="text-xs font-medium"
        :class="[
          v.title.includes('Err')
            ? 'text-sirius-red-200'
            : v.title.includes('Res')
              ? 'text-sirius-green-100'
              : 'text-white',
        ]"
      >
        <span class="text-xss text-sirius-gray-100">{{ v.time }}</span>
        -- {{ v.title }} --
      </p>
      <p class="text-xs text-white">
        {{ v.subtitle }}
      </p>
      <ShowMore
        v-if="v.data"
        :id="`logger_${type}_${i}`"
        :data="{ key: v.data }"
      />
    </div>
  </div>
</template>

<script>
import ShowMore from './ShowMore.vue';

export default {
  name: 'ConsoleView',
  components: { ShowMore },
  props: {
    type: {
      type: String,
      default: 'Logs',
    },
    logs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
