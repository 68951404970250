import dayjs from 'dayjs';

export default function (v) {
  const conditions = JSON.parse(v.conditions);
  const today = dayjs();
  const expiredAt = dayjs(v.expired_at);
  const isExpired = today.isAfter(expiredAt);
  const isApplied = !!v.activated_at;
  const activatedAt = v.activated_at;

  return {
    promocode: v.promocode,
    conditions,
    discount: +conditions.discount_amount,
    /**
     * Минимальная сумма заказа для применения промокода
     */
    minimumSum: +conditions.amount_gt_than,
    until: expiredAt.format('YYYY-MM-DD'),
    reason: v.promo_type,
    isExpired,
    isApplied,
    isArchive: isApplied || isExpired,
    activatedAt,
    freeDelivery: conditions.free_delivery === '1',
    // сам размер в процентах в discount_amount
    isPercentageDiscount: conditions.discount_type === 'percentage',
    percentageDiscountLimit: Number(conditions.discount_amount_limit || 0),
  };
}
