<template>
  <div class="">
    <label
      :for="`${$attrs.name}_id`"
      class="px-4 transition-all easy-in-out duration-200 flex flex-col justify-center w-full rounded-xl border bg-white"
      :class="{
        'border-sirius-red-200': invalid,
        'border-sirius-orange-100': focused,
        'border-sirius-gray-200': !invalid && !focused,
        'py-1': value || focused,
        'py-3': !value && !focused,
      }"
    >
      <p
        class="transition-all easy-in-out duration-200 text-sirius-gray-100 leading-5"
        :class="`${value || focused ? 'text-xs leading-4' : 'leading-5'}`"
      >
        {{ $attrs.placeholder }}
      </p>
      <textarea
        :id="`${$attrs.name}_id`"
        :value="value"
        class="textarea appearance-none focus:outline-none transition easy-in-out duration-200 w-full mt-0.5 p-0 bg-transparent text-sirius-black-100"
        :style="`${noResize ? 'resize: none' : ''}`"
        v-bind="$attrs"
        v-on="$listeners"
        @focus="onFocus"
        @blur="focused = false"
        @input="onInput"
      ></textarea>
    </label>
    <div v-if="invalid && errorMessage.length > 0" class="mt-0.5 flex">
      <span class="pl-2 text-sirius-red-200 text-xs leading-tight">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MmTextarea',
  model: {
    prop: 'value',
    event: 'onInput',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    noResize: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    focused: false,
  }),
  methods: {
    onInput(event) {
      this.$emit('onInput', event.target.value);
    },
    onFocus() {
      this.focused = true;
      this.$emit('onFocus');
    },
  },
};
</script>

<style scoped>
.textarea::placeholder {
  color: rgba(0, 0, 0, 0);
}
</style>
