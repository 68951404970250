<template>
  <a
    class="WaLink"
    href="https://wa.me/77014000079"
    target="_blank"
    @click.prevent="() => {}"
  >
    <WaIcon width="24" class="WaLink__icon" />
    <span class="WaLink__text"> +7 701 400 00 79 </span>
    <span
      class="WaLink__descr"
      v-html="
        $i18n.locale === 'ru' ? 'написать в Whatsapp' : 'WhatsApp-қа жазыңыз'
      "
    />
  </a>
</template>

<script>
import WaIcon from './icons/whatsapp.svg';

export default {
  name: 'WaLink',
  components: { WaIcon },
};
</script>

<style scoped>
.WaLink {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 0 12px;
  text-decoration: none;
  color: #000;
}

.WaLink:hover {
  color: #ffa100;
}

.WaLink__icon {
}

.WaLink__text {
  color: inherit;
  font-weight: 500;
}

.WaLink__descr {
  color: #7b7a8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.07px;
  grid-column: 2;
}

.WaLink__col_icon {
}
</style>
