import { api } from '@shared/services/api';

export function fetchProducts({
  limit,
  sorting = 'last_shopped_at:desc',
} = {}) {
  return api.lavkaFavorites.shoppingHistoryProductsList({
    limit,
    sorting,
  });
}
