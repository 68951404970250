<template>
  <div class="MmForm">
    <FormScreen v-if="state === 'form'" @onSuccess="state = 'success'" />
    <SuccessScreen
      v-if="state === 'success'"
      @onDone="
        $emit('onDone');
        state = 'form';
      "
    />
  </div>
</template>

<script>
import FormScreen from './FormScreen.vue';
import SuccessScreen from './SuccessScreen.vue';

export default {
  name: 'MmForm',
  components: { SuccessScreen, FormScreen },
  inject: [],
  props: {},
  data: () => ({
    /**
     * 'form' | 'success'
     */
    state: 'form',
  }),

  i18n: {
    messages: {
      kk: {},
      ru: {},
    },
  },
};
</script>

<style scoped>
.MmForm {
  min-height: 100%;
}
</style>
