import { client } from '../../clients';

/**
 * @typedef Review
 * @prop {string} id
 * @prop {number} product_id
 * @prop {string} review
 * @prop {string} comment
 * @prop {boolean} call_back
 * @param {Review[]} reviews
 * @returns Promise
 */
export default function (reviews) {
  return client
    .post('ryadom-review/api/v1/review/products', reviews, {
      useJSONParser: true,
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: v => JSON.stringify(v),
      transformRequest: v => JSON.stringify(v),
    })
    .then(response => response.data);
}
