export const BannersModule = {
  namespaced: true,
  state: {
    banners: [],
    categoryBanners: {},
  },
  getters: {
    banners: state => {
      return state.banners;
    },
    categoryBanners: state => {
      return state.categoryBanners;
    },
  },
  mutations: {
    SET_BANNERS(state, value) {
      state.banners = value;
    },
    SET_CATEGORY_BANNERS(state, value) {
      state.categoryBanners = value;
    },
  },
};
