<template>
  <div
    class="category p-2 md:p-4 bg-cover relative w-full h-full overflow-hidden cursor-pointer rounded-2xl md:rounded-3xl"
    :style="`background-color: ${categoryColor(category)};`"
    :class="category.id === 794 ? 'pr-0 md:pr-4' : ''"
    @click.prevent="navigate"
  >
    <h3 class="font-medium z-10 text-xs md:text-lg">
      {{ category.name }}
    </h3>

    <img
      :src="compIcon"
      alt=""
      class="absolute w-full h-full left-0 bottom-0 z-block object-cover md:object-contain"
    />
    <!-- {{ category.icon_big }} -->
  </div>
</template>

<script>
import { RahmetApp } from '@shared/RahmetApp';
import {
  Analitycs,
  EVENTS,
  APPSFLYER_EVENTS,
} from '@shared/services/analitycs';

export default {
  name: 'SpecialCategory',
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  computed: {
    compIcon() {
      /* eslint-disable camelcase */
      const { isDesktop } = this.$store.getters;
      const { icon, icon_big } = this.category;
      return isDesktop ? icon_big || icon : icon;
      /* eslint-enable camelcase */
    },
  },
  methods: {
    categoryColor(category) {
      if (category.color) return category.color;
      return '#F3FFEF';
    },
    navigate() {
      RahmetApp.hapticSelection();
      Analitycs.logEvent(EVENTS.CATEGORY_SELECTED, {
        categoryId: this.category.id.toString(),
        categoryName: this.category.name,
        from: 'main',
      });
      Analitycs.logAppsflyerEvent(APPSFLYER_EVENTS.DOM_NEW_MAIN_CATEGORY_MAIN, {
        dom_type: 'main',
        dom_category_id: this.category.id.toString(),
        dom_category_title: this.category.name,
      });

      this.$router.push({
        name: 'category',
        params: {
          id: this.category.id.toString(),
          route: this.$route,
        },
      });
    },
  },
};
</script>

<style scoped>
.category {
  height: 120px;
  min-width: 140px;
  max-width: 140px;
}
@media (min-width: 768px) {
  .category {
    height: 152px;
    width: 100%;
    /* min-width: 388px;
    max-width: 388px; */
    min-width: unset;
    max-width: unset;
  }
}
</style>
