<template>
  <transition name="fade">
    <div class="MmMMenu">
      <div class="MmMMenu__container">
        <TopBlock @onClose="$emit('onClose')" />
        <div class="MmMMenu__content">
          <img
            class=""
            src="@/views/micromarket/images/logo.png"
            alt=""
            width="139"
            height="24"
          />
          <LinkItems class="MmMMenu__links" @onLinkClick="onLinkClick" />
        </div>
        <MmButton class="MmMMenu__btnApply" @click="onBtnClick">
          {{ $t('toLeaveApplience') }}
        </MmButton>
      </div>
    </div>
  </transition>
</template>

<script>
import { ScrollTo } from '@/views/micromarket/utils/scroll-to';
import MmButton from '@/views/micromarket/components/MmButton/MmButton.vue';
import LinkItems from './LinkItems.vue';
import TopBlock from './TopBlock.vue';

export default {
  name: 'MmMMenu',
  components: { LinkItems, TopBlock, MmButton },
  inject: [],
  props: {},
  data: () => ({}),

  methods: {
    onLinkClick(scrollToId) {
      this.$emit('onClose');
      setTimeout(() => {
        ScrollTo.elementId(scrollToId);
      }, 250);
    },
    onBtnClick() {
      this.$emit('onClose');
      this.$router.replace({ query: { form: true } });
    },
  },
  i18n: {
    messages: {
      kk: { toLeaveApplience: 'Өтінім қалдыру' },
      ru: { toLeaveApplience: 'Оставить заявку' },
    },
  },
};
</script>

<style scoped>
.MmMMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 10;
}

.MmMMenu__container {
  margin: auto;
  width: calc(100% - 32px);
  max-width: 535px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.MmMMenu__content {
  padding-top: 48px;
}

.MmMMenu__links {
  padding-top: 32px;
}

.MmMMenu__btnApply {
  margin-top: auto;
  margin-bottom: 64px;
}
</style>
