<template>
  <MainLayout>
    <template #header>
      <ToolBar :back="onBackClick">
        <p class="truncate">{{ recipe.name || '' }}</p>
      </ToolBar>
    </template>
    <UiRippleLoader v-if="loading" class="mx-auto" />
    <div v-else>
      <UiAspectRatio ratio="1:1">
        <UiImageSlider :images="imageSrc" />
      </UiAspectRatio>
      <div class="flex justify-between items-center px-5 my-5">
        <p class="text-sm font-bold text-black dark:text-white">
          {{ recipe.name || '' }}
        </p>
        <span
          class="px-3 py-px text-xs text-gray-700 bg-gray-400 rounded dark:bg-black"
        >
          {{ recipe.ingredients ? recipe.ingredients.length : 0 }} шт.
        </span>
      </div>
      <div class="bg-gray-400 p-5 text-sm text-gray-700 dark:bg-black">
        <span class="text-black font-bold dark:text-white">Состав:</span>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="recipe.composition"></p>
      </div>
      <div v-if="ingredients.length > 0" class="p-5 relative">
        <p class="text-sm font-bold text-black dark:text-white">
          Необходимые продукты:
        </p>
        <RecipeProduct
          v-for="prod in ingredients"
          :key="uniqueKey(prod.id, prod.category_id)"
          class="border-b border-solid border-gray-400 dark:border-black"
          :product="prod"
          :recipe-id="recipe.id"
        />
        <div
          v-if="!isExpanded && isIngredientsHidden"
          class="ingredientsBlind"
        ></div>
      </div>
      <div v-if="ingredients.length > 0" class="p-5 flex flex-col">
        <button
          v-if="isIngredientsHidden && !isExpanded"
          class="mx-auto mb-4 focus:outline-none text-orange-400 active:text-orange-700"
          @click="expandIngredients(true)"
        >
          Показать еще
          {{ recipe.ingredients ? recipe.ingredients.length - 3 : '' }}
        </button>
        <button
          v-if="isExpanded"
          class="mx-auto mb-4 focus:outline-none text-orange-400 active:text-orange-700"
          @click="expandIngredients(false)"
        >
          Скрыть
        </button>
        <UiButton variant="outline" class="text-sm" @click="onAllAddInCart">
          Добавить все продукты в корзину
        </UiButton>
      </div>
      <div class="bg-gray-400 p-5 text-sm text-gray-700 dark:bg-black">
        <p class="text-black font-bold dark:text-white">Рецепт:</p>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="recipe.recipe"></p>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import { api } from '@shared/services/api';
import { mapGetters, mapActions } from 'vuex';

import MainLayout from '@layout/MainLayout.vue';
import ToolBar from '@shared/components/layout/ToolBar.vue';
import UiRippleLoader from '@ui/UiRippleLoader.vue';
import UiAspectRatio from '@pure-ui/components/UiAspectRatio/UiAspectRatio.vue';
import UiImageSlider from '@ui/UiImageSlider.vue';
import RecipeProduct from '@shared/components/product/RecipeProduct.vue';
import UiButton from '@ui/UiButton.vue';
import { Analitycs, EVENTS } from '@shared/services/analitycs';

export default {
  name: 'RecipeFull',
  components: {
    UiButton,
    MainLayout,
    ToolBar,
    UiRippleLoader,
    UiAspectRatio,
    RecipeProduct,
    UiImageSlider,
  },
  inject: ['toast'],
  data() {
    return {
      loading: true,
      recipe: {},
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters(['isDesktop']),
    ...mapGetters('delivery', ['warehouseId']),
    imageSrc() {
      return [
        {
          path: this.recipe.icon_dish,
        },
      ];
    },
    ingredients() {
      return this.isExpanded || !this.isIngredientsHidden
        ? this.recipe.ingredients
        : this.recipe.ingredients.slice(0, 3);
    },
    isIngredientsHidden() {
      return this.recipe.ingredients && this.recipe.ingredients.length > 3;
    },
    allProductsIntoCartPayload() {
      const payload = {
        warehouse_id: this.warehouseId,
        products: [],
        added_from: `recipe_${this.recipe.id}`,
      };

      if (this.recipe.ingredients) {
        this.recipe.ingredients.forEach(v => {
          let product = {
            product_id: v.product_id,
            quantity: 1,
          };

          if (v.feature_id) {
            product = Object.assign(product, { feature_id: v.feature_id });
          }
          payload.products.push(product);
        });
      }
      return payload;
    },
  },
  created() {
    this.fetchRecipe();
  },
  mounted() {
    Analitycs.logEvent(EVENTS.VIEWED_RECIPE);
  },
  methods: {
    ...mapActions('cart', {
      fetchCart: 'FETCH_CART',
    }),
    ...mapActions('component', {
      resetRecipeState: 'RESET_RECIPE_STATE',
    }),
    uniqueKey: (id, categoryId) => {
      return `${id}_${categoryId}`;
    },
    fetchRecipe() {
      api.lavka
        .recipeIngredients(this.warehouseId, this.$route.params.id)
        .then(res => {
          this.recipe = res.data;
        })
        .finally(() => (this.loading = false));
    },
    expandIngredients(value) {
      this.isExpanded = value;
    },
    onAllAddInCart() {
      Analitycs.logEvent(EVENTS.RECIPE_ADD_ALL, {
        recipe_id: this.recipeId,
        product_ids: this.allProductsIntoCartPayload.products,
      });
      api.lavka
        .addIngredientsIntoCart(this.allProductsIntoCartPayload)
        .then(() => this.fetchCart())
        .catch(err => this.toast.show(err.message));
    },
    onBackClick() {
      Analitycs.logEvent(EVENTS.RECIPE_BACK, {
        recipe_id: this.$route.params.id,
      });
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="postcss">
.ingredientsBlind {
  @apply absolute left-0 bottom-0 w-full;
  height: 150px;
  background: white;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.7) 70%,
    rgba(255, 255, 255, 1) 100%
  );
}

@media (prefers-color-scheme: dark) {
  .ingredientsBlind {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(37, 37, 37, 0.7) 70%,
      rgba(37, 37, 37, 1) 100%
    );
  }
}
</style>
