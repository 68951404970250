<template>
  <div class="PromoModalWrapper">
    <PromoModal
      v-model="show"
      v-bind="{ ...modalData }"
      v-on="{ onClose, onButtonClick }"
    />
  </div>
</template>

<script>
import PromoModal from '@/pure-ui/components/PromoModal/PromoModal.vue';
import { fetchActiveModal } from './lib/fetch-active-modal';

export default {
  name: 'PromoModalWrapper',
  components: { PromoModal },
  data: () => ({
    show: false,
    modalData: {},
    initPageLoadDone: false,
  }),
  methods: {
    onClose() {
      this.show = false;
    },
    onButtonClick() {
      this.show = false;
      // откладываем переход, т. к. иначе модалка не успевает закрыться
      // и скролл перестаёт работать
      this.$nextTick(() => {
        if (this.modalData.url) {
          this.$router.push(this.modalData.url);
        }
      });
    },
  },
  eventBusEvents: {
    eb_on_city_changed() {
      if (this.initPageLoadDone) {
        fetchActiveModal().then(result => {
          if (result.status === 'HasActiveModal') {
            this.modalData = result.data;
            this.show = true;
          }
        });
      }
    },
    eb_init_page_load_done() {
      this.initPageLoadDone = true;
      if (this.$route.name === 'product-full') return;
      if (this.$route.query.productDeeplink) return;

      fetchActiveModal().then(result => {
        if (result.status === 'HasActiveModal') {
          this.modalData = result.data;
          this.show = true;
        }
      });
    },
  },
};
</script>
