<template>
  <li
    :class="{ 'menu-item_gradient': gradient }"
    class="menu-item mt-3"
    v-on="$listeners"
  >
    <component
      :is="routerTo ? 'router-link' : 'div'"
      :to="routerTo"
      class="menu-item__bg bg-sirius-gray-200 rounded-2xl flex items-center cursor-pointer"
      style="min-height: 56px"
    >
      <span v-if="iconComp" class="menu-item__icon">
        <component :is="iconComp" />
      </span>
      <span class="text-sm">
        <slot />
      </span>
      <span class="menu-item__right ml-auto flex items-center">
        <span v-if="typeof counter === 'number'" class="menu-item__counter">
          {{ counter }}
        </span>
        <span class="menu-item__arrow ml-2">
          <IconProfileArrow />
        </span>
      </span>
    </component>
  </li>
</template>

<script>
import IconProfileArrow from '@shared/components/icons/IconProfileArrow.vue';

import IconProfilePromocodes from '@shared/components/icons/IconProfilePromocodes.vue';
import IconProfileCashback from '@shared/components/icons/IconProfileCashback.vue';
import IconProfileFeedback from '@shared/components/icons/IconProfileFeedback.vue';
import IconProfileLocation from '@shared/components/icons/IconProfileLocation.vue';
import IconProfileSupport from '@shared/components/icons/IconProfileSupport.vue';
import IconProfileOrders from '@shared/components/icons/IconProfileOrders.vue';
import IconProfileGift from '@shared/components/icons/IconProfileGift.vue';
import IconProfileDocs from '@shared/components/icons/IconProfileDocs.vue';
import IconProfileFaq from '@shared/components/icons/IconProfileFaq.vue';
import IconProfileIIN from '@shared/components/icons/IconProfileIIN.vue';

const icons = {
  IconProfilePromocodes,
  IconProfileCashback,
  IconProfileFeedback,
  IconProfileLocation,
  IconProfileSupport,
  IconProfileOrders,
  IconProfileDocs,
  IconProfileGift,
  IconProfileFaq,
  IconProfileIIN,
};

export default {
  name: 'MenuItem',
  components: {
    IconProfileArrow,
  },
  props: {
    routerTo: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: Number,
      default: null,
    },
  },
  computed: {
    iconComp() {
      if (this.icon) {
        return icons[this.icon];
      }
      return null;
    },
  },
};
</script>

<style scoped>
.menu-item {
  --text-color: #000;
  --icon-color: #7b7a8c;
  --bg: #f1f2f7;
  --font-weight: normal;
}

.menu-item_gradient {
  --text-color: #fff;
  --icon-color: #fff;
  --bg: linear-gradient(144.13deg, #f8c355 19.93%, #ffa28e 76.37%);
  --font-weight: 700;
}

.menu-item__bg {
  padding-left: 18px;
  padding-right: 14px;
  background: var(--bg);
  color: var(--text-color);
  font-weight: var(--font-weight);
}

.menu-item__icon {
  color: var(--icon-color);
  margin-right: 14px;
}

.menu-item__arrow {
  color: var(--icon-color);
}

.menu-item__counter {
  padding: 0 8px;
  border-radius: 12px;
  line-height: 26px;
  min-width: 26px;
  font-size: 14px;
  display: inline-block;
  background: #fff;
  text-align: center;
  font-weight: 500;
  @apply text-sirius-orange-100;
}
</style>
