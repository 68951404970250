import axios from 'axios';
import { stringify } from 'qs';
import { Logger as LOG } from '@shared/services/logger';
import { transformError } from './utils';
import { installAxiosMeasureTime } from './utils/install-axios-measure-time';

const devClient = axios.create({
  baseURL: import.meta.env.VITE_CHOCO_GATEWAY,
  paramsSerializer: stringify,
  transformRequest: [data => stringify(data)],
});

installAxiosMeasureTime(devClient);

devClient.interceptors.request.use(
  config => {
    LOG.event(`Config ${config.url}`, config, 'Network');
    return config;
  },
  err => Promise.reject(err)
);

devClient.interceptors.response.use(
  res => {
    LOG.event(`Res: ${res.config.url}`, res, 'Network');

    return res.data.error_code && res.data.error_code !== 0
      ? Promise.reject(transformError(res.data))
      : res;
  },
  err => {
    LOG.event(`Err: ${err.config.url}`, err, 'Network');
    return Promise.reject(transformError(err));
  }
);

export { devClient };
