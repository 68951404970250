import Story1kk from '@img/static-stories/zones-1-kk.jpg';
import Story1ru from '@img/static-stories/zones-1-ru.jpg';
import Story2kk from '@img/static-stories/zones-2-kk.jpg';
import Story2ru from '@img/static-stories/zones-2-ru.jpg';
import imageDesktop from '@img/static-banners/zones-d.jpg';
import imageMobile from '@img/static-banners/zones-m.jpg';

export const zonesBanner = {
  type: 'story',
  code: 'zones_main',
  fixedPosition: true,
  imageDesktop,
  imageMobile,
  textKk: `
    Қайда жеткіземіз
  `,
  textRu: `
    Куда мы доставляем
  `,
  buttonTextRu: 'Всё понятно!',
  buttonTextKk: 'Түсінікті!',
  stories: [
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
    },
    {
      imageKk: Story2kk,
      imageRu: Story2ru,
    },
  ],
};
