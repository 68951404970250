import Story1kk from '@img/static-stories/delivery-1-kk.jpg';
import Story1ru from '@img/static-stories/delivery-1-ru.jpg';
import Story2kk from '@img/static-stories/delivery-2-kk.jpg';
import Story2ru from '@img/static-stories/delivery-2-ru.jpg';
import Story3kk from '@img/static-stories/delivery-3-kk.jpg';
import Story3ru from '@img/static-stories/delivery-3-ru.jpg';
import imageDesktop from '@img/static-banners/delivery-d.jpg';
import imageMobile from '@img/static-banners/delivery-m.jpg';

export const deliveryBanner = {
  type: 'story',
  code: 'delivery_main',
  fixedPosition: true,
  imageDesktop,
  imageMobile,
  textKk: `
    Жеткізу жайлы
  `,
  textRu: `
    Всё о&nbsp;доставке
  `,
  buttonTextRu: 'Всё понятно!',
  buttonTextKk: 'Түсінікті!',
  stories: [
    {
      imageKk: Story1kk,
      imageRu: Story1ru,
    },
    {
      imageKk: Story2kk,
      imageRu: Story2ru,
    },
    {
      imageKk: Story3kk,
      imageRu: Story3ru,
    },
  ],
};
