<template>
  <button
    class="DesktopArrowButton"
    :class="{ DesktopArrowButton_rotated: name === 'right' }"
    v-on="$listeners"
  >
    <span class="DesktopArrowButton__inner">
      <ArrowLeftIcon class="DesktopArrowButton__svg" />
    </span>
  </button>
</template>

<script>
import ArrowLeftIcon from './icons/arrow-left.svg';

export default {
  name: 'DesktopArrowButton',
  components: { ArrowLeftIcon },
  props: {
    name: {
      type: String,
      default: 'left',
      validator: v => ['left', 'right'].includes(v),
    },
  },
};
</script>

<style scoped>
.DesktopArrowButton {
  --bg-color: #d4d3df;
  --arrow-color: #7b7a8c;

  position: relative;
  outline: none;
  width: 52px;
  height: 52px;
  color: var(--arrow-color);
  transition: color 0.3s;
}

/* click target */
.DesktopArrowButton::before {
  content: '';
  display: block;
  width: 148px;
  height: 148px;
  position: absolute;
  top: -48px;
  left: -48px;
}

.DesktopArrowButton:hover {
  --bg-color: #fff;
  --arrow-color: #000;
}

.DesktopArrowButton_rotated {
  transform: rotate(180deg);
}

.DesktopArrowButton__inner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: #d4d3df;
  border-radius: 20px;
  padding: 12px;
  background: var(--bg-color);
  transition: background-color 0.3s;
}
</style>
