export const SamplingsModule = {
  namespaced: true,
  state: {
    samples: [],
    notAvailableSamplings: [],
  },
  getters: {
    samples(state) {
      return state.samples;
    },
    sampleProducts(state, getters, rootState, rootGetters) {
      const samples = [...state.samples];
      return samples
        .map(sample => {
          const sampleProduct = {
            ...rootGetters['productDB/products'][
              `id_${sample.sample_product_id}`
            ],
            price: sample.sample_product_price || 1,
            quantity: sample.applied_count,
            sample_id: sample.id,
          };
          return sampleProduct;
        })
        .filter(p => p.quantity > 0 && p.max_quantity);
    },
    samplingTotalPrice(state, getters) {
      const products = [...getters.sampleProducts];
      return products.reduce((acc, s) => acc + s.price * s.quantity, 0);
    },
    notAvailableSamplings(state) {
      return state.notAvailableSamplings;
    },
  },
  mutations: {
    SET_SAMPLES(state, value) {
      state.samples = value;
    },
    SET_NOT_AVAILABLE_SAMPINGS(state, value) {
      state.notAvailableSamplings = value;
    },
    RESET_ALL(state) {
      state.samples = [];
      state.notAvailableSamplings = [];
    },
  },
};
