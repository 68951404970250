<template>
  <UiButton class="text-sm" variant="neutral" size="sm" v-on="$listeners">
    <p class="truncate">{{ isExpanded ? 'Свернуть' : title }}</p>
    <IconArrowDown
      class="ml-2 transform transition duration-200 ease-linear text-gray-600"
      :class="{ 'rotate-180': isExpanded }"
    />
  </UiButton>
</template>

<script>
import UiButton from '@ui/UiButton.vue';
import IconArrowDown from '@shared/components/icons/IconArrowDown.vue';

export default {
  name: 'FilterToggle',
  components: {
    UiButton,
    IconArrowDown,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
