<template>
  <div class="LoaderAnimation">
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      class="LoaderAnimation__img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.5 20C39.3284 20 40.0059 20.6739 39.9446 21.5001C39.1775 31.8451 30.5411 40 20 40C8.9543 40 0 31.0457 0 20C0 9.45891 8.15486 0.822494 18.4999 0.0554083C19.3261 -0.00585135 20 0.671573 20 1.5C20 2.32843 19.3261 2.99309 18.5009 3.06519C9.81417 3.82417 3 11.1163 3 20C3 29.3888 10.6112 37 20 37C28.8837 37 36.1758 30.1858 36.9348 21.4991C37.0069 20.6739 37.6716 20 38.5 20Z"
        fill="white"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LoaderAnimation',
  components: {},
  inject: [],
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
  i18n: { messages: { kk: {}, ru: {} } },
};
</script>

<style scoped>
.LoaderAnimation__img {
  animation: spin 0.5s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
