<template>
  <div class="UserAgreementReminderMobile">
    <div class="uar">
      <IconClose
        class="uar-close"
        @click.native="$emit('onClose', 'crestik')"
      />
      <p class="uar-title">{{ $t('title') }}</p>
      <p class="uar-descr" v-html="$t('descr1')" />
      <p class="uar-descr" v-html="$t('descr2')" />
      <div class="uar-btns">
        <UiButton
          size="SM"
          variant="gray"
          @click.native="$emit('onClose', 'btn')"
        >
          {{ $t('close') }}
        </UiButton>
        <UiButton size="SM" @click.native="$emit('onReady')">
          {{ $t('familiarize') }}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';
import IconClose from './IconClose.vue';
export default {
  components: { UiButton, IconClose },
  i18n: {
    messages: {
      kk: {
        title: 'Құжаттармен танысыңыз',

        descr1: `Біз Пайдаланушы келісімін жаңарттық жәнеде Қайтарымдар ережелерін қостық.`,
        descr2: `Бұл құжаттарды «Профиль»-дің ішінде Құжаттар бөлімінде көре аласыз.`,
        close: 'Жабу',
        familiarize: 'Танысу',
      },
      ru: {
        title: 'Ознакомьтесь с документами',
        descr1: `Мы обновили Пользовательское соглашение и&nbsp;добавили Политику возвратов и доставки.`,
        descr2: `Эти документы всегда можно найти в&nbsp;Профиле в&nbsp;разделе «Правовые документы».`,
        close: 'Закрыть',
        familiarize: 'Ознакомиться',
      },
    },
  },
};
</script>

<style scoped>
.uar {
  @apply p-4 pb-6 relative bg-white rounded-2xl;
  box-shadow:
    0px 0.83px 2.98px 0px #00000003,
    0px 2.81px 7.16px 0px #00000005,
    0px 6.78px 13.49px 0px #00000001,
    0px -3.51px 24.06px 0px #00000008,
    0px 3.51px 45px 0px #0000000a,
    0px 107.71px 107.71px 0px #00000008;
}
.uar-title {
  @apply font-medium leading-5;
}
.uar-descr {
  @apply mt-3 text-sm leading-18 text-sirius-gray-900;
}
.uar-btns {
  @apply mt-6 flex items-center space-x-3;
}
.uar-close {
  @apply absolute top-2 right-2;
}
</style>
