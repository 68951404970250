function buildQueryOnGoToProduct(product, isAppliances = false, openedFrom) {
  let query = product.replacement || {};
  if (product.category_id && product.parent_id) {
    const pId = product.parent_id;
    const cId = product.category_id;
    query = Object.assign(query, {
      category_id: cId,
      category_name: encodeURIComponent(product.category_name),
      /*
        Если кликнуть на карточку в поиске, то на десктопе в детальной
        в хлебных крошках клик по названию категории будет
        вести на страницу поиска, что неправильно.
        Поэтому руками формируем ссылку
      */
      path: `/category/${pId}?tag=${cId}`,
    });
  }
  query.opened_from = openedFrom;
  if (isAppliances) {
    query = Object.assign(query, { showAppliances: true });
  }
  return query;
}

export const ProductService = {
  buildQueryOnGoToProduct,
};
