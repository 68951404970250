<template>
  <div class="flex flex-col text-black-new-500 dark:text-white">
    <h3 class="font-bold text-center text-2xl mt-10">ABTEST</h3>
    <div class="mt-3">
      A/B test result:
      <p v-if="!tests">Nothing</p>
    </div>
    <pre v-for="(item, index) in tests" :key="index" class="mt-2">
      {{ item }}
    </pre>
    <div class="flex justify-between mt-auto mb-10">
      <UiButton class="mt-10" @click="onTap">Init A/B Test</UiButton>
      <UiButton class="mt-10" @click="$router.push({ name: 'main' })">
        close view
      </UiButton>
    </div>
  </div>
</template>

<script>
import UiButton from '@ui/UiButton.vue';
import { mapGetters } from 'vuex';
import { ABTestService } from '@services/abtest';

export default {
  name: 'ABTestView',
  components: {
    UiButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('abtest', ['tests']),
  },
  methods: {
    onTap() {
      ABTestService.fetchTests();
    },
  },
};
</script>
