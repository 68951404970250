<template>
  <div class="OpenTime">
    <transition name="fade">
      <div
        v-if="
          $store.getters['delivery/warehouse'] && !isWarehouseOpen && reminder
        "
        v-floating-element="'open-time'"
        class="px-4 fixed inset-x-0 z-footer flex justify-center duration-500 ease-in"
        :class="`${position}`"
        style=""
      >
        <div
          class="pl-3 py-4 pr-4 w-full rounded-2xl bg-sirius-black-1000 flex items-center"
          :style="`max-width: 500px; backdrop-filter: blur(2px)`"
        >
          <img
            class="block md:hidden mr-2"
            width="24"
            height="24"
            src="@img/Sleeping Face.png"
            alt=""
          />
          <img
            class="hidden md:block mr-3"
            width="36"
            height="36"
            src="@img/Sleeping Face.png"
            alt=""
          />
          <div class="text-white">
            <p class="text-xs md:text-base font-bold">
              <template v-if="$i18n.locale === 'ru'">
                Начнем принимать заказы
                <span class="whitespace-nowrap">
                  в {{ warehouseOpenDateTime }}
                </span>
              </template>
              <template v-if="$i18n.locale === 'kk'">
                Тапсырыстарды {{ warehouseOpenDateTime }}-де қабылдап бастаймыз
              </template>
            </p>
            <p class="mt-1 hidden md:inline font-medium text-xs">
              Мы принимаем заказы с {{ warehouseOpenDateTime }} до
              {{ warehouseCloseDateTime }}
            </p>
          </div>
          <UiButton
            class="px-4 md:px-6 ml-auto font-medium"
            variant="white"
            size="sm"
            @click="onCloseClick"
          >
            {{ $t('btn') }}
          </UiButton>
        </div>
      </div>
    </transition>
    <UiBottomSheetPopup
      v-model="show"
      style="--popup-width-d: 472px"
      closable
      with-title
      with-back-button
    >
      <div class="">
        <p class="mr-4 text-2xl font-bold">
          <template v-if="$i18n.locale === 'ru'">
            Начнем принимать заказы
            <span class="whitespace-nowrap">
              в {{ warehouseOpenDateTime }}
            </span>
          </template>
          <template v-if="$i18n.locale === 'kk'">
            Тапсырыстарды {{ warehouseOpenDateTime }}-де қабылдап бастаймыз
          </template>
        </p>
        <div class="mt-6">
          <span v-html="$t('weAreGonnaBeDeliveringSoon')" />
        </div>

        <UiButton class="mt-8" size="LG" @click="show = false">
          {{ $t('btn') }}
        </UiButton>
      </div>
    </UiBottomSheetPopup>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import UiBottomSheetPopup from '@pure-ui/components/UiBottomSheetPopup/UiBottomSheetPopup.vue';
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'OpenTime',
  components: {
    UiBottomSheetPopup,
    UiButton,
  },
  props: {
    reminder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    /**
     * warehouse берётся из
     * `/lavka/v1${isAuthorizedUrl(isAuthorized)}/warehouses/get_by_coordinate`
     */
    ...mapState('delivery', ['isWarehouseOpen', 'warehouse']),
    ...mapGetters('cart', ['checkoutReminder']),

    warehouseOpenDateTime() {
      if (this.warehouse?.opens_at) {
        return this.warehouse.opens_at.split(':').slice(0, -1).join(':');
      }
      return '08:00';
    },
    warehouseCloseDateTime() {
      if (this.warehouse?.closes_at) {
        return this.warehouse.closes_at.split(':').slice(0, -1).join(':');
      }
      return '23:00';
    },
    position() {
      if (this.$isWebView && this.checkoutReminder) return 'bottom-40';
      if (this.$isWebView) return 'bottom-20';
      if (!this.$isDesktop && this.checkoutReminder) return 'bottom-20';
      return 'bottom-10';
    },
  },
  methods: {
    ...mapActions('delivery', {
      setIsWarehouseOpenToTrue: 'SET_IS_WAREHOUSE_OPEN_TO_TRUE',
    }),
    ...mapMutations('component', {
      setOpenTimeToTrue: 'SET_OPEN_TIME_POPUP_TO_TRUE',
    }),
    openModal() {
      this.show = true;
      this.setOpenTimeToTrue();
    },
    onCloseClick() {
      this.setIsWarehouseOpenToTrue();
    },
  },
  eventBusEvents: {
    eb_open_time_modal() {
      this.openModal();
    },
  },
  i18n: {
    messages: {
      ru: {
        openingAt: 'Откроемся в {time}',
        weAreGonnaBeDeliveringSoon: `
          Совсем скоро мы снова будем доставлять вам свежие продукты!
          <br><br>
          Надеемся на ваше понимание! Главное, помните, если что, мы "Рядом"
        `,
        btn: 'Понятно',
      },
      kk: {
        openingAt: '{time}-да ашыламыз',
        weAreGonnaBeDeliveringSoon: `
          Қайтадан балғын азық-түлік жеткізіп бастауымызға аз қалды!
          <br><br>
          Түсінікпен қарайды деп үміттенеміз! Бастысы “Рядом” әрдайым жаныңызда екенін ұмытпаңыз
        `,
        btn: 'Түсінікті',
      },
    },
  },
};
</script>
