<template>
  <ToolBar>
    <span class="ml-2">{{ $t('myProfile') }}</span>

    <template #right-side>
      <div class="">
        <IconMiniappClose @click.native="$emit('backToRahmetApp')" />
      </div>
    </template>

    <template #bottom-toolbar> </template>
  </ToolBar>
</template>

<script>
import ToolBar from '@shared/components/layout/ToolBar.vue';
import IconMiniappClose from '@shared/components/icons/IconMiniappClose.vue';

export default {
  name: 'ProfilePageHeader',
  components: {
    IconMiniappClose,
    ToolBar,
  },
  i18n: {
    messages: {
      ru: {
        myProfile: 'Мой профиль',
      },
      kk: {
        myProfile: 'Профиль',
      },
    },
  },
};
</script>
