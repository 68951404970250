<template>
  <div class="cashbacks-finished">
    <p class="cashbacks-title" v-html="$t('finishedTitle')" />

    <div class="mt-4 md:mt-6 cashbacks-descr">
      <p v-html="$t('finishedDescr1', [totalBonuses])" />
      <p class="mt-4" v-html="$t('finishedDescr2')" />
    </div>

    <p class="cashbacks-subtitle" v-html="$t('referralTitle')" />

    <div class="mt-3 md:mt-4 cashbacks-descr">
      <p v-html="$t('referralDescr')" />
    </div>

    <UiButton
      v-if="$isWebView"
      size="lg"
      class="cashbacks-btn"
      @click="$emit('onBtnClicked')"
    >
      {{ $t('referralBtn') }}
    </UiButton>
  </div>
</template>

<script>
import UiButton from '@pure-ui/components/UiButton/UiButton.vue';

export default {
  name: 'FinishedCashbacks',
  components: {
    UiButton,
  },
  props: {
    totalBonuses: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.$emit('onMount');
  },
  i18n: {
    messages: {
      kk: {
        finishedTitle: 'Ура! Бонустар алынды!',
        finishedDescr1: 'Сізге 5 тапсырыс үшін {0} бонус түсті',
        finishedDescr2:
          'Кешбэктердің қаншалықты жиналғанын Choco қосымшасының (үстіңгі оң жақ бұрышта) басты бетінде жәнеде төлем жасау алдында көре аласыз',
        referralTitle: 'Реферал бағдарламасы✨',
        referralDescr:
          'Сізге енді реферал болу мүмкіндігі ашылды. Енді достарыңызбен промокод бөлісіп жеңілдіктер ала аласыз',
        referralBtn: 'Достарды шақыру',
      },
      ru: {
        finishedTitle: 'Ура! Бонусы получены!',
        finishedDescr1:
          'Вам начислено {0} бонусов кешбэка за&nbsp;5&nbsp;заказов.',
        finishedDescr2:
          'Баланс кешбэка отображается на главном экране приложения Choco (в правом верхнем углу), а&nbsp;также при оплате.',
        referralTitle: 'Реферальная программа ✨',
        referralDescr:
          'Теперь вам доступна реферальная программа. Вы можете делиться с&nbsp;друзьями промокодом и получать скидки.',
        referralBtn: 'Получить реферальный код',
      },
    },
  },
};
</script>

<style scoped>
.cashbacks-finished {
  @apply mx-auto;
  max-width: 588px;
}
.cashbacks-title {
  @apply font-bold text-2xl leading-8 md:text-4xl md:leading-10;
}
.cashbacks-subtitle {
  @apply mt-9 text-xl leading-6 font-bold md:mt-12;
}
.cashbacks-descr {
  @apply text-base leading-6 font-normal;
}
.cashbacks-btn {
  @apply mt-11 md:mt-13 w-full text-lg font-bold;
  max-width: 376px;
}
</style>
