<template>
  <div v-if="show" v-click-outside="close" class="SortingTooltip">
    <h2 class="font-bold text-lg mx-auto">
      {{ $t('showFirst') }}
    </h2>
    <div class="SortingTooltip__content">
      <UiRadio
        v-model="localSelectedSort"
        :radios="sortOptions"
        value-id="order"
        class="SortingTooltip__checks"
      />
      <div class="SortingTooltip__buttonsWrap">
        <UiButton
          v-if="selected !== 'popular_asc'"
          key="reset"
          class="SortingTooltip__btn"
          size="lg"
          variant="gray"
          @click="onResetClick"
        >
          {{ $t('reset') }}
        </UiButton>
        <UiButton
          key="apply"
          class="SortingTooltip__btn"
          size="lg"
          @click="onSubmitClick"
        >
          {{ $t('apply') }}
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import UiRadio from '@/pure-ui/components/UiRadio/UiRadio.vue';
import UiButton from '@/pure-ui/components/UiButton/UiButton.vue';
import { sortOptions } from '@/views/category/utils/index.js';

export default {
  name: 'SortingTooltip',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    UiRadio,
    UiButton,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    selected: {
      type: String,
      default: sortOptions[0].order,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    localSelectedSort: sortOptions[0],
  }),
  computed: {
    sortOptions() {
      const lc = this.$i18n.locale;
      return sortOptions.map(v => ({ ...v, title: v[`title.${lc}`] }));
    },
  },
  watch: {
    show(v) {
      if (!v) {
        this.localSelectedSort = sortOptions.find(
          o => o.order === this.selected
        );
      }
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onSubmitClick() {
      this.$emit('update:modelValue', this.localSelectedSort);
      this.close();
    },
    onResetClick() {
      this.localSelectedSort = sortOptions[0];
      this.onSubmitClick();
    },
  },
  i18n: {
    messages: {
      kk: {
        showFirst: 'Бірінші көрсету',
        reset: 'Тазарту',
        apply: 'Қолдану',
      },
      ru: {
        showFirst: 'Показывать сначала',
        reset: 'Сбросить',
        apply: 'Применить',
      },
    },
  },
};
</script>

<style scoped>
.SortingTooltip {
  position: absolute;
  z-index: 30;
  top: calc(100% + 20px);
  right: 0;
  width: 320px;

  padding: 24px 24px 32px;
  border-radius: var(--Spacing-spacing-3m, 24px);
  background: var(--Main-White, #fff);

  /* Shadow */
  box-shadow:
    0px 0.705px 2.546px 0px rgba(0, 0, 0, 0.01),
    0px 2.404px 6.118px 0px rgba(0, 0, 0, 0.01),
    0px 5.794px 11.52px 0px rgba(0, 0, 0, 0.02),
    0px -3px 20.549px 0px rgba(0, 0, 0, 0.06),
    0px 3px 38.435px 0px rgba(0, 0, 0, 0.03),
    0px 92px 92px 0px rgba(0, 0, 0, 0.05);
}

.SortingTooltip__checks {
  padding-top: 20px;
}

.SortingTooltip__buttonsWrap {
  padding-top: 24px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 16px;
}

.SortingTooltip__btn {
  font-weight: 700;
  border-radius: 16px !important;
}
</style>
