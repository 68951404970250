<template>
  <div class="no-promocodes">
    <h3 class="no-promocodes__title">
      {{ $t('noPromocodes') }}
    </h3>

    <p class="no-promocodes__descr">
      <span v-html="$t('promocodesWillBeHere')" />
    </p>
  </div>
</template>

<script>
export default {
  name: 'NoActivePromocodes',
  i18n: {
    messages: {
      ru: {
        noPromocodes: 'нет промокодов',
        promocodesWillBeHere: `
          Здесь будут ваши промокоды со&nbsp;скидками на&nbsp;заказы
        `,
      },
      kk: {
        noPromocodes: 'промокод жоқ',
        promocodesWillBeHere: `
          Бұл жерде тапсырыстарға жеңілдік беретін промокодтар тұрады
        `,
      },
    },
  },
};
</script>

<style>
.no-promocodes {
  border-radius: 16px;
  background: #f1f2f7;
  padding: 12px 20px 28px;
  background-image: url(@img/promocodes/no-promocodes.svg);
  background-position: right 14px bottom;
  background-repeat: no-repeat;
}

.no-promocodes__title {
  font-size: 34px;
  color: #d4d3df;
  font-weight: 900;
}

.no-promocodes__descr {
  padding-top: 8px;
  color: #7b7a8c;
}

@media (min-width: 768px) {
  .no-promocodes {
    padding: 24px 30px 36px;
  }
}
</style>
