import { store } from '@/store';

/**
 * Бэк помечает адрес, на который был сделан последний заказ,
 * полем is_main
 */
export function getLastOrderAddress() {
  const addresses = store.getters['user/addresses'];
  return addresses.find(address => address.is_main);
}
