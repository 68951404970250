const DEBUG = false;

const PRELOAD__TIME_BEFORE_NEXT = 500;
const PRELOAD__TIME_BEFORE_FAILED = 10e3;

export class ImagesLoader {
  constructor({ vm }) {
    // прокинем сразу весь экземпляр
    // чтобы не заморачиваться с реактивностью
    this.vm = vm;
  }

  get stories() {
    return this.vm.localStories;
  }

  preloadGroup({ idx }) {
    DEBUG && console.log(`imagesLoader.preloadGroup(idx=${idx})`);
    const slides = this.stories[idx].stories;
    return slides.reduce((acc, slide) => {
      return acc.then(() => this._preloadImage(slide));
    }, Promise.resolve());
  }

  /**
   * Предзагружает картинку в кеш браузера.
   * Ждёт PRELOAD__TIME_BEFORE_NEXT мс или до загрузки картинки, если
   * она произошла быстрее.
   * Если картинка загружена, ставит флаг isLoaded.
   * Если произошла ошибка или прошло больше PRELOAD__TIME_BEFORE_FAILED мс
   * ставит флаг isFailed;
   */
  _preloadImage(slide) {
    const imageProp = this.vm.$i18n.locale === 'kk' ? 'imageKk' : 'imageRu';
    const videoProp = this.vm.$i18n.locale === 'kk' ? 'videoKk' : 'videoRu';
    const imageUrl = slide[imageProp];
    const videoUrl = slide[videoProp];

    DEBUG && console.log(`imagesLoader._preloadImage(url="${imageUrl}")`);

    return new Promise(resolve => {
      if (videoUrl) {
        this.vm.$set(slide, 'isVideo', true);
        return resolve();
      }

      const onError = () => {
        this.vm.$set(slide, 'isFailed', true);
        resolve();
      };
      const image = new Image();

      setTimeout(resolve, PRELOAD__TIME_BEFORE_NEXT);
      const failedTimeoutId = setTimeout(onError, PRELOAD__TIME_BEFORE_FAILED);

      image.onload = () => {
        this.vm.$set(slide, 'isLoaded', true);
        clearTimeout(failedTimeoutId);
        resolve();
      };
      image.onerror = onError;

      image.src = imageUrl;
    });
  }

  _extractImages(group) {
    const prop = this.vm.$i18n.locale === 'kk' ? 'imageKk' : 'imageRu';
    return group.stories.map(v => v[prop]);
  }
}
